import { RootState } from './../index';
import { GET_EVENT_BY_MAIL_REQUEST } from './../../constants/events';
import { call, put, takeEvery, fork, select } from 'redux-saga/effects';
import {
  GET_EVENT_MEMBERS_REQUEST,
  ADD_EVENT_MEMBER_REQUEST,
  ADD_EVENT_GUEST_REQUEST,
  REMOVE_EVENT_MEMBER_REQUEST,
  REMOVE_EVENT_GUEST_REQUEST,
  GET_EVENT_BY_ID_REQUEST,
  GET_AUTOCOMPLETE_EVENTS_REQUEST,
  GET_CORPS_CONTACTS_REQUEST,
  ADD_EVENT_REQUEST,
  UPDATE_EVENT_REQUEST,
  REMOVE_EVENT_REQUEST,
} from '../../constants/';
import {
  getEventByIdSuccess,
  getEventByIdFailure,
  getEventMembersSuccess,
  getEventMembersFailure,
  addEventMemberSuccess,
  addEventMemberFailure,
  removeEventMemberSuccess,
  removeEventMemberFailure,
  getAutocompleteEventsSuccess,
  getAutocompleteEventsFailure,
  getCorpsContactsSuccess,
  getCorpsContactsFailure,
  addEventSuccess,
  addEventFailure,
  updateEventSuccess,
  removeEventSuccess,
  removeEventFailure,
  setEvents,
} from '../actions/events';
import { showResponse } from '../actions/response';

import {
  getEventMembers,
  addEventMember,
  addEventGuest,
  removeEventMember,
  removeEventGuest,
  getEventById,
  getAutocompletes,
  getCorpsContacts,
  addEvent,
  removeEvent,
  updateEvent,
} from '../../api/';
import { getEventByMail } from '../../api/events';
import { modifyPost } from 'store/reducers/news';

// GET EVENT BY ID
function* getEventByIdWorkerSaga(action) {
  const { eventId, child, startDate, endDate } = action;

  try {
    const result = yield call(() => getEventById(eventId, child, startDate, endDate));

    yield put(getEventByIdSuccess(result));
  } catch (e) {
    yield put(getEventByIdFailure(e));
    yield put(showResponse(e));
  }
}

function* getEventByIdWatcherSaga() {
  yield takeEvery(GET_EVENT_BY_ID_REQUEST, getEventByIdWorkerSaga);
}

// GET EVENT BY GUEST MAIL
function* getEventByMailWorkerSaga(action) {
  const { eventId, email } = action;

  try {
    const result = yield call(() => getEventByMail(eventId, email));

    yield put(getEventByIdSuccess(result.Data));
  } catch (e) {
    yield put(getEventByIdFailure(e));
    yield put(showResponse(e));
  }
}

function* getEventByMailWatcherSaga() {
  yield takeEvery(GET_EVENT_BY_MAIL_REQUEST, getEventByMailWorkerSaga);
}

// GET EVENT MEMBERS
function* getEventMembersWorkerSaga(action) {
  const { eventId, page, limit } = action;

  try {
    const result = yield call(() => getEventMembers(eventId, page, limit));
    yield put(getEventMembersSuccess(result.Data.data, result.Data.total));
  } catch (e) {
    yield put(getEventMembersFailure(e));
    yield put(showResponse(e));
  }
}

function* getEventMembersWatcherSaga() {
  yield takeEvery(GET_EVENT_MEMBERS_REQUEST, getEventMembersWorkerSaga);
}

// ADD EVENT MEMBER
function* addEventMemberWorkerSaga(action) {
  const { eventId, userId, recConfig } = action;
  const userLocalId = localStorage.getItem('userId');
  const id = userLocalId || userId || '';

  try {
    if (id) {
      const res = yield call(() => addEventMember(eventId, id, recConfig));
      if (res.Data) {
        const {
          events: { currentEvent, events, eventList },
        }: RootState = yield select();
        delete res.Data.organizers;

        const {
          news: { news, kreiseNews },
        }: RootState = yield select();

        const post = news.find((post) => {
          return post?.event?._id === eventId;
        });

        if (post) {
          const updatedPost = { ...post, event: { ...post.event, ...res.Data } };
          yield put(modifyPost({ type: 'news', post: updatedPost }));
        }

        const kreisePost = kreiseNews.find((post) => {
          return post?.event?._id === eventId;
        });

        if (kreisePost) {
          const updatedKreisePost = { ...kreisePost, event: { ...kreisePost.event, ...res.Data } };
          yield put(modifyPost({ type: 'kreiseNews', post: updatedKreisePost }));
        }

        if (events) {
          const idx = events.findIndex((event) => event._id === eventId);
          const newEvents = [...events];
          newEvents[idx] = {
            ...newEvents[idx],
            ...res.Data,
          };
          yield put(setEvents(newEvents, 'events'));
        }
        if (eventList) {
          const idx = eventList.findIndex((event) => event._id === eventId);
          const newEvents = [...eventList];
          newEvents[idx] = {
            ...newEvents[idx],
            ...res.Data,
          };
          yield put(setEvents(newEvents, 'eventList'));
        }

        yield put(getEventByIdSuccess({ ...currentEvent, ...res.Data }));
      }
      yield put(addEventMemberSuccess());
    }
  } catch (e) {
    yield put(addEventMemberFailure(e));
    yield put(showResponse(e));
  }
}

function* addEventMemberWatcherSaga() {
  yield takeEvery(ADD_EVENT_MEMBER_REQUEST, addEventMemberWorkerSaga);
}

// ADD EVENT GUEST MEMBER
function* addEventGuestWorkerSaga(action) {
  const { eventId, email, guest, parts } = action;

  try {
    if (eventId && guest.guestName) {
      const result = yield call(() => addEventGuest(eventId, email, guest, parts));
      yield put(addEventMemberSuccess());
      const {
        events: { currentEvent },
      }: RootState = yield select();
      yield put(getEventByIdSuccess({ ...currentEvent, acceptedGuests: result.Data }));
    }
  } catch (e) {
    yield put(addEventMemberFailure(e));
    yield put(showResponse(e));
  }
}

function* addEventGuestWatcherSaga() {
  yield takeEvery(ADD_EVENT_GUEST_REQUEST, addEventGuestWorkerSaga);
}

// REMOVE EVENT MEMBER
function* removeEventMemberWorkerSaga(action) {
  const { eventId } = action;
  const userId = localStorage.getItem('userId');

  try {
    if (eventId && userId) {
      const res = yield call(() => removeEventMember(eventId, userId));
      if (res.Data) {
        const {
          events: { currentEvent, events, eventList },
        }: RootState = yield select();

        delete res.Data.organizers;

        const {
          news: { news, kreiseNews },
        }: RootState = yield select();

        const post = news.find((post) => {
          return post?.event?._id === eventId;
        });

        if (post) {
          const updatedPost = { ...post, event: { ...post.event, ...res.Data } };
          yield put(modifyPost({ type: 'news', post: updatedPost }));
        }

        const kreisePost = kreiseNews.find((post) => {
          return post?.event?._id === eventId;
        });

        if (kreisePost) {
          const updatedKreisePost = { ...kreisePost, event: { ...kreisePost.event, ...res.Data } };
          yield put(modifyPost({ type: 'kreiseNews', post: updatedKreisePost }));
        }

        if (events) {
          const idx = events.findIndex((event) => event._id === eventId);
          const newEvents = [...events];
          newEvents[idx] = {
            ...newEvents[idx],
            ...res.Data,
          };
          yield put(setEvents(newEvents, 'events'));
        }
        if (eventList) {
          const idx = eventList.findIndex((event) => event._id === eventId);
          const newEvents = [...eventList];
          newEvents[idx] = {
            ...newEvents[idx],
            ...res.Data,
          };
          yield put(setEvents(newEvents, 'eventList'));
        }
        yield put(getEventByIdSuccess({ ...currentEvent, ...res.Data }));
      }
      yield put(removeEventMemberSuccess());
    }
  } catch (e) {
    yield put(removeEventMemberFailure(e));
    yield put(showResponse(e));
  }
}

function* removeEventMemberWatcherSaga() {
  yield takeEvery(REMOVE_EVENT_MEMBER_REQUEST, removeEventMemberWorkerSaga);
}

// REMOVE EVENT GUEST MEMBER
function* removeEventGuestWorkerSaga(action) {
  const { eventId, option } = action;

  try {
    if (option) {
      const result = yield call(() => removeEventGuest(eventId, option));
      yield put(removeEventMemberSuccess());
    }
  } catch (e) {
    yield put(removeEventMemberFailure(e));
    yield put(showResponse(e));
  }
}

function* removeEventGuestWatcherSaga() {
  yield takeEvery(REMOVE_EVENT_GUEST_REQUEST, removeEventGuestWorkerSaga);
}

// GET AUTOCOMPLETES
function* getAutocompleteEventsWorkerSaga() {
  try {
    const result = yield call(getAutocompletes);
    yield put(getAutocompleteEventsSuccess(result.Data));
  } catch (e) {
    yield put(getAutocompleteEventsFailure(e));
    yield put(showResponse(e));
  }
}

function* getAutocompleteEventsWatcherSaga() {
  yield takeEvery(GET_AUTOCOMPLETE_EVENTS_REQUEST, getAutocompleteEventsWorkerSaga);
}

// GET CORPS CONTACTS
function* getCorpsContactsWorkerSaga(action) {
  const userId = localStorage.getItem('userId');
  const { corpsName } = action;

  try {
    if (userId) {
      const result = yield call(() => getCorpsContacts(userId, corpsName));

      yield put(getCorpsContactsSuccess(result.Data.list));
    }
  } catch (e) {
    yield put(getCorpsContactsFailure(e));
    yield put(showResponse(e));
  }
}

function* getCorpsContactsWatcherSaga() {
  yield takeEvery(GET_CORPS_CONTACTS_REQUEST, getCorpsContactsWorkerSaga);
}

// ADD EVENT
function* addEventWorkerSaga(action) {
  const { event, callback } = action;
  let { eventObj } = event;

  try {
    const eventResult = yield call(() => addEvent(eventObj));
    yield put(addEventSuccess(eventResult.Data));
    callback();
  } catch (e) {
    console.log(e);
    yield put(showResponse(e));
    yield put(addEventFailure(e));
  }
}

function* addEventWatcherSaga() {
  yield takeEvery(ADD_EVENT_REQUEST, addEventWorkerSaga);
}

// UPDATE EVENT
function* updateEventWorkerSaga(action) {
  let { event, callback } = action;
  let { eventObj } = event;
  try {
    const eventResult = yield call(() => updateEvent(eventObj));
    yield put(updateEventSuccess(eventResult.Data));
    callback();
  } catch (e) {
    yield put(showResponse(e));
  }
}

function* updateEventWatcherSaga() {
  yield takeEvery(UPDATE_EVENT_REQUEST, updateEventWorkerSaga);
}

// REMOVE EVENT
function* removeEventWorkerSaga(action) {
  const { eventId, notify, payload, callback } = action;

  try {
    yield call(() => removeEvent(eventId, notify, payload));
    yield put(removeEventSuccess({ eventId }));
    callback();
  } catch (e) {
    yield put(removeEventFailure(e));
    yield put(showResponse(e));
  }
}

function* removeEventWatcherSaga() {
  yield takeEvery(REMOVE_EVENT_REQUEST, removeEventWorkerSaga);
}

const eventsSagas = [
  fork(getEventMembersWatcherSaga),
  fork(addEventMemberWatcherSaga),
  fork(addEventGuestWatcherSaga),
  fork(removeEventMemberWatcherSaga),
  fork(removeEventGuestWatcherSaga),
  fork(getEventByIdWatcherSaga),
  fork(getEventByMailWatcherSaga),
  fork(getAutocompleteEventsWatcherSaga),
  fork(getCorpsContactsWatcherSaga),
  fork(addEventWatcherSaga),
  fork(updateEventWatcherSaga),
  fork(removeEventWatcherSaga),
];

export default eventsSagas;
