import React, { useState, useEffect } from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { RouteComponentProps } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store';
import { showResponse } from 'store/actions/response';
import { getUserProfileRequest } from 'store/actions/users';
import { sendRequestRequest } from 'store/actions/requests';
import { withConfig } from 'config';
import { imagePath, getAge } from 'utilities';
import { profileImagePath } from 'utilities/profileImagePath';
import Layout from 'components/UI/Layout';
import Loader from 'components/UI/Loader';
import Button from 'components/UI/Button';
import ImageLightBox from 'components/Shared/ImageLightBox/ImageLightBox';
import BackButton from 'components/Shared/BackButton';

import { ProfileDetailsProps, ProfileItemRedirectTypes } from './model';
import ProfileInfo from './components/ProfileInfo';
import ProfileCorps from './components/ProfileCorps';
import ProfileItem from './components/ProfileItem';
import ProfileLink from './components/ProfileLink';
import ProfileAvatar from './components/ProfileAvatar';
import ProfileInteressen from './components/ProfileInteressen';
import { useLanguage } from 'languages/languageContext';
import { getMentoringRequests, mentoringActions, SelectMentoringState } from 'store/reducers/mentoring';
import { mentoringSendRequest, mentoringUpdateRequest } from 'api/mentoring';
import moment from 'moment';
import { dateToString } from 'utilities/dateToString';
import { requestMessage } from 'utilities/messages';
import SocialMediaLink from './components/SocialMediaLink';
import ProfileAttachments from './components/ProfileAttachments';

const styles = createStyles({
  profile: {
    width: '100%',
    borderBottom: '1px solid',
    paddingBottom: 30,
  },
  content: {
    padding: '20px 0',
  },
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any>, ProfileDetailsProps {}

const ProfileDetails: React.FC<Props> = (props) => {
  const { profileDetails, profileStudy, screenTitles, mentoring, responseHandler, profileDetailsScreen, global } =
    useLanguage();
  const dispatch = useAppDispatch();
  const [openLightbox, setOpenLightbox] = useState(false);
  const [largeImage, setLargeImage] = useState('');
  const { user, userIsLoading, currentUser } = useAppSelector((state) => state.users);
  const { sendRequestIsLoading } = useAppSelector(({ requests }) => requests);
  const common = useAppSelector((state) => state.common);
  console.log(currentUser);
  const { classes, theme, history, config } = props;
  const userId = localStorage.getItem('userId');
  const { prevPath } = history.location.state || {};
  let requestSent, reportSent, inContacts;
  const { requests } = useAppSelector(SelectMentoringState);

  if (user && user.name && currentUser && currentUser.name) {
    const reportsIds = user.report_list.map((item) => item.id);
    const requestsIds = user.sent_contact_request.map((item) => item.id);
    requestSent = requestsIds.includes(currentUser._id);
    reportSent = reportsIds.includes(currentUser._id);
    inContacts = user.contact_list.includes(currentUser._id);
  }

  let image,
    imageLarge,
    name,
    status,
    corps,
    branch,
    contacts,
    city,
    country,
    age,
    profession,
    firm,
    link,
    buisness,
    area,
    employmentType,
    activity,
    career,
    description,
    period,
    university,
    studyProgramm,
    corpsList,
    owner,
    interessenList,
    socialMediaList,
    biername,
    education,
    additionalBeruf,
    leibfuechse,
    leibbursch,
    mobilePhone,
    telephoneLandline,
    mobilfunknummer,
    street,
    postCode,
    email,
    partner;

  if (currentUser && currentUser.name) {
    owner = currentUser._id === userId;
    status = currentUser.corp[0]?.status;
    name = `${currentUser.title ? currentUser.title + ' ' : ''}${currentUser.name} ${currentUser.lastname} ${
      currentUser.isGuest ? '(' + profileDetails.guest + ')' : ''
    }`;
    corps = currentUser.corp[0].corpsname;
    branch = currentUser.industry;
    contacts = currentUser.contact_list.length;
    country = currentUser.country;
    corpsList = currentUser.corp;
    age = currentUser.birthday && getAge(currentUser.birthday);
    profession = currentUser.beruf[0]?.name;
    firm = currentUser.beruf[0]?.company;
    link = currentUser.beruf[0]?.website;
    buisness = currentUser.beruf[0]?.industry;
    area = currentUser.beruf[0]?.domain;
    employmentType = currentUser.beruf[0]?.employmentType;
    activity = currentUser.beruf[0]?.activity;
    career = currentUser.beruf[0]?.careerLevel;
    description = currentUser.beruf[0]?.description;
    period =
      currentUser.start && currentUser.end
        ? `${dateToString(currentUser.start, 'MM.YYYY')} - ${dateToString(currentUser.end, 'MM.YYYY', global.tilToday)}`
        : null;
    university = currentUser.hochschule;
    studyProgramm = currentUser.studiengang;
    interessenList = currentUser.interessen;
    biername = currentUser.corp[0].biername || '';
    education = currentUser.education;
    additionalBeruf = currentUser.beruf.filter((_, idx) => idx !== 0);
    leibfuechse = currentUser.leibfuechse;
    leibbursch = currentUser.leibbursch;
    mobilfunknummer = currentUser.mobilfunknummer;
    mobilePhone = currentUser.mobilePhone;
    telephoneLandline = currentUser.telephoneLandline;
    street = currentUser.street;
    postCode = currentUser.postcode;
    email = currentUser.email;
    partner = currentUser.partner;
    city = currentUser.wohnort;
    socialMediaList = currentUser.socialMedia;
    if (currentUser && currentUser.photo) {
      image = imagePath(null, currentUser.photo);
    }
  }

  const handleSendRequest = () => {
    dispatch(
      sendRequestRequest(
        currentUser._id,
        requestMessage({ sender: `${user.name} ${user.lastname}`, receiver: currentUser.name }),
        profileDetails.sendRequestSuccessLabel,
      ),
    );
  };

  const openLightboxHandler = () => {
    setOpenLightbox((prev) => !prev);
  };

  const getUser = () => {
    const userId = history.location.pathname.replace('/profile-details/', '');

    dispatch(getUserProfileRequest(userId));
  };

  const backHandler = () => {
    if (prevPath) {
      history.push(prevPath, { receiver: currentUser });
    } else {
      history.goBack();
    }
  };

  const getNavigationMetadata = (type: Partial<ProfileItemRedirectTypes>, optionalSource?: string) => {
    const metadata = { type };
    if (optionalSource) Object.assign(metadata, { optionalSource });
    return metadata;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getUser();
  }, [userId]);

  useEffect(() => {
    getFullPhoto();
  }, [currentUser]);

  async function getFullPhoto() {
    if (currentUser?.photo) {
      const res = await profileImagePath('full', currentUser.photo);
      setLargeImage(res);
    }
  }

  const canSendRequest = !requestSent && !inContacts && !config.WITHOUT_CONTACTS;

  const mentoringRequestId = requests.outgoingRequests.findIndex((req) => req?.receiver?._id === currentUser?._id);
  const mentoringRequestSent = mentoringRequestId !== -1;
  const alreadyHaveMentor = requests.mentors.length;

  const mentoringRequestHandler = async (id: string) => {
    try {
      if (mentoringRequestSent) {
        await mentoringUpdateRequest(requests.outgoingRequests[mentoringRequestId]._id, 'cancelled');
        const filteredRequests = requests.outgoingRequests.filter((_, idx) => idx === mentoringRequestId);
        dispatch(mentoringActions.setRequests({ ...requests, outgoingRequests: filteredRequests }));
        await dispatch(getMentoringRequests());
        dispatch(showResponse({ message: mentoring.requests.requestRetracted }));
      }

      if (!mentoringRequestSent) {
        history.push(`/mentoring/mentoring-request/${userId}`, {
          user: currentUser,
          // sendMentoringRequest: (text: string) => sendMentoringRequest(id, text),
          reason: 'send',
        });
      }
    } catch (e) {
      console.log('ERROR ON sendContactRequest', e);
      dispatch(showResponse({ message: responseHandler.messageLabel }));
    }
  };

  return (
    <Layout>
      <BackButton title={screenTitles.profileTitle} onClick={backHandler} />
      <Loader showLoader={userIsLoading || sendRequestIsLoading} />
      <ImageLightBox
        showGallery={openLightbox}
        onClose={openLightboxHandler}
        images={[{ url: largeImage, fileName: largeImage, size: null, mimetype: '' }]}
      />
      {currentUser && currentUser.name && !userIsLoading && (
        <div className={'w-full p-4 md:p-8 relative'}>
          <div className={classes.profile} style={{ borderColor: theme.BACKGROUND_SECONDARY }}>
            <ProfileAvatar image={image} onClick={openLightboxHandler} />
            {name && corps && (
              <ProfileInfo
                name={name}
                status={status}
                corps={corps}
                branch={branch}
                contacts={contacts}
                biername={biername}
              />
            )}
          </div>
          <div className={classes.content}>
            {common.allow_bio && currentUser.bio && <ProfileItem title={profileDetails.bio} value={currentUser.bio} />}
            {common.allow_link && currentUser.link && (
              <ProfileItem title={profileDetails.link} value={currentUser.link} />
            )}
            {config.WITH_ADRESS && config.APP_NAME !== 'gdl' && street && (
              <ProfileItem value={street} title={profileDetails.street} />
            )}
            {city && (
              <ProfileItem
                navigationMetadata={getNavigationMetadata(ProfileItemRedirectTypes.Stadt)}
                prefixNavigationMetadata={getNavigationMetadata(ProfileItemRedirectTypes.PostCode)}
                title={profileDetails.wohnort}
                value={city}
                prefix={postCode}
              />
            )}
            {country && (
              <ProfileItem
                navigationMetadata={getNavigationMetadata(ProfileItemRedirectTypes.Country)}
                title={profileDetails.country}
                value={country}
              />
            )}
            {corps && (
              <ProfileCorps
                navigationMetadata={getNavigationMetadata(ProfileItemRedirectTypes.Clubs)}
                corpsList={corpsList}
              />
            )}
            {partner && <ProfileItem value={partner} title={profileDetails.partner} />}
            {config.WITH_MENTOR && (
              <>
                {leibbursch && <ProfileItem value={leibbursch} title={profileDetails.leibbursch} />}
                {leibfuechse && <ProfileItem value={leibfuechse} title={profileDetails.leibfuechse} />}
              </>
            )}
            {config.SHOW_MOBILE && mobilfunknummer && (
              <ProfileItem value={mobilfunknummer} title={profileDetails.mobileNumberLabel} />
            )}
            {config.WITH_TELEPHONE && (
              <>
                {/* {mobilePhone && <ProfileItem value={mobilePhone} title={profileDetails.handphoneNumberLabel} />} */}
                {telephoneLandline && (
                  <ProfileItem value={telephoneLandline} title={profileDetails.telephoneNumberLabel} />
                )}
              </>
            )}
            {config.SHOW_EMAIL && email && <ProfileItem value={email} title={profileDetailsScreen.email} />}
            {config.APP_NAME !== 'soroptimist' &&
              config.SHOW_AGE &&
              (age ? (
                <ProfileItem
                  title={profileDetails.birthdayLabel}
                  value={
                    currentUser.birthday &&
                    `${moment(currentUser.birthday.slice(0, 10)).format('DD.MM.YYYY')} (${
                      profileDetails.ageLabel
                    }: ${getAge(currentUser.birthday)})`
                  }
                />
              ) : null)}
            {config.WITH_WORK && (
              <>
                {profession && (
                  <ProfileItem
                    navigationMetadata={getNavigationMetadata(ProfileItemRedirectTypes.Beruf)}
                    title={profileDetails.positionLabel}
                    value={profession}
                  />
                )}
                {firm && (
                  <ProfileLink
                    navigationMetadata={getNavigationMetadata(ProfileItemRedirectTypes.Firma)}
                    title={profileDetails.firmaLabel}
                    value={firm}
                    link={link}
                  />
                )}
                {buisness && config.APP_NAME !== 'gdl' && (
                  <ProfileItem
                    navigationMetadata={getNavigationMetadata(ProfileItemRedirectTypes.Branche)}
                    title={profileDetails.industryLabel}
                    value={buisness}
                  />
                )}
                {area && <ProfileItem title={profileDetails.areaLabel} value={area} />}
                {employmentType && <ProfileItem title={profileDetails.typeOfEmplLabel} value={employmentType} />}
                {activity && <ProfileItem title={profileDetails.activityLabel} value={activity} />}
                {career && <ProfileItem title={profileDetails.careerStageLabel} value={career} />}
                {description && <ProfileItem title={profileDetails.activityDescLabel} value={description} />}
                {period && <ProfileItem title={profileDetails.employmentPeriodLabel} value={period} />}
                {additionalBeruf?.map((beruf: any, i: number) => {
                  const start = dateToString(beruf.start, 'MM.YYYY');
                  const end = dateToString(beruf.end, 'MM.YYYY');
                  const shouldRenderDate = !!start.length && !!end.length;
                  return (
                    beruf?._id && (
                      <React.Fragment key={beruf._id}>
                        <div
                          style={{
                            backgroundColor: theme.BACKGROUND_SECONDARY,
                            height: 1,
                          }}
                          key={beruf._id}
                        />
                        {beruf.name && <ProfileItem title={profileDetails.positionDescLabel} value={beruf.name} />}
                        {beruf.company && <ProfileItem title={profileDetails.firmaLabel} value={beruf.company} />}
                        {beruf.industry && <ProfileItem title={profileDetails.industryLabel} value={beruf.industry} />}

                        {beruf.branch && <ProfileItem title={profileDetails.industryLabel} value={beruf.branch} />}
                        {shouldRenderDate && (
                          <ProfileItem title={profileDetails.employmentPeriodLabel} value={`${start} - ${end}`} />
                        )}
                      </React.Fragment>
                    )
                  );
                })}
              </>
            )}
            {config.WITH_EDUCATION && (
              <>
                {' '}
                {university && (
                  <ProfileItem
                    navigationMetadata={getNavigationMetadata(ProfileItemRedirectTypes.Hochschule, 'hochschule')}
                    title={profileStudy.universityLabel}
                    value={university}
                  />
                )}
                {studyProgramm && (
                  <ProfileItem
                    navigationMetadata={getNavigationMetadata(ProfileItemRedirectTypes.Studiengang, 'studiengang')}
                    title={profileStudy.courseLabel}
                    value={studyProgramm}
                  />
                )}
                {education?.map((el: any, i) => {
                  return (
                    <React.Fragment key={el._id}>
                      <div
                        style={{
                          // borderBottomColor: 'red',
                          backgroundColor: theme.BACKGROUND_SECONDARY,
                          height: 1,
                        }}
                      />
                      {el?.hochschule && (
                        <ProfileItem
                          navigationMetadata={getNavigationMetadata(ProfileItemRedirectTypes.Studiengang)}
                          title={profileStudy.universityLabel}
                          value={el.hochschule}
                        />
                      )}
                      {el?.studiengang && (
                        <ProfileItem
                          navigationMetadata={getNavigationMetadata(ProfileItemRedirectTypes.Studiengang)}
                          title={profileStudy.courseLabel}
                          value={el.studiengang}
                        />
                      )}
                      {el?.abschluss && (
                        <ProfileItem
                          navigationMetadata={getNavigationMetadata(ProfileItemRedirectTypes.Studiengang)}
                          title={profileStudy.degreeLabel}
                          value={el.abschluss}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </>
            )}
            {interessenList && interessenList.length ? (
              <ProfileInteressen
                navigationMetadata={getNavigationMetadata(ProfileItemRedirectTypes.Interessen)}
                interessenList={interessenList}
              />
            ) : null}{' '}
            {common.social_media && !!socialMediaList?.length && <SocialMediaLink items={socialMediaList} />}
          </div>

          {common.allow_attachments && currentUser.attachments && (
            <ProfileAttachments value={currentUser.attachments} />
          )}

          {owner && (
            <div className={classes.buttonsWrapper}>
              <Button label={profileDetails.editProfileLabel} onClick={() => history.push('/profile')} />
            </div>
          )}
          {!owner && (
            <div className={classes.buttonsWrapper}>
              {canSendRequest && (
                <Button label={profileDetails.addContactLabel} onClick={handleSendRequest} width={300} />
              )}
              <Button
                label={profileDetails.sendMessageLabel}
                onClick={() => history.push('/dialogs/' + currentUser._id, { receiver: currentUser })}
                width={300}
              />
              {!user.isMentor && currentUser.mentoringActive && !alreadyHaveMentor && (
                <Button
                  label={
                    mentoringRequestSent ? mentoring.requests.cancelSendingRequest : mentoring.requests.sendRequest
                  }
                  onClick={() => mentoringRequestHandler(currentUser._id)}
                  width={300}
                  background={(mentoringRequestSent && theme.ERROR_PRIMARY) || undefined}
                />
              )}
              {requestSent && <Button label={profileDetails.requestSentLabel} disabled={false} width={300} />}
              {!history.location.state?.fromMentoring && (
                <Button
                  label={profileDetails.addReportLabel}
                  background={theme.BUTTON_SECONDARY}
                  width={300}
                  onClick={() => history.push('/report', { user: currentUser })}
                />
              )}
            </div>
          )}
        </div>
      )}
    </Layout>
  );
};

export default withStyles(styles)(withConfig(ProfileDetails));
