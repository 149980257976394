import {
  acceptEventName,
  ActivityName,
  CalendarName,
  CompanyName,
  CorpsEditDate,
  CorpsName,
  CountryName,
  DocumentsName,
  FunctionName,
  IndustryName,
  InfoContactsName,
  KreiseName,
  MessagesName,
  passwordDigits,
  PostsName,
  ProfileName,
  SignUpCorpsName,
  StudyName,
} from './specificNames';

const translations = ({
  guest_naming_singular,
  guest_naming_plural,
  maximal_membership_count,
  member_naming_singular,
  support_email,
  support_phone,
}: {
  guest_naming_singular?: string;
  guest_naming_plural?: string;
  maximal_membership_count?: number;
  member_naming_singular?: string;
  support_email?: string;
  support_phone?: string;
} = {}) => [
  {
    key: 'GER',
    key2: 'de',
    editProfile: {
      updated: 'Profil aktualisiert.',
      validateFieldsViaEmail:
        'Bitte validiere die folgenden Datenfelder via E-Mail. Nur dann werden sie an die zentrale \nMitgliederverwaltung übermittelt.',
      newValue: (value: any) => `Neuer Wert: "${value}"`,
      resendLong: 'Oder sollen wir die Validierungs-Mail erneut senden?',
      resendEmail: 'Or should we resend the validation email?',
      validationPending: 'Validierung ausstehend',
      resend: 'Erneut senden',
      resendDone:
        'Die Validierungs-Mail wurde erneut gesendet. Bitte warte einige Minuten und überprüfe auch deinen Spam-Ordner.',
    },
    appInfoScreen: {
      infoSection: 'App Info',
      hasUpdate: 'Es gibt eine neuere Version. Klicke hier zum Updaten.',
      noUpdate: 'Dies ist die aktuellste Version.',
      appVerison: 'App-Version:',
      title: 'Über',
      privacy: 'Datenschutzinformationen',
      find: 'Hier finden Sie die',
      terms: ' Nutzungsbedingungen ',
      and: ' und ',
    },
    corpsScreen: {
      admins: 'Admins',
      info: 'Info',
      searchMember: 'Suche nach Mitgliedern',
      website: 'Website:',
      email: 'E-Mail-Adresse:',
      members: 'Mitglieder',
    },
    contactsScreen: {
      empty:
        'Hier sind noch keine Einträge. Gehe über das Hauptmenü auf “Suche” → “Name”, um neue Kontakte zu finden. ',
    },
    dialogPanel: {
      hangOn: 'Bierjunge anhängen?',
      ok: 'OK',
    },
    dialogs: {
      emptyTitle: 'Klicke oben rechts auf das Plus, um eine Konversation zu starten.',
    },
    documentsGallery: {
      modalTitle: 'Löschen eines Dokuments',
      modalDesc: 'Diese Datei löschen?',
      cancel: 'Abbrechen',
      delete: 'Löschen',
      deleteConfirm: 'Heruntergeladene Datei wieder löschen (nur für mich)?',
      downloadConfirm: 'Diese Datei herunterladen?',
      availableInDownloads: `\n\n*All downloaded files available in Downloads folder`,
    },
    drawerScreen: {
      backdrop: 'Es müssen erst alle Basis-Informationen ausgefüllt sein, bevor die App genutzt werden kann.',
    },
    kreiseInfoScreen: {
      creator: 'Ersteller:in dieses Gruppenchats',
      revokeAdmin: (name: string) =>
        `Möchtest du dem Admin "${name}" nur die Admin-Rechte entziehen oder ihn ganz aus der Gruppe entfernen?`,
    },
    kreisePostScreen: {
      infoParticipants: (num: string | number) => `Info & Teilnehmer (${num})`,
      subscribeTitle: 'Gruppenchat abonnieren?',
      subscribeDesc: 'Möchtest du diesen Gruppenchat abonnieren, um keine Neuigkeiten mehr zu verpassen?',
      yes: 'Ja',
      later: 'Vielleicht später',
      dontAsk: 'Für diesen Gruppenchat nicht mehr fragen',
    },
    mapScreen: {
      appUser: 'App nutzer',
      viewIsForMember: 'Das Ansehen von Nutzerprofilen ist nur für Mitglieder möglich.',
      all: 'Alle',
    },
    mentoringScreen: {
      clickMentor: 'Klicke auf den Namen des Mentors, um eine Erinnerung zu senden oder die Anfrage zurückzuziehen',
      all: 'Alle',
      startRequest: 'Anfrage starten:',
      startDesc:
        'Bitte schildere Dein Anliegen für ein Mentorat möglichst klar und präzise – dies ist Grundlage für den Mentor / die Mentorin, Deine Anfrage besser einzuschätzen und schnell zu beantworten.',
      notice: 'Hinweis:',
      noticeDesc: `Du solltest dem Mentor / der Mentorin für eine Antwort bis zu 2 Wochen Zeit geben, bevor Du eine
					Erinnerung schickst. Bevor Du einen anderen Mentor kontaktierst, ziehe bitte Deine laufende Anfrage
					zurück.`,
      withdrawRequest: 'Anfrage zurückziehen',
      reminder: 'Erinnerung senden',
      reminderSuccess: 'Erinnerung wird gesendet (+ Kopie an dich)',
      rejectRequest: 'Anfrage ablehnen',
      requestTitle: 'Mentoring-Anfrage',
      areas: 'Themenfelder',
      topics: 'Themen',
      withReguards: (name: string, lastname: string) => `\n\n\n(...)\n\nMit freundlichen Grüßen\n\n${name} ${lastname}`,
      rejectionMessage: (name: string, lastname: string) =>
        `Hallo!\n\nLeider muss ich die Mentorenfrage ablehnen. Auf Rückfrage kann ich gerne die Gründe dafür erläutern.\n\nMit freundlichen Grüßen\n\n${name} ${lastname}`,
      maxMessage: (max: number | string, topic: string) => `Du kannst max. ${max} ${topic} auswählen.`,
      decisionOpen: 'Entscheidung: offen',
      maxMentoring: 'Max. 1 Mentoring-Anfrage gestattet',
      maxParalelMentoring: 'Max. 1 parallele Mentoring Anfrage gestattet',
      openHere: 'Öffne hier das ',
      profileUpdated: 'Profil aktualisiert!',
      mentoringProfile: 'Mentoring-Profil',
      mentee: 'Mentee',
      mentor: 'Mentor',
      leaveMentoring: 'Mentoring-Gruppe verlassen',
      mentoringTitle: 'Mentoring',
      scrollAuto: 'scrollable auto tabs example',
      stopMentoringMessageMentor: (name: string, lastname: string) => `Mentorat mit ${name} ${lastname} beenden?`,
      stopMentoringMessageMentee: (name: string, lastname: string) => `Mentorat mit ${name} ${lastname} beenden?`,
      mentorEndedMentorship: (name: string, lastname: string) =>
        `Der Mentor ${name} ${lastname} hat das Mentorat beendet.`,
      mentorRejectedMentorship: (name: string, lastname: string) =>
        `Der Mentor ${name} ${lastname} hat deine Anfrage abgelehnt.`,
      menteeEndedMentorship: (name: string, lastname: string) =>
        `Der Mentee ${name} ${lastname} hat das Mentorat beendet.`,
      menteeRejectedMentorship: (name: string, lastname: string) =>
        `Der Mentee ${name} ${lastname} hat deine Anfrage abgelehnt.`,
      mentorat: 'Mentorat',
    },
    newEventScreen: {
      kreiseError: 'Bitte Zielgruppe wählen',
      correctDate: 'Datum/Zeit korrigieren',
      correctDateMessage: 'Enddatum/-zeit liegt vor Startdatum/-zeit. Bitte korrigieren.',
    },
    outRequestScreen: {
      backButton: 'Kontaktanfragen',
      requestMessage: (name: string, lastname: string) =>
        `Hallo!\n\nGerne würde ich Sie/Dich zu meinen Kontakten hinzufügen.\n\nMit freundlichen Grüßen\n\n${name} ${lastname}`,
    },
    participantsScreen: {
      interestedParties: 'Interessenten',
      removeGuestTitle: (name: string) => `${name} entfernen?`,
      removeGuestDesc: (name: string) => `“${name}” von der Teilnehmerliste entfernen?`,
      informUser: (name: string) => `“${name}” über Entfernung von der Teilnehmerliste informieren`,
    },
    partParticipantsScreen: {
      interestedParties: 'Interessenten',
      removeParticipantTitle: (name: string, lastname: string) =>
        `Teilnehmer "${name} ${lastname}" von der Teilnehmerliste entfernen?`,
      removeParticipantDesc: (name: string, lastname: string) => `"${name} ${lastname}" entfernen?`,
    },
    documentsScreen: {
      deleteTitle: 'Dateien ausgewählt:',
      cancel: 'Abbrechen',
      uploadData: 'Daten hochladen',
    },
    postsScreen: {
      ads: 'Ads...',
      oldPostsForMembers: 'Ältere Beiträge sind nur für Mitglieder einsehbar.',
    },
    profileBasisScreen: {
      editPhoto: 'Foto editieren',
    },
    profileBenachrichtigungenScreen: {
      fillData: 'Es müssen erst alle Basis-Informationen ausgefüllt sein, bevor die App genutzt werden kann.',
      changesInChat: 'Diese Einstellungen kannst du im jeweiligen Gruppenchat vornehmen.',
      commentsWarning1:
        'Die Mailbenachrichtigungen für Kommentare zu deinen eigenen Beiträgen auszustellen wird nicht empfohlen, da wichtige Kommentare so untergehen können.',
      commentsWarning2:
        'Die Mailbenachrichtigungen für private Nachrichten auszustellen wird nicht empfohlen, da wichtige Nachrichten so untergehen können.',
      messagesWarning1:
        'Die Mailbenachrichtigungen für private Nachrichten auszustellen wird nicht empfohlen, da du im Vergleich zu Gruppenchat-Beiträgen i.d.R. sehr selten private Nachrichten erhalten wirst. Wenn, dann ist es aber wichtig, weil eine Person ein konkretes Anliegen an dich hat. Und diese (wichtigen) privaten Nachrichten könnten untergehen, wenn du die Benachrichtigung abstellst. ',
      messagesWarning2:
        'Die Mailbenachrichtigungen für Kommentare zu deinen eigenen Beiträgen auszustellen wird nicht empfohlen, da wichtige Kommentare sonst untergehen können. ',
      areYouSure: 'Bist du sicher?',
      turnOff: 'Ausschalten',
      maybeLater: 'Vielleicht später',
      notRecommended: 'Nicht empfohlene Einstellung',
      ok: 'OK',
    },
    profileBerufScreen: {
      fillData: 'Es müssen erst alle Basis-Informationen ausgefüllt sein, bevor die App genutzt werden kann.',
      defaultDate: 'Bis heute',
      noArea: 'Kein Bereich',
      furtherActivity: 'Weitere Tätigkeit',
      deleteConfirm: 'Eintrag wirklich löschen?',
      ok: 'OK',
    },
    profileCorpsScreen: {
      waitingActivation: 'Warten auf Freischaltung',
      requestExistingMessage: (name: string) =>
        `Die Mitgliedschaft in "${name}" wurde beantragt. Sie werden benachrichtigt, sobald Sie freigeschaltet wurden.`,
      requestNewMessage: (name: string) =>
        `Die Erstellung von "${name}" wurde beantragt. Sie werden benachrichtigt, sobald Sie freigeschaltet wurden.`,
      createMessage: (name: string) => `"${name}" wurde erstellt und deinem Profil hinzugefügt.`,
      corpsname: 'Projektname',
      createHint: 'Es wird ein neuer Eintrag angelegt.',
      requestHint: 'Die Erstellung des neuen Eintrags wird beantragt.',
      status: 'Status',
      deleteConfirm: (name: string) => `Wollen Sie Ihre Mitgliedschaft im ${name} wirklich löschen?`,
      ok: 'OK',
      fillData: 'Es müssen erst alle Basis-Informationen ausgefüllt sein, bevor die App genutzt werden kann.',
      duplicatedCorps: (name: string) => `Das ${name} ist bereits im Profil vorhanden, bitte einmal überprüfen.`,
      missingCorpName: `Bitte den Namen des Corps angeben.`,
    },
    profileDetailsScreen: {
      email: 'E-mail',
    },
    profilePrivacyScreen: {
      fillData: 'Es müssen erst alle Basis-Informationen ausgefüllt sein, bevor die App genutzt werden kann.',
    },
    profileSonstigenScreen: {
      other: 'Sonstige (bitte eingeben)',
    },
    profileStudiumScreen: {
      deleteConfirm: 'Eintrag wirklich löschen?',
      ok: 'OK',
      fillData: 'Es müssen erst alle Basis-Informationen ausgefüllt sein, bevor die App genutzt werden kann.',
    },
    reportScreen: {
      reportUser: 'Benutzer melden',
      notMember: 'Der Benutzer ist kein Mitglied mehr',
      fakeProfile: 'Der Benutzer macht falsche Profilangeboten.',
      violations1: 'Das Profilbild des Nutzers verstößt gegen die Richtlinien',
      violations2: 'Die Äußerungen des Nutzers verstoßen gegen die Richtlilien.',
      violations3: 'Der Benutzer sendet Spam im Chat.',
      violations4: 'Melde/kennzeichne potenziell verletzende Inhalte',
      violations5: 'Andere Nutzer wegen möglicher Verstöße melden/markiere',
      reportReason: 'Gründe für die Meldung',
      sendReport: 'Senden',
      cancel: 'Abbrechen',
      showContent: 'Inhalte dieses Benutzers anzeigen',
      hideContent: 'Inhalte des Nutzers ausblenden',
    },
    resetPasswordScreen: {
      incorrectEmail: 'Die Angabe Ihrer E-Mail-Adresse ist nicht korrekt',
    },
    serverIsDownScreen: {
      serverMaintenance: 'Server maintenance',
    },
    signInScreen: {
      dontHaveAccount: 'Noch keinen Account?',
    },
    signUpScreen: {
      login: 'Login',
      emailMatchError: 'E-Mail und E-Mail-Wiederholung stimmen nicht überein.',
      passwordMatchError: 'Passwort und -wiederholung stimmen nicht überein.',
      incorrectEmail: 'Die Angabe Ihrer E-Mail-Adresse ist nicht korrekt!',
      pleaseSelect: 'Bitte auswählen',
      accountExistsTitle: 'Account vorhanden',
      accountExistsDesc: 'Sie haben bereits einen Account unter dieser Mailadresse.',
      toLogin: 'Zum Login',
    },
    subscriptionsScreen: {
      subscribed: 'Abonniert:',
      noSubscribed: 'Keine abonnierten Korporation gefunden',
      otherSubscriptions: 'Andere abonnierte Veranstalter:',
      noOtherSubscriptions: `Keine abonnierten Veranstalter gefunden`,
    },
    sucheScreen: {
      searchFor: 'Suchergebnis für:',
      filter: 'Filter:',
      name: 'Name',
      branch: 'Niederlassung',
      function: 'Funktion',
      status: 'Status',
      city: 'Stadt',
      industry: 'Branche',
      business: 'Firma',
      profession: 'Beruf',
      training: 'Aus-/Weiterbildung',
      studyProgram: 'Ausbildungs-/Studiengang',
      college: 'Ausbildungs-/Hochschule',
      cancel: 'Abbrechen',
      searchBar: 'Search BAR',
      searchTab: 'Search Tab',
      searchTitle: 'Suche',
      scrollable: 'scrollable auto tabs example',
    },
    dialogComponent: {
      close: 'Close',
    },
    phoneInputComponent: {
      noCountry: 'No country found.',
    },
    cropImageHandlerComponent: {
      cancel: 'Abbrechen',
      crop: 'Zuschneiden',
    },
    datePickerComponent: {
      date: 'Datum/Zeit',
      cancel: 'Abbrechen',
      ok: 'OK',
      day: 'Tag',
      month: 'Monat',
      year: 'Jahr',
      wrongDate: 'falsches Datum',
    },
    timePickerComponent: {
      hours: 'Stunden',
      minutes: 'Minuten',
    },
    responseHandlerComponet: {
      supportEmail: `Unbekannter Fehler. Bitte wenden Sie sich an ${support_email}`,
    },
    searchModalComponent: {
      selectionError: 'Bitte mindestens 1 Eintrag wählen.',
      maximumSelection: (num: string | number) => `Bitte maximal ${num} Einträgewahl!`,
    },
    customDropzoneComponent: {
      dropFiles: 'Drop files here',
      clickHere: ' or click here to select ',
    },
    documentsGalleryComponent: {
      clickToOpen: 'Klicken zum Öffnen',
      clickToDownload: 'Klicken Sie zum Herunterladen',
    },
    eventToolbarComponent: {
      noRegistration: 'Keine Anmeldung!',
      notLoggedId: 'Du bist noch nicht angemeldet. Dies ist nur eine Interessensbekundung.\n\n',
      registerVia: 'Die Anmeldung erfolgt über:\n',
    },
    likeComponent: {
      externalGuest: 'Externer Gast',
      invitedBy: (name: string) => `Eingeladen von ${name}`,
    },
    membersComponent: {
      interested: 'Interesse bekundet',
      loggedIn: 'Angemeldet',
      um: ' um ',
      invitedBy: (name: string) => `Eingeladen von ${name}`,
    },
    multipleSearchableInputComponent: {
      maximalMembers: `Bitte maximal ${maximal_membership_count} Mitgliedschaften angeben.`,
    },
    multipleSelectModalComponent: {
      all: 'Alle',
    },
    offlineModalComponent: {
      checkInternet: 'Bitte Internetverbindung prüfen.',
    },
    postContentComponent: {
      orginized: 'Veranstalter:',
      date: 'Datum:',
      ort: 'Ort:',
    },
    postsToolbarComponent: {
      membersOnly: 'Nur Mitglieder können Beiträge verfassen.',
    },
    zusagenModalComponent: {
      incorrectEmail: 'Die Angabe Ihrer E-Mail ist nicht korrekt',
      required: 'Dieses Feld wird benötigt.',
      name: 'Name',
      email: 'E-Mail (optional)',
      invitedBy: 'Eingeladen von',
      save: 'Speichern',
      cancel: 'Abbrechen',
      warning1: `
		  Hinweis: Bitte hier nur als ${guest_naming_singular} eintragen! Wenn du einen Account auf der App hast,
					logge dich bitte damit ein und sage offiziell als Mitglied zu. Danke!`,
      warning2: 'Mit der Eintragung stimmen Sie den ',
      and: ' und ',
      to: ' zu.',
    },
    beerSmileComponent: {
      bierjunge: 'Bierjunge!',
    },
    buttonComponent: {
      examination: 'Überprüfung:',
    },
    formInputComponent: {
      cancelConfirmation: 'Bestätigung abbrechen?',
    },
    logoComponent: {
      alt: `App Logo`,
    },
    customHistoryHook: {
      news: 'Nachricht',
    },
    global: {
      all: 'Alle',
      postSubject: 'Betreff zu deinem Beitrag',
      showMore: 'Mehr lesen',
      toRegister: 'Zur Anmeldung',
      addToCalendar: 'iCal / ICS',
      interested: 'Interessiert',
      loggedIn: 'Angemeldet',
      tilToday: 'Bis heute',
      status: 'Status',
      deletePost: 'Beitrag löschen?',
      pdf: '(PDF)',
      time: 'Time',
      birthday: (age: number | string, name: string, lastname: string) => `${age}. Geburtstag von ${name} ${lastname}`,
      eur: 'EUR',
      price: 'Preis:',
      priceForMe: 'Preis für mich:',
      ok: 'OK',
      likesCount: (num: string | number) => `Likes (${num})`,
      event: 'Veranstaltung',
      standardMessage: 'Wir generieren einen Betreff zu deinem Beitrag. Passt dieser Betreff?',
      optionalMessage:
        'Worum geht es in deinem Beitrag? Schreibe einen kurzen Betreff. Mithilfe von KI kannst du dir auch einen Vorschlag generieren lassen.',
      optionalMessageNote: 'Durch die Nutzung dieser Funktion stimmst du den ',
      chatGPTTerms: 'AGB von ChatGPT',
      chatGPTTermsLink: 'https://openai.com/policies/terms-of-use',
      optionalMessageNoteEnding: ' zu.',
      postSubjectTitle: 'Betreff zum Beitrag',
      generate: 'Generieren',
      defaultSubject: 'Standard-Betreff',
      memebership: 'Mitgliedschaft',
      applyMemebership: 'Mitgliedschaft beantragen?',
      pendingMembership: 'Mitgliedschaft beantragt - Entscheidung offen.',
      requestAlreadySent: 'Anfrage bereits gesendet',

      requestedMemebership: (kreiseName: string) => `Die Mitgliedschaft in "${kreiseName}" wurde beantragt.`,
      yes: 'Ja',
      no: 'Nein',
      change: 'Ändern',
      passwordOldMatchNewError: 'Altes und neues Passwort stimmen überein',
      passwordLenghtError: 'Das Passwort muss mindestens 6 Zeichen lang sein.',
      passwordsMatchError: 'Altes und neues Passwort stimmen überein',
      passwordsMatchError2: 'Passwort und -wiederholung stimmen nicht überein.',
      currentPassword: 'Aktuelles Passwort',
      newPassword: 'Neues Passwort',
      confirmPassword: 'Passwort wiederholen',
      changePassword: 'Passwort ändern',
      cancel: 'Abbrechen',
      fits: 'Passt',
      selectChatTitle: 'Gruppenchat auswählen',
      selectChatDesc:
        'Wähle mindestens 1 Chat aus den Favoriten aus, an den dein Beitrag geschickt werden soll. Weitere findest du im Hauptmenü unter “Gruppenchats”.',
      socialMedia: 'Social Media',
      generatedText: 'Wir generieren einen Text für Social Media. Passt dieser Text?',

      usePhoto: 'Danke für deinen Beitrag! Dürfen wir dein Foto auch für die Social Media Kanäle verwenden?',
      maximumLenght: 'Maximal 20.000 Zeichen.',
      userSharedNewPicture: 'Ein:e Nutzer:in unser App hat ein neues Bild geteilt!',
      contributionTo: (kreiseName: string) => `Beitrag an “${kreiseName || 'Alle'}”`,
      postWarning1:
        'Nur Mut! Du hast im nächsten Schritt noch die Möglichkeit, einen eigenen Text hinzuzufügen. Zudem wird dein Beitrag noch einmal geprüft.',
      postWarning2:
        'Nach dem Absenden wird dein Beitrag noch von einem Administrator überprüft. Bei Fragen wende dich gern an ',
      postWarning3: ' oder ',
      AGB: 'AGB',
      DSE: 'DSE',
      withCheckbox:
        "Mit erstmaliger Aktivierung meines Accounts über die 'Passwort vergessen' Funktionen akzeptiere ich hiermit die",
      withoutCheckbox:
        "Mit erstmaliger Aktivierung deines Accounts über die 'Passwort vergessen' Funktionen akzeptierst du die",
      tou: 'Nutzungsbedingungen',
      dse: 'DSE',
      impressum: 'Impressum',
    },

    responseHandler: {
      messageLabel: `Unbekannter Fehler. Bitte wenden Sie sich an ${support_email}`,
      expiredLinkLabel: 'Link abgelaufen. Fordern Sie eine neue Passwortänderung an.',
      emailExistLabel: 'Entschuldigung, diese E-Mail-Adresse ist bereits registriert.',
      profileUpdated: 'Profil aktualisiert',
      profileUpdatedCorpsError: `Profil aktualisiert! Das folgende ${
        CorpsName('GER').single
      } konnte jedoch aufgrund eines falschen Login-Codes nicht hinzugefügt werden:`,
      appNeedsUpdate: 'Bitte updaten Sie die App im App Store / Google Play.',
      maximalVideoSize: 'Die maximale Dateigröße beträgt 2 GB!',
    },
    report: {
      notBelongLabel: 'Der Benutzer ist kein Mitglied mehr.',
      incProfileLabel: 'Der Benutzer macht falsche Profilangeboten',
      badPicLabel: 'Das Profilbild des Nutzers verstößt gegen die Richtlinien',
      badComLabel: 'Die Äußerungen des Nutzers verstoßen gegen die Richtlilien',
      spamLabel: 'Der Benutzer sendet Spam im Chat',
      reasonsLabel: 'Gründe für die Meldung',
    },
    drawer: {
      subscription: 'Abonnements',
      news: `Neuigkeiten`,
      kreise: KreiseName('GER').multi,
      search: 'Suche',
      calendar: CalendarName('GER'),
      documents: `${DocumentsName('GER')}`,
      contacts: 'Kontakte',
      requests: 'Kontaktanfragen',
      messages: MessagesName('GER'),
      profile: ProfileName('GER'),
      logout: 'Ausloggen',
      map: 'Karte',
      mentoring: 'Mentoring',
    },
    imageCropper: {
      cropLabel: 'Zuschneiden',
      cancelLabel: 'Abbrechen',
      chooseLabel: 'Fertig',
    },
    signIn: {
      register: 'Registrieren',
      title: 'Bitte einloggen',
      loginPlaceholder: 'E-Mail-Adresse',
      passwordPlaceholder: 'Passwort',
      forgotPassword: 'Passwort vergessen?',
      loginBtn: 'Login',
      noAccount: 'Noch keinen Account?',
      description0: 'Bei Fragen können Sie sich gerne an',
      description1: 'oder',
      description2: 'wenden.',
      loginValidation: 'Die Angabe Ihrer E-Mail-Adresse ist nicht korrekt',
      passwordValidation: 'Das Passwort muss mindestens 6 Zeichen lang sein',
    },
    search: {
      name: 'Name',
      corps: CorpsName('GER').single,
      country: CountryName('GER'),
      city: 'Stadt',
      industry: IndustryName('GER'),
      company: 'Firma',
      profession: 'Beruf',
      hobby: 'Interessen',
      cultural: 'Kulturpaten',
      study: `${StudyName('GER').short}`,
      office: 'Niederlassung',
      degreeProgram: StudyName('GER').studiengang,
      university: StudyName('GER').university,
      position: `${FunctionName('GER')}`,
      status: 'Status',
      title: 'Suchergebnis für',
      filterTitle: 'Filter nach',
      postCode: 'Postleitzahl',
      global: 'Alles',
      activity: ActivityName('GER'),
    },
    resetPassword: {
      enterEmail: 'Bitte E-Mail-Adresse eingeben',
      inputPlaceholder: 'E-Mail-Adresse',
      sendBtn: 'Senden',
      cancelBtn: 'Abbrechen',
      message: 'Falls uns die E-Mail-Adresse bekannt ist, wurde dorthin ein Link geschickt.',
      desc: 'Du musst exakt die Mailadresse eingeben, welche in der App hinterlegt ist. Schaue ggf. in deinen Mails nach, welche das ist.',
    },
    password: {
      resetLabel: 'Geben Sie ein neues Passwort ein',
      inputLabel: 'Neues Passwort',
    },
    signUp: {
      createAccount: 'Account anlegen',
      inputPlaceholder: `Login Code Ihres ${CorpsName('GER').single}s`,
      sendBtn: 'Weiter',
      descFirst: 'Mit der Registrierung erklären Sie sich mit unseren ',
      descSecond: 'und bestätigen, dass Sie unsere gelesen haben',
      descThird: `Sollten Sie Fragen haben, können Sie uns gerne unter ${support_email} oder +49 170 49 805 kontaktieren.`,
      terms: 'terms and conditions',
      policy: 'privacy policy.',
      description: 'Hinweis',
      generatePassword: 'Passwort generieren',
      noCodeSuccessRegister:
        'Vielen Dank für Ihre Registrierung! Sie werden per Mail informiert, sobald Sie freigeschaltet wurden.',
    },
    signUpDefault: {
      withoutCode: 'Ohne Einladungscode',
      createAccount: 'Hier einfach registrieren',
      simpleRegister: 'Ohne Einladungscode',
      existingAccount: 'Account bereits vorhanden?',
      descr01: 'Ich stimme den',
      descr0: 'Mit der Registrierung stimmen Sie den',
      descr1: 'und der',
      descr2: 'zu.',
      guest: `Registrieren als ${guest_naming_singular}`,
      withCodeWithDescr: `Registrieren als ${member_naming_singular}`,
      withCode: 'Mit Einladungscode',
    },
    signUpManual: {
      title: 'Registrieren',
      photoDesc: 'Foto hinzufügen',
      genderInput: 'Geschlecht',
      firstName: 'Vorname',
      lastName: 'Nachname',
      club: CorpsName('GER').single,
      specify: 'bitte angeben',
      emailPlaceholder: 'E-Mail-Adresse',
      emailHint: 'Bitte langfristig genutzte Mailadresse verwenden.',
      repeatEmailPlaceholder: 'E-Mail-Adresse wiederholen',
      passwordPlaceholder: 'Passwort festlegen',
      repeatPasswordPlaceholder: 'Passwort wiederholen',
      postcode: 'Postleitzahl',
      place: 'Wohnort',
      country: 'Land',
      loginCode: 'Einladungscode',
      mobilePlaceholder: 'Mobil-Nr.',
      corps: SignUpCorpsName('GER'),
      searchCountry: 'Suche nach Land',
    },
    backToLogin: {
      desc: 'Account bereits vorhanden? ',
      login: 'Login',
    },
    backToSignUp: {
      desc: 'Noch keinen Account? ',
      register: 'Registrieren',
    },
    privacy: {
      desc: 'Ja, ich stimme den',
      btn: 'AGB',
      zu: 'zu',
      map: {
        title: 'Datenschutzhinweis',
        privacyNotice: `Diese Karte wurde DSGVO-konform implementiert. Nähere Hinweise dazu befinden sich dazu in unserer Datenschutzerklärung. `,
        additionalInfo: {
          intro: 'Die Pins auf der Karte werden',
          inaccurateDisplay: 'nur ungenau dargestellt',
          locationBasis: ', auf Basis der PLZ/Stadt aber ohne Straße/Nr.',
          noConclusion: 'Damit ist kein Rückschluss',
          onAddresses: 'auf konkrete Adressen',
          conclusion: 'der Nutzer möglich.',
        },
        agree: 'Verstanden',
        linkName: 'Datenschutzerklärung',
        options: {
          doNotShowAgain: 'Nicht erneut anzeigen',
          doNotShowMeOnMap: 'Mich nicht auf der Karte anzeigen (auch nicht nur ungefähr)',
        },
      },
      restrictions: {
        overall: 'Diese Funktion ist nur für Mitglieder zugänglich.',
        overallWilkar: 'Diese Funktion ist aus Datenschutzgründen nicht zugänglich.',
        profile:
          'Nur Mitglieder können Nutzerprofile ansehen. Du kannst dem Nutzer aber eine private Nachricht schreiben.',
        groupchat: `Die ${KreiseName('GER').single} sind nur für Mitglieder zugänglich.`,
        groupchatWilkar: `Aus Datenschutzgründen die ${KreiseName('GER').single} sind nur für Mitglieder zugänglich.`,
        comments: 'Kommentare sind nur für Mitglieder einsehbar.',
        commentsWilkar: 'Aus Datenschutzgründen kommentare sind nur für Mitglieder einsehbar.',
        likes: 'Die Liste der Likes sind nur für Mitglieder einsehbar.',
        post: 'Nur Mitglieder können Beiträge verfassen.',
        participantsList: 'Die Teilnehmerliste ist nur für Mitglieder einsehbar.',
        commentsOnlyForMembers: 'Kommentare sind nur für Mitglieder einsehbar.',
        postsLimitation: 'Ältere Beiträge sind nur für Mitglieder einsehbar.',
      },
      settings: {
        guestPost: {
          title: `Verifizierte ${guest_naming_plural} können meine Beiträge sehen`,
          ask: 'Immer fragen',
          no: 'Nein',
          yes: 'Ja',
        },
      },
      guestModal: {
        title: `Dürfen validierte ${guest_naming_plural} diesen Beitrag sehen? Du kannst diese Sichtbarkeits-Einstellung für jeden einzelnen Beitrag im Nachhinein noch ändern.`,
        dontAskAgain:
          'Nicht erneut fragen (Du kannst diese Einstellung unter “Profil” → “Privatsphäre” auch wieder ändern).',
      },
      post: {
        ifShown: `Für ${guest_naming_plural} verbergen`,
        ifHidden: `Für ${guest_naming_plural} anzeigen`,
        ifShownModal: `Der Beitrag ist jetzt für ${guest_naming_plural} nicht mehr sichtbar.`,
        ifHiddenModal: `Der Beitrag ist jetzt für ${guest_naming_plural} sichtbar.`,
      },
    },
    searchModal: {
      landTitle: 'Wähle ein Land',
      corpsTitle: `${CorpsName('GER').single} Auswählen`,
      pleaseSelect: 'Bitte wählen',
    },
    btn: {
      sendBtn: 'Senden',
      cancelBtn: 'Abbrechen',
      select: 'Auswählen',
      cancelEventBtn: acceptEventName('GER').cancel,
      acceptBtn: acceptEventName('GER').accept,
      saveBtn: 'Speichern',
      clearBtn: 'Löschen',
      acceptReqBtn: 'Annehmen',
      declineReqBtn: 'Abbrechen',
      updateBtn: 'Aktualisieren',
      declineBtn: 'Ablehnen',
      yesUnderstood: 'Ja, verstanden',
      ok: 'OK',
      cancel2: 'Absagen',
    },
    validation: {
      email: 'Die Angabe Ihrer E-Mail-Adresse ist nicht korrekt',
      password: 'Das Passwort muss mindestens 6 Zeichen lang sein',
      repeatEmail: 'E-Mail und E-Mail-Wiederholung stimmen nicht überein.',
      repeatPassword: 'Passwort und -wiederholung stimmen nicht überein',
      required: 'Dieses Feld wird benötigt',
      matchPasswords: 'Altes und neues Passwort stimmen überein',
      dontMatchPasswords: 'Passwort und -wiederholung stimmen nicht überein.',
    },
    init: {
      title: 'Wird geladen',
      overlayLabel: 'Bitte Internetverbindung prüfen',
    },
    screenTitles: {
      postsTitle: `Neuigkeiten`,
      circlesTitle: KreiseName('GER').multi,
      searchTitle: 'Suche',
      profileTitle: ProfileName('GER'),
      eventTitle: CalendarName('GER'),
      eventDetailsTitle: 'Veranstaltungen',
      newEventTitle: 'Veranstaltung hinzufügen',
      contactsTitle: 'Kontakte',
      contactReqTitle: 'Kontaktanfragen',
      reqDetailsTitle: 'Kontaktanfragen',
      outReqTitle: 'Kontaktanfragen',
      messagesTitle: MessagesName('GER'),
      changePasswordTitle: 'Passwort ändern',
      appInfoTitle: 'Über',
      reportTitle: 'Benutzer melden',
      createKreiseTitle: 'Gruppenchat erstellen',
      eventParticipants: 'Teilnehmer',
    },
    profileDetails: {
      showFilters: 'Filter anzeigen',
      user: 'Nutzer',
      mainTitleLabel: ProfileName('GER'),
      basisLabel: 'Basis',
      clubLabel: CorpsName('GER').single,
      clubLabelPlural: CorpsName('GER').multi,
      professionLabel: 'Beruf',
      studyLabel: StudyName('GER').short,
      uploadPhotoLabel: 'Foto aufnehmen',
      editPhotoLabel: 'Foto editieren',
      profileCompletedLabel: 'Profil',
      profileCompletedGLabel: 'vollständig',
      birthday: 'Geburtsdatum',
      functionLabel: `${FunctionName('GER')}`,
      dateOfJoinLabel: CorpsEditDate('GER'),
      positionDescLabel: 'Beruf',
      companyLabel: CompanyName('GER'),
      nameLabel: 'Name',
      websiteLabel: 'Webseite',
      fromLabel: 'Von',
      toLabel: 'Bis',
      delete: 'löschen',
      untilToday: 'Bis heute',
      degreeLabel: StudyName('ENG').abshchluss,
      miscellaneos: 'Sonstiges',
      hobbies: 'Interessen',
      cultural: 'Kulturpaten',
      positionLabel: 'Beruf',
      firmaLabel: 'Firma',
      industryLabel: IndustryName('GER'),
      areaLabel: 'Bereich',
      typeOfEmplLabel: 'Beschäftigungsart',
      activityLabel: ActivityName('GER'),
      careerStageLabel: 'Karrierestufe',
      activityDescLabel: 'Beschreibung der Tätigkeit',
      employmentPeriodLabel: 'Zeitraum',
      universityLabel: StudyName('GER').hochsule,
      courseLabel: StudyName('GER').studiengang,
      ageLabel: 'Alter',
      birthdayLabel: 'Geburtstag',
      editProfileLabel: 'Profil editieren',
      addContactLabel: 'Als Kontakt hinzufügen',
      sendMessageLabel: 'Nachricht senden',
      requestSentLabel: 'Kontaktanfrage bereits gesendet',
      sendRequestSuccessLabel: 'Anfrage erfolgreich gesendet',
      reportSentLabel: 'Benutzer bereits gemeldet',
      addReportLabel: 'Benutzer melden/blockieren',
      contactsLabel: 'Anzahl Kontakte',
      showBirthday: 'Kontakte sehen meinen Geburtstag',
      street: 'Straße + Nr. ',
      houseLabel: 'Hausnummer',
      houseNumberLabel: 'Hausnummer',
      partner: 'Name Partner/in',
      mobileNumberLabel: 'Mobil-Nr.',
      handphoneNumberLabel: 'Handynummer',
      telephoneNumberLabel: 'Telefonnummer',
      leibbursch: 'Leibbursch',
      leibfuechse: 'Leibfüchse',
      bio: 'Über mich',
      addDocuments: 'Dokumente zu dir hinzufügen',
      image: 'Bild',
      video: 'Video',
      pdf: 'PDF',
      link: 'Link',
      guest: 'Gast',
      firstName: 'Vorname',
      lastName: 'Nachname',
      title: 'Titel',
      email: 'E-Mail',
      name: 'Vorname',
      lastname: 'Nachname',
      country: CountryName('GER'),
      wohnort: 'Stadt',
      postcode: 'PLZ',
      inReview: 'Bestätigung via Mail ausstehend',
    },
    calendar: {
      birthdayLabel: 'Geburtstag von',
      noEventsLabel: 'Für das ausgewählte Datum gibt es keine Ereignisse',
      allTabLabel: 'Alle',
      myTabLabel: 'Meine',
      clubsTabLabel: CorpsName('GER').single,
      subsTabLabel: 'Abonnements',
      subscribedTabLabel: 'Abonnierte',
      otherTabLabel: 'Andere',

      error: 'Startdatum/-zeit muss vor Enddatum/-zeit liegen.',
      filter: {
        all: 'Alle',
        invited: 'Eingeladen',
        confirmed: 'Zugesagt',
      },
    },
    eventLabels: {
      organizer: 'Organizer',
      registrationVia: 'Anmeldung über',
      maximalParticipants: 'Maximale Teilnehmeranzahl',
      participantsWarning: 'Teilnehmerzahl ist begrenzt',
      interested: 'Interessiert',
      notInterested: 'Nicht interessiert',
      removeFromWaitList: 'Von Warteliste entfernen',
      addEventTitleImage: 'Titelbild hinzufügen',
      changeEventTitleImage: 'Titelbild ändern',
      inviteTo: 'Einladung an',
      addEndDate: 'Enddatum/-zeit eingeben',
      eventLabel: 'Veranstalter',
      startLabel: 'Beginnt um',
      locationLabel: 'Ort',
      participantLabel: 'Teilnehmer',
      moreInfo: 'Weitere Informationen',
      commentsLabel: 'Kommentare',
      noCommentsLabel: 'Noch keine Kommentare',
      eventStartLabel: 'Beginn',
      eventEndLabel: 'Ende',
      descLabel: 'Beschreibung',
      creatorLabel: 'Erstellt von',
      titleLabel: 'Titel',
      deleteEventTitle: 'Veranstaltung löschen?',
      deleteEventLabel: 'Diese Veranstaltung löschen?',
      clubsTitle: `${CorpsName('GER').single}s`,
      gruppenchat: 'Gruppenchat(s) wählen',
      contactsTitle: 'Kontakte',
      delCommentLabel: 'Kommentar löschen?',
      sendInvite: 'Einladung senden an',
      selectCorps: 'Zielgruppe auswählen',
      externalGuest: 'Eingeladene externe Gäste',
      externalGuestPlaceholder: 'Mailadresse eingeben',
      attachmentLabel: 'Anhang',
      shareLinkLabel: 'Link teilen',
      linkLabel: `${guest_naming_singular} eintragen`,
      copyLinkLabel: 'Link kopieren',
      iCalDownload: 'Kalenderdatei',
      timezone: 'Zeitzone',
      visibility: 'Sichtbarkeit',
      errorDate: 'Datum liegt in der Vergangenheit',
      startAfterEndError: 'The end date must be after the start date',
      members: 'Teilnehmer',
      waitlist: 'Warteliste',
      onWaitlist: 'auf der Warteliste',
      leaveWaitlist: 'Platz freigeben?',
      leaveWaitlistDesc: `Von Warteliste entfernen?\n\n Wenn du dich später wieder auf die Warteliste setzt, landest du ggf. auf einer hinteren Position.`,
      limitAchieved: (limit: number) =>
        `Die maximale Teilnehmeranzahl von ${limit} ist erreicht. Du wurdest auf die Warteliste gesetzt.`,
      visibilityOptions: {
        allWithoutGuests: 'Mitglieder und auf Webseite',
        invited_member: 'Nur Mitglieder',
        verified_member: 'Alle Mitglieder',
        authorized_member: 'Mitglieder und verifizierte Gäste',
        all: 'Mitglieder, Gäste und auf Webseite',
      },
      modalEvent: {
        title: 'Veranstaltung erstellt',
        subTitle: 'Die Veranstaltung wurde erstellt.\n\n',
        content:
          'Je nach Nutzereinstellungen werden nun Push-/Mailbenachrichtigungen zur Veranstaltung versendet. \n\nFalls Sie das unter “Profil” → “Benachrichtigungen” eingestellt haben, werden Sie auch selbst eine Mailbenachrichtigung zur Veranstaltung erhalten.',
      },
      modalComment: {
        title: 'Wen benachrichtigen?',
        message: 'Wer soll über deinen Kommentar informiert werden?',
        optionAll: 'Alle Teilnehmer',
        optionNone: 'Nur Organisator(en)',
        button: 'Kommentar senden',
      },
      cancelModal: {
        title: 'Absagen oder löschen?',
        message: 'Veranstaltung absagen oder löschen?',
        optionAll: 'Absagen (Teilnehmer werden informiert)',
        optionNone: 'Löschen (Teilnehmer werden nicht informiert)',
      },
      updateEventModal: {
        title: 'Weitere Einladungen verschickt',
        label: 'Die weiteren Gruppen wurden eingeladen.',
        message:
          'Je nach Einstellungen werden jetzt Push-oder Mailbenachrichtigungen zur Veranstaltung an die weiteren Gruppen versendet.',
      },
      eventRegistrationType: {
        app: 'App',
        event: 'Link/Mailadresse',
        enterLink: 'Link/Mailadresse o.ä. hinzufügen',
      },
      parts: {
        part: 'Programmpunkt',
        addPart: 'Programmpunkt hinzufügen',
        hint: 'Explanation',
        question: 'Frage',
        single: 'Nur eine Option wählbar',
        multi: 'Mehrere Optionen wählbar',
        freeText: 'Freitextantwort möglich',
        quiz: 'Quiz',
        questionnaire: 'Optionsabfrage',
        addQuestionnaire: 'Optionsabfrage hinzufügen',
        questionnaireType: 'Antwortmöglichkeiten',
        option: 'Option',
        addOption: 'Option hinzufügen',
        showMembers: 'Teilnehmer',
        youJoined: 'Du nimmst teil an:',
        totalToPay: 'Zu zahlende Gesamtsumme:',
        yourAnswer: 'Deine Antwort',
        visibleForCreators: 'Sichtbar nur für Event-Admins',
        allAnswers: 'Alle Antworten:',
        addUsers: 'Teilnehmer hinzufügen',
        isAnonym: 'Anonyme Umfrage',
        isAnonymQuiz: 'Anonymes Quiz',
      },
    },
    rrEventLabels: {
      title: 'Wiederholung',
      MO: 'Montag',
      TU: 'Dienstag',
      WE: 'Mittwoch',
      TH: 'Donnerstag',
      FR: 'Freitag',
      SA: 'Samstag',
      SU: 'Sonntag',
      YEARLY: { single: 'Jahr', regular: 'Jährlich' },
      MONTHLY: { single: 'Monat', regular: 'Monatlich' },
      WEEKLY: { single: 'Woche', regular: 'Wöchentlich' },
      DAILY: { single: 'Tag', regular: 'Täglich' },
      SINGLE: { single: 'Einmalig', regular: 'Einmalig' },
      CUSTOM: {
        single: 'Benutzerdefiniert...',
        regular: 'Benutzerdefiniert...',
      },
      freq: 'Frequenz',
      interval: 'Intervall',
      notRepeat: 'Einmalig',
      every: 'Jeden',
      month: 'monat',
      week: 'woche',
      last: 'letzten',
      on: 'am',
      everyMonthOn: 'Jeden Monat am',
      ofTheMonth: 'des Monats',
      editLabel: 'Welche Termine willst du ändern?',
      thisDate: 'Nur diesen Termin',
      thisDateAndFollowing: 'Diesen und alle folgenden',
      allDates: 'Alle Termine',
      after: 'Nach',
      time: 'Terminen',
      times: 'Terminen',
      neverEnds: 'Endet nie',
      until: 'bis zum',
      repeatedDefault: ({ frequency, freq }: { frequency: string; freq: number }) =>
        `Wird jede${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency} wiederholt`,
      repeatedMonthly: ({ day, interval }: { day: string; interval: number }) =>
        `Wird jeden${interval == 1 ? '' : ` ${interval}.`} Monat am ${day.length > 2 ? day : `${day}.`} wiederholt.`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }: { day: string; interval: number; bysetpos: number }) =>
        `Wird jeden${bysetpos == -1 ? ' letzten' : ` ${bysetpos}.`} ${day} des${
          interval === 1 ? '' : ` ${interval}.`
        } Monats wiederholt.`,

      repeatedYearly: ({ interval }: { interval: number }) =>
        `Wird jedes${interval == 1 ? '' : ` ${interval}.`} Jahr wiederholt.`,
      repeatedWeekly: ({ interval, byweekday }: { interval: number; byweekday: any }) =>
        `Wird jede${interval != 1 ? ` ${interval}.` : ''} Woche${
          !!byweekday?.length
            ? ' am ' +
              byweekday?.slice(0, -1).join(', ') +
              (byweekday?.length > 1 ? ' und ' : '') +
              byweekday?.slice(-1)
            : ''
        } wiederholt.`,
      repeatedDaily: ({ interval }: { interval: number }) =>
        `Wird jeden${interval != 1 ? ` ${interval}.` : ''} Tag wiederholt.`,
    },
    requestsLabels: {
      receiveLabel: 'Empfangen',
      sentLabel: 'Gesendet',
    },
    post: {
      adTitle: 'Information/Anzeige',
      toKreise: 'an',
      comment: 'Kommentare',
      myPost: 'Ich',
      openPdf: 'Klicken zum Öffnen',
      deletePost: 'Diesen Beitrag löschen?',
      posted: 'Gepostet',
      commented: 'Kommentiert',
      contacts: 'Kontakte',
      sendToContacts: 'Nachricht an alle',
      enterText: 'Schreibe hier eine Nachricht',
      sendToCircle: `Nachricht an ${KreiseName('GER').singleArticle}`,
      sendComment: 'Kommentar eingeben',
      longMessage: 'Maximal 20.000 Zeichen',
      noComments: 'Noch keine Kommentare',
      deleteComment: 'Kommentar löschen?',
      deleteEventPost: 'Diesen Beitrag löschen? Die Veranstaltung wird dadurch nicht gelöscht.',
      editPostLabel: 'Beitrag bearbeiten',
      editingPostLabel: 'Bearbeitungsmodus',
      deletePostLabel: 'Beitrag löschen',
      editedAt: 'Bearbeitet',
      pinned: 'Angepinnt.',
      actions: {
        label: 'Aktionen posten',
        pin: {
          label: 'Beitrag anpinnen',
          once: 'Einmalig nach oben rücken',
          d3: '3 Tage',
          d5: '5 Tage',
          w1: '1 Woche',
          w2: '2 Wochen',
          unpin: 'Nicht mehr anpinnen',
        },
      },
    },
    circles: {
      circlesTitle: `Abonnierte ${KreiseName('GER').multi}`,
      noCirclesTitle: `Nicht abonnierte ${KreiseName('GER').multi}`,
      myPost: 'Ich',
      circlesInfoTitle: 'Info & Teilnehmer',
      circlesSubscribe: 'Abonnieren',
      unsubscribeAlert: 'Geschlossenen Gruppenchat unwiderruflich verlassen?',
      continueAlert: 'Verlassen',
      readOnlyKreise: `Dies ist ein "Nur-lese-${
        KreiseName('GER').single
      }". Nur Admins können hier Beiträge erstellen. Du kannst aber Kommentare zu den Beiträgen schreiben.`,
    },
    messages: {
      addMessageLabel: 'Nachricht eingeben',
      changesSaved: 'Änderungen gespeichert!',
      newConversation: 'Neue Konversation',
    },
    profileTabs: {
      basicTab: 'Basis',
      clubsTab: `${CorpsName('GER').single}`,
      miscellaneosTab: 'Interessen',
      professionTab: 'Beruf',
      studyTab: StudyName('GER')?.short,
      notificationsTab: 'Benachrichtigungen',
      privacy: 'Privatsphäre',
    },
    profile: {
      addPhotoLabel: 'Foto hinzufügen',
      genderInputLabel: 'Geschlecht',
      titleLabel: 'Titel',
      firstNameLabel: 'Vorname',
      lastNameLabel: 'Nachname',
      passwordLabel: 'Passwort festlegen',
      birthday: 'Geburtsdatum',
      birthDateLabel: 'Geburtsdatum',
      changePasswordLabel: 'Passwort ändern',
      defaultCountryLabel: 'Deutschland',
      defaultGraduationLabel: 'Keine Angabe',
      defaultCorpsLabel: 'keine',
      street: 'Straße + Nr. ',
      houseNumberLabel: 'Hausnummer',
      partner: 'Name Partner/in',
      mobileNumberLabel: 'Mobil-Nr.',
      handphoneNumberLabel: 'Handynummer',
      telephoneNumberLabel: 'Telefonnummer',
    },
    profileKorp: {
      verband: CorpsName('GER').single,
      function: FunctionName('GER'),
      dateOfJoin: CorpsEditDate('GER'),
      beername: 'Biername',
    },
    profileClubs: {
      noUserLabel: 'Es müssen erst alle Basis-Informationen ausgefüllt sein, bevor die App genutzt werden kann.',
      functionLabel: `${FunctionName('GER')}`,
      setClubLabel: `Bitte den Namen des ${CorpsName('GER').single} angeben.`,
      matchClubLabel: 'Das',
      matchClubContinueLabel: 'ist bereits im Profil vorhanden, bitte einmal überprüfen.',
      clubLabel: `${CorpsName('GER').single.toUpperCase()}`,
      clubCodeLabel: `LOGIN CODE IHRES ${CorpsName('GER').single.toUpperCase()}S`,
      entryTimeLabel: CorpsEditDate('GER'),
      deleteClubLabel: 'Wollen Sie Ihre Mitgliedschaft im zu',
      deleteClubContinueLabel: 'wirklich löschen?',
      selectVariant: 'Was möchtest du tun?',
      requestExistingCorp: 'Existierenden Eintrag hinzufügen',
      requestNewCorps: 'Neuen Eintrag anfragen',
      createCorp: 'Neuen Eintrag anlegen',
    },
    profileProfession: {
      jobTitle: 'Berufsbezeichnung',
      noUserLabel: 'Es müssen erst alle Basis-Informationen ausgefüllt sein, bevor die App genutzt werden kann.',
      untilToday: 'Bis heute',
      positionTitleLabel: 'Beruf',
      itemNameLabel: 'Beruf',
      employmentLabel: 'Beschäftigungsart',
      activityLabel: ActivityName('GER'),
      careerStageLabel: 'Karrierestufe',
      activityDescLabel: 'Beschreibung der Tätigkeit',
      companyLabel: CompanyName('GER'),
      nameLabel: 'Name',
      industryLabel: IndustryName('GER'),
      areaLabel: 'Bereich',
      websiteLabel: 'Webseite des Unternehmens',
      employmentPeriodLabel: 'Zeitraum der Beschäftigung',
      fromLabel: 'Von',
      toLabel: 'Bis',
    },
    profileStudy: {
      noUserLabel: 'Es müssen erst alle Basis-Informationen ausgefüllt sein, bevor die App genutzt werden kann.',
      universityLabel: StudyName('GER').hochsule,
      courseLabel: StudyName('GER').studiengang,
      degreeLabel: StudyName('GER').abshchluss,
    },
    profileOther: {
      noUserLabel: 'Es müssen erst alle Basis-Informationen ausgefüllt sein, bevor die App genutzt werden kann.',
      hobbiesLabel: 'Interessen',
      culturalLabel: 'Kulturpaten',
      socialMediaPlattform: 'Social-Media-Plattform',
      socialMediaLabel: 'Social Media Links',
      socialMediaLink: 'Link',
      socialMediaError: 'Bitte vollständige URL eingeben',
    },
    profileNotifications: {
      noUserLabel: 'Es müssen erst alle Basis-Informationen ausgefüllt sein, bevor die App genutzt werden kann.',
      notificationsInfo:
        'Hier können Sie E-Mail-Benachrichtigungen für einzelne Aktivitäten aktivieren (Standard) oder für 3 Monate deaktivieren.',
      notificationsTitle: 'Push-Benachrichtigungen:',
      notificationsDescr0: 'Die Push-Benachrichtigungen können Sie in den ',
      notificationsDescr1: 'Smartphone-Systemeinstellungen',
      notificationsDescr2: ' vornehmen.',
      mailingTitle: 'E-Mail-Benachrichtigungen:',
      mailingDescr:
        'Hier können Sie die E-Mail-Benachrichtigungen für einzelne Aktivitäten aktivieren (Standard) oder für 1 Jahr deaktivieren.',
      allNotificationsLabel: 'Alle Benachrichtigungen',
      postsLabel: `Beiträge in ${KreiseName('GER').multi}`,
      messagesLabel: 'Private Nachrichten',
      commentsLabel: 'Kommentare zu eigenen Beiträgen',
      contactRequestsLabel: 'Kontaktanfragen',
      eventsLabel: 'Veranstaltungen',
      clubMemberLabel: 'Neu registrierte Mitglieder',
      enableLabel: 'An',
      disableToLabel: 'Aus bis',
      disableLabel: 'Aus',
      ownPosts: 'Meine Beiträge (Mail auch selbst erhalten)',
    },
    searchInput: {
      title: 'Suche',
      placeholder: 'Suche',
    },
    emptyList: {
      title: 'Hier sind (noch) keine Inhalte',
    },
    switchLanguage: {
      placeholder: 'Sprache/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'Russian (Русский)',
      SP: 'Spanish (Español)',
      NLD: 'Dutch (Nederlands)',
      POL: 'Polish (Polish)',
      title: 'Sprache wählen',
    },
    filePicker: {
      takePicLabel: 'Anhänge',
      uploadPicLabel: 'Foto aufnehmen',
      selectPicLabel: 'Foto/Video auswählen',
      selectDocLabel: 'Dokument auswählen',
    },
    datePicker: {
      selectDateLabel: 'Datum auswählen',
      confirmDateLabel: 'Bestätigen',
      selectTimeLabel: 'Zeit auswählen',
    },
    changePassword: {
      currentPasswordLabel: 'Aktuelles Passwort',
      newPasswordLabel: 'Neues Passwort',
      repeatPasswordLabel: 'Passwort wiederholen',
      changePasswordLabel: 'Passwort ändern',
    },
    appInfo: {
      appDescLabel:
        'Korponnect ist die App für Verbindungsstudenten. Austausch unter Korporierten, Organisation von Veranstaltungen sowie berufliches und privates Vernetzen – alles in einer App.',
      contactsLabel: InfoContactsName('GER'),
      infoLabel: 'Informationen',
      versionLabel: 'App-Version',
      agbLabel: 'AGB',
      dataProtectionLabel: 'Datenschutzerklärung',
      manualLabel: 'Anleitung',
      appTextLabel: 'Hier finden Sie unsere',
      appAndLabel: 'und eine',
      appMainTextLabel: `Sie wollen einen Kreis hinzufügen lassen oder haben eine andere Anregung? Sie wollen Ihr Profil löschen? Schreiben Sie uns einfach eine kurze E-Mail an ${support_email}.`,
      deleteFeedback: 'Grund für die Löschung / Feedback 🙂:',
      deleteUserTitle: 'Account löschen?',
      deleteUserDesc:
        'Hier können Sie Ihren Account unwiderruflich löschen.\b Übrigens: Sie können auch einfach die Benachrichtigungen ausschalten. Bearbeiten Sie die Einstellungen unter Profil -> Benachrichtigungen oder schreiben Sie uns eine E-Mail.',
      deleteUserAlert: 'Wollen Sie Ihren Account wirklich löschen?',
      webLabel: 'Web-Version',
    },
    infoMessages: {
      registartionSuccessLabel: 'Vielen Dank für die Registrierung!',
      manualRegSuccessLabel:
        'Vielen Dank für Ihre Registrierung. Sie werden in Kürze freigeschaltet und per E-Mail darüber informiert.',
      linkExpiredLabel: 'Link abgelaufen. Fordern Sie eine neue Passwortänderung an.',
      emailExistLabel: 'Sorry, die E-Mail ist nicht verfügbar.',
    },
    logoutAlert: {
      title: 'Ausloggen',
      text: 'Wollen Sie sie wirklich ausloggen? Sie müssen sich nicht ausloggen, sondern können die App einfach schließen oder minimieren.',
      confirm: 'Ja',
      dismiss: 'Abbrechen',
    },
    invalidInputs: {
      passwordChars: `Das Passwort muss mindestens ${passwordDigits(
        'GER',
      )} Zeichen enthalten, inkl. Klein-, Großbuchstaben, Ziffern und Sonderzeichen.`,
      fieldRequired: 'Dieses Feld wird benötigt.',
      emailsNotMatching: 'Bitte stellen Sie sicher, dass Ihre E-Mails übereinstimmen',
      emailInvalid: 'Die Angabe Ihrer E-Mail-Adresse ist nicht korrekt!',
      selectCorrectAnswer: 'Bitte die korrekte Antwort markieren.',
      corp: 'Bitte mindestens 1 Eintrag auswählen',
    },
    mentoring: {
      profile: {
        rules1: 'Ich stimme den',
        rules2: ' Werteverständnis ',
        rules3: '“Mentoring für Stipendiaten” zu.',
        menteeDescription:
          'Bitte wähle deine Mentoring-Region aus, sodass Du über vor-Ort-Veranstaltungen informiert werden kannst. HINWEIS: Die Auswahl kannst Du jederzeit ändern. Sie schränkt NICHT Deine Mentoren-Suche ein.',
        mentorDescription:
          'Bitte fülle Dein Mentoren-Profil aus, damit Dich Stipendiat/innen als Mentor finden können. HINWEIS: Mindestens 1 Region und min. 1 Themenfeld sind nötig.',
        mentoringRegions: 'Mentoring-Regionen',
        reigonsShort: 'Regionen',
        topics: 'Themenfelder',
        departments: 'Fachbereich',
        available: 'Ich bin verfügbar',
        unavailable: 'Ich bin derzeit nicht verfügbar',
        deleteAccount: 'Ment.-Profil löschen & Gruppe verlassen',
        deleteAccountDescr: 'Mentoring-Profil löschen und Mentoring-Gruppenchat verlassen?',
        uploadPdf: 'Mentoren-Profil (PDF)',
        myPdf: 'Mein Profil (PDF)',
        pdf: 'Mentorenprofil',
      },
      tabNames: {
        info: 'Info',
        news: 'News',
        profile: 'Profil',
        search: 'Suche & Anfrage',
        request: 'Mentorate',
        requestDetails: 'Mentoringanfrage',
        tabDeactivated: 'Tab deaktiviert. Bitte Mentoring-Profil ausfüllen.',
      },
      search: {
        description:
          'Um die Suche gezielt zu starten, empfehlen wir Dir, zunächst mit wenigen Auswahlfeldern zu beginnen und dann in einem neuen Suchlauf das Suchraster breiter anzulegen. Wenn Du in Deiner Region keinen geeigneten Mentor findest, dann weite die Suche auf eine benachbarte Mentoring-Region aus. Suchkriterien: 1 Region, 1 bis 3 Themenfelder. Alle Felder sind UND-verknüpft!',
        searchBtn: 'Suche',
      },
      requests: {
        mentorIncoming: 'Meine Mentoring-Anfragen',
        mentorCurrent: 'Meine Mentorate',
        menteeOutgoing: 'Meine Mentoring-Anfrage',
        menteeCurrent: 'Mein Mentorat',
        cancelMentoring: 'Mentorat beenden',
        sendRequest: 'Als Mentor anfragen',
        alreadyHaveMentor: 'Sie können nur einen Mentor haben',
        requestSuccess: 'Anfrage erfolgreich gesendet',
        requestRetracted: 'Anfrage erfolgreich zurückgezogen',
        cancelSendingRequest: 'Mentoring-Anfrage zurückziehen',
        approveRequest: 'Anfrage annehmen',
        rejectRequest: 'Anfrage ablehnen',
        invitaionText: 'Anfrage',
        rejectionText: 'Begründen Sie Ihre Ablehnung',
        sureRetractRequest: 'Willst du die Anfrage wirklich zurückziehen?',
      },
    },
    downloads: {
      root: 'Hauptverzeichnis',
      delete: 'Löschen',
      rename: 'Umbenennen',
      areYouSureDeleteFolder: 'Ordner wirklich löschen? Alle Inhalte werden gelöscht!',
      renameFolder: 'Ordner umbenennen',
      createNewFolder: 'Neuen Ordner erstellen',
      newFolderPlaceholder: 'Ordnernamen eingeben',
      create: 'Erstellen',
      areYouSureDeleteFile: '',
      downloadFile: 'Herunterladen',
      deleteFile: 'Vom Gerät löschen',
      openInFinder: 'Öffnen in Dateien',
      cancel: 'Abbrechen',
    },
    globalSearch: {
      postedBy: 'gepostet von',
      found: 'Gefunden',
      loadMore: 'Weitere laden',
      enterQuery: 'Gib irgendeinen Suchbegriff ein. Du kannst nach allem suchen (Name, Dokument, Event, Beitrag, …)',
      folder: 'Ordner',
      post: 'Beitrag',
      error: 'Gib mindestens 3 Zeichen ein',
    },
    kreiseSettings: {
      description: 'Beschreibung',
      push: 'Push-Benachrichtigungen',
      email: 'Mail-Benachrichtigungen',
      subscribers: 'Mitglieder',
      active: 'Aktiviert',
      oneYear: 'Stumm für 1 Jahr',
      forever: 'Stumm für immer',
      until: 'Bis',
      cantDeactive: 'Für diesen Gruppenchat ist diese Einstellung deaktiviert',
      SINGLE: 'Für jeden Beitrag',
      SUMMARY_ONCE: 'Zusammenfassung 1x pro Woche',
      SUMMARY_TWICE: 'Zusammenfassung 2x pro Woche',
      SUMMARY_DAY: 'Zusammenfassung 1x pro Tag',
      OFF: 'Aus',
      PUSH: 'Push-Benachrichtigung',
      EMAIL: 'Mail-Benachrichtigung',
      PUSH_AND_EMAIL: 'Push- und Mailbenachrichtigung',
      newUser: 'Benachrichtigung für neue Nutzer',
      statistic: 'Sende-Statistik',
      off: 'Aus',
      ownPosts: 'Sende-Statistik-Mail für deine Beiträge',
      deleteMemberDescr: 'entfernen?',
      deleteMemberBtn: 'Entfernen',
      retractAdminRights: 'Rechte entziehen',
      deleteAdminAndUser: 'Ganz entfernen',
      toDeleteKreiseBtn: 'Gruppenchat löschen',
      deleteKreiseDescr: `${KreiseName('GER').single} unwiderruflich löschen?`,
      deleteKreiseModalTitle: 'Mitglied entfernen?',
      addKreiseName: 'Name',
      addKreiseDescription: 'Beschreibung',
      addMembers: 'Mitglieder hinzufügen',
      createKreise: 'Gruppenchat erstellen',
      toKreiseEditMessage: 'Hinweis: Alle Änderungen an den Mitgliedern werden sofort umgesetzt.',
      kreiseCreator: 'Ersteller:in dieses Gruppenchats',
      kreiseDeleteMembersLeft: 'Der Gruppenchat kann erst gelöscht werden, wenn nur noch ein Mitglied darin ist.',
      kreiseDeleteMembersLeftTitle: 'Erst Mitglieder entfernen',
      suggestionInNewsfeed: {
        admin: 'Admin',
        member: 'Mitglieder',
        corp: `${CorpsName('GER').single} mitglieder`,
        corpAdmin: `${CorpsName('GER').single} admin`,
      },
    },
    surveys: {
      checkVotes: 'Ergebnisse anzeigen',
      chooseOptions: {
        single: 'Wähle eine Option aus.',
        multiple: 'Wähle mindestens eine Optionen aus.',
      },
      votesModalTitle: 'Ergebnisse',
      createModalTitle: 'Umfrage',
      noVotes: 'Es liegen keine Abstimmungen vor',
      question: 'Frage',
      askAQuestion: 'Eine Frage stellen',
      allowMultiple: 'Mehrere Antworten zulassen',
      votes: {
        single: 'Stimme',
        multiple: 'Stimmen',
      },
      option: 'Option',
      optionsLabel: 'Optionen',
      anonymus: 'Anonymus poll',
      create: 'Erstellen',
    },
    subscription: {
      subscription: 'Abonnements',
      pricePerMonth: 'EUR/Monat',

      month: 'Monat',
      day: 'Tag',
      otherPlans: 'Andere Tarife',
      subscriptionActive: 'Abonnement aktiv',
      until: 'bis',
      subscribe: 'Abonnieren',
      successfulPayment: {
        title: 'Zahlung erfolgreich',
        text: 'Dein Abonnement wurde erfolgreich abgeschlossen. Du kannst ab sofort die erweiterten Funktionen nutzen.',
      },
      cancel: {
        cancel: 'Abonnement kündigen',
        title: 'Abonnement kündigen?',
        text: 'Möchtest du dein Abonnement kündigen? Nach Ablauf der bezahlten Periode werden deine Zugriffsrechte zurückgestuft.',
      },
    },
  },
  {
    key: 'ENG',
    key2: 'en',
    // part 1
    editProfile: {
      resendLong: 'Or should we resend the validation email?',
      updated: 'Profile updated.',
      validateFieldsViaEmail:
        'Please validate the following data fields via email. Only then will they be submitted to central member management.',
      newValue: (value: any) => `New value: "${value}"`,
      resendEmail: 'Or should we resend the validation email?',
      validationPending: 'Validation pending',
      resend: 'Resend',
      resendDone: 'The validation email has been resent. Please wait a few minutes and also check your spam folder.',
    },
    appInfoScreen: {
      title: 'About',
      privacy: 'Privacy Information',
      find: 'Here you can find the',
      terms: ' Terms of Use ',
      and: ' and ',
      infoSection: 'App Info',
      hasUpdate: 'There is a newer version. Click here to update.',
      noUpdate: 'This is the latest version.',
      appVerison: 'App Version:',
    },
    corpsScreen: {
      website: 'Website:',
      email: 'Email address:',
      members: 'Members',
      admins: 'Admins',
      info: 'Info',
      searchMember: 'Search for members',
    },
    contactsScreen: {
      empty: "There are no entries here yet. Go to the main menu and select 'Search' → 'Name' to find new contacts.",
    },
    dialogPanel: {
      hangOn: 'Attach Bierjunge?',
      ok: 'OK',
    },
    dialogs: {
      emptyTitle: 'Click on the plus in the top right corner to start a conversation.',
    },
    documentsGallery: {
      modalTitle: 'Delete a document',
      modalDesc: 'Delete this file?',
      cancel: 'Cancel',
      delete: 'Delete',
      deleteConfirm: 'Delete the downloaded file again (only for me)?',
      downloadConfirm: 'Download this file?',
      availableInDownloads: `\n\n*All downloaded files are available in the Downloads folder`,
    },
    drawerScreen: {
      backdrop: 'All basic information must first be completed before using the app.',
    },
    kreiseInfoScreen: {
      creator: 'Creator of this group chat',
      revokeAdmin: (name: string) =>
        `Do you want to remove admin rights for "${name}" or remove them from the group entirely?`,
    },
    kreisePostScreen: {
      infoParticipants: (num: string | number) => `Info & Participants (${num})`,
      subscribeTitle: 'Subscribe to group chat?',
      subscribeDesc: 'Do you want to subscribe to this group chat to not miss any news?',
      yes: 'Yes',
      later: 'Maybe later',
      dontAsk: 'Don’t ask for this group chat again',
    },
    mapScreen: {
      appUser: 'App user',
      viewIsForMember: 'Viewing user profiles is only available for members.',
      all: 'All',
    },
    mentoringScreen: {
      clickMentor: "Click on the mentor's name to send a reminder or withdraw the request",
      all: 'All',
      startRequest: 'Start request:',
      startDesc:
        'Please describe your concern for mentorship as clearly and concisely as possible—this helps the mentor assess your request better and respond promptly.',
      notice: 'Note:',
      noticeDesc:
        'You should allow up to 2 weeks for the mentor to respond before sending a reminder. Withdraw your ongoing request before contacting another mentor.',
      withdrawRequest: 'Withdraw request',
      reminder: 'Send reminder',
      reminderSuccess: 'Reminder is being sent (+ copy to you)',
      rejectRequest: 'Reject request',
      requestTitle: 'Mentorship request',
      areas: 'Areas of expertise',
      topics: 'Topics',
      withReguards: (name: string, lastname: string) => `\n\n\n(...)\n\nBest regards,\n\n${name} ${lastname}`,
      rejectionMessage: (name: string, lastname: string) =>
        `Hello!\n\nUnfortunately, I must decline the mentorship request. I’m happy to explain the reasons if you ask.\n\nBest regards,\n\n${name} ${lastname}`,
      maxMessage: (max: number | string, topic: string) => `You can select a maximum of ${max} ${topic}.`,
      decisionOpen: 'Decision: open',
      maxMentoring: 'Max. 1 mentoring request allowed',
      maxParalelMentoring: 'Max. 1 parallel mentoring request allowed',
      openHere: 'Open the ',
      profileUpdated: 'Profile updated!',
      mentoringProfile: 'Mentoring Profile',
      mentee: 'Mentee',
      mentor: 'Mentor',
      leaveMentoring: 'Leave mentoring group',
      mentoringTitle: 'Mentoring',
      scrollAuto: 'scrollable auto tabs example',
      stopMentoringMessageMentor: (name: string, lastname: string) => `End mentorship with ${name} ${lastname}?`,
      stopMentoringMessageMentee: (name: string, lastname: string) => `End mentorship with ${name} ${lastname}?`,
      mentorEndedMentorship: (name: string, lastname: string) => `Mentor ${name} ${lastname} has ended the mentorship.`,
      mentorRejectedMentorship: (name: string, lastname: string) => `Mentor ${name} ${lastname} rejected your request.`,
      menteeEndedMentorship: (name: string, lastname: string) =>
        `The mentee ${name} ${lastname} has ended the mentorship.`,
      menteeRejectedMentorship: (name: string, lastname: string) =>
        `The mentee ${name} ${lastname} has declined your request.`,

      mentorat: 'Mentorship',
    },
    newEventScreen: {
      kreiseError: 'Please select a target group',
      correctDate: 'Correct date/time',
      correctDateMessage: 'End date/time is before start date/time. Please correct it.',
    },
    outRequestScreen: {
      backButton: 'Contact Requests',
      requestMessage: (name: string, lastname: string) =>
        `Hello!\n\nI would like to add you to my contacts.\n\nBest regards,\n\n${name} ${lastname}`,
    },
    participantsScreen: {
      interestedParties: 'Interested parties',
      removeGuestTitle: (name: string) => `Remove ${name}?`,
      removeGuestDesc: (name: string) => `Remove "${name}" from the participant list?`,
      informUser: (name: string) => `Inform "${name}" about being removed from the participant list`,
    },

    // part 2
    partParticipantsScreen: {
      interestedParties: 'Interested parties',
      removeParticipantTitle: (name: string, lastname: string) =>
        `Remove participant "${name} ${lastname}" from the participant list?`,
      removeParticipantDesc: (name: string, lastname: string) => `Remove "${name} ${lastname}"?`,
    },
    documentsScreen: {
      deleteTitle: 'Files selected:',
      cancel: 'Cancel',
      uploadData: 'Upload data',
    },
    postsScreen: {
      ads: 'Ads...',
      oldPostsForMembers: 'Older posts are only visible to members.',
    },
    profileBasisScreen: {
      editPhoto: 'Edit photo',
    },
    profileBenachrichtigungenScreen: {
      fillData: 'All basic information must be completed before the app can be used.',
      changesInChat: 'You can make these settings in the respective group chat.',
      commentsWarning1:
        'Disabling email notifications for comments on your own posts is not recommended, as important comments may be missed.',
      commentsWarning2:
        'Disabling email notifications for private messages is not recommended, as important messages may be missed.',
      messagesWarning1:
        'Disabling email notifications for private messages is not recommended, as you generally receive very few private messages compared to group chat posts. However, they are important because they usually involve specific requests directed at you. These (important) private messages could be missed if notifications are disabled.',
      messagesWarning2:
        'Disabling email notifications for comments on your own posts is not recommended, as important comments may be missed.',
      areYouSure: 'Are you sure?',
      turnOff: 'Turn off',
      maybeLater: 'Maybe later',
      notRecommended: 'Not recommended setting',
      ok: 'OK',
    },
    profileBerufScreen: {
      fillData: 'All basic information must be completed before the app can be used.',
      defaultDate: 'Until today',
      noArea: 'No area',
      furtherActivity: 'Additional activity',
      deleteConfirm: 'Really delete entry?',
      ok: 'OK',
    },
    profileCorpsScreen: {
      corpsname: 'Project name',
      createHint: 'A new entry is being created.',
      requestHint: 'The creation of the new entry is being requested.',
      status: 'Status',
      deleteConfirm: (name: string) => `Do you really want to delete your membership in ${name}?`,
      ok: 'OK',
      fillData: 'All basic information must be completed before the app can be used.',
      duplicatedCorps: (name: string) => `The ${name} is already in the profile, please check once.`,
      missingCorpName: `Please provide the name of the corps.`,
      waitingActivation: 'Waiting for activation',
      requestExistingMessage: (name: string) =>
        `Membership in "${name}" has been requested. You will be notified once activated.`,
      requestNewMessage: (name: string) =>
        `The creation of "${name}" has been requested. You will be notified once activated.`,
      createMessage: (name: string) => `"${name}" has been created and added to your profile.`,
    },
    profileSonstigenScreen: {
      other: 'Other (please specify)',
    },
    profileDetailsScreen: {
      email: 'E-mail',
    },
    profilePrivacyScreen: {
      fillData: 'All basic information must be completed before the app can be used.',
    },
    profileStudiumScreen: {
      deleteConfirm: 'Really delete entry?',
      ok: 'OK',
      fillData: 'All basic information must be completed before the app can be used.',
    },
    reportScreen: {
      reportUser: 'Report user',
      notMember: 'The user is no longer a member',
      fakeProfile: 'The user is making fake profile offers.',
      violations1: 'The user’s profile picture violates guidelines.',
      violations2: 'The user’s statements violate guidelines.',
      violations3: 'The user is sending spam in the chat.',
      violations4: 'Report/mark potentially offensive content',
      violations5: 'Report/mark other users for potential violations',
      reportReason: 'Reasons for reporting',
      sendReport: 'Send',
      cancel: 'Cancel',
      showContent: 'Show this user’s content',
      hideContent: 'Hide this user’s content',
    },
    resetPasswordScreen: {
      incorrectEmail: 'Your email address is incorrect.',
    },
    serverIsDownScreen: {
      serverMaintenance: 'Server maintenance',
    },
    signInScreen: {
      dontHaveAccount: "Don't have an account?",
    },
    signUpScreen: {
      login: 'Login',
      emailMatchError: 'Email and email confirmation do not match.',
      passwordMatchError: 'Password and password confirmation do not match.',
      incorrectEmail: 'Your email address is incorrect!',
      pleaseSelect: 'Please select',
      accountExistsTitle: 'Account exists',
      accountExistsDesc: 'You already have an account with this email address.',
      toLogin: 'To login',
    },
    subscriptionsScreen: {
      subscribed: 'Subscribed:',
      noSubscribed: 'No subscribed corporation found',
      otherSubscriptions: 'Other subscribed organizers:',
      noOtherSubscriptions: `No subscribed organizers found`,
    },
    sucheScreen: {
      searchFor: 'Search result for:',
      filter: 'Filter:',
      name: 'Name',
      branch: 'Branch',
      function: 'Function',
      status: 'Status',
      city: 'City',
      industry: 'Industry',
      business: 'Business',
      profession: 'Profession',
      training: 'Training',
      studyProgram: 'Study program',
      college: 'College',
      cancel: 'Cancel',
      searchBar: 'Search BAR',
      searchTab: 'Search Tab',
      searchTitle: 'Search',
      scrollable: 'Scrollable auto tabs example',
    },
    dialogComponent: {
      close: 'Close',
    },
    phoneInputComponent: {
      noCountry: 'No country found.',
    },
    cropImageHandlerComponent: {
      cancel: 'Cancel',
      crop: 'Crop',
    },
    datePickerComponent: {
      cancel: 'Cancel',
      ok: 'OK',
      date: 'Date/Time',
      day: 'Day',
      month: 'Month',
      year: 'Year',
      wrongDate: 'wrong date',
    },
    timePickerComponent: {
      hours: 'Hours',
      minutes: 'Minutes',
    },
    responseHandlerComponet: {
      supportEmail: `Unknown error. Please contact ${support_email}`,
    },
    searchModalComponent: {
      selectionError: 'Please select at least one entry.',
      maximumSelection: (num: string | number) => `Please select a maximum of ${num} entries!`,
    },
    customDropzoneComponent: {
      dropFiles: 'Drop files here',
      clickHere: ' or click here to select ',
    },
    documentsGalleryComponent: {
      clickToOpen: 'Click to open',
      clickToDownload: 'Click to download',
    },
    eventToolbarComponent: {
      noRegistration: 'No registration!',
      notLoggedId: 'You are not logged in yet. This is just an expression of interest.',
      registerVia: 'Registration is done via:',
    },
    likeComponent: {
      externalGuest: 'External Guest',
      invitedBy: (name: string) => `Invited by ${name}`,
    },
    membersComponent: {
      interested: 'Expressed interest',
      loggedIn: 'Logged in',
      um: ' at ',
      invitedBy: (name: string) => `Invited by ${name}`,
    },
    multipleSearchableInputComponent: {
      maximalMembers: `Please specify a maximum of ${maximal_membership_count} memberships.`,
    },
    multipleSelectModalComponent: {
      all: 'All',
    },
    offlineModalComponent: {
      checkInternet: 'Please check your internet connection.',
    },
    postsToolbarComponent: {
      membersOnly: 'Only members can post.',
    },
    postContentComponent: {
      orginized: 'Organizer:',
      date: 'Date:',
      ort: 'Location:',
    },
    beerSmileComponent: {
      bierjunge: 'Beer Buddy!',
    },
    buttonComponent: {
      examination: 'Examination:',
    },
    zusagenModalComponent: {
      incorrectEmail: 'Your email is incorrect',
      required: 'This field is required.',
      name: 'Name',
      email: 'E-mail (optional)',
      invitedBy: 'Invited by',
      save: 'Save',
      cancel: 'Cancel',
      warning1: `
		  Note: Please only register here as a ${guest_naming_singular}! 
		  If you have an account on the app, please log in with it and confirm officially as a member. Thank you!`,
      warning2: 'By registering, you agree to the ',
      and: ' and ',
      to: '.',
    },
    formInputComponent: {
      cancelConfirmation: 'Cancel confirmation?',
    },
    logoComponent: {
      alt: `App Logo`,
    },
    customHistoryHook: {
      news: 'Message',
    },
    // part 3
    global: {
      all: 'All',
      postSubject: 'Subject of your post',
      showMore: 'Read more',
      toRegister: 'To registration',
      addToCalendar: 'iCal / ICS',
      interested: 'Interested',
      loggedIn: 'Logged in',
      tilToday: 'Until today',
      status: 'Status',
      deletePost: 'Delete post?',
      pdf: '(PDF)',
      time: 'Time',
      birthday: (age: number | string, name: string, lastname: string) => `${age}th birthday of ${name} ${lastname}`,
      eur: 'EUR',
      price: 'Price',
      priceForMe: 'Price for me:',
      ok: 'OK',
      likesCount: (num: string | number) => `Likes (${num})`,
      event: 'Event',
      standardMessage: 'We are generating a subject for your post. Does this subject fit?',
      optionalMessage:
        'What is your post about? Write a short subject. You can also let AI generate a suggestion for you.',
      optionalMessageNote: 'By using this feature, you agree to the ',
      chatGPTTerms: 'ChatGPT Terms of Use',
      chatGPTTermsLink: 'https://openai.com/policies/terms-of-use',
      optionalMessageNoteEnding: '.',
      postSubjectTitle: 'Post Subject',
      generate: 'Generate',
      defaultSubject: 'Default Subject',
      memebership: 'Membership',
      applyMemebership: 'Apply for membership?',
      pendingMembership: 'Membership application submitted - decision pending.',
      requestAlreadySent: 'Request already sent',
      requestedMemebership: (kreiseName: string) => `Membership in "${kreiseName}" has been requested.`,
      yes: 'Yes',
      no: 'No',
      change: 'Change',
      passwordOldMatchNewError: 'Old and new passwords match',
      passwordLenghtError: 'Password must be at least 6 characters long.',
      passwordsMatchError: 'Old and new passwords match',
      passwordsMatchError2: 'Password and confirmation do not match.',
      currentPassword: 'Current Password',
      newPassword: 'New Password',
      confirmPassword: 'Confirm Password',
      changePassword: 'Change Password',
      cancel: 'Cancel',
      fits: 'Fits',
      selectChatTitle: 'Select group chat',
      selectChatDesc:
        "Select at least 1 favorite chat to send your post to. You can find more in the main menu under 'Group Chats'.",
      socialMedia: 'Social Media',
      generatedText: 'We are generating text for social media. Does this text fit?',
      usePhoto: 'Thanks for your post! Can we also use your photo for social media channels?',
      maximumLenght: 'Maximum 20,000 characters.',
      userSharedNewPicture: 'A user of our app has shared a new picture!',
      contributionTo: (kreiseName: string) => `Contribution to “${kreiseName || 'All'}”`,
      postWarning1:
        'Go ahead! You will still have the opportunity to add your own text in the next step. Additionally, your post will be reviewed.',
      postWarning2:
        'After submission, your post will be reviewed by an administrator. For questions, feel free to contact ',
      postWarning3: ' or ',
      AGB: 'Terms and Conditions',
      DSE: 'Privacy Policy',
      withCheckbox: "By initially activating my account using the 'Forgot Password' feature, I hereby accept the",
      withoutCheckbox: "By initially activating your account using the 'Forgot Password' feature, you accept the",
      tou: 'Terms of Use',
      dse: 'Privacy Policy',
      impressum: 'Legal Notice',
    },

    responseHandler: {
      messageLabel: `Unknown error. Please contact ${support_email}.`,
      expiredLinkLabel: 'Link expired. Request a new password change.',
      emailExistLabel: 'Sorry, this email address is already registered.',
      profileUpdated: 'Profile updated',
      profileUpdatedCorpsError: `Profile updated! However, the following ${
        CorpsName('ENG').single
      } could not be added due to an incorrect login code:`,
      appNeedsUpdate: 'Please update the app in the App Store / Google Play.',
      maximalVideoSize: 'The maximum file size is 2 GB!',
    },
    report: {
      notBelongLabel: `The user no longer belongs to a ${CorpsName('ENG').single}`,
      incProfileLabel: 'The user makes incorrect profile offers',
      badPicLabel: "The user's profile picture violates the guidelines",
      badComLabel: "The user's comments violate the guidelines",
      spamLabel: 'The user sends spam in the chat',
      reasonsLabel: 'Reasons for reporting',
    },
    drawer: {
      subscription: 'Subscription',
      news: 'News',
      kreise: KreiseName('ENG').multi,
      search: 'Search',
      calendar: CalendarName('ENG'),
      contacts: 'Contacts',
      documents: `${DocumentsName('ENG')}`,
      requests: 'Requests',
      messages: MessagesName('ENG'),
      profile: ProfileName('ENG'),
      logout: 'Logout',
      map: 'Map',
      mentoring: 'Mentoring',
    },
    imageCropper: {
      cropLabel: 'Crop',
      cancelLabel: 'Cancel',
      chooseLabel: 'Choose',
    },
    signIn: {
      register: 'Register',
      title: 'Please log in',
      loginPlaceholder: 'Mail adress',
      passwordPlaceholder: 'Password',
      forgotPassword: 'Forgot password?',
      loginBtn: 'Login',
      noAccount: 'No account yet?',
      description0: 'If you have any questions, please feel free to contact us at',
      description1: 'or',
      description2: '.',
      loginValidation: 'Your email address is incorrect',
      passwordValidation: 'The password must be at least 6 characters long',
    },
    search: {
      name: 'Name',
      corps: CorpsName('ENG').single,
      country: CountryName('ENG'),
      city: 'City',
      industry: IndustryName('ENG'),
      company: CompanyName('ENG'),
      profession: 'Profession',
      hobby: 'Interests',
      cultural: 'Cultural sponsor',
      study: 'Study',
      office: 'Office',
      degreeProgram: 'Degree program',
      university: 'University',
      position: FunctionName('ENG'),
      status: 'Status',
      title: 'Search result for',
      filterTitle: 'Filter by',
      postCode: 'Post code',
      global: 'All',
      activity: ActivityName('ENG'),
    },
    resetPassword: {
      enterEmail: 'Please enter your e-mail adress',
      inputPlaceholder: 'Mail adress',
      sendBtn: 'Send',
      cancelBtn: 'Cancel',
      message: 'If the e-mail address is known to us, a link was sent to it.',
      desc: 'You must enter the exact email address that is stored in the app. If necessary, check your mails to see which one it is. ',
    },
    password: {
      resetLabel: 'Enter a new password',
      inputLabel: 'Enter new password',
    },
    signUp: {
      createAccount: 'Create account',
      inputPlaceholder: `Login code of your ${CorpsName('ENG').single}'s`,
      sendBtn: 'Continue',
      descFirst: 'By registering you agree to our',
      descSecond: 'and confirm that you have read our',
      descThird: `If you have any questions, please feel free to contact us at ${support_email} or ${support_phone}.`,
      terms: 'terms and conditions ',
      policy: 'privacy policy.',
      description: 'Note',
      generatePassword: 'Generate password',
      noCodeSuccessRegister:
        'Thank you for your registration! You will be informed by mail as soon as you have been activated.',
    },
    signUpDefault: {
      withoutCode: 'Without Code',
      createAccount: 'Simply register here',
      simpleRegister: 'Without Invitation Code',
      existingAccount: 'Account already exists?',
      descr01: 'I agree with the',
      descr0: 'By registering you agree to our',
      descr1: '(general terms) and confirm that you have read our',
      descr2: '(data privacy policy).',
      guest: 'Register as Guest',
      withCodeWithDescr: `Register as ${member_naming_singular}`,
      withCode: 'With Code',
    },
    signUpManual: {
      title: 'Registration',
      photoDesc: 'Add profile photo',
      genderInput: 'Gender',
      firstName: 'First name(s)',
      lastName: 'Last name',
      club: CorpsName('ENG').single,
      specify: 'please specify',
      emailPlaceholder: 'E-mail adress',
      emailHint: 'Please use long term used mail address.',
      repeatEmailPlaceholder: 'Repeat E-mail adress',
      passwordPlaceholder: 'Set password',
      repeatPasswordPlaceholder: 'Repeat password',
      postcode: 'Postcode',
      place: 'Place of residence',
      country: 'Country',
      loginCode: 'Invitation code',
      mobilePlaceholder: 'Mobile (not visible in profile)',
      corps: SignUpCorpsName('ENG'),
      searchCountry: 'Search for country',
    },
    backToLogin: {
      desc: 'You already have an account? ',
      login: 'Login',
    },
    backToSignUp: {
      desc: 'No account yet? ',
      register: 'Register',
    },
    privacy: {
      desc: 'Yes, I accept the',
      btn: 'general terms and conditions',
      zu: '',
      map: {
        title: 'Privacy Notice',
        privacyNotice: `This map has been implemented in compliance with GDPR. Further information can be found in our privacy policy.`,
        additionalInfo: {
          intro: 'The pins on the map are',
          inaccurateDisplay: 'only displayed inaccurately',
          locationBasis: ', based on the postcode/city but without street/no.',
          noConclusion: 'This means that no conclusions',
          onAddresses: 'can be drawn about specific user addresses',
          conclusion: '.',
        },
        agree: 'Understood',
        linkName: 'Privacy Policy',
        options: {
          doNotShowAgain: 'Do not show again',
          doNotShowMeOnMap: 'Do not show me on the map (even approximately)',
        },
      },
      restrictions: {
        overall: 'This function is only accessible to members.',
        overallWilkar: 'This function is not accessible for data protection reasons.',

        profile: 'Only members can view user profiles. However, you can write a private message to users.',
        groupchat: `The ${KreiseName('ENG').single} are only accessible to members.`,
        groupchatWilkar: `For data protection reasons, the ${KreiseName('ENG').single} are only accessible to members.`,
        comments: 'Comments are only visible to members.',
        commentsWilkar: 'For data protection reasons, comments are only visible to members.',
        likes: 'The list of likes can only be viewed by members.',
        post: 'Only members can post articles.',
        participantsList: 'The list of participants can only be viewed by members.',
        commentsOnlyForMembers: 'Comments are only visible to members.',
        postsLimitation: 'Older posts can only be viewed by members.',
      },
      settings: {
        guestPost: {
          title: `Verified ${guest_naming_plural} can see my posts`,
          ask: 'Always ask',
          no: 'No',
          yes: 'Yes',
        },
      },
      guestModal: {
        title: 'Can guests see this post? You can also change this setting afterwards.',
        dontAskAgain: 'Do not ask again (you can also change this setting under "Profile" → "Privacy").',
      },
      post: {
        ifShown: `Hide for ${guest_naming_plural}`,
        ifHidden: `Show for ${guest_naming_plural}`,
        ifShownModal: `The post is now no longer visible for ${guest_naming_plural}.`,
        ifHiddenModal: `The post is now visible for ${guest_naming_plural}.`,
      },
    },
    searchModal: {
      landTitle: 'Choose a country',
      corpsTitle: `Select a ${CorpsName('ENG').single}`,
      pleaseSelect: 'Please select',
    },
    btn: {
      sendBtn: 'Send',
      cancelBtn: 'Cancel',
      select: 'Select',
      cancelEventBtn: acceptEventName('ENG').cancel,
      acceptBtn: acceptEventName('ENG').accept,
      saveBtn: 'Save',
      clearBtn: 'Delete',
      acceptReqBtn: 'Accept',
      declineReqBtn: 'Decline',
      updateBtn: 'Update',
      declineBtn: 'Decline',
      yesUnderstood: 'Ja, verstanden',

      ok: 'OK',
      cancel2: 'Cancel',
    },
    validation: {
      email: 'Your email address is incorrect',
      password: 'The password must be at least 6 characters long',
      repeatEmail: 'Email and retry email do not match',
      repeatPassword: 'Password and repeat do not match',
      required: 'This field is required',
      matchPasswords: 'Old and new password match',
      dontMatchPasswords: 'Password and repeat do not match',
    },
    init: {
      title: 'Loading',
      overlayLabel: 'Please check your internet connection',
    },
    screenTitles: {
      postsTitle: 'News',
      circlesTitle: KreiseName('ENG').multi,
      searchTitle: 'Search',
      profileTitle: ProfileName('ENG'),
      eventTitle: CalendarName('ENG'),
      eventDetailsTitle: 'Event',
      newEventTitle: 'Add Event',
      contactsTitle: 'Contacts',
      contactReqTitle: 'Contact requests',
      reqDetailsTitle: 'Request details',
      outReqTitle: 'Contact request',
      messagesTitle: MessagesName('ENG'),
      changePasswordTitle: 'Change password',
      appInfoTitle: 'Info',
      reportTitle: 'Report user',
      createKreiseTitle: 'Create group chat',
      eventParticipants: 'Participants',
    },
    profileDetails: {
      showFilters: 'Show filters',
      user: 'Users',
      mainTitleLabel: ProfileName('ENG'),
      basisLabel: 'Basic',
      clubLabel: CorpsName('ENG').single,
      clubLabelPlural: CorpsName('ENG').multi,
      professionLabel: 'Profession',
      studyLabel: StudyName('ENG').short,
      uploadPhotoLabel: 'Upload photo',
      editPhotoLabel: 'Edit photo',
      profileCompletedLabel: 'Profile completed to',
      profileCompletedGLabel: '',
      birthday: 'Date of Birth',
      functionLabel: 'Function',
      dateOfJoinLabel: 'Date of Loin',
      positionDescLabel: 'Profession',
      companyLabel: CompanyName('ENG'),
      nameLabel: 'Name',
      websiteLabel: 'Website',
      fromLabel: 'From',
      toLabel: 'To',
      delete: 'delete',
      untilToday: 'Until today',
      degreeLabel: StudyName('ENG').abshchluss,
      miscellaneos: 'Miscellaneos',
      hobbies: 'Interests',
      cultural: 'Cultural sponsors',
      positionLabel: 'Position',
      typeOfEmplLabel: 'Type of employment',
      activityLabel: ActivityName('ENG'),
      careerStageLabel: 'Career stage',
      activityDescLabel: 'Description of the activity',
      industryLabel: IndustryName('ENG'),
      areaLabel: 'Area',
      employmentPeriodLabel: 'Period of employment',
      universityLabel: 'University',
      courseLabel: 'Course of studies',
      firmaLabel: 'Firma',

      ageLabel: 'Age',
      birthdayLabel: 'Birthday',
      editProfileLabel: 'Edit profile',
      addContactLabel: 'Add to contacts',
      sendMessageLabel: 'Send message',
      requestSentLabel: 'Send friend request',
      sendRequestSuccessLabel: 'Request sent successfully',
      reportSentLabel: 'User already reported',
      addReportLabel: 'Send report',
      contactsLabel: 'Contacts',
      showBirthday: 'Contacts see my birthday',
      street: 'Street + Nr. ',
      houseLabel: 'House number',
      houseNumberLabel: 'House number',
      partner: 'Partner name',
      mobileNumberLabel: 'Mobile number',
      handphoneNumberLabel: 'Cell phone number',
      telephoneNumberLabel: 'Phone number',
      leibbursch: 'Mentor',
      leibfuechse: 'Mentee',

      bio: 'About me',
      addDocuments: 'Add documents',
      image: 'Bild',
      video: 'Video',
      pdf: 'PDF',
      link: 'Link',
      guest: 'Guest',
      firstName: 'First name',
      lastName: 'Surname',
      title: 'Title',
      email: 'E-Mail',
      name: 'First name',
      lastname: 'Surname',
      country: CountryName('ENG'),
      wohnort: 'City',
      postcode: 'ZIP CODE',
      inReview: 'Confirmation via mail pending',
    },
    calendar: {
      birthdayLabel: 'Birthday of',
      noEventsLabel: 'There are no events for the selected date',
      allTabLabel: 'All',
      myTabLabel: 'My events',
      clubsTabLabel: `${CorpsName('ENG').single}`,
      subsTabLabel: 'Subscriptions',
      subscribedTabLabel: 'Subscribed',
      otherTabLabel: 'Others',

      error: 'Start date/time must be before end date/time.',
      filter: {
        all: 'All',
        invited: 'Invited',
        confirmed: 'Confirmed',
      },
    },
    eventLabels: {
      organizer: 'Organizer',
      registrationVia: 'Registration via',
      maximalParticipants: 'Maximum number of participants',
      participantsWarning: 'Number of participants is limited',
      interested: 'Interested',
      notInterested: 'Not interested',
      removeFromWaitList: 'Remove from waiting list',
      addEventTitleImage: 'Add title picture',
      changeEventTitleImage: 'Change title picture',
      inviteTo: 'Invite to',
      addEndDate: 'Enter end date/time',
      eventLabel: 'Event',
      startLabel: 'Starts at',
      locationLabel: 'Location',
      participantLabel: 'Participants',
      moreInfo: 'More information',
      commentsLabel: 'Comments',
      noCommentsLabel: 'No comments yet',
      eventStartLabel: 'Start',
      eventEndLabel: 'End',
      descLabel: 'Description',
      creatorLabel: 'Created by',
      titleLabel: 'Title',
      deleteEventTitle: 'Delete event?',
      deleteEventLabel: 'Delete this event?',
      clubsTitle: CorpsName('ENG').single,
      gruppenchat: 'Select group(s) chat',
      contactsTitle: 'Contacts',
      delCommentLabel: 'Delete this comment?',
      sendInvite: 'Send invitation to',
      selectCorps: 'Select target audience',
      externalGuest: 'Invite external guests',
      externalGuestPlaceholder: 'Enter mail address',
      attachmentLabel: 'Attachment',
      shareLinkLabel: 'Share link',
      linkLabel: 'Register Guest',
      copyLinkLabel: 'Copy link',
      iCalDownload: 'Calendar file',
      timezone: 'Time zone',
      visibility: 'Visibility',
      errorDate: 'Date is in the past',
      startAfterEndError: 'The end date must be after the start date',
      members: 'Members',
      waitlist: 'Waitlist',
      onWaitlist: 'on the waitlist',
      leaveWaitlist: 'Leave waitlist?',
      leaveWaitlistDesc: `Remove from waitlist?\n\n If you join the waitlist again later, you may be placed at the back of the line.`,
      limitAchieved: (limit: number) =>
        `The maximum number of participants of ${limit} has been reached. You have been placed on the waiting list.`,
      visibilityOptions: {
        allWithoutGuests: 'Members and on Website',
        invited_member: 'Invited members only',
        verified_member: 'All members',
        authorized_member: 'All members and guests',
        all: 'Members, guests and on website',
      },
      modalEvent: {
        title: 'Event created',
        subTitle: 'The event was created.\n\n',
        content:
          'Depending on the user settings, push/mail notifications will now be sent for the event. \n\nIf you have set this under "Profile" → "Notifications", you will also receive a mail notification about the event yourself.',
      },
      modalComment: {
        title: 'Notify whom?',
        message: 'Who should be informed about your comment?',
        optionAll: 'All participants',
        optionNone: 'Organizer(s) only',
        button: 'Send comment',
      },
      cancelModal: {
        title: 'Cancel or delete?',
        message: 'Cancel or delete event?',
        optionAll: 'Cancel (participants will be informed)',
        optionNone: 'Delete (attendees will not be informed)',
      },
      updateEventModal: {
        title: 'Further invitations sent out',
        label: 'The other groups were invited.',
        message:
          'Depending on the settings, push notifications or email notifications about the event are now sent to the other groups.',
      },
      eventRegistrationType: {
        app: 'App',
        event: 'Link/Email Address',
        enterLink: 'Add link/mail address or similar',
      },
      parts: {
        part: 'Part',
        addPart: 'Add program point',
        hint: 'Explanation',
        question: 'Question',
        single: 'Only one option selectable',
        multi: 'Several options available',
        freeText: 'Free text answer',
        quiz: 'Quiz',
        questionnaire: 'Questionnaire',
        addQuestionnaire: 'Add Questionnaire',
        questionnaireType: 'Answer options',
        option: 'Option',
        addOption: 'Add option',
        showMembers: 'Participants',
        youJoined: 'You take part in:',
        totalToPay: 'Total amount to be paid:',

        yourAnswer: 'Your answer',
        visibleForCreators: 'Visible only for creators',
        allAnswers: 'All answers:',
        addUsers: 'Add users',
        isAnonym: 'Anonymous voting',
        isAnonymQuiz: 'Anonymes Quiz',
      },
    },
    rrEventLabels: {
      title: 'Repetition',
      MO: 'Mondat',
      TU: 'Tuesday',
      WE: 'Wednesday',
      TH: 'Thursday',
      FR: 'Friday',
      SA: 'Saturday',
      SU: 'Sunday',
      YEARLY: { single: 'Year', regular: 'Annually' },
      MONTHLY: { single: 'Month', regular: 'Monthly' },
      WEEKLY: { single: 'Week', regular: 'Weekly' },
      DAILY: { single: 'Day', regular: 'Daily' },
      SINGLE: { single: 'Unique', regular: 'Unique' },
      CUSTOM: {
        single: 'User-defined...',
        regular: 'User-defined...',
      },
      freq: 'Frequency',
      interval: 'Interval',
      notRepeat: 'Unique',
      every: 'Every',
      month: 'month',
      week: 'week',
      last: 'last',
      on: 'on',
      everyMonthOn: 'Every month on',
      ofTheMonth: 'of the month',
      editLabel: 'Which dates do you want to change?',
      thisDate: 'This date only',
      thisDateAndFollowing: 'This and all subsequent',
      allDates: 'All dates',
      after: 'After',
      time: 'time',
      times: 'times',
      neverEnds: 'Never ends',
      until: 'until the',
      repeatedDefault: ({ frequency, freq }: { frequency: string; freq: number }) =>
        `Is repeated every${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency}`,
      repeatedMonthly: ({ day, interval }: { day: string; interval: number }) =>
        `Is repeated every${interval == 1 ? '' : ` ${interval}.`} month on ${day.length > 2 ? day : `${day}.`}`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }: { day: string; interval: number; bysetpos: number }) =>
        `Is repeated every ${bysetpos == -1 ? ' last' : `${bysetpos}.`} ${day} of ${
          interval === 1 ? '' : `${interval}.`
        } month`,
      repeatedYearly: ({ interval }: { interval: number }) =>
        `Is repeated every${interval == 1 ? '' : ` ${interval}.`} year`,
      repeatedWeekly: ({ interval, byweekday }: { interval: number; byweekday: any }) =>
        `Is repeated every${interval != 1 ? ` ${interval}.` : ''} week${
          !!byweekday?.length
            ? ' on ' +
              byweekday?.slice(0, -1).join(', ') +
              (byweekday?.length > 1 ? ' and ' : '') +
              byweekday?.slice(-1)
            : ''
        }`,
      repeatedDaily: ({ interval }: { interval: number }) =>
        `Is repeated every${interval != 1 ? ` ${interval}.` : ''} day`,
    },
    requestsLabels: {
      receiveLabel: 'Received',
      sentLabel: 'Sent',
    },
    post: {
      adTitle: 'Information/Advertisement',
      toKreise: 'to',
      comment: 'Comments',
      myPost: 'Me',
      openPdf: 'Click to open',
      deletePost: 'Delete this post?',
      posted: 'Posted',
      commented: 'Commented',
      contacts: 'Contacts',
      sendToContacts: 'Message to all',
      enterText: 'Write a message here',
      sendToCircle: `Enter message in this ${KreiseName('ENG').single}`,
      sendComment: 'Enter comment',
      longMessage: '20,000 characters maximum',
      noComments: 'No comments yet',
      deleteComment: 'Delete comment?',
      deleteEventPost: 'Delete this post? This will not delete the event.',
      editPostLabel: 'Edit post',
      editingPostLabel: 'Editing mode',
      deletePostLabel: 'Delete post',
      editedAt: 'Edited',
      pinned: 'Pinned',
      actions: {
        label: 'Post actions',
        pin: {
          label: 'Pin post',
          once: 'Move up once',
          d3: '3 days',
          d5: '5 days',
          w1: '1 week',
          w2: '2 weeks',
          unpin: 'Unpin',
        },
      },
    },
    circles: {
      circlesTitle: `Subscribed ${KreiseName('ENG').multi}`,
      noCirclesTitle: `Unsubscribed ${KreiseName('ENG').multi}`,
      myPost: 'Me',
      circlesInfoTitle: 'Info & Members',
      circlesSubscribe: 'Subscribe',
      unsubscribeAlert: 'Leave closed group chat irrevocably?',
      continueAlert: 'Leave',
      readOnlyKreise: `This is a "read-only ${
        KreiseName('ENG').single
      }". Only admins can post here. You can post comments on the posts though.`,
    },
    messages: {
      addMessageLabel: 'Enter message',
      changesSaved: 'Changes saved!',
      newConversation: 'New conversation',
    },
    profileTabs: {
      basicTab: 'Basic',
      clubsTab: CorpsName('ENG').single,
      miscellaneosTab: 'Interests',
      professionTab: 'Profession',
      studyTab: StudyName('ENG')?.short,
      notificationsTab: 'Notifications',
      privacy: 'Privacy',
    },
    profile: {
      addPhotoLabel: 'Add profile photo',
      genderInputLabel: 'Gender',
      titleLabel: 'Title',
      firstNameLabel: 'First name(s)',
      lastNameLabel: 'Last name',

      passwordLabel: 'Set password',
      birthDateLabel: 'Date of birth',
      birthday: 'Date of birth',
      changePasswordLabel: 'Change password',
      defaultCountryLabel: 'Germany',
      defaultGraduationLabel: 'No information',
    },
    profileKorp: {
      verband: CorpsName('ENG').single,
      function: FunctionName('ENG'),
      dateOfJoin: CorpsEditDate('ENG'),
      beername: 'Beer name',
    },
    profileClubs: {
      noUserLabel: 'All basic information must be filled in before the app can be used.',
      functionLabel: 'Function',
      setClubLabel: `Please indicate the name of the ${CorpsName('ENG').single}.`,
      matchClubLabel: 'The',
      matchClubContinueLabel: 'already exists in the profile, please check again.',
      clubLabel: CorpsName('ENG').single.toUpperCase(),
      clubCodeLabel: `LOGIN CODE OF YOUR ${CorpsName('ENG').single.toUpperCase()}`,
      entryTimeLabel: CorpsEditDate('ENG'),
      deleteClubLabel: 'Are you sure you want to delete your membership in',
      deleteClubContinueLabel: '?',
      selectVariant: 'Select variant',
      requestExistingCorp: 'Request existing corp',
      requestNewCorps: 'Request new corps',
      createCorp: 'Create',
    },
    profileProfession: {
      jobTitle: 'Job title',
      noUserLabel: 'All basic information must be filled in before the app can be used.',
      untilToday: 'Until today',
      positionTitleLabel: 'Position',
      itemNameLabel: 'Profession',
      employmentLabel: 'Employment type',
      activityLabel: ActivityName('ENG'),
      careerStageLabel: 'Career stage',
      activityDescLabel: 'Description of the activity',
      companyLabel: CompanyName('ENG'),
      nameLabel: 'Name',
      industryLabel: IndustryName('ENG'),
      areaLabel: 'Area',
      websiteLabel: 'Website',
      employmentPeriodLabel: 'Period of employment',
      fromLabel: 'From',
      toLabel: 'To',
    },
    profileStudy: {
      noUserLabel: 'All basic information must be filled in before the app can be used.',
      universityLabel: StudyName('ENG').hochsule,
      courseLabel: StudyName('ENG').studiengang,
      degreeLabel: StudyName('ENG').abshchluss,
    },
    profileOther: {
      noUserLabel: 'All basic information must be filled in before the app can be used.',
      hobbiesLabel: 'Interests',
      culturalLabel: 'Cultural sponsors',
      socialMediaPlattform: 'Social-Media-Plattform',
      socialMediaLabel: 'Social Media Links',
      socialMediaLink: 'Link',
      socialMediaError: 'Please enter full URL',
    },
    profileNotifications: {
      noUserLabel: 'All basic information must be filled in before the app can be used.',
      notificationsTitle: 'Push notifications:',
      notificationsDescr0: 'You can set the push notifications in the ',
      notificationsDescr1: 'smartphone system settings.',
      notificationsDescr2: '',
      mailingTitle: 'Email notifications:',
      mailingDescr: 'Activate email notifications for individual activities (default) or deactivate them for 1 year.',
      allNotificationsLabel: 'All notifications',
      postsLabel: `Posts in ${KreiseName('ENG').multi}`,
      messagesLabel: 'Private messages',
      commentsLabel: 'Comments on own contributions',
      contactRequestsLabel: 'Contact requests',
      eventsLabel: 'Events',
      clubMemberLabel: 'New registered members',
      enableLabel: 'On',
      disableToLabel: 'Off until',
      disableLabel: 'Off',
      ownPosts: 'My contributions (mail also received by myself)',
    },
    searchInput: {
      title: 'Search',
      placeholder: 'Search',
    },
    emptyList: {
      title: 'Here are no contents (yet)',
    },
    switchLanguage: {
      placeholder: 'Language/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'Russian (Русский)',
      SP: 'Spanish (Español)',
      NLD: 'Dutch (Nederlands)',
      POL: 'Polish (Polish)',
      title: 'Choose language',
    },
    filePicker: {
      takePicLabel: 'Attachments',
      uploadPicLabel: 'Upload photo',
      selectPicLabel: 'Select photo or video',
      selectDocLabel: 'Select document',
    },
    datePicker: {
      selectDateLabel: 'Select date',
      confirmDateLabel: 'Confirm date',
      selectTimeLabel: 'Select time',
    },
    changePassword: {
      currentPasswordLabel: 'Current password',
      newPasswordLabel: 'New password',
      repeatPasswordLabel: 'Repeat password',
      changePasswordLabel: 'Change password',
    },
    appInfo: {
      appDescLabel:
        'Korponnect is the app for fraternity students. Exchange among corporates, organization of events as well as professional and private networking - all in one app.',
      contactsLabel: InfoContactsName('ENG'),
      infoLabel: 'Information',
      versionLabel: 'App Version',
      agbLabel: 'AGB',
      dataProtectionLabel: 'Data protection declaration',
      manualLabel: 'Manual',
      appTextLabel: 'You can find us here',
      appAndLabel: 'and a',
      appMainTextLabel: `Do you want to add a circle or do you have another suggestion? Do you want to delete your profile? Just write us a short email to ${support_email}.`,
      deleteFeedback: ' Reason for account deletion / feedback 🙂:',
      deleteUserTitle: 'Delete account?',
      deleteUserDesc:
        'Here you can irrevocably delete your account.\b By the way: You can also simply turn off the notifications. Edit the settings under profile -> Notifications or write us an email. ',
      deleteUserAlert: 'All your data will be deleted forever, continue?',
      webLabel: 'Web-Version',
    },
    infoMessages: {
      registartionSuccessLabel: 'Thank you for registering!',
      manualRegSuccessLabel: 'Thank you for registering! The profile will be activated shortly.',
      linkExpiredLabel: 'Link expired. Request a new password change.',
      emailExistLabel: 'Sorry, the email is not available.',
    },
    logoutAlert: {
      title: 'Logout',
      text: "Do you really want to log them out? You don't have to log out, you can simply close or minimize the app.",
      confirm: 'Yes',
      dismiss: 'No',
    },
    invalidInputs: {
      passwordChars: `Password must contain minimum ${passwordDigits(
        'ENG',
      )} characters, at least one letter, one number and one special character.`,
      fieldRequired: 'This field is required.',
      emailsNotMatching: 'Please make sure that your emails match',
      emailInvalid: 'The information of your e-mail address is not correct!',
      selectCorrectAnswer: 'You need to select correct answer',
      corp: 'Please select at least 1 entry',
    },
    mentoring: {
      profile: {
        rules1: 'I agree with the',
        rules2: ' Understanding of values ',
        rules3: '"Mentoring for Scholars" to.',
        menteeDescription:
          'Please select your mentoring region so that you can be informed about on-site events. NOTE: You can change your selection at any time. It does NOT limit your mentor search.',
        mentorDescription:
          'Please fill out your mentor profile so that scholarship holders can find you as a mentor. NOTE: At least 1 region and min. 1 subject area are required.',
        mentoringRegions: 'Mentoring Regions',
        reigonsShort: 'Regions',
        topics: 'Topics',
        departments: 'Department',
        available: 'I am available',
        unavailable: 'I am currently not available',
        deleteAccount: 'Delete ment. profile & leave group',
        deleteAccountDescr: 'Delete mentor profile and leave mentor group chat?',
        uploadPdf: 'Mentor profile (PDF)',
        myPdf: 'My profile (PDF)',
        pdf: 'Mentor Profile',
      },
      tabNames: {
        info: 'Info',
        news: 'News',
        profile: 'Profile',
        search: 'Search & Request',
        request: 'Mentorates',
        requestDetails: 'Mentoring request',
        tabDeactivated: 'Tab deactivated. Please fill out mentoring profile.',
      },
      search: {
        description:
          'To start a targeted search, we recommend that you begin with a few selection fields and then broaden the search grid in a new search run. If you do not find a suitable mentor in your region, then expand the search to a neighboring mentoring region. Search criteria: 1 region, 1 to 3 topic fields',
        searchBtn: 'Search',
      },
      requests: {
        mentorIncoming: 'My mentoring requests',
        mentorCurrent: 'My mentorships',
        menteeOutgoing: 'My mentoring request',
        menteeCurrent: 'My mentorship',
        cancelMentoring: 'End mentorship',
        sendRequest: 'Request as mentor',
        alreadyHaveMentor: 'You can have only one mentor',
        requestSuccess: 'Request sent successfully',
        requestRetracted: 'Request withdrawn successfully',
        cancelSendingRequest: 'Cancel mentoring request',
        approveRequest: 'Accept request',
        rejectRequest: 'Reject mentee',
        invitaionText: 'Request',
        rejectionText: 'Give reasons for your rejection',
        sureRetractRequest: 'Really withdraw request?',
      },
    },
    downloads: {
      root: 'Home',
      delete: 'Delete',
      rename: 'Rename',
      areYouSureDeleteFolder: 'Delete folder really? All contents will be deleted!',
      renameFolder: 'Rename folder',
      createNewFolder: 'Create new folder',
      newFolderPlaceholder: 'Enter folder name',
      create: 'Create',
      areYouSureDeleteFile: '',
      downloadFile: 'Download',
      deleteFile: 'Delete from device',
      openInFinder: 'Open in Files',
      cancel: 'Cancel',
    },
    globalSearch: {
      postedBy: 'posted by',
      found: 'Found',
      loadMore: 'Load more',
      enterQuery: 'Enter any search term. You can search for anything (name, document, event, post, ...)',
      folder: 'Folder',
      post: 'Post',
      error: 'Enter at least 3 characters',
    },
    kreiseSettings: {
      description: 'Description',
      push: 'Push notifications',
      email: 'Mail notifications',
      subscribers: 'Members',
      active: 'Activated',
      oneYear: 'Silent for 1 year',
      forever: 'Silent forever',
      until: 'Until',
      cantDeactive: 'This setting is deactivated for this group chat',
      SINGLE: 'For each contribution',
      SUMMARY_ONCE: 'Summary 1x per week',
      SUMMARY_TWICE: 'Summary 2x per week',
      SUMMARY_DAY: 'Summary 1x per day',
      OFF: 'Off',
      PUSH: 'Push notification',
      EMAIL: 'Mail notification',
      PUSH_AND_EMAIL: 'Push and email notifications',
      newUser: 'Notification for new users',
      statistic: 'Send statistics',
      off: 'Off',
      ownPosts: 'Send statistics mail for your contributions',
      deleteMemberDescr: 'Remove?',
      deleteMemberBtn: 'Remove',
      retractAdminRights: 'Withdraw rights',
      deleteAdminAndUser: 'Remove completely',
      toDeleteKreiseBtn: `Delete ${KreiseName('ENG').single}`,
      deleteKreiseDescr: `Delete irrevocably ${KreiseName('ENG').single}?`,
      deleteKreiseModalTitle: 'Remove member?',
      addKreiseName: 'Name',
      addKreiseDescription: 'Description',
      addMembers: 'Add members',
      createKreise: `Create ${KreiseName('ENG').single}`,
      toKreiseEditMessage: 'Note: All changes to the members will be implemented immediately.',
      kreiseCreator: 'Creator:in this group chat',
      kreiseDeleteMembersLeft: `${KreiseName('ENG').single} can be deleted only if there is only one member left`,
      kreiseDeleteMembersLeftTitle: 'Remove members first',
      suggestionInNewsfeed: {
        admin: 'Admin',
        member: 'Members',
        corp: `${CorpsName('ENG').single} member`,
        corpAdmin: `${CorpsName('ENG').single} admin`,
      },
    },
    surveys: {
      checkVotes: 'View results',
      chooseOptions: {
        single: 'Choose one option',
        multiple: 'Choose multiple options',
      },
      votesModalTitle: 'Votes',
      createModalTitle: 'Poll',
      noVotes: 'There are no votes',
      question: 'Question',
      askAQuestion: 'Ask a question',
      allowMultiple: 'Allow multiple answers',
      votes: {
        single: 'Vote',
        multiple: 'Votes',
      },
      option: 'Option',
      optionsLabel: 'Poll options',
      anonymus: 'Anonymus poll',
      create: 'Create',
    },
    subscription: {
      subscription: 'Subscription',
      pricePerMonth: 'EUR/Monat',
      month: 'Month',
      day: 'Day',
      otherPlans: 'Other plans',
      subscriptionActive: 'Subscription active',
      until: 'until',
      subscribe: 'Subscribe',
      successfulPayment: {
        title: 'Zahlung erfolgreich',
        text: 'Dein Abonnement wurde erfolgreich abgeschlossen. Du kannst ab sofort die erweiterten Funktionen nutzen.',
      },
      cancel: {
        cancel: 'Abonnement kündigen',
        title: 'Abonnement kündigen?',
        text: 'Möchtest du dein Abonnement kündigen? Nach Ablauf der bezahlten Periode werden deine Zugriffsrechte zurückgestuft.',
      },
    },
  },
  {
    key: 'ITA',
    key2: 'it',
    editProfile: {
      updated: 'Profilo aggiornato.',
      validateFieldsViaEmail:
        'Per favore, valida i seguenti campi dati via email. Solo allora verranno inviati alla gestione centrale dei membri.',
      newValue: (value: any) => `Nuovo valore: "${value}"`,
      resendEmail: "O vuoi che inviamo di nuovo l'email di convalida?",
      validationPending: 'Convalida in sospeso',
      resend: 'Invia di nuovo',
      resendDone:
        "L'email di convalida è stata inviata di nuovo. Per favore, aspetta qualche minuto e controlla anche la tua cartella spam.",
      resendLong: "O vuoi che inviamo di nuovo l'email di convalida?",
    },
    appInfoScreen: {
      title: 'Informazioni',
      privacy: 'Informazioni sulla privacy',
      find: 'Qui puoi trovare i',
      terms: ' Termini di utilizzo ',
      and: ' e ',
      infoSection: "Informazioni sull'app",
      hasUpdate: 'È disponibile una nuova versione. Clicca qui per aggiornare.',
      noUpdate: 'Questa è la versione più recente.',
      appVerison: "Versione dell'app:",
    },
    corpsScreen: {
      website: 'Sito web:',
      email: 'Indirizzo email:',
      members: 'Membri',
      admins: 'Amministratori',
      info: 'Informazioni',
      searchMember: 'Cerca membri',
    },
    contactsScreen: {
      empty:
        "Non ci sono ancora voci qui. Vai nel menu principale e seleziona 'Cerca' → 'Nome' per trovare nuovi contatti.",
    },
    dialogPanel: {
      hangOn: 'Allegare Bierjunge?',
      ok: 'OK',
    },
    dialogs: {
      emptyTitle: "Clicca sul più nell'angolo in alto a destra per iniziare una conversazione.",
    },
    documentsGallery: {
      modalTitle: 'Eliminare un documento',
      modalDesc: 'Eliminare questo file?',
      cancel: 'Annulla',
      delete: 'Elimina',
      deleteConfirm: 'Eliminare il file scaricato (solo per me)?',
      downloadConfirm: 'Scaricare questo file?',
      availableInDownloads: `\n\n*Tutti i file scaricati sono disponibili nella cartella Downloads`,
    },
    drawerScreen: {
      backdrop: "Tutte le informazioni di base devono essere completate prima di utilizzare l'app.",
    },
    kreiseInfoScreen: {
      creator: 'Creatore di questa chat di gruppo',
      revokeAdmin: (name: string) =>
        `Vuoi rimuovere i diritti di amministratore per "${name}" o rimuoverlo completamente dal gruppo?`,
    },
    kreisePostScreen: {
      infoParticipants: (num: string | number) => `Info & Partecipanti (${num})`,
      subscribeTitle: 'Iscriviti alla chat di gruppo?',
      subscribeDesc: 'Vuoi iscriverti a questa chat di gruppo per non perdere nessuna novità?',
      yes: 'Sì',
      later: 'Forse più tardi',
      dontAsk: 'Non chiedere più per questa chat di gruppo',
    },
    mapScreen: {
      appUser: "Utente dell'app",
      viewIsForMember: 'Visualizzare i profili utente è disponibile solo per i membri.',
      all: 'Tutti',
    },
    mentoringScreen: {
      clickMentor: 'Clicca sul nome del mentore per inviare un promemoria o ritirare la richiesta',
      all: 'Tutti',
      startRequest: 'Inizia richiesta:',
      startDesc:
        'Descrivi il tuo bisogno di mentorship in modo chiaro e conciso—questo aiuta il mentore a valutare meglio la tua richiesta e rispondere prontamente.',
      notice: 'Nota:',
      noticeDesc:
        'Dovresti concedere fino a 2 settimane per la risposta del mentore prima di inviare un promemoria. Ritira la tua richiesta in corso prima di contattare un altro mentore.',
      withdrawRequest: 'Ritirare richiesta',
      reminder: 'Invia promemoria',
      reminderSuccess: 'Promemoria inviato (+ copia a te)',
      rejectRequest: 'Rifiutare richiesta',
      requestTitle: 'Richiesta di mentorship',
      areas: 'Aree di competenza',
      topics: 'Argomenti',
      withReguards: (name: string, lastname: string) => `\n\n\n(...)\n\nCordiali saluti,\n\n${name} ${lastname}`,
      rejectionMessage: (name: string, lastname: string) =>
        `Ciao!\n\nPurtroppo devo rifiutare la richiesta di mentorship. Sono felice di spiegare i motivi se lo chiedi.\n\nCordiali saluti,\n\n${name} ${lastname}`,
      maxMessage: (max: number | string, topic: string) => `Puoi selezionare un massimo di ${max} ${topic}.`,
      decisionOpen: 'Decisione: aperta',
      maxMentoring: 'Massimo 1 richiesta di mentorship consentita',
      maxParalelMentoring: 'Massimo 1 richiesta di mentorship parallela consentita',
      openHere: 'Apri il ',
      profileUpdated: 'Profilo aggiornato!',
      mentoringProfile: 'Profilo di mentorship',
      mentee: 'Mentee',
      mentor: 'Mentore',
      leaveMentoring: 'Lascia il gruppo di mentoring',
      mentoringTitle: 'Mentoring',
      scrollAuto: 'esempio di schede auto-scroll',
      stopMentoringMessageMentor: (name: string, lastname: string) => `Terminare il mentoring con ${name} ${lastname}?`,
      stopMentoringMessageMentee: (name: string, lastname: string) => `Terminare il mentoring con ${name} ${lastname}?`,
      mentorEndedMentorship: (name: string, lastname: string) =>
        `Il mentore ${name} ${lastname} ha terminato il mentoring.`,
      mentorRejectedMentorship: (name: string, lastname: string) =>
        `Il mentore ${name} ${lastname} ha rifiutato la tua richiesta.`,
      menteeEndedMentorship: (name: string, lastname: string) =>
        `Il mentee ${name} ${lastname} ha concluso il mentorship.`,
      menteeRejectedMentorship: (name: string, lastname: string) =>
        `Il mentee ${name} ${lastname} ha rifiutato la tua richiesta.`,
      mentorat: 'Mentoring',
    },
    participantsScreen: {
      interestedParties: 'Partecipanti interessati',
      removeGuestTitle: (name: string) => `Rimuovere ${name}?`,
      removeGuestDesc: (name: string) => `Rimuovere "${name}" dalla lista dei partecipanti?`,
      informUser: (name: string) => `Informare "${name}" della sua rimozione dalla lista dei partecipanti`,
    },
    partParticipantsScreen: {
      interestedParties: 'Parti interessate',
      removeParticipantTitle: (name: string, lastname: string) =>
        `Rimuovere il partecipante "${name} ${lastname}" dalla lista dei partecipanti?`,
      removeParticipantDesc: (name: string, lastname: string) => `Rimuovere "${name} ${lastname}"?`,
    },
    documentsScreen: {
      deleteTitle: 'File selezionati:',
      cancel: 'Annulla',
      uploadData: 'Carica dati',
    },
    postsScreen: {
      ads: 'Annunci...',
      oldPostsForMembers: 'I post più vecchi sono visibili solo ai membri.',
    },
    profileBasisScreen: {
      editPhoto: 'Modifica foto',
    },
    profileBenachrichtigungenScreen: {
      fillData: "Tutte le informazioni di base devono essere completate prima che l'app possa essere utilizzata.",
      changesInChat: 'Puoi fare queste impostazioni nel rispettivo gruppo di chat.',
      commentsWarning1:
        'Disattivare le notifiche via email per i commenti sui tuoi post non è consigliato, in quanto potrebbero essere persi commenti importanti.',
      commentsWarning2:
        'Disattivare le notifiche via email per i messaggi privati non è consigliato, in quanto potrebbero essere persi messaggi importanti.',
      messagesWarning1:
        'Disattivare le notifiche via email per i messaggi privati non è consigliato, poiché generalmente ricevi pochi messaggi privati rispetto ai post nei gruppi di chat. Tuttavia, questi sono importanti poiché di solito coinvolgono richieste specifiche dirette a te. Questi (importanti) messaggi privati potrebbero essere persi se le notifiche sono disattivate.',
      messagesWarning2:
        'Disattivare le notifiche via email per i commenti sui tuoi post non è consigliato, in quanto potrebbero essere persi commenti importanti.',
      areYouSure: 'Sei sicuro?',
      turnOff: 'Disattiva',
      maybeLater: 'Forse più tardi',
      notRecommended: 'Impostazione non consigliata',
      ok: 'OK',
    },
    profileBerufScreen: {
      fillData: "Tutte le informazioni di base devono essere completate prima che l'app possa essere utilizzata.",
      defaultDate: 'Fino ad oggi',
      noArea: 'Nessuna area',
      furtherActivity: 'Attività aggiuntiva',
      deleteConfirm: 'Sei sicuro di voler eliminare questa voce?',
      ok: 'OK',
    },
    newEventScreen: {
      kreiseError: 'Seleziona un gruppo target',
      correctDate: 'Correggi data/ora',
      correctDateMessage: 'La data/ora di fine è precedente a quella di inizio. Per favore correggi.',
    },
    outRequestScreen: {
      backButton: 'Richieste di contatto',
      requestMessage: (name: string, lastname: string) =>
        `Ciao!\n\nMi piacerebbe aggiungerti ai miei contatti.\n\nCordiali saluti\n\n${name} ${lastname}`,
    },
    profileCorpsScreen: {
      corpsname: 'Nome del progetto',
      createHint: 'Una nuova voce è in fase di creazione.',
      requestHint: 'La creazione della nuova voce è in fase di richiesta.',
      status: 'Stato',
      deleteConfirm: (name: string) => `Vuoi davvero eliminare la tua adesione a ${name}?`,
      ok: 'OK',
      fillData: "Tutte le informazioni di base devono essere completate prima che l'app possa essere utilizzata.",
      duplicatedCorps: (name: string) => `Il ${name} è già nel profilo, verifica per favore.`,
      missingCorpName: 'Per favore, fornisci il nome del corpo.',
      waitingActivation: 'In attesa di attivazione',
      requestExistingMessage: (name: string) =>
        `È stata richiesta l\'adesione a "${name}". Riceverai una notifica non appena sarai attivato.`,
      requestNewMessage: (name: string) =>
        `È stata richiesta la creazione di "${name}". Riceverai una notifica non appena sarà attivato.`,
      createMessage: (name: string) => `"${name}" è stato creato e aggiunto al tuo profilo.`,
    },
    profileSonstigenScreen: {
      other: 'Altro (per favore specifica)',
    },
    profileDetailsScreen: {
      email: 'E-mail',
    },
    profilePrivacyScreen: {
      fillData: "Tutte le informazioni di base devono essere completate prima che l'app possa essere utilizzata.",
    },
    profileStudiumScreen: {
      deleteConfirm: 'Sei sicuro di voler eliminare questa voce?',
      ok: 'OK',
      fillData: "Tutte le informazioni di base devono essere completate prima che l'app possa essere utilizzata.",
    },
    reportScreen: {
      reportUser: 'Segnala utente',
      notMember: "L'utente non è più un membro",
      fakeProfile: "L'utente sta creando offerte di profili falsi.",
      violations1: "La foto del profilo dell'utente viola le linee guida.",
      violations2: "Le dichiarazioni dell'utente violano le linee guida.",
      violations3: "L'utente sta inviando spam nella chat.",
      violations4: 'Segnala/consegna contenuti potenzialmente offensivi',
      violations5: 'Segnala/marca altri utenti per potenziali violazioni',
      reportReason: 'Motivi della segnalazione',
      sendReport: 'Invia',
      cancel: 'Annulla',
      showContent: 'Mostra contenuti di questo utente',
      hideContent: 'Nascondi contenuti di questo utente',
    },
    resetPasswordScreen: {
      incorrectEmail: "L'indirizzo email è errato.",
    },
    serverIsDownScreen: {
      serverMaintenance: 'Manutenzione del server',
    },
    signInScreen: {
      dontHaveAccount: 'Non hai un account?',
    },
    signUpScreen: {
      login: 'Accedi',
      emailMatchError: "L'email e la conferma dell'email non corrispondono.",
      passwordMatchError: 'La password e la conferma della password non corrispondono.',
      incorrectEmail: 'Il tuo indirizzo email è errato!',
      pleaseSelect: 'Seleziona per favore',
      accountExistsTitle: 'Account esistente',
      accountExistsDesc: 'Hai già un account con questo indirizzo email.',
      toLogin: 'Per accedere',
    },
    subscriptionsScreen: {
      subscribed: 'Iscritto:',
      noSubscribed: 'Nessuna corporazione iscritta trovata',
      otherSubscriptions: 'Altri organizzatori iscritti:',
      noOtherSubscriptions: `Nessun organizzatore iscritto trovato`,
    },
    sucheScreen: {
      searchFor: 'Risultato della ricerca per:',
      filter: 'Filtra:',
      name: 'Nome',
      branch: 'Settore',
      function: 'Funzione',
      status: 'Stato',
      city: 'Città',
      industry: 'Industria',
      business: 'Attività',
      profession: 'Professione',
      training: 'Formazione',
      studyProgram: 'Programma di studi',
      college: 'Università',
      cancel: 'Annulla',
      searchBar: 'Barra di ricerca',
      searchTab: 'Tab di ricerca',
      searchTitle: 'Cerca',
      scrollable: 'Esempio di tab auto-scrollabile',
    },
    dialogComponent: {
      close: 'Chiudi',
    },
    phoneInputComponent: {
      noCountry: 'Nessun paese trovato.',
    },
    cropImageHandlerComponent: {
      cancel: 'Annulla',
      crop: 'Ritaglia',
    },
    datePickerComponent: {
      cancel: 'Annulla',
      ok: 'OK',
      date: 'Data/Ora',
      day: 'Giorno',
      month: 'Mese',
      year: 'Anno',
      wrongDate: 'Data errata',
    },
    timePickerComponent: {
      hours: 'Ore',
      minutes: 'Minuti',
    },
    responseHandlerComponet: {
      supportEmail: 'Errore sconosciuto. Per favore contatta ${support_email}',
    },
    searchModalComponent: {
      selectionError: 'Seleziona almeno una voce.',
      maximumSelection: (num: string | number) => `Seleziona massimo ${num} elementi!`,
    },
    customDropzoneComponent: {
      dropFiles: 'Trascina i file qui',
      clickHere: ' o clicca qui per selezionare ',
    },
    documentsGalleryComponent: {
      clickToOpen: 'Clicca per aprire',
      clickToDownload: 'Clicca per scaricare',
    },
    eventToolbarComponent: {
      noRegistration: 'Nessuna registrazione!',
      notLoggedId: 'Non sei ancora loggato. Questo è solo un interesse preliminare.',
      registerVia: 'La registrazione avviene tramite:',
    },
    likeComponent: {
      externalGuest: 'Ospite esterno',
      invitedBy: (name: string) => `Invitato da ${name}`,
    },
    postContentComponent: {
      orginized: 'Organizzatore:',
      date: 'Data:',
      ort: 'Luogo:',
    },
    beerSmileComponent: {
      bierjunge: 'Bierjunge!',
    },
    buttonComponent: {
      examination: 'Verifica:',
    },
    membersComponent: {
      interested: 'Interesse espresso',
      loggedIn: 'Accesso effettuato',
      um: ' a ',
      invitedBy: (name: string) => `Invitato da ${name}`,
    },
    multipleSearchableInputComponent: {
      maximalMembers: 'Per favore, specifica un massimo di ${maximal_membership_count} membri.',
    },
    multipleSelectModalComponent: {
      all: 'Tutti',
    },
    offlineModalComponent: {
      checkInternet: 'Controlla la connessione a Internet.',
    },
    postsToolbarComponent: {
      membersOnly: 'Solo i membri possono pubblicare.',
    },
    zusagenModalComponent: {
      incorrectEmail: 'La tua email è errata',
      required: 'Questo campo è obbligatorio.',
      name: 'Nome',
      email: 'E-mail (opzionale)',
      invitedBy: 'Invitato da',
      save: 'Salva',
      cancel: 'Annulla',
      warning1:
        "Nota: Si prega di registrarsi qui solo come ${guest_naming_singular}! Se hai un account nell'app, accedi con esso e conferma ufficialmente come membro. Grazie!",
      warning2: 'Registrandoti, accetti i ',
      and: ' e ',
      to: '.',
    },
    formInputComponent: {
      cancelConfirmation: 'Conferma annullamento?',
    },
    logoComponent: {
      alt: "Logo dell'app",
    },
    customHistoryHook: {
      news: 'Messaggio',
    },
    global: {
      all: 'Tutti',
      postSubject: 'Oggetto del tuo post',
      showMore: 'Leggi di più',
      toRegister: 'Per registrarsi',
      addToCalendar: 'iCal / ICS',
      interested: 'Interessato',
      loggedIn: 'Connesso',
      tilToday: 'Fino ad oggi',
      status: 'Stato',
      deletePost: 'Eliminare il post?',
      pdf: '(PDF)',
      time: 'Tempo',
      birthday: (age: number | string, name: string, lastname: string) => `${age}° compleanno di ${name} ${lastname}`,
      eur: 'EUR',
      price: 'Prezzo:',
      priceForMe: 'Prezzo per me:',
      ok: 'OK',
      likesCount: (num: string | number) => `Mi piace (${num})`,
      event: 'Evento',
      standardMessage: 'Stiamo generando un oggetto per il tuo post. Questo oggetto va bene?',
      optionalMessage:
        "Di cosa tratta il tuo post? Scrivi un breve oggetto. Puoi anche far generare un suggerimento tramite l'IA.",
      optionalMessageNote: 'Usando questa funzionalità, accetti i ',
      chatGPTTerms: 'Termini di utilizzo di ChatGPT',
      chatGPTTermsLink: 'https://openai.com/policies/terms-of-use',
      optionalMessageNoteEnding: '.',
      postSubjectTitle: 'Oggetto del post',
      generate: 'Genera',
      defaultSubject: 'Oggetto predefinito',
      memebership: 'Membresia',
      applyMemebership: 'Richiedere la membership?',
      pendingMembership: 'Richiesta di membership inviata - decisione in sospeso.',
      requestAlreadySent: 'Richiesta già inviata',
      requestedMemebership: (kreiseName: string) => `La membership in "${kreiseName}" è stata richiesta.`,
      yes: 'Sì',
      no: 'No',
      change: 'Modificare',
      passwordOldMatchNewError: 'La vecchia e la nuova password corrispondono',
      passwordLenghtError: 'La password deve essere lunga almeno 6 caratteri.',
      passwordsMatchError: 'La vecchia e la nuova password corrispondono',
      passwordsMatchError2: 'La password e la conferma non corrispondono.',
      currentPassword: 'Password attuale',
      newPassword: 'Nuova password',
      confirmPassword: 'Conferma password',
      changePassword: 'Cambia password',
      cancel: 'Annulla',
      fits: 'Va bene',
      selectChatTitle: 'Seleziona chat di gruppo',
      selectChatDesc:
        "Seleziona almeno 1 chat tra i preferiti a cui inviare il tuo post. Puoi trovarne altre nel menu principale sotto 'Chat di gruppo'.",
      socialMedia: 'Social Media',
      generatedText: 'Stiamo generando un testo per i social media. Questo testo va bene?',
      usePhoto: 'Grazie per il tuo post! Possiamo usare anche la tua foto sui canali social?',
      maximumLenght: 'Massimo 20.000 caratteri.',
      userSharedNewPicture: 'Un utente della nostra app ha condiviso una nuova foto!',
      contributionTo: (kreiseName: string) => `Contributo a “${kreiseName || 'Tutti'}”`,
      postWarning1:
        'Vai avanti! Avrai ancora la possibilità di aggiungere un testo personalizzato nel prossimo passaggio. Inoltre, il tuo post verrà revisionato.',
      postWarning2: "Dopo l'invio, il tuo post sarà revisionato da un amministratore. Per domande, contatta ",
      postWarning3: ' o ',
      AGB: 'Termini e Condizioni',
      DSE: 'Politica sulla privacy',
      withCheckbox: "Attivando inizialmente il mio account tramite la funzione 'Password dimenticata', accetto i",
      withoutCheckbox: "Attivando inizialmente il tuo account tramite la funzione 'Password dimenticata', accetti i",
      tou: 'Termini di utilizzo',
      dse: 'Politica sulla privacy',
      impressum: 'Avviso legale',
    },
    responseHandler: {
      messageLabel: `Errore sconosciuto. Si prega di contattare ${support_email}.`,
      expiredLinkLabel: 'Il collegamento è scaduto. Richiedere la modifica di una nuova password.',
      emailExistLabel: 'Spiacenti, questo indirizzo e-mail è già registrato.',
      profileUpdated: 'Profilo aggiornato',
      profileUpdatedCorpsError: `Profilo aggiornato! Tuttavia, il seguente ${
        CorpsName('ITA').single
      } non ha potuto essere aggiunto a causa di un codice di accesso errato:`,
      appNeedsUpdate: "Aggiornare l'applicazione nell'App Store / Google Play.",
      maximalVideoSize: 'La dimensione massima del file è di 2 GB!',
    },
    report: {
      notBelongLabel: `L'utente non appartiene più ad un ${CorpsName('ITA').single}`,
      incProfileLabel: "L'utente fa offerte di profilo non corrette",
      badPicLabel: "L'immagine del profilo dell'utente viola le linee guida",
      badComLabel: "I commenti dell'utente violano le linee guida",
      spamLabel: "L'utente invia spam nella chat",
      reasonsLabel: "L'utente invia spam nella chat",
    },
    drawer: {
      subscription: 'Subscription',
      news: 'Notizie',
      kreise: KreiseName('ITA').multi,
      search: 'Ricerca',
      calendar: CalendarName('ITA'),
      contacts: 'Contatti',
      documents: `${DocumentsName('ITA')}`,
      requests: 'Richieste',
      messages: MessagesName('ITA'),
      profile: ProfileName('ITA'),
      logout: 'Logout',
      map: 'Mappa',
      mentoring: 'Mentoring',
    },
    imageCropper: {
      cropLabel: 'Ritaglio',
      cancelLabel: 'Annulla',
      chooseLabel: 'Scegliere',
    },
    signIn: {
      register: 'Registra',
      title: 'Accedere prego',
      loginPlaceholder: 'Indirizzo email',
      passwordPlaceholder: "Parola d'ordine",
      forgotPassword: 'Ha dimenticato la password?',
      loginBtn: 'Accesso',
      noAccount: 'Non hai ancora un conto?',
      description0: 'Se avete domande, non esitate a contattarci a',
      description1: 'o',
      description2: '.',
      loginValidation: 'Il tuo indirizzo e-mail non è corretto',
      passwordValidation: 'La password deve essere lunga almeno 6 caratteri',
    },
    search: {
      name: 'Cognome',
      corps: CorpsName('ITA').single,
      country: CountryName('ITA'),
      city: 'Città',
      industry: IndustryName('ITA'),
      company: 'Company',
      profession: 'Lavoro',
      hobby: 'Interessi',
      cultural: 'Sponsor culturali',
      study: 'Formazione scolastica',
      office: 'Ufficio',
      degreeProgram: 'Programma di laurea',
      university: 'Università',
      position: FunctionName('ITA'),
      status: 'Status',
      title: 'Risultato della ricerca per:',
      filterTitle: 'Filtra dopo',
      global: 'Tutti',
      activity: ActivityName('ITA'),
    },
    resetPassword: {
      enterEmail: 'Inserisci il tuo indirizzo email',
      inputPlaceholder: 'Indirizzo email',
      sendBtn: 'Spedire',
      cancelBtn: 'Abortire',
      message: "Se conosciamo l'indirizzo e-mail, è stato inviato un collegamento lì.",
      desc: 'È necessario inserire l`indirizzo e-mail esatto memorizzato nell`app. Se necessario, controllate le vostre e-mail per verificare quale sia.',
    },
    password: {
      resetLabel: 'Inserisci una nuova password',
      inputLabel: 'Inserisci una nuova password',
    },
    signUp: {
      createAccount: 'Creare un profilo',
      inputPlaceholder: `Il codice di accesso del tuo ${CorpsName('ITA').single}`,
      sendBtn: 'Continua',
      descFirst: 'Registrandoti, accetti il nostro ',
      descSecondo: 'e confermare che avete letto il nostro',
      descThird: `Se avete domande, non esitate a contattarci a ${support_email} o +49 170 49 805`,
      terms: 'termini e condizioni',
      policy: 'politica sulla privacy.',
      description: 'Nota',
      generatePassword: 'Generate password',
      noCodeSuccessRegister:
        'Grazie per la vostra registrazione! Sarete informati via e-mail non appena sarete stati attivati.',
    },
    signUpDefault: {
      withoutCode: 'Senza codice',
      createAccount: 'Basta registrarsi qui',
      withCode: "Con codice d'invito",
      simpleRegister: "Senza codice d'invito",
      existingAccount: 'Conto già disponibile?',
      descr01: "Sono d'accordo con il",
      descr0: "Registrandosi, l'utente accetta il nostro",
      descr1: 'e confermate di aver letto e compreso il nostro.',
      descr2: 'Se avete domande.',
      guest: 'Registrieren als Gast',
      withCodeWithDescr: `Registrieren als ${member_naming_singular}`,
    },
    signUpManual: {
      title: 'Registrare',
      photoDesc: 'Aggiungere una foto',
      genderInput: 'Genere',
      firstName: 'Nome di battesimo',
      lastName: 'Cognome',
      club: CorpsName('ITA').single,
      specify: 'per favore specificare',
      emailPlaceholder: 'E-mail',
      emailHint: "Utilizzate l'indirizzo e-mail che utilizzate da tempo.",
      repeatEmailPlaceholder: 'Conferma E-mail',
      passwordPlaceholder: 'Per impostare una password',
      repeatPasswordPlaceholder: 'Ripeti la password',
      postcode: 'Codice postale',
      place: 'Residenza',
      country: 'Nazione',
      loginCode: "Codice d'invito",
      mobilePlaceholder: 'Mobile (non visibile nel profilo)',
      corps: SignUpCorpsName('ITA'),

      searchCountry: 'Cerca per paese',
    },
    backToLogin: {
      desc: 'Hai già un account? ',
      login: 'Accesso',
    },
    backToSignUp: {
      desc: 'Non hai ancora un conto? ',
      register: 'Registra',
    },
    privacy: {
      desc: 'Sì, accetto i ',
      btn: 'termini e le condizioni',
      zu: '',
      map: {
        title: 'Avviso sulla Privacy',
        privacyNotice: `Questa mappa è stata realizzata in conformità con il GDPR. `,
        additionalInfo: {
          intro: 'Le puntine sulla mappa sono',
          inaccurateDisplay: 'visualizzate solo in modo impreciso',
          locationBasis: ', sulla base del codice postale/città ma senza la via/numero civico.',
          noConclusion: 'Ciò significa che non è possibile trarre conclusioni',
          onAddresses: 'sugli indirizzi specifici degli utenti',
          conclusion: '.',
        },
        agree: 'Capito',
        linkName: 'Informativa sulla Privacy',
        options: {
          doNotShowAgain: 'Non mostrare più',
          doNotShowMeOnMap: 'Non mostrarmi sulla mappa (nemmeno approssimativamente)',
        },
      },
      restrictions: {
        overall: 'Questa funzione è accessibile solo ai membri.',
        overallWilkar: 'Questa funzione non è accessibile per motivi di privacy.',

        profile:
          "Solo i membri possono visualizzare i profili degli utenti. Tuttavia, puoi inviare un messaggio privato all'utente.",
        groupchat: `I ${KreiseName('ITA').single} sono accessibili solo ai membri.`,
        groupchatWilkar: `Per motivi di privacy, i ${KreiseName('ITA').single} sono accessibili solo ai membri.`,
        comments: 'I commenti sono visibili solo ai membri.',
        commentsWilkar: 'Per motivi di privacy, i commenti sono visibili solo ai membri.',
        likes: 'La lista dei like è visibile solo ai membri.',
        post: 'Solo i membri possono pubblicare contenuti.',
        participantsList: 'La lista dei partecipanti è visibile solo ai membri.',
        commentsOnlyForMembers: 'I commenti sono visibili solo ai membri.',
        postsLimitation: 'I post più vecchi sono visibili solo ai membri.',
      },
      settings: {
        guestPost: {
          title: 'Gli ospiti verificati possono vedere i miei post',
          ask: 'Chiedi sempre',
          no: 'No',
          yes: 'Sì',
        },
      },
      guestModal: {
        title: 'Gli ospiti possono vedere questo post? Puoi cambiare questa impostazione in seguito.',
        dontAskAgain: 'Non chiedere più (Puoi modificare questa impostazione sotto “Profilo” → “Privacy”).',
      },
      post: {
        ifShown: `Nascondi per ${guest_naming_plural}`,
        ifHidden: `Mostra per ${guest_naming_plural}`,
        ifShownModal: `Il post non è più visibile per ${guest_naming_plural}.`,
        ifHiddenModal: `Il post è ora visibile per ${guest_naming_plural}.`,
      },
    },
    searchModal: {
      landTitle: 'Scegliere un nazione',
      corpsTitle: `Seleziona il ${CorpsName('ITA').single}`,
      pleaseSelect: 'Selezionare',
    },
    btn: {
      sendBtn: 'Spedire',
      cancelBtn: 'Abortire',
      select: 'Selezionare',
      cancelEventBtn: acceptEventName('ITA').cancel,
      acceptBtn: acceptEventName('ITA').accept,
      saveBtn: 'Salva',
      clearBtn: 'Cancella',
      acceptReqBtn: 'Confermare',
      declineReqBtn: 'Diminuire',
      updateBtn: 'Aggiorna',
      declineBtn: 'Declino',
      yesUnderstood: 'Ja, verstanden',

      ok: 'OK',
      cancel2: 'Abortire',
    },
    validation: {
      email: 'Il tuo indirizzo e-mail non è corretto',
      password: 'La password deve essere lunga almeno 6 caratteri',
      repeatEmail: "L'e-mail e la ripetizione dell'e-mail non corrispondono",
      repeatPassword: 'Password e ripetizione non corrispondono',
      required: 'Questo campo è obbligatorio',
      matchPasswords: 'La vecchia e la nuova password corrispondono',
      dontMatchPasswords: 'Password e ripetizione non corrispondono',
    },
    init: {
      title: 'Caricamento',
      overlayLabel: 'Si prega di controllare la connessione internet',
    },
    screenTitles: {
      postsTitle: 'Notizie',
      circlesTitle: KreiseName('ITA').multi,
      searchTitle: 'Ricerca',
      profileTitle: ProfileName('ITA'),
      eventTitle: CalendarName('ITA'),
      eventDetailsTitle: 'Evento',
      newEventTitle: 'Aggiungi evento',
      contactsTitle: 'Contatti',
      contactReqTitle: 'Richieste di contatto',
      reqDetailsTitle: 'Dettagli della richiesta',
      outReqTitle: 'Richiesta di contatto',
      messagesTitle: MessagesName('ITA'),
      changePasswordTitle: 'Cambiare la password',
      appInfoTitle: 'Info',
      reportTitle: 'Segnala utente',
      createKreiseTitle: 'Creare una chat di gruppo',
      eventParticipants: 'Teilnehmer',
    },
    profileDetails: {
      showFilters: 'Mostra filtri',
      user: 'Utenti',
      mainTitleLabel: ProfileName('ITA'),
      basisLabel: 'Base',
      clubLabel: CorpsName('ITA').single,
      clubLabelPlural: CorpsName('ITA').multi,
      professionLabel: 'Lavoro',
      studyLabel: StudyName('ITA').short,
      uploadPhotoLabel: 'Carica una foto',
      editPhotoLabel: 'Modifica foto',
      profileCompletedLabel: 'Profilo',
      profileCompletedGLabel: 'completato',
      birthday: 'Data di nascita',
      functionLabel: 'Funzione',
      dateOfJoinLabel: 'Tempo di adesione',
      positionDescLabel: 'Profession',
      companyLabel: CompanyName('ITA'),
      nameLabel: 'Cognome',
      websiteLabel: 'Sito web aziendale',
      fromLabel: 'A partire dal',
      toLabel: 'Per',
      delete: 'cancellare',
      untilToday: 'Fino ad oggi',
      degreeLabel: StudyName('ITA').abshchluss,
      miscellaneos: 'Altro',
      hobbies: 'Interessi',
      cultural: 'Sponsor culturali',
      positionLabel: 'Posizione',
      typeOfEmplLabel: 'Tipo di impiego',
      activityLabel: ActivityName('ITA'),
      careerStageLabel: 'Livello di carriera',
      activityDescLabel: 'Descrizione del lavoro',
      industryLabel: IndustryName('ITA'),
      areaLabel: 'La zona',
      employmentPeriodLabel: 'Periodo di impiego',
      universityLabel: 'Università',
      courseLabel: 'Corso di studio',
      firmaLabel: 'Firma',

      ageLabel: 'Età',
      birthdayLabel: 'Compleanno',
      editProfileLabel: 'Modifica profilo',
      addContactLabel: 'Aggiungi ai contatti',
      sendMessageLabel: 'Invia messaggio',
      requestSentLabel: 'Invia richiesta di amicizia',
      sendRequestSuccessLabel: 'Richiesta inviata con successo',
      reportSentLabel: 'Utente già segnalato',
      addReportLabel: 'Inviare il rapporto',
      contactsLabel: 'Contatti',
      showBirthday: 'I contatti vedono il mio compleanno',
      street: 'Via + Nc. ',
      houseLabel: 'Numero civico',
      houseNumberLabel: 'Numero civico',
      partner: 'Nome del partner',
      mobileNumberLabel: 'Numero di cellulare',
      handphoneNumberLabel: 'Numero di telefono cellulare',
      telephoneNumberLabel: 'Numero di telefono',
      leibbursch: 'Mentore',
      leibfuechse: 'Mentee',

      bio: 'Su di me',
      addDocuments: 'Aggiungi documenti',
      image: 'Immagine',
      video: 'Video',
      pdf: 'PDF',
      link: 'Link',
      guest: 'Ospite',
      firstName: 'Nome',
      lastName: 'Cognome',
      title: 'Titolo',
      email: 'E-Mail',
      name: 'Nome',
      lastname: 'Cognome',
      country: CountryName('ITA'),
      wohnort: 'Città',
      postcode: 'CAP',
      inReview: 'Conferma in attesa via email',
    },
    calendar: {
      birthdayLabel: 'Compleanno del',
      noEventsLabel: 'Non ci sono eventi per la data selezionata',
      allTabLabel: 'Tutti',
      myTabLabel: 'I miei eventi',
      clubsTabLabel: `${CorpsName('ITA').single}`,
      subsTabLabel: 'Abbonamenti',
      subscribedTabLabel: 'Iscritto',
      otherTabLabel: 'Altri',

      error: 'La data di fine deve essere successiva alla data di inizio',
      filter: {
        all: 'Tutti',
        invited: 'Invitati',
        confirmed: 'Confermati',
      },
    },
    eventLabels: {
      organizer: 'Organizer',
      registrationVia: 'Registrazione tramite',
      maximalParticipants: 'Numero massimo di partecipanti',
      participantsWarning: 'Il numero di partecipanti è limitato',
      interested: 'Parti interessate',
      notInterested: 'Nessun interesse',
      removeFromWaitList: 'Remove from waiting list',
      addEventTitleImage: 'Aggiungi l`immagine del titolo',
      changeEventTitleImage: 'Cambia l`immagine del titolo',
      inviteTo: 'Invito a',
      addEndDate: 'Inserire la data e l`ora di fine',
      eventLabel: 'Evento',
      startLabel: 'Inizia a',
      locationLabel: 'Posizione',
      participantLabel: 'Partecipanti',
      moreInfo: 'Ulteriori informazioni',
      commentsLabel: 'Commenti',
      noCommentsLabel: 'Non ci sono ancora commenti',
      eventStartLabel: 'Iniziare',
      eventEndLabel: 'Fine',
      descLabel: 'Descrizione',
      creatorLabel: 'Creato da',
      titleLabel: 'Titolo',
      deleteEventTitle: 'Cancellare un evento?',
      deleteEventLabel: 'Cancellare questo evento?',
      clubsTitle: CorpsName('ITA').single,
      gruppenchat: 'Selezionare la chat di gruppo',
      contactsTitle: 'Contatti',
      delCommentLabel: 'Cancellare questo commento?',
      sendInvite: "Inviare l'invito a",
      selectCorps: 'Selezionare il pubblico di riferimento',
      externalGuest: 'Ospiti esterni invitati',
      externalGuestPlaceholder: 'Inserire l`indirizzo di posta elettronica',
      attachmentLabel: 'Allegato',
      shareLinkLabel: 'Condividi il link',
      linkLabel: "Registrazione dell'ospite",
      copyLinkLabel: 'Copiare il link',
      iCalDownload: 'File calendario',
      timezone: 'Fuso orario',
      visibility: 'Sichtbarkeit',
      errorDate: 'La data è nel passato',
      startAfterEndError: 'La data di fine deve essere successiva a quella di inizio',
      members: 'Membri',
      waitlist: 'Lista d`attesa',
      onWaitlist: 'in lista d`attesa',
      leaveWaitlist: 'Lasciare la lista d`attesa?',
      leaveWaitlistDesc:
        "Rimuoviti dalla lista d'attesa?\n\n Se ti iscrivi nuovamente alla lista d'attesa in seguito, potresti essere posizionato in fondo alla lista.",
      limitAchieved: (limit: number) =>
        `È stato raggiunto il numero massimo di partecipanti di ${limit}. Sei stato inserito nella lista d'attesa.`,
      visibilityOptions: {
        allWithoutGuests: 'Membri e sul Sito Web',
        invited_member: 'Solo membri invitati',
        verified_member: 'Tutti i membri',
        authorized_member: 'Membri e ospiti verificati',
        all: 'Membri, ospiti e sul sito web',
      },
      modalEvent: {
        title: 'Evento creato',
        subTitle: 'L`evento è stato creato.\n\n',
        content:
          'A seconda delle impostazioni dell`utente, verranno inviate notifiche push/mail per l`evento. \n\nSe è stato impostato in "Profilo" → "Notifiche", l`utente riceverà anche una notifica via e-mail dell`evento.',
      },
      modalComment: {
        title: 'Chi lo comunica?',
        message: 'Chi deve essere informato del suo commento?',
        optionAll: 'Tutti i partecipanti',
        optionNone: 'Solo organizzatore/i',
        button: 'Invia un commento',
      },
      cancelModal: {
        title: 'Cancellare o eliminare?',
        message: 'Cancellare o eliminare l`evento?',
        optionAll: 'Annullare (i partecipanti saranno informati)',
        optionNone: 'Cancellare (i partecipanti non saranno informati)',
      },
      updateEventModal: {
        title: 'Invio di ulteriori inviti',
        label: 'Gli altri gruppi sono stati invitati.',
        message:
          'A seconda delle impostazioni, le notifiche push o le notifiche e-mail sull`evento vengono ora inviate agli altri gruppi.',
      },
      eventRegistrationType: {
        app: 'App',
        event: 'Link/Indirizzo Email',
        enterLink: 'Aggiungi link/indirizzo e-mail o simili',
      },
      parts: {
        part: 'Parte',
        addPart: 'Aggiungi punto del programma',
        hint: 'Spiegazione',
        question: 'Domanda',
        single: 'Solo un`opzione selezionabile',
        multi: 'Diverse opzioni disponibili',
        freeText: 'Risposta a testo libero',
        quiz: 'Quiz',
        questionnaire: 'Questionario',
        addQuestionnaire: 'Aggiungi Questionario',
        questionnaireType: 'Opzioni di risposta',
        option: 'Opzione',
        addOption: 'Aggiungi opzione',
        showMembers: 'Partecipanti',
        youJoined: 'Partecipi a:',
        totalToPay: 'Importo totale da pagare:',
        yourAnswer: 'La tua risposta',
        visibleForCreators: 'Visibile solo per i creatori',
        allAnswers: 'Tutte le risposte:',
        addUsers: 'Aggiungi utenti',
        isAnonym: 'Voto anonimo',
        isAnonymQuiz: 'Quiz anonimo',
      },
    },
    rrEventLabels: {
      title: 'Ripetizione',
      MO: 'Lunedì',
      TU: 'Martedì',
      WE: 'Mercoledì',
      TH: 'Giovedì',
      FR: 'Venerdì',
      SA: 'Sabato',
      SU: 'Domenica',
      YEARLY: { single: 'Anno', regular: 'Annualmente' },
      MONTHLY: { single: 'Mese', regular: 'Mensilmente' },
      WEEKLY: { single: 'Settimana', regular: 'Settimanale' },
      DAILY: { single: 'Giorno', regular: 'Giornaliero' },
      SINGLE: { single: 'Una volta', regular: 'Una volta' },
      CUSTOM: {
        single: 'Personalizzato...',
        regular: 'Personalizzato...',
      },
      freq: 'Frequenza',
      interval: 'Intervallo',
      notRepeat: 'Una volta',
      every: 'Ogni',
      month: 'mese',
      week: 'settimana',
      last: 'ultimo',
      on: 'il',
      everyMonthOn: 'Ogni mese il',
      ofTheMonth: 'del mese',
      editLabel: 'Quali appuntamenti vuoi modificare?',
      thisDate: 'Solo questa data',
      thisDateAndFollowing: 'Questa e tutte le seguenti',
      allDates: 'Tutte le date',
      after: 'Dopo',
      time: 'ora',
      times: 'volte',
      neverEnds: 'Non finisce mai',
      until: 'fino al',
      repeatedDefault: ({ frequency, freq }: { frequency: string; freq: number }) =>
        `Viene ripetuto ogni${freq == 2 ? '' : freq == 0 ? 'a' : 'o'} ${frequency}`,
      repeatedMonthly: ({ day, interval }: { day: string; interval: number }) =>
        `Viene ripetuto ogni${interval == 1 ? '' : ` ${interval}.`} mese il ${day.length > 2 ? day : `${day}.`}`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }: { day: string; interval: number; bysetpos: number }) =>
        `Viene ripetuto ogni ${bysetpos == -1 ? ' ultimo' : `${bysetpos}.`} ${day} del ${
          interval === 1 ? '' : `${interval}.`
        } mese.`,
      repeatedYearly: ({ interval }: { interval: number }) =>
        `Viene ripetuto ogni ${interval == 1 ? '' : `${interval}.`} anno.`,
      repeatedWeekly: ({ interval, byweekday }: { interval: number; byweekday: any }) =>
        `Viene ripetuto ogni${interval != 1 ? ` ${interval}.` : ''} settimana${
          !!byweekday?.length
            ? ' il ' + byweekday?.slice(0, -1).join(', ') + (byweekday?.length > 1 ? ' e ' : '') + byweekday?.slice(-1)
            : ''
        }`,
      repeatedDaily: ({ interval }: { interval: number }) =>
        `Viene ripetuto ogni${interval != 1 ? ` ${interval}.` : ''} giorno.`,
    },

    requestsLabels: {
      receiveLabel: 'Ricevuto',
      sentLabel: 'Inviato a',
    },
    post: {
      adTitle: 'Information/Advertisement',
      toKreise: 'a',
      comment: 'Commenti',
      myPost: 'Me',
      openPdf: 'Clicca per aprire',
      deletePost: 'Cancella questo post?',
      posted: 'Pubblicato',
      commented: 'Commentato',
      contacts: 'Contatti',
      sendToContacts: 'Messaggio a tutti',
      enterText: 'Schreibe hier eine Nachricht',
      sendToCircle: `Inserire il messaggio in questo ${KreiseName('ITA').single}`,
      sendComment: 'Inserisci il tuo commento',
      longMessage: '20.000 caratteri al massimo',
      noComments: 'Non ci sono ancora commenti',
      deleteComment: 'Cancellare il commento?',
      deleteEventPost: "Cancellare questo post? Questa operazione non cancella l'evento.",
      editPostLabel: 'Modifica post',
      editingPostLabel: 'Modalità di modifica',
      deletePostLabel: 'Elimina post',
      editedAt: 'Modificato',
      pinned: 'In evidenza.',
      actions: {
        label: 'Azioni post',
        pin: {
          label: 'Fissa post',
          once: 'Sposta in alto una volta',
          d3: '3 giorni',
          d5: '5 giorni',
          w1: '1 settimana',
          w2: '2 settimane',
          unpin: 'Rimuovi dalla fissazione',
        },
      },
    },
    circles: {
      circlesTitle: `${KreiseName('ITA').multi} Cerchi`,
      noCirclesTitle: `${KreiseName('ITA').multi} non cerchi`,
      myPost: 'Me',
      circlesInfoTitle: 'Info & Membri',
      circlesSubscribe: 'Abbonarsi a',
      unsubscribeAlert: 'Lasciare irrevocabilmente la chat di gruppo chiusa?',
      continueAlert: 'Lasciare',
      readOnlyKreise: `Questa è una chat di ${
        KreiseName('ITA').single
      } di sola lettura. Solo gli amministratori possono postare qui. È tuttavia possibile scrivere commenti sui messaggi".`,
    },
    messages: {
      addMessageLabel: 'Inserire il messaggio',
      changesSaved: 'Changes saved!',
      newConversation: 'New conversation',
    },
    profileTabs: {
      basicTab: 'Base',
      clubsTab: CorpsName('ITA').single,
      miscellaneosTab: 'Altro',
      professionTab: 'lavoro',
      studyTab: StudyName('ITA')?.short,
      notificationsTab: 'Notifiche',
      privacy: 'Privatsphäre',
    },
    profile: {
      addPhotoLabel: 'Aggiungere una foto',
      genderInputLabel: 'Genere',
      titleLabel: 'Titel',
      firstNameLabel: 'Nome di battesimo',
      lastNameLabel: 'Cognome',

      passwordLabel: 'Per impostare una password',
      birthDateLabel: 'Data di nascita',
      birthday: 'Data di nascita',
      changePasswordLabel: 'Cambiare la password',
      defaultCountryLabel: 'Germania',
      defaultGraduationLabel: 'Nessuna informazione',
    },
    profileKorp: {
      verband: CorpsName('ITA').single,
      function: FunctionName('ITA'),
      dateOfJoin: CorpsEditDate('ITA'),
      beername: 'Nome della birra',
    },
    profileClubs: {
      noUserLabel: "Tutte le informazioni di base devono essere compilate prima di poter utilizzare l'app.",
      functionLabel: 'Funzione',
      setClubLabel: `Indicare il nome del ${CorpsName('ITA').single}.`,
      matchClubLabel: 'Il',
      matchClubContinueLabel: 'esiste già nel profilo, si prega di controllare di nuovo.',
      clubLabel: CorpsName('ITA').single.toUpperCase(),
      clubCodeLabel: `Il codice di accesso del tuo ${CorpsName('ITA').single.toUpperCase()}`,
      entryTimeLabel: CorpsEditDate('ITA'),
      deleteClubLabel: 'Sei sicuro di voler cancellare la tua iscrizione a',
      deleteClubContinueLabel: '?',
      selectVariant: 'Selezionare la variante',
      requestExistingCorp: 'Richiesta di un`azienda esistente',
      requestNewCorps: 'Richiesta di un nuovo corpo',
      createCorp: 'Creare',
    },
    profileProfession: {
      jobTitle: 'Titolo del lavoro',
      noUserLabel: "Tutte le informazioni di base devono essere compilate prima di poter utilizzare l'app.",
      untilToday: 'Fino ad oggi',
      positionTitleLabel: 'Posizione',
      itemNameLabel: 'Profession',
      employmentLabel: 'Tipo di impiego',
      activityLabel: ActivityName('ITA'),
      careerStageLabel: 'Livello di carriera',
      activityDescLabel: 'Descrizione del lavoro',
      companyLabel: CompanyName('ITA'),
      nameLabel: 'Cognome',
      industryLabel: IndustryName('ITA'),
      areaLabel: 'La zona',
      websiteLabel: 'Sito web aziendale',
      employmentPeriodLabel: 'Periodo di impiego',
      fromLabel: 'A partire dal',
      toLabel: 'Per',
    },
    profileStudy: {
      noUserLabel: "Tutte le informazioni di base devono essere compilate prima di poter utilizzare l'app.",
      universityLabel: StudyName('ITA').hochsule,
      courseLabel: StudyName('ITA').studiengang,
      degreeLabel: StudyName('ITA').abshchluss,
    },
    profileOther: {
      noUserLabel: "Tutte le informazioni di base devono essere compilate prima di poter utilizzare l'app.",
      hobbiesLabel: 'Interessi',
      culturalLabel: 'Sponsor culturali',
      socialMediaPlattform: 'Social-Media-Plattform',
      socialMediaLabel: 'Social Media Links',
      socialMediaLink: 'Link',
      socialMediaError: 'Inserire l`URL completo',
    },
    profileNotifications: {
      noUserLabel: "Tutte le informazioni di base devono essere compilate prima di poter utilizzare l'app.",
      notificationsInfo:
        'Qui puoi attivare le notifiche via email per le singole attività (default) o disattivarle per 3 mesi.',
      notificationsTitle: 'Notifiche push:',
      notificationsDescr0: 'È possibile impostare le notifiche push nelle impostazioni di ',
      notificationsDescr1: 'sistema dello smartphone.',
      notificationsDescr2: '',
      mailingTitle: 'Notifiche via e-mail:',
      mailingDescr:
        'Qui è possibile attivare le notifiche via e-mail per le singole attività (impostazione predefinita) o disattivarle per 1 anno.',
      allNotificationsLabel: 'Tutte le notifiche',
      postsLabel: `Messaggi nelle ${KreiseName('ITA').multi}`,
      messagesLabel: 'Messaggi privati',
      commentsLabel: 'Commenti sui propri contributi',
      contactRequestsLabel: 'Richieste di contatto',
      eventsLabel: 'Eventi',
      clubMemberLabel: 'Nuovi membri registrati',
      enableLabel: 'Su',
      disableToLabel: 'Spento fino a quando',
      disableLabel: 'Da',
      ownPosts: 'Meine Beiträge (Mail auch selbst erhalten)',
    },
    searchInput: {
      title: 'Ricerca',
      placeholder: 'Ricerca',
    },
    emptyList: {
      title: 'Qui non ci sono contenuti (ancora)',
    },
    switchLanguage: {
      placeholder: 'Lingua/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'Russian (Русский)',
      SP: 'Spanish (Español)',
      NLD: 'Dutch (Nederlands)',
      POL: 'Polish (Polish)',
      title: 'Scegliere la lingua',
    },
    filePicker: {
      takePicLabel: 'Scatta la foto',
      uploadPicLabel: 'Carica foto',
      selectPicLabel: 'Seleziona la foto/video',
      selectDocLabel: 'Seleziona il documento',
    },
    datePicker: {
      selectDateLabel: 'Seleziona la data',
      confirmDateLabel: 'Confermare la data',
      selectTimeLabel: "Seleziona l'ora",
    },
    changePassword: {
      currentPasswordLabel: 'Password attuale',
      newPasswordLabel: 'Nuova password',
      repeatPasswordLabel: 'Ripetere la password',
      changePasswordLabel: 'Cambiare la password',
    },
    appInfo: {
      appDescLabel:
        "Korponnect è l'app per gli studenti della confraternita. Scambio tra aziende, organizzazione di eventi e networking professionale e privato: tutto in un'unica app.",
      contactsLabel: InfoContactsName('ITA'),
      infoLabel: 'Informazioni',
      versionLabel: "Versione dell'app",
      agbLabel: 'AGB',
      dataProtectionLabel: 'Dichiarazione di protezione dei dati',
      manualLabel: 'Manuale',
      appTextLabel: 'Potete trovarci qui',
      appAndLabel: 'e un',
      appMainTextLabel: `Vuoi aggiungere una cerchia o hai un altro suggerimento? Vuoi eliminare il tuo profilo? Scrivici una breve email a ${support_email}.`,
      deleteFeedback: 'Motivo dell`eliminazione dell`account / feedback 🙂',
      deleteUserTitle: `Eliminare l'account?`,
      deleteUserDesc: `Qui puoi eliminare irrevocabilmente il tuo account.\b A proposito: puoi anche semplicemente disattivare le notifiche.Modifica le impostazioni in profilo -> Notifiche o scrivici un'e -mail.`,
      deleteUserAlert: 'Tutti i tuoi dati verranno eliminati per sempre, continua?',
      webLabel: 'Versione web',
    },
    infoMessages: {
      registartionSuccessLabel: 'Grazie per esserti registrato!',
      manualRegSuccessLabel: 'Grazie per esserti registrato! Il profilo sarà attivato a breve.',
      linkExpiredLabel: 'Il collegamento è scaduto. Richiedere la modifica di una nuova password.',
      emailExistLabel: "Spiacenti, l'e-mail non è disponibile.",
    },
    logoutAlert: {
      title: 'Disconnettersi',
      text: "Volete davvero disconnettervi? Non è necessario disconnettersi, è sufficiente chiudere o ridurre a icona l'applicazione.",
      confirm: 'Si',
      dismiss: 'No',
    },
    invalidInputs: {
      passwordChars: `La password deve contenere almeno ${passwordDigits(
        'ITA',
      )} caratteri, di cui almeno una lettera, un numero e un carattere speciale.`,
      fieldRequired: 'Questo campo è obbligatorio.',
      emailsNotMatching: 'Assicuratevi che le vostre e-mail corrispondano',
      emailInvalid: "L'indirizzo e-mail non è corretto!",
      selectCorrectAnswer: 'You need to select correct answer',
      corp: 'Selezionare almeno 1 voce',
    },
    mentoring: {
      profile: {
        rules1: "Sono d'accordo con il",
        rules2: ' Comprensione dei valori ',
        rules3: '"Mentoring for Scholars" a.',
        menteeDescription:
          'Selezionate la vostra regione di tutoraggio per essere informati sugli eventi in loco. NOTA: è possibile modificare la selezione in qualsiasi momento. NON limita la ricerca di mentori.',
        mentorDescription:
          'Compilate il vostro profilo di mentore in modo che i borsisti possano trovarvi come mentori. NOTA: sono richieste almeno 1 regione e almeno 1 area tematica.',
        mentoringRegions: 'Regioni di mentoring',
        reigonsShort: 'Regioni',
        topics: 'Argomenti',
        departments: 'Dipartimento',
        available: 'Sono disponibile',
        unavailable: 'Al momento non sono disponibile',
        deleteAccount: 'Cancellare il profilo Ment. e lasciare il gruppo',
        deleteAccountDescr: 'Cancellare il profilo del mentore e lasciare la chat del gruppo mentori?',
        uploadPdf: 'Profilo del mentore (PDF)',
        myPdf: 'Il mio profilo (PDF)',
        pdf: 'Mentor Profile',
      },
      tabNames: {
        info: 'Info',
        news: 'Notizie',
        profile: 'Profilo',
        search: 'Ricerca e richiesta',
        request: 'Mentore',
        requestDetails: 'Richiesta di mentoring',
        tabDeactivated: 'Scheda disattivata. Compilare il profilo di mentore.',
      },
      search: {
        description:
          'Per avviare la ricerca in modo mirato, si consiglia di iniziare con alcuni campi di selezione e poi ampliare la griglia di ricerca in una nuova ricerca. Se non trovate un mentore adatto nella vostra regione, allargate la ricerca a una regione vicina. Criteri di ricerca: 1 regione, da 1 a 3 campi tematici',
        searchBtn: 'Ricerca',
      },
      requests: {
        mentorIncoming: 'Le mie richieste di mentoring',
        mentorCurrent: 'I miei tutoraggi',
        menteeOutgoing: 'La mia richiesta di mentoring',
        menteeCurrent: 'Il mio tutoraggio',
        cancelMentoring: 'Fine del tutoraggio',
        sendRequest: 'Richiesta come mentore',
        alreadyHaveMentor: 'Si può avere un solo mentore',
        requestSuccess: 'Richiesta inviata con successo',
        requestRetracted: 'Anfrage erfolgreich zurückgezogen',
        cancelSendingRequest: 'Annulla la richiesta del mentore',
        approveRequest: 'Accettare la richiesta',
        rejectRequest: 'Rifiutare il mentee',
        invitaionText: 'Richiesta',
        rejectionText: 'Motivare il rifiuto',
        sureRetractRequest: 'Ritirare davvero la richiesta?',
      },
    },
    downloads: {
      root: 'Casa',
      delete: 'Elimina',
      rename: 'Rinomina',
      areYouSureDeleteFolder: 'Eliminare davvero la cartella? Tutti i contenuti saranno cancellati!',
      renameFolder: 'Rinomina cartella',
      createNewFolder: 'Crea nuova cartella',
      newFolderPlaceholder: 'Inserisci il nome della cartella',
      create: 'Crea',
      areYouSureDeleteFile: '',
      downloadFile: 'Scarica',
      deleteFile: 'Elimina dal dispositivo',
      openInFinder: 'Apri in Finder',
      cancel: 'Annulla',
    },
    globalSearch: {
      postedBy: 'Pubblicato da',
      found: 'Trovato',
      loadMore: 'Carica altro',
      enterQuery:
        'Inserisci qualsiasi termine di ricerca. Puoi cercare qualsiasi cosa (nome, documento, evento, post, ...)',
      folder: 'Cartella',
      post: 'Post',
      error: 'Inserisci almeno 3 caratteri',
    },
    kreiseSettings: {
      description: 'Descrizione',
      push: 'Notifiche push',
      email: 'Notifiche via email',
      subscribers: 'Membri',
      active: 'Attivato',
      oneYear: 'Silenzioso per 1 anno',
      forever: 'Silenzioso per sempre',
      until: 'Fino a',
      cantDeactive: 'Questa impostazione è disattivata per questo gruppo',
      SINGLE: 'Per ogni post',
      SUMMARY_ONCE: 'Riepilogo 1 volta a settimana',
      SUMMARY_TWICE: 'Riepilogo 2 volte a settimana',
      SUMMARY_DAY: 'Riepilogo 1 volta al giorno',
      OFF: 'Spento',
      PUSH: 'Notifica push',
      EMAIL: 'Notifica via email',
      PUSH_AND_EMAIL: 'Notifica push e via email',
      newUser: 'Notifica per nuovi utenti',
      statistic: 'Statistiche invio',
      off: 'Spento',
      ownPosts: 'Invio statistiche per i tuoi post',
      deleteMemberDescr: 'rimuovere?',
      deleteMemberBtn: 'Rimuovere',
      retractAdminRights: 'Revocare i diritti',
      deleteAdminAndUser: 'Rimuovere completamente',
      toDeleteKreiseBtn: 'Eliminare il gruppo',
      deleteKreiseDescr: `${KreiseName('ITA').single} cancellato irreversibilmente?`,
      deleteKreiseModalTitle: 'Rimuovere membro?',
      addKreiseName: 'Nome',
      addKreiseDescription: 'Descrizione',
      addMembers: 'Aggiungi membri',
      createKreise: 'Crea gruppo',
      toKreiseEditMessage: 'Nota: tutte le modifiche ai membri saranno applicate immediatamente.',
      kreiseCreator: 'Creatore di questo gruppo',
      kreiseDeleteMembersLeft: 'Il gruppo può essere eliminato solo se rimane un solo membro',
      kreiseDeleteMembersLeftTitle: 'Rimuovi prima i membri',
      suggestionInNewsfeed: {
        admin: 'Amministratore',
        member: 'Membri',
        corp: `${CorpsName('ITA').single} membri`,
        corpAdmin: `${CorpsName('ITA').single} amministratore`,
      },
    },
    surveys: {
      checkVotes: 'Visualizza risultati',
      chooseOptions: {
        single: "Scegli un'opzione",
        multiple: 'Scegli più opzioni',
      },
      votesModalTitle: 'Voti',
      createModalTitle: 'Sondaggio',
      noVotes: 'Non ci sono voti',
      question: 'Domanda',
      askAQuestion: 'Fai una domanda',
      allowMultiple: 'Permetti risposte multiple',
      votes: {
        single: 'Voto',
        multiple: 'Voti',
      },
      option: 'Opzione',
      optionsLabel: 'Opzioni del sondaggio',
      anonymus: 'Sondaggio anonimo',
      create: 'Crea',
    },
    subscription: {
      subscription: 'Abonnements',
      pricePerMonth: 'EUR/Monat',
      month: 'Monat',
      day: 'Tag',
      otherPlans: 'Andere Tarife',
      subscriptionActive: 'Abonnement aktiv',
      until: 'bis',
      subscribe: 'Abonnieren',
      successfulPayment: {
        title: 'Zahlung erfolgreich',
        text: 'Dein Abonnement wurde erfolgreich abgeschlossen. Du kannst ab sofort die erweiterten Funktionen nutzen.',
      },
      cancel: {
        cancel: 'Abonnement kündigen',
        title: 'Abonnement kündigen?',
        text: 'Möchtest du dein Abonnement kündigen? Nach Ablauf der bezahlten Periode werden deine Zugriffsrechte zurückgestuft.',
      },
    },
  },
  {
    key: 'FRA',
    key2: 'fr',
    editProfile: {
      updated: 'Profil mis à jour.',
      validateFieldsViaEmail:
        "Veuillez valider les champs de données suivants par e-mail. Ce n'est qu'alors qu'ils seront soumis à la gestion centrale des membres.",
      newValue: (value: any) => `Nouvelle valeur : "${value}"`,
      resendEmail: "Ou devons-nous renvoyer l'e-mail de validation ?",
      validationPending: 'Validation en attente',
      resend: 'Renvoyer',
      resendDone:
        "L'e-mail de validation a été renvoyé. Veuillez patienter quelques minutes et vérifier également votre dossier de spam.",
      resendLong: "Ou devrions-nous renvoyer l'e-mail de validation?",
    },
    appInfoScreen: {
      title: 'À propos',
      privacy: 'Informations sur la confidentialité',
      find: 'Ici, vous pouvez trouver les',
      terms: "Conditions d'utilisation",
      and: ' et ',
      infoSection: "Infos de l'application",
      hasUpdate: 'Une nouvelle version est disponible. Cliquez ici pour mettre à jour.',
      noUpdate: 'Ceci est la version la plus récente.',
      appVerison: "Version de l'application:",
    },
    corpsScreen: {
      website: 'Site Web :',
      email: 'Adresse e-mail :',
      members: 'Membres',
      admins: 'Admins',
      info: 'Info',
      searchMember: 'Rechercher des membres',
    },
    contactsScreen: {
      empty:
        "Il n'y a pas encore d'entrées ici. Allez dans le menu principal et sélectionnez 'Recherche' → 'Nom' pour trouver de nouveaux contacts.",
    },
    dialogPanel: {
      hangOn: 'Attacher Bierjunge ?',
      ok: 'OK',
    },
    dialogs: {
      emptyTitle: 'Cliquez sur le plus dans le coin supérieur droit pour commencer une conversation.',
    },
    documentsGallery: {
      modalTitle: 'Supprimer un document',
      modalDesc: 'Supprimer ce fichier ?',
      cancel: 'Annuler',
      delete: 'Supprimer',
      deleteConfirm: 'Supprimer le fichier téléchargé (uniquement pour moi) ?',
      downloadConfirm: 'Télécharger ce fichier ?',
      availableInDownloads: `\n\n*Tous les fichiers téléchargés sont disponibles dans le dossier Téléchargements`,
    },
    drawerScreen: {
      backdrop: "Toutes les informations de base doivent être remplies avant d'utiliser l'application.",
    },
    kreiseInfoScreen: {
      creator: 'Créateur de ce chat de groupe',
      revokeAdmin: (name: string) =>
        `Voulez-vous retirer les droits d'administrateur à "${name}" ou les retirer complètement du groupe ?`,
    },
    kreisePostScreen: {
      infoParticipants: (num: string | number) => `Info & Participants (${num})`,
      subscribeTitle: "S'abonner au chat de groupe ?",
      subscribeDesc: 'Voulez-vous vous abonner à ce chat de groupe pour ne manquer aucune nouvelle ?',
      yes: 'Oui',
      later: 'Peut-être plus tard',
      dontAsk: 'Ne plus demander ce chat de groupe',
    },
    mapScreen: {
      appUser: "Utilisateur de l'application",
      viewIsForMember: 'La consultation des profils des utilisateurs est uniquement disponible pour les membres.',
      all: 'Tout',
    },
    mentoringScreen: {
      clickMentor: 'Cliquez sur le nom du mentor pour envoyer un rappel ou retirer la demande',
      all: 'Tout',
      startRequest: 'Commencer la demande :',
      startDesc:
        'Veuillez décrire votre demande de mentorat de manière aussi claire et concise que possible – cela aide le mentor à mieux évaluer votre demande et à répondre rapidement.',
      notice: 'Remarque :',
      noticeDesc:
        "Vous devez laisser jusqu'à 2 semaines pour que le mentor réponde avant d'envoyer un rappel. Retirez votre demande en cours avant de contacter un autre mentor.",
      withdrawRequest: 'Retirer la demande',
      reminder: 'Envoyer un rappel',
      reminderSuccess: "Le rappel est en cours d'envoi (+ copie pour vous)",
      rejectRequest: 'Rejeter la demande',
      requestTitle: 'Demande de mentorat',
      areas: "Domaines d'expertise",
      topics: 'Sujets',
      withReguards: (name: string, lastname: string) => `\n\n\n(...)\n\nCordialement,\n\n${name} ${lastname}`,
      rejectionMessage: (name: string, lastname: string) =>
        `Bonjour !\n\nMalheureusement, je dois décliner la demande de mentorat. Je suis heureux d'expliquer les raisons si vous demandez.\n\nCordialement,\n\n${name} ${lastname}`,
      maxMessage: (max: number | string, topic: string) => `Vous pouvez sélectionner un maximum de ${max} ${topic}.`,
      decisionOpen: 'Décision : ouverte',
      maxMentoring: 'Max. 1 demande de mentorat autorisée',
      maxParalelMentoring: 'Max. 1 demande de mentorat parallèle autorisée',
      openHere: 'Ouvrir le ',
      profileUpdated: 'Profil mis à jour !',
      mentoringProfile: 'Profil de mentorat',
      mentee: 'Mentee',
      mentor: 'Mentor',
      leaveMentoring: 'Quitter le groupe de mentorat',
      mentoringTitle: 'Mentorat',
      scrollAuto: "exemple d'onglets auto-défilants",
      stopMentoringMessageMentor: (name: string, lastname: string) =>
        `Mettre fin au mentorat avec ${name} ${lastname} ?`,
      stopMentoringMessageMentee: (name: string, lastname: string) =>
        `Mettre fin au mentorat avec ${name} ${lastname} ?`,
      mentorEndedMentorship: (name: string, lastname: string) => `Le mentor ${name} ${lastname} a mis fin au mentorat.`,
      mentorRejectedMentorship: (name: string, lastname: string) =>
        `Le mentor ${name} ${lastname} a rejeté votre demande.`,
      menteeEndedMentorship: (name: string, lastname: string) =>
        `Le mentoré ${name} ${lastname} a mis fin au mentorat.`,
      menteeRejectedMentorship: (name: string, lastname: string) =>
        `Le mentoré ${name} ${lastname} a rejeté votre demande.`,
      mentorat: 'Mentorat',
    },
    newEventScreen: {
      kreiseError: 'Veuillez choisir une cible',
      correctDate: 'Corriger la date/heure',
      correctDateMessage: 'La date/heure de fin est antérieure à la date/heure de début. Veuillez corriger.',
    },
    outRequestScreen: {
      backButton: 'Demandes de contact',
      requestMessage: (name: string, lastname: string) =>
        `Bonjour !\n\nJe souhaite vous ajouter à mes contacts.\n\nCordialement,\n\n${name} ${lastname}`,
    },
    participantsScreen: {
      interestedParties: 'Parties intéressées',
      removeGuestTitle: (name: string) => `Supprimer ${name} ?`,
      removeGuestDesc: (name: string) => `Supprimer "${name}" de la liste des participants ?`,
      informUser: (name: string) => `Informer "${name}" qu'il a été retiré de la liste des participants`,
    },
    partParticipantsScreen: {
      interestedParties: 'Parties intéressées',
      removeParticipantTitle: (name: string, lastname: string) =>
        `Supprimer le participant "${name} ${lastname}" de la liste des participants ?`,
      removeParticipantDesc: (name: string, lastname: string) => `Supprimer "${name} ${lastname}" ?`,
    },
    documentsScreen: {
      deleteTitle: 'Fichiers sélectionnés :',
      cancel: 'Annuler',
      uploadData: 'Télécharger les données',
    },
    postsScreen: {
      ads: 'Annonces...',
      oldPostsForMembers: 'Les anciens posts sont uniquement visibles par les membres.',
    },
    profileBasisScreen: {
      editPhoto: 'Modifier la photo',
    },
    profileBenachrichtigungenScreen: {
      fillData: "Toutes les informations de base doivent être complétées avant que l'application puisse être utilisée.",
      changesInChat: 'Vous pouvez modifier ces paramètres dans le chat de groupe respectif.',
      commentsWarning1:
        "Il n'est pas recommandé de désactiver les notifications par e-mail pour les commentaires sur vos propres posts, car des commentaires importants pourraient être manqués.",
      commentsWarning2:
        "Il n'est pas recommandé de désactiver les notifications par e-mail pour les messages privés, car des messages importants pourraient être manqués.",
      messagesWarning1:
        "Il n'est pas recommandé de désactiver les notifications par e-mail pour les messages privés, car vous recevez généralement très peu de messages privés par rapport aux posts de groupe. Cependant, ils sont importants car ils concernent généralement des demandes spécifiques adressées à vous. Ces messages privés (importants) pourraient être manqués si les notifications sont désactivées.",
      messagesWarning2:
        "Il n'est pas recommandé de désactiver les notifications par e-mail pour les commentaires sur vos propres posts, car des commentaires importants pourraient être manqués.",
      areYouSure: 'Êtes-vous sûr ?',
      turnOff: 'Désactiver',
      maybeLater: 'Peut-être plus tard',
      notRecommended: 'Paramètre non recommandé',
      ok: 'OK',
    },
    profileBerufScreen: {
      fillData: "Toutes les informations de base doivent être complétées avant que l'application puisse être utilisée.",
      defaultDate: "Jusqu'à aujourd'hui",
      noArea: 'Aucune zone',
      furtherActivity: 'Activité supplémentaire',
      deleteConfirm: 'Voulez-vous vraiment supprimer cette entrée ?',
      ok: 'OK',
    },
    profileCorpsScreen: {
      corpsname: 'Nom du projet',
      createHint: 'Une nouvelle entrée est en cours de création.',
      requestHint: 'La création de la nouvelle entrée est demandée.',
      status: 'Statut',
      deleteConfirm: (name: string) => `Voulez-vous vraiment supprimer votre adhésion à ${name} ?`,
      ok: 'OK',
      fillData: "Toutes les informations de base doivent être complétées avant que l'application puisse être utilisée.",
      duplicatedCorps: (name: string) => `Le ${name} est déjà dans le profil, veuillez vérifier une fois.`,
      missingCorpName: 'Veuillez fournir le nom du corps.',
      waitingActivation: "En attente d'activation",
      requestExistingMessage: (name: string) =>
        `L'adhésion à "${name}" a été demandée. Vous serez notifié une fois activé.`,
      requestNewMessage: (name: string) =>
        `La création de "${name}" a été demandée. Vous serez notifié une fois activé.`,
      createMessage: (name: string) => `"${name}" a été créé et ajouté à votre profil.`,
    },
    profileSonstigenScreen: {
      other: 'Autre (veuillez saisir)',
    },
    profileDetailsScreen: {
      email: 'E-mail',
    },
    profilePrivacyScreen: {
      fillData: "Toutes les informations de base doivent être complétées avant que l'application puisse être utilisée.",
    },
    profileStudiumScreen: {
      deleteConfirm: 'Voulez-vous vraiment supprimer cette entrée ?',
      ok: 'OK',
      fillData: "Toutes les informations de base doivent être complétées avant que l'application puisse être utilisée.",
    },
    reportScreen: {
      reportUser: 'Signaler un utilisateur',
      notMember: "L'utilisateur n'est plus membre",
      fakeProfile: "L'utilisateur crée des profils faux.",
      violations1: "La photo de profil de l'utilisateur enfreint les directives.",
      violations2: "Les déclarations de l'utilisateur enfreignent les directives.",
      violations3: "L'utilisateur envoie du spam dans le chat.",
      violations4: 'Signaler/désigner un contenu potentiellement offensant',
      violations5: "Signaler/designer d'autres utilisateurs pour de possibles infractions",
      reportReason: 'Raisons pour signaler',
      sendReport: 'Envoyer',
      cancel: 'Annuler',
      showContent: 'Afficher le contenu de cet utilisateur',
      hideContent: 'Masquer le contenu de cet utilisateur',
    },
    resetPasswordScreen: {
      incorrectEmail: 'Votre adresse e-mail est incorrecte.',
    },
    serverIsDownScreen: {
      serverMaintenance: 'Maintenance du serveur',
    },
    signInScreen: {
      dontHaveAccount: 'Pas encore de compte ?',
    },
    signUpScreen: {
      login: 'Connexion',
      emailMatchError: "L'e-mail et la confirmation de l'e-mail ne correspondent pas.",
      passwordMatchError: 'Le mot de passe et la confirmation du mot de passe ne correspondent pas.',
      incorrectEmail: 'Votre adresse e-mail est incorrecte !',
      pleaseSelect: 'Veuillez sélectionner',
      accountExistsTitle: 'Compte existant',
      accountExistsDesc: 'Vous avez déjà un compte avec cette adresse e-mail.',
      toLogin: 'Pour vous connecter',
    },
    subscriptionsScreen: {
      subscribed: 'Abonnements:',
      noSubscribed: 'Aucune corporation abonnée trouvée',
      otherSubscriptions: 'Autres organisateurs abonnés:',
      noOtherSubscriptions: `Aucun organisateur abonné trouvé`,
    },
    sucheScreen: {
      searchFor: 'Résultat de recherche pour :',
      filter: 'Filtrer :',
      name: 'Nom',
      branch: 'Secteur',
      function: 'Fonction',
      status: 'Statut',
      city: 'Ville',
      industry: 'Industrie',
      business: 'Entreprise',
      profession: 'Profession',
      training: 'Formation',
      studyProgram: "Programme d'études",
      college: 'Collège',
      cancel: 'Annuler',
      searchBar: 'Barre de recherche',
      searchTab: 'Onglet de recherche',
      searchTitle: 'Recherche',
      scrollable: "Exemple d'onglets auto-défilants",
    },
    dialogComponent: {
      close: 'Fermer',
    },
    phoneInputComponent: {
      noCountry: 'Aucun pays trouvé.',
    },
    cropImageHandlerComponent: {
      cancel: 'Annuler',
      crop: 'Recadrer',
    },
    datePickerComponent: {
      cancel: 'Annuler',
      ok: 'OK',
      date: 'Date/Heure',
      day: 'Jour',
      month: 'Mois',
      year: 'Année',
      wrongDate: 'Date incorrecte',
    },
    timePickerComponent: {
      hours: 'Heures',
      minutes: 'Minutes',
    },
    responseHandlerComponet: {
      supportEmail: 'Erreur inconnue. Veuillez contacter ${support_email}',
    },
    searchModalComponent: {
      selectionError: 'Veuillez sélectionner au moins une entrée.',
      maximumSelection: (num: string | number) => `Veuillez choisir un maximum de ${num} entrées!`,
    },
    customDropzoneComponent: {
      dropFiles: 'Déposez des fichiers ici',
      clickHere: ' ou cliquez ici pour sélectionner ',
    },
    documentsGalleryComponent: {
      clickToOpen: 'Cliquez pour ouvrir',
      clickToDownload: 'Cliquez pour télécharger',
    },
    eventToolbarComponent: {
      noRegistration: "Pas d'inscription !",
      notLoggedId: "Vous n'êtes pas encore connecté. Ceci est juste une expression d'intérêt.",
      registerVia: "L'inscription se fait via :",
    },
    likeComponent: {
      externalGuest: 'Invité externe',
      invitedBy: (name: string) => `Invité par ${name}`,
    },
    membersComponent: {
      interested: 'Intérêt exprimé',
      loggedIn: 'Connecté',
      um: ' à ',
      invitedBy: (name: string) => `Invité par ${name}`,
    },
    multipleSearchableInputComponent: {
      maximalMembers: 'Veuillez spécifier un maximum de ${maximal_membership_count} adhésions.',
    },
    multipleSelectModalComponent: {
      all: 'Tout',
    },
    offlineModalComponent: {
      checkInternet: 'Veuillez vérifier votre connexion Internet.',
    },
    postsToolbarComponent: {
      membersOnly: 'Seuls les membres peuvent publier.',
    },
    zusagenModalComponent: {
      incorrectEmail: 'Votre e-mail est incorrect',
      required: 'Ce champ est requis.',
      name: 'Nom',
      email: 'E-mail (facultatif)',
      invitedBy: 'Invité par',
      save: 'Sauvegarder',
      cancel: 'Annuler',
      warning1:
        "Note : Veuillez vous inscrire ici en tant que ${guest_naming_singular} ! Si vous avez un compte sur l'application, veuillez vous connecter et confirmer officiellement en tant que membre. Merci !",
      warning2: 'En vous inscrivant, vous acceptez les ',
      and: ' et ',
      to: '.',
    },
    formInputComponent: {
      cancelConfirmation: 'Annuler la confirmation ?',
    },
    logoComponent: {
      alt: "Logo de l'application",
    },
    customHistoryHook: {
      news: 'Message',
    },
    postContentComponent: {
      orginized: 'Organisateur:',
      date: 'Date:',
      ort: 'Lieu:',
    },
    beerSmileComponent: {
      bierjunge: 'Bierjunge!',
    },
    buttonComponent: {
      examination: 'Examen:',
    },
    global: {
      all: 'Tous',
      postSubject: 'Sujet de votre publication',
      showMore: 'Lire plus',
      toRegister: "S'inscrire",
      addToCalendar: 'iCal / ICS',
      interested: 'Intéressé',
      loggedIn: 'Connecté',
      tilToday: 'Jusqu`à aujourd`hui',
      status: 'Statut',
      deletePost: 'Supprimer le post ?',
      pdf: '(PDF)',
      time: 'Temps',
      birthday: (age: number | string, name: string, lastname: string) =>
        `${age}ème anniversaire de ${name} ${lastname}`,
      eur: 'EUR',
      price: 'Prix :',
      priceForMe: 'Prix pour moi:',
      ok: 'OK',
      likesCount: (num: string | number) => `J'aime (${num})`,
      event: 'Événement',
      standardMessage: 'Nous générons un sujet pour votre post. Ce sujet convient-il ?',
      optionalMessage:
        "De quoi parle votre post ? Écrivez un court sujet. Vous pouvez également laisser l'IA générer une suggestion pour vous.",
      optionalMessageNote: 'En utilisant cette fonctionnalité, vous acceptez les ',
      chatGPTTerms: "Conditions d'utilisation de ChatGPT",
      chatGPTTermsLink: 'https://openai.com/policies/terms-of-use',
      optionalMessageNoteEnding: '.',
      postSubjectTitle: 'Sujet du post',
      generate: 'Générer',
      defaultSubject: 'Sujet par défaut',
      memebership: 'Adhésion',
      applyMemebership: "Postuler pour l'adhésion ?",
      pendingMembership: "Demande d'adhésion soumise - décision en attente.",
      requestAlreadySent: 'Demande déjà envoyée',
      requestedMemebership: (kreiseName: string) => `L'adhésion à "${kreiseName}" a été demandée.`,
      yes: 'Oui',
      no: 'Non',
      change: 'Changer',
      passwordOldMatchNewError: 'Les anciens et nouveaux mots de passe correspondent',
      passwordLenghtError: 'Le mot de passe doit comporter au moins 6 caractères.',
      passwordsMatchError: 'Les anciens et nouveaux mots de passe correspondent',
      passwordsMatchError2: 'Le mot de passe et la confirmation ne correspondent pas.',
      currentPassword: 'Mot de passe actuel',
      newPassword: 'Nouveau mot de passe',
      confirmPassword: 'Confirmer le mot de passe',
      changePassword: 'Changer le mot de passe',
      cancel: 'Annuler',
      fits: 'Correspond',
      selectChatTitle: 'Sélectionner un chat de groupe',
      selectChatDesc:
        "Sélectionnez au moins un chat favori pour envoyer votre post. Vous pouvez en trouver d'autres dans le menu principal sous 'Chats de groupe'.",
      socialMedia: 'Réseaux sociaux',
      generatedText: 'Nous générons un texte pour les réseaux sociaux. Ce texte vous convient-il ?',
      usePhoto: 'Merci pour votre post ! Pouvons-nous également utiliser votre photo pour les réseaux sociaux ?',
      maximumLenght: 'Maximum 20 000 caractères.',
      userSharedNewPicture: 'Un utilisateur de notre application a partagé une nouvelle photo !',
      contributionTo: (kreiseName: string) => `Contribution à “${kreiseName || 'Tous'}”`,
      postWarning1:
        "Allez-y ! Vous aurez encore la possibilité d'ajouter votre propre texte à l'étape suivante. De plus, votre post sera examiné.",
      postWarning2:
        "Après la soumission, votre post sera examiné par un administrateur. Pour toute question, n'hésitez pas à contacter ",
      postWarning3: ' ou ',
      AGB: 'Conditions Générales',
      DSE: 'Politique de confidentialité',
      withCheckbox: "En activant initialement mon compte via la fonction 'Mot de passe oublié', j'accepte les ",
      withoutCheckbox:
        "En activant initialement votre compte via la fonction 'Mot de passe oublié', vous acceptez les ",
      tou: "Conditions d'utilisation",
      dse: 'Politique de confidentialité',
      impressum: 'Mentions légales',
    },
    responseHandler: {
      messageLabel: `Erreur inconnue. Veuillez contacter ${support_email}.`,
      expiredLinkLabel: 'Lien expiré. Demander un nouveau changement de mot de passe.',
      emailExistLabel: 'Désolé, cette adresse électronique est déjà enregistrée.',
      profileUpdated: 'Profil actualisé',
      profileUpdatedCorpsError: `Profil mis à jour ! Cependant, le ${
        CorpsName('FRA').single
      } suivant n'a pas pu être ajouté en raison d'un code de connexion incorrect :`,
      appNeedsUpdate: "Veuillez mettre à jour l'application dans l'App Store / Google Play.",
      maximalVideoSize: 'La taille maximale des fichiers est de 2 Go !',
    },
    report: {
      notBelongLabel: `L'utilisateur n'appartient plus à un ${CorpsName('FRA').single}`,
      incProfileLabel: "L'utilisateur fait des offres de profil incorrectes",
      badPicLabel: "La photo de profil de l'utilisateur enfreint les directives",
      badComLabel: "Les commentaires de l'utilisateur enfreignent les lignes directrices",
      spamLabel: "L'utilisateur envoie du spam dans le chat",
      reasonsLabel: 'Raisons de la déclaration',
    },
    drawer: {
      subscription: 'Subscription',
      news: 'Nouvelles',
      kreise: KreiseName('FRA').multi,
      search: 'Recherchez',
      documents: `${DocumentsName('FRA')}`,
      calendar: CalendarName('FRA'),
      contacts: 'Contacts',
      requests: 'Demandes',
      messages: MessagesName('FRA'),
      profile: ProfileName('FRA'),
      logout: 'Déconnexion',
      map: 'Carte',
      mentoring: 'Mentorat',
    },
    imageCropper: {
      cropLabel: 'Culture',
      cancelLabel: 'Annuler',
      chooseLabel: 'Choisir',
    },
    signIn: {
      register: 'Registre',
      title: 'Veuillez vous connecter',
      loginPlaceholder: 'Adresse e-mail',
      passwordPlaceholder: 'Mot de passe',
      forgotPassword: 'Oublié votre mot de passe ?',
      loginBtn: 'Connexion',
      noAccount: 'Pas encore de compte ?',
      description0: "Si vous avez des questions, n'hésitez pas à nous contacter à l'adresse",
      description1: 'ou au',
      description2: '.',
      loginValidation: 'Votre adresse électronique est incorrecte',
      passwordValidation: 'Le mot de passe doit comporter au moins 6 caractères',
    },
    search: {
      name: 'Nom',
      corps: CorpsName('FRA').single,
      country: CountryName('FRA'),
      city: 'Ville',
      industry: IndustryName('FRA'),
      company: CompanyName('FRA'),
      profession: 'Profession',
      hobby: 'Hobby',
      cultural: 'Sponsor culturel',
      study: 'Étude',
      office: 'Bureau',
      degreeProgram: 'Programme de diplôme',
      university: 'Université',
      position: FunctionName('FRA'),
      status: 'Statut',
      title: 'Résultat de la recherche pour',
      filterTitle: 'Filtrer par',
      global: 'Tout',
      activity: ActivityName('FRA'),
    },
    resetPassword: {
      enterEmail: 'Veuillez entrer votre adresse électronique',
      inputPlaceholder: 'Adresse e-mail',
      sendBtn: 'Envoyer',
      cancelBtn: 'Annuler',
      message: "Si l'adresse électronique nous est connue, un lien lui a été envoyé.",
      desc: 'Tu dois saisir exactement l`adresse e-mail qui est enregistrée dans l`application. Vérifie éventuellement dans tes e-mails de quelle adresse il s`agit.',
    },
    password: {
      resetLabel: 'Entrez un nouveau mot de passe',
      inputLabel: 'Entrez un nouveau mot de passe',
    },
    signUp: {
      createAccount: 'Créer un compte',
      inputPlaceholder: `Code de connexion de votre ${CorpsName('FRA').single}`,
      sendBtn: 'Continuer',
      descFirst: 'En vous inscrivant, vous acceptez notre ',
      descSecond: 'et confirmez que vous avez lu notre',
      descThird: `Si vous avez des questions, n'hésitez pas à nous contacter à l'adresse ${support_email} ou au +49 170 49 805`,
      terms: 'conditions générales',
      policy: 'politique de confidentialité.',
      description: 'Remarque',
      generatePassword: 'Generate password',
      noCodeSuccessRegister:
        'Nous vous remercions de votre inscription ! Vous serez informé(e) par e-mail dès que vous aurez été activé(e).',
    },
    signUpDefault: {
      withoutCode: 'Sans code',
      createAccount: "Il suffit de s'inscrire ici",
      withCode: "Avec code d'invitation",
      simpleRegister: "Sans code d'invitation",
      existingAccount: 'Compte déjà disponible ?',
      descr01: "Je suis d'accord avec le",
      descr0: "En s'inscrivant, l'utilisateur accepte notre",
      descr1: 'et confirmez que vous avez lu et compris notre. ',
      descr2: '',
      guest: 'Registrieren als Gast',
      withCodeWithDescr: `Registrieren als ${member_naming_singular}`,
    },

    signUpManual: {
      title: 'Inscription',
      photoDesc: 'Ajouter une photo de profil',
      genderInput: 'Genre',
      firstName: 'Prénom(s)',
      lastName: 'Nom de famille',
      club: CorpsName('FRA').single,
      specify: 'veuillez préciser',
      emailPlaceholder: "l'adresse électronique",
      emailHint: "Veuillez utiliser l'adresse e-mail utilisée à long terme.",
      repeatEmailPlaceholder: "Répéter l'adresse E-mail",
      passwordPlaceholder: 'Définir le mot de passe',
      repeatPasswordPlaceholder: 'Répéter le mot de passe',
      postcode: 'Code postal',
      place: 'Lieu de résidence',
      country: 'Pays',
      loginCode: "Code d'invitation",
      mobilePlaceholder: 'Mobile (non visible dans le profil)',
      corps: SignUpCorpsName('FRA'),
      searchCountry: 'Suche nach Land',
    },
    backToLogin: {
      desc: 'Vous avez déjà un compte ? ',
      login: 'Login',
    },
    backToSignUp: {
      desc: 'Pas encore de compte? ',
      register: 'Registre',
    },
    privacy: {
      desc: "Oui, j'accepte la",
      btn: 'conditions générales',
      zu: '',
      map: {
        title: 'Avis de confidentialité',
        privacyNotice: `Cette carte a été mise en œuvre en conformité avec le GDPR. De plus amples informations sont disponibles dans notre politique de confidentialité.`,
        additionalInfo: {
          intro: 'Les épingles sur la carte ne sont',
          inaccurateDisplay: 'affichées que de manière imprécise',
          locationBasis: ', sur la base du code postal/de la ville, mais sans la rue/le numéro.',
          noConclusion: 'Cela signifie qu’aucune conclusion',
          onAddresses: 'ne peut être tirée sur les adresses spécifiques',
          conclusion: 'des utilisateurs.',
        },
        agree: 'Compris',
        linkName: 'Politique de confidentialité',
        options: {
          doNotShowAgain: 'Ne plus afficher',
          doNotShowMeOnMap: 'Ne pas m’afficher sur la carte (même approximativement)',
        },
      },
      restrictions: {
        overall: 'Cette fonctionnalité est accessible uniquement aux membres.',
        overallWilkar: "Cette fonctionnalité n'est pas accessible pour des raisons de confidentialité.",

        profile:
          "Seuls les membres peuvent voir les profils des utilisateurs. Tu peux toutefois envoyer un message privé à l'utilisateur.",
        groupchat: `Le ${KreiseName('FRA').single} est accessible uniquement aux membres.`,
        groupchatWilkar: `Pour des raisons de confidentialité, le ${
          KreiseName('FRA').single
        } est accessible uniquement aux membres.`,
        comments: 'Les commentaires sont visibles uniquement par les membres.',
        commentsWilkar:
          'Pour des raisons de confidentialité, les commentaires sont visibles uniquement par les membres.',
        likes: 'La liste des likes est visible uniquement par les membres.',
        post: 'Seuls les membres peuvent publier des posts.',
        participantsList: 'La liste des participants est visible uniquement par les membres.',
        commentsOnlyForMembers: 'Les commentaires sont visibles uniquement par les membres.',
        postsLimitation: 'Les anciens posts sont visibles uniquement par les membres.',
      },
      settings: {
        guestPost: {
          title: 'Les invités vérifiés peuvent voir mes posts',
          ask: 'Toujours demander',
          no: 'Non',
          yes: 'Oui',
        },
      },
      guestModal: {
        title: 'Les invités peuvent-ils voir ce post ? Tu peux modifier ce paramètre plus tard.',
        dontAskAgain: 'Ne plus demander (tu peux modifier ce paramètre sous “Profil” → “Confidentialité”).',
      },
      post: {
        ifShown: `Masquer pour ${guest_naming_plural}`,
        ifHidden: `Afficher pour ${guest_naming_plural}`,
        ifShownModal: `Le post n\'est plus visible pour ${guest_naming_plural}.`,
        ifHiddenModal: `Le post est maintenant visible pour ${guest_naming_plural}.`,
      },
    },
    searchModal: {
      landTitle: 'Choisissez un pays',
      corpsTitle: `Sélectionner un ${CorpsName('FRA').single}`,
      pleaseSelect: 'Veuillez choisir',
    },
    btn: {
      sendBtn: 'Envoyer',
      cancelBtn: 'Annuler',
      select: 'Sélectionnez',
      cancelEventBtn: acceptEventName('FRA').cancel,
      acceptBtn: acceptEventName('FRA').accept,
      saveBtn: 'Sauvegarder',
      clearBtn: 'Clair',
      acceptReqBtn: 'Confirmer',
      declineReqBtn: 'Déclin',
      updateBtn: 'Mise à jour',
      declineBtn: 'Refuser',
      yesUnderstood: 'Ja, verstanden',

      ok: 'OK',
      cancel2: 'Annuler',
    },
    validation: {
      email: 'Votre adresse électronique est incorrecte',
      password: 'Le mot de passe doit comporter au moins 6 caractères',
      repeatEmail: 'Le courriel et le courriel de relance ne correspondent pas',
      repeatPassword: 'Le mot de passe et la répétition ne correspondent pas',
      required: 'Ce champ est obligatoire',
      matchPasswords: "Correspondance entre l'ancien et le nouveau mot de passe",
      dontMatchPasswords: 'Le mot de passe et la répétition ne correspondent pas',
    },
    init: {
      title: 'Chargement',
      overlayLabel: 'Veuillez vérifier votre connexion Internet',
    },
    screenTitles: {
      postsTitle: 'Nouvelles',
      circlesTitle: KreiseName('FRA').multi,
      searchTitle: 'Recherchez',
      profileTitle: ProfileName('FRA'),
      eventTitle: CalendarName('FRA'),
      eventDetailsTitle: 'Événement',
      newEventTitle: 'Ajouter un événement',
      contactsTitle: 'Contacts',
      contactReqTitle: 'Demandes de contact',
      reqDetailsTitle: 'Demande de renseignements',
      outReqTitle: 'Demande de contact',
      messagesTitle: MessagesName('FRA'),
      changePasswordTitle: 'Changer de mot de passe',
      appInfoTitle: 'Info',
      reportTitle: 'Signaler un utilisateur',
      createKreiseTitle: 'Créer un chat de groupe',
      eventParticipants: 'Teilnehmer',
    },
    profileDetails: {
      showFilters: 'Afficher les filtres',
      user: 'Users',
      mainTitleLabel: ProfileName('FRA'),
      basisLabel: 'De base',
      clubLabel: CorpsName('FRA').single,
      clubLabelPlural: CorpsName('FRA').multi,
      professionLabel: 'Profession',
      studyLabel: StudyName('FRA').short,
      uploadPhotoLabel: 'Télécharger une photo',
      editPhotoLabel: 'Modifier la photo',
      profileCompletedLabel: 'Modifier la photo',
      profileCompletedGLabel: '',
      birthday: 'Date de naissance',
      functionLabel: 'Fonction',
      dateOfJoinLabel: 'Date de la pièce',
      positionDescLabel: 'Profession',
      companyLabel: CompanyName('FRA'),
      nameLabel: 'Nom',
      websiteLabel: 'Site web',
      fromLabel: 'Tiré de',
      toLabel: 'à',
      delete: 'supprimer',
      untilToday: "Jusqu'à aujourd'hui",
      degreeLabel: StudyName('FRA').abshchluss,
      miscellaneos: 'Miscellaneos',
      hobbies: 'Hobbies',
      cultural: 'Parrainage culturel',
      positionLabel: 'Position',
      typeOfEmplLabel: "Type d'emploi",
      activityLabel: ActivityName('FRA'),
      careerStageLabel: 'Étape de la carrière',
      activityDescLabel: "Description de l'activité",
      industryLabel: IndustryName('FRA'),
      areaLabel: 'Domaine',
      employmentPeriodLabel: "Période d'emploi",
      universityLabel: 'Université',
      courseLabel: "Cours d'études",
      firmaLabel: 'Firma',

      ageLabel: 'Âge',
      birthdayLabel: 'Anniversaire',
      editProfileLabel: 'Modifier le profil',
      addContactLabel: 'Ajouter aux contacts',
      sendMessageLabel: 'Envoyer un message',
      requestSentLabel: "Envoyer une demande d'ami",
      sendRequestSuccessLabel: 'Demande envoyée avec succès',
      reportSentLabel: 'Utilisateur déjà signalé',
      addReportLabel: 'Envoyer le rapport',
      street: 'Rue + Nr. ',
      houseLabel: 'Numéro de la maison',
      houseNumberLabel: 'Numéro de la maison',
      partner: 'Nom du partenaire',
      mobileNumberLabel: 'Numéro de téléphone mobile',
      handphoneNumberLabel: 'Numéro de téléphone portable',
      telephoneNumberLabel: 'Numéro de téléphone',
      leibbursch: 'Mentor',
      leibfuechse: 'Mentee',

      contactsLabel: 'Contacts',
      showBirthday: 'Contacts Voir mon anniversaire',
      bio: 'À propos de moi',
      addDocuments: 'Ajouter des documents à votre profil',
      image: 'Image',
      video: 'Vidéo',
      pdf: 'PDF',
      link: 'Lien',
      guest: 'Invité',
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      title: 'Titre',
      email: 'E-Mail',
      name: 'Prénom',
      lastname: 'Nom de famille',
      country: CountryName('FRA'),
      wohnort: 'Ville',
      postcode: 'Code postal',
      inReview: 'Confirmation par e-mail en attente',
    },
    calendar: {
      birthdayLabel: 'Anniversaire du',
      noEventsLabel: "Il n'y a pas d'événements pour la date sélectionnée",
      allTabLabel: 'Tous',
      myTabLabel: 'Mes événements',
      clubsTabLabel: `${CorpsName('FRA').single}`,
      subsTabLabel: 'Abonnements',
      subscribedTabLabel: 'Inscrit',
      otherTabLabel: 'Autres',

      error: 'La date de fin doit être postérieure à la date de début.',
      filter: {
        all: 'Tous',
        invited: 'Invités',
        confirmed: 'Confirmés',
      },
    },
    eventLabels: {
      organizer: 'Organizer',
      registrationVia: 'Inscription via',
      maximalParticipants: 'Nombre maximum de participants',
      participantsWarning: 'Le nombre de participants est limité',
      interested: 'Pas d`intérêt',
      notInterested: 'Parties intéressées',
      removeFromWaitList: 'Supprimer de la liste d`attente',
      addEventTitleImage: 'Ajouter une image de titre',
      changeEventTitleImage: 'Changer l`image du titre',
      inviteTo: 'Invitation à',
      addEndDate: 'Saisir la date/l`heure de fin',
      eventLabel: 'Événement',
      startLabel: 'Commence à',
      locationLabel: 'Lieu',
      participantLabel: 'Participants',
      moreInfo: 'Plus d`informations',
      commentsLabel: 'Commentaires',
      noCommentsLabel: 'Pas encore de commentaires',
      eventStartLabel: 'Démarrer',
      eventEndLabel: 'Fin',
      descLabel: 'Description',
      creatorLabel: 'Créé par',
      titleLabel: 'Titre',
      deleteEventTitle: 'Supprimer l`événement ?',
      deleteEventLabel: 'Supprimer cet événement?',
      clubsTitle: CorpsName('FRA').single,
      gruppenchat: 'Sélectionner le chat de groupe',
      contactsTitle: 'Contacts',
      delCommentLabel: 'Supprimer ce commentaire?',
      sendInvite: "Envoyez l'invitation à",
      selectCorps: 'Sélectionner le public cible',
      externalGuest: 'Invités externes',
      externalGuestPlaceholder: 'Saisir l`adresse e-mail',
      attachmentLabel: 'Pièce jointe',
      shareLinkLabel: 'Lien de partage',
      linkLabel: 'Inscrire un invité',
      copyLinkLabel: 'Copier le lien',
      iCalDownload: 'Fichier calendrier',
      timezone: 'Fuseau horaire',
      visibility: 'Sichtbarkeit',
      errorDate: 'La date se situe dans le passé',
      startAfterEndError: 'La date de fin doit être postérieure à la date de début',
      members: 'Membres',
      waitlist: 'Liste d`attente',
      onWaitlist: 'sur la liste d`attente',
      leaveWaitlist: 'Quitter la liste d`attente ?',
      leaveWaitlistDesc:
        'Retirer de la liste d`attente ?\n\n Si vous rejoignez la liste d`attente plus tard, vous pourriez être placé en fin de liste.',

      limitAchieved: (limit: number) =>
        `Le nombre maximum de participants de ${limit} a été atteint. Vous avez été placé sur la liste d'attente.`,
      visibilityOptions: {
        allWithoutGuests: 'Membres et sur le site Web',
        invited_member: 'Membres invités uniquement',
        verified_member: 'Tous les membres',
        authorized_member: 'Membres et invités vérifiés',
        all: 'Membres, invités et sur le site web',
      },
      modalEvent: {
        title: 'Événement créé',
        subTitle: 'L`événement a été créé.\n\n',
        content:
          'Selon les paramètres de l`utilisateur, des notifications push/mail sont envoyées pour l`événement. \n\nSi vous l`avez configuré sous "Profil" → "Notifications", vous recevrez également une notification par e-mail de l`événement.',
      },
      modalComment: {
        title: 'Qui avertir ?',
        message: 'Qui doit être informé de ton commentaire ?',
        optionAll: 'Tous les participants',
        optionNone: 'Organisateur(s) uniquement',
        button: 'Envoyer un commentaire',
      },
      cancelModal: {
        title: 'Annuler ou supprimer ?',
        message: 'Annuler ou supprimer un événement ?',
        optionAll: 'Annuler (les participants sont informés)',
        optionNone: 'Supprimer (les participants ne sont pas informés)',
      },
      updateEventModal: {
        title: 'Envoi d`autres invitations',
        label: 'Les autres groupes ont été invités.',
        message:
          'En fonction des paramètres, des notifications push ou des notifications par e-mail concernant l`événement sont désormais envoyées aux autres groupes.',
      },
      eventRegistrationType: {
        app: 'App',
        event: 'Lien/Adresse Email',
        enterLink: 'Ajouter un lien/une adresse e-mail ou autre',
      },
      parts: {
        part: 'Partie',
        addPart: 'Ajouter un point du programme',
        hint: 'Explication',
        question: 'Question',
        single: 'Une seule option sélectionnable',
        multi: 'Plusieurs options disponibles',
        freeText: 'Réponse en texte libre',
        quiz: 'Quiz',
        questionnaire: 'Questionnaire',
        addQuestionnaire: 'Ajouter un questionnaire',
        questionnaireType: 'Options de réponse',
        option: 'Option',
        addOption: 'Ajouter une option',
        showMembers: 'Participants',
        youJoined: 'Vous participez à:',
        totalToPay: 'Montant total à payer:',
        yourAnswer: 'Votre réponse',
        visibleForCreators: 'Visible uniquement pour les créateurs',
        allAnswers: 'Toutes les réponses:',
        addUsers: 'Ajouter des utilisateurs',
        isAnonym: 'Vote anonyme',
        isAnonymQuiz: 'Quiz anonyme',
      },
    },
    rrEventLabels: {
      title: 'Répétition',
      MO: 'Lundi',
      TU: 'Mardi',
      WE: 'Mercredi',
      TH: 'Jeudi',
      FR: 'Vendredi',
      SA: 'Samedi',
      SU: 'Dimanche',
      YEARLY: { single: 'Année', regular: 'Annuellement' },
      MONTHLY: { single: 'Mois', regular: 'Mensuel' },
      WEEKLY: { single: 'Semaine', regular: 'Hebdomadaire' },
      DAILY: { single: 'Jour', regular: 'Quotidien' },
      SINGLE: { single: 'Unique', regular: 'Unique' },
      CUSTOM: {
        single: 'Personnalisé...',
        regular: 'Personnalisé...',
      },
      freq: 'Fréquence',
      interval: 'Intervalle',
      notRepeat: 'Unique',
      every: 'Chaque',
      month: 'mois',
      week: 'semaine',
      last: 'dernier',
      on: 'le',
      everyMonthOn: 'Chaque mois le',
      ofTheMonth: 'du mois',
      editLabel: 'Quels rendez-vous souhaitez-vous modifier ?',
      thisDate: 'Seulement cette date',
      thisDateAndFollowing: 'Cette date et toutes les suivantes',
      allDates: 'Toutes les dates',
      after: 'Après',
      time: 'temps',
      times: 'fois',
      neverEnds: 'Ne finit jamais',
      until: "jusqu'au",
      repeatedDefault: ({ frequency, freq }: { frequency: string; freq: number }) =>
        `Se répète chaque${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency}`,
      repeatedMonthly: ({ day, interval }: { day: string; interval: number }) =>
        `Se répète chaque${interval == 1 ? '' : ` ${interval}.`} mois le ${day.length > 2 ? day : `${day}.`} .`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }: { day: string; interval: number; bysetpos: number }) =>
        `Se répète chaque ${bysetpos == -1 ? ' dernier' : `${bysetpos}.`} ${day} du ${
          interval === 1 ? '' : `${interval}.`
        } mois.`,
      repeatedYearly: ({ interval }: { interval: number }) =>
        `Se répète chaque ${interval == 1 ? '' : `${interval}.`} an.`,
      repeatedWeekly: ({ interval, byweekday }: { interval: number; byweekday: any }) =>
        `Se répète chaque${interval != 1 ? ` ${interval}.` : ''} semaine${
          !!byweekday?.length
            ? ' le ' + byweekday?.slice(0, -1).join(', ') + (byweekday?.length > 1 ? ' et ' : '') + byweekday?.slice(-1)
            : ''
        }.`,
      repeatedDaily: ({ interval }: { interval: number }) =>
        `Se répète chaque${interval != 1 ? ` ${interval}.` : ''} jour.`,
    },
    requestsLabels: {
      receiveLabel: 'Reçu',
      sentLabel: 'Envoyé',
    },
    post: {
      adTitle: 'Information/Advertisement',
      toKreise: 'à',
      comment: 'Commentaires',
      myPost: 'Moi',
      openPdf: 'Cliquez pour ouvrir',
      deletePost: 'Supprimer ce poste?',
      posted: 'Publié sur',
      commented: 'Commenté',
      contacts: 'Contacts',
      sendToContacts: 'Message à tous:',
      enterText: 'Schreibe hier eine Nachricht',
      sendToCircle: `Entrez le message dans ce ${KreiseName('FRA').single}`,
      sendComment: 'Entrez un commentaire',
      longMessage: '20,000 characters maximum',
      noComments: 'Pas encore de commentaires',
      deleteComment: 'Supprimer le commentaire?',
      deleteEventPost: "Supprimer ce message ? Cela ne supprime pas l'événement.",
      editPostLabel: 'Edit post',
      editingPostLabel: 'Editing mode',
      deletePostLabel: 'Delete post',
      editedAt: 'Edited',
      pinned: 'Angepinnt.',
      actions: {
        label: 'Aktionen posten',
        pin: {
          label: 'Beitrag anpinnen',
          once: 'Einmalig nach oben rücken',
          d3: '3 Tage',
          d5: '5 Tage',
          w1: '1 Woche',
          w2: '2 Wochen',
          unpin: 'Nicht mehr anpinnen',
        },
      },
    },
    circles: {
      circlesTitle: `${KreiseName('FRA').multi} souscrits`,
      noCirclesTitle: `${KreiseName('FRA').multi} désabonnés`,
      myPost: 'Moi',
      circlesInfoTitle: 'Info & Membres',
      circlesSubscribe: "S'abonner",
      unsubscribeAlert: 'Quitter irrévocablement la discussion de groupe fermée ?',
      continueAlert: 'Laissez',
      readOnlyKreise: `Il s'agit d'un "chat de ${
        KreiseName('FRA').single
      } en lecture seule". Seuls les admins peuvent y créer des messages. Tu peux toutefois écrire des commentaires sur les messages.`,
    },
    messages: {
      addMessageLabel: 'Entrez le message',
      changesSaved: 'Changes saved!',
      newConversation: 'New conversation',
    },
    profileTabs: {
      basicTab: 'De base',
      clubsTab: CorpsName('FRA').single,
      miscellaneosTab: 'Intérêts',
      professionTab: 'Profession',
      studyTab: StudyName('FRA')?.short,
      notificationsTab: 'Notifications',
      privacy: 'Privatsphäre',
    },
    profile: {
      addPhotoLabel: 'Ajouter une photo de profil',
      genderInputLabel: 'Genre',
      titleLabel: 'Titel',
      firstNameLabel: 'Prénom(s)',
      lastNameLabel: 'Nom de famille',

      passwordLabel: 'Définir le mot de passe',
      birthDateLabel: 'Date de naissance',
      birthday: 'Date de naissance',
      changePasswordLabel: 'Changer de mot de passe',
      defaultCountryLabel: 'Allemagne',
      defaultGraduationLabel: 'Aucune information',
    },
    profileKorp: {
      verband: CorpsName('FRA').single,
      function: FunctionName('FRA'),
      dateOfJoin: CorpsEditDate('FRA'),
      beername: 'Nom de la bière',
    },
    profileClubs: {
      noUserLabel:
        "Toutes les informations de base doivent être remplies avant que l'application puisse être utilisée.",
      functionLabel: 'Fonction',
      setClubLabel: `Veuillez indiquer le nom du ${CorpsName('FRA').single}.`,
      matchClubLabel: 'Le',
      matchClubContinueLabel: 'existe déjà dans le profil, veuillez vérifier à nouveau.',
      clubLabel: CorpsName('FRA').single.toUpperCase(),
      clubCodeLabel: `CODE DE CONNEXION DE VOTRE ${CorpsName('FRA').single.toUpperCase()}`,
      entryTimeLabel: CorpsEditDate('FRA'),
      deleteClubLabel: 'Êtes-vous sûr de vouloir supprimer votre adhésion à',
      deleteClubContinueLabel: '?',
      selectVariant: 'Sélectionner une variante',
      requestExistingCorp: 'Demande de corps existant',
      requestNewCorps: 'Demander un nouveau corps',
      createCorp: 'Créer',
    },
    profileProfession: {
      jobTitle: 'Titre de la profession',
      noUserLabel:
        "Toutes les informations de base doivent être remplies avant que l'application puisse être utilisée.",
      untilToday: "Jusqu'à aujourd'hui",
      positionTitleLabel: 'Position',
      itemNameLabel: 'Profession',
      employmentLabel: "Type d'emploi",
      activityLabel: ActivityName('FRA'),
      careerStageLabel: 'Étape de la carrière',
      activityDescLabel: "Description de l'activité",
      companyLabel: CompanyName('FRA'),
      nameLabel: 'Nom',
      industryLabel: IndustryName('FRA'),
      areaLabel: 'Domaine',
      websiteLabel: 'Site web',
      employmentPeriodLabel: "Période d'emploi",
      fromLabel: 'Tiré de',
      toLabel: 'à',
    },
    profileStudy: {
      noUserLabel:
        "Toutes les informations de base doivent être remplies avant que l'application puisse être utilisée.",
      universityLabel: StudyName('FRA').hochsule,
      courseLabel: StudyName('FRA').studiengang,
      degreeLabel: StudyName('FRA').abshchluss,
    },
    profileOther: {
      noUserLabel:
        "Toutes les informations de base doivent être remplies avant que l'application puisse être utilisée.",
      hobbiesLabel: 'Hobbies',
      culturalLabel: 'Parrainage culturel',
      socialMediaPlattform: 'Plate-forme de médias sociaux',
      socialMediaLabel: 'Liens avec les médias sociaux',
      socialMediaLink: 'Liens',
      socialMediaError: 'Veuillez saisir l`URL complète',
    },
    profileNotifications: {
      noUserLabel:
        "Toutes les informations de base doivent être remplies avant que l'application puisse être utilisée.",
      notificationsInfo:
        'Ici, vous pouvez activer les notifications par courriel pour les activités individuelles (par défaut) ou les désactiver pendant 3 mois.',
      notificationsTitle: 'Notifications push:',
      notificationsDescr0: 'Vous pouvez configurer les notifications push dans les ',
      notificationsDescr1: 'paramètres système du smartphone.',
      notificationsDescr2: '',
      mailingTitle: 'Notifications par e-mail:',
      mailingDescr:
        'Ici, vous pouvez activer les notifications par e-mail pour des activités individuelles (par défaut) ou les désactiver pendant 1 an.',
      allNotificationsLabel: 'Toutes les notifications',
      postsLabel: `Messages dans ${KreiseName('FRA').multi}`,
      messagesLabel: 'Messages privés',
      commentsLabel: 'Commentaires sur les contributions propres',
      contactRequestsLabel: 'Demandes de contact',
      eventsLabel: 'Événements',
      clubMemberLabel: 'Nouveaux membres enregistrés',
      enableLabel: 'Sur',
      disableToLabel: "Arrêt jusqu'à",
      disableLabel: 'De',
      ownPosts: 'Meine Beiträge (Mail auch selbst erhalten)',
    },
    searchInput: {
      title: 'Rechercher',
      placeholder: 'Rechercher',
    },
    emptyList: {
      title: "Il n'y a pas (encore) de contenu ici",
    },
    switchLanguage: {
      placeholder: 'Langue/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'Russian (Русский)',
      SP: 'Spanish (Español)',
      NLD: 'Dutch (Nederlands)',
      POL: 'Polish (Polish)',
      title: 'Choisissez une langue',
    },
    filePicker: {
      takePicLabel: 'Prendre une photo',
      uploadPicLabel: 'Télécharger une photo',
      selectPicLabel: 'Sélectionnez une photo/video',
      selectDocLabel: 'Sélectionner un document',
    },
    datePicker: {
      selectDateLabel: 'Sélectionnez une date',
      confirmDateLabel: 'Confirmer la date',
      selectTimeLabel: "Sélectionnez l'heure",
    },
    changePassword: {
      currentPasswordLabel: 'Mot de passe actuel',
      newPasswordLabel: 'Nouveau mot de passe',
      repeatPasswordLabel: 'Répéter le mot de passe',
      changePasswordLabel: 'Changer de mot de passe',
    },
    appInfo: {
      appDescLabel:
        "Korponnect est l'application pour les étudiants de la fraternité. Échange entre entreprises, organisation d'événements ainsi que réseautage professionnel et privé - le tout dans une seule application.",
      contactsLabel: InfoContactsName('FRA'),
      infoLabel: 'Informations',
      versionLabel: "Version de l'application",
      agbLabel: 'AGB',
      dataProtectionLabel: 'Déclaration sur la protection des données',
      manualLabel: 'Manuel',
      appTextLabel: 'Vous pouvez nous trouver ici',
      appAndLabel: 'et un',
      appMainTextLabel: `Souhaitez-vous ajouter un cercle ou avez-vous une autre suggestion? Voulez-vous supprimer votre profil ? Écrivez-nous simplement un court e-mail à ${support_email}.`,
      deleteFeedback: 'Raison de la suppression du compte / retour d`information 🙂',
      deleteUserTitle: 'Supprimer le compte?',
      deleteUserDesc:
        'Ici, vous pouvez supprimer irrévocablement votre compte.\b au fait: vous pouvez également désactiver les notifications.Modifiez les paramètres sous Profil -> Notifications ou écrivez-nous un e-mail.',
      deleteUserAlert: 'Toutes vos données seront supprimées pour toujours, continuer?',
      webLabel: 'Version Web',
    },
    infoMessages: {
      registartionSuccessLabel: 'Merci de vous inscrire!',
      manualRegSuccessLabel: 'Merci de vous inscrire ! Le profil sera activé prochainement.',
      linkExpiredLabel: 'Lien expiré. Demander un nouveau changement de mot de passe.',
      emailExistLabel: "Désolé, l'e-mail n'est pas disponible.",
    },
    logoutAlert: {
      title: 'Se déconnecter',
      text: "Vous voulez vraiment la déconnecter ? Vous n'êtes pas obligé de vous déconnecter, il vous suffit de fermer ou de réduire l'application.",
      confirm: 'Oui',
      dismiss: 'Non',
    },
    invalidInputs: {
      passwordChars: `Le mot de passe doit contenir au moins ${passwordDigits(
        'FRA',
      )} caractères, dont au moins une lettre, un chiffre et un caractère spécial.`,
      fieldRequired: 'Ce champ est obligatoire.',
      emailsNotMatching: 'Veuillez vous assurer que vos emails correspondent',
      emailInvalid: "L'indication de votre adresse e-mail n'est pas correcte !",
      selectCorrectAnswer: 'You need to select correct answer',
      corp: 'Veuillez sélectionner au moins 1 entrée',
    },
    mentoring: {
      profile: {
        rules1: "Je suis d'accord avec le",
        rules2: ' Compréhension des valeurs ',
        rules3: '"Mentorat pour les boursiers".',
        menteeDescription:
          "Veuillez sélectionner votre région de mentoring afin d'être informé(e) des événements locaux. REMARQUE : tu peux modifier ta sélection à tout moment. Elle ne limite PAS ta recherche de mentor.",
        mentorDescription:
          'Veuillez remplir votre profil de mentor afin que les boursiers puissent vous trouver comme mentor. REMARQUE : Au moins 1 région et au moins 1 domaine thématique sont nécessaires.',
        mentoringRegions: 'Régions de mentoring',
        reigonsShort: 'Régions',
        topics: 'Champs thématiques',
        departments: 'Département',
        available: 'Je suis disponible',
        unavailable: 'Je ne suis pas disponible pour le moment',
        deleteAccount: 'Supprimer le profil Ment. & quitter le groupe',
        deleteAccountDescr: 'Supprimer le profil du mentor et quitter le chat du groupe de mentors ?',
        uploadPdf: 'Profil du mentor (PDF)',
        myPdf: 'Mon profil (PDF)',
        pdf: 'Mentor Profile',
      },
      tabNames: {
        info: 'Info',
        news: 'Actualités',
        profile: 'Profil',
        search: 'Recherche & demande',
        request: 'Mentorat',
        requestDetails: 'Demande de mentoring',
        tabDeactivated: 'Onglet désactivé. Veuillez remplir le profil de mentoring.',
      },
      search: {
        description:
          "Pour bien cibler ta recherche, nous te conseillons de commencer avec peu de champs de sélection, puis d'élargir la grille de recherche lors d'une nouvelle recherche. Si tu ne trouves pas de mentor approprié dans ta région, élargis la recherche à une région de mentoring voisine. Critères de recherche : 1 région, 1 à 3 champs thématiques",
        searchBtn: 'Recherche',
      },
      requests: {
        mentorIncoming: 'Mes demandes de mentoring',
        mentorCurrent: 'Mes mentorats',
        menteeOutgoing: 'Ma demande de mentoring',
        menteeCurrent: 'Mon mentorat',
        cancelMentoring: 'Terminer le mentorat',
        sendRequest: 'Demander à être mentor',
        alreadyHaveMentor: "Vous ne pouvez avoir qu'un seul mentor",
        requestSuccess: 'Demande envoyée avec succès',
        requestRetracted: 'Demande retirée avec succès',
        cancelSendingRequest: 'Annuler la demande de mentorat',
        approveRequest: 'Accepter la demande',
        rejectRequest: 'Refuser un mentee',
        invitaionText: 'Demande',
        rejectionText: 'Justifiez votre refus',
        sureRetractRequest: 'Retirer vraiment la demande ?',
      },
    },
    downloads: {
      root: 'Accueil',
      delete: 'Supprimer',
      rename: 'Renommer',
      areYouSureDeleteFolder: 'Voulez-vous vraiment supprimer le dossier ? Tous les contenus seront supprimés !',
      renameFolder: 'Renommer le dossier',
      createNewFolder: 'Créer un nouveau dossier',
      newFolderPlaceholder: 'Entrez le nom du dossier',
      create: 'Créer',
      areYouSureDeleteFile: '',
      downloadFile: 'Télécharger',
      deleteFile: 'Supprimer du périphérique',
      openInFinder: 'Ouvrir dans le Finder',
      cancel: 'Annuler',
    },
    globalSearch: {
      postedBy: 'Publié par',
      found: 'Trouvé',
      loadMore: 'Charger plus',
      enterQuery:
        "Saisissez n'importe quel terme de recherche. Vous pouvez rechercher n'importe quoi (nom, document, événement, publication, ...)",
      folder: 'Dossier',
      post: 'Publication',
      error: 'Saisissez au moins 3 caractères',
    },
    kreiseSettings: {
      description: 'Description',
      push: 'Notifications push',
      email: 'Notifications par email',
      subscribers: 'Membres',
      active: 'Activé',
      oneYear: 'Silence pendant 1 an',
      forever: 'Silence à vie',
      until: "Jusqu'à",
      cantDeactive: 'Cette option est désactivée pour ce groupe de discussion',
      SINGLE: 'Pour chaque message',
      SUMMARY_ONCE: 'Résumé 1x par semaine',
      SUMMARY_TWICE: 'Résumé 2x par semaine',
      SUMMARY_DAY: 'Résumé 1x par jour',
      OFF: 'Désactivé',
      PUSH: 'Notification push',
      EMAIL: 'Notification par email',
      PUSH_AND_EMAIL: 'Notification push et par email',
      newUser: 'Notification pour les nouveaux utilisateurs',
      statistic: "Statistiques d'envoi",
      off: 'Désactivé',
      ownPosts: 'Envoyer des statistiques par email pour vos publications',
      deleteMemberDescr: 'Supprimer ?',
      deleteMemberBtn: 'Supprimer',
      retractAdminRights: "Révoquer les droits d'administration",
      deleteAdminAndUser: 'Supprimer complètement',
      toDeleteKreiseBtn: 'Supprimer le groupe de discussion',
      deleteKreiseDescr: `${KreiseName('FRA').single} supprimer définitivement ?`,
      deleteKreiseModalTitle: 'Supprimer ce membre ?',
      addKreiseName: 'Nom',
      addKreiseDescription: 'Description',
      addMembers: 'Ajouter des membres',
      createKreise: 'Créer un groupe de discussion',
      toKreiseEditMessage: 'Note : toutes les modifications des membres seront appliquées immédiatement.',
      kreiseCreator: 'Créateur de ce groupe de discussion',
      kreiseDeleteMembersLeft: "Le groupe peut être supprimé uniquement s'il reste un seul membre",
      kreiseDeleteMembersLeftTitle: "Supprimez d'abord les membres",
      suggestionInNewsfeed: {
        admin: 'Administrateur',
        member: 'Membres',
        corp: `${CorpsName('FRA').single} membres`,
        corpAdmin: `${CorpsName('FRA').single} administrateur`,
      },
    },
    surveys: {
      checkVotes: 'Voir les résultats',
      chooseOptions: {
        single: 'Choisir une option',
        multiple: 'Choisir plusieurs options',
      },
      votesModalTitle: 'Votes',
      createModalTitle: 'Sondage',
      noVotes: "Il n'y a pas de votes",
      question: 'Question',
      askAQuestion: 'Posez une question',
      allowMultiple: 'Permettre plusieurs réponses',
      votes: {
        single: 'Vote',
        multiple: 'Votes',
      },
      option: 'Option',
      optionsLabel: 'Options du sondage',
      anonymus: 'Sondage anonyme',
      create: 'Créer',
    },
    subscription: {
      subscription: 'Abonnements',
      pricePerMonth: 'EUR/Monat',
      month: 'Monat',
      day: 'Tag',
      otherPlans: 'Andere Tarife',
      subscriptionActive: 'Abonnement aktiv',
      until: 'bis',
      subscribe: 'Abonnieren',
      successfulPayment: {
        title: 'Zahlung erfolgreich',
        text: 'Dein Abonnement wurde erfolgreich abgeschlossen. Du kannst ab sofort die erweiterten Funktionen nutzen.',
      },
      cancel: {
        cancel: 'Abonnement kündigen',
        title: 'Abonnement kündigen?',
        text: 'Möchtest du dein Abonnement kündigen? Nach Ablauf der bezahlten Periode werden deine Zugriffsrechte zurückgestuft.',
      },
    },
  },
  {
    key: 'HNG',
    key2: 'hu',
    editProfile: {
      updated: 'Profil frissítve.',
      validateFieldsViaEmail:
        'Kérjük, érvényesítse az alábbi adatmezőket e-mailben. Csak ezután kerülnek benyújtásra a központi tagsági nyilvántartásba.',
      newValue: (value: any) => `Új érték: "${value}"`,
      resendEmail: 'Vagy újra kellene küldeni az érvényesítő e-mailt?',
      validationPending: 'Érvényesítés folyamatban',
      resend: 'Újraküldés',
      resendDone:
        'Az érvényesítő e-mail újraküldése megtörtént. Kérjük, várjon néhány percet, és ellenőrizze a spam mappáját is.',
      resendLong: 'Vagy újraküldjük az érvényesítő e-mailt?',
    },
    appInfoScreen: {
      title: 'Rólunk',
      privacy: 'Adatvédelmi információk',
      find: 'Itt találhatja meg a',
      terms: ' Használati feltételek ',
      and: ' és ',
      infoSection: 'App Információ',
      hasUpdate: 'Elérhető újabb verzió. Kattints ide a frissítéshez.',
      noUpdate: 'Ez a legfrissebb verzió.',
      appVerison: 'App-verzió:',
    },
    corpsScreen: {
      website: 'Weboldal:',
      email: 'E-mail cím:',
      members: 'Tagok',
      admins: 'Adminok',
      info: 'Információ',
      searchMember: 'Tagok keresése',
    },
    contactsScreen: {
      empty:
        "Még nincsenek bejegyzések. Menjen a főmenübe, és válassza a 'Keresés' → 'Név' lehetőséget, hogy új kapcsolatokat találjon.",
    },
    dialogPanel: {
      hangOn: 'Csatolni Bierjunge-t?',
      ok: 'OK',
    },
    dialogs: {
      emptyTitle: 'Kattintson a jobb felső sarokban található plusz gombra a beszélgetés indításához.',
    },
    documentsGallery: {
      modalTitle: 'Dokumentum törlése',
      modalDesc: 'Törölni szeretné ezt a fájlt?',
      cancel: 'Mégse',
      delete: 'Törlés',
      deleteConfirm: 'Letöltött fájl törlése (csak nálam)?',
      downloadConfirm: 'Letöltöd ezt a fájlt?',
      availableInDownloads: `\n\n*Minden letöltött fájl elérhető a Letöltések mappában`,
    },
    drawerScreen: {
      backdrop: 'Minden alapvető információt először ki kell tölteni, mielőtt használni tudná az alkalmazást.',
    },
    kreiseInfoScreen: {
      creator: 'A csoportos csevegés létrehozója',
      revokeAdmin: (name: string) =>
        `El akarja venni az adminisztrátori jogokat "${name}" számára, vagy teljesen eltávolítani őt a csoportból?`,
    },
    kreisePostScreen: {
      infoParticipants: (num: string | number) => `Információk és résztvevők (${num})`,
      subscribeTitle: 'Feliratkozik a csoportos csevegésre?',
      subscribeDesc: 'Fel szeretne iratkozni erre a csoportos csevegésre, hogy ne hagyjon ki semmilyen hírt?',
      yes: 'Igen',
      later: 'Később talán',
      dontAsk: 'Ne kérdezze meg többet ezt a csoportos csevegést',
    },
    mapScreen: {
      appUser: 'Alkalmazás felhasználó',
      viewIsForMember: 'A felhasználói profilok megtekintése csak tagok számára elérhető.',
      all: 'Minden',
    },
    mentoringScreen: {
      clickMentor: 'Kattintson a mentor nevére, hogy emlékeztetőt küldjön, vagy visszavonja a kérést',
      all: 'Minden',
      startRequest: 'Kérés indítása:',
      startDesc:
        'Kérjük, írja le világosan és tömören a mentorálással kapcsolatos problémáját – ez segíti a mentort a kérés jobb felmérésében és gyors válaszadásban.',
      notice: 'Megjegyzés:',
      noticeDesc:
        'Kérjük, engedje meg, hogy legfeljebb 2 hétig várjon a mentor válaszára, mielőtt emlékeztetőt küldene. Törölje a folyamatban lévő kérelmét, mielőtt másik mentort keresne.',
      withdrawRequest: 'Kérelem visszavonása',
      reminder: 'Emlékeztető küldése',
      reminderSuccess: 'Emlékeztető küldése folyamatban (+ másolat önnek)',
      rejectRequest: 'Kérelem elutasítása',
      requestTitle: 'Mentorálási kérés',
      areas: 'Szakmai területek',
      topics: 'Témák',
      withReguards: (name: string, lastname: string) => `\n\n\n(...)\n\nÜdvözlettel,\n\n${name} ${lastname}`,
      rejectionMessage: (name: string, lastname: string) =>
        `Helló!\n\nSajnos el kell utasítanom a mentorálási kérelmet. Szívesen elmagyarázom az okokat, ha kérdezi.\n\nÜdvözlettel,\n\n${name} ${lastname}`,
      maxMessage: (max: number | string, topic: string) => `Legfeljebb ${max} ${topic} választható.`,
      decisionOpen: 'Döntés: nyitott',
      maxMentoring: 'Max. 1 mentorálási kérés engedélyezett',
      maxParalelMentoring: 'Max. 1 párhuzamos mentorálási kérés engedélyezett',
      openHere: 'Nyissa meg a ',
      profileUpdated: 'Profil frissítve!',
      mentoringProfile: 'Mentorálási profil',
      mentee: 'Mentorált',
      mentor: 'Mentor',
      leaveMentoring: 'Kilépés a mentorálási csoportból',
      mentoringTitle: 'Mentorálás',
      scrollAuto: 'automatikusan görgethető lapok példa',
      stopMentoringMessageMentor: (name: string, lastname: string) =>
        `Mentorálás befejezése ${name} ${lastname} személyével?`,
      stopMentoringMessageMentee: (name: string, lastname: string) =>
        `Mentorálás befejezése ${name} ${lastname} személyével?`,
      mentorEndedMentorship: (name: string, lastname: string) => `Mentor ${name} ${lastname} befejezte a mentorálást.`,
      mentorRejectedMentorship: (name: string, lastname: string) =>
        `Mentor ${name} ${lastname} elutasította a kérelmét.`,
      menteeEndedMentorship: (name: string, lastname: string) =>
        `A mentorált ${name} ${lastname} befejezte a mentorálást.`,
      menteeRejectedMentorship: (name: string, lastname: string) =>
        `A mentorált ${name} ${lastname} elutasította a kérésedet.`,
      mentorat: 'Mentorálás',
    },
    newEventScreen: {
      kreiseError: 'Kérlek válassz célcsoportot',
      correctDate: 'Dátum/idő helyesbítése',
      correctDateMessage: 'A befejezés dátuma/ideje a kezdés előtt van. Kérlek, javítsd ki.',
    },
    outRequestScreen: {
      backButton: 'Kapcsolati kérések',
      requestMessage: (name: string, lastname: string) =>
        `Szia!\n\nSzeretném hozzáadni Önt/Téged a kapcsolataimhoz.\n\nÜdvözlettel\n\n${name} ${lastname}`,
    },
    participantsScreen: {
      interestedParties: 'Érdeklődők',
      removeGuestTitle: (name: string) => `Eltávolítja ${name}-t?`,
      removeGuestDesc: (name: string) => `Eltávolítja "${name}"-t a résztvevők listájáról?`,
      informUser: (name: string) => `Tájékoztatja "${name}"-t arról, hogy eltávolították a résztvevők listájáról`,
    },
    partParticipantsScreen: {
      interestedParties: 'Érdeklődő felek',
      removeParticipantTitle: (name: string, lastname: string) =>
        `Eltávolítod "${name} ${lastname}"-t a résztvevők listájáról?`,
      removeParticipantDesc: (name: string, lastname: string) => `Eltávolítod "${name} ${lastname}"-t?`,
    },
    documentsScreen: {
      deleteTitle: 'Kiválasztott fájlok:',
      cancel: 'Mégse',
      uploadData: 'Adatok feltöltése',
    },
    postsScreen: {
      ads: 'Hirdetések...',
      oldPostsForMembers: 'Régebbi posztok csak tagok számára láthatók.',
    },
    profileBasisScreen: {
      editPhoto: 'Fotó szerkesztése',
    },
    profileBenachrichtigungenScreen: {
      fillData: 'Minden alapinformációt ki kell tölteni, mielőtt az alkalmazás használható lenne.',
      changesInChat: 'Ezeket a beállításokat az adott csoportcsevegésben tudod módosítani.',
      commentsWarning1:
        'A saját posztjaid kommentjeiről szóló e-mail értesítések letiltása nem ajánlott, mivel fontos kommentekről maradhatsz le.',
      commentsWarning2:
        'A privát üzenetekről szóló e-mail értesítések letiltása nem ajánlott, mivel fontos üzenetekről maradhatsz le.',
      messagesWarning1:
        'A privát üzenetekről szóló e-mail értesítések letiltása nem ajánlott, mivel általában nagyon kevés privát üzenetet kapsz a csoportcsevegéshez képest. Azonban ezek fontosak lehetnek, mert általában konkrét kéréseket tartalmaznak, amik (fontos) privát üzenetekként elveszhetnek, ha az értesítések le vannak tiltva.',
      messagesWarning2:
        'A saját posztjaid kommentjeiről szóló e-mail értesítések letiltása nem ajánlott, mivel fontos kommentekről maradhatsz le.',
      areYouSure: 'Biztos vagy benne?',
      turnOff: 'Kikapcsolás',
      maybeLater: 'Lehet, hogy később',
      notRecommended: 'Nem ajánlott beállítás',
      ok: 'OK',
    },
    profileBerufScreen: {
      fillData: 'Minden alapinformációt ki kell tölteni, mielőtt az alkalmazás használható lenne.',
      defaultDate: 'Maig',
      noArea: 'Nincs terület',
      furtherActivity: 'További tevékenység',
      deleteConfirm: 'Valóban törölni akarod a bejegyzést?',
      ok: 'OK',
    },
    profileCorpsScreen: {
      corpsname: 'Projekt neve',
      createHint: 'Új bejegyzés létrehozása folyamatban.',
      requestHint: 'Az új bejegyzés létrehozásának kérése folyamatban.',
      status: 'Státusz',
      deleteConfirm: (name: string) => `Valóban törölni akarod a tagságodat ${name} nevű csoportban?`,
      ok: 'OK',
      fillData: 'Minden alapinformációt ki kell tölteni, mielőtt az alkalmazás használható lenne.',
      duplicatedCorps: (name: string) => `A ${name} már szerepel a profilban, kérlek ellenőrizd újra.`,
      missingCorpName: 'Kérlek add meg a csoport nevét.',
      waitingActivation: 'Aktiválásra vár',
      requestExistingMessage: (name: string) =>
        `A tagság a(z) "${name}" számára igényelve lett. Értesítjük, amint aktiválva lesz.`,
      requestNewMessage: (name: string) =>
        `A(z) "${name}" létrehozása kérvényezve lett. Értesítjük, amint aktiválva lesz.`,
      createMessage: (name: string) => `A(z) "${name}" létrehozva, és hozzáadva a profiljához.`,
    },
    profileSonstigenScreen: {
      other: 'Egyéb (kérjük, adja meg)',
    },
    profileDetailsScreen: {
      email: 'E-mail',
    },
    profilePrivacyScreen: {
      fillData: 'Minden alapinformációt ki kell tölteni, mielőtt az alkalmazás használható lenne.',
    },
    profileStudiumScreen: {
      deleteConfirm: 'Valóban törölni akarod a bejegyzést?',
      ok: 'OK',
      fillData: 'Minden alapinformációt ki kell tölteni, mielőtt az alkalmazás használható lenne.',
    },
    reportScreen: {
      reportUser: 'Felhasználó jelentése',
      notMember: 'A felhasználó már nem tag',
      fakeProfile: 'A felhasználó hamis profil ajánlatokat tesz.',
      violations1: 'A felhasználó profilképe sérti az irányelveket.',
      violations2: 'A felhasználó kijelentései sértik az irányelveket.',
      violations3: 'A felhasználó spamet küld a csevegésben.',
      violations4: 'Jelentse/jelölje a potenciálisan sértő tartalmat',
      violations5: 'Jelentsen/jelöljön meg más felhasználókat esetleges szabálysértésért',
      reportReason: 'Jelentés indoklása',
      sendReport: 'Küldés',
      cancel: 'Mégse',
      showContent: 'A felhasználó tartalmának megjelenítése',
      hideContent: 'A felhasználó tartalmának elrejtése',
    },
    resetPasswordScreen: {
      incorrectEmail: 'Hibás email cím.',
    },
    serverIsDownScreen: {
      serverMaintenance: 'Szerver karbantartás',
    },
    signInScreen: {
      dontHaveAccount: 'Még nincs fiókod?',
    },
    signUpScreen: {
      login: 'Bejelentkezés',
      emailMatchError: 'Az email és az email megerősítés nem egyezik.',
      passwordMatchError: 'A jelszó és a jelszó megerősítés nem egyezik.',
      incorrectEmail: 'Hibás email cím!',
      pleaseSelect: 'Kérlek válassz',
      accountExistsTitle: 'Fiók létezik',
      accountExistsDesc: 'Már van fiókod ezzel az email címmel.',
      toLogin: 'Bejelentkezéshez',
    },
    subscriptionsScreen: {
      subscribed: 'Feliratkozva:',
      noSubscribed: 'Nem található feliratkozott szervezet',
      otherSubscriptions: 'Egyéb feliratkozott szervezők:',
      noOtherSubscriptions: `Nem található feliratkozott szervező`,
    },
    sucheScreen: {
      searchFor: 'Keresési eredmény:',
      filter: 'Szűrő:',
      name: 'Név',
      branch: 'Ágazat',
      function: 'Funkció',
      status: 'Státusz',
      city: 'Város',
      industry: 'Iparág',
      business: 'Vállalkozás',
      profession: 'Szakma',
      training: 'Képzés',
      studyProgram: 'Tanulmányi program',
      college: 'Főiskola',
      cancel: 'Mégse',
      searchBar: 'Keresés sáv',
      searchTab: 'Keresési lap',
      searchTitle: 'Keresés',
      scrollable: 'Görgethető automatikus fül példa',
    },
    dialogComponent: {
      close: 'Bezárás',
    },
    phoneInputComponent: {
      noCountry: 'Nem található ország.',
    },
    cropImageHandlerComponent: {
      cancel: 'Mégse',
      crop: 'Kivágás',
    },
    datePickerComponent: {
      cancel: 'Mégse',
      ok: 'OK',
      date: 'Dátum/Idő',
      day: 'Nap',
      month: 'Hónap',
      year: 'Év',
      wrongDate: 'Hibás dátum',
    },
    timePickerComponent: {
      hours: 'Órák',
      minutes: 'Percek',
    },
    responseHandlerComponet: {
      supportEmail: 'Ismeretlen hiba. Kérjük, lépjen kapcsolatba a ${support_email} email címmel.',
    },
    searchModalComponent: {
      selectionError: 'Kérlek válassz legalább egy bejegyzést.',
      maximumSelection: (num: string | number) => `Kérjük, legfeljebb ${num} elemet válasszon!`,
    },
    customDropzoneComponent: {
      dropFiles: 'Húzd ide a fájlokat',
      clickHere: ' vagy kattints ide a kiválasztáshoz',
    },
    documentsGalleryComponent: {
      clickToOpen: 'Kattints a megnyitáshoz',
      clickToDownload: 'Kattints a letöltéshez',
    },
    eventToolbarComponent: {
      noRegistration: 'Nincs regisztráció!',
      notLoggedId: 'Még nem vagy bejelentkezve. Ez csak egy érdeklődés kifejezés.',
      registerVia: 'Regisztráció itt történik:',
    },
    likeComponent: {
      externalGuest: 'Külsős vendég',
      invitedBy: (name: string) => `Meghívta: ${name}`,
    },
    membersComponent: {
      interested: 'Érdeklődők',
      loggedIn: 'Bejelentkezve',
      um: ' itt: ',
      invitedBy: (name: string) => `Meghívta: ${name}`,
    },
    multipleSearchableInputComponent: {
      maximalMembers: `Kérlek adj meg legfeljebb ${maximal_membership_count} tagságot.`,
    },
    multipleSelectModalComponent: {
      all: 'Minden',
    },
    offlineModalComponent: {
      checkInternet: 'Kérlek ellenőrizd az internet kapcsolatot.',
    },
    postsToolbarComponent: {
      membersOnly: 'Csak tagok posztolhatnak.',
    },
    zusagenModalComponent: {
      incorrectEmail: 'Hibás email cím',
      required: 'Ez a mező kötelező.',
      name: 'Név',
      email: 'E-mail (opcionális)',
      invitedBy: 'Meghívó',
      save: 'Mentés',
      cancel: 'Mégse',
      warning1:
        'Figyelem: Kérlek itt csak ${guest_naming_singular}-ként regisztrálj! Ha van már fiókod az alkalmazásban, kérlek jelentkezz be és erősítsd meg tagként. Köszönjük!',
      warning2: 'A regisztrációval elfogadod a ',
      and: ' és ',
      to: '.',
    },
    formInputComponent: {
      cancelConfirmation: 'Mégse a megerősítést?',
    },
    logoComponent: {
      alt: 'Alkalmazás logó',
    },
    customHistoryHook: {
      news: 'Üzenet',
    },
    postContentComponent: {
      orginized: 'Szervező:',
      date: 'Dátum:',
      ort: 'Helyszín:',
    },
    beerSmileComponent: {
      bierjunge: 'Sörtestvér!',
    },
    buttonComponent: {
      examination: 'Vizsgálat:',
    },
    global: {
      all: 'Minden',
      postSubject: 'Hozzászólásod tárgya',
      showMore: 'Tovább olvas',
      toRegister: 'Regisztrációhoz',
      addToCalendar: 'iCal / ICS',
      interested: 'Érdeklődik',
      loggedIn: 'Bejelentkezve',
      tilToday: 'Máig',
      status: 'Állapot',
      deletePost: 'Törölni a bejegyzést?',
      pdf: '(PDF)',
      time: 'Idő',
      birthday: (age: number | string, name: string, lastname: string) => `${age}. születésnapja ${name} ${lastname}`,
      eur: 'EUR',
      price: 'Ár:',
      priceForMe: 'Ár nekem:',
      ok: 'OK',
      likesCount: (num: string | number) => `Tetszik (${num})`,
      event: 'Esemény',
      standardMessage: 'Generálunk egy címet a bejegyzésedhez. Megfelel ez a cím?',
      optionalMessage:
        'Miről szól a bejegyzésed? Írj egy rövid címet. AI-t is használhatsz, hogy javaslatot adjon neked.',
      optionalMessageNote: 'Ennek a funkciónak a használatával elfogadod a ',
      chatGPTTerms: 'ChatGPT felhasználási feltételek',
      chatGPTTermsLink: 'https://openai.com/policies/terms-of-use',
      optionalMessageNoteEnding: '.',
      postSubjectTitle: 'Bejegyzés címe',
      generate: 'Generálás',
      defaultSubject: 'Alapértelmezett cím',
      memebership: 'Tagság',
      applyMemebership: 'Pályázni a tagságra?',
      pendingMembership: 'Tagság igénylését benyújtották - döntés folyamatban.',
      requestAlreadySent: 'A kérés már el lett küldve',
      requestedMemebership: (kreiseName: string) => `A "${kreiseName}" tagságot igényelték.`,
      yes: 'Igen',
      no: 'Nem',
      change: 'Módosítás',
      passwordOldMatchNewError: 'A régi és az új jelszó megegyezik',
      passwordLenghtError: 'A jelszónak legalább 6 karakterből kell állnia.',
      passwordsMatchError: 'A régi és az új jelszó megegyezik',
      passwordsMatchError2: 'A jelszó és a megerősítés nem egyezik.',
      currentPassword: 'Jelenlegi jelszó',
      newPassword: 'Új jelszó',
      confirmPassword: 'Jelszó megerősítése',
      changePassword: 'Jelszó megváltoztatása',
      cancel: 'Mégse',
      fits: 'Megfelel',
      selectChatTitle: 'Válassz csoportos beszélgetést',
      selectChatDesc:
        "Válassz legalább egy kedvenc beszélgetést, amelyhez a bejegyzésedet küldeni szeretnéd. Továbbiakat találhatsz a főmenüben a 'Csoportos beszélgetések' alatt.",
      socialMedia: 'Közösségi média',
      generatedText: 'Szöveget generálunk a közösségi médiához. Megfelel ez a szöveg?',
      usePhoto: 'Köszönjük a bejegyzésedet! Használhatjuk a fényképedet a közösségi média csatornáinkon?',
      maximumLenght: 'Legfeljebb 20 000 karakter.',
      userSharedNewPicture: 'Egy felhasználó új képet osztott meg az alkalmazásunkban!',
      contributionTo: (kreiseName: string) => `Hozzájárulás a "${kreiseName || 'Mindenki'}" számára`,
      postWarning1:
        'Tedd meg! A következő lépésben még lehetőséged lesz saját szöveget hozzáadni. Ezen kívül a bejegyzésedet is ellenőrizzük.',
      postWarning2:
        'A beküldés után a bejegyzésedet egy adminisztrátor fogja ellenőrizni. Kérdések esetén bátran vedd fel velünk a kapcsolatot ',
      postWarning3: ' vagy ',
      AGB: 'Felhasználási feltételek',
      DSE: 'Adatvédelmi szabályzat',
      withCheckbox: "A fiókom aktiválásával az 'Elfelejtett jelszó' funkcióval elfogadom a",
      withoutCheckbox: "A fiók aktiválásával az 'Elfelejtett jelszó' funkcióval elfogadod a",
      tou: 'Felhasználási feltételek',
      dse: 'Adatvédelmi szabályzat',
      impressum: 'Jogi nyilatkozat',
    },
    responseHandler: {
      messageLabel: `Ismeretlen hiba. Kérjük, lépjen kapcsolatba az ${support_email} címmel.`,
      expiredLinkLabel: 'A link lejárt. Kérjen új jelszót.',
      emailExistLabel: 'Sajnáljuk, ez az e-mail cím már regisztrálva van.',
      profileUpdated: 'Profil aktualizálva',
      profileUpdatedCorpsError: `Profil frissítve! Azonban a következő ${
        CorpsName('GER').single
      } nem tudott hozzáadni egy hibás bejelentkezési kód miatt:`,
      appNeedsUpdate: 'Kérjük, frissítse az alkalmazást az App Store-ban / Google Play-ben.',
      maximalVideoSize: 'A maximális fájlméret 2 GB!',
    },
    report: {
      notBelongLabel: 'A felhasználó már nem tartozik egy hadtesthez',
      incProfileLabel: 'A felhasználó hibás profilajánlatokat tesz',
      badPicLabel: 'A felhasználó profilképe sérti az irányelveket',
      badComLabel: 'A felhasználó megjegyzései sértik az irányelveket',
      spamLabel: 'A felhasználó spamet küld a csevegésben',
      reasonsLabel: 'A jelentés oka',
    },
    drawer: {
      subscription: 'Subscription',
      news: 'Üzenetek',
      kreise: KreiseName('HNG').multi,
      documents: `${DocumentsName('HNG')}`,
      search: 'Keresés',
      calendar: CalendarName('HNG'),
      contacts: 'Névjegyek',
      requests: 'Kérések',
      messages: MessagesName('HNG'),
      profile: ProfileName('HNG'),
      logout: 'Kijelentkezés',
      map: 'Térkép',
      mentoring: 'Mentorálás',
    },
    imageCropper: {
      cropLabel: 'Kép kivágása',
      cancelLabel: 'Megszünteti',
      chooseLabel: 'Befejezett',
    },
    signIn: {
      register: 'Regisztráció',
      title: 'Kérjük jelentkezz be',
      loginPlaceholder: 'Levelezési cím',
      passwordPlaceholder: 'Jelszó',
      forgotPassword: 'Elfelejtetted a jelszavad?',
      loginBtn: 'Belépés',
      noAccount: 'Még nincs fiókod?',
      description0: 'Ha bármilyen kérdése van, kérjük, forduljon hozzánk bizalommal a',
      description1: 'vagy a',
      description2: 'e-mail címen.',
      loginValidation: 'Az email címed helytelen',
      passwordValidation: 'A jelszónak legalább 6 karakter hosszúnak kell lennie',
    },
    search: {
      name: 'Név',
      corps: CorpsName('HNG').single,
      country: CountryName('HNG'),
      city: 'Város',
      industry: IndustryName('HNG'),
      company: CompanyName('HNG'),
      profession: 'Szakma',
      hobby: 'Hobbi',
      cultural: 'Kulturális szponzor',
      study: 'Tanulmány',
      office: 'Hivatal',
      degreeProgram: 'Szak',
      university: 'Egyetem',
      position: FunctionName('HNG'),
      status: 'Állapot',
      title: 'Keresési eredmény a következőre',
      filterTitle: 'Szűrés vlami alapján',
      global: 'Minden',
      activity: ActivityName('HNG'),
    },
    resetPassword: {
      enterEmail: 'Kérjük, add meg e-mail címed',
      inputPlaceholder: 'Levelezési cím',
      sendBtn: 'Küld',
      cancelBtn: 'Megszünteti',
      message: 'Ha az e-mail cím ismert számunkra, linket küldtek rá.',
      desc: 'Meg kell adnia az alkalmazásban tárolt pontos e-mail címet. Ha szükséges, ellenőrizze az e-mailjeit, hogy melyik az.',
    },
    password: {
      resetLabel: 'Adjon meg egy új jelszót',
      inputLabel: 'Adjon meg új jelszót',
    },
    signUp: {
      createAccount: 'Fiók létrehozása',
      inputPlaceholder: `Klubod bejelentkezési ${CorpsName('HNG').single}`,
      sendBtn: 'Folytatás',
      descFirst: 'A regisztrációval Ön elfogadja a ',
      descSecond: 'és megerősíti, hogy elolvasta a',
      descThird: `Ha bármilyen kérdése van, kérjük, forduljon hozzánk bizalommal a ${support_email} vagy a +49 170 49 805 e-mail címen`,
      terms: 'feltételek és kikötések',
      policy: 'adatvédelmi politika.',
      description: 'Megjegyzés',
      generatePassword: 'Generate password',
      noCodeSuccessRegister: 'Köszönjük a regisztrációt! E-mailben értesítjük Önt, amint aktiválódott.',
    },
    signUpDefault: {
      withoutCode: 'Kód nélkül',
      createAccount: 'Csak regisztráljon itt',
      withCode: 'Meghívó kóddal',
      simpleRegister: 'Meghívó kód nélkül',
      existingAccount: 'Számla már elérhető?',
      descr01: 'Elfogadom a',
      descr0: 'A regisztrációval a felhasználó beleegyezik a következőkbe',
      descr1: 'és megerősíti, hogy elolvasta és megértette a.',
      descr2: '',
      guest: 'Regisztrálj vendégként',
      withCodeWithDescr: `Regisztrálj mint ${member_naming_singular}`,
    },
    signUpManual: {
      title: 'Bejegyzés',
      photoDesc: 'Profilfotó hozzáadása',
      genderInput: 'Nem',
      firstName: 'Keresztnév (nevek)',
      lastName: 'Vezetéknév',
      club: CorpsName('HNG').single,
      specify: 'kérem pontosítsa',
      emailPlaceholder: 'E-mail',
      emailHint: 'Kérjük, használja azt az e-mail címet, amelyet már régóta használ.',
      repeatEmailPlaceholder: 'Ismételd meg az E-mail címet',
      passwordPlaceholder: 'Állítsd be a jelszót',
      repeatPasswordPlaceholder: 'Jelszó újra',
      postcode: 'Irányítószám',
      place: 'Lakóhely',
      country: 'Ország',
      loginCode: 'Meghívó kód',
      mobilePlaceholder: 'Mobil (nem látható a profilban)',
      corps: SignUpCorpsName('HNG'),

      searchCountry: 'Ország szerint keresés',
    },
    backToLogin: {
      desc: 'Már van fiókja? ',
      login: 'Belépés',
    },
    backToSignUp: {
      desc: 'Még nincs fiókod? ',
      register: 'Regisztráció',
    },
    privacy: {
      desc: 'Igen, elfogadom a',
      btn: 'általános szerződési feltételek',
      zu: '',
      map: {
        title: 'Adatvédelmi tájékoztató',
        privacyNotice: `Ez a térkép a GDPR-nak megfelelően készült.`,
        additionalInfo: {
          intro: 'A térképen lévő gombostűk',
          inaccurateDisplay: 'pontatlanul csak irányítószám/város szerint vannak feltüntetve',
          locationBasis: ', nem pedig utcanév/házszám szerint.',
          noConclusion: 'Ez azt jelenti, hogy nem lehet következtetéseket levonni',
          onAddresses: 'a felhasználó konkrét címére',
          conclusion: 'vonatkozóan.',
        },
        agree: 'Értettem',
        linkName: 'Adatvédelmi nyilatkozat',
        options: {
          doNotShowAgain: 'Ne jelenjen meg újra',
          doNotShowMeOnMap: 'Ne jelenjen meg a térképen (még megközelítőleg sem)',
        },
      },
      restrictions: {
        overall: 'Ez a funkció csak tagok számára elérhető.',
        overallWilkar: 'Ez a funkció adatvédelmi okokból nem elérhető.',

        profile: 'Csak tagok láthatják a felhasználói profilokat. Azonban privát üzenetet küldhetsz a felhasználónak.',
        groupchat: `A ${KreiseName('GER').single} csak tagok számára elérhető.`,
        groupchatWilkar: `Adatvédelmi okokból a ${KreiseName('GER').single} csak tagok számára elérhető.`,
        comments: 'A kommentek csak tagok számára láthatóak.',
        commentsWilkar: 'Adatvédelmi okokból a kommentek csak tagok számára láthatóak.',
        likes: 'A kedvelések listája csak tagok számára látható.',
        post: 'Csak tagok írhatnak bejegyzéseket.',
        participantsList: 'A résztvevők listája csak tagok számára látható.',
        commentsOnlyForMembers: 'A kommentek csak tagok számára láthatóak.',
        postsLimitation: 'Régebbi bejegyzések csak tagok számára láthatóak.',
      },
      settings: {
        guestPost: {
          title: 'A hitelesített vendégek láthatják a bejegyzéseimet',
          ask: 'Mindig kérdezni',
          no: 'Nem',
          yes: 'Igen',
        },
      },
      guestModal: {
        title: 'Megengedjem a vendégek számára, hogy lássák ezt a bejegyzést? Ezt a beállítást később is módosíthatod.',
        dontAskAgain: 'Ne kérdezd meg újra (Ezt a beállítást a “Profil” → “Adatvédelem” menüpontban is módosíthatod).',
      },
      post: {
        ifShown: `Elrejtés a ${guest_naming_plural} előtt`,
        ifHidden: `Megjelenítés a ${guest_naming_plural} előtt`,
        ifShownModal: `A bejegyzés mostantól nem látható a ${guest_naming_plural} számára.`,
        ifHiddenModal: `A bejegyzés mostantól látható a ${guest_naming_plural} számára.`,
      },
    },
    searchModal: {
      landTitle: 'Válassz egy országot',
      corpsTitle: `Válassza a ${CorpsName('HNG').single} lehetőséget`,
      pleaseSelect: 'Kérjük, válasszon',
    },
    btn: {
      sendBtn: 'Küld',
      cancelBtn: 'Megszünteti',
      select: 'Válassza a lehetőséget',
      cancelEventBtn: acceptEventName('HNG').cancel,
      acceptBtn: acceptEventName('HNG').accept,
      saveBtn: 'Megment',
      clearBtn: 'Törlés',
      acceptReqBtn: 'Megerősíteni',
      declineReqBtn: 'Hanyatlás',
      updateBtn: 'Frissítés',
      declineBtn: 'Hanyatlás',
      yesUnderstood: 'Ja, verstanden',

      ok: 'Oké',
      cancel2: 'Megszünteti',
    },
    validation: {
      email: 'Az email címed helytelen',
      password: 'A jelszónak legalább 6 karakter hosszúnak kell lennie',
      repeatEmail: 'Az e-mail és az újrapróbálkozás nem egyezik',
      repeatPassword: 'A jelszó és az ismétlés nem egyezik',
      required: 'Ez a mező kötelező',
      matchPasswords: 'Régi és új jelszó egyezik',
      dontMatchPasswords: 'A jelszó és az ismétlés nem egyezik',
    },
    init: {
      title: 'Betöltés',
      overlayLabel: 'Kérjük, ellenőrizze internetkapcsolatát',
    },
    screenTitles: {
      postsTitle: 'Üzenetek',
      circlesTitle: KreiseName('HNG').multi,
      searchTitle: 'Keresés',
      profileTitle: ProfileName('HNG'),
      eventTitle: CalendarName('HNG'),
      eventDetailsTitle: 'Esemény',
      newEventTitle: 'Esemény hozzáadása',
      contactsTitle: 'Névjegyek',
      contactReqTitle: 'Kapcsolattartási kérelmek',
      reqDetailsTitle: 'Kérjen részleteket',
      outReqTitle: 'Kapcsolatfelkérés',
      messagesTitle: MessagesName('HNG'),
      changePasswordTitle: 'Jelszó módosítása',
      appInfoTitle: 'Info',
      reportTitle: 'Felhasználó bejelentése',
      createKreiseTitle: 'Csoportos csevegés létrehozása',
      eventParticipants: 'Résztvevők',
      guest: 'Vendég',
    },
    profileDetails: {
      showFilters: 'Szűrők megjelenítése',
      user: 'Users',
      mainTitleLabel: ProfileName('HNG'),
      basisLabel: 'Alapvető',
      clubLabel: CorpsName('HNG').single,
      clubLabelPlural: CorpsName('HNG').multi,
      professionLabel: 'Szakma',
      studyLabel: StudyName('HNG').short,
      uploadPhotoLabel: 'Fénykép feltöltése',
      editPhotoLabel: 'Fénykép szerkesztése',
      profileCompletedLabel: 'A profil elkészült',
      profileCompletedGLabel: '',
      birthday: 'Születési dátum',
      functionLabel: 'Funkció',
      dateOfJoinLabel: 'Az ágyék dátuma',
      positionDescLabel: 'Profession',
      companyLabel: CompanyName('HNG'),
      nameLabel: 'Név',
      websiteLabel: 'Weboldal',
      fromLabel: 'tól',
      toLabel: 'ig',
      delete: 'töröl',
      untilToday: 'A mai napig',
      degreeLabel: StudyName('HNG').abshchluss,
      miscellaneos: 'Vegyes',
      hobbies: 'Hobbi',
      cultural: 'Kulturális támogatás',
      positionLabel: 'Beosztás',
      typeOfEmplLabel: 'Foglalkoztatás típusa',
      activityLabel: ActivityName('HNG'),
      careerStageLabel: 'Karrier szakasz',
      activityDescLabel: 'A tevékenység leírása',
      industryLabel: IndustryName('HNG'),
      areaLabel: 'Szakterület',
      employmentPeriodLabel: 'A foglalkoztatás időszaka',
      universityLabel: 'Egyetem',
      courseLabel: 'Tanulmányok menete',
      firmaLabel: 'Firma',

      ageLabel: 'Kor',
      birthdayLabel: 'Születésnap',
      partner: 'Partner neve',
      editProfileLabel: 'Profil szerkesztése',
      addContactLabel: 'Hozzáadás a kapcsolatokhoz',
      sendMessageLabel: 'Üzenet küldése',
      requestSentLabel: 'Küldj barátkérést',
      sendRequestSuccessLabel: 'Sikeresen elküldött kérés',
      reportSentLabel: 'A felhasználó már jelentett',
      addReportLabel: 'Jelentés küldése',
      contactsLabel: 'Névjegyek',
      showBirthday: 'A kapcsolatok lásd a születésnapomat',
      street: 'Utca + Nr. ',
      houseLabel: 'Házszám',
      houseNumberLabel: 'Házszám',
      mobileNumberLabel: 'Mobilszám',
      handphoneNumberLabel: 'Mobiltelefonszám',
      telephoneNumberLabel: 'Telefonszám',
      leibbursch: 'Mentor',
      leibfuechse: 'Mentees',

      bio: 'Rólam',
      addDocuments: 'Dokumentumok hozzáadása',
      image: 'Kép',
      video: 'Video',
      pdf: 'PDF',
      link: 'Link',
      firstName: 'Keresztnév',
      lastName: 'Vezetéknév',
      title: 'Cím',
      email: 'E-Mail',
      name: 'Keresztnév',
      lastname: 'Vezetéknév',
      country: CountryName('HNG'),
      wohnort: 'Város',
      postcode: 'Irányítószám',
      inReview: 'E-mailben várható megerősítés',
    },
    calendar: {
      birthdayLabel: 'Születésnapja a',
      noEventsLabel: 'Nincsenek események a kiválasztott dátumra',
      allTabLabel: 'Minden',
      myTabLabel: 'Eseményeim eseményeim',
      clubsTabLabel: `${CorpsName('HNG').single}`,
      subsTabLabel: 'Feliratkozások',
      subscribedTabLabel: 'Feliratkozott',
      otherTabLabel: 'Mások',

      error: 'A befejezési dátumnak később kell lennie, mint a kezdési dátumnak',
      filter: {
        all: 'Minden',
        invited: 'Meghívott',
        confirmed: 'Megerősített',
      },
    },
    eventLabels: {
      organizer: 'Organizer',
      registrationVia: 'Regisztráció keresztül',
      maximalParticipants: 'A résztvevők maximális száma',
      participantsWarning: 'A résztvevők száma korlátozott',
      interested: 'Nem érdekel',
      notInterested: 'Érdekelt felek',
      removeFromWaitList: 'Távolítsa el a várólistáról',
      addEventTitleImage: 'Adjon hozzá címképet',
      changeEventTitleImage: 'Címkép módosítása',
      inviteTo: 'Meghívó a',
      addEndDate: 'Adja meg a végdátumot/időpontot',
      eventLabel: 'Esemény',
      startLabel: 'Kezdődik',
      locationLabel: 'Elhelyezkedés',
      participantLabel: 'Résztvevők',
      moreInfo: 'További információk',
      commentsLabel: 'Hozzászólások',
      noCommentsLabel: 'Még nincsenek hozzászólások',
      eventStartLabel: 'Rajt',
      eventEndLabel: 'Vége',
      descLabel: 'Leírás',
      creatorLabel: 'Készítette',
      titleLabel: 'Cím',
      deleteEventTitle: 'Esemény törlése?',
      deleteEventLabel: 'Törli ezt az eseményt?',
      clubsTitle: CorpsName('HNG').single,
      gruppenchat: 'Csoportos csevegés kiválasztása',
      contactsTitle: 'Névjegyek',
      delCommentLabel: 'Törli ezt a megjegyzést?',
      sendInvite: 'Küldjön meghívót a következő címre',
      selectCorps: 'Célközönség kiválasztása',
      externalGuest: 'Meghívott külső vendégek',
      externalGuestPlaceholder: 'Adja meg a levelezési címet',
      attachmentLabel: 'Mellékletek',
      shareLinkLabel: 'Megosztás link',
      linkLabel: 'Vendég regisztrálása',
      copyLinkLabel: 'Link másolása',
      iCalDownload: 'Naptár fájl',
      timezone: 'Időzóna',
      visibility: 'Sichtbarkeit',
      errorDate: 'A dátum a múltban van',
      startAfterEndError: 'A végdátumnak később kell lennie, mint a kezdet',
      members: 'Tagok',
      waitlist: 'Várólista',
      onWaitlist: 'várólistán',
      leaveWaitlist: 'Kilép a várólistáról?',
      leaveWaitlistDesc:
        'Eltávolítás a várólistáról?\n\n Ha később újra csatlakozol a várólistához, előfordulhat, hogy a lista végére kerülsz.',
      limitAchieved: (limit: number) => `A maximális résztvevői létszám ${limit} fő. Felkerültél a várólistára.`,
      visibilityOptions: {
        allWithoutGuests: 'Tagok és a weboldalon',
        invited_member: 'Csak meghívott tagok',
        verified_member: 'Minden tag',
        authorized_member: 'Tagok és hitelesített vendégek',
        all: 'Tagok, vendégek és a weboldalon',
      },
      modalEvent: {
        title: 'Létrehozott esemény',
        subTitle: 'Az eseményt létrehozták.\n\n',
        content:
          'A felhasználói beállításoktól függően mostantól push/mail értesítések kerülnek kiküldésre az eseményről. \n\nHa ezt a "Profil" → "Értesítések" menüpontban állította be, akkor maga is kap e-mail értesítést az eseményről.',
      },
      modalComment: {
        title: 'Kit kell figyelmeztetni?',
        message: 'Kit kell tájékoztatni a megjegyzéséről?',
        optionAll: 'Minden résztvevő',
        optionNone: 'Csak a szervező(k)',
        button: 'Megjegyzés küldése',
      },
      cancelModal: {
        title: 'Törlés vagy törlés?',
        message: 'Esemény törlése vagy törlése?',
        optionAll: 'Törlés (a résztvevőket értesítjük)',
        optionNone: 'Törlés (a résztvevők nem kapnak értesítést)',
      },
      updateEventModal: {
        title: 'Egyéb meghívók küldése',
        label: 'A többi csoportot meghívták.',
        message:
          'En fonction des paramètres, des notifications push ou des notifications par e-mail concernant l`événement sont désormais envoyées aux autres groupes.',
      },
      eventRegistrationType: {
        app: 'App',
        event: 'Link/E-mail cím',
        enterLink: 'Link/email cím vagy hasonló hozzáadása',
      },
      parts: {
        part: 'Rész',
        addPart: 'Program pont hozzáadása',
        hint: 'Magyarázat',
        question: 'Kérdés',
        single: 'Csak egy választható opció',
        multi: 'Több választható opció',
        freeText: 'Szabad szöveges válasz',
        quiz: 'Kvíz',
        questionnaire: 'Kérdőív',
        addQuestionnaire: 'Kérdőív hozzáadása',
        questionnaireType: 'Válaszlehetőségek',
        option: 'Opció',
        addOption: 'Opció hozzáadása',
        showMembers: 'Résztvevők',
        youJoined: 'Részt veszel:',
        totalToPay: 'Fizetendő teljes összeg:',
        yourAnswer: 'A válaszod',
        visibleForCreators: 'Csak a készítők láthatják',
        allAnswers: 'Minden válasz:',
        addUsers: 'Felhasználók hozzáadása',
        isAnonym: 'Névtelen szavazás',
        isAnonymQuiz: 'Névtelen kvíz',
      },
    },
    rrEventLabels: {
      title: 'Ismétlés',
      MO: 'Hétfő',
      TU: 'Kedd',
      WE: 'Szerda',
      TH: 'Csütörtök',
      FR: 'Péntek',
      SA: 'Szombat',
      SU: 'Vasárnap',
      YEARLY: 'Év',
      MONTHLY: 'Hónap',
      WEEKLY: 'Hét',
      DAILY: 'Nap',
      SINGLE: 'Egyszeri',
      CUSTOM: 'Egyedi...',
      freq: 'Frekvencia',
      interval: 'Intervallum',
      notRepeat: 'Egyszeri',
      every: 'Minden',
      month: 'hónap',
      week: 'hét',
      last: 'utolsó',
      on: 'a',
      everyMonthOn: 'Minden hónapban a',
      ofTheMonth: 'a hónapban',
      editLabel: 'Melyik időpontot szeretnéd módosítani?',
      thisDate: 'Csak ezt a dátumot',
      thisDateAndFollowing: 'Ezt és az összes következő dátumot',
      allDates: 'Minden dátum',
      after: 'Után',
      time: 'idő',
      times: 'alkalommal',
      neverEnds: 'Soha nem ér véget',
      until: 'egészen',
      repeatedDefault: ({ frequency, freq }: { frequency: string; freq: number }) =>
        `Minden${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency} ismétlődik`,
      repeatedMonthly: ({ day, interval }: { day: string; interval: number }) =>
        `Minden${interval == 1 ? '' : ` ${interval}.`} hónapban a ${day.length > 2 ? day : `${day}.`} ismétlődik.`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }: { day: string; interval: number; bysetpos: number }) =>
        `Minden ${bysetpos == -1 ? ' utolsó' : `${bysetpos}.`} ${day} a ${
          interval === 1 ? '' : `${interval}.`
        } hónap ismétlődik.`,
      repeatedYearly: ({ interval }: { interval: number }) =>
        `Minden ${interval == 1 ? '' : `${interval}.`} év ismétlődik.`,
      repeatedWeekly: ({ interval, byweekday }: { interval: number; byweekday: any }) =>
        `Minden${interval != 1 ? ` ${interval}.` : ''} héten${
          !!byweekday?.length
            ? ' a ' + byweekday?.slice(0, -1).join(', ') + (byweekday?.length > 1 ? ' és ' : '') + byweekday?.slice(-1)
            : ''
        } ismétlődik.`,
      repeatedDaily: ({ interval }: { interval: number }) =>
        `Minden${interval != 1 ? ` ${interval}.` : ''} napon ismétlődik.`,
    },
    requestsLabels: {
      receiveLabel: 'Fogadva',
      sentLabel: 'Küldött',
    },
    post: {
      adTitle: 'Információ/Hirdetés',
      toKreise: 'ig',
      comment: 'Hozzászólások',
      myPost: 'Nekem',
      openPdf: 'Kattintson a megnyitáshoz',
      deletePost: 'Törölje ezt a bejegyzést?',
      posted: 'Feladva',
      commented: 'Hozzászólt',
      contacts: 'Névjegyek',
      sendToContacts: 'Üzenet mindenkinek',
      enterText: 'Írja ide az üzenetet',
      sendToCircle: `Írja be az üzenetet ebbe a ${KreiseName('HNG').single}`,
      sendComment: 'Írja be a megjegyzést',
      longMessage: 'Legfeljebb 20 000 karakter',
      noComments: 'Még nincsenek hozzászólások',
      deleteComment: 'Törli a megjegyzést?',
      deleteEventPost: 'Törölni ezt a hozzászólást? Ez nem törli az eseményt.',
      editPostLabel: 'Bejegyzés szerkesztése',
      editingPostLabel: 'Szerkesztési mód',
      deletePostLabel: 'Bejegyzés törlése',
      editedAt: 'Szerkesztve',
      pinned: 'Kitűzve.',
      actions: {
        label: 'Akciók posztolása',
        pin: {
          label: 'Bejegyzés kitűzése',
          once: 'Egyszeri feljebb helyezés',
          d3: '3 napra',
          d5: '5 napra',
          w1: '1 hétre',
          w2: '2 hétre',
          unpin: 'Ne pinelje többé',
        },
      },
    },
    circles: {
      circlesTitle: `Feliratkozott ${KreiseName('HNG').multi}`,
      noCirclesTitle: `Leiratkozott ${KreiseName('HNG').multi}`,
      myPost: 'Nekem',
      circlesInfoTitle: 'Info és tagok',
      circlesSubscribe: 'Iratkozz fel',
      unsubscribeAlert: 'Visszavonhatatlanul kilépni a zárt csoportos megbeszélésből?',
      continueAlert: 'Hagyja',
      readOnlyKreise: `Ez egy "csak olvasható ${
        KreiseName('HNG').single
      }". Csak az adminok írhatnak ide. A hozzászólásokhoz azonban lehet kommenteket írni.`,
    },
    messages: {
      addMessageLabel: 'Írja be az üzenetet',
      changesSaved: 'Changes saved!',
      newConversation: 'New conversation',
    },
    profileTabs: {
      basicTab: 'Alapvető',
      clubsTab: CorpsName('HNG').single,
      miscellaneosTab: 'Másféle',
      professionTab: 'Szakma',
      studyTab: StudyName('HNG')?.short,
      notificationsTab: 'Értesítések',
      privacy: 'Adatvédelem',
    },
    profile: {
      addPhotoLabel: 'Profilfotó hozzáadása',
      genderInputLabel: 'Nem',
      titleLabel: 'Cím',
      firstNameLabel: 'Keresztnév (nevek)',
      lastNameLabel: 'Vezetéknév',

      passwordLabel: 'Állítsd be a jelszót',
      birthDateLabel: 'Születési dátum',
      birthday: 'Születési dátum',
      changePasswordLabel: 'Jelszó módosítása',
      defaultCountryLabel: 'Németország',
      defaultGraduationLabel: 'Nincs információ',
    },
    profileKorp: {
      verband: CorpsName('HNG').single,
      function: FunctionName('HNG'),
      dateOfJoin: CorpsEditDate('HNG'),
      beername: 'Sör neve',
    },
    profileClubs: {
      noUserLabel: 'Az alkalmazás használatához minden alapvető információt ki kell tölteni.',
      functionLabel: 'Funkció',
      setClubLabel: `Kérjük, adja meg a ${CorpsName('HNG').single} nevét.`,
      matchClubLabel: 'Az',
      matchClubContinueLabel: 'már létezik a profilban, ellenőrizze újra.',
      clubLabel: CorpsName('HNG').single.toUpperCase(),
      clubCodeLabel: `KLUBJÁNAK BEJELENTKEZÉSI ${CorpsName('HNG').single.toUpperCase()}`,
      entryTimeLabel: CorpsEditDate('HNG'),
      deleteClubLabel: 'Biztosan törli a (z) domain tagságát',
      deleteClubContinueLabel: '?',
      selectVariant: 'Változat kiválasztása',
      requestExistingCorp: 'Meglévő corp kérése',
      requestNewCorps: 'Új alakulat kérése',
      createCorp: 'Hozzon létre',
    },
    profileProfession: {
      jobTitle: 'Munkakör',
      noUserLabel: 'Az alkalmazás használatához minden alapvető információt ki kell tölteni.',
      untilToday: 'A mai napig',
      positionTitleLabel: 'Beosztás',
      itemNameLabel: 'Szakma',
      employmentLabel: 'Foglalkoztatási típus',
      activityLabel: ActivityName('HNG'),
      careerStageLabel: 'Karrier szakasz',
      activityDescLabel: 'A tevékenység leírása',
      companyLabel: CompanyName('HNG'),
      nameLabel: 'Név',
      industryLabel: IndustryName('HNG'),
      areaLabel: 'Szakterület',
      websiteLabel: 'Weboldal',
      employmentPeriodLabel: 'A foglalkoztatás időszaka',
      fromLabel: 'tól',
      toLabel: 'ig',
    },
    profileStudy: {
      noUserLabel: 'Az alkalmazás használatához minden alapvető információt ki kell tölteni.',
      universityLabel: StudyName('HNG').hochsule,
      courseLabel: StudyName('HNG').studiengang,
      degreeLabel: StudyName('HNG').abshchluss,
    },
    profileOther: {
      noUserLabel: 'Az alkalmazás használatához minden alapvető információt ki kell tölteni.',
      hobbiesLabel: 'Hobbik',
      culturalLabel: 'Kulturális támogatás',
      socialMediaPlattform: 'Social-Media-Plattform',
      socialMediaLabel: 'Social Media Links',
      socialMediaLink: 'Link',
      socialMediaError: 'Kérjük, adja meg a teljes URL-t',
    },
    profileNotifications: {
      noUserLabel: 'Az alkalmazás használatához minden alapvető információt ki kell tölteni.',
      notificationsInfo:
        'Itt aktiválhatja az e-mail értesítéseket az egyes tevékenységekhez (alapértelmezett), vagy kikapcsolhatja őket 3 hónapra.',
      notificationsTitle: 'Push értesítések:',
      notificationsDescr0: 'A push-értesítéseket az ',
      notificationsDescr1: 'okostelefon rendszerbeállításaiban',
      notificationsDescr2: ' állíthatja be.',
      mailingTitle: 'E-mail értesítések:',
      mailingDescr:
        'Itt aktiválhatja az e-mail értesítéseket az egyes tevékenységekhez (alapértelmezett), vagy kikapcsolhatja őket 1 évre.',
      allNotificationsLabel: 'Minden értesítés',
      postsLabel: `Hozzászólások ${KreiseName('HNG').multi}`,
      messagesLabel: 'Privát üzenetek',
      commentsLabel: 'Észrevételek a saját hozzájárulásokhoz',
      contactRequestsLabel: 'Kapcsolatfelvételi kérelmek',
      eventsLabel: 'Események',
      clubMemberLabel: 'Új regisztrált tagok',
      enableLabel: 'A oldalon',
      disableToLabel: 'Kikapcsolva, amíg',
      disableLabel: 'A címről',
      ownPosts: 'Saját hozzászólások (E-mailben is megkapod)',
    },
    searchInput: {
      title: 'Keresés',
      placeholder: 'Keresés',
    },
    emptyList: {
      title: 'Itt nincs tartalom (még)',
    },
    switchLanguage: {
      placeholder: 'Nyelv/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'Russian (Русский)',
      SP: 'Spanish (Español)',
      NLD: 'Dutch (Nederlands)',
      POL: 'Polish (Polish)',
      title: 'Válassz nyelvet',
    },
    filePicker: {
      takePicLabel: 'Fotót készíteni',
      uploadPicLabel: 'Fénykép feltöltése',
      selectPicLabel: 'Fotó/video kiválasztása',
      selectDocLabel: 'Válassza ki a dokumentumot',
    },
    datePicker: {
      selectDateLabel: 'Válassza ki a dátumot',
      confirmDateLabel: 'Erősítse meg a dátumot',
      selectTimeLabel: 'Válassza ki az időt',
    },
    changePassword: {
      currentPasswordLabel: 'Jelenlegi jelszó',
      newPasswordLabel: 'Új jelszó',
      repeatPasswordLabel: 'Jelszó újra',
      changePasswordLabel: 'Jelszó módosítása',
    },
    appInfo: {
      appDescLabel:
        'Csevegés a klub barátokkal, rendezvények szervezése, szakmai és személyes kapcsolatépítés. Együtt a humánus világért - mindez egy alkalmazásban.',
      contactsLabel: InfoContactsName('HNG'),
      infoLabel: 'Információ',
      versionLabel: 'App verzió',
      agbLabel: 'AGB',
      dataProtectionLabel: 'Adatvédelmi nyilatkozat',
      manualLabel: 'Kézikönyv',
      appTextLabel: 'Itt találhat meg minket',
      appAndLabel: 'és a',
      appMainTextLabel: `Szeretne egyetemet vagy kerületet hozzáadni? Van más kérése vagy javaslata? Törli a profilját? Csak írjon nekünk egy rövid e-mailt az ${support_email}.`,
      deleteFeedback: 'A fiók törlésének oka / visszajelzés 🙂',
      deleteUserTitle: 'Fiók törlése?',
      deleteUserDesc:
        'Itt visszavonhatatlanul törölheti fiókját.\b egyébként: egyszerűen kikapcsolhatja az értesítéseket.Szerkessze a beállításokat a Profil -> Értesítések alatt, vagy írjon nekünk egy e -mailt.',
      deleteUserAlert: 'Az összes adata örökre törlődik, folytassa?',
      webLabel: 'Web-verzió',
    },
    infoMessages: {
      registartionSuccessLabel: 'Köszönjük a regisztrációját!',
      manualRegSuccessLabel: 'Köszönjük a regisztrációját! A profil hamarosan aktiválódik.',
      linkExpiredLabel: 'A link lejárt. Kérjen új jelszót.',
      emailExistLabel: 'Sajnáljuk, az e-mail nem érhető el.',
    },
    logoutAlert: {
      title: 'Kijelentkezés',
      text: 'Tényleg ki akarja őket jelenteni? Nem kell kijelentkeznie, egyszerűen bezárhatja vagy minimalizálhatja az alkalmazást.',
      confirm: 'Igen',
      dismiss: 'Nem',
    },
    invalidInputs: {
      passwordChars: `A jelszónak legalább ${passwordDigits(
        'HNG',
      )} karaktert kell tartalmaznia, legalább egy betűt, egy számot és egy speciális karaktert.`,
      fieldRequired: 'Ez a mező kötelező.',
      emailsNotMatching: 'Kérjük, győződjön meg róla, hogy az e-mail címei megegyeznek',
      emailInvalid: 'Az e-mail címe nem helyes!',
      selectCorrectAnswer: 'You need to select correct answer',
      corp: 'Kérjük, válasszon ki legalább 1 bejegyzést',
    },
    mentoring: {
      profile: {
        rules1: 'Elfogadom a',
        rules2: 'Értékrend',
        rules3: '“Mentorálás ösztöndíjasok számára” szabályait.',
        menteeDescription:
          'Kérlek, válaszd ki a mentorálási régiódat, hogy értesülhess a helyszíni eseményekről. MEGJEGYZÉS: A választásodat bármikor módosíthatod. Ez NEM korlátozza a mentor keresését.',
        mentorDescription:
          'Kérlek, töltsd ki a mentor profilodat, hogy a leendő ösztöndíjasok mint mentor megtalálhassanak. MEGJEGYZÉS: Legalább 1 régió és minimum 1 témakör szükséges.',
        mentoringRegions: 'Mentorálási régiók',
        reigonsShort: 'Régiók',
        topics: 'Témakörök',
        departments: 'Tanszék',
        available: 'Elérhető vagyok',
        unavailable: 'Jelenleg nem vagyok elérhető',
        deleteAccount: 'Mentor profil törlése & csoport elhagyása',
        deleteAccountDescr: 'A mentor profil törlésével és a mentor csoportchat elhagyásával egyetértesz?',
        uploadPdf: 'Mentor profil (PDF) feltöltése',
        myPdf: 'Saját profilom (PDF)',
        pdf: 'Mentor profil',
      },
      tabNames: {
        info: 'Információ',
        news: 'Hírek',
        profile: 'Profil',
        search: 'Keresés & Kérelem',
        request: 'Mentorálás kérése',
        requestDetails: 'Mentorálási kérés részletei',
        tabDeactivated: 'Fül deaktiválva. Kérjük, töltsd ki a mentorálási profilt.',
      },
      search: {
        description:
          'A keresés célzott indításához azt javasoljuk, hogy kezdd el a keresést néhány alapértelmezett szűrővel, majd egy új keresés során bővítsd a szűrőket. Ha a saját régiódban nem találsz megfelelő mentort, szélesítheted a keresést a szomszédos mentorálási régiókra. Keresési feltételek: 1 régió, 1-3 témakör. Minden mező “ÉS” kapcsolódású!',
        searchBtn: 'Keresés',
      },
      requests: {
        mentorIncoming: 'Mentorálási kérelmeim',
        mentorCurrent: 'Jelenlegi mentorálásaim',
        menteeOutgoing: 'Mentorálási kérelmem',
        menteeCurrent: 'Jelenlegi mentorálásom',
        cancelMentoring: 'Mentorálás megszüntetése',
        sendRequest: 'Mentorként jelentkezés',
        alreadyHaveMentor: 'Csak egy mentorod lehet',
        requestSuccess: 'Kérelem sikeresen elküldve',
        requestRetracted: 'Kérelem sikeresen visszavonva',
        cancelSendingRequest: 'Mentori kérelem törlése',
        approveRequest: 'Kérelem elfogadása',
        rejectRequest: 'Kérelem elutasítása',
        invitaionText: 'Kérelem',
        rejectionText: 'Indokolja elutasítását',
        sureRetractRequest: 'Valóban vissza szeretné vonni a kérelmet?',
      },
    },
    downloads: {
      root: 'Kezdőlap',
      delete: 'Törlés',
      rename: 'Átnevezés',
      areYouSureDeleteFolder: 'Biztosan törölni szeretné a mappát? Minden tartalom törlődni fog!',
      renameFolder: 'Mappa átnevezése',
      createNewFolder: 'Új mappa létrehozása',
      newFolderPlaceholder: 'Adja meg a mappa nevét',
      create: 'Létrehozás',
      areYouSureDeleteFile: 'Biztosan törölni szeretné a fájlt?',
      downloadFile: 'Letöltés',
      deleteFile: 'Törlés az eszközről',
      openInFinder: 'Megnyitás a Finderben',
      cancel: 'Mégse',
    },
    globalSearch: {
      postedBy: 'Közzétette',
      found: 'Találat',
      loadMore: 'További betöltése',
      enterQuery: 'Adjon meg bármilyen keresési kifejezést. Keresés bármi (név, dokumentum, esemény, bejegyzés, ...)',
      folder: 'Mappa',
      post: 'Bejegyzés',
      error: 'Legalább 3 karaktert adjon meg',
    },
    kreiseSettings: {
      description: 'Leírás',
      push: 'Push értesítések',
      email: 'E-mail értesítések',
      subscribers: 'Tagok',
      active: 'Aktiválva',
      oneYear: 'Némítva 1 évig',
      forever: 'Némítva örökké',
      until: 'Meddig',
      cantDeactive: 'Ezt a beállítást nem lehet deaktiválni ennek a csoportcsevegésnek',
      SINGLE: 'Minden egyes bejegyzésnél',
      SUMMARY_ONCE: 'Heti összefoglaló 1x',
      SUMMARY_TWICE: 'Heti összefoglaló 2x',
      SUMMARY_DAY: 'Napi összefoglaló 1x',
      OFF: 'Ki',
      PUSH: 'Push értesítés',
      EMAIL: 'E-mail értesítés',
      PUSH_AND_EMAIL: 'Push és e-mail értesítés',
      newUser: 'Új felhasználók értesítése',
      statistic: 'Küldési statisztika',
      off: 'Ki',
      ownPosts: 'Küldési statisztika a saját bejegyzéseidhez',
      deleteMemberDescr: 'eltávolítani?',
      deleteMemberBtn: 'Eltávolítás',
      retractAdminRights: 'Jogosultságok visszavonása',
      deleteAdminAndUser: 'Teljes eltávolítás',
      toDeleteKreiseBtn: 'Csoportcsevegés törlése',
      deleteKreiseDescr: `${KreiseName('HNG').single} véglegesen törlésre kerül?`,
      deleteKreiseModalTitle: 'Tag eltávolítása?',
      addKreiseName: 'Név',
      addKreiseDescription: 'Leírás',
      addMembers: 'Tagok hozzáadása',
      createKreise: 'Csoportcsevegés létrehozása',
      toKreiseEditMessage: 'Megjegyzés: Minden változtatás azonnal érvényesül.',
      kreiseCreator: 'A csoportcsevegés létrehozója',
      kreiseDeleteMembersLeft: 'A csoportcsevegést csak akkor lehet törölni, ha már csak egy tag marad',
      kreiseDeleteMembersLeftTitle: 'Először távolíts el tagokat',
      suggestionInNewsfeed: {
        admin: 'Admin',
        member: 'Tagok',
        corp: `${CorpsName('HNG').single} tagjai`,
        corpAdmin: `${CorpsName('HNG').single} admin`,
      },
    },

    surveys: {
      checkVotes: 'Szavazatok megtekintése',
      chooseOptions: {
        single: 'Válasszon egy lehetőséget',
        multiple: 'Válasszon több lehetőséget',
      },
      votesModalTitle: 'Szavazatok',
      createModalTitle: 'Kérdőív',
      noVotes: 'Nincsenek szavazatok',
      question: 'Kérdés',
      askAQuestion: 'Tegyél fel egy kérdést',
      allowMultiple: 'Több válasz engedélyezése',
      votes: {
        single: 'Szavazat',
        multiple: 'Szavazatok',
      },
      option: 'Lehetőség',
      optionsLabel: 'Kérdőív lehetőségek',
      anonymus: 'Névtelen kérdőív',
      create: 'Létrehozás',
    },
    subscription: {
      subscription: 'Abonnements',
      pricePerMonth: 'EUR/Monat',
      month: 'Monat',
      day: 'Tag',
      otherPlans: 'Andere Tarife',
      subscriptionActive: 'Abonnement aktiv',
      until: 'bis',
      subscribe: 'Abonnieren',
      successfulPayment: {
        title: 'Zahlung erfolgreich',
        text: 'Dein Abonnement wurde erfolgreich abgeschlossen. Du kannst ab sofort die erweiterten Funktionen nutzen.',
      },
      cancel: {
        cancel: 'Abonnement kündigen',
        title: 'Abonnement kündigen?',
        text: 'Möchtest du dein Abonnement kündigen? Nach Ablauf der bezahlten Periode werden deine Zugriffsrechte zurückgestuft.',
      },
    },
  },
  {
    key: 'SP', //spanish language
    key2: 'es',
    editProfile: {
      updated: 'Perfil actualizado.',
      validateFieldsViaEmail:
        'Por favor, valida los siguientes campos de datos por correo electrónico. Solo entonces se enviarán a la gestión central de miembros.',
      newValue: (value: any) => `Nuevo valor: "${value}"`,
      resendEmail: '¿O deberíamos reenviar el correo de validación?',
      validationPending: 'Validación pendiente',
      resend: 'Reenviar',
      resendDone:
        'El correo de validación ha sido reenviado. Por favor, espera unos minutos y revisa también tu carpeta de spam.',
      resendLong: '¿O deberíamos reenviar el correo de validación?',
    },
    appInfoScreen: {
      title: 'Acerca de',
      privacy: 'Información de privacidad',
      find: 'Aquí puedes encontrar los',
      terms: ' Términos de uso ',
      and: ' y ',
      infoSection: 'Información de la App',
      hasUpdate: 'Hay una versión más reciente. Haz clic aquí para actualizar.',
      noUpdate: 'Esta es la versión más reciente.',
      appVerison: 'Versión de la App:',
    },
    corpsScreen: {
      website: 'Sitio web:',
      email: 'Dirección de correo electrónico:',
      members: 'Miembros',
      admins: 'Administradores',
      info: 'Información',
      searchMember: 'Buscar miembros',
    },
    contactsScreen: {
      empty:
        "Aún no hay entradas aquí. Ve al menú principal y selecciona 'Buscar' → 'Nombre' para encontrar nuevos contactos.",
    },
    dialogPanel: {
      hangOn: '¿Adjuntar Bierjunge?',
      ok: 'OK',
    },
    dialogs: {
      emptyTitle: 'Haz clic en el más en la esquina superior derecha para iniciar una conversación.',
    },
    documentsGallery: {
      modalTitle: 'Eliminar un documento',
      modalDesc: '¿Eliminar este archivo?',
      cancel: 'Cancelar',
      delete: 'Eliminar',
      deleteConfirm: '¿Eliminar el archivo descargado (solo para mí)?',
      downloadConfirm: '¿Descargar este archivo?',
      availableInDownloads: `\n\n*Todos los archivos descargados están disponibles en la carpeta de Descargas.`,
    },
    drawerScreen: {
      backdrop: 'Toda la información básica debe completarse primero antes de usar la aplicación.',
    },
    kreiseInfoScreen: {
      creator: 'Creador de este chat grupal',
      revokeAdmin: (name: string) =>
        `¿Quieres quitar los derechos de administrador de "${name}" o eliminarlos completamente del grupo?`,
    },
    kreisePostScreen: {
      infoParticipants: (num: string | number) => `Información & Participantes (${num})`,
      subscribeTitle: '¿Suscribirse al chat grupal?',
      subscribeDesc: '¿Quieres suscribirte a este chat grupal para no perderte ninguna novedad?',
      yes: 'Sí',
      later: 'Tal vez más tarde',
      dontAsk: 'No preguntar más sobre este chat grupal',
    },
    mapScreen: {
      appUser: 'Usuario de la app',
      viewIsForMember: 'Ver perfiles de usuario solo está disponible para miembros.',
      all: 'Todos',
    },
    mentoringScreen: {
      clickMentor: 'Haz clic en el nombre del mentor para enviar un recordatorio o retirar la solicitud',
      all: 'Todos',
      startRequest: 'Iniciar solicitud:',
      startDesc:
        'Por favor, describe tu solicitud de mentoría de manera clara y concisa. Esto ayuda al mentor a evaluar mejor tu solicitud y responder rápidamente.',
      notice: 'Nota:',
      noticeDesc:
        'Deberías permitir hasta 2 semanas para que el mentor responda antes de enviar un recordatorio. Retira tu solicitud pendiente antes de contactar a otro mentor.',
      withdrawRequest: 'Retirar solicitud',
      reminder: 'Enviar recordatorio',
      reminderSuccess: 'El recordatorio está siendo enviado (+ copia para ti)',
      rejectRequest: 'Rechazar solicitud',
      requestTitle: 'Solicitud de mentoría',
      areas: 'Áreas de especialización',
      topics: 'Temas',
      withReguards: (name: string, lastname: string) => `\n\n\n(...)\n\nAtentamente,\n\n${name} ${lastname}`,
      rejectionMessage: (name: string, lastname: string) =>
        `¡Hola!\n\nLamentablemente, debo rechazar la solicitud de mentoría. Estoy feliz de explicar las razones si lo deseas.\n\nAtentamente,\n\n${name} ${lastname}`,
      maxMessage: (max: number | string, topic: string) => `Puedes seleccionar un máximo de ${max} ${topic}.`,
      decisionOpen: 'Decisión: abierta',
      maxMentoring: 'Máx. 1 solicitud de mentoría permitida',
      maxParalelMentoring: 'Máx. 1 solicitud de mentoría paralela permitida',
      openHere: 'Abrir el ',
      profileUpdated: '¡Perfil actualizado!',
      mentoringProfile: 'Perfil de mentoría',
      mentee: 'Mentee',
      mentor: 'Mentor',
      leaveMentoring: 'Dejar el grupo de mentoría',
      mentoringTitle: 'Mentoría',
      scrollAuto: 'ejemplo de pestañas automáticas desplazables',
      stopMentoringMessageMentor: (name: string, lastname: string) => `¿Terminar mentoría con ${name} ${lastname}?`,
      stopMentoringMessageMentee: (name: string, lastname: string) => `¿Terminar mentoría con ${name} ${lastname}?`,
      mentorEndedMentorship: (name: string, lastname: string) =>
        `El mentor ${name} ${lastname} ha finalizado la mentoría.`,
      mentorRejectedMentorship: (name: string, lastname: string) =>
        `El mentor ${name} ${lastname} rechazó tu solicitud.`,
      menteeEndedMentorship: (name: string, lastname: string) =>
        `El mentee ${name} ${lastname} ha terminado la mentoría.`,
      menteeRejectedMentorship: (name: string, lastname: string) =>
        `El mentee ${name} ${lastname} ha rechazado tu solicitud.`,
      mentorat: 'Mentoría',
    },
    newEventScreen: {
      kreiseError: 'Por favor selecciona el público objetivo.',
      correctDate: 'Corrige la fecha/hora',
      correctDateMessage: 'La fecha/hora de finalización es anterior a la de inicio. Por favor, corrige.',
    },
    outRequestScreen: {
      backButton: 'Solicitudes de contacto',
      requestMessage: (name: string, lastname: string) =>
        `¡Hola!\n\nMe gustaría añadirte a mis contactos.\n\nSaludos cordiales,\n\n${name} ${lastname}`,
    },
    participantsScreen: {
      interestedParties: 'Partes interesadas',
      removeGuestTitle: (name: string) => `¿Eliminar a ${name}?`,
      removeGuestDesc: (name: string) => `¿Eliminar a "${name}" de la lista de participantes?`,
      informUser: (name: string) => `Informar a "${name}" sobre su eliminación de la lista de participantes`,
    },
    partParticipantsScreen: {
      interestedParties: 'Partes interesadas',
      removeParticipantTitle: (name: string, lastname: string) =>
        `¿Eliminar al participante "${name} ${lastname}" de la lista de participantes?`,
      removeParticipantDesc: (name: string, lastname: string) => `¿Eliminar a "${name} ${lastname}"?`,
    },
    documentsScreen: {
      deleteTitle: 'Archivos seleccionados:',
      cancel: 'Cancelar',
      uploadData: 'Subir datos',
    },
    postsScreen: {
      ads: 'Anuncios...',
      oldPostsForMembers: 'Las publicaciones antiguas solo son visibles para miembros.',
    },
    profileBasisScreen: {
      editPhoto: 'Editar foto',
    },
    profileBenachrichtigungenScreen: {
      fillData: 'Toda la información básica debe completarse antes de que se pueda usar la aplicación.',
      changesInChat: 'Puedes hacer estos ajustes en el chat grupal correspondiente.',
      commentsWarning1:
        'Desactivar las notificaciones por correo electrónico para los comentarios en tus publicaciones no es recomendable, ya que podrían perderse comentarios importantes.',
      commentsWarning2:
        'Desactivar las notificaciones por correo electrónico para mensajes privados no es recomendable, ya que podrían perderse mensajes importantes.',
      messagesWarning1:
        'Desactivar las notificaciones por correo electrónico para mensajes privados no es recomendable, ya que generalmente recibes pocos mensajes privados en comparación con las publicaciones del chat grupal. Sin embargo, son importantes porque generalmente involucran solicitudes específicas dirigidas a ti. Estos mensajes privados (importantes) podrían perderse si se desactivan las notificaciones.',
      messagesWarning2:
        'Desactivar las notificaciones por correo electrónico para los comentarios en tus publicaciones no es recomendable, ya que podrían perderse comentarios importantes.',
      areYouSure: '¿Estás seguro?',
      turnOff: 'Apagar',
      maybeLater: 'Tal vez más tarde',
      notRecommended: 'Ajuste no recomendado',
      ok: 'OK',
    },
    profileBerufScreen: {
      fillData: 'Toda la información básica debe completarse antes de que se pueda usar la aplicación.',
      defaultDate: 'Hasta hoy',
      noArea: 'Sin área',
      furtherActivity: 'Actividad adicional',
      deleteConfirm: '¿Realmente deseas eliminar la entrada?',
      ok: 'OK',
    },
    profileCorpsScreen: {
      corpsname: 'Nombre del proyecto',
      createHint: 'Se está creando una nueva entrada.',
      requestHint: 'Se está solicitando la creación de la nueva entrada.',
      status: 'Estado',
      deleteConfirm: (name: string) => `¿Realmente deseas eliminar tu membresía en ${name}?`,
      ok: 'OK',
      fillData: 'Toda la información básica debe completarse antes de que se pueda usar la aplicación.',
      duplicatedCorps: (name: string) => `El ${name} ya está en el perfil, por favor verifica una vez.`,
      missingCorpName: 'Por favor, proporciona el nombre del proyecto.',
      waitingActivation: 'Esperando activación',
      requestExistingMessage: (name: string) =>
        `Se ha solicitado la membresía en "${name}". Serás notificado una vez que sea aprobada.`,
      requestNewMessage: (name: string) =>
        `Se ha solicitado la creación de "${name}". Serás notificado una vez que sea aprobada.`,
      createMessage: (name: string) => `"${name}" ha sido creado y añadido a tu perfil.`,
    },
    profileSonstigenScreen: {
      other: 'Otros (por favor especifica)',
    },
    profileDetailsScreen: {
      email: 'Correo electrónico',
    },
    profilePrivacyScreen: {
      fillData: 'Toda la información básica debe completarse antes de que se pueda usar la aplicación.',
    },
    profileStudiumScreen: {
      deleteConfirm: '¿Realmente deseas eliminar la entrada?',
      ok: 'OK',
      fillData: 'Toda la información básica debe completarse antes de que se pueda usar la aplicación.',
    },
    reportScreen: {
      reportUser: 'Denunciar usuario',
      notMember: 'El usuario ya no es miembro',
      fakeProfile: 'El usuario está creando ofertas de perfiles falsos.',
      violations1: 'La foto de perfil del usuario infringe las normas.',
      violations2: 'Las declaraciones del usuario infringen las normas.',
      violations3: 'El usuario está enviando spam en el chat.',
      violations4: 'Informar/etiquetar contenido potencialmente ofensivo',
      violations5: 'Informar/etiquetar a otros usuarios por posibles infracciones',
      reportReason: 'Razones para denunciar',
      sendReport: 'Enviar',
      cancel: 'Cancelar',
      showContent: 'Mostrar contenido de este usuario',
      hideContent: 'Ocultar contenido de este usuario',
    },
    resetPasswordScreen: {
      incorrectEmail: 'Tu dirección de correo electrónico es incorrecta.',
    },
    serverIsDownScreen: {
      serverMaintenance: 'Mantenimiento del servidor',
    },
    signInScreen: {
      dontHaveAccount: '¿No tienes una cuenta?',
    },
    signUpScreen: {
      login: 'Iniciar sesión',
      emailMatchError: 'El correo electrónico y la confirmación del correo electrónico no coinciden.',
      passwordMatchError: 'La contraseña y la confirmación de la contraseña no coinciden.',
      incorrectEmail: '¡Tu dirección de correo electrónico es incorrecta!',
      pleaseSelect: 'Por favor, selecciona',
      accountExistsTitle: 'Cuenta existente',
      accountExistsDesc: 'Ya tienes una cuenta con esta dirección de correo electrónico.',
      toLogin: 'Para iniciar sesión',
    },
    subscriptionsScreen: {
      subscribed: 'Suscrito:',
      noSubscribed: 'No se encontraron corporaciones suscritas',
      otherSubscriptions: 'Otros organizadores suscritos:',
      noOtherSubscriptions: `No se encontraron organizadores suscritos`,
    },
    sucheScreen: {
      searchFor: 'Resultado de búsqueda para:',
      filter: 'Filtrar:',
      name: 'Nombre',
      branch: 'Rama',
      function: 'Función',
      status: 'Estado',
      city: 'Ciudad',
      industry: 'Industria',
      business: 'Negocio',
      profession: 'Profesión',
      training: 'Formación',
      studyProgram: 'Programa de estudios',
      college: 'Universidad',
      cancel: 'Cancelar',
      searchBar: 'Barra de búsqueda',
      searchTab: 'Pestaña de búsqueda',
      searchTitle: 'Buscar',
      scrollable: 'Ejemplo de pestañas autodesplazables',
    },
    dialogComponent: {
      close: 'Cerrar',
    },
    phoneInputComponent: {
      noCountry: 'No se encontró el país.',
    },
    cropImageHandlerComponent: {
      cancel: 'Cancelar',
      crop: 'Recortar',
    },
    datePickerComponent: {
      cancel: 'Cancelar',
      ok: 'OK',
      date: 'Fecha/Hora',
      day: 'Día',
      month: 'Mes',
      year: 'Año',
      wrongDate: 'Fecha incorrecta',
    },
    timePickerComponent: {
      hours: 'Horas',
      minutes: 'Minutos',
    },
    responseHandlerComponet: {
      supportEmail: `Error desconocido. Por favor contacta a ${support_email}`,
    },
    searchModalComponent: {
      selectionError: 'Por favor selecciona al menos una entrada.',
      maximumSelection: (num: string | number) => `¡Por favor selecciona como máximo ${num} entradas!`,
    },
    customDropzoneComponent: {
      dropFiles: 'Suelta los archivos aquí',
      clickHere: ' o haz clic aquí para seleccionar ',
    },
    documentsGalleryComponent: {
      clickToOpen: 'Haz clic para abrir',
      clickToDownload: 'Haz clic para descargar',
    },
    eventToolbarComponent: {
      noRegistration: '¡No hay registro!',
      notLoggedId: 'Aún no has iniciado sesión. Esto es solo una expresión de interés.',
      registerVia: 'El registro se realiza a través de:',
    },
    likeComponent: {
      externalGuest: 'Invitado externo',
      invitedBy: (name: string) => `Invitado por ${name}`,
    },
    membersComponent: {
      interested: 'Expresó interés',
      loggedIn: 'Conectado',
      um: ' en ',
      invitedBy: (name: string) => `Invitado por ${name}`,
    },
    multipleSearchableInputComponent: {
      maximalMembers: `Por favor, especifica un máximo de ${maximal_membership_count} membresías.`,
    },
    multipleSelectModalComponent: {
      all: 'Todo',
    },
    offlineModalComponent: {
      checkInternet: 'Por favor revisa tu conexión a Internet.',
    },
    postsToolbarComponent: {
      membersOnly: 'Solo los miembros pueden publicar.',
    },
    zusagenModalComponent: {
      incorrectEmail: 'Tu correo electrónico es incorrecto',
      required: 'Este campo es obligatorio.',
      name: 'Nombre',
      email: 'Correo electrónico (opcional)',
      invitedBy: 'Invitado por',
      save: 'Guardar',
      cancel: 'Cancelar',
      warning1: `Nota: ¡Por favor regístrate aquí solo como ${guest_naming_singular}! Si tienes una cuenta en la aplicación, inicia sesión con ella y confirma oficialmente como miembro. ¡Gracias!`,
      warning2: 'Al registrarte, aceptas los ',
      and: ' y ',
      to: '.',
    },
    formInputComponent: {
      cancelConfirmation: '¿Cancelar confirmación?',
    },
    logoComponent: {
      alt: 'Logo de la aplicación',
    },
    customHistoryHook: {
      news: 'Mensaje',
    },
    postContentComponent: {
      orginized: 'Organizador:',
      date: 'Fecha:',
      ort: 'Lugar:',
    },
    beerSmileComponent: {
      bierjunge: '¡Bierjunge!',
    },
    buttonComponent: {
      examination: 'Examen:',
    },
    global: {
      all: 'Todos',
      postSubject: 'Asunto de tu publicación',
      showMore: 'Leer más',
      toRegister: 'Para registrarse',
      addToCalendar: 'iCal / ICS',
      interested: 'Interesado',
      loggedIn: 'Conectado',
      tilToday: 'Hasta hoy',
      status: 'Estado',
      deletePost: '¿Eliminar publicación?',
      pdf: '(PDF)',
      time: 'Hora',
      birthday: (age: number | string, name: string, lastname: string) => `${age}º cumpleaños de ${name} ${lastname}`,
      eur: 'EUR',
      price: 'Precio:',
      priceForMe: 'Precio para mí:',
      ok: 'Aceptar',
      likesCount: (num: string | number) => `Me gusta (${num})`,
      event: 'Evento',
      standardMessage: 'Estamos generando un asunto para tu publicación. ¿Te parece adecuado este asunto?',
      optionalMessage:
        '¿De qué trata tu publicación? Escribe un asunto corto. También puedes dejar que la IA te sugiera uno.',
      optionalMessageNote: 'Al utilizar esta función, aceptas los ',
      chatGPTTerms: 'Términos de uso de ChatGPT',
      chatGPTTermsLink: 'https://openai.com/policies/terms-of-use',
      optionalMessageNoteEnding: '.',
      postSubjectTitle: 'Asunto de la publicación',
      generate: 'Generar',
      defaultSubject: 'Asunto predeterminado',
      memebership: 'Membresía',
      applyMemebership: '¿Solicitar membresía?',
      pendingMembership: 'Solicitud de membresía enviada - decisión pendiente.',
      requestAlreadySent: 'Solicitud ya enviada',
      requestedMemebership: (kreiseName: string) => `Se ha solicitado la membresía en "${kreiseName}".`,
      yes: 'Sí',
      no: 'No',
      change: 'Cambiar',
      passwordOldMatchNewError: 'La contraseña antigua y la nueva coinciden',
      passwordLenghtError: 'La contraseña debe tener al menos 6 caracteres.',
      passwordsMatchError: 'La contraseña antigua y la nueva coinciden',
      passwordsMatchError2: 'La contraseña y la confirmación no coinciden.',
      currentPassword: 'Contraseña actual',
      newPassword: 'Nueva contraseña',
      confirmPassword: 'Confirmar contraseña',
      changePassword: 'Cambiar contraseña',
      cancel: 'Cancelar',
      fits: 'Encaja',
      selectChatTitle: 'Seleccionar chat grupal',
      selectChatDesc:
        "Selecciona al menos 1 chat favorito al que enviar tu publicación. Puedes encontrar más en el menú principal bajo 'Chats grupales'.",
      socialMedia: 'Redes sociales',
      generatedText: 'Estamos generando un texto para redes sociales. ¿Te parece adecuado este texto?',
      usePhoto: '¡Gracias por tu publicación! ¿Podemos usar también tu foto para los canales de redes sociales?',
      maximumLenght: 'Máximo 20,000 caracteres.',
      userSharedNewPicture: '¡Un usuario de nuestra app ha compartido una nueva foto!',
      contributionTo: (kreiseName: string) => `Contribución a “${kreiseName || 'Todos'}”`,
      postWarning1:
        '¡Adelante! Aún tendrás la oportunidad de agregar tu propio texto en el siguiente paso. Además, tu publicación será revisada.',
      postWarning2:
        'Después de enviarla, tu publicación será revisada por un administrador. Si tienes preguntas, no dudes en contactar a ',
      postWarning3: ' o ',
      AGB: 'Términos y condiciones',
      DSE: 'Política de privacidad',
      withCheckbox: "Al activar inicialmente mi cuenta utilizando la función '¿Olvidaste tu contraseña?', acepto los",
      withoutCheckbox:
        "Al activar inicialmente tu cuenta utilizando la función '¿Olvidaste tu contraseña?', aceptas los",
      tou: 'Términos de uso',
      dse: 'Política de privacidad',
      impressum: 'Aviso legal',
    },
    responseHandler: {
      messageLabel: `Error desconocido. Póngase en contacto con ${support_email}`,
      expiredLinkLabel: 'Enlace caducado. Solicitar un nuevo cambio de contraseña.',
      emailExistLabel: 'Lo sentimos, esta dirección de correo electrónico ya está registrada.',
      profileUpdated: 'Perfil actualizado',
      profileUpdatedCorpsError: `Perfil actualizado Las siguientes ${
        CorpsName('SP').single
      } no se ha podido añadir debido a un código de acceso incorrecto:`,
      appNeedsUpdate: 'Actualiza la aplicación en App Store / Google Play.',
      maximalVideoSize: 'El tamaño máximo de los archivos es de 2 GB.',
    },
    report: {
      notBelongLabel: 'El usuario ya no es miembro.',
      incProfileLabel: 'El usuario hace falsas ofertas de perfil',
      badPicLabel: 'La foto de perfil del usuario infringe las directrices',
      badComLabel: 'Las declaraciones del usuario infringen las directrices',
      spamLabel: 'El usuario envía spam en el chat',
      reasonsLabel: 'Motivos de la notificación',
    },
    drawer: {
      subscription: 'Subscription',
      news: `Últimas noticias`,
      kreise: KreiseName('SP').multi,
      search: 'Buscar en',
      calendar: CalendarName('SP'),
      documents: `${DocumentsName('SP')}`,
      contacts: 'Contactos',
      requests: 'Contacto',
      messages: MessagesName('SP'),
      profile: ProfileName('SP'),
      logout: 'Cerrar sesión',
      map: 'Mapa',
      mentoring: 'Tutoría',
    },
    imageCropper: {
      cropLabel: 'Cultivos',
      cancelLabel: 'Cancelar',
      chooseLabel: 'Listo',
    },
    signIn: {
      register: 'Regístrese en',
      title: 'Conéctese',
      loginPlaceholder: 'Correo electrónico',
      passwordPlaceholder: 'contraseña',
      forgotPassword: '¿Ha olvidado su contraseña?',
      loginBtn: 'Inicio de sesión',
      noAccount: '¿Aún no tiene cuenta?',
      description0: 'Si tiene alguna pregunta, póngase en contacto con',
      description1: 'o',
      description2: 'vuelta.',
      loginValidation: 'Su dirección de correo electrónico no es correcta',
      passwordValidation: 'La contraseña debe tener al menos 6 caracteres',
    },
    search: {
      name: 'Nombre',
      corps: CorpsName('SP').single,
      country: CountryName('SP'),
      city: 'Ciudad',
      industry: IndustryName('SP'),
      company: CompanyName('SP'),
      profession: 'Profesión',
      hobby: 'Intereses',
      cultural: 'Patrocinadores culturales',
      study: `${StudyName('SP').short}`,
      office: 'Sucursal',
      degreeProgram: StudyName('SP').studiengang,
      university: StudyName('SP').university,
      position: `${FunctionName('SP')}`,
      status: 'Estado',
      title: 'Resultado de la búsqueda de',
      filterTitle: 'Filtrar por',
      postCode: 'Código postal',
      global: 'Todo',
      activity: ActivityName('SP'),
    },
    resetPassword: {
      enterEmail: 'Introduzca su dirección de correo electrónico',
      inputPlaceholder: 'Correo electrónico',
      sendBtn: 'Enviar',
      cancelBtn: 'Cancelar',
      message: 'Si conocemos la dirección de correo electrónico, se ha enviado un enlace a la misma.',
      desc: 'Debes introducir la dirección de correo electrónico exacta que está almacenada en la aplicación. Comprueba tus correos electrónicos para ver cuál es.',
    },
    password: {
      resetLabel: 'Introduzca una nueva contraseña',
      inputLabel: 'Nueva contraseña',
    },
    signUp: {
      createAccount: 'Crear una cuenta',
      inputPlaceholder: `Código de inicio de sesión de su ${CorpsName('SP').single}s`,
      sendBtn: 'Más información en',
      descFirst: 'Al registrarse, acepta nuestros términos y condiciones ',
      descSecond: 'y confirme que ha leído nuestro',
      descThird: `Si tiene alguna pregunta, no dude en ponerse en contacto con nosotros en ${support_email} o +49 170 49 805.`,
      terms: 'condiciones generales',
      policy: 'política de privacidad.',
      description: 'Nota',
      generatePassword: 'Generar contraseña',
      noCodeSuccessRegister:
        'Gracias por registrarse. Se le informará por correo electrónico en cuanto se le haya activado.',
    },
    signUpDefault: {
      withoutCode: 'Sin código',
      createAccount: 'Sólo tiene que inscribirse aquí',
      withCode: 'Con código de invitación',
      simpleRegister: 'Sin código de invitación',
      existingAccount: 'Ya tiene una cuenta',
      descr01: 'Estoy de acuerdo con el',
      descr0: 'Al registrarse, acepta la',
      descr1: 'y el',
      descr2: 'a.',
      guest: `Registrarse como ${guest_naming_singular}`,
      withCodeWithDescr: `Registrieren als ${member_naming_singular}`,
    },
    signUpManual: {
      title: 'Regístrese en',
      photoDesc: 'Añadir foto',
      genderInput: 'Género',
      firstName: 'Nombre',
      lastName: 'Apellido',
      club: CorpsName('SP').single,
      specify: 'especifique',
      emailPlaceholder: 'Correo electrónico',
      emailHint: 'Utilice una dirección de correo electrónico de larga duración.',
      repeatEmailPlaceholder: 'E-Mail-Repetir dirección',
      passwordPlaceholder: 'Establecer contraseña',
      repeatPasswordPlaceholder: 'Repetir contraseña',
      postcode: 'Código postal',
      place: 'Lugar de residencia',
      country: 'País',
      loginCode: 'Código de invitación',
      mobilePlaceholder: 'Móvil no. (no visible en el perfil)',
      corps: SignUpCorpsName('SP'),
      searchCountry: 'Suche nach Land',
    },
    backToLogin: {
      desc: '¿Ya tiene una cuenta? ',
      login: 'Login',
    },
    backToSignUp: {
      desc: '¿Aún no tiene cuenta? ',
      register: 'Regístrese en',
    },
    privacy: {
      desc: 'Sí, acepto la',
      btn: 'Condiciones',
      zu: 'a',
      map: {
        title: 'Aviso de privacidad',
        privacyNotice: `Este mapa se ha implementado en cumplimiento del GDPR. Encontrará más información en nuestra política de privacidad.`,
        additionalInfo: {
          intro: 'Las chinchetas del mapa solo se',
          inaccurateDisplay: 'muestran de forma imprecisa',
          locationBasis: ', basadas en el código postal/ciudad pero sin calle/no.',
          noConclusion: 'Esto significa que no se pueden extraer conclusiones',
          onAddresses: 'sobre las direcciones específicas de los usuarios',
          conclusion: '.',
        },
        agree: 'Entendido',
        linkName: 'Política de privacidad',
        options: {
          doNotShowAgain: 'No volver a mostrar',
          doNotShowMeOnMap: 'No mostrarme en el mapa (ni siquiera aproximadamente)',
        },
      },
      restrictions: {
        overall: 'Esta función sólo es accesible a los afiliados.',
        overallWilkar: 'Esta función no es accesible por razones de protección de datos.',
        profile:
          'Sólo los miembros pueden ver los perfiles de los usuarios. Sin embargo, puedes escribir un mensaje privado al usuario.',
        groupchat: `Die ${KreiseName('SP').single} sólo son accesibles para los miembros.`,
        groupchatWilkar: `Por motivos de protección de datos ${
          KreiseName('SP').single
        } sólo son accesibles a los afiliados.`,
        comments: 'Los comentarios sólo son visibles para los miembros.',
        commentsWilkar: 'Por razones de protección de datos, los comentarios sólo pueden ser vistos por los miembros.',
        likes: 'La lista de "Me gusta" sólo la pueden ver los miembros.',
        post: 'Sólo los miembros pueden publicar artículos.',
        participantsList: 'La lista de participantes sólo puede ser consultada por los miembros.',
        commentsOnlyForMembers: 'Los comentarios sólo son visibles para los miembros.',
        postsLimitation: 'Los mensajes antiguos sólo pueden ser vistos por los miembros.',
      },
      settings: {
        guestPost: {
          title: `Verificado ${guest_naming_plural} puede ver mis mensajes`,
          ask: 'Pregunte siempre',
          no: 'No',
          yes: 'Sí',
        },
      },
      guestModal: {
        title: `Mayo validado ${guest_naming_plural} ver este post? Posteriormente, puede cambiar esta configuración de visibilidad para cada entrada individual.`,
        dontAskAgain:
          'No vuelvas a preguntar (Puede cambiar esta configuración en "Perfil". → Cambiar de nuevo "Privacidad).',
      },
      post: {
        ifShown: `Para ${guest_naming_plural} ocultar`,
        ifHidden: `Para ${guest_naming_plural} Mostrar`,
        ifShownModal: `La contribución ya está disponible para ${guest_naming_plural} ya no es visible.`,
        ifHiddenModal: `La contribución ya está disponible para ${guest_naming_plural} visible.`,
      },
    },
    searchModal: {
      landTitle: 'Elija un país',
      corpsTitle: `${CorpsName('SP').single} Seleccione`,
      pleaseSelect: 'Seleccione una opción',
    },
    btn: {
      sendBtn: 'Enviar',
      cancelBtn: 'Cancelar',
      select: 'Seleccione',
      cancelEventBtn: acceptEventName('SP').cancel,
      acceptBtn: acceptEventName('SP').accept,
      saveBtn: 'Guardar',
      clearBtn: 'Borrar',
      acceptReqBtn: 'Acepte',
      declineReqBtn: 'Cancelar',
      updateBtn: 'Actualizar',
      declineBtn: 'Rechazar',
      yesUnderstood: 'Ja, verstanden',

      ok: 'OK',
    },
    validation: {
      email: 'Su dirección de correo electrónico no es correcta',
      password: 'La contraseña debe tener al menos 6 caracteres',
      repeatEmail: 'El correo electrónico y la repetición de correo electrónico no coinciden.',
      repeatPassword: 'La contraseña y la repetición de contraseña no coinciden',
      required: 'Este campo es obligatoriot',
      matchPasswords: 'Coincidencia de contraseña antigua y nueva',
      dontMatchPasswords: 'La contraseña y la repetición de contraseña no coinciden.',
    },
    init: {
      title: 'Cargando',
      overlayLabel: 'Compruebe su conexión a Internet',
    },
    screenTitles: {
      postsTitle: `Últimas noticias`,
      circlesTitle: KreiseName('SP').multi,
      searchTitle: 'Buscar en',
      profileTitle: ProfileName('SP'),
      eventTitle: CalendarName('SP'),
      eventDetailsTitle: 'eventos',
      newEventTitle: 'Añadir evento',
      contactsTitle: 'Contactos',
      contactReqTitle: 'Contacto',
      reqDetailsTitle: 'Contacto',
      outReqTitle: 'Contacto',
      messagesTitle: MessagesName('SP'),
      changePasswordTitle: 'Cambiar contraseña',
      appInfoTitle: 'Acerca de',
      reportTitle: 'Denunciar usuario',
      createKreiseTitle: 'Crear chat de grupo',
      eventParticipants: 'Teilnehmer',
    },
    profileDetails: {
      showFilters: 'Mostrar filtros',
      user: 'Usuarios',
      mainTitleLabel: ProfileName('SP'),
      basisLabel: 'Base',
      clubLabel: CorpsName('SP').single,
      clubLabelPlural: CorpsName('SP').multi,
      professionLabel: 'Profesión',
      studyLabel: StudyName('SP').short,
      uploadPhotoLabel: 'Haz una foto',
      editPhotoLabel: 'Editar foto',
      profileCompletedLabel: 'Perfil',
      profileCompletedGLabel: 'completa',
      birthday: 'Fecha de nacimiento',
      functionLabel: `${FunctionName('SP')}`,
      dateOfJoinLabel: CorpsEditDate('SP'),
      positionDescLabel: 'Profesión',
      companyLabel: CompanyName('SP'),
      nameLabel: 'Nombre',
      websiteLabel: 'Página web',
      fromLabel: 'En',
      toLabel: 'Hasta',
      delete: 'Borrar',
      untilToday: 'Hasta hoy',
      degreeLabel: StudyName('SP').abshchluss,
      miscellaneos: 'Varios',
      hobbies: 'Intereses',
      cultural: 'Patrocinadores culturales',

      positionLabel: 'Profesión',
      firmaLabel: 'Empresa',
      industryLabel: IndustryName('SP'),
      areaLabel: 'Gama',
      typeOfEmplLabel: 'Tipo de empleo',
      activityLabel: ActivityName('SP'),
      careerStageLabel: 'Nivel profesional',
      activityDescLabel: 'Descripción de la actividad',
      employmentPeriodLabel: 'Periodo',
      universityLabel: StudyName('SP').hochsule,
      courseLabel: StudyName('SP').studiengang,
      ageLabel: 'Edad',
      birthdayLabel: 'Cumpleaños',
      editProfileLabel: 'Editar perfil',
      addContactLabel: 'Añadir como contacto',
      sendMessageLabel: 'Enviar mensaje',
      requestSentLabel: 'Solicitud de contacto ya enviada',
      sendRequestSuccessLabel: 'Consulta enviada correctamente',
      reportSentLabel: 'Usuario ya informado',
      addReportLabel: 'Denunciar/bloquear usuario',
      contactsLabel: 'Número de contactos',
      showBirthday: 'Contactos ver mi cumpleaños',
      street: 'Calle + Nº ',
      houseLabel: 'Número de casa',
      houseNumberLabel: 'Número de casa',
      partner: 'Nombre del socio',
      mobileNumberLabel: 'Móvil no.',
      handphoneNumberLabel: 'Número de teléfono móvil',
      telephoneNumberLabel: 'Número de teléfono',

      leibbursch: 'Leibbursch',
      leibfuechse: 'Leibfüchse',
      bio: 'Sobre mí',
      addDocuments: 'Añadirle documentos',
      image: 'Fotografía',
      video: 'Vídeo',
      pdf: 'PDF',
      link: 'Link',
      firstName: 'Nombre de pila',
      lastName: 'Apellidos',
      title: 'Título',
      email: 'E-Mail',
      name: 'Nombre',
      lastname: 'Apellido',
      country: CountryName('SP'),
      wohnort: 'Ciudad',
      postcode: 'CÓDIGO POSTAL',
      inReview: 'Confirmation via mail pending',
    },
    calendar: {
      birthdayLabel: 'Cumpleaños de',
      noEventsLabel: 'No hay eventos para la fecha seleccionada',
      allTabLabel: 'Todos',
      myTabLabel: 'Mi',
      clubsTabLabel: CorpsName('SP').single,
      subsTabLabel: 'Suscripciones',
      subscribedTabLabel: 'Abonados',
      otherTabLabel: 'Otros',

      error: 'La fecha/hora inicial debe ser anterior a la fecha/hora final.',
      filter: {
        all: 'Todos',
        invited: 'Invitado',
        confirmed: 'Prometido',
      },
    },
    eventLabels: {
      organizer: 'Organizer',
      registrationVia: 'Registro a través de',
      maximalParticipants: 'Número máximo de participantes',
      participantsWarning: 'El número de participantes es limitado.',
      interested: 'Interesados',
      notInterested: 'Inscripción a través de',
      removeFromWaitList: 'Eliminar de la lista de espera',
      addEventTitleImage: 'Agregar imagen de título',
      changeEventTitleImage: 'Cambiar imagen del título',
      inviteTo: 'Invitación a',
      addEndDate: 'Introduzca la fecha/hora de finalización',
      eventLabel: 'Organizador',
      startLabel: 'Comienza en',
      locationLabel: 'Lugar',
      participantLabel: 'Participantes',
      moreInfo: 'Para más información',
      commentsLabel: 'Comentarios',
      noCommentsLabel: 'Aún no hay comentarios',
      eventStartLabel: 'Inicio',
      eventEndLabel: 'Fin',
      descLabel: 'Descripción de la',
      creatorLabel: 'Creado por',
      titleLabel: 'Título',
      deleteEventTitle: '¿Borrar evento?',
      deleteEventLabel: '¿Suprimir este evento?',
      clubsTitle: `${CorpsName('SP').single}s`,
      gruppenchat: 'Seleccionar chat de grupo',
      contactsTitle: 'Contactos',
      delCommentLabel: '¿Borrar comentario?',
      sendInvite: 'Enviar invitación a',
      selectCorps: 'Seleccionar el grupo destinatario',
      externalGuest: 'Invitados externos',
      externalGuestPlaceholder: 'Introduzca su dirección de correo electrónico',
      attachmentLabel: 'Anexo',
      shareLinkLabel: 'Compartir enlace',
      linkLabel: `${guest_naming_singular} entre en`,
      copyLinkLabel: 'Copiar enlace',
      iCalDownload: 'Calendario',
      timezone: 'Huso horario',
      visibility: 'Visibilidad',
      errorDate: 'La fecha está en el pasado',
      startAfterEndError: 'La fecha de finalización debe ser posterior a la de inicio',
      members: 'Miembros',
      waitlist: 'Lista de espera',
      onWaitlist: 'en lista de espera',
      leaveWaitlist: '¿Dejar lista de espera?',
      leaveWaitlistDesc:
        '¿Eliminar de la lista de espera?\n\n Si te unes a la lista de espera nuevamente más tarde, podrías ser colocado al final de la lista.',
      limitAchieved: (limit: number) =>
        `Se ha alcanzado el número máximo de participantes de ${limit}. Has sido agregado a la lista de espera.`,
      visibilityOptions: {
        allWithoutGuests: 'Miembros y en el sitio web',
        invited_member: 'Sólo miembros invitados',
        verified_member: 'Todos los miembros',
        authorized_member: 'Todos los socios e invitados',
        all: 'Miembros, invitados y en el sitio web',
      },
      modalEvent: {
        title: 'Evento creado',
        subTitle: 'El acontecimiento se creó.\n\n',
        content:
          'Dependiendo de la configuración del usuario, ahora se enviarán notificaciones push/notificaciones por correo electrónico sobre el evento. Si lo ha configurado en "Perfil" → "Notificaciones", también recibirá una notificación por correo electrónico sobre el evento.',
      },
      modalComment: {
        title: '¿A quién avisar?',
        message: 'Quién debe ser informado de su comentario?',
        optionAll: 'Todos los participantes',
        optionNone: 'Sólo organizador(es)',
        button: 'Enviar comentario',
      },
      cancelModal: {
        title: '¿Cancelar o eliminar?',
        message: '¿Cancelar o eliminar un evento?',
        optionAll: 'Cancelaciones (se informará a los participantes)',
        optionNone: 'Suprimir (no se informa a los participantes)',
      },
      updateEventModal: {
        title: 'Envío de nuevas invitaciones',
        label: 'Los demás grupos fueron invitados.',
        message:
          'Dependiendo de la configuración, ahora se envían notificaciones push o por correo electrónico sobre el evento a los demás grupos.',
      },
      eventRegistrationType: {
        app: 'App',
        event: 'Enlace/Dirección de correo electrónico',
        enterLink: 'Añadir enlace/dirección de correo electrónico o similar',
      },
      parts: {
        part: 'Parte',
        addPart: 'Agregar punto del programa',
        hint: 'Explicación',
        question: 'Pregunta',
        single: 'Solo una opción seleccionable',
        multi: 'Varias opciones disponibles',
        freeText: 'Respuesta de texto libre',
        quiz: 'Quiz',
        questionnaire: 'Cuestionario',
        addQuestionnaire: 'Agregar cuestionario',
        questionnaireType: 'Opciones de respuesta',
        option: 'Opción',
        addOption: 'Agregar opción',
        showMembers: 'Participantes',
        youJoined: 'Participas en:',
        totalToPay: 'Importe total a pagar:',
        yourAnswer: 'Tu respuesta',
        visibleForCreators: 'Visible solo para los creadores',
        allAnswers: 'Todas las respuestas:',
        addUsers: 'Agregar usuarios',
        isAnonym: 'Voto anónimo',
        isAnonymQuiz: 'Quiz anónimo',
      },
    },
    rrEventLabels: {
      title: 'Repetición',
      MO: 'Montag',
      TU: 'Lunes',
      WE: 'Miércoles',
      TH: 'Jueves',
      FR: 'Viernes',
      SA: 'Sábado',
      SU: 'Domingo',
      YEARLY: { single: 'Año', regular: 'Anualmente' },
      MONTHLY: { single: 'mes', regular: 'Mensualmente' },
      WEEKLY: { single: 'semana', regular: 'Semanal' },
      DAILY: { single: 'Día', regular: 'Diario' },
      SINGLE: { single: 'Único', regular: 'Único' },
      CUSTOM: {
        single: 'Definido por el usuario...',
        regular: 'Definido por el usuario...',
      },
      freq: 'Frecuencia',
      interval: 'Intervalo',
      notRepeat: 'Único',
      every: 'Cada',
      month: 'mes',
      week: 'semana',
      last: 'último',
      on: 'en',
      everyMonthOn: 'Todos los meses en',
      ofTheMonth: 'del mes',
      editLabel: '¿Qué fechas desea cambiar?',
      thisDate: 'Esta fecha sólo',
      thisDateAndFollowing: 'Ésta y todas las',
      allDates: 'Todas las fechas',
      after: 'En',
      time: 'Citas',
      times: 'Terminen',
      neverEnds: 'Nunca termina',
      until: 'hasta',
      repeatedDefault: ({ frequency, freq }: { frequency: string; freq: number }) =>
        `¿Es cada${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency} repetido`,
      repeatedMonthly: ({ day, interval }: { day: string; interval: number }) =>
        `Será cada${interval == 1 ? '' : ` ${interval}.`} Mes en ${day.length > 2 ? day : `${day}.`} repetido.`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }: { day: string; interval: number; bysetpos: number }) =>
        `Será cada${bysetpos == -1 ? ' último' : ` ${bysetpos}.`} ${day} de la${
          interval === 1 ? '' : ` ${interval}.`
        } Mes repetido.`,

      repeatedYearly: ({ interval }: { interval: number }) =>
        `Si cada${interval == 1 ? '' : ` ${interval}.`} Año repetido.`,
      repeatedWeekly: ({ interval, byweekday }: { interval: number; byweekday: any }) =>
        `¿Es cada${interval != 1 ? ` ${interval}.` : ''} semana${
          !!byweekday?.length
            ? ' am ' + byweekday?.slice(0, -1).join(', ') + (byweekday?.length > 1 ? ' y ' : '') + byweekday?.slice(-1)
            : ''
        } repetido.`,
      repeatedDaily: ({ interval }: { interval: number }) =>
        `Será cada${interval != 1 ? ` ${interval}.` : ''} Día repetido.`,
    },
    requestsLabels: {
      receiveLabel: 'Reciba',
      sentLabel: 'Enviado',
    },
    post: {
      adTitle: 'Información/visualización',
      toKreise: 'en',
      comment: 'Comentarios',
      myPost: 'I',
      openPdf: 'Haga clic para abrir',
      deletePost: '¿Borrar este post?',
      posted: 'Publicado en',
      commented: 'Comentado',
      contacts: 'Contactos',
      sendToContacts: 'Mensaje a todos',
      enterText: 'Escriba aquí un mensaje',
      sendToCircle: `Mensaje para ${KreiseName('SP').singleArticle}`,
      sendComment: 'Escriba un comentario',
      longMessage: 'Máximo 20.000 caracteres',
      noComments: 'Aún no hay comentarios',
      deleteComment: '¿Borrar comentario?',
      deleteEventPost: '¿Borrar este mensaje? Esto no borrará el evento.',
      editPostLabel: 'Editar entrada',
      editingPostLabel: 'Modo edición',
      deletePostLabel: 'Suprimir puesto',
      editedAt: 'Procesado',
      pinned: 'Clavado.',
      actions: {
        label: 'Acciones postales',
        pin: {
          label: 'Pin post',
          once: 'Sube una vez',
          d3: '3 días',
          d5: '5 días',
          w1: '1 semana',
          w2: '2 semanas',
          unpin: 'No fijar más',
        },
      },
    },
    circles: {
      circlesTitle: `Abonados ${KreiseName('SP').multi}`,
      noCirclesTitle: `No suscrito ${KreiseName('SP').multi}`,
      myPost: 'I',
      circlesInfoTitle: 'Información y participantes',
      circlesSubscribe: 'Suscríbase a',
      unsubscribeAlert: '¿Dejar irrevocablemente un chat de grupo cerrado?',
      continueAlert: 'Abandono',
      readOnlyKreise: `Se trata de un "sólo lectura-${
        KreiseName('SP').single
      }". Sólo los administradores pueden crear mensajes aquí. Sin embargo, puedes escribir comentarios en las entradas.`,
    },
    messages: {
      addMessageLabel: 'Introducir mensaje',
      changesSaved: 'Cambios guardados.',
      newConversation: 'Nueva conversación',
    },
    profileTabs: {
      basicTab: 'Base',
      clubsTab: `${CorpsName('SP').single}`,
      miscellaneosTab: 'Intereses',
      professionTab: 'Profesión',
      studyTab: StudyName('SP')?.short,
      notificationsTab: 'Notificaciones',
      privacy: 'Privacidad',
    },
    profile: {
      addPhotoLabel: 'Añadir foto',
      genderInputLabel: 'Género',
      titleLabel: 'Título',
      firstNameLabel: 'Nombre',
      lastNameLabel: 'Apellido',

      passwordLabel: 'Establecer contraseña',
      birthDateLabel: 'Fecha de nacimiento',
      birthday: 'Fecha de nacimiento',
      changePasswordLabel: 'Cambiar contraseña',
      defaultCountryLabel: 'Alemania',
      defaultGraduationLabel: 'No especificado',
      defaultCorpsLabel: 'ninguno',
      street: 'Calle + No. ',
      houseNumberLabel: 'Número de casa',
      partner: 'Nombre del socio',
      mobileNumberLabel: 'Nº de móvil',
      handphoneNumberLabel: 'Número de teléfono móvil',
      telephoneNumberLabel: 'Número de teléfono',
    },
    profileKorp: {
      verband: CorpsName('SP').single,
      function: FunctionName('SP'),
      dateOfJoin: CorpsEditDate('SP'),
      beername: 'Nombre de la cerveza',
    },
    profileClubs: {
      noUserLabel: 'Para poder utilizar la aplicación, primero hay que rellenar toda la información básica..',
      functionLabel: `${FunctionName('SP')}`,
      setClubLabel: `Introduzca el nombre del ${CorpsName('SP').single} especifique.`,
      matchClubLabel: 'En',
      matchClubContinueLabel: 'ya existe en el perfil, compruébelo una vez.',
      clubLabel: `${CorpsName('SP').single.toUpperCase()}`,
      clubCodeLabel: `CÓDIGO DE ACCESO DE SU ${CorpsName('SP').single.toUpperCase()}S`,
      entryTimeLabel: CorpsEditDate('SP'),
      deleteClubLabel: '¿Desea ampliar su afiliación a la',
      deleteClubContinueLabel: '¿Borrar de verdad?',
      selectVariant: '¿Qué le gustaría hacer?',
      requestExistingCorp: 'Añadir entrada existente',
      requestNewCorps: 'Solicitar nueva entrada',
      createCorp: 'Crear una nueva entrada',
    },
    profileProfession: {
      jobTitle: 'Puesto',
      noUserLabel: 'Antes de poder utilizar la aplicación, hay que rellenar toda la información básica.',
      untilToday: 'Hasta hoy',
      positionTitleLabel: 'Profesión',
      itemNameLabel: 'Profesión',
      employmentLabel: 'Tipo de empleo',
      activityLabel: ActivityName('SP'),
      careerStageLabel: 'Nivel profesional',
      activityDescLabel: 'Descripción de la actividad',
      companyLabel: CompanyName('SP'),
      nameLabel: 'Nombre',
      industryLabel: IndustryName('SP'),
      areaLabel: 'Gama',
      websiteLabel: 'Sitio web de la empresa',
      employmentPeriodLabel: 'Período de empleo',
      fromLabel: 'En',
      toLabel: 'Hasta',
    },
    profileStudy: {
      noUserLabel: 'Antes de poder utilizar la aplicación, hay que rellenar toda la información básica.',
      universityLabel: StudyName('SP').hochsule,
      courseLabel: StudyName('SP').studiengang,
      degreeLabel: StudyName('SP').abshchluss,
    },
    profileOther: {
      noUserLabel: 'Antes de poder utilizar la aplicación, hay que rellenar toda la información básica.',
      hobbiesLabel: 'Intereses',
      culturalLabel: 'Patrocinadores culturales',
      socialMediaPlattform: 'Plataforma de medios sociales',
      socialMediaLabel: 'Enlaces a redes sociales',
      socialMediaLink: 'Link',
      socialMediaError: 'Introduzca la URL completa',
    },
    profileNotifications: {
      noUserLabel: 'Para poder utilizar la aplicación, primero hay que rellenar toda la información básica..',
      notificationsInfo:
        'Aquí puede activar las notificaciones por correo electrónico para actividades individuales (por defecto) o desactivarlas durante 3 meses.',
      notificationsTitle: 'Notificaciones push:',
      notificationsDescr0: 'Puede configurar las notificaciones push en ',
      notificationsDescr1: 'Ajustes del sistema del smartphone',
      notificationsDescr2: ' escriba a.',
      mailingTitle: 'Notificaciones por correo electrónico:',
      mailingDescr:
        'Aquí puede activar las notificaciones por correo electrónico para actividades individuales (por defecto) o desactivarlas durante 1 año.',
      allNotificationsLabel: 'Todas las notificaciones',
      postsLabel: `Contribuciones en ${KreiseName('SP').multi}`,
      messagesLabel: 'Mensajes privados',
      commentsLabel: 'Comentarios sobre las "contribuciones propias',
      contactRequestsLabel: 'Contacto',
      eventsLabel: 'eventos',
      clubMemberLabel: 'Nuevos miembros registrados',
      enableLabel: 'An',
      disableToLabel: 'Aus bis',
      disableLabel: 'Aus',
      ownPosts: 'Mis contribuciones (correo recibido también por mí)',
    },
    searchInput: {
      title: 'Buscar en',
      placeholder: 'Buscar en',
    },
    emptyList: {
      title: 'No hay contenido aquí (todavía)',
    },
    switchLanguage: {
      placeholder: 'Idioma/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'Russian (Русский)',
      SP: 'Spanish (Español)',
      NLD: 'Dutch (Nederlands)',
      POL: 'Polish (Polish)',
      title: 'Seleccionar idioma',
    },
    filePicker: {
      takePicLabel: 'Archivos adjuntos',
      uploadPicLabel: 'Haz una foto',
      selectPicLabel: 'Seleccionar foto/vídeo',
      selectDocLabel: 'Seleccionar documento',
    },
    datePicker: {
      selectDateLabel: 'Seleccione la fecha',
      confirmDateLabel: 'Confirme',
      selectTimeLabel: 'Seleccionar hora',
    },
    changePassword: {
      currentPasswordLabel: 'Contraseña actual',
      newPasswordLabel: 'Nueva contraseña',
      repeatPasswordLabel: 'Repetir contraseña',
      changePasswordLabel: 'Cambiar contraseña',
    },
    appInfo: {
      appDescLabel:
        'Korponnect es la aplicación para estudiantes de fraternidades. Intercambio entre los miembros, organización de eventos y creación de redes profesionales y privadas: todo en una sola aplicación.',
      contactsLabel: InfoContactsName('SP'),
      infoLabel: 'Información sobre',
      versionLabel: 'Versión de la aplicación',
      agbLabel: 'GTC',
      dataProtectionLabel: 'Política de privacidad',
      manualLabel: 'Instrucciones',
      appTextLabel: 'Aquí encontrará nuestro',
      appAndLabel: 'y un',
      appMainTextLabel: `¿Quieres añadir un círculo o tienes otra sugerencia? ¿Quiere borrar su perfil? Envíenos un breve correo electrónico a ${support_email}.`,
      deleteFeedback: 'Motivo de la eliminación / comentario 🙂:',
      deleteUserTitle: '¿Borrar cuenta?',
      deleteUserDesc:
        'Aquí puedes eliminar tu cuenta de forma irrevocable.\b Por cierto: también puedes desactivar las notificaciones de forma sencilla. Edita la configuración en Perfil -> Notificaciones o envíanos un correo electrónico.',
      deleteUserAlert: '¿De verdad quieres borrar tu cuenta?',
      webLabel: 'Versión web',
    },
    infoMessages: {
      registartionSuccessLabel: 'Gracias por inscribirse.',
      manualRegSuccessLabel:
        'Gracias por registrarse. En breve se le activará y se le informará por correo electrónico.',
      linkExpiredLabel: 'Enlace caducado. Solicitar un nuevo cambio de contraseña.',
      emailExistLabel: 'Lo sentimos, el correo electrónico no está disponible.',
    },
    logoutAlert: {
      title: 'Cerrar sesión',
      text: '¿De verdad quieres cerrar la sesión? No tienes que cerrar la sesión, puedes simplemente cerrar o minimizar la aplicación.',
      confirm: 'Sí',
      dismiss: 'Cancelar',
    },
    invalidInputs: {
      passwordChars: `La contraseña debe ser como mínimo ${passwordDigits(
        'SP',
      )} caracteres, incluyendo al menos una letra, un número y un carácter especial.`,
      fieldRequired: 'Este campo es obligatorio.',
      emailsNotMatching: 'Asegúrese de que sus correos electrónicos coinciden',
      emailInvalid: 'Su dirección de correo electrónico no es correcta.',
      selectCorrectAnswer: 'You need to select correct answer',
      corp: 'Seleccione al menos 1 entrada',
    },
    mentoring: {
      profile: {
        rules1: 'Estoy de acuerdo con el',
        rules2: ' Comprensión de los valores ',
        rules3: '“Tutoría para becarios” a.',
        menteeDescription:
          'Seleccione su región de tutoría para que se le pueda informar sobre los eventos in situ. NOTA: Puede cambiar su selección en cualquier momento. NO limita su búsqueda de mentores.',
        mentorDescription:
          'Por favor, rellene su perfil de mentor para que los becarios puedan encontrarle como mentor. NOTA: Se requiere al menos 1 región y al menos 1 área temática.',
        mentoringRegions: 'Regiones de tutoría.',
        reigonsShort: 'Regiones',
        topics: 'Áreas temáticas',
        departments: 'Departamento',
        available: 'Estoy disponible',
        unavailable: 'Actualmente no estoy disponible',
        deleteAccount: 'Eliminar el perfil del tutor y abandonar el grupo',
        deleteAccountDescr: '¿Borrar el perfil de tutor y abandonar el chat del grupo de tutoría?',
        uploadPdf: 'Perfil del tutor (PDF)',
        myPdf: 'Mi perfil (PDF)',
        pdf: 'Perfil del tutor',
      },
      tabNames: {
        info: 'Información',
        news: 'Noticias',
        profile: 'Perfil',
        search: 'Búsqueda y consulta',
        request: 'Mentoriza',
        requestDetails: 'Solicitud de tutoría',
        tabDeactivated: 'Pestaña desactivada. Rellene su perfil de tutor.',
      },
      search: {
        description:
          'Para iniciar la búsqueda de forma selectiva, le recomendamos que comience con unos pocos campos de selección y, a continuación, amplíe la parrilla de búsqueda en una nueva búsqueda. Si no encuentra un mentor adecuado en su región, amplíe la búsqueda a una región de mentores vecina. Criterios de búsqueda: 1 región, de 1 a 3 áreas temáticas. Todos los campos están vinculados por AND.',
        searchBtn: 'Suche',
      },
      requests: {
        mentorIncoming: 'Mis consultas sobre tutoría',
        mentorCurrent: 'Mis mentorados',
        menteeOutgoing: 'Mi solicitud de tutoría',
        menteeCurrent: 'Mi tutoría',
        cancelMentoring: 'Fin de la tutoría',
        sendRequest: 'Solicitud como mentor',
        alreadyHaveMentor: 'Sólo puedes tener un mentor',
        requestSuccess: 'Consulta enviada correctamente',
        requestRetracted: 'Consulta retirada con éxito',
        cancelSendingRequest: 'Retirar la solicitud de tutoría',
        approveRequest: 'Aceptar solicitud',
        rejectRequest: 'Rechazar solicitud',
        invitaionText: 'Consulta',
        rejectionText: 'Razone su rechazo',
        sureRetractRequest: '¿De verdad quiere retirar la consulta?',
      },
    },

    downloads: {
      root: 'Inicio',
      delete: 'Eliminar',
      rename: 'Renombrar',
      areYouSureDeleteFolder: '¿Seguro que quieres eliminar la carpeta? ¡Se eliminarán todos los contenidos!',
      renameFolder: 'Renombrar carpeta',
      createNewFolder: 'Crear nueva carpeta',
      newFolderPlaceholder: 'Introduce el nombre de la carpeta',
      create: 'Crear',
      areYouSureDeleteFile: '',
      downloadFile: 'Descargar',
      deleteFile: 'Eliminar del dispositivo',
      openInFinder: 'Abrir en el Finder',
      cancel: 'Cancelar',
    },
    globalSearch: {
      postedBy: 'Publicado por',
      found: 'Encontrado',
      loadMore: 'Cargar más',
      enterQuery:
        'Introduce cualquier término de búsqueda. Puedes buscar cualquier cosa (nombre, documento, evento, publicación, ...)',
      folder: 'Carpeta',
      post: 'Publicación',
      error: 'Introduce al menos 3 caracteres',
    },
    kreiseSettings: {
      description: 'Descripción de la',
      push: 'Notificaciones push',
      email: 'Notificaciones por correo',
      subscribers: 'Miembros',
      active: 'Activado',
      oneYear: 'Silencio durante 1 año',
      forever: 'Silencio para siempre',
      until: 'Hasta',
      cantDeactive: 'Esta configuración está desactivada para este chat de grupo',
      SINGLE: 'Para cada contribución',
      SUMMARY_ONCE: 'Resumen 1 vez por semana',
      SUMMARY_TWICE: 'Resumen 2x por semana',
      SUMMARY_DAY: 'Resumen 1 vez al día',
      OFF: 'En',
      PUSH: 'Push-Benachrichtigung',
      EMAIL: 'Notificación por correo',
      PUSH_AND_EMAIL: 'Notificaciones push y por correo electrónico',
      newUser: 'Notificación para nuevos usuarios',
      statistic: 'Estadísticas de transmisiónk',
      off: 'En',
      ownPosts: 'Enviar correo estadístico para sus contribuciones',
      deleteMemberDescr: '¿Quitar?',
      deleteMemberBtn: 'Eliminar',
      retractAdminRights: 'Retirar derechos',
      deleteAdminAndUser: 'Quitar completamente',
      toDeleteKreiseBtn: 'Borrar chat de grupo',
      deleteKreiseDescr: `${KreiseName('SP').single} ¿borrar irrevocablemente?`,
      deleteKreiseModalTitle: '¿Retirar a un miembro?',
      addKreiseName: 'Nombre',
      addKreiseDescription: 'Descripción de la',
      addMembers: 'Añadir miembros',
      createKreise: 'Crear chat de grupo',
      toKreiseEditMessage: 'Nota: Todos los cambios de los miembros se aplicarán inmediatamente.',
      kreiseCreator: 'Creador:en este chat de grupo',
      kreiseDeleteMembersLeft: 'El chat de grupo sólo puede borrarse cuando sólo queda un miembro.',
      kreiseDeleteMembersLeftTitle: 'Eliminar primero a los miembro',
      suggestionInNewsfeed: {
        admin: 'Admin',
        member: 'Miembros',
        corp: `${CorpsName('SP').single} miembros`,
        corpAdmin: `${CorpsName('SP').single} admin`,
      },
    },
    surveys: {
      checkVotes: 'Resultados',
      chooseOptions: {
        single: 'Selecciona una opción.',
        multiple: 'Seleccione al menos una opción.',
      },
      votesModalTitle: 'Resultados',
      createModalTitle: 'Encuesta',
      noVotes: 'No se han emitido votos',
      question: 'Pregunta',
      askAQuestion: 'Formule una pregunta',
      allowMultiple: 'Permitir varias respuestas',
      votes: {
        single: 'Voz',
        multiple: 'Voces',
      },
      option: 'Opción',
      optionsLabel: 'Opciones',
      anonymus: 'Anonymus poll',
      create: 'Cree',
    },
    subscription: {
      subscription: 'Abonnements',
      pricePerMonth: 'EUR/Monat',
      month: 'Monat',
      day: 'Tag',
      otherPlans: 'Andere Tarife',
      subscriptionActive: 'Abonnement aktiv',
      until: 'bis',
      subscribe: 'Abonnieren',
      successfulPayment: {
        title: 'Zahlung erfolgreich',
        text: 'Dein Abonnement wurde erfolgreich abgeschlossen. Du kannst ab sofort die erweiterten Funktionen nutzen.',
      },
      cancel: {
        cancel: 'Abonnement kündigen',
        title: 'Abonnement kündigen?',
        text: 'Möchtest du dein Abonnement kündigen? Nach Ablauf der bezahlten Periode werden deine Zugriffsrechte zurückgestuft.',
      },
    },
  },
  {
    key: 'KOR', //korean language /koreanisch
    key2: 'ko',
    editProfile: {
      updated: '프로필이 업데이트되었습니다.',
      validateFieldsViaEmail: '다음 데이터 필드를 이메일로 확인해 주세요. 그 후에만 중앙 회원 관리에 제출됩니다.',
      newValue: (value: any) => `새 값: "${value}"`,
      resendEmail: '아니면 확인 이메일을 다시 보내시겠습니까?',
      validationPending: '확인 대기 중',
      resend: '다시 보내기',
      resendDone: '확인 이메일이 다시 전송되었습니다. 몇 분 후에 확인해 주세요. 스팸 폴더도 확인해 주세요.',
      resendLong: '아니면 확인 이메일을 다시 보내드릴까요?',
    },
    appInfoScreen: {
      title: '정보',
      privacy: '개인정보 보호 정보',
      find: '여기에서 다음을 찾을 수 있습니다',
      terms: '이용 약관',
      and: '와',
      infoSection: '앱 정보',
      hasUpdate: '새 버전이 있습니다. 여기를 클릭하여 업데이트하세요.',
      noUpdate: '현재 최신 버전입니다.',
      appVerison: '앱 버전:',
    },
    corpsScreen: {
      website: '웹사이트:',
      email: '이메일 주소:',
      members: '회원',
      admins: '관리자',
      info: '정보',
      searchMember: '회원 검색',
    },
    contactsScreen: {
      empty: "여기에는 아직 항목이 없습니다. 메뉴로 가서 '검색' → '이름'을 선택하여 새로운 연락처를 찾아보세요.",
    },
    dialogPanel: {
      hangOn: 'Bierjunge 첨부?',
      ok: '확인',
    },
    dialogs: {
      emptyTitle: '대화 시작을 위해 오른쪽 상단의 더하기 버튼을 클릭하세요.',
    },
    documentsGallery: {
      modalTitle: '문서 삭제',
      modalDesc: '이 파일을 삭제하시겠습니까?',
      cancel: '취소',
      delete: '삭제',
      deleteConfirm: '다운로드된 파일을 삭제하시겠습니까? (나만 보기)',
      downloadConfirm: '이 파일을 다운로드하시겠습니까?',
      availableInDownloads: `\n\n*모든 다운로드된 파일은 다운로드 폴더에서 확인 가능합니다`,
    },
    drawerScreen: {
      backdrop: '앱을 사용하기 전에 모든 기본 정보를 먼저 작성해야 합니다.',
    },
    kreiseInfoScreen: {
      creator: '이 그룹 채팅의 생성자',
      revokeAdmin: (name: string) => `"${name}"의 관리자 권한을 제거하거나 그룹에서 완전히 제거하시겠습니까?`,
    },
    kreisePostScreen: {
      infoParticipants: (num: string | number) => `정보 & 참가자 (${num})`,
      subscribeTitle: '그룹 채팅을 구독하시겠습니까?',
      subscribeDesc: '이 그룹 채팅을 구독하여 새로운 소식을 놓치지 않으시겠습니까?',
      yes: '예',
      later: '나중에',
      dontAsk: '이 그룹 채팅에 대해 다시 묻지 않기',
    },
    mapScreen: {
      appUser: '앱 사용자',
      viewIsForMember: '사용자 프로필 보기 기능은 회원만 사용할 수 있습니다.',
      all: '모두',
    },
    mentoringScreen: {
      clickMentor: '멘토의 이름을 클릭하여 알림을 보내거나 요청을 철회하세요.',
      all: '모두',
      startRequest: '요청 시작:',
      startDesc:
        '멘토십 요청을 명확하고 간결하게 설명해 주세요. 이는 멘토가 요청을 더 잘 평가하고 신속하게 응답하는 데 도움이 됩니다.',
      notice: '알림:',
      noticeDesc:
        '멘토가 응답할 때까지 최대 2주를 기다려 주세요. 다른 멘토에게 연락하기 전에 진행 중인 요청을 철회하세요.',
      withdrawRequest: '요청 철회',
      reminder: '알림 보내기',
      reminderSuccess: '알림이 전송되고 있습니다 (+ 복사본도 보내드립니다)',
      rejectRequest: '요청 거부',
      requestTitle: '멘토십 요청',
      areas: '전문 분야',
      topics: '주제',
      withReguards: (name: string, lastname: string) => `\n\n\n(...)\n\n감사합니다,\n\n${name} ${lastname}`,
      rejectionMessage: (name: string, lastname: string) =>
        `안녕하세요!\n\n안타깝게도 멘토십 요청을 거절해야 합니다. 이유는 요청하시면 설명드리겠습니다.\n\n감사합니다,\n\n${name} ${lastname}`,
      maxMessage: (max: number | string, topic: string) => `최대 ${max}개의 ${topic}을(를) 선택할 수 있습니다.`,
      decisionOpen: '결정: 열림',
      maxMentoring: '최대 1개의 멘토십 요청 허용',
      maxParalelMentoring: '최대 1개의 병렬 멘토십 요청 허용',
      openHere: '여기에서 열기',
      profileUpdated: '프로필이 업데이트되었습니다!',
      mentoringProfile: '멘토십 프로필',
      mentee: '멘티',
      mentor: '멘토',
      leaveMentoring: '멘토십 그룹에서 나가기',
      mentoringTitle: '멘토십',
      scrollAuto: '자동 스크롤 탭 예시',
      stopMentoringMessageMentor: (name: string, lastname: string) =>
        `${name} ${lastname}와(과)의 멘토십을 종료하시겠습니까?`,
      stopMentoringMessageMentee: (name: string, lastname: string) =>
        `${name} ${lastname}와(과)의 멘토십을 종료하시겠습니까?`,
      mentorEndedMentorship: (name: string, lastname: string) => `멘토 ${name} ${lastname}가 멘토십을 종료했습니다.`,
      mentorRejectedMentorship: (name: string, lastname: string) =>
        `멘토 ${name} ${lastname}가 멘토십 요청을 거절했습니다.`,
      menteeEndedMentorship: (name: string, lastname: string) => `멘티 ${name} ${lastname}님이 멘토링을 종료했습니다.`,
      menteeRejectedMentorship: (name: string, lastname: string) => `멘티 ${name} ${lastname}님이 요청을 거절했습니다.`,
      mentorat: '멘토십',
    },
    newEventScreen: {
      kreiseError: '대상 그룹을 선택하세요',
      correctDate: '날짜/시간 수정',
      correctDateMessage: '종료 날짜/시간이 시작 날짜/시간보다 이전입니다. 수정해주세요.',
    },
    outRequestScreen: {
      backButton: '연락처 요청',
      requestMessage: (name: string, lastname: string) =>
        `안녕하세요!\n\n귀하를 제 연락처에 추가하고 싶습니다.\n\n감사합니다.\n\n${name} ${lastname}`,
    },
    participantsScreen: {
      interestedParties: '참여자',
      removeGuestTitle: (name: string) => `${name}을(를) 제거하시겠습니까?`,
      removeGuestDesc: (name: string) => `"${name}"을(를) 참가자 목록에서 제거하시겠습니까?`,
      informUser: (name: string) => `"${name}"에게 참가자 목록에서 제거된 사실을 알리기`,
    },
    partParticipantsScreen: {
      interestedParties: '관심 있는 당사자',
      removeParticipantTitle: (name: string, lastname: string) =>
        `"${name} ${lastname}" 참가자를 참가자 목록에서 제거하시겠습니까?`,
      removeParticipantDesc: (name: string, lastname: string) => `"${name} ${lastname}"을(를) 제거하시겠습니까?`,
    },
    documentsScreen: {
      deleteTitle: '선택된 파일:',
      cancel: '취소',
      uploadData: '데이터 업로드',
    },
    postsScreen: {
      ads: '광고...',
      oldPostsForMembers: '이전 게시물은 회원만 볼 수 있습니다.',
    },
    profileBasisScreen: {
      editPhoto: '사진 편집',
    },
    profileBenachrichtigungenScreen: {
      fillData: '앱을 사용하기 전에 모든 기본 정보를 입력해야 합니다.',
      changesInChat: '각 그룹 채팅에서 이 설정을 변경할 수 있습니다.',
      commentsWarning1:
        '자신의 게시물에 대한 댓글에 대한 이메일 알림을 비활성화하면 중요한 댓글을 놓칠 수 있으므로 권장하지 않습니다.',
      commentsWarning2:
        '개인 메시지에 대한 이메일 알림을 비활성화하면 중요한 메시지를 놓칠 수 있으므로 권장하지 않습니다.',
      messagesWarning1:
        '개인 메시지에 대한 이메일 알림을 비활성화하면 중요한 개인 메시지를 놓칠 수 있으므로 권장하지 않습니다. 이는 일반적으로 그룹 채팅 게시물보다 적은 개인 메시지를 받기 때문입니다. 하지만 이는 보통 특정 요청이 포함된 메시지이므로 중요한 메시지를 놓칠 수 있습니다.',
      messagesWarning2:
        '자신의 게시물에 대한 댓글에 대한 이메일 알림을 비활성화하면 중요한 댓글을 놓칠 수 있으므로 권장하지 않습니다.',
      areYouSure: '확실합니까?',
      turnOff: '끄기',
      maybeLater: '나중에 할게요',
      notRecommended: '추천하지 않는 설정',
      ok: '확인',
    },
    profileBerufScreen: {
      fillData: '앱을 사용하기 전에 모든 기본 정보를 입력해야 합니다.',
      defaultDate: '오늘까지',
      noArea: '해당 없음',
      furtherActivity: '추가 활동',
      deleteConfirm: '정말로 이 항목을 삭제하시겠습니까?',
      ok: '확인',
    },
    profileCorpsScreen: {
      corpsname: '프로젝트 이름',
      createHint: '새 항목이 생성 중입니다.',
      requestHint: '새 항목 생성이 요청되었습니다.',
      status: '상태',
      deleteConfirm: (name: string) => `${name}에서 회원 자격을 정말로 삭제하시겠습니까?`,
      ok: '확인',
      fillData: '앱을 사용하기 전에 모든 기본 정보를 입력해야 합니다.',
      duplicatedCorps: (name: string) => `${name}은 이미 프로필에 있습니다. 다시 확인해주세요.`,
      missingCorpName: '프로젝트 이름을 입력해주세요.',
      waitingActivation: '활성화를 기다리는 중',
      requestExistingMessage: (name: string) => `"${name}"의 회원 가입이 요청되었습니다. 승인되면 알림을 받게 됩니다.`,
      requestNewMessage: (name: string) => `"${name}"의 생성을 요청했습니다. 승인되면 알림을 받게 됩니다.`,
      createMessage: (name: string) => `"${name}"가 생성되어 프로필에 추가되었습니다.`,
    },
    profileSonstigenScreen: {
      other: '기타 (입력해주세요)',
    },
    profileDetailsScreen: {
      email: '이메일',
    },
    profilePrivacyScreen: {
      fillData: '앱을 사용하기 전에 모든 기본 정보를 입력해야 합니다.',
    },
    profileStudiumScreen: {
      deleteConfirm: '정말로 이 항목을 삭제하시겠습니까?',
      ok: '확인',
      fillData: '앱을 사용하기 전에 모든 기본 정보를 입력해야 합니다.',
    },
    reportScreen: {
      reportUser: '사용자 신고',
      notMember: '이 사용자는 더 이상 회원이 아닙니다.',
      fakeProfile: '이 사용자는 가짜 프로필을 만들고 있습니다.',
      violations1: '이 사용자의 프로필 사진이 가이드라인을 위반합니다.',
      violations2: '이 사용자의 발언이 가이드라인을 위반합니다.',
      violations3: '이 사용자가 채팅에서 스팸을 보내고 있습니다.',
      violations4: '잠재적으로 유해한 콘텐츠 신고/표시',
      violations5: '다른 사용자를 잠재적 위반으로 신고/표시',
      reportReason: '신고 사유',
      sendReport: '보내기',
      cancel: '취소',
      showContent: '이 사용자의 콘텐츠 보기',
      hideContent: '이 사용자의 콘텐츠 숨기기',
    },
    resetPasswordScreen: {
      incorrectEmail: '이메일 주소가 잘못되었습니다.',
    },
    serverIsDownScreen: {
      serverMaintenance: '서버 점검 중',
    },
    signInScreen: {
      dontHaveAccount: '계정이 없으신가요?',
    },
    signUpScreen: {
      login: '로그인',
      emailMatchError: '이메일과 이메일 확인이 일치하지 않습니다.',
      passwordMatchError: '비밀번호와 비밀번호 확인이 일치하지 않습니다.',
      incorrectEmail: '이메일 주소가 잘못되었습니다!',
      pleaseSelect: '선택해주세요',
      accountExistsTitle: '계정이 존재합니다',
      accountExistsDesc: '이미 이 이메일 주소로 계정을 등록하셨습니다.',
      toLogin: '로그인 하러 가기',
    },
    subscriptionsScreen: {
      subscribed: '구독 중:',
      noSubscribed: '구독 중인 코퍼레이션을 찾을 수 없습니다',
      otherSubscriptions: '기타 구독 중인 주최자:',
      noOtherSubscriptions: `구독 중인 주최자를 찾을 수 없습니다`,
    },
    sucheScreen: {
      searchFor: '검색 결과:',
      filter: '필터:',
      name: '이름',
      branch: '분야',
      function: '기능',
      status: '상태',
      city: '도시',
      industry: '산업',
      business: '사업',
      profession: '직업',
      training: '교육',
      studyProgram: '학위 프로그램',
      college: '대학교',
      cancel: '취소',
      searchBar: '검색 창',
      searchTab: '검색 탭',
      searchTitle: '검색',
      scrollable: '스크롤 가능한 자동 탭 예시',
    },
    dialogComponent: {
      close: '닫기',
    },
    phoneInputComponent: {
      noCountry: '국가를 찾을 수 없습니다.',
    },
    cropImageHandlerComponent: {
      cancel: '취소',
      crop: '자르기',
    },
    datePickerComponent: {
      cancel: '취소',
      ok: '확인',
      date: '날짜/시간',
      day: '일',
      month: '월',
      year: '년',
      wrongDate: '잘못된 날짜',
    },
    timePickerComponent: {
      hours: '시간',
      minutes: '분',
    },
    responseHandlerComponet: {
      supportEmail: `알 수 없는 오류가 발생했습니다. ${support_email}로 문의해 주세요`,
    },
    searchModalComponent: {
      selectionError: '하나 이상의 항목을 선택해주세요.',
      maximumSelection: (num: string | number) => `최대 ${num}개 항목만 선택 가능합니다!`,
    },
    customDropzoneComponent: {
      dropFiles: '여기에 파일을 드롭하세요',
      clickHere: '또는 여기를 클릭하여 선택',
    },
    documentsGalleryComponent: {
      clickToOpen: '열기 클릭',
      clickToDownload: '다운로드 클릭',
    },
    eventToolbarComponent: {
      noRegistration: '등록 없음!',
      notLoggedId: '아직 로그인하지 않았습니다. 이는 관심 표현에 불과합니다.',
      registerVia: '등록은 다음을 통해 진행됩니다:',
    },
    likeComponent: {
      externalGuest: '외부 게스트',
      invitedBy: (name: string) => `${name}이(가) 초대함`,
    },
    membersComponent: {
      interested: '관심 표현',
      loggedIn: '로그인됨',
      um: '에서',
      invitedBy: (name: string) => `${name}이(가) 초대함`,
    },
    multipleSearchableInputComponent: {
      maximalMembers: `최대 ${maximal_membership_count}명까지 등록할 수 있습니다.`,
    },
    multipleSelectModalComponent: {
      all: '모두',
    },
    offlineModalComponent: {
      checkInternet: '인터넷 연결을 확인해주세요.',
    },
    postsToolbarComponent: {
      membersOnly: '회원만 게시할 수 있습니다.',
    },
    zusagenModalComponent: {
      incorrectEmail: '이메일이 잘못되었습니다',
      required: '이 필드는 필수입니다.',
      name: '이름',
      email: '이메일 (선택사항)',
      invitedBy: '초대한 사람',
      save: '저장',
      cancel: '취소',
      warning1: `
			주의: 여기서는 ${guest_naming_singular}으로만 등록해주세요! 
			앱에 계정이 있는 경우, 로그인하여 공식적으로 회원으로 확인해주세요. 감사합니다!`,
      warning2: '등록함으로써, 당신은 ',
      and: ' 및 ',
      to: '에 동의합니다.',
    },
    formInputComponent: {
      cancelConfirmation: '확인을 취소하시겠습니까?',
    },
    logoComponent: {
      alt: '앱 로고',
    },
    customHistoryHook: {
      news: '메시지',
    },
    postContentComponent: {
      orginized: '주최자:',
      date: '날짜:',
      ort: '장소:',
    },
    beerSmileComponent: {
      bierjunge: '맥주 한잔!',
    },
    buttonComponent: {
      examination: '검토:',
    },
    global: {
      all: '전체',
      postSubject: '게시물 주제',
      showMore: '더 읽기',
      toRegister: '등록하기',
      addToCalendar: 'iCal / ICS',
      interested: '관심 있음',
      loggedIn: '로그인됨',
      tilToday: '오늘까지',
      status: '상태',
      deletePost: '게시물을 삭제하시겠습니까?',
      pdf: '(PDF)',
      time: '시간',
      birthday: (age: number | string, name: string, lastname: string) => `${age}번째 생일 ${name} ${lastname}`,
      eur: 'EUR',
      price: '가격:',
      priceForMe: '나를 위한 가격:',
      ok: '확인',
      likesCount: (num: string | number) => `좋아요 (${num})`,
      event: '이벤트',
      standardMessage: '게시물의 제목을 생성 중입니다. 이 제목이 적합한가요?',
      optionalMessage:
        '게시물은 무엇에 관한 것인가요? 간단한 제목을 작성하세요. AI가 제안을 생성하도록 할 수도 있습니다.',
      optionalMessageNote: '이 기능을 사용함으로써 당신은 ',
      chatGPTTerms: 'ChatGPT 이용 약관',
      chatGPTTermsLink: 'https://openai.com/policies/terms-of-use',
      optionalMessageNoteEnding: '에 동의합니다.',
      postSubjectTitle: '게시물 제목',
      generate: '생성',
      defaultSubject: '기본 제목',
      memebership: '회원 가입',
      applyMemebership: '회원 가입 신청하시겠습니까?',
      pendingMembership: '회원 가입 신청이 제출되었습니다 - 결정 대기 중.',
      requestAlreadySent: '요청이 이미 전송되었습니다.',
      requestedMemebership: (kreiseName: string) => `"${kreiseName}"에 대한 회원 가입이 요청되었습니다.`,
      yes: '네',
      no: '아니오',
      change: '변경',
      passwordOldMatchNewError: '기존 비밀번호와 새 비밀번호가 일치합니다',
      passwordLenghtError: '비밀번호는 최소 6자 이상이어야 합니다.',
      passwordsMatchError: '기존 비밀번호와 새 비밀번호가 일치합니다',
      passwordsMatchError2: '비밀번호와 확인 비밀번호가 일치하지 않습니다.',
      currentPassword: '현재 비밀번호',
      newPassword: '새 비밀번호',
      confirmPassword: '비밀번호 확인',
      changePassword: '비밀번호 변경',
      cancel: '취소',
      fits: '적합',
      selectChatTitle: '그룹 채팅 선택',
      selectChatDesc:
        "게시물을 보낼 최소 1개의 즐겨찾기 채팅을 선택하세요. '그룹 채팅' 메뉴에서 더 많은 채팅을 찾을 수 있습니다.",
      socialMedia: '소셜 미디어',
      generatedText: '소셜 미디어용 텍스트를 생성 중입니다. 이 텍스트가 적합한가요?',
      usePhoto: '게시물 감사합니다! 이 사진을 소셜 미디어 채널에서 사용해도 될까요?',
      maximumLenght: '최대 20,000자.',
      userSharedNewPicture: '우리 앱의 사용자가 새로운 사진을 공유했습니다!',
      contributionTo: (kreiseName: string) => `"${kreiseName || '모두'}"에 대한 기여`,
      postWarning1:
        '계속 진행하세요! 다음 단계에서 자신의 텍스트를 추가할 수 있는 기회가 있습니다. 또한, 게시물은 검토될 예정입니다.',
      postWarning2: '제출 후, 게시물은 관리자에 의해 검토됩니다. 질문이 있으면 ',
      postWarning3: ' 또는 ',
      AGB: '이용 약관',
      DSE: '개인정보 보호정책',
      withCheckbox: "'비밀번호 찾기' 기능을 사용하여 내 계정을 처음 활성화함으로써, 나는 여기서 ",
      withoutCheckbox: "'비밀번호 찾기' 기능을 사용하여 계정을 처음 활성화함으로써, 당신은 ",
      tou: '이용 약관',
      dse: '개인정보 보호정책',
      impressum: '법적 고지',
    },
    responseHandler: {
      messageLabel: `알 수 없는 오류입니다. ${support_email} 으로 문의하세요.`,
      expiredLinkLabel: '링크가 만료되었습니다. 새 비밀번호 변경 요청하기.',
      emailExistLabel: '죄송합니다. 이 이메일 주소는 이미 등록되어 있습니다.',
      profileUpdated: '프로필 업데이트',
      profileUpdatedCorpsError: `프로필이 업데이트되었습니다! 다음 사항 ${
        CorpsName('KOR').single
      } 로그인 코드가 잘못되어 추가할 수 없습니다:`,
      appNeedsUpdate: '앱 스토어/구글 플레이에서 앱을 업데이트하세요.',
      maximalVideoSize: '최대 파일 크기는 2GB입니다!',
    },
    report: {
      notBelongLabel: '사용자가 더 이상 회원이 아닙니다..',
      incProfileLabel: '사용자가 허위 프로필 제안을 하는 경우',
      badPicLabel: '사용자의 프로필 사진이 가이드라인을 위반합니다.',
      badComLabel: '사용자의 진술이 가이드라인을 위반하는 경우',
      spamLabel: '사용자가 채팅에서 스팸을 보내는 경우',
      reasonsLabel: '알림 이유',
    },
    drawer: {
      subscription: 'Subscription',
      news: `최신 뉴스`,
      kreise: KreiseName('KOR').multi,
      search: '검색',
      calendar: CalendarName('KOR'),
      documents: `${DocumentsName('KOR')}`,
      contacts: '연락처',
      requests: '연락처 문의',
      messages: MessagesName('KOR'),
      profile: ProfileName('KOR'),
      logout: '로그아웃',
      map: '지도',
      mentoring: '멘토링',
    },
    imageCropper: {
      cropLabel: '자르기',
      cancelLabel: '취소',
      chooseLabel: '완료',
    },
    signIn: {
      register: '등록하기',
      title: '로그인하세요',
      loginPlaceholder: '이메일 주소',
      passwordPlaceholder: '비밀번호',
      forgotPassword: '비밀번호를 잊어버리셨나요?',
      loginBtn: '로그인',
      noAccount: '아직 계정이 없으신가요?',
      description0: '궁금한 점이 있으면 다음 연락처로 문의하세요.',
      description1: '또는',
      description2: '턴.',
      loginValidation: '이메일 주소가 올바르지 않습니다.',
      passwordValidation: '비밀번호는 6자 이상이어야 합니다.',
    },
    search: {
      name: '이름',
      corps: CorpsName('KOR').single,
      country: CountryName('KOR'),
      city: '도시',
      industry: IndustryName('KOR'),
      company: CompanyName('KOR'),
      profession: '직업',
      hobby: '관심 분야',
      cultural: '문화 스폰서',
      study: `${StudyName('KOR').short}`,
      office: '지사 사무실',
      degreeProgram: StudyName('KOR').studiengang,
      university: StudyName('KOR').university,
      position: `${FunctionName('KOR')}`,
      status: '상태',
      title: '검색 결과',
      filterTitle: '필터링 기준',
      postCode: '우편번호',
      global: '모든 것',
      activity: ActivityName('KOR'),
    },
    resetPassword: {
      enterEmail: '이메일 주소를 입력하세요.',
      inputPlaceholder: '이메일 주소',
      sendBtn: '보내기',
      cancelBtn: '취소',
      message: '이메일 주소를 알고 있는 경우 해당 주소로 링크가 전송되었습니다.',
      desc: '앱에 저장된 정확한 이메일 주소를 입력해야 합니다. 어떤 이메일인지 확인하려면 이메일을 확인하세요.',
    },
    password: {
      resetLabel: '새 비밀번호 입력',
      inputLabel: '새 비밀번호',
    },
    signUp: {
      createAccount: '계정 만들기',
      inputPlaceholder: `로그인 코드 ${CorpsName('KOR').single}s`,
      sendBtn: '추가',
      descFirst: '등록하면 이용약관에 동의하는 것으로 간주됩니다. ',
      descSecond: 'u를 읽었는지 확인합니다.',
      descThird: `궁금한 점이 있으면 ${support_email} 또는 +49 170 49 805로 문의하시기 바랍니다.`,
      terms: '이용 약관',
      policy: '개인정보 보호정책.',
      description: '참고',
      generatePassword: '비밀번호 생성',
      noCodeSuccessRegister: '등록해 주셔서 감사합니다! 활성화되는 즉시 이메일로 알려드립니다.',
    },
    signUpDefault: {
      withoutCode: '코드 없이',
      createAccount: '여기에서 간단히 등록하세요.',
      withCode: '초대 코드 사용',
      simpleRegister: '초대 코드 없이',
      existingAccount: '이미 계정이 있으신가요?',
      descr01: '본인은',
      descr0: '등록하면 다음 사항에 동의하는 것으로 간주됩니다.',
      descr1: '및',
      descr2: '에.',
      guest: `다음 계정으로 등록 ${guest_naming_singular}`,
      withCodeWithDescr: `다음 계정으로 등록 ${member_naming_singular}`,
    },
    signUpManual: {
      title: '등록하기',
      photoDesc: '사진 추가',
      genderInput: '성별',
      firstName: '이름',
      lastName: '성',
      club: CorpsName('KOR').single,
      specify: '지정하십시오',
      emailPlaceholder: '이메일 주소',
      emailHint: '장기 이메일 주소를 사용해 주세요.',
      repeatEmailPlaceholder: '이메일 주소를 반복합니다.',
      passwordPlaceholder: '이메일 주소를 반복합니다.',
      repeatPasswordPlaceholder: '비밀번호 반복',
      postcode: '우편번호:,',
      place: '거주지',
      country: '국가',
      loginCode: '초대 코드',
      mobilePlaceholder: '휴대폰 번호(프로필에 표시되지 않음)',
      corps: SignUpCorpsName('KOR'),
      searchCountry: 'Suche nach Land',
    },
    backToLogin: {
      desc: '이미 계정이 있으신가요? ',
      login: '로그인',
    },
    backToSignUp: {
      desc: '아직 계정이 없으신가요? ',
      register: '등록하기',
    },
    privacy: {
      desc: '예, 본인은',
      btn: 'GTC',
      zu: '에',
      map: {
        title: '개인정보 보호 알림',
        privacyNotice: `이 지도는 GDPR을 준수하여 제작되었습니다.`,
        additionalInfo: {
          intro: '지도의 핀은',
          inaccurateDisplay: '우편번호/도시 기준으로만 부정확하게 표시되며',
          locationBasis: ', 도로명/집 번호는 표시되지 않습니다.',
          noConclusion: '따라서 사용자의 특정 주소에 대한',
          onAddresses: '결론을 도출할 수 없습니다',
          conclusion: '.',
        },
        agree: '이해했습니다',
        linkName: '개인정보 보호정책',
        options: {
          doNotShowAgain: '다시 표시하지 않기',
          doNotShowMeOnMap: '지도에 표시하지 않기 (대략적으로도)',
        },
      },
      restrictions: {
        overall: '이 기능은 회원만 액세스할 수 있습니다.',
        overallWilkar: '이 기능은 데이터 보호상의 이유로 액세스할 수 없습니다.',
        profile: '회원만 사용자 프로필을 볼 수 있습니다. 하지만 사용자에게 비공개 메시지를 작성할 수는 있습니다.',
        groupchat: `그리고 ${KreiseName('KOR').single} 는 회원만 액세스할 수 있습니다.`,
        groupchatWilkar: `데이터 보호를 위해 ${KreiseName('KOR').single} 회원만 액세스할 수 있습니다..`,
        comments: '댓글은 회원에게만 표시됩니다.',
        commentsWilkar: '데이터 보호를 위해 댓글은 회원만 볼 수 있습니다.',
        likes: '좋아요 목록은 회원만 볼 수 있습니다.',
        post: '회원만 글을 게시할 수 있습니다.',
        participantsList: '참가자 목록은 회원만 볼 수 있습니다.',
        commentsOnlyForMembers: '댓글은 회원에게만 표시됩니다.',
        postsLimitation: '이전 게시물은 회원만 볼 수 있습니다.',
      },
      settings: {
        guestPost: {
          title: `확인됨 ${guest_naming_plural} 내 게시물을 볼 수 있습니다`,
          ask: '항상 물어보세요',
          no: '아니요',
          yes: '예',
        },
      },
      guestModal: {
        title: `유효성 검사 가능 ${guest_naming_plural} 이 게시물을 보시나요? 나중에 각 개별 게시물에 대해 이 공개 설정을 변경할 수 있습니다.`,
        dontAskAgain: '다시 묻지 마세요(“프로필“ → “개인정보“에서 이 설정을 변경할 수도 있습니다).',
      },
      post: {
        ifShown: `쇼${guest_naming_plural} 숨기기`,
        ifHidden: `쇼 ${guest_naming_plural} 보여주기`,
        ifShownModal: `기여금은 이제 다음 용도로 사용할 수 있습니다. ${guest_naming_plural} 더 이상 표시되지 않음.`,
        ifHiddenModal: `기여금은 이제 다음 용도로 사용할 수 있습니다. ${guest_naming_plural} 표시.`,
      },
    },
    searchModal: {
      landTitle: '국가 선택',
      corpsTitle: `${CorpsName('GER').single} 선택`,
      pleaseSelect: '선택하세요',
    },
    btn: {
      sendBtn: '보내기',
      cancelBtn: '취소',
      select: '선택',
      cancelEventBtn: acceptEventName('KOR').cancel,
      acceptBtn: acceptEventName('KOR').accept,
      saveBtn: '저장',
      clearBtn: '삭제',
      acceptReqBtn: '수락',
      declineReqBtn: '취소',
      updateBtn: '새로 고침',
      declineBtn: '거부',
      yesUnderstood: '네, 알겠습니다',
      ok: '확인',
    },
    validation: {
      email: '이메일 주소가 올바르지 않습니다.',
      password: '비밀번호는 6자 이상이어야 합니다.',
      repeatEmail: '이메일과 이메일 반복이 일치하지 않습니다.',
      repeatPassword: '비밀번호와 비밀번호 반복이 일치하지 않음',
      required: '이 필드는 필수 입력 사항입니다',
      matchPasswords: '이전 비밀번호와 새 비밀번호 일치',
      dontMatchPasswords: '비밀번호와 비밀번호 반복이 일치하지 않습니다.',
    },
    init: {
      title: '로드 중',
      overlayLabel: '인터넷 연결 상태를 확인하세요.',
    },
    screenTitles: {
      postsTitle: `최신 뉴스`,
      circlesTitle: KreiseName('KOR').multi,
      searchTitle: '검색',
      profileTitle: ProfileName('KOR'),
      eventTitle: CalendarName('KOR'),
      eventDetailsTitle: '이벤트',
      newEventTitle: '이벤트 추가',
      contactsTitle: '연락처',
      contactReqTitle: '연락처 문의',
      reqDetailsTitle: '연락처 문의',
      outReqTitle: '연락처 문의',
      messagesTitle: MessagesName('KOR'),
      changePasswordTitle: '비밀번호 변경',
      appInfoTitle: '정보',
      reportTitle: '사용자 신고',
      createKreiseTitle: '그룹 채팅 만들기',
      eventParticipants: 'Teilnehmer',
    },
    profileDetails: {
      showFilters: '필터 표시',
      user: '사용자',
      mainTitleLabel: ProfileName('KOR'),
      basisLabel: '기본',
      clubLabel: CorpsName('KOR').single,
      clubLabelPlural: CorpsName('KOR').multi,
      professionLabel: '직업',
      studyLabel: StudyName('KOR').short,
      uploadPhotoLabel: '사진 찍기',
      editPhotoLabel: '사진 편집',
      profileCompletedLabel: '프로필',
      profileCompletedGLabel: '완료',
      birthday: '생년월일',
      functionLabel: `${FunctionName('KOR')}`,
      dateOfJoinLabel: CorpsEditDate('KOR'),
      positionDescLabel: '직업',
      companyLabel: CompanyName('KOR'),
      nameLabel: '이름',
      websiteLabel: '웹사이트',
      fromLabel: '에서',
      toLabel: '까지',
      delete: '삭제',
      untilToday: '오늘까지',
      degreeLabel: StudyName('KOR').abshchluss,
      miscellaneos: '기타',
      hobbies: '관심 분야',
      cultural: '문화 스폰서',

      positionLabel: '직업',
      firmaLabel: '회사',
      industryLabel: IndustryName('KOR'),
      areaLabel: '범위',
      typeOfEmplLabel: '고용 유형',
      activityLabel: ActivityName('KOR'),
      careerStageLabel: '경력 수준',
      activityDescLabel: '활동 설명',
      employmentPeriodLabel: '기간',
      universityLabel: StudyName('KOR').hochsule,
      courseLabel: StudyName('KOR').studiengang,
      ageLabel: '나이',
      birthdayLabel: '생일',
      editProfileLabel: '프로필 수정',
      addContactLabel: '연락처로 추가',
      sendMessageLabel: '메시지 보내기',
      requestSentLabel: '이미 보낸 연락처 요청',
      sendRequestSuccessLabel: '문의가 성공적으로 전송되었습니다.',
      reportSentLabel: '이미 신고한 사용자',
      addReportLabel: '사용자 신고/차단',
      contactsLabel: '연락처 수',
      showBirthday: '연락처에서 내 생일 보기',
      street: '거리 + 아니요. ',
      houseLabel: '집 번호',
      houseNumberLabel: '집 번호',
      partner: '파트너 이름',
      mobileNumberLabel: '모바일 없음.',
      handphoneNumberLabel: '휴대폰 번호',
      telephoneNumberLabel: '전화 번호r',

      leibbursch: '바디 폭스',
      leibfuechse: '바디 폭스',
      bio: '내 소개',
      addDocuments: '문서 추가',
      image: '사진',
      video: '비디오',
      pdf: 'PDF',
      link: '링크',
      firstName: '이름',
      lastName: '성',
      title: '제목',
      email: '이메일',
      name: '이름',
      lastname: '성',
      country: CountryName('KOR'),
      wohnort: '도시',
      postcode: '포스트코드',
      inReview: 'Confirmation via mail pending',
    },
    calendar: {
      birthdayLabel: '생일',
      noEventsLabel: '선택한 날짜에 이벤트가 없습니다.',
      allTabLabel: '모두',
      myTabLabel: '마이',
      clubsTabLabel: CorpsName('KOR').single,
      subsTabLabel: '구독',
      subscribedTabLabel: '구독자',
      otherTabLabel: '기타',

      error: '시작 날짜/시간은 종료 날짜/시간 이전이어야 합니다.',
      filter: {
        all: '모두.',
        invited: '초대됨',
        confirmed: '약속',
      },
    },
    eventLabels: {
      organizer: 'Organizer',
      registrationVia: '등록을 통해',
      maximalParticipants: '최대 참가자 수',
      participantsWarning: '参加者数には制限があります 있습니다',
      interested: '이해관계자',
      notInterested: '관심 없음',
      removeFromWaitList: '대기자 명단에서 제거',
      addEventTitleImage: '제목 사진 추가',
      changeEventTitleImage: '제목 사진 변경',
      inviteTo: '초대',
      addEndDate: '종료 날짜/시간 입력',
      eventLabel: '주최자',
      startLabel: '다음에서 시작',
      locationLabel: '장소',
      participantLabel: '참가자',
      moreInfo: '추가 정보',
      commentsLabel: '댓글',
      noCommentsLabel: '아직 댓글이 없습니다',
      eventStartLabel: '시작',
      eventEndLabel: '종료',
      descLabel: '설명',
      creatorLabel: '만든 사람',
      titleLabel: 'Titel',
      deleteEventTitle: '이벤트 삭제?',
      deleteEventLabel: '이 이벤트를 삭제하시겠습니까?',
      clubsTitle: `${CorpsName('KOR').single}s`,
      gruppenchat: '그룹 채팅 선택',
      contactsTitle: '연락처',
      delCommentLabel: '댓글을 삭제하시겠습니까?',
      sendInvite: '다음 주소로 초대 보내기',
      selectCorps: '대상 그룹 선택',
      externalGuest: '외부 게스트 초대',
      externalGuestPlaceholder: '이메일 주소 입력',
      attachmentLabel: '부록',
      shareLinkLabel: '공유 링크',
      linkLabel: `${guest_naming_singular} 입력`,
      copyLinkLabel: '링크 복사',
      iCalDownload: '캘린더 파일',
      timezone: '시간대',
      visibility: '가시성',
      errorDate: '날짜가 과거입니다.',
      startAfterEndError: '종료 날짜가 시작 날짜보다 늦어야 합니다.',
      members: '멤버',
      waitlist: '대기 목록',
      onWaitlist: '대기 목록에 있음',
      leaveWaitlist: '대기 목록에서 나가시겠습니까?',
      leaveWaitlistDesc:
        '대기 목록에서 제거하시겠습니까?\n\n 나중에 다시 대기 목록에 등록하면 목록의 맨 뒤로 이동할 수 있습니다.',
      limitAchieved: (limit: number) => `최대 참가자 수인 ${limit}에 도달했습니다. 대기자 명단에 등록되었습니다.`,
      visibilityOptions: {
        allWithoutGuests: '멤버 및 웹사이트',
        invited_member: '초대된 회원만',
        verified_member: '모든 회원',
        authorized_member: '모든 회원 및 게스트',
        all: '회원, 게스트 및 웹사이트',
      },
      modalEvent: {
        title: '이벤트 생성',
        subTitle: '이벤트가 생성되었습니다.\n\n',
        content:
          "이제 사용자 설정에 따라 이벤트에 대한 푸시 알림/이메일 알림이 전송됩니다. \n\n'프로필' → '알림'에서 설정한 경우 이벤트에 대한 이메일 알림도 직접 받게 됩니다.",
      },
      modalComment: {
        title: '누구에게 알려야 하나요?',
        message: '내 댓글에 대해 누구에게 알려야 하나요?',
        optionAll: '모든 참가자',
        optionNone: '주최자 전용(en)',
        button: '댓글 보내기',
      },
      cancelModal: {
        title: '취소 또는 삭제?',
        message: '이벤트를 취소하거나 삭제하시겠습니까?',
        optionAll: '취소 (참가자에게 알림)',
        optionNone: '삭제 (참가자는 알림을 받지 않습니다)',
      },
      updateEventModal: {
        title: '추가 초대장 발송',
        label: '다른 그룹은 초대되었습니다',
        message: '설정에 따라 이제 이벤트에 대한 푸시 알림 또는 이메일 알림이 다른 그룹으로 전송됩니다.',
      },
      eventRegistrationType: {
        app: '앱',
        event: '링크/이메일 주소',
        enterLink: '링크/이메일 주소 또는 유사 주소 추가',
      },
      parts: {
        part: '파트',
        addPart: '프로그램 포인트 추가',
        hint: '설명',
        question: '질문',
        single: '단일 선택 가능',
        multi: '여러 옵션 사용 가능',
        freeText: '자유 텍스트 답변',
        quiz: '퀴즈',
        questionnaire: '설문지',
        addQuestionnaire: '설문지 추가',
        questionnaireType: '답변 옵션',
        option: '옵션',
        addOption: '옵션 추가',
        showMembers: '참가자',
        youJoined: '당신은 참여합니다:',
        totalToPay: '총 지불 금액:',
        yourAnswer: '당신의 답변',
        visibleForCreators: '크리에이터에게만 표시',
        allAnswers: '모든 답변:',
        addUsers: '사용자 추가',
        isAnonym: '익명 투표',
        isAnonymQuiz: '익명 퀴즈',
      },
    },
    rrEventLabels: {
      title: '반복',
      MO: '월요일',
      TU: '화요일',
      WE: '수요일',
      TH: '목요일',
      FR: '금요일',
      SA: '토요일',
      SU: '일요일',
      YEARLY: { single: '연도', regular: '매년' },
      MONTHLY: { single: '월', regular: '월간' },
      WEEKLY: { single: '주', regular: '주간' },
      DAILY: { single: '일', regular: '매일' },
      SINGLE: { single: '고유', regular: '고유' },
      CUSTOM: {
        single: '사용자 지정...',
        regular: '사용자 지정...',
      },
      freq: '빈도',
      interval: '간격',
      notRepeat: '고유',
      every: '모든',
      month: '월',
      week: '주',
      last: '마지막',
      on: 'on',
      everyMonthOn: '매월',
      ofTheMonth: '이달의',
      editLabel: '어떤 날짜를 변경하시겠습니까?',
      thisDate: '이 날짜만 해당',
      thisDateAndFollowing: '이것과 그 이후의 모든',
      allDates: '모든 날짜',
      after: '이후',
      time: '약속',
      times: '약속',
      neverEnds: '끝이 없습니다',
      until: '까지',
      repeatedDefault: ({ frequency, freq }: { frequency: string; freq: number }) =>
        `모든${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency} 반복`,
      repeatedMonthly: ({ day, interval }: { day: string; interval: number }) =>
        `모든${interval == 1 ? '' : ` ${interval}.`} 월별 ${day.length > 2 ? day : `${day}.`} 반복.`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }: { day: string; interval: number; bysetpos: number }) =>
        `Will every${bysetpos == -1 ? ' letzten' : ` ${bysetpos}.`} ${day} 의${
          interval === 1 ? '' : ` ${interval}.`
        } 월 반복.`,

      repeatedYearly: ({ interval }: { interval: number }) =>
        `각${interval == 1 ? '' : ` ${interval}.`} 매년 반복됩니다.`,
      repeatedWeekly: ({ interval, byweekday }: { interval: number; byweekday: any }) =>
        `모든${interval != 1 ? ` ${interval}.` : ''} 주${
          !!byweekday?.length
            ? ' on ' +
              byweekday?.slice(0, -1).join(', ') +
              (byweekday?.length > 1 ? ' 그리고 ' : '') +
              byweekday?.slice(-1)
            : ''
        } 반복.`,
      repeatedDaily: ({ interval }: { interval: number }) =>
        `모든${interval != 1 ? ` ${interval}.` : ''} 하루가 반복됩니다.`,
    },
    requestsLabels: {
      receiveLabel: '수신',
      sentLabel: '보낸',
    },
    post: {
      adTitle: '정보/디스플레이',
      toKreise: '에',
      comment: '댓글',
      myPost: '내 게시물',
      openPdf: '클릭하여 열기',
      deletePost: '이 게시물을 삭제하시겠습니까?',
      posted: '게시됨',
      commented: '댓글 달기',
      contacts: '연락처',
      sendToContacts: '모두에게 보내는 메시지',
      enterText: '여기에 메시지 작성',
      sendToCircle: `대상 메시지 ${KreiseName('KOR').singleArticle}`,
      sendComment: '댓글 입력',
      longMessage: '최대 20,000자',
      noComments: '아직 댓글이 없습니다',
      deleteComment: '댓글을 삭제하시겠습니까?',
      deleteEventPost: '이 게시물을 삭제하시겠습니까? 이벤트는 삭제되지 않습니다.',
      editPostLabel: '게시물 수정',
      editingPostLabel: '편집 모드',
      deletePostLabel: '게시물 삭제',
      editedAt: '처리됨',
      pinned: '고정됨.',
      actions: {
        label: '작업 게시',
        pin: {
          label: '핀 포스트',
          once: '한 번 위로 이동',
          d3: '3일',
          d5: '5일',
          w1: '1주',
          w2: '2주',
          unpin: '더 이상 고정하지 마세요.',
        },
      },
    },
    circles: {
      circlesTitle: `구독자 ${KreiseName('KOR').multi}`,
      noCirclesTitle: `구독하지 않음 ${KreiseName('KOR').multi}`,
      myPost: 'I',
      circlesInfoTitle: '정보 및 참가자',
      circlesSubscribe: '구독',
      unsubscribeAlert: '비공개 그룹 채팅을 취소할 수 없나요?',
      continueAlert: 'Verlassen',
      readOnlyKreise: `이것은 '읽기 전용'입니다.-${
        KreiseName('KOR').single
      }". 관리자만 이곳에서 글을 작성할 수 있습니다. 그러나 게시물에 댓글을 작성할 수 있습니다..`,
    },
    messages: {
      addMessageLabel: '메시지 입력',
      changesSaved: '변경 사항이 저장되었습니다!',
      newConversation: '새 대화',
    },
    profileTabs: {
      basicTab: '기본',
      clubsTab: `${CorpsName('KOR').single}`,
      miscellaneosTab: '관심 분야',
      professionTab: '직업',
      studyTab: StudyName('KOR')?.short,
      notificationsTab: '알림',
      privacy: '개인 정보 보호',
    },
    profile: {
      addPhotoLabel: '사진 추가',
      genderInputLabel: '성별',
      titleLabel: '제목',
      firstNameLabel: '이름',
      lastNameLabel: '성',

      passwordLabel: '비밀번호 설정',
      birthDateLabel: '생년월일',
      birthday: '생년월일',
      changePasswordLabel: '비밀번호 변경',
      defaultCountryLabel: '독일',
      defaultGraduationLabel: '지정되지 않음',
      defaultCorpsLabel: '없음',
      street: '거리 + 아니요. ',
      houseNumberLabel: '집 번호',
      partner: '파트너 이름',
      mobileNumberLabel: '휴대폰 번호',
      handphoneNumberLabel: '휴대폰 번호',
      telephoneNumberLabel: '전화 번호',
    },
    profileKorp: {
      verband: CorpsName('KOR').single,
      function: FunctionName('KOR'),
      dateOfJoin: CorpsEditDate('KOR'),
      beername: '맥주 이름',
    },
    profileClubs: {
      noUserLabel: '앱을 사용하려면 먼저 모든 기본 정보를 입력해야 합니다.',
      functionLabel: `${FunctionName('KOR')}`,
      setClubLabel: `이름을 입력하세요. ${CorpsName('KOR').single} 지정.`,
      matchClubLabel: '그리고',
      matchClubContinueLabel: '프로필에 이미 존재하는 경우 한 번만 확인해 주세요.',
      clubLabel: `${CorpsName('KOR').single.toUpperCase()}`,
      clubCodeLabel: `로그인 코드 ${CorpsName('KOR').single.toUpperCase()}S`,
      entryTimeLabel: CorpsEditDate('KOR'),
      deleteClubLabel: '멤버십을 연장하시겠습니까?',
      deleteClubContinueLabel: '정말 삭제할까요?',
      selectVariant: '어떻게 하시겠습니까?',
      requestExistingCorp: '기존 항목 추가',
      requestNewCorps: '새 항목 요청',
      createCorp: '새 항목 만들기',
    },
    profileProfession: {
      jobTitle: '직책',
      noUserLabel: '앱을 사용하려면 먼저 모든 기본 정보를 입력해야 합니다.',
      untilToday: '오늘까지',
      positionTitleLabel: '직업',
      itemNameLabel: '직업',
      employmentLabel: '고용 유형',
      activityLabel: ActivityName('KOR'),
      careerStageLabel: '경력 수준',
      activityDescLabel: '활동 설명',
      companyLabel: CompanyName('KOR'),
      nameLabel: '이름',
      industryLabel: IndustryName('KOR'),
      areaLabel: '범위h',
      websiteLabel: '회사 웹사이트',
      employmentPeriodLabel: '고용 기간',
      fromLabel: '에서',
      toLabel: '까지',
    },
    profileStudy: {
      noUserLabel: '앱을 사용하려면 먼저 모든 기본 정보를 입력해야 합니다.',
      universityLabel: StudyName('KOR').hochsule,
      courseLabel: StudyName('KOR').studiengang,
      degreeLabel: StudyName('KOR').abshchluss,
    },
    profileOther: {
      noUserLabel: '앱을 사용하려면 먼저 모든 기본 정보를 입력해야 합니다.',
      hobbiesLabel: '관심 분야',
      culturalLabel: '문화 스폰서',
      socialMediaPlattform: '소셜 미디어 플랫폼',
      socialMediaLabel: '소셜 미디어 링크',
      socialMediaLink: '링크',
      socialMediaError: '전체 URL을 입력하세요.',
    },
    profileNotifications: {
      noUserLabel: '앱을 사용하려면 먼저 모든 기본 정보를 입력해야 합니다.',
      notificationsInfo:
        '여기에서 개별 활동에 대한 이메일 알림을 활성화하거나(기본값) 3개월 동안 비활성화할 수 있습니다.',
      notificationsTitle: '푸시 알림:',
      notificationsDescr0: '푸시 알림을 설정할 수 있습니다. ',
      notificationsDescr1: '스마트폰 시스템 설정',
      notificationsDescr2: ' make.',
      mailingTitle: '이메일 알림:',
      mailingDescr: '여기에서 개별 활동에 대한 이메일 알림을 활성화(기본값)하거나 1년 동안 비활성화할 수 있습니다.',
      allNotificationsLabel: '모든 알림',
      postsLabel: `기여 분야 ${KreiseName('KOR').multi}`,
      messagesLabel: '비공개 메시지',
      commentsLabel: '자신의 기여에 대한 댓글',
      contactRequestsLabel: '연락처 문의',
      eventsLabel: '이벤트',
      clubMemberLabel: '신규 등록한 회원',
      enableLabel: 'To',
      disableToLabel: '다음까지 꺼짐',
      disableLabel: '에서',
      ownPosts: '내 기여(내가 직접 받은 메일도 포함)',
    },
    searchInput: {
      title: '검색',
      placeholder: '검색',
    },
    emptyList: {
      title: '여기에 콘텐츠가 없습니다(아직).',
    },
    switchLanguage: {
      placeholder: '언어/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'Russian (Русский)',
      SP: 'Spanish (Español)',
      NLD: 'Dutch (Nederlands)',
      POL: 'Polish (Polish)',
      title: '언어 선택',
    },
    filePicker: {
      takePicLabel: '첨부 파일',
      uploadPicLabel: '사진 찍기',
      selectPicLabel: '사진/비디오 선택',
      selectDocLabel: '문서 선택',
    },
    datePicker: {
      selectDateLabel: '날짜 선택',
      confirmDateLabel: '확인',
      selectTimeLabel: 'Zeit auswählen',
    },
    changePassword: {
      currentPasswordLabel: '현재 비밀번호',
      newPasswordLabel: '새 비밀번호',
      repeatPasswordLabel: '비밀번호 반복',
      changePasswordLabel: '비밀번호 변경',
    },
    appInfo: {
      appDescLabel:
        'Korponnect는 동아리 학생들을 위한 앱입니다. 기업 간 교류, 이벤트 조직, 전문 및 개인 네트워킹을 하나의 앱에서 모두 가능합니다.',
      contactsLabel: InfoContactsName('KOR'),
      infoLabel: '다음에 대한 정보',
      versionLabel: '앱 버전',
      agbLabel: 'GTC',
      dataProtectionLabel: '개인정보 보호정책',
      manualLabel: '지침',
      appTextLabel: '여기에서',
      appAndLabel: '및',
      appMainTextLabel: `동그라미를 추가하거나 다른 제안을 하시겠습니까? 프로필을 삭제하시겠습니까? ${support_email} 으로 간단한 이메일을 보내주세요.`,
      deleteFeedback: '삭제 사유/피드백 🙂:',
      deleteUserTitle: '계정 삭제?',
      deleteUserDesc:
        '여기에서 계정을 취소할 수 있습니다.\b 참고: 알림을 간단히 끌 수도 있습니다. 프로필 -> 알림에서 설정을 수정하거나 이메일을 보내주세요.',
      deleteUserAlert: '정말 계정을 삭제하시겠습니까?',
      webLabel: '웹 버전',
    },
    infoMessages: {
      registartionSuccessLabel: '등록해 주셔서 감사합니다!',
      manualRegSuccessLabel: '등록해 주셔서 감사합니다. 곧 활성화되며 이메일로 안내해 드립니다.',
      linkExpiredLabel: '링크가 만료되었습니다. 새 비밀번호 변경을 요청하세요.',
      emailExistLabel: '죄송합니다. 이메일을 사용할 수 없습니다.',
    },
    logoutAlert: {
      title: '로그아웃',
      text: '정말 로그아웃하시겠습니까? 로그아웃할 필요 없이 앱을 닫거나 최소화하기만 하면 됩니다.',
      confirm: '예',
      dismiss: '취소',
    },
    invalidInputs: {
      passwordChars: `비밀번호는 다음 중 하나 이상이어야 합니다. ${passwordDigits(
        'KOR',
      )} 문자 하나, 숫자 하나, 특수 문자 1개를 포함한 문자 수입니다.`,
      fieldRequired: '이 필드는 필수 입력 사항입니다..',
      emailsNotMatching: '이메일이 일치하는지 확인하세요.',
      emailInvalid: '이메일 주소가 올바르지 않습니다!',
      selectCorrectAnswer: 'You need to select correct answer',
      corp: '1개 이상의 항목을 선택해 주세요.',
    },
    mentoring: {
      profile: {
        rules1: '본인은',
        rules2: ' 가치에 대한 이해 ',
        rules3: '“장학금 소지자를 위한 멘토링".',
        menteeDescription:
          '멘토링 지역을 선택하면 현장 이벤트에 대한 정보를 받을 수 있습니다. 참고: 언제든지 선택 지역을 변경할 수 있습니다. 멘토 검색을 제한하지 않습니다..',
        mentorDescription:
          '장학금 수혜자가 멘토로 여러분을 찾을 수 있도록 멘토 프로필을 작성해 주세요. 참고: 최소 1개 지역과 최소 1개 주제 영역이 필요합니다.',
        mentoringRegions: '멘토링 지역',
        reigonsShort: '지역',
        topics: '주제 영역',
        departments: '부서',
        available: '사용 가능',
        unavailable: '현재 부재중입니다.',
        deleteAccount: '멘션 프로필 삭제 및 그룹 탈퇴',
        deleteAccountDescr: '멘토링 프로필을 삭제하고 멘토링 그룹 채팅에서 나가시겠습니까?',
        uploadPdf: '멘토 프로필 (PDF)',
        myPdf: '내 프로필 (PDF)',
        pdf: '멘토 프로필',
      },
      tabNames: {
        info: '정보',
        news: '뉴스',
        profile: '프로필',
        search: '검색 및 문의',
        request: '멘토링',
        requestDetails: '멘토링 문의',
        tabDeactivated: '탭이 비활성화되었습니다. 멘토링 프로필을 작성해 주세요.',
      },
      search: {
        description:
          '타겟팅된 방식으로 검색을 시작하려면 몇 가지 선택 필드로 시작한 다음 새 검색 실행에서 검색 그리드를 넓히는 것이 좋습니다. 해당 지역에서 적합한 멘토를 찾을 수 없다면 인근 멘토링 지역으로 검색 범위를 확장하세요. 검색 기준: 지역 1개, 주제 분야 1~3개. 모든 필드는 AND로 연결됩니다!',
        searchBtn: '검색',
      },
      requests: {
        mentorIncoming: '멘토링 문의',
        mentorCurrent: '내 멘토',
        menteeOutgoing: '멘토링 문의',
        menteeCurrent: '나의 멘토링',
        cancelMentoring: '멘토링 종료',
        sendRequest: '멘토링 종료',
        alreadyHaveMentor: '멘토는 한 명만 가질 수 있습니다.',
        requestSuccess: '문의가 성공적으로 전송되었습니다.',
        requestRetracted: '문의가 성공적으로 철회되었습니다.',
        cancelSendingRequest: '멘토링 요청 철회하기',
        approveRequest: '문의 수락',
        rejectRequest: '요청 거부',
        invitaionText: '문의',
        rejectionText: '거부 사유 제공',
        sureRetractRequest: '정말 문의를 철회하시겠습니까?',
      },
    },
    downloads: {
      root: '기본 디렉토리',
      delete: '삭제',
      rename: '이름 바꾸기',
      areYouSureDeleteFolder: '정말 폴더를 삭제하시겠습니까? 모든 콘텐츠가 삭제됩니다!',
      renameFolder: '폴더 이름 바꾸기',
      createNewFolder: '새 폴더 만들기',
      newFolderPlaceholder: '폴더 이름 입력',
      create: '만들기',
      areYouSureDeleteFile: '',
      downloadFile: '다운로드',
      deleteFile: '기기에서 삭제',
      openInFinder: '파일로 열기',
      cancel: '취소',
    },
    globalSearch: {
      postedBy: '게시자',
      found: '발견됨',
      loadMore: '더 보기',
      enterQuery: '검색어를 입력합니다. 이름, 문서, 이벤트, 게시물 등 무엇이든 검색할 수 있습니다.',
      folder: '폴더',
      post: '기여',
      error: '3자 이상 입력',
    },
    kreiseSettings: {
      description: '설명',
      push: '푸시 알림',
      email: '메일 알림',
      subscribers: '회원',
      active: '활성화',
      oneYear: '1년간 침묵',
      forever: '영원히 침묵',
      until: '까지',
      cantDeactive: '이 그룹 채팅에 대해서는 이 설정이 비활성화되어 있습니다.',
      SINGLE: '각 기여에 대해',
      SUMMARY_ONCE: '주당 1회 요약',
      SUMMARY_TWICE: '주당 2회 요약',
      SUMMARY_DAY: '하루 1회 요약',
      OFF: '에서',
      PUSH: '푸시 알림',
      EMAIL: '메일 알림',
      PUSH_AND_EMAIL: '푸시 및 이메일 알림',
      newUser: '신규 사용자를 위한 알림',
      statistic: '전송 통계',
      off: '에서',
      ownPosts: '기여에 대한 통계 메일 보내기',
      deleteMemberDescr: '제거하시겠습니까?',
      deleteMemberBtn: '제거',
      retractAdminRights: '권한 철회',
      deleteAdminAndUser: '완전히 제거',
      toDeleteKreiseBtn: '그룹 채팅 삭제',
      deleteKreiseDescr: `${KreiseName('KOR').single} 취소할 수 없게 삭제할 수 있나요?`,
      deleteKreiseModalTitle: '회원을 제거하시겠습니까?',
      addKreiseName: '이름',
      addKreiseDescription: '이름',
      addMembers: '멤버 추가',
      createKreise: '그룹 채팅 만들기',
      toKreiseEditMessage: '참고: 멤버에 대한 모든 변경 사항은 즉시 적용됩니다.',
      kreiseCreator: '크리에이터:이 그룹 채팅에서',
      kreiseDeleteMembersLeft: '그룹 채팅은 멤버가 한 명만 남은 경우에만 삭제할 수 있습니다.',
      kreiseDeleteMembersLeftTitle: '먼저 멤버 제거',
      suggestionInNewsfeed: {
        admin: '관리자',
        member: '회원',
        corp: `${CorpsName('KOR').single} 멤버`,
        corpAdmin: `${CorpsName('KOR').single} 관리자`,
      },
    },
    surveys: {
      checkVotes: '결과 표시',
      chooseOptions: {
        single: '옵션 선택.',
        multiple: '하나 이상의 옵션을 선택합니다.',
      },
      votesModalTitle: '결과',
      createModalTitle: '설문 조사',
      noVotes: '투표가 진행되지 않았습니다.',
      question: '질문',
      askAQuestion: '질문하기',
      allowMultiple: '복수 답변 허용',
      votes: {
        single: '음성',
        multiple: '음성',
      },
      option: '옵션',
      optionsLabel: '옵션',
      anonymus: '익명 설문 조사',
      create: '만들기',
    },
    subscription: {
      subscription: 'Abonnements',
      pricePerMonth: 'EUR/Monat',
      month: 'Monat',
      day: 'Tag',
      otherPlans: 'Andere Tarife',
      subscriptionActive: 'Abonnement aktiv',
      until: 'bis',
      subscribe: 'Abonnieren',
      successfulPayment: {
        title: 'Zahlung erfolgreich',
        text: 'Dein Abonnement wurde erfolgreich abgeschlossen. Du kannst ab sofort die erweiterten Funktionen nutzen.',
      },
      cancel: {
        cancel: 'Abonnement kündigen',
        title: 'Abonnement kündigen?',
        text: 'Möchtest du dein Abonnement kündigen? Nach Ablauf der bezahlten Periode werden deine Zugriffsrechte zurückgestuft.',
      },
    },
  },
  {
    key: 'JAP', //japan language /japanisch
    key2: 'ja',
    editProfile: {
      updated: 'プロフィールが更新されました。',
      validateFieldsViaEmail: '以下のデータ項目をメールで確認してください。確認後、中央メンバー管理に送信されます。',
      newValue: (value: any) => `新しい値: "${value}"`,
      resendEmail: 'それとも、確認メールを再送信しますか？',
      validationPending: '確認待ち',
      resend: '再送信',
      resendDone: '確認メールが再送信されました。数分お待ちいただき、迷惑メールフォルダもご確認ください。',
      resendLong: 'それとも、検証メールを再送信しますか？',
    },
    appInfoScreen: {
      title: 'アプリについて',
      privacy: 'プライバシー情報',
      find: 'ここで以下を確認できます',
      terms: '利用規約',
      and: 'と',
      infoSection: 'アプリ情報',
      hasUpdate: '新しいバージョンがあります。ここをクリックして更新してください。',
      noUpdate: 'これは最新バージョンです。',
      appVerison: 'アプリバージョン:',
    },
    corpsScreen: {
      website: 'ウェブサイト:',
      email: 'メールアドレス:',
      members: 'メンバー',
      admins: '管理者',
      info: '情報',
      searchMember: 'メンバーを検索',
    },
    contactsScreen: {
      empty:
        'ここにはまだエントリーがありません。メインメニューに移動し、「検索」→「名前」を選択して新しい連絡先を探してください。',
    },
    dialogPanel: {
      hangOn: 'Bierjungeを添付しますか？',
      ok: 'OK',
    },
    dialogs: {
      emptyTitle: '右上のプラスをクリックして会話を開始してください。',
    },
    documentsGallery: {
      modalTitle: 'ドキュメントを削除',
      modalDesc: 'このファイルを削除しますか？',
      cancel: 'キャンセル',
      delete: '削除',
      deleteConfirm: 'ダウンロードしたファイルを削除しますか（自分のみ）？',
      downloadConfirm: 'このファイルをダウンロードしますか？',
      availableInDownloads: `\n\n*ダウンロードしたすべてのファイルは「ダウンロード」フォルダにあります`,
    },
    drawerScreen: {
      backdrop: 'アプリを使用する前に、まず基本情報を完了させる必要があります。',
    },
    kreiseInfoScreen: {
      creator: 'このグループチャットの作成者',
      revokeAdmin: (name: string) => `"${name}"の管理者権限を削除しますか？それともグループから完全に削除しますか？`,
    },
    kreisePostScreen: {
      infoParticipants: (num: string | number) => `情報と参加者 (${num})`,
      subscribeTitle: 'グループチャットに参加しますか？',
      subscribeDesc: 'このグループチャットに参加して、最新のニュースを見逃さないようにしますか？',
      yes: 'はい',
      later: '後で',
      dontAsk: 'このグループチャットにはもう聞かない',
    },
    mapScreen: {
      appUser: 'アプリユーザー',
      viewIsForMember: 'ユーザープロフィールの閲覧はメンバーのみ利用可能です。',
      all: 'すべて',
    },
    mentoringScreen: {
      clickMentor: 'メンターの名前をクリックして、リマインダーを送信したりリクエストを取り下げたりできます',
      all: 'すべて',
      startRequest: 'リクエスト開始:',
      startDesc:
        'メンタリングのリクエスト内容をできるだけ明確に簡潔に説明してください。これにより、メンターはリクエストをより良く評価し、迅速に返答できます。',
      notice: '注意:',
      noticeDesc:
        'リマインダーを送信する前に、メンターからの返答には最大2週間かかることを許可してください。別のメンターに連絡する前に、現在のリクエストを取り下げてください。',
      withdrawRequest: 'リクエストを取り下げる',
      reminder: 'リマインダーを送信',
      reminderSuccess: 'リマインダーが送信されています (+ コピーが届きます)',
      rejectRequest: 'リクエストを拒否',
      requestTitle: 'メンタリングリクエスト',
      areas: '専門分野',
      topics: 'トピック',
      withReguards: (name: string, lastname: string) => `\n\n\n(...)\n\nよろしくお願いします,\n\n${name} ${lastname}`,
      rejectionMessage: (name: string, lastname: string) =>
        `こんにちは！\n\n残念ながら、メンタリングリクエストをお断りしなければなりません。理由を説明しますので、聞いていただければと思います。\n\nよろしくお願いします,\n\n${name} ${lastname}`,
      maxMessage: (max: number | string, topic: string) => `${max}個まで${topic}を選択できます。`,
      decisionOpen: '決定: 開いている',
      maxMentoring: '最大1つのメンタリングリクエストが許可されています',
      maxParalelMentoring: '最大1つの並行メンタリングリクエストが許可されています',
      openHere: 'こちらを開く',
      profileUpdated: 'プロフィールが更新されました！',
      mentoringProfile: 'メンタリングプロフィール',
      mentee: 'メンティ',
      mentor: 'メンター',
      leaveMentoring: 'メンタリンググループを離れる',
      mentoringTitle: 'メンタリング',
      scrollAuto: '自動スクロールタブの例',
      stopMentoringMessageMentor: (name: string, lastname: string) =>
        `${name} ${lastname}とのメンタリングを終了しますか？`,
      stopMentoringMessageMentee: (name: string, lastname: string) =>
        `${name} ${lastname}とのメンタリングを終了しますか？`,
      mentorEndedMentorship: (name: string, lastname: string) =>
        `メンター${name} ${lastname}がメンタリングを終了しました。`,
      mentorRejectedMentorship: (name: string, lastname: string) =>
        `メンター${name} ${lastname}があなたのリクエストを拒否しました。`,
      menteeEndedMentorship: (name: string, lastname: string) =>
        `メンティー ${name} ${lastname} がメンタリングを終了しました。`,
      menteeRejectedMentorship: (name: string, lastname: string) =>
        `メンティー ${name} ${lastname} があなたのリクエストを拒否しました。`,
      mentorat: 'メンタリング',
    },
    newEventScreen: {
      kreiseError: '対象グループを選択してください',
      correctDate: '日付/時間を修正',
      correctDateMessage: '終了日時が開始日時より前に設定されています。修正してください。',
    },
    outRequestScreen: {
      backButton: '連絡先リクエスト',
      requestMessage: (name: string, lastname: string) =>
        `こんにちは！\n\nぜひ、あなたを連絡先に追加したいです。\n\nよろしくお願いいたします。\n\n${name} ${lastname}`,
    },
    participantsScreen: {
      interestedParties: '関心のある人々',
      removeGuestTitle: (name: string) => `"${name}"を削除しますか？`,
      removeGuestDesc: (name: string) => `"${name}"を参加者リストから削除しますか？`,
      informUser: (name: string) => `"${name}"に参加者リストから削除されたことを通知します`,
    },
    partParticipantsScreen: {
      interestedParties: '興味のある当事者',
      removeParticipantTitle: (name: string, lastname: string) =>
        `参加者 "${name} ${lastname}" を参加者リストから削除しますか？`,
      removeParticipantDesc: (name: string, lastname: string) => `"${name} ${lastname}" を削除しますか？`,
    },
    documentsScreen: {
      deleteTitle: '選択されたファイル:',
      cancel: 'キャンセル',
      uploadData: 'データをアップロード',
    },
    postsScreen: {
      ads: '広告...',
      oldPostsForMembers: '古い投稿はメンバーのみが表示できます。',
    },
    profileBasisScreen: {
      editPhoto: '写真を編集',
    },
    profileBenachrichtigungenScreen: {
      fillData: 'アプリを使用する前に基本情報をすべて入力する必要があります。',
      changesInChat: 'これらの設定は、それぞれのグループチャットで行えます。',
      commentsWarning1:
        '自分の投稿へのコメントのメール通知を無効にすることはお勧めしません。重要なコメントを見逃す可能性があるためです。',
      commentsWarning2:
        'プライベートメッセージのメール通知を無効にすることはお勧めしません。重要なメッセージを見逃す可能性があるためです。',
      messagesWarning1:
        'プライベートメッセージのメール通知を無効にすることはお勧めしません。通常、グループチャットの投稿に比べてプライベートメッセージは少ないですが、それらは通常、特定のリクエストが含まれているため重要です。このような（重要な）プライベートメッセージを見逃す可能性があります。',
      messagesWarning2:
        '自分の投稿へのコメントのメール通知を無効にすることはお勧めしません。重要なコメントを見逃す可能性があるためです。',
      areYouSure: '本当に実行しますか？',
      turnOff: 'オフにする',
      maybeLater: '後で',
      notRecommended: '推奨しない設定',
      ok: 'OK',
    },
    profileBerufScreen: {
      fillData: 'アプリを使用する前に基本情報をすべて入力する必要があります。',
      defaultDate: '今日まで',
      noArea: 'エリアなし',
      furtherActivity: '追加の活動',
      deleteConfirm: '本当にエントリを削除しますか？',
      ok: 'OK',
    },
    profileCorpsScreen: {
      corpsname: 'プロジェクト名',
      createHint: '新しいエントリが作成されています。',
      requestHint: '新しいエントリの作成が要求されています。',
      status: 'ステータス',
      deleteConfirm: (name: string) => `${name} のメンバーシップを本当に削除しますか？`,
      ok: 'OK',
      fillData: 'アプリを使用する前に基本情報をすべて入力する必要があります。',
      duplicatedCorps: (name: string) => `${name} はすでにプロファイルにあります。確認してください。`,
      missingCorpName: 'コープ名を提供してください。',
      waitingActivation: '承認待ち',
      requestExistingMessage: (name: string) =>
        `"${name}" へのメンバーシップをリクエストしました。承認され次第、通知されます。`,
      requestNewMessage: (name: string) => `"${name}" の作成をリクエストしました。承認され次第、通知されます。`,
      createMessage: (name: string) => `"${name}" が作成され、プロフィールに追加されました。`,
    },
    profileSonstigenScreen: {
      other: 'その他（入力してください）',
    },
    profileDetailsScreen: {
      email: 'Eメール',
    },
    profilePrivacyScreen: {
      fillData: 'アプリを使用する前に基本情報をすべて入力する必要があります。',
    },
    profileStudiumScreen: {
      deleteConfirm: '本当にエントリを削除しますか？',
      ok: 'OK',
      fillData: 'アプリを使用する前に基本情報をすべて入力する必要があります。',
    },
    reportScreen: {
      reportUser: 'ユーザーを報告',
      notMember: 'そのユーザーはもはやメンバーではありません',
      fakeProfile: 'そのユーザーは偽のプロファイルを提供しています。',
      violations1: 'そのユーザーのプロフィール写真はガイドラインに違反しています。',
      violations2: 'そのユーザーの発言はガイドラインに違反しています。',
      violations3: 'そのユーザーはチャットでスパムを送信しています。',
      violations4: '潜在的に有害な内容を報告/マーク',
      violations5: '他のユーザーの可能性のある違反を報告/マーク',
      reportReason: '報告理由',
      sendReport: '送信',
      cancel: 'キャンセル',
      showContent: 'このユーザーのコンテンツを表示',
      hideContent: 'このユーザーのコンテンツを非表示',
    },
    resetPasswordScreen: {
      incorrectEmail: 'あなたのメールアドレスは正しくありません。',
    },
    serverIsDownScreen: {
      serverMaintenance: 'サーバーメンテナンス中',
    },
    signInScreen: {
      dontHaveAccount: 'アカウントをお持ちでないですか？',
    },
    signUpScreen: {
      login: 'ログイン',
      emailMatchError: 'メールアドレスと確認用メールアドレスが一致しません。',
      passwordMatchError: 'パスワードと確認用パスワードが一致しません。',
      incorrectEmail: 'メールアドレスが正しくありません！',
      pleaseSelect: '選択してください',
      accountExistsTitle: 'アカウントが存在します',
      accountExistsDesc: 'このメールアドレスで既にアカウントがあります。',
      toLogin: 'ログインするには',
    },
    subscriptionsScreen: {
      subscribed: '購読済み:',
      noSubscribed: '購読中の団体が見つかりません',
      otherSubscriptions: '他の購読中の主催者:',
      noOtherSubscriptions: `購読中の主催者が見つかりません`,
    },
    sucheScreen: {
      searchFor: '検索結果:',
      filter: 'フィルター:',
      name: '名前',
      branch: '部門',
      function: '機能',
      status: 'ステータス',
      city: '都市',
      industry: '業界',
      business: 'ビジネス',
      profession: '職業',
      training: '訓練',
      studyProgram: '学習プログラム',
      college: '大学',
      cancel: 'キャンセル',
      searchBar: '検索バー',
      searchTab: '検索タブ',
      searchTitle: '検索',
      scrollable: 'スクロール可能な自動タブ例',
    },
    dialogComponent: {
      close: '閉じる',
    },
    phoneInputComponent: {
      noCountry: '国が見つかりません。',
    },
    cropImageHandlerComponent: {
      cancel: 'キャンセル',
      crop: 'トリミング',
    },
    datePickerComponent: {
      cancel: 'キャンセル',
      ok: 'OK',
      date: '日付/時間',
      day: '日',
      month: '月',
      year: '年',
      wrongDate: '無効な日付',
    },
    timePickerComponent: {
      hours: '時間',
      minutes: '分',
    },
    responseHandlerComponet: {
      supportEmail: `不明なエラー。${support_email}に連絡してください。`,
    },
    searchModalComponent: {
      maximumSelection: (num: string | number) => `最大で${num}件まで選択してください！`,
      selectionError: '少なくとも1つのエントリを選択してください。',
    },
    customDropzoneComponent: {
      dropFiles: 'ここにファイルをドロップ',
      clickHere: ' またはここをクリックして選択 ',
    },
    documentsGalleryComponent: {
      clickToOpen: 'クリックして開く',
      clickToDownload: 'クリックしてダウンロード',
    },
    eventToolbarComponent: {
      noRegistration: '登録なし！',
      notLoggedId: 'まだログインしていません。これはあくまで興味の表明です。',
      registerVia: '登録は以下の方法で行います：',
    },
    likeComponent: {
      externalGuest: '外部ゲスト',
      invitedBy: (name: string) => `${name}に招待されました`,
    },
    membersComponent: {
      interested: '関心あり',
      loggedIn: 'ログイン済み',
      um: ' で ',
      invitedBy: (name: string) => `${name}に招待されました`,
    },
    multipleSearchableInputComponent: {
      maximalMembers: `最大で${maximal_membership_count}のメンバーシップを指定してください。`,
    },
    multipleSelectModalComponent: {
      all: 'すべて',
    },
    offlineModalComponent: {
      checkInternet: 'インターネット接続を確認してください。',
    },
    postsToolbarComponent: {
      membersOnly: 'メンバーのみが投稿できます。',
    },
    zusagenModalComponent: {
      incorrectEmail: 'あなたのメールは正しくありません',
      required: 'このフィールドは必須です。',
      name: '名前',
      email: 'Eメール（任意）',
      invitedBy: '招待者',
      save: '保存',
      cancel: 'キャンセル',
      warning1:
        '注意: ここには${guest_naming_singular}としてのみ登録してください！\nアプリにアカウントがある場合は、それでログインし、正式にメンバーとして確認してください。ありがとうございます！',
      warning2: '登録することで、あなたは ',
      and: ' と ',
      to: ' に同意します。',
    },
    formInputComponent: {
      cancelConfirmation: '確認をキャンセルしますか？',
    },
    logoComponent: {
      alt: 'アプリロゴ',
    },
    customHistoryHook: {
      news: 'メッセージ',
    },
    postContentComponent: {
      orginized: '主催者:',
      date: '日付:',
      ort: '場所:',
    },
    beerSmileComponent: {
      bierjunge: 'ビアジャンゲ！',
    },
    buttonComponent: {
      examination: '審査:',
    },
    global: {
      all: 'すべて',
      postSubject: '投稿の件名',
      showMore: 'もっと読む',
      toRegister: '登録へ',
      addToCalendar: 'iCal / ICS',
      interested: '興味あり',
      loggedIn: 'ログイン済み',
      tilToday: '今日まで',
      status: 'ステータス',
      deletePost: '投稿を削除しますか？',
      pdf: '(PDF)',
      time: '時間',
      birthday: (age: number | string, name: string, lastname: string) => `${age}歳の${name} ${lastname}の誕生日`,
      eur: 'EUR',
      price: '価格：',
      priceForMe: '私のための価格:',
      ok: 'OK',
      likesCount: (num: string | number) => `いいね (${num})`,
      event: 'イベント',
      standardMessage: 'あなたの投稿の件名を生成しています。この件名は合っていますか？',
      optionalMessage: '投稿は何についてですか？ 短い件名を書いてください。AIに提案を生成させることもできます。',
      optionalMessageNote: 'この機能を使用することで、あなたは',
      chatGPTTerms: 'ChatGPT利用規約',
      chatGPTTermsLink: 'https://openai.com/policies/terms-of-use',
      optionalMessageNoteEnding: 'に同意したことになります。',
      postSubjectTitle: '投稿の件名',
      generate: '生成する',
      defaultSubject: 'デフォルトの件名',
      memebership: 'メンバーシップ',
      applyMemebership: 'メンバーシップを申請しますか？',
      pendingMembership: 'メンバーシップ申請が送信されました - 決定待ち。',
      requestAlreadySent: 'リクエストは既に送信されています',
      requestedMemebership: (kreiseName: string) => `"${kreiseName}"のメンバーシップが申請されました。`,
      yes: 'はい',
      no: 'いいえ',
      change: '変更',
      passwordOldMatchNewError: '古いパスワードと新しいパスワードが一致します',
      passwordLenghtError: 'パスワードは6文字以上である必要があります。',
      passwordsMatchError: '古いパスワードと新しいパスワードが一致します',
      passwordsMatchError2: 'パスワードと確認が一致しません。',
      currentPassword: '現在のパスワード',
      newPassword: '新しいパスワード',
      confirmPassword: 'パスワードを確認',
      changePassword: 'パスワードを変更',
      cancel: 'キャンセル',
      fits: '合っています',
      selectChatTitle: 'グループチャットを選択',
      selectChatDesc:
        '投稿を送信するために、少なくとも1つのお気に入りのチャットを選択してください。その他のチャットは、メインメニューの「グループチャット」から確認できます。',
      socialMedia: 'ソーシャルメディア',
      generatedText: 'ソーシャルメディア用のテキストを生成しています。このテキストは合っていますか？',
      usePhoto: '投稿ありがとうございます！あなたの写真をソーシャルメディアチャンネルで使用してもよろしいですか？',
      maximumLenght: '最大20,000文字。',
      userSharedNewPicture: 'アプリのユーザーが新しい写真を共有しました！',
      contributionTo: (kreiseName: string) => `"${kreiseName || '全員'}"への投稿`,
      postWarning1:
        '頑張ってください！次のステップで自分のテキストを追加する機会があります。また、あなたの投稿は再度確認されます。',
      postWarning2: '送信後、投稿は管理者によって確認されます。質問があれば、遠慮なく以下にご連絡ください。',
      postWarning3: 'または',
      AGB: '利用規約',
      DSE: 'プライバシーポリシー',
      withCheckbox: "'パスワードを忘れた'機能を使ってアカウントを最初に有効化することによって、私はここに同意します。",
      withoutCheckbox: "'パスワードを忘れた'機能を使ってアカウントを最初に有効化することによって、あなたは同意します。",
      tou: '利用規約',
      dse: 'プライバシーポリシー',
      impressum: '法的通知',
    },
    responseHandler: {
      messageLabel: `不明なエラー。${support_email} までご連絡ください。`,
      expiredLinkLabel: 'リンク切れです。新しいパスワードの変更をリクエストする.',
      emailExistLabel: 'このメールアドレスはすでに登録されています。.',
      profileUpdated: 'プロフィール更新',
      profileUpdatedCorpsError: `プロフィールを更新しました！以下 ${
        CorpsName('JAP').single
      } ログインコードが正しくないため、追加できませんでした：`,
      appNeedsUpdate: 'App Store / Google Playでアプリをアップデートしてください。',
      maximalVideoSize: '最大ファイルサイズは2GB！',
    },
    report: {
      notBelongLabel: 'ユーザーはメンバーではなくなりました。',
      incProfileLabel: '虚偽のプロフィールを提示',
      badPicLabel: 'プロフィール写真がガイドラインに違反している。',
      badComLabel: 'ユーザーの発言がガイドラインに違反している',
      spamLabel: 'ユーザーがチャットでスパムを送信',
      reasonsLabel: '通告の理由',
    },
    drawer: {
      subscription: 'Subscription',
      news: `最新ニュース`,
      kreise: KreiseName('JAP').multi,
      search: '検索',
      calendar: CalendarName('JAP'),
      documents: `${DocumentsName('JAP')}`,
      contacts: 'コンタクト',
      requests: 'お問い合わせ',
      messages: MessagesName('JAP'),
      profile: ProfileName('JAP'),
      logout: 'ログアウト',
      map: '地図',
      mentoring: 'メンタリング',
    },
    imageCropper: {
      cropLabel: '作物',
      cancelLabel: 'キャンセル',
      chooseLabel: '終了',
    },
    signIn: {
      register: '登録',
      title: 'ログインしてください',
      loginPlaceholder: 'Eメールアドレス',
      passwordPlaceholder: 'パスワード',
      forgotPassword: 'パスワードをお忘れですか？',
      loginBtn: 'Login',
      noAccount: 'アカウントはまだ？',
      description0: 'ご不明な点がございましたら、下記までお問い合わせください。',
      description1: 'または',
      description2: 'ターン。',
      loginValidation: 'Eメールアドレスが正しくありません。',
      passwordValidation: 'パスワードは6文字以上でなければなりません。',
    },
    search: {
      name: '名称',
      corps: CorpsName('JAP').single,
      country: CountryName('JAP'),
      city: '都市',
      industry: IndustryName('JAP'),
      company: CompanyName('JAP'),
      profession: '職業',
      hobby: '趣味',
      cultural: 'Kulturpaten',
      study: `${StudyName('JAP').short}`,
      office: '支店',
      degreeProgram: StudyName('JAP').studiengang,
      university: StudyName('JAP').university,
      position: `${FunctionName('JAP')}`,
      status: 'ステータス',
      title: '検索結果',
      filterTitle: 'フィルター',
      postCode: '郵便番号',
      global: 'すべて',
      activity: ActivityName('JAP'),
    },
    resetPassword: {
      enterEmail: 'Eメールアドレスを入力してください。',
      inputPlaceholder: 'Eメールアドレス',
      sendBtn: '送信',
      cancelBtn: 'キャンセル',
      message: 'メールアドレスがわかっている場合は、リンクが送信されている。',
      desc: 'アプリに保存されているメールアドレスを正確に入力してください。それがどれなのか、メールをチェックしてください。',
    },
    password: {
      resetLabel: '新しいパスワードを入力してください。',
      inputLabel: '新しいパスワード',
    },
    signUp: {
      createAccount: 'アカウント作成',
      inputPlaceholder: `ログインコード ${CorpsName('JAP').single}s`,
      sendBtn: 'さらに',
      descFirst: '登録することで、利用規約に同意したものとみなされます。 ',
      descSecond: 'をお読みいただき',
      descThird: `ご質問がございましたら、${support_email} または +49 170 49 805 までお気軽にお問い合わせください。`,
      terms: '諸条件',
      policy: 'プライバシーポリシー.',
      description: 'ヒント',
      generatePassword: 'パスワードの生成',
      noCodeSuccessRegister: 'ご登録ありがとうございます！登録が有効になり次第、Eメールにてお知らせいたします。',
    },
    signUpDefault: {
      withoutCode: 'コードなし',
      createAccount: '登録はこちらから',
      withCode: '招待コード付き',
      simpleRegister: '招待コードなし',
      existingAccount: 'すでにアカウントをお持ちですか？',
      descr01: 'に同意する。',
      descr0: '登録することにより、以下の事項に同意したものとみなされます。',
      descr1: 'そして',
      descr2: 'に。',
      guest: `登録 ${guest_naming_singular}`,
      withCodeWithDescr: `Rとして登録する。 ${member_naming_singular}`,
    },
    signUpManual: {
      title: '登録',
      photoDesc: '写真を追加',
      genderInput: '性別',
      firstName: '名前',
      lastName: '苗字',
      club: CorpsName('JAP').single,
      specify: '指定してください',
      emailPlaceholder: 'Eメールアドレス',
      emailHint: 'メールアドレスは長期的に使用できるものをご使用ください。',
      repeatEmailPlaceholder: 'リピートEメールアドレス',
      passwordPlaceholder: 'パスワード設定',
      repeatPasswordPlaceholder: 'リピートパスワード',
      postcode: '郵便番号',
      place: '居住地',
      country: '国名',
      loginCode: '招待コード',
      mobilePlaceholder: '携帯番号（プロフィールでは表示されません）',
      corps: SignUpCorpsName('JAP'),
      searchCountry: '国を検索',
    },
    backToLogin: {
      desc: 'すでにアカウントをお持ちですか？',
      login: 'ログイン ',
    },
    backToSignUp: {
      desc: 'アカウントはまだ？ ',
      register: '登録',
    },
    privacy: {
      desc: 'はい、同意します。',
      btn: 'GTC',
      zu: 'への',
      map: {
        title: 'プライバシー通知',
        privacyNotice: `この地図はGDPRに準拠して作成されています。`,
        additionalInfo: {
          intro: '地図上のピンは',
          inaccurateDisplay: '郵便番号/市町村に基づいて不正確に表示されているだけで',
          locationBasis: ', 通り/家屋番号はありません。',
          noConclusion: 'このため、利用者の特定の住所に関する',
          onAddresses: '結論を導き出すことはできません',
          conclusion: '.',
        },
        agree: '理解しました',
        linkName: 'プライバシーポリシー',
        options: {
          doNotShowAgain: '再表示しない',
          doNotShowMeOnMap: '地図に表示しない (大まかにでも)',
        },
      },
      restrictions: {
        overall: 'この機能は会員のみが利用できる。',
        overallWilkar: 'データ保護のため、この機能にはアクセスできません。.',
        profile:
          'ユーザーのプロフィールを閲覧できるのはメンバーのみです。ただし、そのユーザーにプライベートメッセージを書き込むことはできます。',
        groupchat: `について ${KreiseName('JAP').single} 会員のみアクセス可能.`,
        groupchatWilkar: `データ保護の観点から ${KreiseName('JAP').single} にアクセスできるのは会員のみである。`,
        comments: 'コメントは会員のみ閲覧可能.',
        commentsWilkar: 'データ保護の観点から、コメントは会員のみが閲覧できます。',
        likes: 'いいね！」のリストは会員のみが閲覧できます。',
        post: '記事を投稿できるのは会員のみです。',
        participantsList: '参加者リストは会員のみ閲覧可能。',
        commentsOnlyForMembers: 'コメントは会員のみに表示されます。',
        postsLimitation: '古い投稿は会員のみ閲覧可能です。',
      },
      settings: {
        guestPost: {
          title: `V洗練された ${guest_naming_plural} 私の投稿を見ることができる`,
          ask: '常に尋ねる',
          no: 'いいえ',
          yes: 'はい',
        },
      },
      guestModal: {
        title: `5月有効 ${guest_naming_plural} この投稿が見えますか？この表示設定は、後で個々の投稿ごとに変更することができます。`,
        dontAskAgain: '再度質問しない（この設定は「プロフィール」→「プライバシー」で再度変更することもできます）.',
      },
      post: {
        ifShown: `について ${guest_naming_plural} 隠す`,
        ifHidden: `について ${guest_naming_plural} ショー`,
        ifShownModal: `この寄付は現在、以下の目的で利用可能である。 ${guest_naming_plural} もう見えない。`,
        ifHiddenModal: `この寄付は現在、以下の目的で利用可能である ${guest_naming_plural} 目に見える.`,
      },
    },
    searchModal: {
      landTitle: '国を選ぶ',
      corpsTitle: `${CorpsName('JAP').single} 選択`,
      pleaseSelect: '選択してください',
    },
    btn: {
      sendBtn: '送信',
      cancelBtn: 'キャンセル',
      select: '選択',
      cancelEventBtn: acceptEventName('JAP').cancel,
      acceptBtn: acceptEventName('JAP').accept,
      saveBtn: 'セーブ',
      clearBtn: '削除',
      acceptReqBtn: '受け入れる',
      declineReqBtn: 'キャンセル',
      updateBtn: 'リフレッシュ',
      declineBtn: '却下',
      yesUnderstood: 'はい、理解しました',
      ok: 'OK',
    },
    validation: {
      email: 'Eメールアドレスが正しくありません。',
      password: 'パスワードは6文字以上でなければなりません。',
      repeatEmail: 'EメールとEメールの繰り返しは一致しない。',
      repeatPassword: 'パスワードとパスワードの繰り返しが一致しない',
      required: 'このフィールドは必須です',
      matchPasswords: '新旧パスワードの一致',
      dontMatchPasswords: 'パスワードとパスワードの繰り返しが一致しない。',
    },
    init: {
      title: 'ローディング',
      overlayLabel: 'インターネット接続をご確認ください',
    },
    screenTitles: {
      postsTitle: `最新ニュース`,
      circlesTitle: KreiseName('JAP').multi,
      searchTitle: '検索',
      profileTitle: ProfileName('JAP'),
      eventTitle: CalendarName('JAP'),
      eventDetailsTitle: 'イベント',
      newEventTitle: 'イベント追加',
      contactsTitle: '連絡先',
      contactReqTitle: '連絡先',
      reqDetailsTitle: '連絡先',
      outReqTitle: '連絡先',
      messagesTitle: MessagesName('JAP'),
      changePasswordTitle: 'パスワードの変更',
      appInfoTitle: 'について',
      reportTitle: '報告ユーザー',
      createKreiseTitle: 'グループチャットの作成',
      eventParticipants: '参加者',
    },
    profileDetails: {
      showFilters: 'フィルターを表示',
      user: 'ユーザー',
      mainTitleLabel: ProfileName('JAP'),
      basisLabel: 'ベース',
      clubLabel: CorpsName('JAP').single,
      clubLabelPlural: CorpsName('JAP').multi,
      professionLabel: '職業',
      studyLabel: StudyName('JAP').short,
      uploadPhotoLabel: '写真を撮る',
      editPhotoLabel: '写真を編集する',
      profileCompletedLabel: 'プロフィール',
      profileCompletedGLabel: '完全',
      birthday: '生年月日',
      functionLabel: `${FunctionName('JAP')}`,
      dateOfJoinLabel: CorpsEditDate('JAP'),
      positionDescLabel: '職業',
      companyLabel: CompanyName('JAP'),
      nameLabel: '名称',
      websiteLabel: 'ウェブサイト',
      fromLabel: 'より',
      toLabel: 'まで',
      delete: '削除',
      untilToday: '今日まで',
      degreeLabel: StudyName('JAP').abshchluss,
      miscellaneos: 'その他',
      hobbies: '趣味',
      cultural: '文化スポンサー',

      positionLabel: '職業',
      firmaLabel: '会社概要',
      industryLabel: IndustryName('JAP'),
      areaLabel: 'レンジ',
      typeOfEmplLabel: '雇用形態',
      activityLabel: ActivityName('JAP'),
      careerStageLabel: 'キャリアレベル',
      activityDescLabel: '活動内容',
      employmentPeriodLabel: '期間',
      universityLabel: StudyName('JAP').hochsule,
      courseLabel: StudyName('JAP').studiengang,
      ageLabel: '年齢',
      birthdayLabel: '誕生日',
      editProfileLabel: 'プロフィール編集',
      addContactLabel: '連絡先として追加',
      sendMessageLabel: 'メッセージを送る',
      requestSentLabel: 'コンタクトリクエストは送信済み',
      sendRequestSuccessLabel: 'お問い合わせは正常に送信されました',
      reportSentLabel: 'すでに報告済みのユーザー',
      addReportLabel: 'ユーザーへの報告/ブロック',
      contactsLabel: 'コンタクト数',
      showBirthday: 'コンタクトは私の誕生日を見る',
      street: 'ストリート＋No. ',
      houseLabel: '家屋番号',
      houseNumberLabel: '家屋番号',
      partner: 'パートナーの名前',
      mobileNumberLabel: '携帯番号',
      handphoneNumberLabel: '携帯電話番号',
      telephoneNumberLabel: '電話番号',

      leibbursch: 'ライプバーシュ',
      leibfuechse: '親しい仲間',
      bio: '私について',
      addDocuments: 'ドキュメントを追加する',
      image: '写真',
      video: 'ビデオ',
      pdf: 'PDF',
      link: 'リンク',
      firstName: '名',
      lastName: '姓',
      title: '役職名',
      email: '電子メール',
      name: '名前',
      lastname: '苗字',
      country: CountryName('JAP'),
      wohnort: '都市',
      postcode: '郵便番号',
      inReview: 'メールによる確認待ち',
    },
    calendar: {
      birthdayLabel: '誕生日',
      noEventsLabel: '選択された日付のイベントはありません。',
      allTabLabel: 'すべて',
      myTabLabel: '私の',
      clubsTabLabel: CorpsName('JAP').single,
      subsTabLabel: 'サブスクリプ',
      subscribedTabLabel: '購読者',
      otherTabLabel: 'その他',

      error: '開始日時は終了日時より前でなければならない。',
      filter: {
        all: 'すべて',
        invited: '招待',
        confirmed: '約束',
      },
    },
    eventLabels: {
      organizer: 'Organizer',
      registrationVia: '経由での登録',
      maximalParticipants: '最大参加者数',
      participantsWarning: '参加者数には制限があります',
      interested: '利害関係者',
      notInterested: '興味なし',
      removeFromWaitList: '待機リストから削除する',
      addEventTitleImage: 'タイトル画像を追加',
      changeEventTitleImage: 'タイトル画像の変更',
      inviteTo: '招待状',
      addEndDate: '終了日時を入力',
      eventLabel: 'オーガナイザー',
      startLabel: 'でスタート',
      locationLabel: '場所',
      participantLabel: '参加者',
      moreInfo: '詳細情報',
      commentsLabel: 'コメント',
      noCommentsLabel: 'コメントはまだない',
      eventStartLabel: 'スタート',
      eventEndLabel: '終了',
      descLabel: '概要',
      creatorLabel: '作成者',
      titleLabel: 'タイトル',
      deleteEventTitle: 'イベントを削除しますか？',
      deleteEventLabel: 'このイベントを削除する?',
      clubsTitle: `${CorpsName('JAP').single}s`,
      gruppenchat: 'グループチャットを選択',
      contactsTitle: '連絡先',
      delCommentLabel: 'コメントを削除しますか？',
      sendInvite: '招待状送付先',
      selectCorps: 'ターゲット・グループを選ぶ',
      externalGuest: '外部招待客',
      externalGuestPlaceholder: 'Eメールアドレスを入力してください。',
      attachmentLabel: '付録',
      shareLinkLabel: 'シェアリンク',
      linkLabel: `${guest_naming_singular} 入る`,
      copyLinkLabel: 'リンクをコピーする',
      iCalDownload: 'カレンダーファイル',
      timezone: 'タイムゾーン',
      visibility: '視認性',
      errorDate: '日付は過去',
      startAfterEndError: '終了日は開始日よりも遅くなければならない',
      members: 'メンバー',
      waitlist: 'ウェイティングリスト',
      onWaitlist: 'ウェイティングリストにいます',
      leaveWaitlist: 'ウェイティングリストから離脱しますか？',
      leaveWaitlistDesc:
        'ウェイティングリストから削除しますか？\n\n 後で再びウェイティングリストに参加すると、リストの最後に配置される可能性があります。',
      limitAchieved: (limit: number) => `${limit} の参加者の最大数に達しました。あなたは待機リストに登録されました。`,
      visibilityOptions: {
        allWithoutGuests: 'メンバーとウェブサイト',
        invited_member: '招待された会員のみ',
        verified_member: '全員',
        authorized_member: '全メンバーとゲスト',
        all: '会員、ゲスト、ウェブサイト',
      },
      modalEvent: {
        title: 'イベント作成',
        subTitle: 'このイベントは.\n\n',
        content:
          'ユーザー設定により、イベントのプッシュ通知/メール通知が送信されるようになります。Profile」→「Notifications」で設定した場合は、自分自身にもイベントに関するメール通知が届きます。',
      },
      modalComment: {
        title: '通知先?',
        message: 'あなたのコメントを誰に知らせるべきか？',
        optionAll: '参加者全員',
        optionNone: '主催者のみ(en)',
        button: 'コメントを送信',
      },
      cancelModal: {
        title: 'キャンセルまたは削除?',
        message: 'イベントのキャンセルまたは削除?',
        optionAll: 'キャンセルについて (参加者に通知)',
        optionNone: '削除（参加者には知らされない）',
      },
      updateEventModal: {
        title: 'さらに招待状を送付',
        label: '他のグループも招待された。',
        message: '設定に応じて、イベントに関するプッシュ通知またはEメール通知が他のグループに送信されます。',
      },
      eventRegistrationType: {
        app: 'アプリ',
        event: 'リンク/メールアドレス',
        enterLink: 'リンク/メールアドレスなどを追加',
      },
      parts: {
        part: 'パート',
        addPart: 'プログラムポイントを追加',
        hint: '説明',
        question: '質問',
        single: '単一選択可能',
        multi: '複数のオプションが利用可能',
        freeText: '自由テキスト回答',
        quiz: 'クイズ',
        questionnaire: 'アンケート',
        addQuestionnaire: 'アンケートを追加',
        questionnaireType: '回答オプション',
        option: 'オプション',
        addOption: 'オプションを追加',
        showMembers: '参加者',
        youJoined: '参加しています:',
        totalToPay: '支払う総額:',
        yourAnswer: 'あなたの回答',
        visibleForCreators: '作成者のみ表示可能',
        allAnswers: 'すべての回答:',
        addUsers: 'ユーザーを追加',
        isAnonym: '匿名投票',
        isAnonymQuiz: '匿名クイズ',
      },
    },
    rrEventLabels: {
      title: '繰り返し',
      MO: '月曜日',
      TU: '火曜日',
      WE: '水曜日',
      TH: '木曜日',
      FR: '金曜日',
      SA: '土曜日',
      SU: '日曜日',
      YEARLY: { single: '年', regular: '毎年' },
      MONTHLY: { single: '月', regular: '毎月' },
      WEEKLY: { single: 'ウィークリー', regular: 'ウィークリー' },
      DAILY: { single: '日', regular: '毎日' },
      SINGLE: { single: 'ユニーク', regular: 'ユニーク' },
      CUSTOM: {
        single: 'カスタマイズ',
        regular: 'カスタマイズ...',
      },
      freq: '頻度',
      interval: 'インターバル',
      notRepeat: 'ユニーク',
      every: 'すべての',
      month: '月',
      week: '週間',
      last: '最後',
      on: 'オン',
      everyMonthOn: '毎月',
      ofTheMonth: '月',
      editLabel: 'どの日程を変更しますか？',
      thisDate: 'この日のみ',
      thisDateAndFollowing: 'これ以降もすべて',
      allDates: '全日程',
      after: 'その後',
      time: 'アポイントメント',
      times: 'アポイントメント',
      neverEnds: '終わりはない',
      until: 'まで',
      repeatedDefault: ({ frequency, freq }: { frequency: string; freq: number }) =>
        `すべての${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency} 繰り返し`,
      repeatedMonthly: ({ day, interval }: { day: string; interval: number }) =>
        `毎回${interval == 1 ? '' : ` ${interval}.`} 月 ${day.length > 2 ? day : `${day}.`} を繰り返した。`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }: { day: string; interval: number; bysetpos: number }) =>
        `毎回${bysetpos == -1 ? ' 最後' : ` ${bysetpos}.`} ${day} の${
          interval === 1 ? '' : ` ${interval}.`
        } 月が繰り返される。`,

      repeatedYearly: ({ interval }: { interval: number }) =>
        `もしそれぞれが${interval == 1 ? '' : ` ${interval}.`} 繰り返し年.`,
      repeatedWeekly: ({ interval, byweekday }: { interval: number; byweekday: any }) =>
        `すべての${interval != 1 ? ` ${interval}.` : ''} 週間${
          !!byweekday?.length
            ? ' am ' +
              byweekday?.slice(0, -1).join(', ') +
              (byweekday?.length > 1 ? ' そして ' : '') +
              byweekday?.slice(-1)
            : ''
        } 繰り返し.`,
      repeatedDaily: ({ interval }: { interval: number }) => `毎回${interval != 1 ? ` ${interval}.` : ''} 連日.`,
    },
    requestsLabels: {
      receiveLabel: '受け取る',
      sentLabel: '送信済み',
    },
    post: {
      adTitle: 'インフォメーション/ディスプレイ',
      toKreise: 'オン',
      comment: 'コメント',
      myPost: 'I',
      openPdf: 'クリックして開く',
      deletePost: 'クリックして開く',
      posted: '投稿',
      commented: 'コメント',
      contacts: '連絡先',
      sendToContacts: '皆さんへのメッセージ',
      enterText: 'ここにメッセージを書く',
      sendToCircle: `メッセージ ${KreiseName('JAP').singleArticle}`,
      sendComment: 'コメントを入力',
      longMessage: '最大20,000文字',
      noComments: 'コメントはまだない',
      deleteComment: 'コメント削除?',
      deleteEventPost: 'この投稿を削除しますか？これはイベントを削除しません.',
      editPostLabel: '投稿を編集する',
      editingPostLabel: '編集モード',
      deletePostLabel: '投稿を削除する',
      editedAt: '加工済み',
      pinned: 'ピン留め.',
      actions: {
        label: 'ポストアクション',
        pin: {
          label: 'ピンポスト',
          once: '一度繰り上がる',
          d3: '3日',
          d5: '5日',
          w1: '1週間',
          w2: '2週間',
          unpin: 'これ以上ピンを打つな',
        },
      },
    },
    circles: {
      circlesTitle: `購読者 ${KreiseName('JAP').multi}`,
      noCirclesTitle: `未登録 ${KreiseName('JAP').multi}`,
      myPost: 'I',
      circlesInfoTitle: '情報＆参加者',
      circlesSubscribe: 'サブスクライブ',
      unsubscribeAlert: 'クローズド・グループ・チャットを取り消すことはできない？',
      continueAlert: '放棄',
      readOnlyKreise: `これは "読むだけ-${
        KreiseName('JAP').single
      }". ここで投稿を作成できるのは管理者のみです。ただし、投稿にコメントを書き込むことはできます。.`,
    },
    messages: {
      addMessageLabel: 'メッセージを入力',
      changesSaved: '変更が保存された！',
      newConversation: 'New conversation',
    },
    profileTabs: {
      basicTab: 'ベース',
      clubsTab: `${CorpsName('JAP').single}`,
      miscellaneosTab: '趣味',
      professionTab: '職業',
      studyTab: StudyName('JAP')?.short,
      notificationsTab: 'お知らせ',
      privacy: 'プライバシー',
    },
    profile: {
      addPhotoLabel: '写真を追加',
      genderInputLabel: '性別',
      titleLabel: 'タイトル',
      firstNameLabel: '名前',
      lastNameLabel: '苗字',

      passwordLabel: 'パスワードの設定',
      birthDateLabel: '生年月日',
      birthday: '生年月日',
      changePasswordLabel: 'パスワードの変更',
      defaultCountryLabel: 'ドイツ',
      defaultGraduationLabel: '特になし',
      defaultCorpsLabel: 'なし',
      street: 'ストリート＋No. ',
      houseNumberLabel: '家屋番号',
      partner: 'パートナー名',
      mobileNumberLabel: '携帯番号',
      handphoneNumberLabel: '携帯電話番号',
      telephoneNumberLabel: '電話番号',
    },
    profileKorp: {
      verband: CorpsName('JAP').single,
      function: FunctionName('JAP'),
      dateOfJoin: CorpsEditDate('JAP'),
      beername: 'ビール名',
    },
    profileClubs: {
      noUserLabel: 'アプリを使用する前に、まずすべての基本情報を入力しなければならない。',
      functionLabel: `${FunctionName('JAP')}`,
      setClubLabel: `の名前を入力してください。 ${CorpsName('JAP').single} 指定する.`,
      matchClubLabel: 'について',
      matchClubContinueLabel: 'すでにプロフィールに存在しますので、一度ご確認ください。',
      clubLabel: `${CorpsName('JAP').single.toUpperCase()}`,
      clubCodeLabel: `ログインコード ${CorpsName('JAP').single.toUpperCase()}S`,
      entryTimeLabel: CorpsEditDate('JAP'),
      deleteClubLabel: '会員資格の延長をご希望ですか？',
      deleteClubContinueLabel: '本当に削除するのか？',
      selectVariant: 'どうしたい？',
      requestExistingCorp: '既存のエントリーを追加する',
      requestNewCorps: '新規エントリーのリクエスト',
      createCorp: '新規エントリーの作成',
    },
    profileProfession: {
      jobTitle: '役職名',
      noUserLabel: 'アプリを使用する前に、まずすべての基本情報を入力しなければならない。',
      untilToday: '今日まで',
      positionTitleLabel: '職業',
      itemNameLabel: '職業',
      employmentLabel: '雇用形態',
      activityLabel: ActivityName('JAP'),
      careerStageLabel: 'キャリアレベル',
      activityDescLabel: '活動内容',
      companyLabel: CompanyName('JAP'),
      nameLabel: '名称',
      industryLabel: IndustryName('JAP'),
      areaLabel: 'レンジ',
      websiteLabel: 'ウェブサイト',
      employmentPeriodLabel: '雇用期間',
      fromLabel: 'より',
      toLabel: 'まで',
    },
    profileStudy: {
      noUserLabel: 'アプリを使用する前に、すべての基本情報を入力する必要があります。.',
      universityLabel: StudyName('JAP').hochsule,
      courseLabel: StudyName('JAP').studiengang,
      degreeLabel: StudyName('JAP').abshchluss,
    },
    profileOther: {
      noUserLabel: 'アプリを使用する前に、まずすべての基本情報を入力しなければならない。',
      hobbiesLabel: '趣味',
      culturalLabel: '文化スポンサー',
      socialMediaPlattform: 'ソーシャルメディア・プラットフォーム',
      socialMediaLabel: 'ソーシャルメディア・リンク',
      socialMediaLink: 'リンク',
      socialMediaError: '完全なURLを入力してください',
    },
    profileNotifications: {
      noUserLabel: 'アプリを使用する前に、まずすべての基本情報を入力しなければならない。',
      notificationsInfo:
        'ここでは、個々のアクティビティに対するEメール通知を有効にするか（デフォルト）、または3ヶ月間無効にすることができます。',
      notificationsTitle: 'プッシュ通知:',
      notificationsDescr0: 'プッシュ通知は ',
      notificationsDescr1: 'スマートフォンのシステム設定',
      notificationsDescr2: ' 作る.',
      mailingTitle: '電子メールによる通知：',
      mailingDescr:
        'ここでは、個々のアクティビティに対するEメール通知を有効にしたり（デフォルト）、1年間無効にしたりすることができます。',
      allNotificationsLabel: 'すべての通知',
      postsLabel: `貢献 ${KreiseName('JAP').multi}`,
      messagesLabel: 'プライベートメッセージ',
      commentsLabel: '自身の貢献に対するコメント',
      contactRequestsLabel: 'お問い合わせ',
      eventsLabel: 'イベント',
      clubMemberLabel: '新規会員登録',
      enableLabel: 'へ',
      disableToLabel: 'までオフ',
      disableLabel: 'より',
      ownPosts: '私の投稿（郵便物も自分で受け取った）',
    },
    searchInput: {
      title: '検索',
      placeholder: '検索',
    },
    emptyList: {
      title: 'ここには（まだ）コンテンツはない',
    },
    switchLanguage: {
      placeholder: '言語/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'Russian (Русский)',
      SP: 'Spanish (Español)',
      NLD: 'Dutch (Nederlands)',
      POL: 'Polish (Polish)',
      title: '言語を選択',
    },
    filePicker: {
      takePicLabel: 'アタッチメント',
      uploadPicLabel: '写真を撮る',
      selectPicLabel: 'F音/ビデオを選択',
      selectDocLabel: 'ドキュメントを選択',
    },
    datePicker: {
      selectDateLabel: '日付を選択',
      confirmDateLabel: '確認',
      selectTimeLabel: '時間を選択',
    },
    changePassword: {
      currentPasswordLabel: '現在のパスワード',
      newPasswordLabel: '新しいパスワード',
      repeatPasswordLabel: 'リピートパスワード',
      changePasswordLabel: 'パスワードの変更',
    },
    appInfo: {
      appDescLabel:
        'Korponnectは、クラブ活動をしている学生のためのアプリです。企業間の交流、イベントの開催、プロフェッショナルでプライベートなネットワーキングなど、すべてが1つのアプリに集約されています。',
      contactsLabel: InfoContactsName('JAP'),
      infoLabel: 'インフォメーションn',
      versionLabel: 'アプリ版',
      agbLabel: 'GTC',
      dataProtectionLabel: 'プライバシーポリシー',
      manualLabel: '使用方法',
      appTextLabel: 'ここでは、私たちの',
      appAndLabel: 'そして',
      appMainTextLabel: `サークルを追加しますか？プロフィールを削除したいですか？ ${support_email}。`,
      deleteFeedback: '削除理由／ご意見 🙂:',
      deleteUserTitle: 'アカウントを削除しますか？',
      deleteUserDesc:
        'アカウントを削除することもできます。プロフィール]->[通知]で設定を編集するか、私たちにメールを送ってください。',
      deleteUserAlert: '本当にアカウントを削除したいのですか？',
      webLabel: 'ウェブ版',
    },
    infoMessages: {
      registartionSuccessLabel: 'ご登録ありがとうございました。!',
      manualRegSuccessLabel: 'ご登録ありがとうございます。まもなく登録が完了し、Eメールにてお知らせいたします。',
      linkExpiredLabel: 'リンクの有効期限が切れています。新しいパスワードの変更をリクエストしてください。',
      emailExistLabel: '申し訳ございませんが、Eメールはご利用いただけません。.',
    },
    logoutAlert: {
      title: 'ログアウト',
      text: '本当にログアウトしますか？ログアウトする必要はありません。アプリを閉じるか最小化するだけです。',
      confirm: 'はい',
      dismiss: 'キャンセル',
    },
    invalidInputs: {
      passwordChars: `パスワードは少なくとも ${passwordDigits(
        'JAP',
      )} 文字（少なくとも1つの文字、1つの数字、1つの特殊文字を含む）。`,
      fieldRequired: 'このフィールドは必須です：.',
      emailsNotMatching: 'あなたの電子メールが一致することを確認してください',
      emailInvalid: 'メールアドレスが正しくありません！',
      selectCorrectAnswer: 'You need to select correct answer',
      corp: '少なくとも1つのエントリーを選択してください。',
    },
    mentoring: {
      profile: {
        rules1: 'に同意する。',
        rules2: ' 価値観の理解 ',
        rules3: '“奨学金保持者のためのメンタリング」。',
        menteeDescription:
          'メンター地域を選択すると、現地で開催されるイベントについてお知らせすることができます。注：選択はいつでも変更できます。メンター探しを制限するものではありません。',
        mentorDescription:
          '奨学生があなたをメンターとして見つけることができるよう、メンター・プロフィールを記入してください。注：少なくとも1つの地域と少なくとも1つの分野が必要です。',
        mentoringRegions: 'メンタリング地域',
        reigonsShort: '地域',
        topics: '対象分野',
        departments: '部門',
        available: '私は利用可能である',
        unavailable: '現在欠席中',
        deleteAccount: 'メンターのプロフィールを削除し、グループから離脱する',
        deleteAccountDescr: 'メンター・プロフィールを削除し、メンター・グループ・チャットから退会しますか？',
        uploadPdf: 'メンター・プロフィール (PDF)',
        myPdf: 'プロフィール (PDF)',
        pdf: 'メンター・プロフィール',
      },
      tabNames: {
        info: 'インフォメーション',
        news: 'ニュース',
        profile: 'プロフィール',
        search: '検索・お問い合わせ',
        request: 'メンター',
        requestDetails: 'メンタリングに関するお問い合わせ',
        tabDeactivated: 'タブが無効になりました。メンタープロフィールを入力してください。',
      },
      search: {
        description:
          '的を絞って検索を開始するために、いくつかの選択フィールドから始め、新しい検索実行で検索グリッドを広げることをお勧めします。お住まいの地域で適切な指導者が見つからない場合は、近隣の指導地域に検索を広げてください。検索条件：1地域、1～3分野。すべてのフィールドはANDリンクされています！',
        searchBtn: '検索',
      },
      requests: {
        mentorIncoming: '私の指導に関するお問い合わせ',
        mentorCurrent: '私のメンターたち',
        menteeOutgoing: 'メンタリングに関するお問い合わせ',
        menteeCurrent: '私のメンターシップ',
        cancelMentoring: 'メンタリング終了',
        sendRequest: 'メンターとしての依頼',
        alreadyHaveMentor: 'メンターは一人しか持てない',
        requestSuccess: 'お問い合わせは正常に送信されました',
        requestRetracted: 'お問い合わせの取り下げに成功',
        cancelSendingRequest: 'メンタリング要請の取り下げ',
        approveRequest: '問い合わせを受け付ける',
        rejectRequest: 'リクエスト拒否',
        invitaionText: 'お問い合わせ',
        rejectionText: '不採用の理由を述べる',
        sureRetractRequest: '本当に問い合わせを取り下げたいのか？',
      },
    },
    downloads: {
      root: 'メインディレクトリ',
      delete: '削除',
      rename: '名前変更',
      areYouSureDeleteFolder: '本当にフォルダを削除しますか？すべてのコンテンツが削除されます！',
      renameFolder: 'フォルダ名の変更',
      createNewFolder: '新しいフォルダを作成する',
      newFolderPlaceholder: 'フォルダ名を入力',
      create: '作成',
      areYouSureDeleteFile: '',
      downloadFile: 'ダウンロード',
      deleteFile: 'デバイスから削除',
      openInFinder: 'ファイルで開く',
      cancel: 'キャンセル',
    },
    globalSearch: {
      postedBy: '投稿者',
      found: '発見',
      loadMore: 'もっと読む',
      enterQuery: '検索用語を入力してください。何でも検索できます(名前、文書、イベント、投稿、...)',
      folder: 'フォルダ',
      post: '貢献',
      error: '3文字以上',
    },
    kreiseSettings: {
      description: '概要',
      push: 'プッシュ通知',
      email: 'メール通知',
      subscribers: 'メンバー',
      active: '活性化',
      oneYear: '1年間沈黙',
      forever: 'サイレント・フォーエバー',
      until: 'まで',
      cantDeactive: 'この設定は、このグループチャットでは無効になっています。',
      SINGLE: '各寄稿について',
      SUMMARY_ONCE: 'サマリー 週1回',
      SUMMARY_TWICE: 'サマリー 週2回',
      SUMMARY_DAY: 'サマリー 1日1回',
      OFF: 'より',
      PUSH: 'プッシュ通知',
      EMAIL: 'メール通知',
      PUSH_AND_EMAIL: 'プッシュ通知とEメール通知',
      newUser: '新規ユーザーへの通知',
      statistic: '送信統計',
      off: 'より',
      ownPosts: 'ご寄付の統計メールを送る',
      deleteMemberDescr: '削除?',
      deleteMemberBtn: '削除',
      retractAdminRights: '権利の撤回',
      deleteAdminAndUser: '完全に取り除く',
      toDeleteKreiseBtn: 'グループチャットの削除',
      deleteKreiseDescr: `${KreiseName('JAP').single} 取り返しのつかない削除？`,
      deleteKreiseModalTitle: 'メンバーを外す？',
      addKreiseName: '名称',
      addKreiseDescription: '概要',
      addMembers: 'メンバーを追加する',
      createKreise: 'グループチャットの作成',
      toKreiseEditMessage: '注：メンバーの変更はすべて直ちに実施されます',
      kreiseCreator: '作成者：このグループチャットで',
      kreiseDeleteMembersLeft: 'グループチャットを削除できるのは、メンバーが1人だけになったときだけです。',
      kreiseDeleteMembersLeftTitle: 'まずメンバーから外す',
      suggestionInNewsfeed: {
        admin: '管理者',
        member: 'メンバー',
        corp: `${CorpsName('JAP').single} メンバー`,
        corpAdmin: `${CorpsName('JAP').single} 管理者`,
      },
    },
    surveys: {
      checkVotes: 'ショーの結果',
      chooseOptions: {
        single: 'オプションを選択してください。',
        multiple: '少なくとも1つのオプションを選択する。',
      },
      votesModalTitle: '結果',
      createModalTitle: 'アンケート',
      noVotes: '投票は行われていない',
      question: '質問',
      askAQuestion: '質問する',
      allowMultiple: '複数回答を許可する',
      votes: {
        single: '声',
        multiple: '声',
      },
      option: 'オプション',
      optionsLabel: 'オプション',
      anonymus: '匿名投票',
      create: '作成',
    },
    subscription: {
      subscription: 'Abonnements',
      pricePerMonth: 'EUR/Monat',
      month: 'Monat',
      day: 'Tag',
      otherPlans: 'Andere Tarife',
      subscriptionActive: 'Abonnement aktiv',
      until: 'bis',
      subscribe: 'Abonnieren',
      successfulPayment: {
        title: 'Zahlung erfolgreich',
        text: 'Dein Abonnement wurde erfolgreich abgeschlossen. Du kannst ab sofort die erweiterten Funktionen nutzen.',
      },
      cancel: {
        cancel: 'Abonnement kündigen',
        title: 'Abonnement kündigen?',
        text: 'Möchtest du dein Abonnement kündigen? Nach Ablauf der bezahlten Periode werden deine Zugriffsrechte zurückgestuft.',
      },
    },
  },
  {
    key: 'CIN', //chinese language / chinesisch
    key2: 'zh',
    editProfile: {
      updated: '个人资料已更新。',
      validateFieldsViaEmail: '请通过电子邮件验证以下数据字段。只有验证后，它们才会提交给中央会员管理。',
      newValue: (value: any) => `新值: "${value}"`,
      resendEmail: '或者我们应该重新发送验证邮件吗？',
      validationPending: '验证待处理',
      resend: '重新发送',
      resendDone: '验证邮件已重新发送。请等待几分钟并检查垃圾邮件文件夹。',
      resendLong: '或者我们重新发送验证邮件？',
    },
    appInfoScreen: {
      title: '关于',
      privacy: '隐私信息',
      find: '在这里你可以找到',
      terms: ' 使用条款 ',
      and: ' 和 ',
      infoSection: '应用信息',
      hasUpdate: '有新版本。点击这里更新。',
      noUpdate: '这是最新版本。',
      appVerison: '应用版本：',
    },
    corpsScreen: {
      website: '网站:',
      email: '电子邮件地址:',
      members: '成员',
      admins: '管理员',
      info: '信息',
      searchMember: '搜索成员',
    },
    contactsScreen: {
      empty: "这里还没有任何条目。请前往主菜单并选择 '搜索' → '姓名' 来查找新联系人。",
    },
    dialogPanel: {
      hangOn: '附加 Bierjunge?',
      ok: '确定',
    },
    dialogs: {
      emptyTitle: '点击右上角的加号开始一个对话。',
    },
    documentsGallery: {
      modalTitle: '删除文档',
      modalDesc: '删除这个文件？',
      cancel: '取消',
      delete: '删除',
      deleteConfirm: '删除下载的文件（仅对我）？',
      downloadConfirm: '下载此文件？',
      availableInDownloads: `\n\n*所有下载的文件可在下载文件夹中找到`,
    },
    drawerScreen: {
      backdrop: '在使用应用之前，必须首先完成所有基本信息。',
    },
    kreiseInfoScreen: {
      creator: '此群组聊天的创建者',
      revokeAdmin: (name: string) => `你想要移除 "${name}" 的管理员权限，还是将其从群组中移除？`,
    },
    kreisePostScreen: {
      infoParticipants: (num: string | number) => `信息与参与者 (${num})`,
      subscribeTitle: '订阅群组聊天？',
      subscribeDesc: '你想要订阅这个群组聊天，以便不错过任何新闻吗？',
      yes: '是的',
      later: '稍后再说',
      dontAsk: '以后不要再询问这个群组聊天',
    },
    mapScreen: {
      appUser: '应用用户',
      viewIsForMember: '查看用户资料仅对成员可用。',
      all: '所有',
    },
    mentoringScreen: {
      clickMentor: '点击导师的名字发送提醒或撤回请求',
      all: '所有',
      startRequest: '开始请求:',
      startDesc: '请尽可能清晰简明地描述你的导师需求，这将帮助导师更好地评估你的请求并及时回应。',
      notice: '注意:',
      noticeDesc: '在发送提醒之前，应该允许导师最多两周的时间来回应。在联系其他导师之前，请撤回你当前的请求。',
      withdrawRequest: '撤回请求',
      reminder: '发送提醒',
      reminderSuccess: '提醒正在发送中（+复制给你）',
      rejectRequest: '拒绝请求',
      requestTitle: '导师请求',
      areas: '专长领域',
      topics: '主题',
      withReguards: (name: string, lastname: string) => `\n\n\n(...)\n\n此致敬礼，\n\n${name} ${lastname}`,
      rejectionMessage: (name: string, lastname: string) =>
        `你好！\n\n很抱歉，我必须拒绝你的导师请求。如果你需要了解原因，我很乐意为你解释。\n\n此致敬礼，\n\n${name} ${lastname}`,
      maxMessage: (max: number | string, topic: string) => `你最多可以选择 ${max} 个 ${topic}。`,
      decisionOpen: '决策：待定',
      maxMentoring: '最多允许 1 个导师请求',
      maxParalelMentoring: '最多允许 1 个并行导师请求',
      openHere: '在这里打开',
      profileUpdated: '个人资料已更新！',
      mentoringProfile: '导师资料',
      mentee: '学员',
      mentor: '导师',
      leaveMentoring: '离开导师小组',
      mentoringTitle: '导师指导',
      scrollAuto: '可滚动自动标签示例',
      stopMentoringMessageMentor: (name: string, lastname: string) => `结束与 ${name} ${lastname} 的导师关系？`,
      stopMentoringMessageMentee: (name: string, lastname: string) => `结束与 ${name} ${lastname} 的导师关系？`,
      mentorEndedMentorship: (name: string, lastname: string) => `导师 ${name} ${lastname} 已结束导师关系。`,
      mentorRejectedMentorship: (name: string, lastname: string) => `导师 ${name} ${lastname} 拒绝了你的请求。`,
      menteeEndedMentorship: (name: string, lastname: string) => `学员 ${name} ${lastname} 已结束指导关系。`,
      menteeRejectedMentorship: (name: string, lastname: string) => `学员 ${name} ${lastname} 拒绝了您的请求。`,
      mentorat: '导师指导',
    },
    newEventScreen: {
      kreiseError: '请选择目标群体',
      correctDate: '更正日期/时间',
      correctDateMessage: '结束日期/时间在开始日期/时间之前。请更正。',
    },
    outRequestScreen: {
      backButton: '联系人请求',
      requestMessage: (name: string, lastname: string) =>
        `你好！\n\n我想将您加入我的联系人。\n\n此致\n\n${name} ${lastname}`,
    },
    participantsScreen: {
      interestedParties: '感兴趣方',
      removeGuestTitle: (name: string) => `移除 ${name}?`,
      removeGuestDesc: (name: string) => `移除 "${name}" 从参与者列表？`,
      informUser: (name: string) => `通知 "${name}" 已从参与者列表中移除`,
    },

    partParticipantsScreen: {
      interestedParties: '感兴趣的方',
      removeParticipantTitle: (name: string, lastname: string) => `从参与者列表中移除参与者 "${name} ${lastname}"？`,
      removeParticipantDesc: (name: string, lastname: string) => `移除 "${name} ${lastname}"？`,
    },
    documentsScreen: {
      deleteTitle: '已选择文件：',
      cancel: '取消',
      uploadData: '上传数据',
    },
    postsScreen: {
      ads: '广告...',
      oldPostsForMembers: '旧帖子仅对成员可见。',
    },
    profileBasisScreen: {
      editPhoto: '编辑照片',
    },
    profileBenachrichtigungenScreen: {
      fillData: '在应用程序可以使用之前，所有基本信息必须填写。',
      changesInChat: '您可以在相应的群聊中进行这些设置。',
      commentsWarning1: '不建议禁用关于您自己帖子评论的电子邮件通知，因为可能会错过重要评论。',
      commentsWarning2: '不建议禁用私信的电子邮件通知，因为可能会错过重要消息。',
      messagesWarning1:
        '不建议禁用私信的电子邮件通知，因为与群聊帖子的比较，您通常收到的私信较少。然而，私信通常涉及具体请求，禁用通知可能会错过这些（重要）消息。',
      messagesWarning2: '不建议禁用关于您自己帖子评论的电子邮件通知，因为可能会错过重要评论。',
      areYouSure: '您确定吗？',
      turnOff: '关闭',
      maybeLater: '稍后再说',
      notRecommended: '不推荐设置',
      ok: '确定',
    },
    profileBerufScreen: {
      fillData: '在应用程序可以使用之前，所有基本信息必须填写。',
      defaultDate: '直到今天',
      noArea: '没有地区',
      furtherActivity: '附加活动',
      deleteConfirm: '真的删除条目吗？',
      ok: '确定',
    },
    profileCorpsScreen: {
      corpsname: '项目名称',
      createHint: '正在创建一个新条目。',
      requestHint: '正在请求创建新条目。',
      status: '状态',
      deleteConfirm: (name: string) => `您真的想删除在 ${name} 的会员资格吗？`,
      ok: '确定',
      fillData: '在应用程序可以使用之前，所有基本信息必须填写。',
      duplicatedCorps: (name: string) => `该 ${name} 已经在个人资料中，请再次检查。`,
      missingCorpName: '请提供项目名称。',
      waitingActivation: '等待激活',
      requestExistingMessage: (name: string) => `"${name}" 的会员资格已申请。激活后您会收到通知。`,
      requestNewMessage: (name: string) => `"${name}" 的创建已申请。激活后您会收到通知。`,
      createMessage: (name: string) => `"${name}" 已创建并添加到您的个人资料中。`,
    },
    profileSonstigenScreen: {
      other: '其他（请填写）',
    },
    profileDetailsScreen: {
      email: '电子邮件',
    },
    profilePrivacyScreen: {
      fillData: '在应用程序可以使用之前，所有基本信息必须填写。',
    },
    profileStudiumScreen: {
      deleteConfirm: '真的删除条目吗？',
      ok: '确定',
      fillData: '在应用程序可以使用之前，所有基本信息必须填写。',
    },
    reportScreen: {
      reportUser: '举报用户',
      notMember: '该用户不再是会员',
      fakeProfile: '该用户正在发布虚假资料。',
      violations1: '该用户的头像违反了指南。',
      violations2: '该用户的声明违反了指南。',
      violations3: '该用户在聊天中发送垃圾信息。',
      violations4: '报告/标记可能的违规内容',
      violations5: '报告/标记其他用户的可能违规行为',
      reportReason: '举报原因',
      sendReport: '发送',
      cancel: '取消',
      showContent: '显示此用户的内容',
      hideContent: '隐藏此用户的内容',
    },
    resetPasswordScreen: {
      incorrectEmail: '您的电子邮件地址不正确。',
    },
    serverIsDownScreen: {
      serverMaintenance: '服务器维护',
    },
    signInScreen: {
      dontHaveAccount: '没有账户？',
    },
    signUpScreen: {
      login: '登录',
      emailMatchError: '电子邮件和电子邮件确认不匹配。',
      passwordMatchError: '密码和密码确认不匹配。',
      incorrectEmail: '您的电子邮件地址不正确！',
      pleaseSelect: '请选择',
      accountExistsTitle: '账户已存在',
      accountExistsDesc: '您已使用此电子邮件地址注册账户。',
      toLogin: '登录',
    },
    subscriptionsScreen: {
      subscribed: '已订阅：',
      noSubscribed: '未找到订阅的社团',
      otherSubscriptions: '其他订阅的组织者：',
      noOtherSubscriptions: `未找到订阅的组织者`,
    },
    sucheScreen: {
      searchFor: '搜索结果：',
      filter: '筛选：',
      name: '姓名',
      branch: '分支',
      function: '功能',
      status: '状态',
      city: '城市',
      industry: '行业',
      business: '商业',
      profession: '职业',
      training: '培训',
      studyProgram: '学习计划',
      college: '学院',
      cancel: '取消',
      searchBar: '搜索栏',
      searchTab: '搜索标签',
      searchTitle: '搜索',
      scrollable: '可滚动的自动标签示例',
    },
    dialogComponent: {
      close: '关闭',
    },
    phoneInputComponent: {
      noCountry: '未找到国家。',
    },
    cropImageHandlerComponent: {
      cancel: '取消',
      crop: '裁剪',
    },
    datePickerComponent: {
      cancel: '取消',
      ok: '确定',
      date: '日期/时间',
      day: '日',
      month: '月',
      year: '年',
      wrongDate: '错误的日期',
    },
    timePickerComponent: {
      hours: '小时',
      minutes: '分钟',
    },
    responseHandlerComponet: {
      supportEmail: `未知错误。请联系 ${support_email}`,
    },
    searchModalComponent: {
      selectionError: '请至少选择一项条目。',
      maximumSelection: (num: string | number) => `最多选择 ${num} 个条目！`,
    },
    customDropzoneComponent: {
      dropFiles: '将文件拖放到此处',
      clickHere: ' 或点击此处选择 ',
    },
    documentsGalleryComponent: {
      clickToOpen: '点击打开',
      clickToDownload: '点击下载',
    },
    eventToolbarComponent: {
      noRegistration: '没有注册！',
      notLoggedId: '您尚未登录。这只是一个表达兴趣。',
      registerVia: '注册方式：',
    },
    likeComponent: {
      externalGuest: '外部访客',
      invitedBy: (name: string) => `由 ${name} 邀请`,
    },
    membersComponent: {
      interested: '表达兴趣',
      loggedIn: '已登录',
      um: ' 于 ',
      invitedBy: (name: string) => `由 ${name} 邀请`,
    },
    multipleSearchableInputComponent: {
      maximalMembers: `请指定最多 ${maximal_membership_count} 个会员。`,
    },
    multipleSelectModalComponent: {
      all: '所有',
    },
    offlineModalComponent: {
      checkInternet: '请检查您的互联网连接。',
    },
    postsToolbarComponent: {
      membersOnly: '仅会员可以发布。',
    },
    zusagenModalComponent: {
      incorrectEmail: '您的电子邮件不正确',
      required: '此字段是必填的。',
      name: '姓名',
      email: '电子邮件（可选）',
      invitedBy: '邀请人',
      save: '保存',
      cancel: '取消',
      warning1: `
			注意：请仅作为 ${guest_naming_singular} 在此注册！
			如果您已经有一个帐户，请使用该帐户登录并正式确认作为会员。谢谢！`,
      warning2: '通过注册，您同意 ',
      and: ' 和 ',
      to: '。',
    },
    formInputComponent: {
      cancelConfirmation: '取消确认？',
    },
    logoComponent: {
      alt: '应用程序Logo',
    },
    customHistoryHook: {
      news: '消息',
    },
    postContentComponent: {
      orginized: '主办方：',
      date: '日期：',
      ort: '地点：',
    },
    beerSmileComponent: {
      bierjunge: '喝啤酒！',
    },
    buttonComponent: {
      examination: '检查：',
    },
    global: {
      all: '全部',
      postSubject: '帖子主题',
      showMore: '阅读更多',
      toRegister: '去注册',
      addToCalendar: '添加到日历',
      interested: '感兴趣',
      loggedIn: '已登录',
      tilToday: '直到今天',
      status: '状态',
      deletePost: '删除帖子？',
      pdf: '(PDF)',
      time: '时间',
      birthday: (age: number | string, name: string, lastname: string) => `${age}岁的生日，${name} ${lastname}`,
      eur: '欧元',
      price: '价格：',
      priceForMe: '我的价格:',
      ok: '确定',
      likesCount: (num: string | number) => `点赞 (${num})`,
      event: '活动',
      standardMessage: '我们正在为您的帖子生成一个标题。这个标题合适吗？',
      optionalMessage: '您的帖子是关于什么的？写一个简短的标题。您也可以让AI为您生成建议。',
      optionalMessageNote: '使用此功能即表示您同意',
      chatGPTTerms: 'ChatGPT 使用条款',
      chatGPTTermsLink: 'https://openai.com/policies/terms-of-use',
      optionalMessageNoteEnding: '。',
      postSubjectTitle: '帖子标题',
      generate: '生成',
      defaultSubject: '默认标题',
      memebership: '会员',
      applyMemebership: '申请会员？',
      pendingMembership: '会员申请已提交 - 等待决策。',
      requestAlreadySent: '请求已发送',
      requestedMemebership: (kreiseName: string) => `已申请加入“${kreiseName}”的会员。`,
      yes: '是',
      no: '否',
      change: '更改',
      passwordOldMatchNewError: '旧密码和新密码相同',
      passwordLenghtError: '密码必须至少6个字符长。',
      passwordsMatchError: '旧密码和新密码相同',
      passwordsMatchError2: '密码和确认密码不匹配。',
      currentPassword: '当前密码',
      newPassword: '新密码',
      confirmPassword: '确认密码',
      changePassword: '更改密码',
      cancel: '取消',
      fits: '合适',
      selectChatTitle: '选择群聊',
      selectChatDesc: '选择至少1个收藏的聊天，您要将帖子发送到该聊天。您可以在主菜单的“群聊”中找到更多。',
      socialMedia: '社交媒体',
      generatedText: '我们正在为社交媒体生成文本。这个文本合适吗？',
      usePhoto: '感谢您的帖子！我们可以将您的照片用于社交媒体渠道吗？',
      maximumLenght: '最大20,000个字符。',
      userSharedNewPicture: '我们的应用用户分享了一张新照片！',
      contributionTo: (kreiseName: string) => `贡献给“${kreiseName || '所有'}”`,
      postWarning1: '加油！您仍然可以在下一步中添加自己的文本。此外，您的帖子将会被审查。',
      postWarning2: '提交后，您的帖子将由管理员审查。如有疑问，请随时联系',
      postWarning3: '或',
      AGB: '条款和条件',
      DSE: '隐私政策',
      withCheckbox: "通过首次使用'忘记密码'功能激活我的帐户，我在此接受",
      withoutCheckbox: "通过首次使用'忘记密码'功能激活您的帐户，您接受",
      tou: '使用条款',
      dse: '隐私政策',
      impressum: '法律声明',
    },
    responseHandler: {
      messageLabel: `未知错误。请联系 ${support_email}`,
      expiredLinkLabel: '链接已过期。申请更改新密码.',
      emailExistLabel: '抱歉，该电子邮件地址已注册。',
      profileUpdated: '简介已更新',
      profileUpdatedCorpsError: `简介已更新！以下内容 ${CorpsName('CIN').single} 由于登录代码不正确，无法添加：`,
      appNeedsUpdate: '请在 App Store / Google Play 更新应用程序。',
      maximalVideoSize: '最大文件大小为 2 GB！',
    },
    report: {
      notBelongLabel: '用户不再是会员。',
      incProfileLabel: '用户提供虚假个人资料',
      badPicLabel: '用户的个人资料图片违反了指导原则',
      badComLabel: '用户的发言违反了指导原则',
      spamLabel: '用户在聊天中发送垃圾邮件',
      reasonsLabel: '发出通知的原因',
    },
    drawer: {
      subscription: 'Subscription',
      news: `最新消息`,
      kreise: KreiseName('CIN').multi,
      search: '搜索',
      calendar: CalendarName('CIN'),
      documents: `${DocumentsName('CIN')}`,
      contacts: '联系方式',
      requests: '联系查询',
      messages: MessagesName('CIN'),
      profile: ProfileName('CIN'),
      logout: '退出登录',
      map: '地图',
      mentoring: '指导',
    },
    imageCropper: {
      cropLabel: '农作物',
      cancelLabel: '取消',
      chooseLabel: '准备就绪',
    },
    signIn: {
      register: '注册',
      title: '请登录',
      loginPlaceholder: '电子邮件地址',
      passwordPlaceholder: '暗号',
      forgotPassword: '忘记密码？',
      loginBtn: '登录',
      noAccount: '尚无账户?',
      description0: '如有任何问题，请联系',
      description1: '或',
      description2: '转捩.',
      loginValidation: '您的电子邮件地址不正确',
      passwordValidation: '密码长度必须至少为 6 个字符。',
    },
    search: {
      name: '名称',
      corps: CorpsName('CIN').single,
      country: CountryName('CIN'),
      city: '城市',
      industry: IndustryName('CIN'),
      company: CompanyName('CIN'),
      profession: '专业',
      hobby: '兴趣爱好',
      cultural: '文化赞助商',
      study: `${StudyName('CIN').short}`,
      office: '分支机构',
      degreeProgram: StudyName('CIN').studiengang,
      university: StudyName('CIN').university,
      position: `${FunctionName('CIN')}`,
      status: '现状',
      title: '搜索结果',
      filterTitle: '筛选方式',
      postCode: '邮编',
      global: '一切',
      activity: ActivityName('CIN'),
    },
    resetPassword: {
      enterEmail: '请输入您的电子邮件地址',
      inputPlaceholder: '电子邮件地址',
      sendBtn: '发送',
      cancelBtn: '取消',
      message: '如果我们知道电子邮件地址，就会向其发送链接。',
      desc: '您必须输入应用程序中存储的准确电子邮件地址。请检查您的电子邮件，看看是哪个。',
    },
    password: {
      resetLabel: '输入新密码',
      inputLabel: '新密码',
    },
    signUp: {
      createAccount: '创建账户',
      inputPlaceholder: `您的 ${CorpsName('CIN').single}s`,
      sendBtn: '发送',
      descFirst: '注册即表示您同意我们的条款和条件 ',
      descSecond: '并确认您已阅读我们的',
      descThird: `如有任何疑问，请随时通过 ${support_email} 或 +49 170 49 805 与我们联系。`,
      terms: '条款和条件。',
      policy: '隐私政策。.',
      description: '备注',
      generatePassword: '生成密码',
      noCodeSuccessRegister: '感谢您的注册！一旦激活，我们将通过电子邮件通知您',
    },
    signUpDefault: {
      withoutCode: '无代码',
      createAccount: '只需在此注册',
      withCode: '带邀请码',
      simpleRegister: '无邀请码',
      existingAccount: '已有账户？',
      descr01: '我同意',
      descr0: '注册即表示您同意',
      descr1: '和',
      descr2: '至.',
      guest: `注册为 ${guest_naming_singular}`,
      withCodeWithDescr: `注册为s ${member_naming_singular}`,
    },
    signUpManual: {
      title: '注册',
      photoDesc: '添加照片',
      genderInput: '性别',
      firstName: '姓名',
      lastName: '姓氏',
      club: CorpsName('CIN').single,
      specify: '请注明',
      emailPlaceholder: '电子邮件地址',
      emailHint: '请使用长期使用的邮件地址.',
      repeatEmailPlaceholder: '重复电子邮件地址',
      passwordPlaceholder: '设置密码',
      repeatPasswordPlaceholder: '重复密码',
      postcode: '邮编',
      place: '居住地',
      country: '国家',
      loginCode: '邀请码',
      mobilePlaceholder: '手机号码（在个人资料中不可见）',
      corps: SignUpCorpsName('CIN'),
      searchCountry: '搜索国家',
    },
    backToLogin: {
      desc: '已有账户？ ',
      login: '登录',
    },
    backToSignUp: {
      desc: '还没有账户？ ',
      register: '注册',
    },
    privacy: {
      desc: '是的，我同意',
      btn: '通用技术委员会',
      zu: '至',
      map: {
        title: '隐私通知',
        privacyNotice: `本地图的制作符合 GDPR 的规定。`,
        additionalInfo: {
          intro: '地图上的图钉仅',
          inaccurateDisplay: '根据邮政编码/城市不准确显示',
          locationBasis: ', 但没有街道/门牌号。',
          noConclusion: '这意味着无法就用户的具体地址',
          onAddresses: '得出结论',
          conclusion: '。',
        },
        agree: '我明白了',
        linkName: '隐私政策',
        options: {
          doNotShowAgain: '不再显示',
          doNotShowMeOnMap: '不要在地图上显示我 (即使大概)',
        },
      },
      restrictions: {
        overall: '该功能仅对以下成员开放.',
        overallWilkar: '出于数据保护原因，该功能不可用。',
        profile: '只有会员才能查看用户资料。不过，您可以给用户写私人信息.',
        groupchat: `"(《世界人权宣言》) ${KreiseName('CIN').single} 只有会员才能访问.`,
        groupchatWilkar: `出于数据保护的原因 ${KreiseName('CIN').single} 只有会员才能访问.`,
        comments: '只有会员才能看到评论.',
        commentsWilkar: '出于数据保护的原因，评论只能由会员查看。.',
        likes: '只有会员才能查看 "喜欢 "列表。',
        post: '只有会员才能撰写帖子.',
        participantsList: '与会者名单仅供会员查看。',
        commentsOnlyForMembers: '只有会员才能看到评论。',
        postsLimitation: '较早的帖子只能由会员查看。',
      },
      settings: {
        guestPost: {
          title: `已验证 ${guest_naming_plural} 可以看到我的帖子`,
          ask: '一定要问',
          no: '没有',
          yes: '是',
        },
      },
      guestModal: {
        title: `可以验证e ${guest_naming_plural} 看到这篇文章？您可以在之后更改每个帖子的可见性设置.`,
        dontAskAgain: '请勿再次询问（您也可以在 "个人资料"→"隐私 "下更改此设置）。',
      },
      post: {
        ifShown: `对于 ${guest_naming_plural} 隐藏`,
        ifHidden: `对于 ${guest_naming_plural} 展览`,
        ifShownModal: `捐款现可用于 ${guest_naming_plural} 不可见.`,
        ifHiddenModal: `捐款现可用于 ${guest_naming_plural} 看得见.`,
      },
    },
    searchModal: {
      landTitle: '选择国家',
      corpsTitle: `${CorpsName('CIN').single} 选择`,
      pleaseSelect: '请选择',
    },
    btn: {
      sendBtn: '发送',
      cancelBtn: '取消',
      select: '选择',
      cancelEventBtn: acceptEventName('CIN').cancel,
      acceptBtn: acceptEventName('CIN').accept,
      saveBtn: '节省',
      clearBtn: '删除',
      acceptReqBtn: '接受',
      declineReqBtn: '取消',
      updateBtn: '刷新',
      declineBtn: '拒绝',
      yesUnderstood: '是的，明白了',
      ok: '好的',
    },
    validation: {
      email: '您的电子邮件地址不正确',
      password: '密码长度至少为 6 个字符',
      repeatEmail: '电子邮件和电子邮件重复不匹配。',
      repeatPassword: '密码和密码重复不匹配',
      required: '该字段为必填字段',
      matchPasswords: '新旧密码匹配',
      dontMatchPasswords: '密码和密码重复不匹配。',
    },
    init: {
      title: '正在加载',
      overlayLabel: '请检查互联网连接',
    },
    screenTitles: {
      postsTitle: `最新消息`,
      circlesTitle: KreiseName('CIN').multi,
      searchTitle: '搜索',
      profileTitle: ProfileName('CIN'),
      eventTitle: CalendarName('CIN'),
      eventDetailsTitle: '活动',
      newEventTitle: '添加事件',
      contactsTitle: '联系方式',
      contactReqTitle: '联系查询',
      reqDetailsTitle: '联系查询',
      outReqTitle: '联系查询',
      messagesTitle: MessagesName('CIN'),
      changePasswordTitle: '更改密码',
      appInfoTitle: '关于',
      reportTitle: '报告用户',
      createKreiseTitle: '创建群组聊天',
      eventParticipants: '参与者',
    },
    profileDetails: {
      showFilters: '显示过滤器',
      user: '用户',
      mainTitleLabel: ProfileName('CIN'),
      basisLabel: '基地',
      clubLabel: CorpsName('CIN').single,
      clubLabelPlural: CorpsName('CIN').multi,
      professionLabel: '专业',
      studyLabel: StudyName('CIN').short,
      uploadPhotoLabel: '拍摄照片',
      editPhotoLabel: '编辑照片',
      profileCompletedLabel: '简介',
      profileCompletedGLabel: '完整',
      birthday: '出生日期',
      functionLabel: `${FunctionName('CIN')}`,
      dateOfJoinLabel: CorpsEditDate('CIN'),
      positionDescLabel: '专业',
      companyLabel: CompanyName('CIN'),
      nameLabel: '名称',
      websiteLabel: '网站',
      fromLabel: '来自',
      toLabel: '直到',
      delete: '删除',
      untilToday: '直到今天',
      degreeLabel: StudyName('CIN').abshchluss,
      miscellaneos: '杂项',
      hobbies: '兴趣爱好',
      cultural: '文化赞助商',

      positionLabel: '专业',
      firmaLabel: '公司名称',
      industryLabel: IndustryName('CIN'),
      areaLabel: '范围',
      typeOfEmplLabel: '就业类型',
      activityLabel: ActivityName('CIN'),
      careerStageLabel: '职业水平',
      activityDescLabel: '活动说明',
      employmentPeriodLabel: '期间',
      universityLabel: StudyName('CIN').hochsule,
      courseLabel: StudyName('CIN').studiengang,
      ageLabel: '年龄',
      birthdayLabel: '生日',
      editProfileLabel: '编辑个人资料',
      addContactLabel: '添加为联系人',
      sendMessageLabel: '发送信息',
      requestSentLabel: '已发送联系请求t',
      sendRequestSuccessLabel: '查询已成功发送',
      reportSentLabel: '用户已报告',
      addReportLabel: '报告/阻止用户',
      contactsLabel: '联系次数',
      showBirthday: '联系人看到我的生日',
      street: '街道 + 编号 ',
      houseLabel: '门牌号',
      houseNumberLabel: '门牌号',
      partner: '合作伙伴名称',
      mobileNumberLabel: '手机号码.',
      handphoneNumberLabel: '手机号码',
      telephoneNumberLabel: '电话号码',

      leibbursch: '兄弟会成员',
      leibfuechse: '兄弟会新成员',
      bio: '关于我',
      addDocuments: '为您添加文件',
      image: '图片',
      video: '视频',
      pdf: 'PDF',
      link: '链接',
      firstName: '名',
      lastName: '姓',
      title: '职称',
      email: 'E-Mail',
      name: '姓名',
      lastname: '姓氏',
      country: CountryName('CIN'),
      wohnort: '城市',
      postcode: '邮政编码',
      inReview: '邮件确认待处理',
    },
    calendar: {
      birthdayLabel: '的生日',
      noEventsLabel: '所选日期没有活动',
      allTabLabel: '全部',
      myTabLabel: '我的',
      clubsTabLabel: CorpsName('CIN').single,
      subsTabLabel: '订阅',
      subscribedTabLabel: '订阅者',
      otherTabLabel: '其他',

      error: '开始日期/时间必须在结束日期/时间之前。',
      filter: {
        all: '全部',
        invited: '特邀',
        confirmed: '承诺',
      },
    },
    eventLabels: {
      organizer: 'Organizer',
      registrationVia: '注册通过',
      maximalParticipants: '最大参加人数',
      participantsWarning: '参加人数有限',
      interested: '有关各方',
      notInterested: '无兴趣',
      removeFromWaitList: '从等待名单中删除',
      addEventTitleImage: '添加标题图片',
      changeEventTitleImage: '更改标题图片',
      inviteTo: '邀请',
      addEndDate: '输入结束日期/时间',
      eventLabel: '组织者',
      startLabel: '起价',
      locationLabel: '地点',
      participantLabel: '与会者',
      moreInfo: '更多信息',
      commentsLabel: '评论',
      noCommentsLabel: '尚无评论',
      eventStartLabel: '开始',
      eventEndLabel: '结束',
      descLabel: '说明',
      creatorLabel: '创建者',
      titleLabel: '标题',
      deleteEventTitle: '删除事件？',
      deleteEventLabel: '删除此事件？',
      clubsTitle: `${CorpsName('CIN').single}s`,
      gruppenchat: '选择群组聊天',
      contactsTitle: '联系方式',
      delCommentLabel: '删除评论?',
      sendInvite: '将邀请函寄往',
      selectCorps: '选择目标群体',
      externalGuest: '受邀外宾',
      externalGuestPlaceholder: '输入您的电子邮件地址',
      attachmentLabel: '附录',
      shareLinkLabel: '分享链接',
      linkLabel: `${guest_naming_singular} 走进`,
      copyLinkLabel: '复制链接',
      iCalDownload: '日历文件',
      timezone: '时区',
      visibility: '可见性',
      errorDate: '日期已过去',
      startAfterEndError: '结束日期必须晚于开始日期',
      members: '成员',
      waitlist: '等待列表',
      onWaitlist: '在等待列表中',
      leaveWaitlist: '离开等待列表？',
      leaveWaitlistDesc: '从等待列表中删除？\n\n 如果你稍后再次加入等待列表，你可能会被放在列表的最后。',
      limitAchieved: (limit: number) => `已达到最大参与者人数 ${limit}。您已被列入等候名单。`,
      visibilityOptions: {
        allWithoutGuests: '成员和网站',
        invited_member: '仅限受邀成员',
        verified_member: '所有成员',
        authorized_member: '所有成员和来宾',
        all: '会员、来宾和网站',
      },
      modalEvent: {
        title: '创建的活动',
        subTitle: '活动应运而生。\n\n',
        content:
          '根据用户设置，现在将发送事件的推送通知/电子邮件通知。如果您在 "个人资料"→"通知 "下进行了设置，您自己也会收到有关活动的电子邮件通知。',
      },
      modalComment: {
        title: '通知谁？',
        message: '您的意见应告知谁？',
        optionAll: '所有与会者',
        optionNone: '仅主办单位',
        button: '发送评论',
      },
      cancelModal: {
        title: '取消还是删除？',
        message: '取消或删除事件？',
        optionAll: '取消（将通知与会者）',
        optionNone: '删除（参与者不知情）',
      },
      updateEventModal: {
        title: '发出更多邀请',
        label: '邀请其他小组.',
        message: '根据设置，有关事件的推送通知或电子邮件通知现在会发送到其他组。',
      },
      eventRegistrationType: {
        app: '应用',
        event: '链接/电子邮件地址',
        enterLink: '添加链接/电子邮件地址或类似内容',
      },
      parts: {
        part: '部分',
        addPart: '添加程序点',
        hint: '解释',
        question: '问题',
        single: '仅可选择一个选项',
        multi: '多个选项可用',
        freeText: '自由文本回答',
        quiz: '测验',
        questionnaire: '问卷',
        addQuestionnaire: '添加问卷',
        questionnaireType: '回答选项',
        option: '选项',
        addOption: '添加选项',
        showMembers: '参与者',
        youJoined: '您参与了:',
        totalToPay: '总支付金额:',
        yourAnswer: '您的回答',
        visibleForCreators: '仅对创建者可见',
        allAnswers: '所有答案:',
        addUsers: '添加用户',
        isAnonym: '匿名投票',
        isAnonymQuiz: '匿名问卷',
      },
    },
    rrEventLabels: {
      title: '重复',
      MO: '星期一',
      TU: '星期二',
      WE: '星期三',
      TH: '星期四',
      FR: '星期五',
      SA: '星期六',
      SU: '星期日',
      YEARLY: { single: '年份', regular: '每年' },
      MONTHLY: { single: '月', regular: '每月' },
      WEEKLY: { single: '周', regular: '周' },
      DAILY: { single: '日', regular: '日' },
      SINGLE: { single: '独特性', regular: '独特性' },
      CUSTOM: {
        single: '定制...',
        regular: '定制...',
      },
      freq: '频率',
      interval: '间隔',
      notRepeat: '独特性',
      every: '每一个',
      month: '月',
      week: '周',
      last: '最后一个',
      on: '关于',
      everyMonthOn: '每月在',
      ofTheMonth: '每月',
      editLabel: '您想更改哪些日期',
      thisDate: '此日期仅限',
      thisDateAndFollowing: '本文件及其后所有',
      allDates: '所有日期',
      after: '之后',
      time: '日期',
      times: '日期',
      neverEnds: '永无止境',
      until: '直到',
      repeatedDefault: ({ frequency, freq }: { frequency: string; freq: number }) =>
        `是否每个${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency} 连串`,
      repeatedMonthly: ({ day, interval }: { day: string; interval: number }) =>
        `将每${interval == 1 ? '' : ` ${interval}.`} 月 ${day.length > 2 ? day : `${day}.`} 连串.`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }: { day: string; interval: number; bysetpos: number }) =>
        `将每${bysetpos == -1 ? ' 最后一个' : ` ${bysetpos}.`} ${day} 的${
          interval === 1 ? '' : ` ${interval}.`
        } 重复月份.`,

      repeatedYearly: ({ interval }: { interval: number }) =>
        `如果每个${interval == 1 ? '' : ` ${interval}.`} 重复年份.`,
      repeatedWeekly: ({ interval, byweekday }: { interval: number; byweekday: any }) =>
        `是否每个${interval != 1 ? ` ${interval}.` : ''} 周${
          !!byweekday?.length
            ? ' 关于 ' +
              byweekday?.slice(0, -1).join(', ') +
              (byweekday?.length > 1 ? ' 和 ' : '') +
              byweekday?.slice(-1)
            : ''
        } 连串.`,
      repeatedDaily: ({ interval }: { interval: number }) => `将每${interval != 1 ? ` ${interval}.` : ''} 日复一日.`,
    },
    requestsLabels: {
      receiveLabel: '接收',
      sentLabel: '发送',
    },
    post: {
      adTitle: '信息/显示屏',
      toKreise: '关于',
      comment: '评论',
      myPost: '我',
      openPdf: '点击打开',
      deletePost: '删除本帖？',
      posted: '已发布',
      commented: '有评论',
      contacts: '联系方式',
      sendToContacts: '给所有人的信息',
      enterText: '在此留言',
      sendToCircle: `留言至 ${KreiseName('CIN').singleArticle}`,
      sendComment: '输入评论',
      longMessage: '最多 20,000 个字符',
      noComments: '尚无评论',
      deleteComment: '删除评论？',
      deleteEventPost: '删除这个帖子？这不会删除事件。',
      editPostLabel: '编辑职位',
      editingPostLabel: '编辑模式',
      deletePostLabel: '删除帖子',
      editedAt: '已处理',
      pinned: '已钉.',
      actions: {
        label: '职位行动',
        pin: {
          label: '针柱',
          once: '向上移动一次',
          d3: '3 天',
          d5: '5 天',
          w1: '1 周',
          w2: '2 周',
          unpin: '不要再用别针固定',
        },
      },
    },
    circles: {
      circlesTitle: `订阅者 ${KreiseName('CIN').multi}`,
      noCirclesTitle: `未订阅的${KreiseName('CIN').multi}`,
      myPost: '我',
      circlesInfoTitle: '信息与参与者',
      circlesSubscribe: '订阅',
      unsubscribeAlert: '不可撤销地离开封闭群聊？',
      continueAlert: '放弃',
      readOnlyKreise: `这是一个 "只读-${
        KreiseName('CIN').single
      }". 只有管理员才能在此创建帖子。不过，您可以在帖子上写评论。`,
    },
    messages: {
      addMessageLabel: '输入信息',
      changesSaved: '已保存更改！',
      newConversation: '新对话',
    },
    profileTabs: {
      basicTab: '基地',
      clubsTab: `${CorpsName('CIN').single}`,
      miscellaneosTab: '兴趣爱好',
      professionTab: '专业',
      studyTab: StudyName('CIN')?.short,
      notificationsTab: '通知',
      privacy: '隐私权',
    },
    profile: {
      addPhotoLabel: '添加照片',
      genderInputLabel: '性别',
      titleLabel: '标题',
      firstNameLabel: '姓名',
      lastNameLabel: '姓氏',

      passwordLabel: '设置密码',
      birthDateLabel: '出生日期',
      birthday: '出生日期',
      changePasswordLabel: '更改密码',
      defaultCountryLabel: '德国',
      defaultGraduationLabel: '未说明',
      defaultCorpsLabel: '无',
      street: '街道 + 编号. ',
      houseNumberLabel: '门牌号',
      partner: '合作伙伴名称',
      mobileNumberLabel: '手机号码.',
      handphoneNumberLabel: '手机号码',
      telephoneNumberLabel: '电话号码',
    },
    profileKorp: {
      verband: CorpsName('CIN').single,
      function: FunctionName('CIN'),
      dateOfJoin: CorpsEditDate('CIN'),
      beername: '啤酒名称',
    },
    profileClubs: {
      noUserLabel: '在使用应用程序之前，必须先填写所有基本信息。',
      functionLabel: `${FunctionName('CIN')}`,
      setClubLabel: `请输入 ${CorpsName('CIN').single} 具体说明.`,
      matchClubLabel: '"《世界人权宣言》',
      matchClubContinueLabel: '已存在于个人资料中，请检查一次.',
      clubLabel: `${CorpsName('CIN').single.toUpperCase()}`,
      clubCodeLabel: `您的 ${CorpsName('CIN').single.toUpperCase()}S`,
      entryTimeLabel: CorpsEditDate('CIN'),
      deleteClubLabel: '您是否愿意延长您在',
      deleteClubContinueLabel: '真的删除？',
      selectVariant: '你想做什么？',
      requestExistingCorp: '添加现有条目',
      requestNewCorps: '申请新条目',
      createCorp: '创建新条目',
    },
    profileProfession: {
      jobTitle: '职位名称',
      noUserLabel: '在使用应用程序之前，必须先填写所有基本信息。',
      untilToday: '直到今天',
      positionTitleLabel: '专业',
      itemNameLabel: '专业',
      employmentLabel: '就业类型',
      activityLabel: ActivityName('CIN'),
      careerStageLabel: '职业水平',
      activityDescLabel: '活动说明',
      companyLabel: CompanyName('CIN'),
      nameLabel: '名称',
      industryLabel: IndustryName('CIN'),
      areaLabel: '范围',
      websiteLabel: '公司网站',
      employmentPeriodLabel: '就业期限',
      fromLabel: '来自',
      toLabel: '直到',
    },
    profileStudy: {
      noUserLabel: '在使用应用程序之前，必须先填写所有基本信息',
      universityLabel: StudyName('CIN').hochsule,
      courseLabel: StudyName('CIN').studiengang,
      degreeLabel: StudyName('CIN').abshchluss,
    },
    profileOther: {
      noUserLabel: '在使用应用程序之前，必须先填写所有基本信息。',
      hobbiesLabel: '兴趣爱好',
      culturalLabel: '文化赞助商',
      socialMediaPlattform: '社交媒体平台',
      socialMediaLabel: '社交媒体链接',
      socialMediaLink: '链接',
      socialMediaError: '请输入完整的 URL',
    },
    profileNotifications: {
      noUserLabel: '在使用应用程序之前，必须先填写所有基本信息。',
      notificationsInfo: '在这里，您可以激活单个活动的电子邮件通知（默认）或在 3 个月内停用。',
      notificationsTitle: '推送通知：',
      notificationsDescr0: '您可以在 ',
      notificationsDescr1: '智能手机系统设置',
      notificationsDescr2: ' 生产.',
      mailingTitle: '电子邮件通知：',
      mailingDescr: '在这里，您可以激活单个活动的电子邮件通知（默认），也可以在 1 年内停用',
      allNotificationsLabel: '所有通知',
      postsLabel: `在 ${KreiseName('CIN').multi}`,
      messagesLabel: '私人信息',
      commentsLabel: '对自己贡献的评论',
      contactRequestsLabel: '联系查询',
      eventsLabel: '活动',
      clubMemberLabel: '新注册会员',
      enableLabel: '至',
      disableToLabel: '关闭，直至',
      disableLabel: '来自',
      ownPosts: '我的捐款（我自己也收到的邮件）',
    },
    searchInput: {
      title: '搜索',
      placeholder: '搜索',
    },
    emptyList: {
      title: '搜索',
    },
    switchLanguage: {
      placeholder: '语言/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'Russian (Русский)',
      SP: 'Spanish (Español)',
      NLD: 'Dutch (Nederlands)',
      POL: 'Polish (Polish)',
      title: '选择语言',
    },
    filePicker: {
      takePicLabel: '附件',
      uploadPicLabel: '拍摄照片',
      selectPicLabel: '选择照片/视频',
      selectDocLabel: '选择文件',
    },
    datePicker: {
      selectDateLabel: '选择日期',
      confirmDateLabel: '选择日期',
      selectTimeLabel: '选择时间',
    },
    changePassword: {
      currentPasswordLabel: '当前密码',
      newPasswordLabel: '新密码',
      repeatPasswordLabel: '重复密码',
      changePasswordLabel: '更改密码',
    },
    appInfo: {
      appDescLabel:
        'Korponnect 是专为兄弟会学生设计的应用程序。企业间的交流、活动的组织以及专业和私人网络--一切尽在一个应用程序中。',
      contactsLabel: InfoContactsName('CIN'),
      infoLabel: '相关信息',
      versionLabel: '应用程序版本',
      agbLabel: '通用技术委员会',
      dataProtectionLabel: '隐私政策',
      manualLabel: '说明',
      appTextLabel: '在这里，您可以找到我们的',
      appAndLabel: '和一个',
      appMainTextLabel: `您想添加一个圈子或有其他建议？您想删除您的个人资料吗？只需发送简短电子邮件至 ${support_email}。`,
      deleteFeedback: '删除原因/反馈 🙂:',
      deleteUserTitle: '删除账户？',
      deleteUserDesc: '顺便说一句：您也可以直接关闭通知。请在 "个人资料"->"通知 "下编辑设置，或给我们发送电子邮件。',
      deleteUserAlert: '您真的想删除您的账户吗？',
      webLabel: '网络版',
    },
    infoMessages: {
      registartionSuccessLabel: '感谢您的注册！',
      manualRegSuccessLabel: '感谢您的注册。您将很快被激活并收到电子邮件通知',
      linkExpiredLabel: '链接已过期。请求更改新密码。',
      emailExistLabel: '抱歉，电子邮件无法使用。',
    },
    logoutAlert: {
      title: '退出登录',
      text: '您真的想注销吗？您不必注销，只需关闭或最小化应用程序即可。',
      confirm: '是',
      dismiss: '取消',
    },
    invalidInputs: {
      passwordChars: `密码必须至少为 ${passwordDigits('CIN')} 字符，包括至少一个字母、一个数字和一个特殊字符。`,
      fieldRequired: '该字段为必填字段。',
      emailsNotMatching: '请确保您的电子邮件与以下内容一致',
      emailInvalid: '您的电子邮件地址不正确！',
      selectCorrectAnswer: 'You need to select correct answer',
      corp: '请至少选择一个参赛项目',
    },
    mentoring: {
      profile: {
        rules1: '我同意',
        rules2: ' 对价值观的理解 ',
        rules3: '“为奖学金获得者提供指导"。',
        menteeDescription:
          '请选择您的指导地区，以便了解现场活动。注意：您可以随时更改您的选择。这不会限制您的导师搜索。',
        mentorDescription:
          '请填写您的导师简介，以便奖学金获得者找到您作为导师。注：至少需要 1 个地区和至少 1 个学科领域.',
        mentoringRegions: '指导地区',
        reigonsShort: '地区',
        topics: '学科领域',
        departments: '部门',
        available: '我可以',
        unavailable: '我目前没有时间',
        deleteAccount: '删除导师档案并离开小组',
        deleteAccountDescr: '删除指导简介并离开指导群聊？',
        uploadPdf: '导师简介 (PDF)',
        myPdf: '我的简介 (PDF)',
        pdf: '导师简介',
      },
      tabNames: {
        info: '信息',
        news: '新闻',
        profile: '新闻',
        search: '搜索与查询e',
        request: '导师',
        requestDetails: '辅导咨询',
        tabDeactivated: '标签已停用。请填写您的导师简介',
      },
      search: {
        description:
          '为了有针对性地开始搜索，我们建议您从几个选择字段开始，然后在新的搜索运行中扩大搜索网格。如果在您所在的地区找不到合适的导师，请将搜索范围扩大到邻近的导师地区。搜索标准：1 个地区，1 至 3 个主题领域。所有字段都是 AND 链接！',
        searchBtn: '搜索',
      },
      requests: {
        mentorIncoming: '我的指导咨询',
        mentorCurrent: '我的导师',
        menteeOutgoing: '我的辅导咨询',
        menteeCurrent: '我的导师',
        cancelMentoring: '结束辅导',
        sendRequest: '请求担任导师',
        alreadyHaveMentor: '你只能有一位导师',
        requestSuccess: '查询已成功发送',
        requestRetracted: '成功撤回查询',
        cancelSendingRequest: '撤回指导申请',
        approveRequest: '接受查询',
        rejectRequest: '拒绝请求',
        invitaionText: '查询',
        rejectionText: '说明拒绝的理由',
        sureRetractRequest: '你真的想撤回调查吗？',
      },
    },
    downloads: {
      root: '主目录',
      delete: '删除',
      rename: '重新命名',
      areYouSureDeleteFolder: '真的要删除文件夹？所有内容都会被删除！',
      renameFolder: '重命名文件夹',
      createNewFolder: '创建新文件夹',
      newFolderPlaceholder: '输入文件夹名称',
      create: '创建',
      areYouSureDeleteFile: '',
      downloadFile: '下载',
      deleteFile: '从设备中删除',
      openInFinder: '在文件中打开',
      cancel: '取消',
    },
    globalSearch: {
      postedBy: '由',
      found: '取消',
      loadMore: '取消',
      enterQuery: '已找到',
      folder: '加载更多 [...] ',
      post: '捐款',
      error: '输入至少 3 个字符',
    },
    kreiseSettings: {
      description: '说明',
      push: '推送通知',
      email: '邮件通知',
      subscribers: '成员',
      active: '已激活',
      oneYear: '静默 1 年',
      forever: '永远沉默',
      until: '直到',
      cantDeactive: '此群组聊天停用此设置',
      SINGLE: '此群组聊天停用此设置',
      SUMMARY_ONCE: '此群组聊天停用此设置',
      SUMMARY_TWICE: '每周 2 次总结',
      SUMMARY_DAY: '摘要 每天 1 次',
      OFF: '来自',
      PUSH: '推送通知',
      EMAIL: '邮件通知',
      PUSH_AND_EMAIL: '推送和电子邮件通知',
      newUser: '新用户通知',
      statistic: '传输统计',
      off: '来自',
      ownPosts: '发送捐款统计邮件',
      deleteMemberDescr: '移除?',
      deleteMemberBtn: '移除',
      retractAdminRights: '移除',
      deleteAdminAndUser: '移除',
      toDeleteKreiseBtn: '完全移除',
      deleteKreiseDescr: `${KreiseName('CIN').single} 不可撤销地删除？`,
      deleteKreiseModalTitle: '不可撤销地删除？',
      addKreiseName: '名称',
      addKreiseDescription: '说明',
      addMembers: '说明',
      createKreise: '删除成员？',
      toKreiseEditMessage: '删除成员？',
      kreiseCreator: '删除成员？',
      kreiseDeleteMembersLeft: '注意：对成员的所有更改都将立即执行。',
      kreiseDeleteMembersLeftTitle: '先删除成员',
      suggestionInNewsfeed: {
        admin: '管理员',
        member: '成员',
        corp: `${CorpsName('CIN').single} 成员`,
        corpAdmin: `${CorpsName('CIN').single} 管理`,
      },
    },
    surveys: {
      checkVotes: '显示结果',
      chooseOptions: {
        single: '选择一个选项.',
        multiple: '至少选择一个选项。',
      },
      votesModalTitle: '成果',
      createModalTitle: '调查',
      noVotes: '没有投票',
      question: '问题',
      askAQuestion: '提问',
      allowMultiple: '允许多个答案',
      votes: {
        single: '声音',
        multiple: '声音',
      },
      option: '选项',
      optionsLabel: '选项',
      anonymus: 'Anonymus poll',
      create: '创建',
    },
    subscription: {
      subscription: 'Abonnements',
      pricePerMonth: 'EUR/Monat',
      month: 'Monat',
      day: 'Tag',
      otherPlans: 'Andere Tarife',
      subscriptionActive: 'Abonnement aktiv',
      until: 'bis',
      subscribe: 'Abonnieren',
      successfulPayment: {
        title: 'Zahlung erfolgreich',
        text: 'Dein Abonnement wurde erfolgreich abgeschlossen. Du kannst ab sofort die erweiterten Funktionen nutzen.',
      },
      cancel: {
        cancel: 'Abonnement kündigen',
        title: 'Abonnement kündigen?',
        text: 'Möchtest du dein Abonnement kündigen? Nach Ablauf der bezahlten Periode werden deine Zugriffsrechte zurückgestuft.',
      },
    },
  },
  {
    key: 'POL', //polish /polnish language / polnisch
    key2: 'pl',
    editProfile: {
      updated: 'Profil zaktualizowany.',
      validateFieldsViaEmail:
        'Proszę zweryfikować poniższe dane za pomocą e-maila. Dopiero wtedy zostaną one przesłane do centralnego zarządzania członkami.',
      newValue: (value: any) => `Nowa wartość: "${value}"`,
      resendEmail: 'A może powinniśmy wysłać ponownie e-mail weryfikacyjny?',
      validationPending: 'Weryfikacja oczekuje',
      resend: 'Wyślij ponownie',
      resendDone: 'E-mail weryfikacyjny został wysłany ponownie. Proszę poczekać kilka minut i sprawdzić folder spam.',
      resendLong: 'A może wysłać ponownie e-mail weryfikacyjny?',
    },
    appInfoScreen: {
      title: 'O aplikacji',
      privacy: 'Informacje o prywatności',
      find: 'Tutaj możesz znaleźć',
      terms: ' Warunki użytkowania ',
      and: ' oraz ',
      infoSection: 'Informacje o aplikacji',
      hasUpdate: 'Dostępna jest nowsza wersja. Kliknij tutaj, aby zaktualizować.',
      noUpdate: 'To jest najnowsza wersja.',
      appVerison: 'Wersja aplikacji:',
    },
    corpsScreen: {
      website: 'Strona internetowa:',
      email: 'Adres e-mail:',
      members: 'Członkowie',
      admins: 'Administratorzy',
      info: 'Informacje',
      searchMember: 'Szukaj członków',
    },
    contactsScreen: {
      empty:
        "Nie ma jeszcze żadnych wpisów. Przejdź do głównego menu i wybierz 'Szukaj' → 'Imię' aby znaleźć nowe kontakty.",
    },
    dialogPanel: {
      hangOn: 'Dołączyć Bierjunge?',
      ok: 'OK',
    },
    dialogs: {
      emptyTitle: 'Kliknij plus w prawym górnym rogu, aby rozpocząć rozmowę.',
    },
    documentsGallery: {
      modalTitle: 'Usuń dokument',
      modalDesc: 'Czy na pewno chcesz usunąć ten plik?',
      cancel: 'Anuluj',
      delete: 'Usuń',
      deleteConfirm: 'Usunąć pobrany plik (tylko dla mnie)?',
      downloadConfirm: 'Pobrać ten plik?',
      availableInDownloads: `\n\n*Wszystkie pobrane pliki dostępne w folderze Pobrane`,
    },
    drawerScreen: {
      backdrop: 'Wszystkie podstawowe informacje muszą zostać uzupełnione przed użyciem aplikacji.',
    },
    kreiseInfoScreen: {
      creator: 'Twórca tego czatu grupowego',
      revokeAdmin: (name: string) =>
        `Czy chcesz usunąć prawa administratora dla "${name}" lub całkowicie usunąć go z grupy?`,
    },
    kreisePostScreen: {
      infoParticipants: (num: string | number) => `Informacje i uczestnicy (${num})`,
      subscribeTitle: 'Zasubskrybować czat grupowy?',
      subscribeDesc: 'Czy chcesz subskrybować ten czat grupowy, aby nie przegapić żadnych nowości?',
      yes: 'Tak',
      later: 'Może później',
      dontAsk: 'Nie pytaj o ten czat grupowy ponownie',
    },
    mapScreen: {
      appUser: 'Użytkownik aplikacji',
      viewIsForMember: 'Przeglądanie profili użytkowników dostępne jest tylko dla członków.',
      all: 'Wszyscy',
    },
    mentoringScreen: {
      clickMentor: 'Kliknij na imię mentora, aby wysłać przypomnienie lub wycofać prośbę',
      all: 'Wszystkie',
      startRequest: 'Rozpocznij prośbę:',
      startDesc:
        'Proszę jak najlepiej opisać swoją sprawę dotyczącą mentora, aby pomóc mentorowi lepiej ocenić Twoją prośbę i szybko odpowiedzieć.',
      notice: 'Uwaga:',
      noticeDesc:
        'Powinieneś poczekać do 2 tygodni na odpowiedź mentora przed wysłaniem przypomnienia. Wycofaj swoją bieżącą prośbę przed skontaktowaniem się z innym mentorem.',
      withdrawRequest: 'Wycofaj prośbę',
      reminder: 'Wyślij przypomnienie',
      reminderSuccess: 'Przypomnienie jest wysyłane (+ kopia do Ciebie)',
      rejectRequest: 'Odrzuć prośbę',
      requestTitle: 'Prośba o mentoring',
      areas: 'Obszary wiedzy',
      topics: 'Tematy',
      withReguards: (name: string, lastname: string) => `\n\n\n(...)\n\nZ pozdrowieniami,\n\n${name} ${lastname}`,
      rejectionMessage: (name: string, lastname: string) =>
        `Cześć!\n\nNiestety muszę odrzucić prośbę o mentoring. Chętnie wyjaśnię powody, jeśli zapytasz.\n\nZ pozdrowieniami,\n\n${name} ${lastname}`,
      maxMessage: (max: number | string, topic: string) => `Możesz wybrać maksymalnie ${max} ${topic}.`,
      decisionOpen: 'Decyzja: otwarta',
      maxMentoring: 'Maks. 1 prośba o mentoring',
      maxParalelMentoring: 'Maks. 1 równoczesna prośba o mentoring',
      openHere: 'Otwórz ',
      profileUpdated: 'Profil zaktualizowany!',
      mentoringProfile: 'Profil mentoringu',
      mentee: 'Mentee',
      mentor: 'Mentor',
      leaveMentoring: 'Opuszczenie grupy mentoringowej',
      mentoringTitle: 'Mentoring',
      scrollAuto: 'przykład automatycznych zakładek przewijanych',
      stopMentoringMessageMentor: (name: string, lastname: string) => `Zakończyć mentoring z ${name} ${lastname}?`,
      stopMentoringMessageMentee: (name: string, lastname: string) => `Zakończyć mentoring z ${name} ${lastname}?`,
      mentorEndedMentorship: (name: string, lastname: string) => `Mentor ${name} ${lastname} zakończył mentoring.`,
      mentorRejectedMentorship: (name: string, lastname: string) => `Mentor ${name} ${lastname} odrzucił Twoją prośbę.`,
      menteeEndedMentorship: (name: string, lastname: string) => `Mentee ${name} ${lastname} zakończył/a mentoring.`,
      menteeRejectedMentorship: (name: string, lastname: string) =>
        `Mentee ${name} ${lastname} odrzucił/a Twoją prośbę.`,
      mentorat: 'Mentoring',
    },
    newEventScreen: {
      kreiseError: 'Proszę wybrać grupę docelową',
      correctDate: 'Popraw datę/czas',
      correctDateMessage: 'Data/czas zakończenia przypada przed datą/czasem rozpoczęcia. Proszę poprawić.',
    },
    outRequestScreen: {
      backButton: 'Prośby o kontakt',
      requestMessage: (name: string, lastname: string) =>
        `Cześć!\n\nChciałbym/Chciałabym dodać Cię do moich kontaktów.\n\nPozdrawiam serdecznie\n\n${name} ${lastname}`,
    },
    participantsScreen: {
      interestedParties: 'Zainteresowane strony',
      removeGuestTitle: (name: string) => `Usunąć ${name}?`,
      removeGuestDesc: (name: string) => `Usunąć "${name}" z listy uczestników?`,
      informUser: (name: string) => `Poinformować "${name}" o usunięciu z listy uczestników`,
    },
    partParticipantsScreen: {
      interestedParties: 'Zainteresowane strony',
      removeParticipantTitle: (name: string, lastname: string) =>
        `Usunąć uczestnika "${name} ${lastname}" z listy uczestników?`,
      removeParticipantDesc: (name: string, lastname: string) => `Usunąć "${name} ${lastname}"?`,
    },
    documentsScreen: {
      deleteTitle: 'Wybrane pliki:',
      cancel: 'Anuluj',
      uploadData: 'Prześlij dane',
    },
    postsScreen: {
      ads: 'Ogłoszenia...',
      oldPostsForMembers: 'Starsze posty są widoczne tylko dla członków.',
    },
    profileBasisScreen: {
      editPhoto: 'Edytuj zdjęcie',
    },
    profileBenachrichtigungenScreen: {
      fillData: 'Wszystkie podstawowe informacje muszą zostać wypełnione, aby aplikacja mogła być używana.',
      changesInChat: 'Te ustawienia można zmienić w odpowiednim czacie grupowym.',
      commentsWarning1:
        'Wyłączenie powiadomień e-mail o komentarzach do własnych postów nie jest zalecane, ponieważ mogą umknąć ważne komentarze.',
      commentsWarning2:
        'Wyłączenie powiadomień e-mail o wiadomościach prywatnych nie jest zalecane, ponieważ mogą umknąć ważne wiadomości.',
      messagesWarning1:
        'Wyłączenie powiadomień e-mail o wiadomościach prywatnych nie jest zalecane, ponieważ otrzymujesz ich znacznie mniej niż postów z czatu grupowego. Są one jednak ważne, ponieważ zazwyczaj dotyczą konkretnych próśb skierowanych do Ciebie. Te (ważne) wiadomości prywatne mogą umknąć, jeśli powiadomienia zostaną wyłączone.',
      messagesWarning2:
        'Wyłączenie powiadomień e-mail o komentarzach do własnych postów nie jest zalecane, ponieważ mogą umknąć ważne komentarze.',
      areYouSure: 'Czy na pewno?',
      turnOff: 'Wyłącz',
      maybeLater: 'Może później',
      notRecommended: 'Niezalecane ustawienie',
      ok: 'OK',
    },
    profileBerufScreen: {
      fillData: 'Wszystkie podstawowe informacje muszą zostać wypełnione, aby aplikacja mogła być używana.',
      defaultDate: 'Do dzisiaj',
      noArea: 'Brak obszaru',
      furtherActivity: 'Dodatkowa działalność',
      deleteConfirm: 'Na pewno chcesz usunąć wpis?',
      ok: 'OK',
    },
    profileCorpsScreen: {
      corpsname: 'Nazwa projektu',
      createHint: 'Tworzony jest nowy wpis.',
      requestHint: 'Tworzenie nowego wpisu jest w trakcie wnioskowania.',
      status: 'Status',
      deleteConfirm: (name: string) => `Na pewno chcesz usunąć swoje członkostwo w ${name}?`,
      ok: 'OK',
      fillData: 'Wszystkie podstawowe informacje muszą zostać wypełnione, aby aplikacja mogła być używana.',
      duplicatedCorps: (name: string) => `Projekt ${name} jest już w profilu, proszę sprawdź raz jeszcze.`,
      missingCorpName: 'Proszę podać nazwę projektu.',
      waitingActivation: 'Oczekiwanie na aktywację',
      requestExistingMessage: (name: string) =>
        `Złożono wniosek o członkostwo w "${name}". Zostaniesz powiadomiony/a po aktywacji.`,
      requestNewMessage: (name: string) =>
        `Złożono wniosek o utworzenie "${name}". Zostaniesz powiadomiony/a po aktywacji.`,
      createMessage: (name: string) => `"${name}" zostało utworzone i dodane do Twojego profilu.`,
    },
    profileSonstigenScreen: {
      other: 'Inne (proszę wpisać)',
    },
    profileDetailsScreen: {
      email: 'E-mail',
    },
    profilePrivacyScreen: {
      fillData: 'Wszystkie podstawowe informacje muszą zostać wypełnione, aby aplikacja mogła być używana.',
    },
    profileStudiumScreen: {
      deleteConfirm: 'Na pewno chcesz usunąć wpis?',
      ok: 'OK',
      fillData: 'Wszystkie podstawowe informacje muszą zostać wypełnione, aby aplikacja mogła być używana.',
    },
    reportScreen: {
      reportUser: 'Zgłoś użytkownika',
      notMember: 'Użytkownik nie jest już członkiem',
      fakeProfile: 'Użytkownik tworzy fałszywe oferty profili.',
      violations1: 'Zdjęcie profilowe użytkownika narusza zasady.',
      violations2: 'Oświadczenia użytkownika naruszają zasady.',
      violations3: 'Użytkownik wysyła spam na czacie.',
      violations4: 'Zgłoś/oznacz potencjalnie obraźliwe treści',
      violations5: 'Zgłoś/oznacz innych użytkowników za możliwe naruszenia',
      reportReason: 'Powody zgłoszenia',
      sendReport: 'Wyślij',
      cancel: 'Anuluj',
      showContent: 'Pokaż treść tego użytkownika',
      hideContent: 'Ukryj treść tego użytkownika',
    },
    resetPasswordScreen: {
      incorrectEmail: 'Twój adres e-mail jest niepoprawny.',
    },
    serverIsDownScreen: {
      serverMaintenance: 'Konserwacja serwera',
    },
    signInScreen: {
      dontHaveAccount: 'Nie masz konta?',
    },
    signUpScreen: {
      login: 'Zaloguj się',
      emailMatchError: 'Adres e-mail i potwierdzenie e-maila się nie zgadzają.',
      passwordMatchError: 'Hasło i potwierdzenie hasła się nie zgadzają.',
      incorrectEmail: 'Twój adres e-mail jest niepoprawny!',
      pleaseSelect: 'Proszę wybrać',
      accountExistsTitle: 'Konto istnieje',
      accountExistsDesc: 'Masz już konto z tym adresem e-mail.',
      toLogin: 'Do logowania',
    },
    subscriptionsScreen: {
      subscribed: 'Subskrybowane:',
      noSubscribed: 'Nie znaleziono subskrybowanych korporacji',
      otherSubscriptions: 'Inni subskrybowani organizatorzy:',
      noOtherSubscriptions: `Nie znaleziono subskrybowanych organizatorów`,
    },
    sucheScreen: {
      searchFor: 'Wynik wyszukiwania dla:',
      filter: 'Filtr:',
      name: 'Imię',
      branch: 'Branża',
      function: 'Funkcja',
      status: 'Status',
      city: 'Miasto',
      industry: 'Przemysł',
      business: 'Biznes',
      profession: 'Zawód',
      training: 'Szkolenie',
      studyProgram: 'Program studiów',
      college: 'Uczelnia',
      cancel: 'Anuluj',
      searchBar: 'Pasek wyszukiwania',
      searchTab: 'Zakładka wyszukiwania',
      searchTitle: 'Wyszukiwanie',
      scrollable: 'Przykład automatycznych kart przewijanych',
    },
    dialogComponent: {
      close: 'Zamknij',
    },
    phoneInputComponent: {
      noCountry: 'Nie znaleziono kraju.',
    },
    cropImageHandlerComponent: {
      cancel: 'Anuluj',
      crop: 'Przytnij',
    },
    datePickerComponent: {
      cancel: 'Anuluj',
      ok: 'OK',
      date: 'Data/Czas',
      day: 'Dzień',
      month: 'Miesiąc',
      year: 'Rok',
      wrongDate: 'nieprawidłowa data',
    },
    timePickerComponent: {
      hours: 'Godziny',
      minutes: 'Minuty',
    },
    responseHandlerComponet: {
      supportEmail: 'Nieznany błąd. Proszę skontaktować się z ${support_email}',
    },
    searchModalComponent: {
      selectionError: 'Proszę wybrać przynajmniej jeden wpis.',
      maximumSelection: (num: string | number) => `Proszę wybrać maksymalnie ${num} pozycji!`,
    },
    customDropzoneComponent: {
      dropFiles: 'Upuść pliki tutaj',
      clickHere: ' lub kliknij tutaj, aby wybrać ',
    },
    documentsGalleryComponent: {
      clickToOpen: 'Kliknij, aby otworzyć',
      clickToDownload: 'Kliknij, aby pobrać',
    },
    eventToolbarComponent: {
      noRegistration: 'Brak rejestracji!',
      notLoggedId: 'Nie jesteś jeszcze zalogowany. To tylko wyrażenie zainteresowania.',
      registerVia: 'Rejestracja odbywa się za pośrednictwem:',
    },
    likeComponent: {
      externalGuest: 'Gość zewnętrzny',
      invitedBy: (name: string) => `Zaproszony przez ${name}`,
    },
    membersComponent: {
      interested: 'Wyrażono zainteresowanie',
      loggedIn: 'Zalogowany',
      um: ' w ',
      invitedBy: (name: string) => `Zaproszony przez ${name}`,
    },
    multipleSearchableInputComponent: {
      maximalMembers: `Proszę określić maksymalną liczbę ${maximal_membership_count} członkostw.`,
    },
    multipleSelectModalComponent: {
      all: 'Wszystko',
    },
    offlineModalComponent: {
      checkInternet: 'Proszę sprawdzić swoje połączenie internetowe.',
    },
    postsToolbarComponent: {
      membersOnly: 'Tylko członkowie mogą publikować.',
    },
    zusagenModalComponent: {
      incorrectEmail: 'Twój adres e-mail jest niepoprawny',
      required: 'To pole jest wymagane.',
      name: 'Imię',
      email: 'E-mail (opcjonalnie)',
      invitedBy: 'Zaproszony przez',
      save: 'Zapisz',
      cancel: 'Anuluj',
      warning1: `
			Uwaga: Proszę zarejestrować się tylko jako ${guest_naming_singular}! 
			Jeśli masz konto w aplikacji, proszę zalogować się na nie i oficjalnie potwierdzić członkostwo. Dziękujemy!`,
      warning2: 'Rejestrując się, zgadzasz się na ',
      and: ' i ',
      to: '.',
    },
    formInputComponent: {
      cancelConfirmation: 'Anulować potwierdzenie?',
    },
    logoComponent: {
      alt: 'Logo aplikacji',
    },
    customHistoryHook: {
      news: 'Wiadomość',
    },
    postContentComponent: {
      orginized: 'Organizator:',
      date: 'Data:',
      ort: 'Miejsce:',
    },
    beerSmileComponent: {
      bierjunge: 'Bierjunge!',
    },
    buttonComponent: {
      examination: 'Weryfikacja:',
    },
    global: {
      all: 'Wszystkie',
      postSubject: 'Temat Twojego wpisu',
      showMore: 'Czytaj więcej',
      toRegister: 'Do rejestracji',
      addToCalendar: 'iCal / ICS',
      interested: 'Zainteresowany',
      loggedIn: 'Zalogowany',
      tilToday: 'Do dzisiaj',
      status: 'Status',
      deletePost: 'Usuń post?',
      pdf: '(PDF)',
      time: 'Czas',
      birthday: (age: number | string, name: string, lastname: string) => `${age} urodziny ${name} ${lastname}`,
      eur: 'EUR',
      price: 'Cena:',
      priceForMe: 'Cena dla mnie:',
      ok: 'OK',
      likesCount: (num: string | number) => `Polubienia (${num})`,
      event: 'Wydarzenie',
      standardMessage: 'Generujemy temat dla twojego postu. Czy ten temat pasuje?',
      optionalMessage:
        'O czym jest twój post? Napisz krótki temat. Możesz również pozwolić AI wygenerować dla ciebie sugestię.',
      optionalMessageNote: 'Korzystając z tej funkcji, akceptujesz ',
      chatGPTTerms: 'Warunki korzystania z ChatGPT',
      chatGPTTermsLink: 'https://openai.com/policies/terms-of-use',
      optionalMessageNoteEnding: '.',
      postSubjectTitle: 'Temat postu',
      generate: 'Generuj',
      defaultSubject: 'Domyślny temat',
      memebership: 'Członkostwo',
      applyMemebership: 'Aplikować o członkostwo?',
      pendingMembership: 'Wniosek o członkostwo złożony - decyzja w toku.',
      requestAlreadySent: 'Wniosek już wysłany',
      requestedMemebership: (kreiseName: string) => `Złożono wniosek o członkostwo w "${kreiseName}".`,
      yes: 'Tak',
      no: 'Nie',
      change: 'Zmień',
      passwordOldMatchNewError: 'Stare i nowe hasło są takie same',
      passwordLenghtError: 'Hasło musi mieć co najmniej 6 znaków.',
      passwordsMatchError: 'Stare i nowe hasło są takie same',
      passwordsMatchError2: 'Hasło i potwierdzenie hasła nie pasują.',
      currentPassword: 'Aktualne hasło',
      newPassword: 'Nowe hasło',
      confirmPassword: 'Potwierdź hasło',
      changePassword: 'Zmień hasło',
      cancel: 'Anuluj',
      fits: 'Pasuje',
      selectChatTitle: 'Wybierz grupowy czat',
      selectChatDesc:
        "Wybierz przynajmniej 1 ulubiony czat, na który ma trafić twój post. Więcej znajdziesz w głównym menu w sekcji 'Grupowe czaty'.",
      socialMedia: 'Media społecznościowe',
      generatedText: 'Generujemy tekst do mediów społecznościowych. Czy ten tekst pasuje?',
      usePhoto:
        'Dziękujemy za twój post! Czy możemy również użyć twojego zdjęcia na kanałach mediów społecznościowych?',
      maximumLenght: 'Maksymalnie 20 000 znaków.',
      userSharedNewPicture: 'Użytkownik naszej aplikacji dodał nowe zdjęcie!',
      contributionTo: (kreiseName: string) => `Wkład do “${kreiseName || 'Wszystkich'}”`,
      postWarning1:
        'Śmiało! Będziesz miał jeszcze możliwość dodania własnego tekstu w następnym kroku. Dodatkowo twój post zostanie sprawdzony.',
      postWarning2: 'Po wysłaniu twój post zostanie sprawdzony przez administratora. W razie pytań, skontaktuj się z ',
      postWarning3: ' lub ',
      AGB: 'Warunki korzystania',
      DSE: 'Polityka prywatności',
      withCheckbox: 'Akceptuję niniejsze ',
      withoutCheckbox: 'Akceptujesz niniejsze ',
      tou: 'Warunki użytkowania',
      dse: 'Polityka prywatności',
      impressum: 'Informacje prawne',
    },
    responseHandler: {
      messageLabel: `Nieznany błąd. Prosimy o kontakt pod adresem ${support_email}`,
      expiredLinkLabel: 'Link wygasł. Poproś o zmianę hasła.',
      emailExistLabel: 'Przepraszamy, ten adres e-mail jest już zarejestrowany.',
      profileUpdated: 'Profil zaktualizowany',
      profileUpdatedCorpsError: `Profil zaktualizowany! Poniżej ${
        CorpsName('POL').single
      } nie mógł zostać dodany z powodu nieprawidłowego kodu logowania:`,
      appNeedsUpdate: 'Prosimy o aktualizację aplikacji w App Store / Google Play.',
      maximalVideoSize: 'Maksymalny rozmiar pliku to 2 GB',
    },
    report: {
      notBelongLabel: 'Użytkownik nie jest już członkiem.',
      incProfileLabel: 'Użytkownik składa fałszywe oferty profilowe.',
      badPicLabel: 'Zdjęcie profilowe użytkownika narusza wytyczne',
      badComLabel: 'Wypowiedzi użytkownika naruszają wytyczne',
      spamLabel: 'Użytkownik wysyła spam na czacie',
      reasonsLabel: 'Powody powiadomienia',
    },
    drawer: {
      subscription: 'Subscription',
      news: `Najnowsze wiadomości`,
      kreise: KreiseName('POL').multi,
      search: 'Wyszukiwanie',
      calendar: CalendarName('POL'),
      documents: `${DocumentsName('POL')}`,
      contacts: 'Kontakty',
      requests: 'Zapytania kontaktowe',
      messages: MessagesName('POL'),
      profile: ProfileName('POL'),
      logout: 'Wylogowanie',
      map: 'Mapa',
      mentoring: 'Mentoring',
    },
    imageCropper: {
      cropLabel: 'Uprawa',
      cancelLabel: 'Anuluj',
      chooseLabel: 'Gotowy',
    },
    signIn: {
      register: 'Rejestr',
      title: 'Zaloguj się',
      loginPlaceholder: 'Adres e-mail',
      passwordPlaceholder: 'hasło',
      forgotPassword: 'Zapomniałeś hasła?',
      loginBtn: 'Logowanie',
      noAccount: 'Nie masz jeszcze konta?',
      description0: 'W razie jakichkolwiek pytań prosimy o kontakt',
      description1: 'lub',
      description2: 'obrót.',
      loginValidation: 'Twój adres e-mail jest nieprawidłowy',
      passwordValidation: 'Hasło musi składać się z co najmniej 6 znaków',
    },
    search: {
      name: 'Nazwa',
      corps: CorpsName('POL').single,
      country: CountryName('POL'),
      city: 'Miasto',
      industry: IndustryName('POL'),
      company: CompanyName('POL'),
      profession: 'Zawód',
      hobby: 'Zainteresowania',
      cultural: 'Sponsorzy kultury',
      study: `${StudyName('POL').short}`,
      office: 'Oddział',
      degreeProgram: StudyName('POL').studiengang,
      university: StudyName('POL').university,
      position: `${FunctionName('POL')}`,
      status: 'Status',
      title: 'Wynik wyszukiwania dla',
      filterTitle: 'Filtruj według',
      postCode: 'Kod pocztowy',
      global: 'Wszystko',
      activity: ActivityName('POL'),
    },
    resetPassword: {
      enterEmail: 'Wprowadź swój adres e-mail',
      inputPlaceholder: 'Adres e-mail',
      sendBtn: 'Wyślij',
      cancelBtn: 'Anuluj',
      message: 'Anuluj',
      desc: 'Należy wprowadzić dokładny adres e-mail zapisany w aplikacji. Sprawdź swoje wiadomości e-mail, aby zobaczyć, który to adres',
    },
    password: {
      resetLabel: 'Wprowadź nowe hasło',
      inputLabel: 'Nowe hasło',
    },
    signUp: {
      createAccount: 'Utwórz konto',
      inputPlaceholder: `Kod logowania użytkownika ${CorpsName('POL').single}s`,
      sendBtn: 'Dalej',
      descFirst: 'Rejestrując się, wyrażasz zgodę na nasze zasady i warunki ',
      descSecond: 'i potwierdzić zapoznanie się z naszymi',
      descThird: `W razie jakichkolwiek pytań prosimy o kontakt pod adresem ${support_email} lub +49 170 49 805.`,
      terms: 'zasady i warunki.',
      policy: 'polityka prywatności.',
      description: 'Wskazówka',
      generatePassword: 'Generowanie hasła',
      noCodeSuccessRegister:
        'Dziękujemy za rejestrację! Zostaniesz poinformowany e-mailem, gdy tylko zostaniesz aktywowany.',
    },
    signUpDefault: {
      withoutCode: 'Bez kodu',
      createAccount: 'Wystarczy zarejestrować się tutaj',
      withCode: 'Z kodem zaproszenia',
      simpleRegister: 'Bez kodu zaproszenia',
      existingAccount: 'Masz już konto?',
      descr01: 'Zgadzam się z',
      descr0: 'Rejestrując się, użytkownik wyraża zgodę na',
      descr1: 'i',
      descr2: 'do.',
      guest: `Zarejestruj się jako ${guest_naming_singular}`,
      withCodeWithDescr: `Zarejestruj się jako ${member_naming_singular}`,
    },
    signUpManual: {
      title: 'Rejestr',
      photoDesc: 'Dodaj zdjęcie',
      genderInput: 'Płeć',
      firstName: 'Imię',
      lastName: 'Nazwisko',
      club: CorpsName('POL').single,
      specify: 'należy określić',
      emailPlaceholder: 'Adres e-mail',
      emailHint: 'Prosimy o używanie długoterminowego adresu e-mail.',
      repeatEmailPlaceholder: 'Powtórzony adres e-mail',
      passwordPlaceholder: 'Ustaw hasło',
      repeatPasswordPlaceholder: 'Powtórz hasło',
      postcode: 'Kod pocztowy',
      place: 'Miejsce zamieszkania',
      country: 'Kraj',
      loginCode: 'Kod zaproszenia',
      mobilePlaceholder: 'Numer telefonu komórkowego (niewidoczny w profilu)',
      corps: SignUpCorpsName('POL'),
      searchCountry: 'Suche nach Land',
    },
    backToLogin: {
      desc: 'Masz już konto? ',
      login: 'Logowanie',
    },
    backToSignUp: {
      desc: 'Nie masz jeszcze konta? ',
      register: 'Rejestr',
    },
    privacy: {
      desc: 'Tak, zgadzam się na',
      btn: 'GTC',
      zu: 'do',
      map: {
        title: 'Informacja o prywatności',
        privacyNotice: `Ta mapa została wdrożona zgodnie z RODO.`,
        additionalInfo: {
          intro: 'Piny na mapie są',
          inaccurateDisplay: 'wyświetlane tylko w przybliżeniu',
          locationBasis: ', na podstawie kodu pocztowego/miasta, ale bez ulicy/numeru.',
          noConclusion: 'Oznacza to, że nie można wyciągnąć żadnych wniosków',
          onAddresses: 'dotyczących konkretnych adresów',
          conclusion: 'użytkowników.',
        },
        agree: 'Zrozumiałem',
        linkName: 'Polityka prywatności',
        options: {
          doNotShowAgain: 'Nie pokazuj ponownie',
          doNotShowMeOnMap: 'Nie pokazuj mnie na mapie (nawet w przybliżeniu)',
        },
      },
      restrictions: {
        overall: 'Ta funkcja jest dostępna tylko dla członków.',
        overallWilkar: 'Ta funkcja jest niedostępna ze względu na ochronę danych.',
        profile:
          'Tylko członkowie mogą przeglądać profile użytkowników. Możesz jednak napisać prywatną wiadomość do użytkownika.',
        groupchat: `The ${KreiseName('POL').single} są dostępne tylko dla członków.`,
        groupchatWilkar: `Ze względu na ochronę danych ${KreiseName('POL').single} są dostępne tylko dla członków.`,
        comments: 'Komentarze są widoczne tylko dla członków.',
        commentsWilkar:
          'Ze względu na ochronę danych osobowych komentarze mogą być przeglądane wyłącznie przez członków.',
        likes: 'Listę polubień mogą przeglądać tylko członkowie.',
        post: 'Tylko członkowie mogą publikować artykuły.',
        participantsList: 'Lista uczestników może być przeglądana tylko przez członków.',
        commentsOnlyForMembers: 'Komentarze są widoczne tylko dla użytkowników.',
        postsLimitation: 'Starsze posty mogą być przeglądane tylko przez użytkowników.',
      },
      settings: {
        guestPost: {
          title: `Zweryfikowano ${guest_naming_plural} może zobaczyć moje posty`,
          ask: 'Zawsze pytaj',
          no: 'Nie',
          yes: 'Tak',
        },
      },
      guestModal: {
        title: `Maj zatwierdzony ${guest_naming_plural} zobaczyć ten post? Możesz zmienić to ustawienie widoczności dla każdego postu z osobna.`,
        dontAskAgain: 'Nie pytaj ponownie (możesz również zmienić to ustawienie w sekcji "Profil" → "Prywatność")..',
      },
      post: {
        ifShown: `Dla ${guest_naming_plural} ukrycie`,
        ifHidden: `Dla ${guest_naming_plural} pokaz`,
        ifShownModal: `Wkład jest teraz dostępny dla ${guest_naming_plural} nie jest już widoczny.`,
        ifHiddenModal: `Wkład jest teraz dostępny dla ${guest_naming_plural} widoczny.`,
      },
    },
    searchModal: {
      landTitle: 'Wybierz kraj',
      corpsTitle: `${CorpsName('POL').single} Wybierz`,
      pleaseSelect: 'Wybierz',
    },
    btn: {
      sendBtn: 'Wyślij',
      cancelBtn: 'Anuluj',
      select: 'Wybierz',
      cancelEventBtn: acceptEventName('POL').cancel,
      acceptBtn: acceptEventName('POL').accept,
      saveBtn: 'Zapisz',
      clearBtn: 'Usuń',
      acceptReqBtn: 'Akceptuj',
      declineReqBtn: 'Anuluj',
      updateBtn: 'Odświeżanie',
      declineBtn: 'Odrzucić',
      yesUnderstood: 'Ja, verstanden',

      ok: 'OK',
    },
    validation: {
      email: 'Twój adres e-mail jest nieprawidłowy',
      password: 'Hasło musi składać się z co najmniej 6 znaków',
      repeatEmail: 'E-mail i powtórzenie e-maila nie pasują do siebie.',
      repeatPassword: 'Hasło i powtórzenie hasła nie są zgodne',
      required: 'To pole jest wymagane',
      matchPasswords: 'Stare i nowe hasło pasują do siebie',
      dontMatchPasswords: 'Hasło i powtórzenie hasła nie są zgodne.',
    },
    init: {
      title: 'Ładowanie',
      overlayLabel: 'Sprawdź połączenie internetowe',
    },
    screenTitles: {
      postsTitle: `Najnowsze wiadomości`,
      circlesTitle: KreiseName('POL').multi,
      searchTitle: 'Wyszukiwanie',
      profileTitle: ProfileName('POL'),
      eventTitle: CalendarName('POL'),
      eventDetailsTitle: 'wydarzenia',
      newEventTitle: 'Dodaj zdarzenie',
      contactsTitle: 'Kontakty',
      contactReqTitle: 'Zapytania kontaktowe',
      reqDetailsTitle: 'Zapytania kontaktow',
      outReqTitle: 'Zapytania kontaktowe',
      messagesTitle: MessagesName('POL'),
      changePasswordTitle: 'Zmiana hasła',
      appInfoTitle: 'O',
      reportTitle: 'Zgłoś użytkownika',
      createKreiseTitle: 'Tworzenie czatu grupowego',
      eventParticipants: 'Teilnehmer',
    },
    profileDetails: {
      showFilters: 'Pokaż filtry',
      user: 'Użytkownicy',
      mainTitleLabel: ProfileName('POL'),
      basisLabel: 'Podstawa',
      clubLabel: CorpsName('POL').single,
      clubLabelPlural: CorpsName('POL').multi,
      professionLabel: 'Zawód',
      studyLabel: StudyName('POL').short,
      uploadPhotoLabel: 'Zrób zdjęcie',
      editPhotoLabel: 'Edytuj zdjęcie',
      profileCompletedLabel: 'Profil',
      profileCompletedGLabel: 'kompletny',
      birthday: 'Data urodzenia',
      functionLabel: `${FunctionName('POL')}`,
      dateOfJoinLabel: CorpsEditDate('POL'),
      positionDescLabel: 'Zawód',
      companyLabel: CompanyName('POL'),
      nameLabel: 'Nazwa',
      websiteLabel: 'Strona internetowa',
      fromLabel: 'Od',
      toLabel: 'Do',
      delete: 'Usuń',
      untilToday: 'Do dzisiaj',
      degreeLabel: StudyName('POL').abshchluss,
      miscellaneos: 'Różne',
      hobbies: 'Zainteresowania',
      cultural: 'Sponsorzy kultury',

      positionLabel: 'Zawód',
      firmaLabel: 'Firma',
      industryLabel: IndustryName('POL'),
      areaLabel: 'Zasięg',
      typeOfEmplLabel: 'Rodzaj zatrudnienia',
      activityLabel: ActivityName('POL'),
      careerStageLabel: 'Poziom kariery',
      activityDescLabel: 'Opis działania',
      employmentPeriodLabel: 'Okres',
      universityLabel: StudyName('POL').hochsule,
      courseLabel: StudyName('POL').studiengang,
      ageLabel: 'Wiek',
      birthdayLabel: 'Urodziny',
      editProfileLabel: 'Edytuj profil',
      addContactLabel: 'Dodaj jako kontakt',
      sendMessageLabel: 'Wyślij wiadomość',
      requestSentLabel: 'Prośba o kontakt została już wysłana',
      sendRequestSuccessLabel: 'Zapytanie wysłane pomyślnie',
      reportSentLabel: 'Użytkownik już zgłosił',
      addReportLabel: 'Zgłaszanie/blokowanie użytkownika',
      contactsLabel: 'Liczba kontaktów',
      showBirthday: 'Kontakty widzą moje urodziny',
      street: 'Ulica + Nie. ',
      houseLabel: 'Numer domu',
      houseNumberLabel: 'Numer domu',
      partner: 'Nazwa partnera',
      mobileNumberLabel: 'Nr telefonu komórkowego.',
      handphoneNumberLabel: 'Numer telefonu komórkowego',
      telephoneNumberLabel: 'Numer telefonu',

      leibbursch: 'Leibbursch',
      leibfuechse: 'Leibfüchse',
      bio: 'O mnie',
      addDocuments: 'Dodawanie dokumentów do użytkownika',
      image: 'Zdjęcie',
      video: 'Zdjęcie',
      pdf: 'PDF',
      link: 'Link',
      firstName: 'Imię',
      lastName: 'Nazwisko',
      title: 'Tytuł',
      email: 'E-Mail',
      name: 'Imię',
      lastname: 'Nazwisko',
      country: CountryName('POL'),
      wohnort: 'Miasto',
      postcode: 'KOD POCZTOWY',
      inReview: 'Confirmation via mail pending',
    },
    calendar: {
      birthdayLabel: 'Urodziny',
      noEventsLabel: 'Nie ma żadnych wydarzeń dla wybranej daty',
      allTabLabel: 'Wszystkie',
      myTabLabel: 'Mój',
      clubsTabLabel: CorpsName('POL').single,
      subsTabLabel: 'Subskrypcje',
      subscribedTabLabel: 'Subskrybenci',
      otherTabLabel: 'Inne',

      error: 'Data/godzina rozpoczęcia musi być wcześniejsza niż data/godzina zakończenia..',
      filter: {
        all: 'Wszystkie',
        invited: 'Zaproszeni',
        confirmed: 'Obiecane',
      },
    },
    eventLabels: {
      organizer: 'Organizer',
      registrationVia: 'Rejestracja poprzez',
      maximalParticipants: 'Maksymalna liczba uczestników',
      participantsWarning: 'Liczba uczestników jest ograniczona',
      interested: 'Zainteresowany',
      notInterested: 'Nie jestem zainteresowany',
      removeFromWaitList: 'Usuń z listy oczekujących',
      addEventTitleImage: 'Dodaj zdjęcie tytułowe',
      changeEventTitleImage: 'Zmień zdjęcie tytułowe',
      inviteTo: 'Zaproszenie do',
      addEndDate: 'Wprowadź datę/godzinę zakończenia',
      eventLabel: 'Organizator',
      startLabel: 'Zaczyna się od',
      locationLabel: 'Lokalizacja',
      participantLabel: 'Uczestnicy',
      moreInfo: 'Więcej informacji',
      commentsLabel: 'Komentarze',
      noCommentsLabel: 'Brak komentarzy',
      eventStartLabel: 'Start',
      eventEndLabel: 'Koniec',
      descLabel: 'Opis',
      creatorLabel: 'Stworzony przez',
      titleLabel: 'Tytuł',
      deleteEventTitle: 'Usunąć zdarzenie?',
      deleteEventLabel: 'Usunąć to wydarzenie?',
      clubsTitle: `${CorpsName('POL').single}s`,
      gruppenchat: 'Wybierz czat grupowy',
      contactsTitle: 'Kontakty',
      delCommentLabel: 'Usunąć komentarz?',
      sendInvite: 'Wyślij zaproszenie na adres',
      selectCorps: 'Wybór grupy docelowej',
      externalGuest: 'Zaproszeni goście zewnętrzni',
      externalGuestPlaceholder: 'Wprowadź swój adres e-mail',
      attachmentLabel: 'Dodatek',
      shareLinkLabel: 'Udostępnij link',
      linkLabel: `${guest_naming_singular} wejść`,
      copyLinkLabel: 'Kopiuj link',
      iCalDownload: 'Plik kalendarza',
      timezone: 'Strefa czasowa',
      visibility: 'Widoczność',
      errorDate: 'Data jest w przeszłości',
      startAfterEndError: 'Data końcowa musi być późniejsza niż początkowa',
      members: 'Członkowie',
      waitlist: 'Lista oczekujących',
      onWaitlist: 'na liście oczekujących',
      leaveWaitlist: 'Opuszczać listę oczekujących?',
      leaveWaitlistDesc:
        'Usunąć z listy oczekujących?\n\n Jeśli ponownie dołączysz do listy oczekujących później, możesz zostać umieszczony na końcu listy.',
      limitAchieved: (limit: number) =>
        `Osiągnięto maksymalną liczbę uczestników ${limit}. Zostałeś umieszczony na liście oczekujących.`,
      visibilityOptions: {
        allWithoutGuests: 'Członkowie i na stronie internetowej',
        invited_member: 'Tylko zaproszeni członkowie',
        verified_member: 'Wszyscy członkowie',
        authorized_member: 'Wszyscy członkowie i goście',
        all: 'Członkowie, goście i na stronie internetowej',
      },
      modalEvent: {
        title: 'Utworzono wydarzenie',
        subTitle: 'Wydarzenie zostało utworzone.\n\n',
        content:
          'W zależności od ustawień użytkownika, powiadomienia push/powiadomienia e-mail są teraz wysyłane dla wydarzenia. Jeśli ustawiłeś to w sekcji "Profil" → "Powiadomienia", otrzymasz również powiadomienie e-mail o wydarzeniu..',
      },
      modalComment: {
        title: 'Kogo powiadomić?',
        message: 'Kto powinien zostać poinformowany o Twoim komentarzu?',
        optionAll: 'Wszyscy uczestnicy',
        optionNone: 'Tylko organizatorzy',
        button: 'Wyślij komentarz',
      },
      cancelModal: {
        title: 'Anulować czy usunąć?',
        message: 'Anulować lub usunąć wydarzenie?',
        optionAll: 'Odwołania (uczestnicy zostaną poinformowani)',
        optionNone: 'Usuń (uczestnicy nie są informowani)',
      },
      updateEventModal: {
        title: 'Wysłano kolejne zaproszenia',
        label: 'Pozostałe grupy zostały zaproszone.',
        message:
          'W zależności od ustawień, powiadomienia push lub powiadomienia e-mail o wydarzeniu są teraz wysyłane do innych grup.',
      },
      eventRegistrationType: {
        app: 'Aplikacja',
        event: 'Link/Adres e-mail',
        enterLink: 'Dodaj link/adres e-mail lub podobny',
      },
      parts: {
        part: 'Część',
        addPart: 'Dodaj punkt programu',
        hint: 'Wyjaśnienie',
        question: 'Pytanie',
        single: 'Tylko jedna opcja do wyboru',
        multi: 'Dostępne kilka opcji',
        freeText: 'Odpowiedź w formie wolnego tekstu',
        quiz: 'Quiz',
        questionnaire: 'Kwestionariusz',
        addQuestionnaire: 'Dodaj kwestionariusz',
        questionnaireType: 'Opcje odpowiedzi',
        option: 'Opcja',
        addOption: 'Dodaj opcję',
        showMembers: 'Uczestnicy',
        youJoined: 'Bierzesz udział w:',
        totalToPay: 'Całkowita kwota do zapłaty:',
        yourAnswer: 'Twoja odpowiedź',
        visibleForCreators: 'Widoczne tylko dla twórców',
        allAnswers: 'Wszystkie odpowiedzi:',
        addUsers: 'Dodaj użytkowników',
        isAnonym: 'Anonimowe głosowanie',
        isAnonymQuiz: 'Anonimowy quiz',
      },
    },
    rrEventLabels: {
      title: 'Powtórzenie',
      MO: 'Poniedziałek',
      TU: 'Wtorek',
      WE: 'Środa',
      TH: 'Czwartek',
      FR: 'Piątek',
      SA: 'Sobota',
      SU: 'Niedziela',
      YEARLY: { single: 'Rok', regular: 'Rocznie' },
      MONTHLY: { single: 'miesiąc', regular: 'Miesięcznie' },
      WEEKLY: { single: 'tydzień', regular: 'Co tydzień' },
      DAILY: { single: 'Dzień', regular: 'Codziennie' },
      SINGLE: { single: 'Wyjątkowy', regular: 'Wyjątkowy' },
      CUSTOM: {
        single: 'Dostosowane...',
        regular: 'Dostosowane...',
      },
      freq: 'Częstotliwość',
      interval: 'Interwał',
      notRepeat: 'Einmalig',
      every: 'Każdy',
      month: 'miesiąc',
      week: 'tydzień',
      last: 'ostatni',
      on: 'na',
      everyMonthOn: 'Co miesiąc na',
      ofTheMonth: 'miesiąca',
      editLabel: 'Które daty chcesz zmienić?',
      thisDate: 'Tylko ta data',
      thisDateAndFollowing: 'Diesen und alle folgenden',
      allDates: 'Wszystkie daty',
      after: 'Po',
      time: 'Nominacje',
      times: 'Nominacje',
      neverEnds: 'Nigdy się nie kończy',
      until: 'Nigdy się nie kończy',
      repeatedDefault: ({ frequency, freq }: { frequency: string; freq: number }) =>
        `Czy każdy${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency} powtórzony`,
      repeatedMonthly: ({ day, interval }: { day: string; interval: number }) =>
        `Będzie każdy${interval == 1 ? '' : ` ${interval}.`} Miesiąc później ${
          day.length > 2 ? day : `${day}.`
        } powtórzony.`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }: { day: string; interval: number; bysetpos: number }) =>
        `Będzie każdy${bysetpos == -1 ? ' ostatni' : ` ${bysetpos}.`} ${day} des${
          interval === 1 ? '' : ` ${interval}.`
        } Powtarzający się miesiąc.`,

      repeatedYearly: ({ interval }: { interval: number }) =>
        `Powtarzający się miesiąc${interval == 1 ? '' : ` ${interval}.`} Rok powtórzony.`,
      repeatedWeekly: ({ interval, byweekday }: { interval: number; byweekday: any }) =>
        `Czy każdy${interval != 1 ? ` ${interval}.` : ''} tydzień${
          !!byweekday?.length
            ? ' na ' +
              byweekday?.slice(0, -1).join(', ') +
              (byweekday?.length > 1 ? ' oraz ' : '') +
              byweekday?.slice(-1)
            : ''
        } powtórzony.`,
      repeatedDaily: ({ interval }: { interval: number }) =>
        `Będzie każdy${interval != 1 ? ` ${interval}.` : ''} Będzie każdy.`,
    },
    requestsLabels: {
      receiveLabel: 'Odbiór',
      sentLabel: 'Wysłany',
    },
    post: {
      adTitle: 'Informacje/wyświetlacz',
      toKreise: 'na',
      comment: 'Komentarze',
      myPost: 'I',
      openPdf: 'Kliknij, aby otworzyć',
      deletePost: 'Usunąć ten post?',
      posted: 'Wysłany',
      commented: 'Komentarz',
      contacts: 'Kontakty',
      sendToContacts: 'Wiadomość dla wszystkich',
      enterText: 'Napisz wiadomość tutaj',
      sendToCircle: `Wiadomość do ${KreiseName('POL').singleArticle}`,
      sendComment: 'Wprowadź komentarz',
      longMessage: 'Maksymalnie 20 000 znaków',
      noComments: 'Brak komentarzy',
      deleteComment: 'Usuń komentarz?',
      deleteEventPost: 'Usunąć ten post? Nie spowoduje to usunięcia wydarzenia.',
      editPostLabel: 'Edytuj post',
      editingPostLabel: 'Tryb edycji',
      deletePostLabel: 'Usuń post',
      editedAt: 'Przetworzone',
      pinned: 'Przypięty.',
      actions: {
        label: 'Działania po',
        pin: {
          label: 'Pin post',
          once: 'Przesuń się raz w górę',
          d3: '3 dni',
          d5: '5 dni',
          w1: '1 tydzień',
          w2: '2 tygodnie',
          unpin: 'Nie przypinaj więcej',
        },
      },
    },
    circles: {
      circlesTitle: `Subskrybenci ${KreiseName('POL').multi}`,
      noCirclesTitle: `Nie subskrybuje ${KreiseName('POL').multi}`,
      myPost: 'Ja',
      circlesInfoTitle: 'Informacje i uczestnicy',
      circlesSubscribe: 'Subskrybuj',
      unsubscribeAlert: 'Bezpowrotnie opuścić zamknięty czat grupowy?',
      continueAlert: 'Porzucenie',
      readOnlyKreise: `Jest to "tylko do odczytu-${
        KreiseName('POL').single
      }". Tylko administratorzy mogą tworzyć tutaj posty. Można jednak pisać komentarze do postów.`,
    },
    messages: {
      addMessageLabel: 'Wprowadź wiadomość',
      changesSaved: 'Zmiany zapisane!',
      newConversation: 'New conversation',
    },
    profileTabs: {
      basicTab: 'Podstawa',
      clubsTab: `${CorpsName('POL').single}`,
      miscellaneosTab: 'Zainteresowania',
      professionTab: 'Zawód',
      studyTab: StudyName('POL')?.short,
      notificationsTab: 'Powiadomienia',
      privacy: 'Prywatność',
    },
    profile: {
      addPhotoLabel: 'Dodaj zdjęcie',
      genderInputLabel: 'Płeć',
      titleLabel: 'Tytuł',
      firstNameLabel: 'Imię',
      lastNameLabel: 'Nazwisko',

      passwordLabel: 'Ustaw hasło',
      birthDateLabel: 'Data urodzenia',
      birthday: 'Data urodzenia',
      changePasswordLabel: 'Ort des Wohnsitzes',
      defaultCountryLabel: 'Niemcy',
      defaultGraduationLabel: 'Nie określono',
      defaultCorpsLabel: 'brak',
      street: 'Ulica + nr. ',
      houseNumberLabel: 'Numer domu',
      partner: 'Nazwa partnera',
      mobileNumberLabel: 'Nr telefonu komórkowego.',
      handphoneNumberLabel: 'Numer telefonu komórkowego',
      telephoneNumberLabel: 'Numer telefonu',
    },
    profileKorp: {
      verband: CorpsName('POL').single,
      function: FunctionName('POL'),
      dateOfJoin: CorpsEditDate('POL'),
      beername: 'Nazwa piwa',
    },
    profileClubs: {
      noUserLabel: 'Przed rozpoczęciem korzystania z aplikacji należy podać wszystkie podstawowe informacje.',
      functionLabel: `${FunctionName('POL')}`,
      setClubLabel: `Wprowadź nazwę ${CorpsName('POL').single} określać.`,
      matchClubLabel: '',
      matchClubContinueLabel: 'już istnieje w profilu, sprawdź raz.',
      clubLabel: `${CorpsName('POL').single.toUpperCase()}`,
      clubCodeLabel: `KOD LOGOWANIA UŻYTKOWNIKA ${CorpsName('POL').single.toUpperCase()}S`,
      entryTimeLabel: CorpsEditDate('POL'),
      deleteClubLabel: 'Czy chciałbyś przedłużyć swoje członkostwo w',
      deleteClubContinueLabel: 'naprawdę usunąć?',
      selectVariant: 'Co chciałbyś robić??',
      requestExistingCorp: 'Dodaj istniejący wpis',
      requestNewCorps: 'Żądanie nowego wpisu',
      createCorp: 'Utwórz nowy wpis',
    },
    profileProfession: {
      jobTitle: 'Nazwa stanowiska',
      noUserLabel: 'Przed rozpoczęciem korzystania z aplikacji należy podać wszystkie podstawowe informacje.',
      untilToday: 'Do dzisiaj',
      positionTitleLabel: 'Zawód',
      itemNameLabel: 'Zawód',
      employmentLabel: 'Rodzaj zatrudnienia',
      activityLabel: ActivityName('POL'),
      careerStageLabel: 'Poziom kariery',
      activityDescLabel: 'Opis działania',
      companyLabel: CompanyName('POL'),
      nameLabel: 'Firma',
      industryLabel: IndustryName('POL'),
      areaLabel: 'Zasięg',
      websiteLabel: 'Strona internetowa firmy',
      employmentPeriodLabel: 'Okres zatrudnienia',
      fromLabel: 'Od',
      toLabel: 'Do',
    },
    profileStudy: {
      noUserLabel: 'Przed rozpoczęciem korzystania z aplikacji należy podać wszystkie podstawowe informacje..',
      universityLabel: StudyName('POL').hochsule,
      courseLabel: StudyName('POL').studiengang,
      degreeLabel: StudyName('POL').abshchluss,
    },
    profileOther: {
      noUserLabel: 'Przed rozpoczęciem korzystania z aplikacji należy podać wszystkie podstawowe informacje.',
      hobbiesLabel: 'Zainteresowania',
      culturalLabel: 'Sponsorzy kultury',
      socialMediaPlattform: 'Sponsorzy kultury',
      socialMediaLabel: 'Linki do mediów społecznościowych',
      socialMediaLink: 'Link',
      socialMediaError: 'Wprowadź pełny adres URL',
    },
    profileNotifications: {
      noUserLabel: 'Przed rozpoczęciem korzystania z aplikacji należy podać wszystkie podstawowe informacje.',
      notificationsInfo:
        'Tutaj możesz aktywować powiadomienia e-mail dla poszczególnych działań (domyślnie) lub dezaktywować je na 3 miesiące..',
      notificationsTitle: 'Powiadomienia push:',
      notificationsDescr0: 'Powiadomienia push można ustawić w aplikacji ',
      notificationsDescr1: 'Powiadomienia push można ustawić w aplikacji',
      notificationsDescr2: ' marka.',
      mailingTitle: 'Powiadomienia e-mail:',
      mailingDescr:
        'W tym miejscu można aktywować powiadomienia e-mail dla poszczególnych działań (domyślnie) lub dezaktywować je na 1 rok.',
      allNotificationsLabel: 'Wszystkie powiadomienia',
      postsLabel: `Wkład w ${KreiseName('POL').multi}`,
      messagesLabel: 'Prywatne wiadomości',
      commentsLabel: 'Komentarze dotyczące wkładu własnego',
      contactRequestsLabel: 'Zapytania kontaktowe',
      eventsLabel: 'wydarzenia',
      clubMemberLabel: 'Nowo zarejestrowani członkowie',
      enableLabel: 'Do',
      disableToLabel: 'Wyłączone do',
      disableLabel: 'Od',
      ownPosts: 'Mój wkład (poczta otrzymana również przeze mnie)',
    },
    searchInput: {
      title: 'Wyszukiwanie',
      placeholder: 'Wyszukiwanie',
    },
    emptyList: {
      title: 'Nie ma tu (jeszcze) żadnej zawartości',
    },
    switchLanguage: {
      placeholder: 'Język/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'Russian (Русский)',
      SP: 'Spanish (Español)',
      NLD: 'Dutch (Nederlands)',
      POL: 'Polish (Polish)',
      title: 'Wybierz język',
    },
    filePicker: {
      takePicLabel: 'Załączniki',
      uploadPicLabel: 'Zrób zdjęcie',
      selectPicLabel: 'Wybierz zdjęcie/wideo',
      selectDocLabel: 'Wybierz dokument',
    },
    datePicker: {
      selectDateLabel: 'Wybierz datę',
      confirmDateLabel: 'Potwierdzenie',
      selectTimeLabel: 'Zeit auswählen',
    },
    changePassword: {
      currentPasswordLabel: 'Aktualne hasło',
      newPasswordLabel: 'Neues Passwort',
      repeatPasswordLabel: 'Passwort wiederholen',
      changePasswordLabel: 'Passwort ändern',
    },
    appInfo: {
      appDescLabel:
        'Korponnect to aplikacja dla studentów zrzeszonych w bractwach. Wymiana między korporacjami, organizacja wydarzeń oraz profesjonalny i prywatny networking - wszystko w jednej aplikacji.',
      contactsLabel: InfoContactsName('POL'),
      infoLabel: 'Informacje na temat',
      versionLabel: 'Wersja aplikacji',
      agbLabel: 'GTC',
      dataProtectionLabel: 'Polityka prywatności',
      manualLabel: 'Instrukcje',
      appTextLabel: 'Tutaj znajdziesz nasze',
      appAndLabel: 'oraz',
      appMainTextLabel: `Chcesz dodać kółko lub masz inną sugestię? Chcesz usunąć swój profil? Po prostu wyślij nam krótką wiadomość e-mail na adres ${support_email}.`,
      deleteFeedback: 'Powód usunięcia / opinia 🙂:',
      deleteUserTitle: 'Usunąć konto?',
      deleteUserDesc:
        'Możesz nieodwracalnie usunąć swoje konto tutaj.\b Przy okazji: Możesz także po prostu wyłączyć powiadomienia. Edytuj ustawienia w sekcji Profil -> Powiadomienia lub wyślij nam wiadomość e-mail.',
      deleteUserAlert: 'Czy naprawdę chcesz usunąć swoje konto?',
      webLabel: 'Wersja internetowa',
    },
    infoMessages: {
      registartionSuccessLabel: 'Dziękujemy za rejestrację!',
      manualRegSuccessLabel: 'Dziękujemy za rejestrację. Zostaniesz wkrótce aktywowany i poinformowany e-mailem.',
      linkExpiredLabel: 'Link wygasł. Poproś o zmianę hasła.',
      emailExistLabel: 'Przepraszamy, wiadomość e-mail nie jest dostępna.',
    },
    logoutAlert: {
      title: 'Wylogowanie',
      text: 'Wylogowanie',
      confirm: 'Tak',
      dismiss: 'Anuluj',
    },
    invalidInputs: {
      passwordChars: `Hasło musi mieć co najmniej ${passwordDigits(
        'POL',
      )} znaków, w tym co najmniej jedną literę, jedną cyfrę i jeden znak specjalny.`,
      fieldRequired: 'To pole jest wymagane.',
      emailsNotMatching: 'Upewnij się, że twoje e-maile są zgodne',
      emailInvalid: 'Twój adres e-mail jest nieprawidłowy!',
      selectCorrectAnswer: 'You need to select correct answer',
      corp: 'Wybierz co najmniej 1 zgłoszenie',
    },
    mentoring: {
      profile: {
        rules1: 'Zgadzam się z',
        rules2: ' Zrozumienie wartości ',
        rules3: '“Mentoring dla stypendystów.',
        menteeDescription:
          'Wybierz swój region mentorski, aby otrzymywać informacje o wydarzeniach na miejscu. UWAGA: Możesz zmienić swój wybór w dowolnym momencie. NIE ogranicza to wyszukiwania mentora.',
        mentorDescription:
          'Wypełnij swój profil mentora, aby stypendyści mogli znaleźć Cię jako mentora. UWAGA: Wymagany jest co najmniej 1 region i co najmniej 1 obszar tematyczny..',
        mentoringRegions: 'Regiony mentorskie',
        reigonsShort: 'Regiony',
        topics: 'Obszary tematyczne',
        departments: 'Dział',
        available: 'Jestem dostępny',
        unavailable: 'Obecnie nie jestem dostępny',
        deleteAccount: 'Usuń profil mentora i opuść grupę',
        deleteAccountDescr: 'Usuń profil mentora i opuść grupę',
        uploadPdf: 'Profesjonalny mentor (PDF)',
        myPdf: 'Mój profil (PDF)',
        pdf: 'Profil mentora',
      },
      tabNames: {
        info: 'Info',
        news: 'Aktualności',
        profile: 'Profil',
        search: 'Wyszukiwanie i zapytania',
        request: 'Mentorzy',
        requestDetails: 'Zapytanie dotyczące mentoringu',
        tabDeactivated: 'Zakładka wyłączona. Wypełnij swój profil mentora.',
      },
      search: {
        description:
          'Aby rozpocząć wyszukiwanie w sposób ukierunkowany, zalecamy rozpoczęcie od kilku pól wyboru, a następnie rozszerzenie siatki wyszukiwania w nowym przebiegu wyszukiwania. Jeśli nie możesz znaleźć odpowiedniego mentora w swoim regionie, rozszerz wyszukiwanie na sąsiedni region mentorski. Kryteria wyszukiwania: 1 region, od 1 do 3 obszarów tematycznych. Wszystkie pola są połączone spójnikiem AND!',
        searchBtn: 'Wyszukiwanie',
      },
      requests: {
        mentorIncoming: 'Moje zapytania dotyczące mentoringu',
        mentorCurrent: 'Moi mentorzy',
        menteeOutgoing: 'Moje zapytanie dotyczące mentoringu',
        menteeCurrent: 'Mój mentoring',
        cancelMentoring: 'Mój mentoring',
        sendRequest: 'Prośba o mentora',
        alreadyHaveMentor: 'Możesz mieć tylko jednego mentora',
        requestSuccess: 'Zapytanie wysłane pomyślnie',
        requestRetracted: 'Zapytanie zostało pomyślnie wycofane',
        cancelSendingRequest: 'Wycofanie wniosku o mentoring',
        approveRequest: 'Zaakceptuj zapytanie',
        rejectRequest: 'Odrzuć żądanie',
        invitaionText: 'Zapytanie',
        rejectionText: 'Podaj powody odrzucenia',
        sureRetractRequest: 'Czy naprawdę chcesz wycofać zapytanie?',
      },
    },
    downloads: {
      root: 'Katalog główny',
      delete: 'Usuń',
      rename: 'Zmiana nazwy',
      areYouSureDeleteFolder: 'Naprawdę usunąć folder? Cała zawartość zostanie usunięta!',
      renameFolder: 'Zmiana nazwy folderu',
      createNewFolder: 'Utwórz nowy folder',
      newFolderPlaceholder: 'Wprowadź nazwę folderu',
      create: 'Utwórz',
      areYouSureDeleteFile: '',
      downloadFile: 'Pobierz',
      deleteFile: 'Usuń z urządzenia',
      openInFinder: 'Otwieranie w plikach',
      cancel: 'Anuluj',
    },
    globalSearch: {
      postedBy: 'opublikowany przez',
      found: 'Znaleziono',
      loadMore: 'Załaduj więcej',
      enterQuery:
        'Wprowadź dowolny termin wyszukiwania. Możesz wyszukiwać cokolwiek (nazwę, dokument, wydarzenie, post, ...).',
      folder: 'teczka',
      post: 'Wkład',
      error: 'Wprowadź co najmniej 3 znaki',
    },
    kreiseSettings: {
      description: 'Opis',
      push: 'Powiadomienia push',
      email: 'Powiadomienia pocztowe',
      subscribers: 'Członkowie',
      active: 'Aktywne',
      oneYear: 'Wyciszone na 1 rok',
      forever: 'Wyciszone na zawsze',
      until: 'Do',
      cantDeactive: 'Ta opcja jest wyłączona dla tego czatu grupowego',
      SINGLE: 'Dla każdego wpisu',
      SUMMARY_ONCE: 'Podsumowanie 1 raz w tygodniu',
      SUMMARY_TWICE: 'Podsumowanie 2 razy w tygodniu',
      SUMMARY_DAY: 'Podsumowanie 1 raz dziennie',
      OFF: 'Wyłączone',
      PUSH: 'Powiadomienia push',
      EMAIL: 'Powiadomienia mailowe',
      PUSH_AND_EMAIL: 'Powiadomienia push i mailowe',
      newUser: 'Powiadomienia o nowych użytkownikach',
      statistic: 'Wysyłanie statystyk',
      off: 'Wyłączone',
      ownPosts: 'Wysyłaj statystyki dotyczące swoich postów',
      deleteMemberDescr: 'Usunąć?',
      deleteMemberBtn: 'Usuń',
      retractAdminRights: 'Cofnij uprawnienia administratora',
      deleteAdminAndUser: 'Całkowicie usuń',
      toDeleteKreiseBtn: 'Usuń czat grupowy',
      deleteKreiseDescr: `${KreiseName('POL').single} Czy na pewno chcesz trwale usunąć`,
      deleteKreiseModalTitle: 'Czy usunąć członka?',
      addKreiseName: 'Nazwa',
      addKreiseDescription: 'Opis',
      addMembers: 'Dodaj członków',
      createKreise: 'Utwórz czat grupowy',
      toKreiseEditMessage: 'Uwaga: Wszystkie zmiany w członkach zostaną natychmiast wprowadzone.',
      kreiseCreator: 'Twórca tego czatu grupowego',
      kreiseDeleteMembersLeft: 'Czat grupowy można usunąć, gdy zostanie tylko jeden członek.',
      kreiseDeleteMembersLeftTitle: 'Najpierw usuń członków',
      suggestionInNewsfeed: {
        admin: 'Administrator',
        member: 'Członkowie',
        corp: `${CorpsName('POL').single} członkowie`,
        corpAdmin: `${CorpsName('POL').single} administrator`,
      },
    },
    surveys: {
      checkVotes: 'Wyświetl wyniki',
      chooseOptions: {
        single: 'Wybierz jedną opcję',
        multiple: 'Wybierz co najmniej jedną opcję',
      },
      votesModalTitle: 'Wyniki głosowania',
      createModalTitle: 'Ankieta',
      noVotes: 'Brak głosów',
      question: 'Pytanie',
      askAQuestion: 'Zadaj pytanie',
      allowMultiple: 'Zezwól na wielokrotne odpowiedzi',
      votes: {
        single: 'Głos',
        multiple: 'Głosy',
      },
      option: 'Opcja',
      optionsLabel: 'Opcje ankiety',
      anonymus: 'Ankieta anonimowa',
      create: 'Utwórz',
    },
    subscription: {
      subscription: 'Abonnements',
      pricePerMonth: 'EUR/Monat',
      month: 'Monat',
      day: 'Tag',
      otherPlans: 'Andere Tarife',
      subscriptionActive: 'Abonnement aktiv',
      until: 'bis',
      subscribe: 'Abonnieren',
      successfulPayment: {
        title: 'Zahlung erfolgreich',
        text: 'Dein Abonnement wurde erfolgreich abgeschlossen. Du kannst ab sofort die erweiterten Funktionen nutzen.',
      },
      cancel: {
        cancel: 'Abonnement kündigen',
        title: 'Abonnement kündigen?',
        text: 'Möchtest du dein Abonnement kündigen? Nach Ablauf der bezahlten Periode werden deine Zugriffsrechte zurückgestuft.',
      },
    },
  },
  {
    key: 'RUS', //russian language / russisch
    key2: 'ru',
    editProfile: {
      updated: 'Профиль обновлен.',
      validateFieldsViaEmail:
        'Пожалуйста, подтвердите следующие данные через электронную почту. Только после этого они будут отправлены в центральное управление участниками.',
      newValue: (value: any) => `Новое значение: "${value}"`,
      resendEmail: 'Или мы должны отправить письмо для подтверждения снова?',
      validationPending: 'Подтверждение в ожидании',
      resend: 'Отправить снова',
      resendDone:
        'Письмо для подтверждения было отправлено повторно. Пожалуйста, подождите несколько минут и проверьте папку спама.',
      resendLong: 'Хотите, чтобы мы снова отправили письмо для валидации?',
    },
    appInfoScreen: {
      title: 'О приложении',
      privacy: 'Информация о конфиденциальности',
      find: 'Здесь вы можете найти',
      terms: ' Условия использования ',
      and: ' и ',
      infoSection: 'Информация о приложении',
      hasUpdate: 'Доступна новая версия. Нажмите здесь, чтобы обновить.',
      noUpdate: 'Это последняя версия.',
      appVerison: 'Версия приложения:',
    },
    corpsScreen: {
      website: 'Вебсайт:',
      email: 'Адрес электронной почты:',
      members: 'Члены',
    },
    contactsScreen: {
      empty: "Здесь пока нет записей. Перейдите в главное меню и выберите 'Поиск' → 'Имя', чтобы найти новые контакты.",
      admins: 'Администраторы',
      info: 'Информация',
      searchMember: 'Поиск участников',
    },
    dialogPanel: {
      hangOn: 'Прикрепить Bierjunge?',
      ok: 'ОК',
    },
    dialogs: {
      emptyTitle: 'Нажмите на плюс в верхнем правом углу, чтобы начать разговор.',
    },
    documentsGallery: {
      modalTitle: 'Удалить документ',
      modalDesc: 'Удалить этот файл?',
      cancel: 'Отмена',
      delete: 'Удалить',
      deleteConfirm: 'Удалить загруженный файл (только для меня)?',
      downloadConfirm: 'Загрузить этот файл?',
      availableInDownloads: `\n\n*Все загруженные файлы доступны в папке "Загрузки"`,
    },
    drawerScreen: {
      backdrop: 'Вся основная информация должна быть заполнена перед использованием приложения.',
    },
    kreiseInfoScreen: {
      creator: 'Создатель этого группового чата',
      revokeAdmin: (name: string) =>
        `Вы хотите удалить права администратора у "${name}" или полностью исключить его из группы?`,
    },
    kreisePostScreen: {
      infoParticipants: (num: string | number) => `Информация и участники (${num})`,
      subscribeTitle: 'Подписаться на групповой чат?',
      subscribeDesc: 'Хотите подписаться на этот групповой чат, чтобы не пропустить новости?',
      yes: 'Да',
      later: 'Может быть позже',
      dontAsk: 'Не спрашивать о этом групповом чате снова',
    },
    mapScreen: {
      appUser: 'Пользователь приложения',
      viewIsForMember: 'Просмотр профилей пользователей доступен только для участников.',
      all: 'Все',
    },
    mentoringScreen: {
      clickMentor: 'Нажмите на имя наставника, чтобы отправить напоминание или отозвать запрос',
      all: 'Все',
      startRequest: 'Начать запрос:',
      startDesc:
        'Пожалуйста, опишите вашу просьбу о наставничестве как можно более четко и кратко — это поможет наставнику лучше оценить ваш запрос и быстро ответить.',
      notice: 'Примечание:',
      noticeDesc:
        'Вы должны дать наставнику до 2 недель для ответа, прежде чем отправить напоминание. Отзовите свой текущий запрос, прежде чем связаться с другим наставником.',
      withdrawRequest: 'Отозвать запрос',
      reminder: 'Отправить напоминание',
      reminderSuccess: 'Напоминание отправляется (+ копия вам)',
      rejectRequest: 'Отклонить запрос',
      requestTitle: 'Запрос на наставничество',
      areas: 'Области экспертизы',
      topics: 'Темы',
      withReguards: (name: string, lastname: string) =>
        `\n\n\n(...)\n\nС наилучшими пожеланиями,\n\n${name} ${lastname}`,
      rejectionMessage: (name: string, lastname: string) =>
        `Здравствуйте!\n\nК сожалению, я должен отклонить запрос на наставничество. Я с радостью объясню причины, если вы спросите.\n\nС наилучшими пожеланиями,\n\n${name} ${lastname}`,
      maxMessage: (max: number | string, topic: string) => `Вы можете выбрать максимум ${max} ${topic}.`,
      decisionOpen: 'Решение: открыто',
      maxMentoring: 'Макс. 1 запрос на наставничество разрешен',
      maxParalelMentoring: 'Макс. 1 параллельный запрос на наставничество разрешен',
      openHere: 'Откройте ',
      profileUpdated: 'Профиль обновлен!',
      mentoringProfile: 'Профиль наставника',
      mentee: 'Подопечный',
      mentor: 'Наставник',
      leaveMentoring: 'Покинуть группу наставничества',
      mentoringTitle: 'Наставничество',
      scrollAuto: 'пример прокручиваемых авто вкладок',
      stopMentoringMessageMentor: (name: string, lastname: string) => `Завершить наставничество с ${name} ${lastname}?`,
      stopMentoringMessageMentee: (name: string, lastname: string) => `Завершить наставничество с ${name} ${lastname}?`,
      mentorEndedMentorship: (name: string, lastname: string) =>
        `Наставник ${name} ${lastname} завершил наставничество.`,
      mentorRejectedMentorship: (name: string, lastname: string) =>
        `Наставник ${name} ${lastname} отклонил ваш запрос.`,
      menteeEndedMentorship: (name: string, lastname: string) => `Ученик ${name} ${lastname} завершил наставничество.`,
      menteeRejectedMentorship: (name: string, lastname: string) => `Ученик ${name} ${lastname} отклонил ваш запрос.`,
      mentorat: 'Наставничество',
    },
    newEventScreen: {
      kreiseError: 'Пожалуйста, выберите целевую аудиторию',
      correctDate: 'Исправьте дату/время',
      correctDateMessage: 'Конечная дата/время раньше начальной даты/времени. Пожалуйста, исправьте.',
    },
    outRequestScreen: {
      backButton: 'Запросы на контакт',
      requestMessage: (name: string, lastname: string) =>
        `Здравствуйте!\n\nЯ бы хотел(а) добавить вас в свои контакты.\n\nС уважением\n\n${name} ${lastname}`,
    },
    participantsScreen: {
      interestedParties: 'Заинтересованные стороны',
      removeGuestTitle: (name: string) => `Удалить ${name}?`,
      removeGuestDesc: (name: string) => `Удалить "${name}" из списка участников?`,
      informUser: (name: string) => `Сообщить "${name}" о его удалении из списка участников`,
    },
    partParticipantsScreen: {
      interestedParties: 'Заинтересованные стороны',
      removeParticipantTitle: (name: string, lastname: string) =>
        `Удалить участника "${name} ${lastname}" из списка участников?`,
      removeParticipantDesc: (name: string, lastname: string) => `Удалить "${name} ${lastname}"?`,
    },
    documentsScreen: {
      deleteTitle: 'Выбранные файлы:',
      cancel: 'Отмена',
      uploadData: 'Загрузить данные',
    },
    postsScreen: {
      ads: 'Реклама...',
      oldPostsForMembers: 'Старые посты видны только участникам.',
    },
    profileBasisScreen: {
      editPhoto: 'Редактировать фото',
    },
    profileBenachrichtigungenScreen: {
      fillData: 'Вся основная информация должна быть заполнена, прежде чем приложение можно будет использовать.',
      changesInChat: 'Вы можете изменить эти настройки в соответствующем групповом чате.',
      commentsWarning1:
        'Отключение уведомлений по электронной почте для комментариев к вашим постам не рекомендуется, так как важные комментарии могут быть пропущены.',
      commentsWarning2:
        'Отключение уведомлений по электронной почте для личных сообщений не рекомендуется, так как важные сообщения могут быть пропущены.',
      messagesWarning1:
        'Отключение уведомлений по электронной почте для личных сообщений не рекомендуется, так как вы обычно получаете гораздо меньше личных сообщений по сравнению с сообщениями в групповых чатах. Однако они важны, потому что обычно касаются конкретных запросов, направленных к вам. Эти (важные) личные сообщения могут быть пропущены, если уведомления отключены.',
      messagesWarning2:
        'Отключение уведомлений по электронной почте для комментариев к вашим постам не рекомендуется, так как важные комментарии могут быть пропущены.',
      areYouSure: 'Вы уверены?',
      turnOff: 'Выключить',
      maybeLater: 'Может быть позже',
      notRecommended: 'Не рекомендуется',
      ok: 'OK',
    },
    profileBerufScreen: {
      fillData: 'Вся основная информация должна быть заполнена, прежде чем приложение можно будет использовать.',
      defaultDate: 'До сегодняшнего дня',
      noArea: 'Нет области',
      furtherActivity: 'Дополнительная активность',
      deleteConfirm: 'Действительно удалить запись?',
      ok: 'OK',
    },
    profileCorpsScreen: {
      corpsname: 'Название проекта',
      createHint: 'Создается новая запись.',
      requestHint: 'Запрашивается создание новой записи.',
      status: 'Статус',
      deleteConfirm: (name: string) => `Действительно ли вы хотите удалить свое членство в ${name}?`,
      ok: 'OK',
      fillData: 'Вся основная информация должна быть заполнена, прежде чем приложение можно будет использовать.',
      duplicatedCorps: (name: string) => `Проект ${name} уже есть в профиле, пожалуйста, проверьте еще раз.`,
      missingCorpName: 'Пожалуйста, укажите название проекта.',
      waitingActivation: 'Ожидание активации',
      requestExistingMessage: (name: string) =>
        `Запрос на членство в "${name}" был отправлен. Вы получите уведомление, когда оно будет активировано.`,
      requestNewMessage: (name: string) =>
        `Запрос на создание "${name}" был отправлен. Вы получите уведомление, когда оно будет активировано.`,
      createMessage: (name: string) => `"${name}" был создан и добавлен в ваш профиль.`,
    },
    profileSonstigenScreen: {
      other: 'Прочее (пожалуйста, введите)',
    },
    profileDetailsScreen: {
      email: 'Электронная почта',
    },
    profilePrivacyScreen: {
      fillData: 'Вся основная информация должна быть заполнена, прежде чем приложение можно будет использовать.',
    },
    profileStudiumScreen: {
      deleteConfirm: 'Действительно удалить запись?',
      ok: 'OK',
      fillData: 'Вся основная информация должна быть заполнена, прежде чем приложение можно будет использовать.',
    },
    reportScreen: {
      reportUser: 'Пожаловаться на пользователя',
      notMember: 'Пользователь больше не является членом',
      fakeProfile: 'Пользователь создает фальшивые профили.',
      violations1: 'Фото профиля пользователя нарушает правила.',
      violations2: 'Заявления пользователя нарушают правила.',
      violations3: 'Пользователь рассылает спам в чате.',
      violations4: 'Сообщите о потенциально нарушающих контент',
      violations5: 'Сообщите/пометьте других пользователей за возможные нарушения',
      reportReason: 'Причины жалобы',
      sendReport: 'Отправить',
      cancel: 'Отмена',
      showContent: 'Показать контент этого пользователя',
      hideContent: 'Скрыть контент этого пользователя',
    },
    resetPasswordScreen: {
      incorrectEmail: 'Ваш адрес электронной почты неверен.',
    },
    serverIsDownScreen: {
      serverMaintenance: 'Технические работы на сервере',
    },
    signInScreen: {
      dontHaveAccount: 'Нет аккаунта?',
    },
    signUpScreen: {
      login: 'Вход',
      emailMatchError: 'Электронная почта и подтверждение электронной почты не совпадают.',
      passwordMatchError: 'Пароль и подтверждение пароля не совпадают.',
      incorrectEmail: 'Ваш адрес электронной почты неверен!',
      pleaseSelect: 'Пожалуйста, выберите',
      accountExistsTitle: 'Аккаунт существует',
      accountExistsDesc: 'У вас уже есть аккаунт с этим адресом электронной почты.',
      toLogin: 'Для входа',
    },
    sucheScreen: {
      searchFor: 'Результат поиска для:',
      filter: 'Фильтр:',
      name: 'Имя',
      branch: 'Отрасль',
      function: 'Функция',
      status: 'Статус',
      city: 'Город',
      industry: 'Индустрия',
      business: 'Бизнес',
      profession: 'Профессия',
      training: 'Обучение',
      studyProgram: 'Учебная программа',
      college: 'Колледж',
      cancel: 'Отмена',
      searchBar: 'Поиск',
      searchTab: 'Вкладка поиска',
      searchTitle: 'Поиск',
      scrollable: 'Пример с авто-прокручиваемыми вкладками',
    },
    dialogComponent: {
      close: 'Закрыть',
    },
    phoneInputComponent: {
      noCountry: 'Страна не найдена.',
    },
    cropImageHandlerComponent: {
      cancel: 'Отмена',
      crop: 'Обрезать',
    },
    datePickerComponent: {
      cancel: 'Отмена',
      ok: 'OK',
      date: 'Дата/Время',
      day: 'День',
      month: 'Месяц',
      year: 'Год',
      wrongDate: 'неверная дата',
    },
    timePickerComponent: {
      hours: 'Часы',
      minutes: 'Минуты',
    },
    responseHandlerComponet: {
      supportEmail: `Неизвестная ошибка. Пожалуйста, свяжитесь с ${support_email}`,
      maximumSelection: (num: string | number) => `Пожалуйста, выберите не более ${num} записей!`,
    },
    searchModalComponent: {
      selectionError: 'Пожалуйста, выберите хотя бы один элемент.',
    },
    customDropzoneComponent: {
      dropFiles: 'Перетащите файлы сюда',
      clickHere: ' или нажмите здесь, чтобы выбрать ',
    },
    documentsGalleryComponent: {
      clickToOpen: 'Нажмите, чтобы открыть',
      clickToDownload: 'Нажмите, чтобы скачать',
    },
    eventToolbarComponent: {
      noRegistration: 'Нет регистрации!',
      notLoggedId: 'Вы еще не вошли. Это просто выражение интереса.',
      registerVia: 'Регистрация осуществляется через:',
    },
    likeComponent: {
      externalGuest: 'Внешний гость',
      invitedBy: (name: string) => `Приглашен(а) ${name}`,
    },
    membersComponent: {
      interested: 'Выразил(а) интерес',
      loggedIn: 'Вошел(ла)',
      um: ' в ',
      invitedBy: (name: string) => `Приглашен(а) ${name}`,
    },
    multipleSearchableInputComponent: {
      maximalMembers: `Пожалуйста, укажите не более ${maximal_membership_count} членств.`,
    },
    multipleSelectModalComponent: {
      all: 'Все',
    },
    offlineModalComponent: {
      checkInternet: 'Пожалуйста, проверьте ваше интернет-соединение.',
    },
    postsToolbarComponent: {
      membersOnly: 'Только участники могут размещать посты.',
    },
    zusagenModalComponent: {
      incorrectEmail: 'Ваш email неверен',
      required: 'Это поле обязательно для заполнения.',
      name: 'Имя',
      email: 'Электронная почта (необязательно)',
      invitedBy: 'Приглашен(а) от',
      save: 'Сохранить',
      cancel: 'Отмена',
      warning1: `
			Внимание: Пожалуйста, регистрируйтесь здесь только как ${guest_naming_singular}!
			Если у вас есть аккаунт в приложении, войдите в него и подтвердите свой статус как член. Спасибо!`,
      warning2: 'Регистрируясь, вы соглашаетесь с',
      and: ' и ',
      to: '.',
    },
    formInputComponent: {
      cancelConfirmation: 'Отменить подтверждение?',
    },
    logoComponent: {
      alt: 'Логотип приложения',
    },
    customHistoryHook: {
      news: 'Сообщение',
    },
    postContentComponent: {
      orginized: 'Организатор:',
      date: 'Дата:',
      ort: 'Место:',
    },
    beerSmileComponent: {
      bierjunge: 'Пивной мальчик!',
    },
    buttonComponent: {
      examination: 'Проверка:',
    },
    global: {
      all: 'Все',
      postSubject: 'Тема вашего поста',
      showMore: 'Читать далее',
      toRegister: 'Для регистрации',
      addToCalendar: 'iCal / ICS',
      interested: 'Интересно',
      loggedIn: 'Вошли',
      tilToday: 'До сегодняшнего дня',
      status: 'Статус',
      deletePost: 'Удалить пост?',
      pdf: '(PDF)',
      time: 'Время',
      birthday: (age: number | string, name: string, lastname: string) => `${age} лет от ${name} ${lastname}`,
      eur: 'EUR',
      price: 'Цена:',
      priceForMe: 'Цена для меня:',
      ok: 'ОК',
      likesCount: (num: string | number) => `Лайки (${num})`,
      event: 'Событие',
      standardMessage: 'Мы генерируем тему для вашего поста. Подходит ли эта тема?',
      optionalMessage: 'О чём ваш пост? Напишите короткую тему. Вы также можете позволить ИИ предложить вам вариант.',
      optionalMessageNote: 'Используя эту функцию, вы соглашаетесь с ',
      chatGPTTerms: 'Условиями использования ChatGPT',
      chatGPTTermsLink: 'https://openai.com/policies/terms-of-use',
      optionalMessageNoteEnding: '.',
      postSubjectTitle: 'Тема поста',
      generate: 'Генерировать',
      defaultSubject: 'Стандартная тема',
      memebership: 'Членство',
      applyMemebership: 'Подать заявку на членство?',
      pendingMembership: 'Заявка на членство подана - решение в процессе.',
      requestAlreadySent: 'Запрос уже отправлен',
      requestedMemebership: (kreiseName: string) => `Заявка на членство в "${kreiseName}" подана.`,
      yes: 'Да',
      no: 'Нет',
      change: 'Изменить',
      passwordOldMatchNewError: 'Старый и новый пароли совпадают',
      passwordLenghtError: 'Пароль должен быть не менее 6 символов.',
      passwordsMatchError: 'Старый и новый пароли совпадают',
      passwordsMatchError2: 'Пароль и подтверждение не совпадают.',
      currentPassword: 'Текущий пароль',
      newPassword: 'Новый пароль',
      confirmPassword: 'Подтвердите пароль',
      changePassword: 'Изменить пароль',
      cancel: 'Отмена',
      fits: 'Подходит',
      selectChatTitle: 'Выберите групповую беседу',
      selectChatDesc:
        "Выберите хотя бы 1 любимую беседу, в которую вы хотите отправить свой пост. Дополнительные беседы можно найти в главном меню в разделе 'Групповые чаты'.",
      socialMedia: 'Социальные сети',
      generatedText: 'Мы генерируем текст для социальных сетей. Подходит ли этот текст?',
      usePhoto: 'Спасибо за ваш пост! Можем ли мы использовать ваше фото для социальных сетей?',
      maximumLenght: 'Максимум 20 000 символов.',
      userSharedNewPicture: 'Пользователь нашего приложения поделился новым изображением!',
      contributionTo: (kreiseName: string) => `Вклад в “${kreiseName || 'Все'}”`,
      postWarning1:
        'Вперёд! У вас будет возможность добавить свой текст на следующем шаге. Также ваш пост будет проверен.',
      postWarning2:
        'После отправки ваш пост будет проверен администратором. Если у вас есть вопросы, не стесняйтесь обращаться к ',
      postWarning3: ' или ',
      AGB: 'Условия использования',
      DSE: 'Политика конфиденциальности',
      withCheckbox: "При первичной активации моего аккаунта через функцию 'Забыли пароль' я соглашаюсь с",
      withoutCheckbox: "При первичной активации вашего аккаунта через функцию 'Забыли пароль' вы соглашаетесь с",
      tou: 'Условия использования',
      dse: 'Политика конфиденциальности',
      impressum: 'Юридическое уведомление',
    },
    responseHandler: {
      messageLabel: `Неизвестная ошибка. Пожалуйста, обратитесь к ${support_email}`,
      expiredLinkLabel: 'Ссылка устарела. Запросите новое изменение пароля.',
      emailExistLabel: 'Извините, этот адрес электронной почты уже зарегистрирован.',
      profileUpdated: 'Профиль обновлен',
      profileUpdatedCorpsError: `Профиль обновлен! Следующие ${
        CorpsName('RUS').single
      } не удалось добавить из-за неправильного кода входа в систему:`,
      appNeedsUpdate: 'Пожалуйста, обновите приложение в App Store / Google Play.',
      maximalVideoSize: 'Максимальный размер файла - 2 ГБ!',
    },
    report: {
      notBelongLabel: 'Пользователь больше не является участником.',
      incProfileLabel: 'Пользователь предоставляет неправильные данные профиля',
      badPicLabel: 'Изображение профиля пользователя нарушает правила',
      badComLabel: 'Комментарии пользователя нарушают правила',
      spamLabel: 'Пользователь отправляет спам в чате',
      reasonsLabel: 'Причины для жалобы',
    },
    drawer: {
      subscription: 'Subscription',
      news: `Новости`,
      kreise: KreiseName('RUS').multi,
      search: 'Поиск',
      calendar: CalendarName('RUS'),
      documents: `${DocumentsName('RUS')}`,
      contacts: 'Контакты',
      requests: 'Запросы на контакты',
      messages: MessagesName('RUS'),
      profile: ProfileName('RUS'),
      logout: 'Выйти',
      map: 'Карта',
      mentoring: 'Наставничество',
    },
    imageCropper: {
      cropLabel: 'Обрезать',
      cancelLabel: 'Отмена',
      chooseLabel: 'Выбрать',
    },
    signIn: {
      register: 'Зарегистрироваться',
      title: 'Пожалуйста, войдите',
      loginPlaceholder: 'Адрес электронной почты',
      passwordPlaceholder: 'Пароль',
      forgotPassword: 'Забыли пароль?',
      loginBtn: 'Войти',
      noAccount: 'Нет аккаунта?',
      description0: 'По всем вопросам обращайтесь',
      description1: 'или',
      description2: '.',
      loginValidation: 'Указание адреса электронной почты неверно',
      passwordValidation: 'Пароль должен содержать не менее 6 символов',
    },
    search: {
      name: 'Имя',
      corps: CorpsName('RUS').single,
      country: CountryName('RUS'),
      city: 'Город',
      industry: IndustryName('RUS'),
      company: CompanyName('RUS'),
      profession: 'Профессия',
      hobby: 'Хобби',
      cultural: 'Культурные партнеры',
      study: `${StudyName('RUS').short}`,
      office: 'Офис',
      degreeProgram: StudyName('RUS').studiengang,
      university: StudyName('RUS').university,
      position: `${FunctionName('RUS')}`,
      status: 'Статус',
      title: 'Результаты поиска для',
      filterTitle: 'Фильтр по',
      postCode: 'Почтовый индекс',
      global: 'Все',
      activity: ActivityName('RUS'),
    },
    resetPassword: {
      enterEmail: 'Пожалуйста, введите адрес электронной почты',
      inputPlaceholder: 'Адрес электронной почты',
      sendBtn: 'Отправить',
      cancelBtn: 'Отмена',
      message: 'Если адрес электронной почты известен, туда была отправлена ссылка.',
      desc: 'Вы должны ввести точный адрес электронной почты, который указан в приложении. Проверьте, возможно, вы можете найти его в своей почте.',
    },
    password: {
      resetLabel: 'Введите новый пароль',
      inputLabel: 'Новый пароль',
    },
    signUp: {
      createAccount: 'Создать аккаунт',
      inputPlaceholder: `Код входа в систему вашего ${CorpsName('RUS').single}s`,
      sendBtn: 'Далее',
      descFirst: 'Регистрируясь, вы соглашаетесь с нашими ',
      descSecond: 'и подтверждаете, что прочитали наши ',
      descThird: `Если у вас есть вопросы, вы можете связаться с нами по адресу ${support_email} или +49 170 49 805.`,
      terms: 'условиями использования',
      policy: 'политикой конфиденциальности.',
      description: 'Примечание',
      generatePassword: 'Сгенерировать пароль',
      noCodeSuccessRegister:
        'Спасибо за регистрацию! Вы будете уведомлены по электронной почте, когда ваш аккаунт будет активирован.',
    },
    signUpDefault: {
      withoutCode: 'Без кода',
      createAccount: 'Просто зарегистрируйтесь здесь',
      withCode: 'С пригласительным кодом',
      simpleRegister: 'Без пригласительного кода',
      existingAccount: 'Уже есть аккаунт?',
      descr01: 'Я соглашаюсь с',
      descr0: 'Регистрируясь, вы соглашаетесь с',
      descr1: 'и с',
      descr2: '.',
      guest: `Зарегистрируйтесь как ${guest_naming_singular}`,
      withCodeWithDescr: `Зарегистрируйтесь как ${member_naming_singular}`,
    },
    signUpManual: {
      title: 'Регистрация',
      photoDesc: 'Добавить фото',
      genderInput: 'Пол',
      firstName: 'Имя',
      lastName: 'Фамилия',
      club: CorpsName('RUS').single,
      specify: 'указать, пожалуйста',
      emailPlaceholder: 'Адрес электронной почты',
      emailHint: 'Пожалуйста, используйте постоянный адрес электронной почты.',
      repeatEmailPlaceholder: 'Повторите адрес электронной почты',
      passwordPlaceholder: 'Установите пароль',
      repeatPasswordPlaceholder: 'Повторите пароль',
      postcode: 'Почтовый индекс',
      place: 'Место проживания',
      country: 'Страна',
      loginCode: 'Пригласительный код',
      mobilePlaceholder: 'Мобильный номер (не виден в профиле)',
      corps: SignUpCorpsName('RUS'),
      searchCountry: 'Поиск по стране',
    },
    backToLogin: {
      desc: 'Уже есть аккаунт? ',
      login: 'Войти',
    },
    backToSignUp: {
      desc: 'Нет аккаунта? ',
      register: 'Зарегистрироваться',
    },
    privacy: {
      desc: 'Да, я соглашаюсь с',
      btn: 'Условиями использования',
      zu: '',
      map: {
        title: 'Уведомление о конфиденциальности',
        privacyNotice: `Эта карта была подготовлена в соответствии с GDPR.`,
        additionalInfo: {
          intro: 'Булавки на карте',
          inaccurateDisplay: 'неточно отображаются только по почтовому индексу/городу',
          locationBasis: ', а не по названию улицы/номера дома.',
          noConclusion: 'Это означает, что нельзя сделать никаких выводов',
          onAddresses: 'о конкретном адресе пользователя',
          conclusion: '.',
        },
        agree: 'Понятно',
        linkName: 'Политика конфиденциальности',
        options: {
          doNotShowAgain: 'Больше не показывать',
          doNotShowMeOnMap: 'Не показывать меня на карте (даже приблизительно)',
        },
      },
      restrictions: {
        overall: 'Эта функция доступна только для участников.',
        overallWilkar: 'Эта функция недоступна по причинам конфиденциальности данных.',
        profile:
          'Только участники могут просматривать профили пользователей. Однако вы можете отправить пользователю личное сообщение.',
        groupchat: `Чаты ${KreiseName('RUS').single} доступны только для участников.`,
        groupchatWilkar: `По соображениям конфиденциальности чаты ${
          KreiseName('RUS').single
        } доступны только для участников`,
        comments: 'Комментарии доступны только для участников.',
        commentsWilkar: 'По соображениям конфиденциальности комментарии доступны только для участников.',
        likes: 'Список лайков доступен только для участников.',
        post: 'Только участники могут создавать записи.',
        participantsList: 'Список участников могут просматривать только члены клуба.',
        commentsOnlyForMembers: 'Комментарии видны только пользователям.',
        postsLimitation: 'Старые сообщения могут просматривать только пользователи.',
      },
      settings: {
        guestPost: {
          title: `Публикации видны только проверенным ${guest_naming_plural} `,
          ask: 'Всегда спрашивать',
          no: 'Нет',
          yes: 'Да',
        },
      },
      guestModal: {
        title: `Могут ли проверенные ${guest_naming_plural} идеть эту запись? Вы можете изменить эту настройку видимости для каждой отдельной записи позже.`,
        dontAskAgain:
          'Больше не спрашивать (вы можете изменить эту настройку в разделе "Профиль" → "Конфиденциальность").',
      },
      post: {
        ifShown: `Скрыть от ${guest_naming_plural} `,
        ifHidden: `Показать ${guest_naming_plural} `,
        ifShownModal: `Эта запись больше не будет видна ${guest_naming_plural}`,
        ifHiddenModal: `Эта запись теперь будет видна ${guest_naming_plural}`,
      },
    },
    searchModal: {
      landTitle: 'Выберите страну',
      corpsTitle: `${CorpsName('RUS').single} выберите`,
      pleaseSelect: 'Пожалуйста, выберите',
    },
    btn: {
      sendBtn: 'Отправить',
      cancelBtn: 'Отмена',
      select: 'Выбрать',
      cancelEventBtn: acceptEventName('RUS').cancel,
      acceptBtn: acceptEventName('RUS').accept,
      saveBtn: 'Сохранить',
      clearBtn: 'Очистить',
      acceptReqBtn: 'Принять',
      declineReqBtn: 'Отклонить',
      updateBtn: 'Обновить',
      declineBtn: 'Отклонить',
      yesUnderstood: 'Ja, verstanden',

      ok: 'OK',
    },
    validation: {
      email: 'Укажите корректный адрес электронной почты',
      password: 'Пароль должен содержать не менее 6 символов',
      repeatEmail: 'Адреса электронной почты не совпадают.',
      repeatPassword: 'Пароли не совпадают',
      required: 'Это поле обязательно для заполнения',
      matchPasswords: 'Старый и новый пароли совпадают',
      dontMatchPasswords: 'Пароли не совпадают',
    },
    init: {
      title: 'Загрузка',
      overlayLabel: 'Пожалуйста, проверьте подключение к Интернету',
    },
    screenTitles: {
      postsTitle: `Новости`,
      circlesTitle: KreiseName('RUS').multi,
      searchTitle: 'Поиск',
      profileTitle: ProfileName('RUS'),
      eventTitle: CalendarName('RUS'),
      eventDetailsTitle: 'События',
      newEventTitle: 'Добавить событие',
      contactsTitle: 'Контакты',
      contactReqTitle: 'Запросы на контакты',
      reqDetailsTitle: 'Запросы на контакты',
      outReqTitle: 'Запросы на контакты',
      messagesTitle: MessagesName('RUS'),
      changePasswordTitle: 'Изменить пароль',
      appInfoTitle: 'О приложении',
      reportTitle: 'Пожаловаться на пользователя',
      createKreiseTitle: 'Создать групповой чат',
      eventParticipants: 'Teilnehmer',
    },
    profileDetails: {
      showFilters: 'Показать фильтры',
      user: 'Пользователь',
      mainTitleLabel: ProfileName('RUS'),
      basisLabel: 'Основное',
      clubLabel: CorpsName('RUS').single,
      clubLabelPlural: CorpsName('RUS').multi,
      professionLabel: 'Профессия',
      studyLabel: StudyName('RUS').short,
      uploadPhotoLabel: 'Загрузить фото',
      editPhotoLabel: 'Редактировать фото',
      profileCompletedLabel: 'Профиль',
      profileCompletedGLabel: 'завершен',
      birthday: 'Дата рождения',
      functionLabel: `${FunctionName('RUS')}`,
      dateOfJoinLabel: CorpsEditDate('RUS'),
      positionDescLabel: 'Профессия',
      companyLabel: CompanyName('RUS'),
      nameLabel: 'Имя',
      websiteLabel: 'Веб-сайт',
      fromLabel: 'С',
      toLabel: 'До',
      delete: 'удалить',
      untilToday: 'До сегодняшнего дня',
      degreeLabel: StudyName('RUS').abshchluss,
      miscellaneos: 'Разное',
      hobbies: 'Хобби',
      cultural: 'Культурные партнеры',

      positionLabel: 'Профессия',
      firmaLabel: 'Фирма',
      industryLabel: IndustryName('RUS'),
      areaLabel: 'Область',
      typeOfEmplLabel: 'Тип занятости',
      activityLabel: ActivityName('RUS'),
      careerStageLabel: 'Этап карьеры',
      activityDescLabel: 'Описание деятельности',
      employmentPeriodLabel: 'Период занятости',
      universityLabel: StudyName('RUS').hochsule,
      courseLabel: StudyName('RUS').studiengang,
      ageLabel: 'Возраст',
      birthdayLabel: 'Дата рождения',
      editProfileLabel: 'Редактировать профиль',
      addContactLabel: 'Добавить в контакты',
      sendMessageLabel: 'Отправить сообщение',
      requestSentLabel: 'Запрос на контакт уже отправлен',
      sendRequestSuccessLabel: 'Запрос успешно отправлен',
      reportSentLabel: 'Пользователь уже заблокирован/пожалован',
      addReportLabel: 'Пожаловаться/заблокировать пользователя',
      contactsLabel: 'Количество контактов',
      showBirthday: 'Мои контакты видят мой день рождения',
      street: 'Улица + номер',
      houseLabel: 'Дом',
      houseNumberLabel: 'Номер дома',
      partner: 'Имя партнера',
      mobileNumberLabel: 'Мобильный номер',
      handphoneNumberLabel: 'Номер мобильного телефона',
      telephoneNumberLabel: 'Номер телефона',

      leibbursch: 'Ментор',
      leibfuechse: 'Подопечный',
      bio: 'Обо мне',
      addDocuments: 'Добавить документы',
      image: 'Изображение',
      video: 'Видео',
      pdf: 'PDF',
      link: 'Ссылка',
      firstName: 'Имя',
      lastName: 'Фамилия',
      title: 'Титул',
      email: 'E-Mail',
      name: 'Имя',
      lastname: 'Фамилия',
      country: CountryName('RUS'),
      wohnort: 'Город',
      postcode: 'Почтовый индекс',
      inReview: 'Ожидается подтверждение по электронной почте',
    },
    calendar: {
      birthdayLabel: 'День рождения',
      noEventsLabel: 'На выбранную дату событий нет',
      allTabLabel: 'Все',
      myTabLabel: 'Мои',
      clubsTabLabel: CorpsName('RUS').single,
      subsTabLabel: 'Подписки',
      subscribedTabLabel: 'Подписано',
      otherTabLabel: 'Другие',

      error: 'Дата начала должна быть перед датой окончания.',
      filter: {
        all: 'Все',
        invited: 'Приглашенные',
        confirmed: 'Подтвержденные',
      },
    },
    eventLabels: {
      organizer: 'Organizer',
      registrationVia: 'Регистрация через',
      maximalParticipants: 'Максимальное количество участников',
      participantsWarning: 'Количество участников ограничено',
      interested: 'Заинтересован',
      notInterested: 'Не интересно',
      removeFromWaitList: 'Удалить из списка ожидания',
      addEventTitleImage: 'Добавить титульное изображение',
      changeEventTitleImage: 'Изменить титульнуe изображение',
      inviteTo: 'Приглашение на',
      addEndDate: 'Введите дату и время окончания',
      eventLabel: 'Организатор',
      startLabel: 'Начало',
      locationLabel: 'Место проведения',
      participantLabel: 'Участник',
      moreInfo: 'Дополнительная информация',
      commentsLabel: 'Комментарии',
      noCommentsLabel: 'Пока нет комментариев',
      eventStartLabel: 'Начало события',
      eventEndLabel: 'Окончание события',
      descLabel: 'Описание',
      creatorLabel: 'Создатель',
      titleLabel: 'Название',
      deleteEventTitle: 'Удалить событие?',
      deleteEventLabel: 'Удалить это событие?',
      clubsTitle: `${CorpsName('RUS').single}s`,
      gruppenchat: 'Выберите групповой чат',
      contactsTitle: 'Контакты',
      delCommentLabel: 'Удалить комментарий?',
      sendInvite: 'Отправить приглашение',
      selectCorps: 'Выберите группу',
      externalGuest: 'Приглашенные внешние гости',
      externalGuestPlaceholder: 'Введите адрес электронной почты',
      attachmentLabel: 'Вложение',
      shareLinkLabel: 'Поделиться ссылкой',
      linkLabel: `${guest_naming_singular} зайдите на`,
      copyLinkLabel: 'Копировать ссылку',
      iCalDownload: 'Скачать файл календаря',
      timezone: 'Часовой пояс',
      visibility: 'Видимость',
      errorDate: 'Дата в прошлом',
      startAfterEndError: 'Дата окончания должна быть позже даты начала',
      members: 'Участники',
      waitlist: 'Список ожидания',
      onWaitlist: 'в списке ожидания',
      leaveWaitlist: 'Покинуть список ожидания?',
      leaveWaitlistDesc:
        'Удалить из списка ожидания?\n\n Если вы снова присоединитесь к списку ожидания позже, вы можете быть помещены в конец списка.',
      limitAchieved: (limit: number) =>
        `Достигнуто максимальное количество участников ${limit}. Вы поставлены в список ожидания.`,
      visibilityOptions: {
        allWithoutGuests: 'Участники и на сайте',
        invited_member: 'Только приглашенные участники',
        verified_member: 'Все участники',
        authorized_member: 'Все участники и гости',
        all: 'Участники, гости и на веб-сайте',
      },
      modalEvent: {
        title: 'Событие создано',
        subTitle: 'Событие создано.\n\n',
        content:
          'В зависимости от настроек пользователя теперь будут отправлены уведомления о событии через Push или по электронной почте. \n\nЕсли вы настроили это в разделе “Профиль” → “Уведомления”, вы также получите уведомление о событии по электронной почте.',
      },
      modalComment: {
        title: 'Кому отправить уведомление?',
        message: 'Кто должен быть уведомлен о вашем комментарии?',
        optionAll: 'Все участники',
        optionNone: 'Только организатор(ы)',
        button: 'Отправить комментарий',
      },
      cancelModal: {
        title: 'Отменить или удалить?',
        message: 'Отменить или удалить событие?',
        optionAll: 'Отменить (участники будут уведомлены)',
        optionNone: 'Удалить (участники не будут уведомлены)',
      },
      updateEventModal: {
        title: 'Дополнительные приглашения отправлены',
        label: 'Дополнительные группы были приглашены.',
        message:
          'В соответствии с настройками теперь будут отправлены уведомления о событии через Push или по электронной почте дополнительным группам.',
      },
      eventRegistrationType: {
        app: 'Приложение',
        event: 'Ссылка/Адрес электронной почты',
        enterLink: 'Добавить ссылку/адрес электронной почты или что-то подобное',
      },
      parts: {
        part: 'Часть',
        addPart: 'Добавить пункт программы',
        hint: 'Объяснение',
        question: 'Вопрос',
        single: 'Только один вариант ответа',
        multi: 'Несколько вариантов ответа',
        freeText: 'Ответ свободным текстом',
        quiz: 'Викторина',
        questionnaire: 'Анкета',
        addQuestionnaire: 'Добавить анкету',
        questionnaireType: 'Варианты ответов',
        option: 'Вариант',
        addOption: 'Добавить вариант',
        showMembers: 'Участники',
        youJoined: 'Вы участвуете в:',
        totalToPay: 'Общая сумма к оплате:',
        yourAnswer: 'Ваш ответ',
        visibleForCreators: 'Видимо только для создателей',
        allAnswers: 'Все ответы:',
        addUsers: 'Добавить пользователей',
        isAnonym: 'Анонимное голосование',
        isAnonymQuiz: 'Анонимная викторина',
      },
    },
    rrEventLabels: {
      title: 'Повторение',
      MO: 'Понедельник',
      TU: 'Вторник',
      WE: 'Среда',
      TH: 'Четверг',
      FR: 'Пятница',
      SA: 'Суббота',
      SU: 'Воскресенье',
      YEARLY: { single: 'Год', regular: 'Ежегодно' },
      MONTHLY: { single: 'Месяц', regular: 'Ежемесячно' },
      WEEKLY: { single: 'Неделя', regular: 'Еженедельно' },
      DAILY: { single: 'День', regular: 'Ежедневно' },
      SINGLE: { single: 'Однократно', regular: 'Однократно' },
      CUSTOM: {
        single: 'Пользовательский...',
        regular: 'Пользовательский...',
      },
      freq: 'Частота',
      interval: 'Интервал',
      notRepeat: 'Однократно',
      every: 'Каждый',
      month: 'месяц',
      week: 'неделю',
      last: 'последний',
      on: 'в',
      everyMonthOn: 'Каждый месяц в',
      ofTheMonth: 'месяца',
      editLabel: 'Какие даты вы хотите изменить?',
      thisDate: 'Только эту дату',
      thisDateAndFollowing: 'Эту и все последующие',
      allDates: 'Все даты',
      after: 'После',
      time: 'раз',
      times: 'раз',
      neverEnds: 'Никогда не заканчивается',
      until: 'до',
      repeatedDefault: ({ frequency, freq }: { frequency: string; freq: number }) =>
        `Каждый ли${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency} повторный`,
      repeatedMonthly: ({ day, interval }: { day: string; interval: number }) =>
        `Каждый ли${interval == 1 ? '' : ` ${interval}.`} Месяц на ${day.length > 2 ? day : `${day}.`} неоднократно.`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }: { day: string; interval: number; bysetpos: number }) =>
        `Каждый ли${bysetpos == -1 ? ' последний' : ` ${bysetpos}.`} ${day} ${
          interval === 1 ? '' : ` ${interval}.`
        } Месяц повторяется.`,

      repeatedYearly: ({ interval }: { interval: number }) =>
        `Каждый ли${interval == 1 ? '' : ` ${interval}.`} Повторный год.`,
      repeatedWeekly: ({ interval, byweekday }: { interval: number; byweekday: any }) =>
        `Каждый ли${interval != 1 ? ` ${interval}.` : ''} неделя${
          !!byweekday?.length
            ? ' на ' + byweekday?.slice(0, -1).join(', ') + (byweekday?.length > 1 ? ' и ' : '') + byweekday?.slice(-1)
            : ''
        } повторный.`,
      repeatedDaily: ({ interval }: { interval: number }) =>
        `Wird jeden${interval != 1 ? ` ${interval}.` : ''} День повторяется.`,
    },
    requestsLabels: {
      receiveLabel: 'Получено',
      sentLabel: 'Отправлено',
    },
    post: {
      adTitle: 'Информация/отображение',
      toCircles: '',
      comment: 'Комментарии',
      myPost: 'Я',
      openPdf: 'Нажмите, чтобы открыть',
      deletePost: 'Удалить это сообщение?',
      posted: 'Опубликовано',
      commented: 'Прокомментировано',
      contacts: 'Контакты',
      sendToContacts: 'Сообщение всем',
      enterText: 'Напишите сообщение здесь',
      sendToCircle: `Сообщение в ${KreiseName('RUS').singleArticle}`,
      sendComment: 'Введите комментарий',
      longMessage: 'Максимум 20 000 символов',
      noComments: 'Комментариев пока нет',
      deleteComment: 'Удалить комментарий?',
      deleteEventPost: 'Удалить это сообщение? Это не приведет к удалению события',
      editPostLabel: 'Редактировать пост',
      editingPostLabel: 'Режим редактирования',
      deletePostLabel: 'Удалить пост',
      editedAt: 'Отредактировано',
      pinned: 'Прикрепленный',
      actions: {
        label: 'Действия',
        pin: {
          label: 'Закрепить пост',
          once: 'Переместить в топ один раз',
          d3: '3 дня',
          d5: '5 дней',
          w1: '1 неделя',
          w2: '2 недели',
          unpin: 'Больше не закреплять',
        },
      },
    },
    circles: {
      circlesTitle: `Подписанные ${KreiseName('RUS').multi}`,
      noCirclesTitle: `Неподписанные ${KreiseName('RUS').multi}`,
      myPost: 'Я',
      circlesInfoTitle: 'Информация и участники',
      circlesSubscribe: 'Подписаться',
      unsubscribeAlert: 'Безвозвратно покинуть закрытый групповой чат?',
      continueAlert: 'Оставить',
      readOnlyKreise: `Это закрытый для чтения чат  "Nur-lese-${
        KreiseName('RUS').single
      }". Создавать сообщения здесь могут только администраторы. Однако вы можете писать комментарии к постам.`,
    },
    messages: {
      addMessageLabel: 'Введите сообщение',
      changesSaved: 'Изменения сохранены!',
      newConversation: 'Новый диалог',
    },
    profileTabs: {
      basicTab: 'Основное',
      clubsTab: `${CorpsName('RUS').single}`,
      miscellaneosTab: 'Интересы',
      professionTab: 'Профессия',
      studyTab: StudyName('RUS')?.short,
      notificationsTab: 'Уведомления',
      privacy: 'Конфиденциальность',
    },
    profile: {
      addPhotoLabel: 'Добавить фото',
      genderInputLabel: 'Пол',
      titleLabel: 'Заголовок',
      firstNameLabel: 'Имя',
      lastNameLabel: 'Фамилия',

      passwordLabel: 'Установить пароль',
      birthDateLabel: 'Дата рождения',
      birthday: 'Дата рождения',
      changePasswordLabel: 'Изменить пароль',
      defaultCountryLabel: 'Германия',
      defaultGraduationLabel: 'Без указания',
      defaultCorpsLabel: 'нет',
      street: 'Улица + номер дома',
      houseNumberLabel: 'Номер дома',
      partner: 'Имя партнера/партнерши',
      mobileNumberLabel: 'Мобильный номер',
      handphoneNumberLabel: 'Номер мобильного телефона',
      telephoneNumberLabel: 'Номер телефона',
    },
    profileKorp: {
      verband: CorpsName('RUS').single,
      function: FunctionName('RUS'),
      dateOfJoin: CorpsEditDate('RUS'),
      beername: 'Biername',
    },
    profileClubs: {
      noUserLabel: 'Вся основная информация должна быть заполнена до начала использования приложения',
      functionLabel: `${FunctionName('RUS')}`,
      setClubLabel: `Пожалуйста, введите название ${CorpsName('RUS').single}`,
      matchClubLabel: 'The',
      matchClubContinueLabel: 'уже существует в профиле, пожалуйста, проверьте один раз',
      clubLabel: `${CorpsName('RUS').single.toUpperCase()}`,
      clubCodeLabel: `LOGIN CODE ВАШЕГО ${CorpsName('RUS').single.toUpperCase()}S`,
      entryTimeLabel: CorpsEditDate('RUS'),
      deleteClubLabel: 'Хотите ли вы удалить свое членство в клубе',
      deleteClubContinueLabel: 'Вы действительно хотите удалить свое членство?',
      selectVariant: 'Что вы хотите сделать?',
      requestExistingCorp: 'Добавить существующую запись',
      requestNewCorps: 'Запросить новую запись',
      createCorp: 'Создать новую запись',
    },
    profileProfession: {
      jobTitle: 'Название должности',
      noUserLabel: 'Вся основная информация должна быть заполнена до начала использования приложения',
      untilToday: 'До сегодняшнего дня',
      positionTitleLabel: 'Профессия',
      itemNameLabel: 'Профессия',
      employmentLabel: 'Тип занятости',
      activityLabel: ActivityName('RUS'),
      careerStageLabel: 'Стадия карьеры',
      activityDescLabel: 'Описание деятельности',
      companyLabel: CompanyName('RUS'),
      nameLabel: 'Имя',
      industryLabel: IndustryName('RUS'),
      areaLabel: 'Область',
      websiteLabel: 'Веб-сайт компании',
      employmentPeriodLabel: 'Период работы',
      fromLabel: 'От',
      toLabel: 'To',
    },
    profileStudy: {
      noUserLabel: 'Перед использованием приложения необходимо заполнить всю основную информацию.',
      universityLabel: StudyName('RUS').hochsule,
      courseLabel: StudyName('RUS').studiengang,
      degreeLabel: StudyName('RUS').abshchluss,
    },
    profileOther: {
      noUserLabel: 'Вся основная информация должна быть заполнена до начала использования приложения',
      hobbiesLabel: 'Интересы',
      culturalLabel: 'культурные спонсоры',
      socialMediaPlatform: 'Платформа социальных сетей',
      socialMediaLabel: 'ссылки на социальные сети',
      socialMediaLink: 'Ссылка',
      socialMediaError: 'Пожалуйста, введите полный URL',
    },
    profileNotifications: {
      noUserLabel: 'Вся основная информация должна быть заполнена до начала использования приложения',
      notificationsInfo:
        'Здесь вы можете активировать уведомления по электронной почте для отдельных действий (по умолчанию) или отключить их на 3 месяца',
      notificationsTitle: 'Push-уведомления:',
      notificationsDescr0: 'Вы можете активировать push-уведомления в ',
      notificationsDescr1: 'Системные настройки смартфона',
      notificationsDescr2: '.',
      mailingTitle: 'Уведомления по электронной почте:',
      mailingDescr:
        'Здесь вы можете активировать уведомления по электронной почте для отдельных действий (по умолчанию) или отключить их на 1 год',
      allNotificationsLabel: 'Все уведомления',
      postsLabel: `Сообщения в ${KreiseName('RUS').multi}`,
      messagesLabel: 'Личные сообщения',
      commentsLabel: 'Комментарии к собственным сообщениям',
      contactRequestsLabel: 'Запросы на контакты',
      eventsLabel: 'События',
      clubMemberLabel: 'Вновь зарегистрированные участники',
      enableLabel: 'Включено',
      disableToLabel: 'Выключено, пока',
      disableLabel: 'Выключено',
      ownPosts: 'Мои сообщения (почта также получена мной)',
    },
    searchInput: {
      title: 'Поиск',
      placeholder: 'Поиск',
    },
    emptyList: {
      title: 'Здесь нет содержимого (пока)',
    },
    switchLanguage: {
      placeholder: 'Язык/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'Russian (Русский)',
      SP: 'Spanish (Español)',
      NLD: 'Dutch (Nederlands)',
      POL: 'Polish (Polish)',
      title: 'Выберите язык',
    },
    filePicker: {
      takePicLabel: 'Вложения',
      uploadPicLabel: 'Сделать фото',
      selectPicLabel: 'Выбрать фото/видео',
      selectDocLabel: 'Выбрать документ',
    },
    datePicker: {
      selectDateLabel: 'Выберите дату',
      confirmDateLabel: 'Подтвердить',
      selectTimeLabel: 'Выберите время',
    },
    changePassword: {
      currentPasswordLabel: 'Текущий пароль',
      newPasswordLabel: 'Новый пароль',
      repeatPasswordLabel: 'Повторить пароль',
      changePasswordLabel: 'Изменить пароль',
    },
    appInfo: {
      appDescLabel:
        'Korponnect - это приложение для студенческого братства. Обмен опытом между корпорациями, организация мероприятий, а также профессиональный и частный нетворкинг - все в одном приложении',
      contactsLabel: InfoContactsName('RUS'),
      infoLabel: 'Информация',
      versionLabel: 'Версия приложения',
      agbLabel: 'AGB',
      dataProtectionLabel: 'Политика конфиденциальности',
      manualLabel: 'Инструкции',
      appTextLabel: 'Здесь вы можете найти наши',
      appAndLabel: 'и одно',
      appMainTextLabel: `Вы хотите добавить круг или получить другое предложение? Хотите удалить свой профиль? Просто напишите нам короткое письмо на ${support_email}.`,
      deleteFeedback: 'Причина удаления/отзыв 🙂:',
      deleteUserTitle: 'Удалить аккаунт?',
      deleteUserDesc:
        'Здесь вы можете безвозвратно удалить свой аккаунт.\b Кстати: вы также можете просто отключить уведомления. Отредактируйте настройки в разделе Профиль -> Уведомления или напишите нам по электронной почте',
      deleteUserAlert: 'Вы действительно хотите удалить свой аккаунт?',
      webLabel: 'Веб-версия',
    },
    infoMessages: {
      registartionSuccessLabel: 'Спасибо за регистрацию!',
      manualRegSuccessLabel:
        'Спасибо за регистрацию. Вы будете активированы в ближайшее время и проинформированы по электронной почте',
      linkExpiredLabel: 'Срок действия ссылки истек. Запросите смену пароля',
      emailExistLabel: 'Извините, электронная почта недоступна',
    },
    logoutAlert: {
      title: 'Выход из системы',
      text: 'Вы действительно хотите выйти из системы? Необязательно выходить, можно просто закрыть или свернуть приложение',
      подтвердить: 'Да',
      отменить: 'Отмена',
    },
    invalidInputs: {
      passwordChars: `Пароль должен быть не менее ${passwordDigits(
        'RUS',
      )} символов, включая как минимум одну букву, одну цифру и один специальный символ.`,
      fieldRequired: 'Это поле обязательно для заполнения',
      emailsNotMatching: 'Пожалуйста, убедитесь, что ваши адреса электронной почты совпадают',
      emailInvalid: 'Ваш адрес электронной почты не верен!',
      selectCorrectAnswer: 'You need to select correct answer',
      corp: 'Пожалуйста, выберите хотя бы 1 пункт',
    },
    mentoring: {
      profile: {
        rules1: 'Я соглашаюсь с',
        rules2: ' пониманием ценностей ',
        rules3: '“Наставничество для стипендиатов”.',
        menteeDescription:
          'Пожалуйста, выберите регион наставничества, чтобы вы могли получать информацию о событиях на месте. ПРИМЕЧАНИЕ: Вы можете изменить свой выбор в любое время. Это НЕ ограничивает ваш поиск наставника',
        mentorDescription:
          'Пожалуйста, заполните профиль наставника, чтобы стипендиаты могли найти вас в качестве наставника. ПРИМЕЧАНИЕ: Необходимо указать хотя бы 1 регион и хотя бы 1 предметную область',
        mentoringRegions: 'Регионы наставничества',
        reigonsShort: 'регионы',
        topics: 'предметные области',
        departments: 'факультет',
        available: 'Я доступен',
        unavailable: 'Я сейчас недоступен',
        deleteAccount: 'Удалить профиль мента и покинуть группу',
        deleteAccountDescr: 'Удалить профиль наставника и покинуть чат группы наставников?',
        uploadPdf: 'Профиль наставника (PDF)',
        myPdf: 'Мой профиль (PDF)',
        pdf: 'Профиль наставника',
      },
      tabNames: {
        info: 'Информация',
        news: 'Новости',
        profile: 'Профиль',
        search: 'поиск и запрос',
        request: 'Наставничество',
        requestDetails: 'Запрос на наставничество',
        tabDeactivated: 'Вкладка деактивирована. Пожалуйста, заполните профиль наставника',
      },
      search: {
        description:
          'Чтобы начать целенаправленный поиск, мы рекомендуем вам начать с небольшого количества полей выбора, а затем в следующем поисковом запросе расширить диапазон поиска. Если вы не находите подходящего наставника в вашем регионе, попробуйте расширить поиск на соседний регион наставничества. Критерии поиска: 1 регион, 1 до 3 областей тематики. Все поля связаны логическим оператором И!',
        searchBtn: 'Поиск',
      },
      requests: {
        mentorIncoming: 'Мои запросы на наставничество',
        mentorCurrent: 'Мои наставнические отношения',
        menteeOutgoing: 'Мои запросы на стажировку',
        menteeCurrent: 'Моя стажировка',
        cancelMentoring: 'Завершить стажировку',
        sendRequest: 'Запросить наставника',
        alreadyHaveMentor: 'Вы можете иметь только одного наставника',
        requestSuccess: 'Запрос успешно отправлен',
        requestRetracted: 'Запрос успешно отозван',
        cancelSendingRequest: 'Отменить отправку запроса на наставничество',
        approveRequest: 'Принять запрос',
        rejectRequest: 'Отклонить запрос',
        invitaionText: 'Запрос',
        rejectionText: 'Укажите причину отклонения',
        sureRetractRequest: 'Вы уверены, что хотите отозвать запрос?',
      },
    },
    downloads: {
      root: 'Главная папка',
      delete: 'Удалить',
      rename: 'Переименовать',
      areYouSureDeleteFolder: 'Действительно удалить папку? Все содержимое будет удалено!',
      renameFolder: 'Переименовать папку',
      createNewFolder: 'Создать новую папку',
      newFolderPlaceholder: 'Введите название папки',
      create: 'Создать',
      areYouSureDeleteFile: 'Вы уверены, что хотите удалить файл?',
      downloadFile: 'Скачать',
      deleteFile: 'Удалить с устройства',
      openInFinder: 'Открыть в файловом менеджере',
      cancel: 'Отмена',
    },
    globalSearch: {
      postedBy: 'опубликовал',
      found: 'Найдено',
      loadMore: 'Загрузить еще',
      enterQuery:
        'Введите любое ключевое слово для поиска. Вы можете искать по всему (имя, документ, событие, сообщение и т. д.)',
      folder: 'Папка',
      post: 'Пост',
      error: 'Введите минимум 3 символа',
    },
    kreiseSettings: {
      description: 'Описание',
      push: 'Уведомления push',
      email: 'Уведомления по электронной почте',
      subscribers: 'Подписчики',
      active: 'Активировано',
      oneYear: 'Без звука на 1 год',
      forever: 'Без звука навсегда',
      until: 'До',
      cantDeactive: 'Для этого группового чата эта настройка отключена',
      SINGLE: 'Для каждого сообщения',
      SUMMARY_ONCE: 'Резюме 1 раз в неделю',
      SUMMARY_TWICE: 'Резюме 2 раза в неделю',
      SUMMARY_DAY: 'Резюме 1 раз в день',
      OFF: 'Отключено',
      PUSH: 'Уведомление push',
      EMAIL: 'Уведомление по электронной почте',
      PUSH_AND_EMAIL: 'Уведомление push и по электронной почте',
      newUser: 'Уведомление для новых пользователей',
      statistic: 'Отправить статистику',
      off: 'Отключено',
      ownPosts: 'Отправить статистическое письмо для ваших сообщений',
      deleteMemberDescr: 'удалить?',
      deleteMemberBtn: 'Удалить',
      retractAdminRights: 'Отозвать права администратора',
      deleteAdminAndUser: 'Полностью удалить',
      toDeleteKreiseBtn: 'Удалить групповой чат',
      deleteKreiseDescr: `${KreiseName('RUS').single} unwiderruflich löschen?`,
      deleteKreiseModalTitle: 'Удалить участника?',
      addCirclesName: 'Имя',
      addKreiseDescription: 'Описание',
      addMembers: 'Добавить участников',
      createCircles: 'Создать групповой чат',
      toKreiseEditMessage: 'Примечание: Все изменения членов группы выполняются немедленно',
      kreiseCreator: 'Создатель:в этом групповом чате',
      kreiseDeleteMembersLeft: 'Групповой чат может быть удален, только если в нем остался только один участник',
      kreiseDeleteMembersLeftTitle: 'Сначала удалите участников',
      suggestionInNewsfeed: {
        admin: 'Админ',
        member: 'Участники',
        corp: `${CorpsName('RUS').single} члены`,
        corpAdmin: `${CorpsName('RUS').single} Администратор`,
      },
    },
    surveys: {
      checkVotes: 'Показать результаты',
      chooseOptions: {
        single: 'Выберите вариант.',
        multiple: 'Выберите хотя бы один вариант.',
      },
      votesModalTitle: 'Результаты',
      createModalTitle: 'Исследование',
      noVotes: 'Голоса не подавались',
      question: 'Вопрос',
      askAQuestion: 'Задать вопрос',
      allowMultiple: 'Разрешите несколько ответов',
      votes: {
        single: 'Голос',
        multiple: 'Голос',
      },
      option: 'Вариант',
      optionsLabel: 'Опции',
      anonymus: 'Анонимный опрос',
      create: 'Создать',
    },
    subscription: {
      subscription: 'Abonnements',
      pricePerMonth: 'EUR/Monat',
      month: 'Monat',
      day: 'Tag',
      otherPlans: 'Andere Tarife',
      subscriptionActive: 'Abonnement aktiv',
      until: 'bis',
      subscribe: 'Abonnieren',
      successfulPayment: {
        title: 'Zahlung erfolgreich',
        text: 'Dein Abonnement wurde erfolgreich abgeschlossen. Du kannst ab sofort die erweiterten Funktionen nutzen.',
      },
      cancel: {
        cancel: 'Abonnement kündigen',
        title: 'Abonnement kündigen?',
        text: 'Möchtest du dein Abonnement kündigen? Nach Ablauf der bezahlten Periode werden deine Zugriffsrechte zurückgestuft.',
      },
    },
  },
  {
    // Dutch
    key: 'NLD',
    key2: 'nl',
    editProfile: {
      resendLong: 'Of moeten we de validatie-e-mail opnieuw verzenden?',
      updated: 'Profiel bijgewerkt.',
      validateFieldsViaEmail:
        'Valideer de volgende gegevensvelden via e-mail. Alleen dan worden ze doorgestuurd naar het centrale ledenbeheer.',
      newValue: (value: any) => `New value: "${value}"`,
      resendEmail: 'Of moeten we de validatie-e-mail opnieuw verzenden?',
      validationPending: 'In afwachting van validatie',
      resend: 'Opnieuw versturen',
      resendDone: 'De validatiemail is opnieuw verzonden. Wacht even en controleer ook je spamfolder.',
    },
    appInfoScreen: {
      title: 'Over',
      privacy: 'Privacy-informatie',
      find: 'Hier vindt u de',
      terms: ' Gebruiksvoorwaarden ',
      and: ' en ',
      infoSection: 'App Info',
      hasUpdate: 'Er is een nieuwere versie. Klik hier om bij te werken.',
      noUpdate: 'Dit is de nieuwste versie.',
      appVerison: 'App versie:',
    },
    corpsScreen: {
      website: 'Website:',
      email: 'E-mailadres:',
      members: 'Leden',
      admins: 'Admins',
      info: 'Info',
      searchMember: 'Leden zoeken',
    },
    contactsScreen: {
      empty:
        "Er zijn hier nog geen contacten. Ga naar het hoofdmenu en selecteer 'Zoeken' → 'Naam' om nieuwe contacten te vinden.",
    },
    dialogPanel: {
      hangOn: 'Bierjongen bevestigen?',
      ok: 'OK',
    },
    dialogs: {
      emptyTitle: 'Klik op het plusje in de rechterbovenhoek om een gesprek te beginnen.',
    },
    documentsGallery: {
      modalTitle: 'Een document verwijderen',
      modalDesc: 'Dit bestand verwijderen?',
      cancel: 'Annuleren',
      delete: 'Verwijder',
      deleteConfirm: 'Het gedownloade bestand opnieuw verwijderen (alleen voor mij)?',
      downloadConfirm: 'download bevestigen?',
      availableInDownloads: `\n\n*All downloaded files are available in the Downloads folder`,
    },
    drawerScreen: {
      backdrop: 'Alle basisinformatie moet eerst worden ingevuld voordat de app kan worden gebruikt.',
    },
    kreiseInfoScreen: {
      creator: 'Maker van deze groepschat',
      revokeAdmin: (name: string) =>
        `Do you want to remove admin rights for "${name}" or remove them from the group entirely?`,
    },
    kreisePostScreen: {
      infoParticipants: (num: string | number) => `Info & Participants (${num})`,
      subscribeTitle: 'Aanmelden voor groepschat?',
      subscribeDesc: 'Wil je je abonneren op deze groepschat om geen nieuws te missen?',
      yes: 'Ja',
      later: 'Misschien later',
      dontAsk: 'Vraag niet meer naar deze groepschat',
    },
    mapScreen: {
      appUser: 'App gebruiker',
      viewIsForMember: 'Gebruikersprofielen bekijken is alleen beschikbaar voor leden.',
      all: 'All',
    },
    mentoringScreen: {
      clickMentor: "Click on the mentor's name to send a reminder or withdraw the request",
      all: 'Alle',
      startRequest: 'Start verzoek:',
      startDesc:
        'Omschrijf uw vraag om mentorschap zo duidelijk en beknopt mogelijk. Dit helpt de mentor uw vraag beter te beoordelen en snel te reageren.',
      notice: 'Opmerking:',
      noticeDesc:
        'U moet de mentor 2 weken de tijd geven om te reageren voordat u een herinnering stuurt. Trek uw lopende verzoek in voordat u contact opneemt met een andere mentor.',
      withdrawRequest: 'Verzoek intrekken',
      reminder: 'Herinnering versturen',
      reminderSuccess: 'Herinnering wordt verstuurd (+ kopie naar jou)',
      rejectRequest: 'Verzoek afwijzen',
      requestTitle: 'Mentorschap aanvragen',
      areas: 'Expertisegebieden',
      topics: 'Onderwerpen',
      withReguards: (name: string, lastname: string) => `\n\n\n(...)\n\nBest regards,\n\n${name} ${lastname}`,
      rejectionMessage: (name: string, lastname: string) =>
        `Hallo! Helaas moet ik het verzoek om mentorschap afwijzen. Ik wil graag de redenen uitleggen als je dat vraagt. Met vriendelijke groet, ${name} ${lastname}`,
      maxMessage: (max: number | string, topic: string) => `Je kunt maximaal ${max} ${topic} selecteren.`,
      decisionOpen: 'Besluit: open',
      maxMentoring: 'Max. 1 mentorverzoek toegestaan',
      maxParalelMentoring: 'Max. 1 parallelle mentoringaanvraag toegestaan',
      openHere: 'Open de ',
      profileUpdated: 'Profiel bijgewerkt!',
      mentoringProfile: 'Mentorprofiel',
      mentee: 'Mentee',
      mentor: 'Mentor',
      leaveMentoring: 'Mentorgroep verlaten',
      mentoringTitle: 'Mentorschap',
      scrollAuto: 'Voorbeeld van scrolbare autotabbladen',
      stopMentoringMessageMentor: (name: string, lastname: string) => `Mentorschap beëindigen met ${name} ${lastname}?`,
      stopMentoringMessageMentee: (name: string, lastname: string) => `Mentorschap beëindigen met ${name} ${lastname}?`,
      mentorEndedMentorship: (name: string, lastname: string) =>
        `Mentor ${name} ${lastname} heeft het mentorschap beëindigd.`,
      mentorRejectedMentorship: (name: string, lastname: string) =>
        `Mentor ${name} ${lastname} heeft uw aanvraag afgewezen.`,
      menteeEndedMentorship: (name: string, lastname: string) =>
        `De mentee ${name} ${lastname} heeft het mentorschap beëindigd.`,
      menteeRejectedMentorship: (name: string, lastname: string) =>
        `De mentee ${name} ${lastname} heeft uw verzoek afgewezen.`,

      mentorat: 'Mentorschap',
    },
    newEventScreen: {
      kreiseError: 'Selecteer een doelgroep',
      correctDate: 'Juiste datum/tijd',
      correctDateMessage: 'Einddatum/-tijd is eerder dan begindatum/-tijd. Corrigeer dit alstublieft.',
    },
    outRequestScreen: {
      backButton: 'Contactverzoeken',
      requestMessage: (name: string, lastname: string) =>
        `Hallo! Ik wil je graag toevoegen aan mijn contacten. Met vriendelijke groet.`,
    },
    participantsScreen: {
      interestedParties: 'Geïnteresseerde partijen',
      removeGuestTitle: (name: string) => `${name} verwijderen?`,
      removeGuestDesc: (name: string) => `“${name}” verwijderen uit de deelnemerslijst?`,
      informUser: (name: string) => `Informeer “${name}” over het verwijderen van de deelnemerslijst`,
    },

    partParticipantsScreen: {
      interestedParties: 'Geïnteresseerde partijen',
      removeParticipantTitle: (name: string, lastname: string) =>
        `Deelnemer “${name} ${lastname}” verwijderen uit de deelnemerslijst?`,
      removeParticipantDesc: (name: string, lastname: string) => `${name} ${lastname}” verwijderen?`,
    },
    documentsScreen: {
      deleteTitle: 'Geselecteerde bestanden:',
      cancel: 'Annuleren',
      uploadData: 'Gegevens uploaden',
    },
    postsScreen: {
      ads: 'Advertenties...',
      oldPostsForMembers: 'Oudere berichten zijn alleen zichtbaar voor leden.',
    },
    profileBasisScreen: {
      editPhoto: 'Foto bewerken',
    },
    profileBenachrichtigungenScreen: {
      fillData: 'Alle basisinformatie moet worden ingevuld voordat de app kan worden gebruikt.',
      changesInChat: 'Je kunt deze instellingen maken in de respectievelijke groepschat.',
      commentsWarning1:
        'Het uitschakelen van e-mailmeldingen voor reacties op je eigen berichten wordt niet aanbevolen, omdat belangrijke reacties dan mogelijk worden gemist.',
      commentsWarning2:
        'Het uitschakelen van e-mailmeldingen voor privéberichten wordt niet aanbevolen, omdat belangrijke berichten dan gemist kunnen worden.',
      messagesWarning1:
        'Het uitschakelen van e-mailmeldingen voor privéberichten wordt niet aanbevolen, omdat je over het algemeen erg weinig privéberichten ontvangt in vergelijking met berichten in de groepschat. Ze zijn echter wel belangrijk omdat ze meestal specifieke verzoeken aan jou bevatten. Deze (belangrijke) privéberichten kunnen worden gemist als de meldingen zijn uitgeschakeld.',
      messagesWarning2:
        'Het uitschakelen van e-mailmeldingen voor reacties op je eigen berichten wordt niet aanbevolen, omdat belangrijke reacties dan mogelijk worden gemist.',
      areYouSure: 'Weet je het zeker?',
      turnOff: 'Zet uit',
      maybeLater: 'Misschien later',
      notRecommended: 'Niet aanbevolen instelling',
      ok: 'OK',
    },
    profileBerufScreen: {
      fillData: 'Alle basisinformatie moet worden ingevuld voordat de app kan worden gebruikt.',
      defaultDate: 'Tot vandaag',
      noArea: 'Geen gebied',
      furtherActivity: 'Extra activiteit',
      deleteConfirm: 'Echt item verwijderen?',
      ok: 'OK',
    },
    profileCorpsScreen: {
      corpsname: 'Naam project',
      createHint: 'Er wordt een nieuw item aangemaakt.',
      requestHint: 'Het aanmaken van de nieuwe vermelding wordt aangevraagd.',
      status: 'Status',
      deleteConfirm: (name: string) => `Wil je echt je lidmaatschap van ${name} verwijderen?`,
      ok: 'OK',
      fillData: 'Alle basisinformatie moet worden ingevuld voordat de app kan worden gebruikt.',
      duplicatedCorps: (name: string) => `De ${name} staat al in het profiel, controleer dit eenmalig.`,
      missingCorpName: `Geef de naam van het korps.`,
      waitingActivation: 'Wachten op activering',
      requestExistingMessage: (name: string) =>
        `Lidmaatschap van “${name}” is aangevraagd. U ontvangt bericht zodra het lidmaatschap is geactiveerd.`,
      requestNewMessage: (name: string) =>
        `De aanmaak van “${name}” is aangevraagd. U ontvangt een bericht zodra deze is geactiveerd.`,
      createMessage: (name: string) => `“${name}” is aangemaakt en toegevoegd aan je profiel.`,
    },
    profileSonstigenScreen: {
      other: 'Andere (specificeer)',
    },
    profileDetailsScreen: {
      email: 'E-mail',
    },
    profilePrivacyScreen: {
      fillData: 'Alle basisinformatie moet worden ingevuld voordat de app kan worden gebruikt.',
    },
    profileStudiumScreen: {
      deleteConfirm: 'Echt item verwijderen?',
      ok: 'OK',
      fillData: 'Alle basisinformatie moet worden ingevuld voordat de app kan worden gebruikt.',
    },
    reportScreen: {
      reportUser: 'Gebruiker rapporteren',
      notMember: 'De gebruiker is niet langer lid',
      fakeProfile: 'De gebruiker maakt valse profielaanbiedingen.',
      violations1: 'De profielfoto van de gebruiker is in strijd met de richtlijnen.',
      violations2: 'De uitspraken van de gebruiker zijn in strijd met de richtlijnen.',
      violations3: 'De gebruiker verstuurt spam in de chat.',
      violations4: 'Mogelijk aanstootgevende inhoud melden/markeren',
      violations5: 'Andere gebruikers rapporteren/markeren voor mogelijke overtredingen',
      reportReason: 'Redenen voor rapportage',
      sendReport: 'Stuur',
      cancel: 'Annuleren',
      showContent: 'Toon de inhoud van deze gebruiker',
      hideContent: 'Inhoud van deze gebruiker verbergen',
    },
    resetPasswordScreen: {
      incorrectEmail: 'Uw e-mailadres is onjuist.',
    },
    serverIsDownScreen: {
      serverMaintenance: 'Serveronderhoud',
    },
    signInScreen: {
      dontHaveAccount: 'Heb je geen account?',
    },
    signUpScreen: {
      login: 'Inloggen',
      emailMatchError: 'E-mail en e-mailbevestiging komen niet overeen.',
      passwordMatchError: 'Wachtwoord en wachtwoordbevestiging komen niet overeen.',
      incorrectEmail: 'Uw e-mailadres is onjuist!',
      pleaseSelect: 'Selecteer',
      accountExistsTitle: 'Account bestaat',
      accountExistsDesc: 'Je hebt al een account met dit e-mailadres.',
      toLogin: 'Om in te loggen',
    },
    subscriptionsScreen: {
      subscribed: 'Ingeschreven:',
      noSubscribed: 'Geen bedrijf gevonden',
      otherSubscriptions: 'Andere ingeschreven organisatoren:',
      noOtherSubscriptions: `Geen ingeschreven organisatoren gevonden`,
    },
    sucheScreen: {
      searchFor: 'Zoekresultaat voor:',
      filter: 'Filter:',
      name: 'Naam',
      branch: 'Tak',
      function: 'Functie',
      status: 'Status',
      city: 'Stad',
      industry: 'Industrie',
      business: 'Bedrijf',
      profession: 'Beroep',
      training: 'Training',
      studyProgram: 'Studieprogramma',
      college: 'College',
      cancel: 'Annuleren',
      searchBar: 'BAR zoeken',
      searchTab: 'Tabblad Zoeken',
      searchTitle: 'Zoek op',
      scrollable: 'Voorbeeld van scrolbare autotabbladen',
    },
    dialogComponent: {
      close: 'Sluit',
    },
    phoneInputComponent: {
      noCountry: 'Geen land gevonden.',
    },
    cropImageHandlerComponent: {
      cancel: 'Annuleren',
      crop: 'Gewas',
    },
    datePickerComponent: {
      cancel: 'Annuleren',
      ok: 'OK',
      date: 'Datum/Tijd',
      day: 'Dag',
      month: 'Maand',
      year: 'Jaar',
      wrongDate: 'verkeerde datum',
    },
    timePickerComponent: {
      hours: 'Uren',
      minutes: 'Notulen',
    },
    responseHandlerComponet: {
      supportEmail: `Onbekende fout. Neem contact op met ${support_email}`,
    },
    searchModalComponent: {
      selectionError: 'Selecteer ten minste één inzending.',
      maximumSelection: (num: string | number) => `Selecteer maximaal ${num} vermeldingen!`,
    },
    customDropzoneComponent: {
      dropFiles: 'Drop bestanden hier',
      clickHere: ' of klik hier om te selecteren ',
    },
    documentsGalleryComponent: {
      clickToOpen: 'Klik om te openen',
      clickToDownload: 'Klik om te downloaden',
    },
    eventToolbarComponent: {
      noRegistration: 'Geen registratie!',
      notLoggedId: 'Je bent nog niet ingelogd. Dit is slechts een blijk van interesse.',
      registerVia: 'Inschrijven kan via:',
    },
    likeComponent: {
      externalGuest: 'Externe gast',
      invitedBy: (name: string) => `Uitgenodigd door ${name}`,
    },
    membersComponent: {
      interested: 'Uitgesproken interesse',
      loggedIn: 'Aangemeld',
      um: ' at ',
      invitedBy: (name: string) => `Uitgenodigd door ${name}`,
    },
    multipleSearchableInputComponent: {
      maximalMembers: `Geef een maximum op van ${maximal_membership_count} lidmaatschappen.`,
    },
    multipleSelectModalComponent: {
      all: 'Alle',
    },
    offlineModalComponent: {
      checkInternet: 'Controleer je internetverbinding.',
    },
    postsToolbarComponent: {
      membersOnly: 'Alleen leden kunnen berichten plaatsen.',
    },
    postContentComponent: {
      orginized: 'Organisator:',
      date: 'Datum:',
      ort: 'Locatie:',
    },
    beerSmileComponent: {
      bierjunge: 'Biervriend!',
    },
    buttonComponent: {
      examination: 'Examen:',
    },
    zusagenModalComponent: {
      incorrectEmail: 'Uw e-mailadres is onjuist',
      required: 'Dit veld is vereist.',
      name: 'Naam',
      email: 'E-mail (optioneel)',
      invitedBy: 'Uitgenodigd door',
      save: 'Sla',
      cancel: 'Annuleren',
      warning1: `
		  Opmerking: Registreer je hier alleen als ${guest_naming_singular}! 
		  Als je een account hebt op de app, log daar dan mee in en bevestig officieel als lid. Hartelijk dank!`,
      warning2: 'Door te registreren gaat u akkoord met de ',
      and: ' en ',
      to: '.',
    },
    formInputComponent: {
      cancelConfirmation: 'Bevestiging annuleren?',
    },
    logoComponent: {
      alt: `App Logo`,
    },
    customHistoryHook: {
      news: 'Bericht',
    },
    documentsSortingBarComponent: {
      sortBy: 'Sorteren op',
      name: 'Naam',
      dateModified: 'Datum gewijzigd',
      dateCreated: 'Datum aangemaakt',
      order: {
        name: {
          asc: 'A tot Z',
          desc: 'Z tot A',
        },
        dateModified: {
          asc: 'Nieuw naar oud',
          desc: 'Oud naar nieuw',
        },
        dateCreated: {
          asc: 'Nieuw naar oud',
          desc: 'Oud naar nieuw',
        },
      },
    },
    sentMessageComponent: {
      seen: ' - gelezen',
    },
    // part 3
    global: {
      all: 'Alle',
      postSubject: 'Onderwerp van je bericht',
      showMore: 'Meer lezen',
      toRegister: 'Naar registratie',
      addToCalendar: 'iCal / ICS',
      interested: 'Geïnteresseerd',
      loggedIn: 'Aangemeld',
      tilToday: 'Tot vandaag',
      status: 'Status',
      deletePost: 'Bericht verwijderen?',
      pdf: '(PDF)',
      time: 'Tijd',
      birthday: (age: number | string, name: string, lastname: string) => `${age}e verjaardag van ${name} ${lastname}`,
      eur: 'EUR',
      price: 'Prijs:',
      priceForMe: 'Prijs voor mij:',
      ok: 'OK',
      likesCount: (num: string | number) => `Houdt van (${num})`,
      event: 'Evenement',
      standardMessage: 'We genereren een onderwerp voor je bericht. Past dit onderwerp?',
      optionalMessage:
        'Waar gaat je bericht over? Schrijf een kort onderwerp. Je kunt AI ook een suggestie voor je laten genereren.',
      optionalMessageNote: 'Door deze functie te gebruiken, gaat u akkoord met de ',
      chatGPTTerms: 'Gebruiksvoorwaarden ChatGPT',
      chatGPTTermsLink: 'https://openai.com/policies/terms-of-use',
      optionalMessageNoteEnding: '.',
      postSubjectTitle: 'Post Onderwerp',
      generate: 'genereren',
      defaultSubject: 'Standaard onderwerp',
      memebership: 'Lidmaatschap',
      applyMemebership: 'Lidmaatschap aanvragen?',
      pendingMembership: 'Lidmaatschapsaanvraag ingediend - besluit in behandeling.',
      requestAlreadySent: 'Verzoek al verzonden',
      requestedMemebership: (kreiseName: string) => `Lidmaatschap van “${kreiseName}” is aangevraagd.`,
      yes: 'Ja',
      no: 'Geen',
      change: 'Verander',
      passwordOldMatchNewError: 'de en nieuwe wachtwoorden komen overeen',
      passwordLenghtError: 'Het wachtwoord moet minstens 6 tekens lang zijn.',
      passwordsMatchError: 'Oude en nieuwe wachtwoorden komen overeen',
      passwordsMatchError2: 'Wachtwoord en bevestiging komen niet overeen.',
      currentPassword: 'Huidig wachtwoord',
      newPassword: 'Nieuw wachtwoord',
      confirmPassword: 'Wachtwoord bevestigen',
      changePassword: 'Wachtwoord wijzigen',
      cancel: 'Annuleren',
      fits: 'Past op',
      selectChatTitle: 'Selecteer groepschat',
      selectChatDesc:
        "Selecteer minstens 1 favoriete chat om je bericht naartoe te sturen. Je vindt er meer in het hoofdmenu onder 'Groepschats'.",
      socialMedia: 'Sociale media',
      generatedText: 'We genereren tekst voor sociale media. Past deze tekst?',
      usePhoto: 'Bedankt voor je bericht! Mogen we je foto ook gebruiken voor social media kanalen?',
      maximumLenght: 'Maximaal 20.000 tekens.',
      userSharedNewPicture: 'Een gebruiker van onze app heeft een nieuwe foto gedeeld!',
      contributionTo: (kreiseName: string) => `Bijdrage aan “${kreiseName || 'All'}`,
      postWarning1:
        'Ga je gang! Je hebt nog steeds de mogelijkheid om je eigen tekst toe te voegen in de volgende stap. Bovendien zal je bericht worden beoordeeld.',
      postWarning2:
        'Na het indienen zal je bericht worden beoordeeld door een beheerder. Voor vragen kun je contact opnemen met ',
      postWarning3: ' of ',
      AGB: 'Algemene voorwaarden',
      DSE: 'Privacybeleid',
      withCheckbox:
        "Door mijn account te activeren met behulp van de functie 'Wachtwoord vergeten', accepteer ik hierbij de",
      withoutCheckbox:
        "Door in eerste instantie uw account te activeren met de functie 'Wachtwoord vergeten', accepteert u de",
      tou: 'Gebruiksvoorwaarden',
      dse: 'Privacybeleid',
      impressum: 'Wettelijke kennisgeving',
    },

    responseHandler: {
      messageLabel: `Onbekende fout. Neem contact op met ${support_email}.`,
      expiredLinkLabel: 'Link is verlopen. Vraag een nieuw wachtwoord aan.',
      emailExistLabel: 'Sorry, dit e-mailadres is al geregistreerd.',
      profileUpdated: 'Profiel bijgewerkt',
      profileUpdatedCorpsError: `Profiel bijgewerkt! Echter, de volgende ${
        CorpsName('NLD').single
      } kon niet worden toegevoegd vanwege een onjuiste inlogcode:`,
      appNeedsUpdate: 'Werk de app bij in de App Store / Google Play.',
      maximalVideoSize: 'De maximale bestandsgrootte is 2 GB!',
    },
    report: {
      notBelongLabel: `De gebruiker behoort niet langer tot een ${CorpsName('NLD').single}`,
      incProfileLabel: 'De gebruiker maakt onjuiste profielaanbiedingen',
      badPicLabel: 'De profielfoto van de gebruiker schendt de richtlijnen',
      badComLabel: 'De opmerkingen van de gebruiker zijn in strijd met de richtlijnen',
      spamLabel: 'De gebruiker verstuurt spam in de chat',
      reasonsLabel: 'Redenen voor rapportage',
    },
    drawer: {
      subscription: 'Subscription',
      news: 'Nieuws',
      kreise: KreiseName('NLD').multi,
      search: 'Zoek op',
      calendar: CalendarName('NLD'),
      contacts: 'Contacten',
      documents: `${DocumentsName('NLD')}`,
      requests: 'Verzoeken',
      messages: MessagesName('NLD'),
      profile: ProfileName('NLD'),
      logout: 'Afmelden',
      map: 'Kaart',
      mentoring: 'Mentorschap',
    },
    imageCropper: {
      cropLabel: 'Gewas',
      cancelLabel: 'Annuleren',
      chooseLabel: 'Kies',
    },
    signIn: {
      register: 'Register',
      title: 'Log in',
      loginPlaceholder: 'Postadres',
      passwordPlaceholder: 'Wachtwoord',
      forgotPassword: 'Wachtwoord vergeten?',
      loginBtn: 'Inloggen',
      noAccount: 'Nog geen account?',
      description0: 'Als je vragen hebt, kun je contact met ons opnemen via',
      description1: 'of',
      description2: '.',
      loginValidation: 'Uw e-mailadres is onjuist',
      passwordValidation: 'Het wachtwoord moet minstens 6 tekens lang zijn',
    },
    search: {
      name: 'Naam',
      corps: CorpsName('NLD').single,
      country: CountryName('NLD'),
      city: 'Stad',
      industry: IndustryName('NLD'),
      company: CompanyName('NLD'),
      profession: 'Beroep',
      hobby: 'Interesses',
      cultural: 'Culturele sponsor',
      study: 'Studie',
      office: 'Kantoor',
      degreeProgram: 'Graadprogramma',
      university: 'Universiteit',
      position: FunctionName('NLD'),
      status: 'Status',
      title: 'Zoekresultaat voor',
      filterTitle: 'Filter op',
      postCode: 'Postcode',
      global: 'Alle',
      activity: ActivityName('NLD'),
    },
    resetPassword: {
      enterEmail: 'Voer uw e-mailadres in',
      inputPlaceholder: 'Postadres',
      sendBtn: 'Stuur',
      cancelBtn: 'Annuleren',
      message: 'Als het e-mailadres bij ons bekend is, is er een link naar verzonden.',
      desc: 'Je moet het exacte e-mailadres invoeren dat is opgeslagen in de app. Controleer indien nodig je e-mails om te zien welk e-mailadres het is. ',
    },
    password: {
      resetLabel: 'Voer een nieuw wachtwoord in ',
      inputLabel: 'Nieuw wachtwoord invoeren',
    },
    signUp: {
      createAccount: 'Account aanmaken',
      inputPlaceholder: `Inlogcode van je ${CorpsName('NLD').single}'s`,
      sendBtn: 'Ga verder',
      descFirst: 'Door te registreren gaat u akkoord met onze',
      descSecond: 'en bevestig dat u onze',
      descThird: `Als je vragen hebt, neem dan gerust contact met ons op via ${support_email} of ${support_phone}.`,
      terms: 'algemene voorwaarden ',
      policy: 'privacybeleid.',
      description: 'Opmerking',
      generatePassword: 'Wachtwoord genereren',
      noCodeSuccessRegister: 'Bedankt voor je registratie! Je ontvangt een e-mail zodra je bent geactiveerd.',
    },
    signUpDefault: {
      withoutCode: 'Zonder code',
      createAccount: 'Registreer hier',
      simpleRegister: 'Zonder uitnodigingscode',
      existingAccount: 'Bestaat de account al?',
      descr01: 'Ik ben het eens met de',
      descr0: 'Door te registreren gaat u akkoord met onze',
      descr1: '(algemene voorwaarden) en bevestigt u dat u onze',
      descr2: '(privacybeleid voor gegevens).',
      guest: 'Registreren als gast',
      withCodeWithDescr: `Registreren als ${member_naming_singular}`,
      withCode: 'Met code',
    },
    signUpManual: {
      title: 'Registratie',
      photoDesc: 'Profielfoto toevoegen',
      genderInput: 'Geslacht',
      firstName: 'Voornaam',
      lastName: 'Achternaam',
      club: CorpsName('NLD').single,
      specify: 'specificeren',
      emailPlaceholder: 'E-mailadres',
      emailHint: 'Gebruik een langgebruikt e-mailadres.',
      repeatEmailPlaceholder: 'E-mailadres herhalen',
      passwordPlaceholder: 'Wachtwoord instellen',
      repeatPasswordPlaceholder: 'Herhaal wachtwoord',
      postcode: 'Postcode',
      place: 'Woonplaats',
      country: 'Land',
      loginCode: 'Code uitnodiging',
      mobilePlaceholder: 'Mobiel (niet zichtbaar in profiel)',
      corps: SignUpCorpsName('NLD'),
      searchCountry: 'Land zoeken',
    },
    backToLogin: {
      desc: 'Heb je al een account? ',
      login: 'Inloggen',
    },
    backToSignUp: {
      desc: 'Nog geen account? ',
      register: 'Registreer',
    },
    privacy: {
      desc: 'Ja, ik accepteer de',
      btn: 'algemene voorwaarden',
      zu: '',
      map: {
        title: 'Privacyverklaring',
        privacyNotice: `Deze kaart is geïmplementeerd in overeenstemming met GDPR. Meer informatie is te vinden in ons privacybeleid.`,
        additionalInfo: {
          intro: 'De spelden op de kaart zijn',
          inaccurateDisplay: 'alleen onnauwkeurig weergegeven',
          locationBasis: ', gebaseerd op postcode/plaats maar zonder straat/nr.',
          noConclusion: 'Dit betekent dat er geen conclusies',
          onAddresses: 'kunnen worden getrokken over specifieke gebruikersadressen',
          conclusion: '.',
        },
        agree: 'Begrepen',
        linkName: 'Privacybeleid',
        options: {
          doNotShowAgain: 'Niet meer weergeven',
          doNotShowMeOnMap: 'Toon me niet op de kaart (zelfs niet bij benadering)',
        },
      },
      restrictions: {
        overall: 'Deze functie is alleen toegankelijk voor leden.',
        overallWilkar: 'Deze functie is niet toegankelijk om redenen van gegevensbescherming.',

        profile:
          'Alleen leden kunnen gebruikersprofielen bekijken. Je kunt echter wel een privébericht schrijven naar gebruikers.',
        groupchat: `De ${KreiseName('NLD').single} zijn alleen toegankelijk voor leden.`,
        groupchatWilkar: `Om redenen van gegevensbescherming zijn de ${
          KreiseName('NLD').single
        } alleen toegankelijk voor leden.`,
        comments: 'Reacties zijn alleen zichtbaar voor leden.',
        commentsWilkar: 'Om redenen van gegevensbescherming zijn opmerkingen alleen zichtbaar voor leden.',
        likes: 'De lijst met likes kan alleen worden bekeken door leden.',
        post: 'Alleen leden kunnen artikelen plaatsen.',
        participantsList: 'De deelnemerslijst kan alleen door leden worden bekeken.',
        commentsOnlyForMembers: 'Reacties zijn alleen zichtbaar voor leden.',
        postsLimitation: 'Oudere berichten kunnen alleen door leden worden bekeken.',
      },
      settings: {
        guestPost: {
          title: `Geverifieerde ${guest_naming_plural} kan mijn berichten zien`,
          ask: 'Altijd vragen',
          no: 'Geen',
          yes: 'Ja',
        },
      },
      guestModal: {
        title: 'Kunnen gasten dit bericht zien? Je kunt deze instelling ook achteraf wijzigen.',
        dontAskAgain: 'Vraag het niet opnieuw (je kunt deze instelling ook wijzigen onder “Profiel” → “Privacy”).',
      },
      post: {
        ifShown: `Verberg voor ${guest_naming_plural}`,
        ifHidden: `Show for ${guest_naming_plural}`,
        ifShownModal: `Het bericht is nu niet langer zichtbaar voor ${guest_naming_plural}.`,
        ifHiddenModal: `Het bericht is nu zichtbaar voor ${guest_naming_plural}.`,
      },
    },
    searchModal: {
      landTitle: 'Kies een land',
      corpsTitle: `Selecteer een ${CorpsName('NLD').single}`,
      pleaseSelect: 'Selecteer',
    },
    btn: {
      sendBtn: 'Stuur',
      cancelBtn: 'Annuleren',
      select: 'Selecteer',
      cancelEventBtn: acceptEventName('NLD').cancel,
      acceptBtn: acceptEventName('NLD').accept,
      saveBtn: 'Sla',
      clearBtn: 'Verwijder',
      acceptReqBtn: 'Accepteer',
      declineReqBtn: 'Daling',
      updateBtn: 'Update',
      declineBtn: 'Daling',
      yesUnderstood: 'Ja, ik begrijp het',

      ok: 'OK',
      cancel2: 'Annuleren',
    },
    validation: {
      email: 'Uw e-mailadres is onjuist',
      password: 'Het wachtwoord moet minstens 6 tekens lang zijn',
      repeatEmail: 'E-mail en e-mail voor opnieuw proberen komen niet overeen',
      repeatPassword: 'Wachtwoord en herhaling komen niet overeen',
      required: 'Dit veld is verplicht',
      matchPasswords: 'Oud en nieuw wachtwoord komen overeen',
      dontMatchPasswords: 'Wachtwoord en herhaling komen niet overeen',
    },
    init: {
      title: 'Laden',
      overlayLabel: 'Controleer uw internetverbinding',
    },
    screenTitles: {
      postsTitle: 'Nieuws',
      circlesTitle: KreiseName('NLD').multi,
      searchTitle: 'Zoek op',
      profileTitle: ProfileName('NLD'),
      eventTitle: CalendarName('NLD'),
      eventDetailsTitle: 'Evenement',
      newEventTitle: 'Evenement toevoegen',
      contactsTitle: 'Contacten',
      contactReqTitle: 'Contactverzoeken',
      reqDetailsTitle: 'Details aanvragen',
      outReqTitle: 'Contactverzoek',
      messagesTitle: MessagesName('NLD'),
      changePasswordTitle: 'Wachtwoord wijzigen',
      appInfoTitle: 'Info',
      reportTitle: 'Gebruiker rapporteren',
      createKreiseTitle: 'Groep chat maken',
      eventParticipants: 'Deelnemers',
    },
    profileDetails: {
      showFilters: 'Filters tonen',
      user: 'Gebruikers',
      mainTitleLabel: ProfileName('NLD'),
      basisLabel: 'Basic',
      clubLabel: CorpsName('NLD').single,
      clubLabelPlural: CorpsName('NLD').multi,
      professionLabel: 'Beroep',
      studyLabel: StudyName('NLD').short,
      uploadPhotoLabel: 'Foto uploaden',
      editPhotoLabel: 'Foto bewerken',
      profileCompletedLabel: 'Profiel ingevuld om',
      profileCompletedGLabel: '',
      birthday: 'Geboortedatum',
      functionLabel: 'Functie',
      dateOfJoinLabel: 'Datum Lende',
      positionDescLabel: 'Beroep',
      companyLabel: CompanyName('NLD'),
      nameLabel: 'Naam',
      websiteLabel: 'Website',
      fromLabel: 'Van',
      toLabel: 'Naar',
      delete: 'verwijderen',
      untilToday: 'Tot vandaag',
      degreeLabel: StudyName('NLD').abshchluss,
      miscellaneos: 'Overige',
      hobbies: 'Interesses',
      cultural: 'Culturele sponsors',
      positionLabel: 'Positie',
      typeOfEmplLabel: 'Type werk',
      activityLabel: ActivityName('NLD'),
      careerStageLabel: 'Carrièrestadium',
      activityDescLabel: 'Beschrijving van de activiteit',
      industryLabel: IndustryName('NLD'),
      areaLabel: 'Gebied',
      employmentPeriodLabel: 'Periode van tewerkstelling',
      universityLabel: 'Universiteit',
      courseLabel: 'Studieprogramma',
      firmaLabel: 'Firma',

      ageLabel: 'Leeftijd',
      birthdayLabel: 'Verjaardag',
      editProfileLabel: 'Profiel bewerken',
      addContactLabel: 'Toevoegen aan contacten',
      sendMessageLabel: 'Verstuur bericht',
      requestSentLabel: 'Vriendschapsverzoek sturen',
      sendRequestSuccessLabel: 'Verzoek succesvol verzonden',
      reportSentLabel: 'Gebruiker heeft al gerapporteerd',
      addReportLabel: 'Rapport verzenden',
      contactsLabel: 'Contacten',
      showBirthday: 'Contacten zien mijn verjaardag',
      street: 'Straat + Nr. ',
      houseLabel: 'Huisnummer',
      houseNumberLabel: 'Huisnummerr',
      partner: 'Naam partner',
      mobileNumberLabel: 'Mobiel nummer',
      handphoneNumberLabel: 'Telefoonnummer',
      telephoneNumberLabel: 'Telefoonnummer',
      leibbursch: 'Mentor',
      leibfuechse: 'Mentee',

      bio: 'Over mij',
      addDocuments: 'Documenten toevoegen',
      image: 'Bild',
      video: 'Video',
      pdf: 'PDF',
      link: 'Link',
      guest: 'Gast',
      firstName: 'Voornaam',
      lastName: 'Achternaam',
      title: 'Titel',
      email: 'E-mail',
      name: 'Voornaam',
      lastname: 'Achternaam',
      country: CountryName('NLD'),
      wohnort: 'Stad',
      postcode: 'POSTCODE',
      inReview: 'Bevestiging via e-mail in behandeling',
    },
    calendar: {
      birthdayLabel: 'Verjaardag van',
      noEventsLabel: 'Er zijn geen evenementen voor de geselecteerde datum',
      allTabLabel: 'Alle',
      myTabLabel: 'Mijn evenementen',
      clubsTabLabel: `${CorpsName('NLD').single}`,
      subsTabLabel: 'Abonnementen',
      subscribedTabLabel: 'Ingeschreven',
      otherTabLabel: 'Anderen',

      error: 'De begindatum/tijd moet voor de einddatum/tijd liggen.',
      filter: {
        all: 'Alle',
        invited: 'Uitgenodigd',
        confirmed: 'Bevestigd',
      },
    },
    eventLabels: {
      advencedSettings: 'Geavanceerde instellingen',
      recipientConfirmation: 'Stuur een e-mail ter bevestiging van de registratieontvangst',
      addHint: 'Voeg hint toe',
      recipientConfirmationHint: 'Hint',
      approvalConfirmation: 'Stuur een bevestigingsmail na goedkeuring',
      approvalConfirmationHint: 'Hint',
      organizer: 'Organisator',
      registrationVia: 'Registratie via',
      maximalParticipants: 'Maximaal aantal deelnemers',
      participantsWarning: 'Het aantal deelnemers is beperkt',
      interested: 'Geïnteresseerd',
      notInterested: 'Niet geïnteresseerd',
      removeFromWaitList: 'Verwijderen van wachtlijst',
      addEventTitleImage: 'Titelafbeelding toevoegen',
      changeEventTitleImage: 'Titelafbeelding wijzigen',
      inviteTo: 'Uitnodigen voor',
      addEndDate: 'Einddatum/tijd invoeren',
      eventLabel: 'Evenement',
      startLabel: 'Begint bij',
      locationLabel: 'Locatie',
      participantLabel: 'Deelnemers',
      moreInfo: 'Meer informatie',
      commentsLabel: 'Reacties',
      noCommentsLabel: 'Nog geen opmerkingen',
      eventStartLabel: 'Start',
      eventEndLabel: 'Einde',
      descLabel: 'Beschrijving',
      creatorLabel: 'Gemaakt door',
      titleLabel: 'Titel',
      deleteEventTitle: 'Gebeurtenis verwijderen?',
      deleteEventLabel: 'Deze gebeurtenis verwijderen?',
      clubsTitle: CorpsName('NLD').single,
      gruppenchat: 'Selecteer groep(en) chat',
      contactsTitle: 'Contacten',
      delCommentLabel: 'Deze opmerking verwijderen?',
      sendInvite: 'Uitnodiging sturen naar',
      selectCorps: 'Doelgroep selecteren',
      externalGuest: 'Externe gasten uitnodigen',
      externalGuestPlaceholder: 'E-mailadres invoeren',
      attachmentLabel: 'Bijlage',
      shareLinkLabel: 'Link delen',
      linkLabel: 'Registreren Gast',
      copyLinkLabel: 'Link kopiëren',
      iCalDownload: 'Kalenderbestand',
      timezone: 'Tijdzone',
      visibility: 'Zichtbaarheid',
      errorDate: 'Datum ligt in het verleden',
      startAfterEndError: 'De einddatum moet na de begindatum liggen',
      members: 'Leden',
      waitlist: 'Wachtlijst',
      onWaitlist: 'op de wachtlijst',
      leaveWaitlist: 'Wachtlijst verlaten?',
      leaveWaitlistDesc: `Verwijderen van wachtlijst? Als je later weer op de wachtlijst komt, kun je achteraan in de rij worden geplaatst.`,
      limitAchieved: (limit: number) =>
        `Het maximum aantal deelnemers van ${limit} is bereikt. Je bent op de wachtlijst geplaatst.`,
      visibilityOptions: {
        allWithoutGuests: 'Leden en op website',
        invited_member: 'Alleen uitgenodigde leden',
        verified_member: 'Alle leden',
        authorized_member: 'Alle leden en gasten',
        all: 'Members, guests and on website',
      },
      modalEvent: {
        title: 'Evenement gemaakt',
        subTitle: 'De gebeurtenis werd gecreëerd.',
        content:
          'Leden Afhankelijk van de gebruikersinstellingen worden nu push- of e-mailmeldingen voor het evenement verzonden. Als je dit hebt ingesteld onder “Profiel” → “Meldingen”, ontvang je zelf ook een e-mailnotificatie over het evenement.',
      },
      modalComment: {
        title: 'Informeer wie?',
        message: 'Wie moet op de hoogte worden gebracht van je opmerking?',
        optionAll: 'Alle deelnemers',
        optionNone: 'Alleen organisator(en)',
        button: 'Stuur commentaar',
      },
      cancelModal: {
        title: 'Annuleren of verwijderen?',
        message: 'Gebeurtenis annuleren of verwijderen?',
        optionAll: 'Annuleren (deelnemers worden geïnformeerd)',
        optionNone: 'Verwijderen (deelnemers worden niet geïnformeerd)',
      },
      updateEventModal: {
        title: 'Verdere uitnodigingen verstuurd',
        label: 'De andere groepen werden uitgenodigd.',
        message:
          'Afhankelijk van de instellingen worden nu pushmeldingen of e-mailmeldingen over het evenement naar de andere groepen gestuurd.',
      },
      eventRegistrationType: {
        app: 'App',
        event: 'Link/E-mailadres',
        enterLink: 'Link/mailadres of iets dergelijks toevoegen',
      },
      parts: {
        part: 'Deel',
        addPart: 'Programmapunt toevoegen',
        hint: 'Uitleg',
        question: 'Vraag',
        single: 'Slechts één optie selecteerbaar',
        multi: 'Verschillende opties beschikbaar',
        freeText: 'Antwoord in vrije tekst',
        quiz: 'Quiz',
        questionnaire: 'Vragenlijst',
        addQuestionnaire: 'Vragenlijst toevoegen',
        questionnaireType: 'Antwoordopties',
        option: 'Optie',
        addOption: 'Optie toevoegen',
        showMembers: 'Deelnemers',
        youJoined: 'Je neemt deel aan:',
        totalToPay: 'Totaal te betalen bedrag:',

        yourAnswer: 'Uw antwoord',
        visibleForCreators: 'Alleen zichtbaar voor makers',
        allAnswers: 'Alle antwoorden:',
        addUsers: 'Gebruiker toevoegens',
        isAnonym: 'Anoniem stemmen',
        isAnonymQuiz: 'Quiz Anonymes',
      },
    },
    rrEventLabels: {
      title: 'Herhaling',
      MO: 'Maandag',
      TU: 'Dinsdag',
      WE: 'Woensdag',
      TH: 'Donderdag',
      FR: 'Vrijdag',
      SA: 'Zaterdag',
      SU: 'Zondag',
      YEARLY: { single: 'Year', regular: 'Annually' },
      MONTHLY: { single: 'Month', regular: 'Monthly' },
      WEEKLY: { single: 'Week', regular: 'Weekly' },
      DAILY: { single: 'Day', regular: 'Daily' },
      SINGLE: { single: 'Unique', regular: 'Unique' },
      CUSTOM: {
        single: 'Door gebruiker gedefinieerd...',
        regular: 'Door gebruiker gedefinieerd...',
      },
      freq: 'Frequentie',
      interval: 'Interval',
      notRepeat: 'Uniek',
      every: 'Elke',
      month: 'maand',
      week: 'week',
      last: 'laatste',
      on: 'op',
      everyMonthOn: 'Elke maand op',
      ofTheMonth: 'van de maand',
      editLabel: 'Welke data wil je veranderen?',
      thisDate: 'Deze datum alleen',
      thisDateAndFollowing: 'Dit en alle volgende',
      allDates: 'Alle data',
      after: 'Na',
      time: 'tijd',
      times: 'keer',
      neverEnds: 'Eindigt nooit',
      until: 'tot de',
      repeatedDefault: ({ frequency, freq }: { frequency: string; freq: number }) =>
        `Is repeated every${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency}`,
      repeatedMonthly: ({ day, interval }: { day: string; interval: number }) =>
        `Is repeated every${interval == 1 ? '' : ` ${interval}.`} month on ${day.length > 2 ? day : `${day}.`}`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }: { day: string; interval: number; bysetpos: number }) =>
        `Is repeated every ${bysetpos == -1 ? ' last' : `${bysetpos}.`} ${day} of ${
          interval === 1 ? '' : `${interval}.`
        } month`,
      repeatedYearly: ({ interval }: { interval: number }) =>
        `Is repeated every${interval == 1 ? '' : ` ${interval}.`} year`,
      repeatedWeekly: ({ interval, byweekday }: { interval: number; byweekday: any }) =>
        `Is repeated every${interval != 1 ? ` ${interval}.` : ''} week${
          !!byweekday?.length
            ? ' on ' +
              byweekday?.slice(0, -1).join(', ') +
              (byweekday?.length > 1 ? ' and ' : '') +
              byweekday?.slice(-1)
            : ''
        }`,
      repeatedDaily: ({ interval }: { interval: number }) =>
        `Is repeated every${interval != 1 ? ` ${interval}.` : ''} day`,
    },
    requestsLabels: {
      receiveLabel: 'Ontvangen',
      sentLabel: 'Verzonden',
    },
    post: {
      adTitle: 'Informatie/Advertentie',
      toKreise: 'naar',
      comment: 'Reacties',
      myPost: 'Ik',
      openPdf: 'Klik om te openen',
      deletePost: 'Dit bericht verwijderen?',
      posted: 'Geplaatst op',
      commented: 'Becommentarieerd',
      contacts: 'Contacten',
      sendToContacts: 'Bericht voor iedereen',
      enterText: 'Schrijf hier een bericht',
      sendToCircle: `Voer het bericht in ${KreiseName('NLD').single}`,
      sendComment: 'Geef commentaar',
      longMessage: 'Maximaal 20.000 tekens',
      noComments: 'Nog geen opmerkingen',
      deleteComment: 'Commentaar verwijderen?',
      deleteEventPost: 'Dit bericht verwijderen? Hiermee wordt het evenement niet verwijderd.',
      editPostLabel: 'Bewerk bericht',
      editingPostLabel: 'Bewerkingsmodus',
      deletePostLabel: 'Verwijder bericht',
      editedAt: 'Bewerkt',
      pinned: 'Gepend',
      actions: {
        label: 'Postacties',
        pin: {
          label: 'Pinpaal',
          once: 'Eenmaal omhoog',
          d3: '3 dagen',
          d5: '5 dagen',
          w1: '1 week',
          w2: '2 weeks',
          unpin: 'Verwijder',
        },
      },
    },
    circles: {
      circlesTitle: `Ingeschreven ${KreiseName('NLD').multi}`,
      noCirclesTitle: `Uitgeschreven ${KreiseName('NLD').multi}`,
      myPost: 'ik',
      circlesInfoTitle: 'Info & Leden',
      circlesSubscribe: 'Aanmelden',
      unsubscribeAlert: 'Gesloten groepschat onherroepelijk verlaten?',
      continueAlert: 'Verlaat',
      readOnlyKreise: `Dit is een “alleen-lezen ${
        KreiseName('NLD').single
      }”. Alleen admins kunnen hier berichten plaatsen. Je kunt wel opmerkingen plaatsen bij de berichten.`,
    },
    messages: {
      addMessageLabel: 'Bericht invoeren',
      changesSaved: 'Wijzigingen opgeslagen!',
      newConversation: 'Nieuw gesprek',
    },
    profileTabs: {
      basicTab: 'Basis',
      clubsTab: CorpsName('NLD').single,
      miscellaneosTab: 'Interesses',
      professionTab: 'Beroep',
      studyTab: StudyName('NLD')?.short,
      notificationsTab: 'Notifications',
      privacy: 'Privacy',
    },
    profile: {
      addPhotoLabel: 'Profielfoto toevoegen',
      genderInputLabel: 'Geslacht',
      titleLabel: 'Titel',
      firstNameLabel: 'Voornaam',
      lastNameLabel: 'Achternaam.',

      passwordLabel: 'Wachtwoord instellen',
      birthDateLabel: 'Geboortedatum',
      birthday: 'Geboortedatum',
      changePasswordLabel: 'Wachtwoord wijzigen',
      defaultCountryLabel: 'Duitsland',
      defaultGraduationLabel: 'Geen informatie',
    },
    profileKorp: {
      verband: CorpsName('NLD').single,
      function: FunctionName('NLD'),
      dateOfJoin: CorpsEditDate('NLD'),
      beername: 'Naam bier',
    },
    profileClubs: {
      noUserLabel: 'Alle basisinformatie moet worden ingevuld voordat de app kan worden gebruikt.',
      functionLabel: 'Functie',
      setClubLabel: `Vermeld de naam van de ${CorpsName('NLD').single}.`,
      matchClubLabel: 'De',
      matchClubContinueLabel: 'bestaat al in het profiel, controleer opnieuw.',
      clubLabel: CorpsName('NLD').single.toUpperCase(),
      clubCodeLabel: `LOGIN CODE OF YOUR ${CorpsName('NLD').single.toUpperCase()}`,
      entryTimeLabel: CorpsEditDate('NLD'),
      deleteClubLabel: 'Weet je zeker dat je je lidmaatschap van',
      deleteClubContinueLabel: '?',
      selectVariant: 'Selecteer variant',
      requestExistingCorp: 'Bestaand corp aanvragen',
      requestNewCorps: 'Nieuw korps aanvragen',
      createCorp: 'Maak',
    },
    profileProfession: {
      jobTitle: 'Functie',
      noUserLabel: 'Alle basisinformatie moet worden ingevuld voordat de app kan worden gebruikt.',
      untilToday: 'Tot vandaag',
      positionTitleLabel: 'Positie',
      itemNameLabel: 'Beroep',
      employmentLabel: 'Type werk',
      activityLabel: ActivityName('NLD'),
      careerStageLabel: 'Carrièrestadium',
      activityDescLabel: 'Beschrijving van de activiteit',
      companyLabel: CompanyName('NLD'),
      nameLabel: 'Naam',
      industryLabel: IndustryName('NLD'),
      areaLabel: 'Gebied',
      websiteLabel: 'Website',
      employmentPeriodLabel: 'Periode van tewerkstelling',
      fromLabel: 'Van',
      toLabel: 'Naar',
    },
    profileStudy: {
      noUserLabel: 'Alle basisinformatie moet worden ingevuld voordat de app kan worden gebruikt.',
      universityLabel: StudyName('NLD').hochsule,
      courseLabel: StudyName('NLD').studiengang,
      degreeLabel: StudyName('NLD').abshchluss,
    },
    profileOther: {
      noUserLabel: 'Alle basisinformatie moet worden ingevuld voordat de app kan worden gebruikt.',
      hobbiesLabel: 'Interesses',
      culturalLabel: 'Culturele sponsors',
      socialMediaPlattform: 'Sociale media-platform',
      socialMediaLabel: 'Social Media Links',
      socialMediaLink: 'Link',
      socialMediaError: 'Voer volledige URL in',
    },
    profileNotifications: {
      noUserLabel: 'Alle basisinformatie moet worden ingevuld voordat de app kan worden gebruikt.',
      notificationsTitle: 'Pushmeldingen:',
      notificationsDescr0: 'Je kunt de pushberichten instellen in de ',
      notificationsDescr1: 'systeeminstellingen van de smartphone.',
      notificationsDescr2: '',
      mailingTitle: 'E-mailmeldingen:',
      mailingDescr: 'Activeer e-mailmeldingen voor individuele activiteiten (standaard) of deactiveer ze voor 1 jaar.',
      allNotificationsLabel: 'Alle meldingen',
      postsLabel: `Berichten in ${KreiseName('NLD').multi}`,
      messagesLabel: 'Privéberichten',
      commentsLabel: 'Commentaar op eigen bijdragen',
      contactRequestsLabel: 'Contactverzoeken',
      eventsLabel: 'Evenementen',
      clubMemberLabel: 'Nieuwe geregistreerde leden',
      enableLabel: 'Op',
      disableToLabel: 'Uit tot',
      disableLabel: 'Uit',
      ownPosts: 'Mijn bijdragen (post ook door mij ontvangen)',
    },
    searchInput: {
      title: 'Zoek op',
      placeholder: 'Zoek op',
    },
    emptyList: {
      title: 'Hier is (nog) geen inhoud',
    },
    switchLanguage: {
      placeholder: 'Language/Language',
      ENG: 'Engels (English)',
      GER: 'Duits (Deutsch)',
      ITA: 'Italiaans (Italienisch)',
      FRA: 'Frans (Français)',
      HNG: 'Hongaars (Magyar)',
      JAP: 'Japans (ジャパンスカ)',
      CIN: 'Chinees (中文)',
      KOR: 'Koreaans (한국어)',
      RUS: 'Russisch (Русский)',
      SP: 'Spaans (Español)',
      NLD: 'Dutch (Nederlands)',
      POL: 'Polish (Polish)',
      title: 'Kies taal',
    },
    filePicker: {
      takePicLabel: 'Bijlagen',
      uploadPicLabel: 'Foto uploaden',
      selectPicLabel: 'Selecteer foto of video',
      selectDocLabel: 'Selecteer document',
    },
    datePicker: {
      selectDateLabel: 'Selecteer datum',
      confirmDateLabel: 'Datum bevestigen',
      selectTimeLabel: 'Selecteer tijd',
    },
    changePassword: {
      currentPasswordLabel: 'Huidig wachtwoord',
      newPasswordLabel: 'Nieuw wachtwoord',
      repeatPasswordLabel: 'Herhaal wachtwoord',
      changePasswordLabel: 'Wachtwoord wijzigen',
    },
    appInfo: {
      appDescLabel:
        'Korponnect is de app voor studenten. Uitwisseling tussen bedrijven, organisatie van evenementen en professioneel en privé netwerken - alles in één app.',
      contactsLabel: InfoContactsName('NLD'),
      infoLabel: 'Informatie',
      versionLabel: 'App-versie',
      agbLabel: 'AGB',
      dataProtectionLabel: 'Verklaring gegevensbescherming',
      manualLabel: 'Handmatig',
      appTextLabel: 'U kunt ons hier vinden',
      appAndLabel: 'en een',
      appMainTextLabel: `Wil je een cirkel toevoegen of heb je een andere suggestie? Wil je je profiel verwijderen? Stuur een korte e-mail naar ${support_email}.`,
      deleteFeedback: ' Reden voor verwijderen account / feedback 🙂:',
      deleteUserTitle: 'Account verwijderen?',
      deleteUserDesc:
        'Hier kun je je account onherroepelijk verwijderen: Je kunt de meldingen ook gewoon uitzetten. Wijzig de instellingen onder profiel -> Meldingen of schrijf ons een e-mail. ',
      deleteUserAlert: 'Al uw gegevens worden voor altijd gewist, gaat u verder?',
      webLabel: 'Webversie',
    },
    infoMessages: {
      registartionSuccessLabel: 'Bedankt voor het registreren!',
      manualRegSuccessLabel: 'Bedankt voor je registratie! Het profiel wordt binnenkort geactiveerd.',
      linkExpiredLabel: 'Link is verlopen. Vraag een nieuw wachtwoord aan.',
      emailExistLabel: 'Sorry, de e-mail is niet beschikbaar.',
    },
    logoutAlert: {
      title: 'Afmelden',
      text: 'Wil je ze echt uitloggen? Je hoeft niet uit te loggen, je kunt de app gewoon sluiten of minimaliseren.',
      confirm: 'Ja',
      dismiss: 'Geen',
    },
    invalidInputs: {
      passwordChars: `Wachtwoord moet minimaal het volgende bevatten ${passwordDigits(
        'ENG',
      )} characters, ten minste één letter, één cijfer en één speciaal teken.`,
      fieldRequired: 'Dit veld is verplicht.',
      emailsNotMatching: 'Zorg ervoor dat je e-mails overeenkomen met',
      emailInvalid: 'De informatie van uw e-mailadres is niet correct!',
      selectCorrectAnswer: 'Selecteer het juiste antwoord',
      corp: 'Selecteer ten minste 1 inzending',
    },
    mentoring: {
      profile: {
        rules1: 'Ik ben het eens met de',
        rules2: ' Begrip van waarden ',
        rules3: '"Mentoring for Scholars” aan.',
        menteeDescription:
          'Selecteer uw mentorregio zodat u op de hoogte kunt blijven van evenementen op locatie. OPMERKING: U kunt uw selectie op elk moment wijzigen. Dit beperkt uw zoektocht naar een mentor NIET..',
        mentorDescription:
          'Vul uw mentorprofiel in zodat beurshouders u als mentor kunnen vinden. OPMERKING: Minstens 1 regio en min. 1 vakgebied zijn vereist.',
        mentoringRegions: "Mentorregio's",
        reigonsShort: "Regio's",
        topics: 'Onderwerpen',
        departments: 'Afdeling',
        available: 'Ik ben beschikbaar',
        unavailable: 'Ik ben momenteel niet beschikbaar',
        deleteAccount: 'Mentorprofiel verwijderen & groep verlaten',
        deleteAccountDescr: 'Mentorprofiel verwijderen en mentorgroepchat verlaten?',
        uploadPdf: 'Profiel mentor (PDF)',
        myPdf: 'Mijn profiel (PDF)',
        pdf: 'Mentor Profiel',
      },
      tabNames: {
        info: 'Info',
        news: 'Nieuws',
        profile: 'Profiel',
        search: 'Zoeken en aanvragen',
        request: 'Mentoraten',
        requestDetails: 'Verzoek om mentorschap',
        tabDeactivated: 'Tabblad gedeactiveerd. Vul mentorprofiel in.',
      },
      search: {
        description:
          'Om gericht te zoeken, raden we u aan te beginnen met een paar selectievelden en vervolgens het zoekraster uit te breiden in een nieuwe zoekronde. Als u in uw regio geen geschikte mentor vindt, breid de zoekopdracht dan uit naar een naburige mentorregio. Zoekcriteria: 1 regio, 1 tot 3 themavelden',
        searchBtn: 'Zoek op',
      },
      requests: {
        mentorIncoming: 'Mijn mentorverzoeken',
        mentorCurrent: 'Mijn mentorschappen',
        menteeOutgoing: 'Mijn mentorverzoek',
        menteeCurrent: 'Mijn mentorschap',
        cancelMentoring: 'Mentorschap beëindigen',
        sendRequest: 'Verzoek als mentor',
        alreadyHaveMentor: 'Je kunt maar één mentor hebben',
        requestSuccess: 'Verzoek succesvol verzonden',
        requestRetracted: 'Aanvraag succesvol ingetrokken',
        cancelSendingRequest: 'Mentorverzoek annuleren',
        approveRequest: 'Verzoek accepteren',
        rejectRequest: 'Afwijzen mentee',
        invitaionText: 'Aanvraag',
        rejectionText: 'Geef redenen voor je afwijzing',
        sureRetractRequest: 'Verzoek echt intrekken?',
      },
    },
    downloads: {
      move: 'Verplaatsen',
      moveToFolder: 'Naar map verplaatsen',
      noFoldersMessage: 'Geen mappen beschikbaar',
      root: 'Home',
      delete: 'Verwijder',
      rename: 'Hernoem',
      areYouSureDeleteFolder: 'Map echt verwijderen? Alle inhoud wordt verwijderd!',
      renameFolder: 'Map hernoemen',
      createNewFolder: 'Nieuwe map maken',
      newFolderPlaceholder: 'Mapnaam invoeren',
      create: 'Maak',
      areYouSureDeleteFile: '',
      downloadFile: 'Downloaden',
      deleteFile: 'Verwijderen van apparaat',
      openInFinder: 'Openen in bestanden',
      cancel: 'Annuleren',
    },
    globalSearch: {
      postedBy: 'geplaatst door',
      found: 'Gevonden',
      loadMore: 'Meer laden',
      enterQuery: 'Voer een zoekterm in. Je kunt naar alles zoeken (naam, document, gebeurtenis, bericht, ...)',
      folder: 'Map',
      post: 'Plaats',
      error: 'Voer minstens 3 tekens in',
    },
    kreiseSettings: {
      description: 'Beschrijving',
      push: 'Pushmeldingen',
      email: 'Mail kennisgevingen',
      subscribers: 'Leden',
      active: 'Geactiveerd',
      oneYear: 'Stil voor 1 jaar',
      forever: 'Voor altijd stil',
      until: 'Tot',
      cantDeactive: 'Deze instelling is uitgeschakeld voor deze groepschat',
      SINGLE: 'Voor elke bijdrage',
      SUMMARY_ONCE: 'Samenvatting 1x per week',
      SUMMARY_TWICE: 'Samenvatting 2x per week',
      SUMMARY_DAY: 'Samenvatting 1x per dag',
      OFF: 'Uit',
      PUSH: 'Pushbericht',
      EMAIL: 'Mail notificatie',
      PUSH_AND_EMAIL: 'Push- en e-mailmeldingen',
      newUser: 'Melding voor nieuwe gebruikers',
      statistic: 'Statistieken verzenden',
      off: 'Uit',
      ownPosts: 'Stuur statistiekmail voor je bijdragen',
      deleteMemberDescr: 'erwijder?',
      deleteMemberBtn: 'Verwijder',
      retractAdminRights: 'Rechten intrekken',
      deleteAdminAndUser: 'Volledig verwijderen',
      toDeleteKreiseBtn: `Verwijder ${KreiseName('NLD')}`,
      deleteKreiseDescr: `Verwijder irrevocably ${KreiseName('NLD').single}?`,
      deleteKreiseModalTitle: 'Lid verwijderen?',
      addKreiseName: 'Naam',
      addKreiseDescription: 'Beschrijving',
      addMembers: 'Leden toevoegen',
      createKreise: `Maak ${KreiseName('NLD')}`,
      toKreiseEditMessage: 'Opmerking: Alle wijzigingen aan de leden worden onmiddellijk doorgevoerd.',
      kreiseCreator: 'Schepper:in deze groep chat',
      kreiseDeleteMembersLeft: `${KreiseName('NLD')} kan alleen worden verwijderd als er nog maar één lid over is`,
      kreiseDeleteMembersLeftTitle: 'Verwijder eerst leden',
      suggestionInNewsfeed: {
        admin: 'Admin',
        member: 'Leden',
        corp: `${CorpsName('NLD').single} Leden`,
        corpAdmin: `${CorpsName('NLD').single} admin`,
      },
    },
    surveys: {
      checkVotes: 'Resultaten bekijken',
      chooseOptions: {
        single: 'Kies een optie',
        multiple: 'Kies meerdere opties',
      },
      votesModalTitle: 'Stemmen',
      createModalTitle: 'Poll',
      noVotes: 'Er zijn geen stemmen',
      question: 'Vraag',
      askAQuestion: 'Stel een vraag',
      allowMultiple: 'Meerdere antwoorden toestaan',
      votes: {
        single: 'Stem',
        multiple: 'Stemmen',
      },
      option: 'Optie',
      optionsLabel: 'Poll opties',
      anonymus: 'Anonymus peiling',
      create: 'Maak',
    },
    subscription: {
      subscription: 'Abonnements',
      pricePerMonth: 'EUR/Monat',
      month: 'Monat',
      day: 'Tag',
      otherPlans: 'Andere Tarife',
      subscriptionActive: 'Abonnement aktiv',
      until: 'bis',
      subscribe: 'Abonnieren',
      successfulPayment: {
        title: 'Zahlung erfolgreich',
        text: 'Dein Abonnement wurde erfolgreich abgeschlossen. Du kannst ab sofort die erweiterten Funktionen nutzen.',
      },
      cancel: {
        cancel: 'Abonnement kündigen',
        title: 'Abonnement kündigen?',
        text: 'Möchtest du dein Abonnement kündigen? Nach Ablauf der bezahlten Periode werden deine Zugriffsrechte zurückgestuft.',
      },
    },
  },
];

export default translations;
