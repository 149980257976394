import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withStyles, WithStyles, createStyles, CircularProgress, Box } from '@material-ui/core';
import { animateScroll } from 'react-scroll';

import { withAuth } from '../../config/';
import { getDialogRequest, clearDialog, getChatUsersRequest, sendMessageRequest } from '../../store/actions/chat';
import Layout from '../../components/UI/Layout';
import BackButton from '../../components/Shared/BackButton';
import RequestHeader from '../../components/Shared/RequestHeader';
import ReceiveMessage from './components/ReceivedMessage';
import SentMessage from './components/SentMessage';
import AddMessage from './components/AddMessage';
import AlertModal from '../../components/Shared/AlertModal';
import Button from '../../components/UI/Button';
import { useLanguage } from 'languages/languageContext';
import { useAppDispatch, useAppSelector } from 'store';
import { getConfig } from 'config/config';
import { trimMedia } from 'store/reducers/mediaSlice';
import { RenderStateAttachments } from './components/RenderStateAttachments';
import { mediaActions, selectMediaState } from 'store/reducers/mediaSlice';
import { useLinkInfo } from './useLinkInfo';
import { extractUrls } from 'utilities/validation';
import LinkPreview from 'components/Shared/LinkPreview';

const { theme } = getConfig();
const styles = createStyles({
  container: {
    width: '100%',
    paddingBottom: 35,
    // position: 'relative',
  },
  progressWrapper: {
    width: '100%',
    padding: '50px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogWrapper: {
    // height: 'calc(100vh - 350px)',
    overflow: 'auto',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    marginBottom: '40px',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  '@media(max-width: 1024px)': {
    requestHeader: {
      right: 0,
      padding: '10px',
      left: '30.2%',
      width: '70%',
    },
  },
  '@media(max-width: 650px)': {
    dialogWrapper: {
      height: 'calc(100vh - 180px)',
      overflow: 'auto',
    },
    requestHeader: {
      width: '100%',
      left: 0,
      right: 0,
      padding: '10px',
    },
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {}

const DialogPanel: FC<Props> = (props) => {
  const { classes, history } = props;
  const dispatch = useAppDispatch();
  const { screenTitles, btn, dialogPanel } = useLanguage();
  const user = useAppSelector((state) => state.users.user);
  const { dialog, getDialogIsLoading, chatUsers } = useAppSelector((state) => state.chat);
  const [url, setUrl] = useState<string | null>(null);
  const [showLinkPreview, setShowLinkPreview] = useState(false);
  const [isDisabledLinkPreview, setIsDisabledLinkPreview] = useState(false);

  const [receiver, setReceiver] = useState(history.location?.state?.receiver || null);
  const { documents, videos, images, isLoadingAllAttachments } = useAppSelector(selectMediaState);
  const { loading } = useLinkInfo(url);

  const attachments = { documents, videos, images };

  const messages = Object.values(dialog);

  const [state, setState] = useState({
    senderUID: '',
    message: '',
    showModal: false,
  });

  const sendBtnDisabled = !!(
    state.message.length ||
    attachments.documents?.length ||
    attachments.videos?.length ||
    attachments.images?.length
  );

  useEffect(() => {
    const { history } = props;
    if (!history.location.state) {
      dispatch(getChatUsersRequest(true));
    }
    if (history.location && history.location.state && history.location.state.receiver) {
      openChannel(history.location.state.receiver.firebaseID);
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(getChatUsersRequest(true));
      if (receiver?.firebaseID) {
        dispatch(getDialogRequest(receiver.firebaseID, 'close'));
      }
      dispatch(clearDialog());
    };
  }, [receiver]);

  useEffect(() => {
    animateScroll.scrollToBottom({
      containerId: 'options-holder',
    });
  });

  useEffect(() => {
    if (!chatUsers.length) return;
    if (receiver) return;
    const userId = history.location.pathname.replace('/dialogs/', '');
    const newReceiver = chatUsers.find((user) => user._id === userId);
    setReceiver(newReceiver);
    openChannel(newReceiver.firebaseID);
  }, [chatUsers]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (state.message === '') {
        setIsDisabledLinkPreview(false);
      }

      if (isDisabledLinkPreview) return;

      try {
        const urls = await extractUrls(state.message?.trim());
        if (urls && urls.length > 0) {
          setUrl(urls[0]);
          setShowLinkPreview(true);
        } else {
          setUrl(null);
          setShowLinkPreview(false);
        }
      } catch (error) {
        console.error('Error extracting URLs:', error);
        setUrl(null);
        setShowLinkPreview(false);
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [state.message, isDisabledLinkPreview]);

  function openChannel(firebaseId: string) {
    dispatch(getChatUsersRequest(false));
    dispatch(getDialogRequest(firebaseId, 'open'));
    setState({
      ...state,
      senderUID: firebaseId,
    });
  }

  function showModalHandler() {
    setState({
      ...state,
      showModal: !state.showModal,
    });
  }

  function onChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    setState({
      ...state,
      message: value.slice(0, 20000),
    });
  }

  function sendMessageHandler() {
    const media = trimMedia(attachments);

    const notification = {
      uid: state.senderUID,
      sender_name: user.name + ' ' + user.lastname,
      sender: user._id,
      msg_content: state.message,
      attachments: [
        ...media.documents.map((file) => ({ ...file, type: 'document' })),
        ...media.videos.map((file) => ({ ...file, type: 'video' })),
        ...media.images.map((file) => ({ ...file, type: 'image' })),
      ],
    };
    dispatch(sendMessageRequest(state.senderUID, state.message, notification, media, isDisabledLinkPreview));

    setState({
      ...state,
      message: '',
      showModal: false,
    });
    dispatch(mediaActions.deleteAllAttachments());
  }

  const deleteAttachment = (type: 'documents' | 'images' | 'videos', id: number) => {
    dispatch(mediaActions.deleteAttachment({ type, id }));
  };

  const messagesList = getDialogIsLoading ? (
    <div className={classes.progressWrapper}>
      <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={35} />
    </div>
  ) : (
    messages &&
    messages.map((message: any, index: number) => {
      if (typeof message !== 'string') {
        return message.type === 'send' ? (
          <SentMessage message={message} key={`${message.time}-${index}`} />
        ) : (
          <ReceiveMessage message={message} key={`${message.time}-${index}`} sender={receiver} />
        );
      }
    })
  );

  const hasAttachments = attachments?.documents?.length || attachments?.images?.length || attachments?.videos?.length;

  return receiver ? (
    <React.Fragment>
      <Layout>
        <div className={classes.container}>
          <BackButton title={screenTitles.messagesTitle} onClick={() => history.goBack()} />
          <RequestHeader prevPath={`/dialogs/${receiver._id}`} sender={receiver} />
          <Box
            className={classes.dialogWrapper}
            height={hasAttachments ? 'calc(100vh - 350px)' : 'calc(100vh - 225px)'}
            id="options-holder"
          >
            {messagesList}
          </Box>
        </div>
        <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
          <RenderStateAttachments
            viewType="column"
            currentAttachments={attachments}
            deleteAttachment={deleteAttachment}
          />
          {showLinkPreview && !isDisabledLinkPreview && (
            <LinkPreview
              url={url}
              row
              handleOpenLink={window.open}
              handleCloseLink={() => {
                setIsDisabledLinkPreview(true);
                setShowLinkPreview(false);
                setUrl(null);
              }}
            />
          )}
          <AddMessage
            onChange={onChangeHandler}
            value={state.message}
            sendMessage={sendMessageHandler}
            disabled={isLoadingAllAttachments && sendBtnDisabled}
            receiver={receiver}
          />
        </div>
      </Layout>
      <AlertModal showModal={state.showModal} modalHandler={showModalHandler} message={dialogPanel.hangOn}>
        <Button onClick={showModalHandler} label={btn.cancelBtn} width={120} background={theme.BUTTON_SECONDARY} />
        <Button onClick={sendMessageHandler} label={dialogPanel.ok} width={120} />
      </AlertModal>
    </React.Fragment>
  ) : (
    <></>
  );
};

export default withStyles(styles)(withAuth(DialogPanel));
