import { useMemo } from "react";
import { UserType } from "store/types/usersTypes";


export const useEventPriceSummary = (event: any | null, user: UserType | null) => {
  const { joinedParts, joinedPartsGuests, totalPriceMember, totalPriceGuests, summaryPrice, showInfoSection } = useMemo(() => {
    const joinedParts = event?.parts
      ?.map((part) => {
        const foundUser = part.members?.find(
          (member) => (member.id?._id || member?._id) === user?._id
        );
        if (foundUser?._id) {
          return {
            ...part,
            priceForPart: part.priceList?.find(
              (price) => price?._id === foundUser.priceId
            ),
          };
        }
      })
      .filter((item) => item) || [];

    const joinedPartsGuests = event?.parts
  ?.reduce((acc, part) => {
    part.members?.forEach((member) => {
      const guest = member?.guest;
      const isInviter = guest?.inviter?.user === user?._id;

      if (isInviter) {
        let existingGuest = acc.find((item) => item.guestName === guest?.name);

        const priceForPart =
          part.priceList?.find((price) => price?._id === member?.priceId) || 0;
        const priceAmount = Number(priceForPart?.amount?.replace(",", ".") || 0);

        if (existingGuest) {
          existingGuest.parts.push({
            ...part,
            priceForPart,
          });
          existingGuest.priceForGuest += priceAmount;
        } else {
          acc.push({
            guestName: guest?.name,
            parts: [
              {
                ...part,
                priceForPart,
              },
            ],
            priceForGuest: priceAmount,
          });
        }
      }
    });

    return acc;
  }, [] as {
    guestName: string;
    parts: typeof event.parts;
    priceForGuest: number;
  }[]) || [];

    const totalPriceMember =
      joinedParts.reduce(
        (acc, part) =>
          acc + Number(part.priceForPart?.amount?.replace(",", ".") || 0),
        0
      ) || 0;

    const totalPriceGuests =
      joinedPartsGuests.reduce((total, guest) => {
        const partsPrice = guest.parts.reduce(
          (acc, part) =>
            acc + Number(part.priceForPart?.amount?.replace(",", ".") || 0),
          0
        );
        return total + partsPrice;
      }, 0) || 0;

    const summaryPrice = (totalPriceMember + totalPriceGuests)?.toFixed(2);

    const showInfoSection = !!joinedParts.length || !!joinedPartsGuests.length;

    return {
      joinedParts,
      joinedPartsGuests,
      totalPriceMember,
      totalPriceGuests,
      summaryPrice,
      showInfoSection,
    };
  }, [event, user]);

  return {
    joinedParts,
    joinedPartsGuests,
    totalPriceMember,
    totalPriceGuests,
    summaryPrice,
    showInfoSection,
  };
};
