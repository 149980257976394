import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import * as api from './../../api/kreise';
import { KreiseCategory } from 'store/types/kreiseTypes';
import { getNestedValue, setNestedValue } from 'utilities/nestedValue';

export const getUnsubscribedKreiseByCategories = createAsyncThunk(
  'categoriesKreise/getUnsubscribedKreiseByCategories',
  async () => {
    return await api.getUnsubscribedKreiseByCategories();
  },
);

const getUnsubscribedKreiseById = createAsyncThunk('categoriesKreise/getUnsubscribedKreiseById', async (id: string) => {
  return await api.getUnsubscribedKreiseById(id);
});

const initialCategoriesState = {
  unsubscribedKreiseCategoriesIsLoading: false,
  unsubscribedKreiseCategories: [] as KreiseCategory[],
  categoriesPaths: {} as Record<string, string>,
  loadingCategories: {} as Record<string, boolean>,
  error: null,
};

const categoriesSlice = createSlice({
  name: 'categoriesKreise',
  initialState: initialCategoriesState,
  reducers: {
    setValueToNestedCategory: (state, action) => {
      state.unsubscribedKreiseCategories = setNestedValue(
        state.unsubscribedKreiseCategories,
        action.payload.path,
        action.payload.value,
      );
    },
    saveCategoryPath: (state, action: PayloadAction<{ id: string; path: string }>) => {
      state.categoriesPaths[action.payload.id] = action.payload.path;
      state.loadingCategories[action.payload.id] = true;
    },
  },
  extraReducers: (builder) => {
    // --> getUnsubscribedKreiseByCategories
    builder.addCase(getUnsubscribedKreiseByCategories.pending, (state) => {
      state.unsubscribedKreiseCategoriesIsLoading = true;
    });
    builder.addCase(getUnsubscribedKreiseByCategories.fulfilled, (state, action: any) => {
      state.unsubscribedKreiseCategoriesIsLoading = false;
      state.unsubscribedKreiseCategories = action.payload.categories;
    });
    builder.addCase(getUnsubscribedKreiseByCategories.rejected, (state, action: any) => {
      state.error = action.payload.error;
      state.unsubscribedKreiseCategoriesIsLoading = false;
    });
    // getUnsubscribedKreiseByCategories <--

    builder.addCase(getUnsubscribedKreiseById.fulfilled, (state, action: any) => {
      const category: KreiseCategory = action.payload.categories[0];
      const path = state.categoriesPaths[category._id];
      const prevCategory: KreiseCategory = getNestedValue(state.unsubscribedKreiseCategories, path);
      category.isOpened = prevCategory.isOpened;

      state.loadingCategories[category._id] = false;
      state.unsubscribedKreiseCategories = setNestedValue(state.unsubscribedKreiseCategories, path, category);
    });
  },
});

export const fetchKreiseById =
  ({ id, path }: { id: string; path: string }) =>
  async (dispatch) => {
    dispatch(categoriesSlice.actions.saveCategoryPath({ id, path }));

    await dispatch(getUnsubscribedKreiseById(id));
  };

export const {
  actions: { setValueToNestedCategory },
  reducer: categoriesKreise,
} = categoriesSlice;
