import { FC, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { useFormik } from 'formik';

import { Button } from 'components/shadcn/button';
import { NewDatePicker } from 'components/shadcn/datepicker';
import { Label } from 'components/shadcn/label';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from 'components/shadcn/select';
import BackButton from 'components/Shared/BackButton';
import { MultipleSearchableInput } from 'components/Shared/MultipleSearchableInput';
import SearchableModal from 'components/Shared/SearchableModal';
import { FormInput } from 'components/UI/FormInput';
import { InputButton } from 'components/UI/InputButton';
import { IonIcon } from 'components/UI/IonIcon';
import Layout from 'components/UI/Layout';
import { MultipleModalItem } from 'components/UI/MultipleModalItem';
import { useCustomHistory } from 'hooks/useCustomHistory';
import { useLanguage } from 'languages/languageContext';
import { useAppDispatch, useAppSelector } from 'store';
import { fileType } from 'store/reducers/attachments';
import { getKreiseForEvents } from 'store/reducers/kreise';
import { mediaActions } from 'store/reducers/mediaSlice';
import { eventValidation, validateEmail } from 'utilities/schema';
import { cn } from 'utilities/utils';
import { RecurringModal } from './components/RecurringModal';
import { configRRules, eFrequency, TRRule, weekdayArray } from 'utilities/configRRules';
import { AddOrganizersModal } from './components/AddOrganizersModal';
import MemberItem from './components/MemberItem';
import SubmitEvent from './components/SubmitEvent';
import {
  addEventRequest,
  getAutocompleteEventsRequest,
  removeEventRequest,
  updateEventRequest,
} from 'store/actions/events';
import { showResponse } from 'store/actions/response';
import { ErrorLabel } from 'components/UI/ErrorLabel';
import { UpdateRRModal } from './components/UpdateRRModal';
import { RemoveEventModal } from './components/RemoveEventModal';
import { Checkbox } from 'components/shadcn/checkbox';
import AutocompleteInput from './components/AutocompleteInput';
import Loader from 'components/UI/Loader';
import languages from 'languages/languages';
import { RenderStateAttachments } from 'scenes/DialogPanel/components/RenderStateAttachments';
import EventPart from './components/EventParts/EventPart';
import { ImageInput } from 'components/Shared/ImageInput';
import { useLocation, useParams } from 'react-router';
import { useQuery as useQueryParams } from 'hooks/useQuery';
import { TEventPart } from './components/EventParts/types';

export enum RECURRING_MODIFY_TYPE {
  SINGLE = 'single',
  FOLLOWING = 'following',
  ALL = 'all',
}

const initialRRules = {
  freq: 8,
  interval: 1,
  bysetpos: 0,
  count: 1,
  endType: 'none',
  until: undefined,
} as TRRule;

export const CreateEvent: FC = () => {
  const { screenTitles, eventLabels, invalidInputs, rrEventLabels, btn, key2: locale } = useLanguage();
  const history = useCustomHistory();
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const { kreiseForEvents } = useAppSelector((state) => state.kreise);
  const {
    disable_guest_function,
    event_show_website_option,
    event_creation_ask_registration_type,
    event_standard_registration_option_link,
    event_participants_limitation,
    event_default_visibility_option,
    events_parts_possible,
  } = useAppSelector((state) => state.common);
  const { images, videos, documents, previewImage } = useAppSelector((state) => state.media);
  const { user } = useAppSelector((state) => state.users);
  const eventSubs = useAppSelector((state) => state.events.eventSubs);

  const [withEndDate, setWithEndDate] = useState(false);
  const [kreiseModal, setKreiseModal] = useState({ open: false, query: '' });
  const [organizersModal, setOrganizersModal] = useState({ open: false });
  const [emailToInvite, setEmailToInvite] = useState({ value: '', error: '' });
  const [recModal, setRecModal] = useState({ open: false, custom: false });
  const [enableParticipantsLimit, setEnableParticipantsLimit] = useState(false);

  const removeEventModalInitState = {
    show: false,
    cancelType: '',
    modifyType: RECURRING_MODIFY_TYPE.SINGLE as string,
  };
  const [isEventLoading, setIsEventLoading] = useState(false);
  const [removeEventModal, setRemoveEventModal] = useState(removeEventModalInitState);
  const [updateRRModal, setUpdateRRModal] = useState({
    show: false,
    modifyType: RECURRING_MODIFY_TYPE.SINGLE as string,
  });

  const event = history.location?.state?.event;

  const isEventHaveMembers = event?.acceptedGuests?.length + event?.members?.length;

  const editMode = history.location.pathname.includes('edit-event');

  const showVisibilitySelector = !disable_guest_function || event_show_website_option;

  const visibilityOptions: (TVisibilityOption | '')[] = [
    'invited_member',
    !disable_guest_function && 'authorized_member',
    event_show_website_option && 'all',
  ];

  const eventRegTypes = ['app', 'event'];

  useEffect(() => {
    dispatch(getKreiseForEvents());
    dispatch(getAutocompleteEventsRequest());

    return () => {
      dispatch(mediaActions.deleteAllAttachments());
    };
  }, []);

  useEffect(() => {
    if (history.location?.state?.event) initState(history.location?.state?.event);
    else initState();
  }, [user]);

  const initState = (event?: any) => {
    setIsEventLoading(true);
    if (event) {
      const parts = [
        ...event.parts.map((part) => ({
          ...part,
          start: moment(part.start),
          end: part.end ? moment(part.end) : undefined,
        })),
      ];

      formik.setValues({
        ...event,
        organizers: !event?.organizers?.length ? [user] : event.organizers,
        registrationLink: event.registration?.value,
        registrationType: event.registration?.type,
        recurringRules: event.recurringConfig,
        startDate: moment(event.startDate),
        endDate: event.hideEndDate ? moment(event.endDate) : undefined,
        parts,
      });
      setEnableParticipantsLimit(!!event.participantsLimit);
      setWithEndDate(event.hideEndDate);
    } else
      formik.setValues({
        ...values,
        organizers: [user],
        visibility: visibilityOptions.includes(event_default_visibility_option)
          ? event_default_visibility_option
          : !disable_guest_function
          ? 'authorized_member'
          : 'invited_member',
        participantsLimit: event_participants_limitation,
      });
    setIsEventLoading(false);
  };

  useEffect(() => {
    if (!!event?.images?.length) {
      dispatch(
        mediaActions.setEditingAttachments({
          images: event.images || [],
          documents: [],
          videos: [],
        }),
      );
    }
  }, []);

  const initialValues = {
    title: '',
    description: '',
    location: '',
    organizerMsg: '',
    visibility: 'authorized_member' as TVisibilityOption,
    startDate: moment(moment().isSameOrBefore(state?.currentDate) ? state?.currentDate : undefined).add(5, 'minutes'),
    endDate: undefined as Moment,
    documents: [] as fileType[],
    images: [] as fileType[],
    videos: [] as fileType[],
    invitedKreises: [] as { _id: string; name: string }[],
    organizers: [] as any[],
    invitedMails: [] as string[],
    registrationType: 'app' as TEventRegistration,
    registrationLink: '',
    recurringRules: initialRRules,
    participantsLimit: event_participants_limitation, // if zero, consider as infinity
    parts: [] as TEventPart[],
  };

  const { values, errors, touched, handleBlur, handleChange, ...formik } = useFormik({
    initialValues,
    validationSchema: eventValidation({ invalidInputs, eventLabels }),
    validateOnBlur: true,
    validateOnMount: false,
    isInitialValid: false,
    onSubmit: (values) => {
      let { recurringConfig } = configRRules({
        rules: values.recurringRules,
        startDate: values.startDate,
        locale,
      });
      recurringConfig = recurringConfig.freq !== undefined && recurringConfig.freq !== 8 ? recurringConfig : undefined;

      let recurringText = {};

      if (recurringConfig) {
        const langs = languages();
        langs.forEach((lang) => {
          const { recurringLabel } = configRRules({
            rules: values.recurringRules,
            startDate: values.startDate,
            labels: lang.rrEventLabels,
            locale,
          });
          recurringText[lang.key2] = recurringLabel;
        });
      } else recurringText = undefined;
      const newParts = values.parts.map((part) => {
        const newQuestions = part.questions?.map((question) => {
          if (question.type === 'freeText') {
            delete question.options;
            return question;
          }
          const newOptions = question.options.filter((option) => !!option.title.length);
          return { ...question, options: newOptions };
        });
        return { ...part, questions: newQuestions };
      });
      const payload: any = {
        ...values,
        registration: {
          type: values.registrationType,
          value: values.registrationLink,
        },
        previewImages: previewImage,
        images: images,
        documents,
        videos,
        endDate: values.endDate,
        hideEndDate: withEndDate,
        recurringConfig,
        invitedKreises: values.invitedKreises.map(({ _id }) => _id),
        sender: localStorage.getItem('userId'),
        recurringText,
        participantsLimit: enableParticipantsLimit ? values.participantsLimit : 0,
        parts: newParts,
      };

      if (
        editMode &&
        (recurringConfig?.interval !== event?.recurringConfig?.interval ||
          recurringConfig?.freq !== event?.recurringConfig?.freq)
      ) {
        payload.recurringModifyType = updateRRModal.modifyType;
      }

      delete payload.recurringRules;
      delete payload.registrationType;
      delete payload.registrationLink;

      const createEventMessage = (
        <span>
          <span style={{ fontWeight: 'bold' }}>{eventLabels.modalEvent.subTitle}</span>
          <br />
          <br />
          {eventLabels.modalEvent.content}
        </span>
      );

      if (payload._id) dispatch(updateEventRequest({ eventObj: payload }, history.goBack));
      else
        dispatch(
          addEventRequest({ eventObj: payload }, () =>
            dispatch(
              showResponse({
                message: createEventMessage,
                title: eventLabels.modalEvent.title,
                route: history.push('/calendar'),
              }),
            ),
          ),
        );

      if (editMode && values.invitedKreises?.length > event.invitedKreises.length)
        dispatch(
          showResponse({
            title: eventLabels.updateEventModal.title,
            message: (
              <>
                <strong>{eventLabels.updateEventModal.label}</strong>
                <br />
                <br />
                {eventLabels.updateEventModal.message}
              </>
            ),
          }),
        );
    },
  });

  const addEndDateHandler = () => {
    const { startDate } = values;
    const start = moment(startDate);
    const after = moment(startDate).hours(23).minutes(0).seconds(0);
    const before = moment(startDate).hours(23).minutes(59).seconds(59);

    const isStartAtTheEnd = start.isBetween(after, before);

    let endDate: Moment;

    if (isStartAtTheEnd) endDate = moment(startDate).hours(23).minutes(59).seconds(59);
    else endDate = moment(startDate).add(1, 'hour');

    formik.setFieldValue('endDate', endDate);
    setWithEndDate(true);
  };

  const addGuestHandler = async () => {
    try {
      const email = await validateEmail({ invalidInputs }).validate(emailToInvite.value);
      if (!values.invitedMails) {
        formik.setFieldValue('invitedMails', [email]);
      } else {
        formik.setFieldValue('invitedMails', [...values.invitedMails, email]);
      }
      setEmailToInvite({ value: '', error: '' });
    } catch (error) {
      setEmailToInvite((c) => ({ ...c, error: error.toString() }));
      console.error('validation error', error);
    }
  };

  const { recurringLabel, recurringConfig } = configRRules({
    rules: values.recurringRules,
    labels: rrEventLabels,
    locale,
  });
  // console.log('🚀 ~ errors:', errors);

  const handleChangeCancelEvent = (value: string) => {
    setRemoveEventModal((c) => ({
      ...c,
      cancelType: value,
    }));
  };

  const removeEventHandler = () => {
    let payload = undefined;

    if (event.recurringConfig) payload = { recurringModifyType: removeEventModal.modifyType, date: values.startDate };

    dispatch(removeEventRequest(event._id, !!removeEventModal.cancelType, payload, history.goBack));

    setRemoveEventModal({ show: false, cancelType: '', modifyType: '' });
  };

  const handleSubmitEvent = () => {
    const { event } = history.location.state || {};
    const existingConfig = { ...event?.recurringConfig };

    if (formik.isSubmitting) return;
    if (isEventLoading) return;

    if (!editMode) {
      return formik.submitForm();
    }
    if (existingConfig?.freq === eFrequency.SINGLE || existingConfig?.freq === undefined) {
      return formik.submitForm();
    }

    setUpdateRRModal((c) => ({ ...c, show: true }));
  };

  const rRulesChanged =
    recurringConfig?.interval !== event?.recurringConfig?.interval ||
    recurringConfig?.freq !== event?.recurringConfig?.freq;

  const changeDate = ({
    e,
    field,
    type,
    value,
  }: ({ e: string; type: 'time' } | { e: Date; type: 'date' }) & {
    field: 'startDate' | 'endDate' | string;
    value?: any;
  }): void => {
    const date = values[field] || value;
    if (type === 'date') {
      const [year, month, day] = moment(e).format('YYYY:MM:DD').split(':');
      date
        .year(+year)
        .month(+month - 1)
        .date(+day);
    }

    if (type === 'time') {
      const [hours, minutes] = e.split(':');
      date.hours(+hours).minutes(+minutes).seconds(0);
    }
    formik.setFieldValue(field, date);
    formik.setFieldTouched(field, true);
    const day = [weekdayArray[date.weekday()]];

    formik.setFieldValue('recurringRules.byweekday', day);
  };

  return (
    <>
      <Layout>
        <BackButton
          title={screenTitles.newEventTitle}
          onClick={() => {
            dispatch(mediaActions.deleteAllAttachments());
            history.goBack();
          }}
        />
        <Loader showLoader={isEventLoading} />
        <div className="flex flex-col self-center p-8">
          <FormInput
            name="title"
            placeholder={eventLabels.titleLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.title}
            error={errors.title}
            touched={touched.title}
          />
          <ImageInput
            addLabel={eventLabels.addEventTitleImage}
            editLabel={eventLabels.changeEventTitleImage}
            editImage={event?.previewImages?.[0]?.thumb}
          />

          <AutocompleteInput
            data={eventSubs?.map((item: { text: string; _id: string }) => ({
              value: item.text,
              label: item.text,
            }))}
            label={eventLabels.organizer}
            placeholder={eventLabels.organizer}
            name="organizerMsg"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.organizerMsg}
            error={errors.organizerMsg}
            touched={touched.organizerMsg}
            onSelect={(item) => formik.setFieldValue('organizerMsg', item)}
          />

          <div>
            <div className="flex flex-row gap-4">
              <div className="flex-1">
                <NewDatePicker
                  name={'startDate'}
                  placeholder={eventLabels.startLabel}
                  date={values.startDate}
                  setDate={(e) => changeDate({ e, field: 'startDate', type: 'date' })}
                  fromYear={+moment().format('YYYY')}
                  toYear={2099}
                />
              </div>
              <div>
                <FormInput
                  aria-label="Time"
                  type="time"
                  value={values.startDate.format('HH:mm')}
                  name="startDate"
                  onChange={(e) => changeDate({ e: e.target.value, field: 'startDate', type: 'time' })}
                />
              </div>
            </div>
            <ErrorLabel>{touched.startDate && errors.startDate}</ErrorLabel>
          </div>

          {!withEndDate && (
            <Button variant="link" className="text-sm -mt-5 w-fit self-center" onClick={addEndDateHandler}>
              {eventLabels.addEndDate}
            </Button>
          )}

          {withEndDate && (
            <div>
              <div className="flex flex-row gap-4">
                <div className="flex-1">
                  <NewDatePicker
                    name={'endDate'}
                    placeholder={eventLabels.eventEndLabel}
                    date={values.endDate}
                    setDate={(e) => changeDate({ e, field: 'endDate', type: 'date' })}
                    fromYear={+moment().format('YYYY')}
                    toYear={2099}
                  />
                </div>
                <div>
                  <FormInput
                    aria-label="Time"
                    type="time"
                    value={values.endDate?.format('HH:mm')}
                    name="endDate"
                    onChange={(e) => changeDate({ e: e.target.value, field: 'endDate', type: 'time' })}
                  />
                </div>
              </div>
              <ErrorLabel>{touched.endDate && errors.endDate}</ErrorLabel>
            </div>
          )}

          <button
            onClick={() => setRecModal((c) => ({ ...c, open: true }))}
            className="my-1 cursor-pointer ml-2 self-start"
          >
            <IonIcon name="refresh-outline" size={18} />
            <span className="ml-1 bottom-[4px] relative text-sm text-secondary-txt">{recurringLabel}</span>
          </button>

          <FormInput
            name="location"
            placeholder={eventLabels.locationLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.location}
            error={errors.location}
            touched={touched.location}
          />

          <FormInput
            name="description"
            placeholder={eventLabels.descLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.description}
            error={errors.description}
            touched={touched.description}
            inputType="textarea"
          />

          {values?.parts.map((part, idx) => (
            <EventPart
              key={idx}
              idx={idx}
              values={part}
              //@ts-ignore
              errors={errors.parts?.[idx]}
              //@ts-ignore
              touched={touched.parts?.[idx]}
              handleBlur={handleBlur}
              handleChange={handleChange}
              setValues={(v: TEventPart) => formik.setFieldValue(`parts[${idx}]`, v)}
              onChangeDate={changeDate}
              deletePart={() => {
                values.parts.splice(idx, 1);
                formik.setValues({ ...values });
              }}
            />
          ))}

          {events_parts_possible && (
            <Button
              variant="text"
              size="text"
              className="self-center mt-2 mb-4"
              onClick={() => {
                const emptyEventPart: TEventPart = {
                  title: '',
                  start: moment().add(5, 'minutes'),
                  end: undefined,
                  location: '',
                  description: '',
                  // price: '0,00',
                  priceList: [{ title: 'Standard-Preis', amount: '0,00', isDefault: true }],
                  maxMembers: 0,
                  questions: [],
                };
                formik.setFieldValue(`parts[${values.parts.length}]`, emptyEventPart);
              }}
            >
              {eventLabels.parts.addPart}
            </Button>
          )}
          <InputButton
            onClick={() => {
              //@ts-ignore
              formik.setTouched({ invitedKreises: true });
              setKreiseModal((c) => ({ ...c, open: true }));
            }}
            label={eventLabels.sendInvite}
            placeholder={eventLabels.gruppenchat}
            //@ts-ignore
            touched={touched.invitedKreises}
            error={errors.invitedKreises as string}
          />

          <div className={cn(values.invitedKreises.length ? 'mb-5' : '')}>
            {values.invitedKreises?.map((item) => (
              <MultipleModalItem
                key={item._id}
                item={item}
                deleteItemHandler={(value) =>
                  formik.setFieldValue(
                    'invitedKreises',
                    values.invitedKreises.filter((el) => el._id !== value._id),
                  )
                }
              />
            ))}
          </div>

          <div className="flex flex-row gap-2">
            <div className="flex-1">
              <FormInput
                name="emailToInvite"
                placeholder={eventLabels.externalGuest}
                onBlur={handleBlur}
                onChange={(e) => setEmailToInvite((c) => ({ error: '', value: e.target.value }))}
                value={emailToInvite.value}
                error={emailToInvite.error}
                touched={true}
              />
            </div>
            <button
              onClick={addGuestHandler}
              className="relative top-7 cursor-pointer rounded-full h-8 w-8 duration-150 hover:scale-105"
            >
              <IonIcon name="add-circle-outline" style={{ width: '100%', height: '100%' }} />
            </button>
          </div>

          <div className={cn(values.invitedMails?.length ? 'mb-5' : '')}>
            {values.invitedMails?.map((item) => (
              <MultipleModalItem
                key={item}
                item={item}
                deleteItemHandler={(value) =>
                  formik.setFieldValue(
                    'invitedMails',
                    values.invitedMails.filter((el) => el !== value),
                  )
                }
              />
            ))}
          </div>

          {showVisibilitySelector && (
            <Select
              onValueChange={(e) => {
                formik.setFieldValue('visibility', e);
              }}
              value={values.visibility}
            >
              <Label className="ml-3 mb-1" htmlFor={'visibility'}>
                {eventLabels.visibility}
              </Label>
              <SelectTrigger className="rounded-full h-10 mb-3">
                <SelectValue placeholder={eventLabels.visibility} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {visibilityOptions.map(
                    (el) =>
                      el.length && (
                        <SelectItem key={el} value={el}>
                          {
                            eventLabels.visibilityOptions[
                              disable_guest_function && event_show_website_option && el === 'all'
                                ? 'allWithoutGuests'
                                : el
                            ]
                          }
                        </SelectItem>
                      ),
                  )}
                </SelectGroup>
              </SelectContent>
            </Select>
          )}

          {event_creation_ask_registration_type && (
            <>
              <Select
                onValueChange={(e) => {
                  formik.setFieldValue('registrationType', e);
                }}
                value={values.registrationType}
              >
                <Label className="ml-3 mb-1" htmlFor={'registrationType'}>
                  {eventLabels.registrationVia}
                </Label>
                <SelectTrigger className="rounded-full h-10 mb-3">
                  <SelectValue placeholder={eventLabels.registrationVia} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {eventRegTypes.map(
                      (el) =>
                        el.length && (
                          <SelectItem key={el} value={el}>
                            {eventLabels.eventRegistrationType[el]}
                          </SelectItem>
                        ),
                    )}
                  </SelectGroup>
                </SelectContent>
              </Select>
              {values.registrationType !== event_standard_registration_option_link && (
                <FormInput
                  placeholder={eventLabels.eventRegistrationType.enterLink}
                  name="registrationLink"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.registrationLink}
                  error={errors.registrationLink}
                  touched={touched.registrationLink}
                />
              )}
            </>
          )}

          {event_participants_limitation !== 0 && (
            <div className="flex flex-col mt-2">
              <div className="flex gap-x-2 mb-4 ml-3">
                <Checkbox checked={enableParticipantsLimit} onClick={() => setEnableParticipantsLimit((c) => !c)} />
                <Label>{eventLabels.participantsWarning}</Label>
              </div>
              {enableParticipantsLimit && (
                <div>
                  <FormInput
                    placeholder={eventLabels.maximalParticipants}
                    name="participantsLimit"
                    type="number"
                    onChange={handleChange}
                    value={values.participantsLimit}
                    error={errors.participantsLimit}
                    touched={touched.participantsLimit}
                  />
                </div>
              )}
            </div>
          )}

          <Label className="ml-3 mt-1">{eventLabels.creatorLabel}:</Label>
          {values.organizers.map((member, i) => (
            <MemberItem
              key={member._id}
              member={member}
              onRemoveMember={(value: any) =>
                formik.setFieldValue(
                  'organizers',
                  values.organizers.filter((el) => el._id !== value._id),
                )
              }
            />
          ))}
          <button
            className="self-center cursor-pointer mt-2 hover:scale-105 duration-150"
            onClick={() => setOrganizersModal({ open: true })}
          >
            <IonIcon name="add-circle" size={36} />
          </button>
          <div style={{ height: 20 }} />
        </div>

        <SubmitEvent
          updateMode={editMode}
          showRemoveEvent={() => setRemoveEventModal((c) => ({ ...c, show: true }))}
          label={btn.clearBtn}
          submitHandler={handleSubmitEvent}
          disabled={!formik.isValid || formik.isSubmitting || isEventLoading}
          loading={isEventLoading}
        />
      </Layout>

      <SearchableModal
        modalOpen={kreiseModal.open}
        modalTitle={eventLabels.selectCorps}
        onClick={() => setKreiseModal((c) => ({ ...c, open: false }))}
        setValueHandler={() => setKreiseModal((c) => ({ ...c, open: false }))}
        value={undefined}
      >
        <MultipleSearchableInput
          data={kreiseForEvents.filter((el) => el.isPostAllowed)}
          onSelect={(value) => formik.setFieldValue('invitedKreises', [...values.invitedKreises, value])}
          placeholder={eventLabels.selectCorps}
          onChange={(e) => setKreiseModal((c) => ({ ...c, query: e.target.value }))}
          deleteItemHandler={(value) =>
            formik.setFieldValue(
              'invitedKreises',
              values.invitedKreises.filter((el) => el._id !== value._id),
            )
          }
          values={values.invitedKreises}
          value={kreiseModal.query}
          alleIsValue={true}
        />
      </SearchableModal>

      <AddOrganizersModal
        open={organizersModal.open}
        onClose={() => setOrganizersModal((c) => ({ ...c, open: false }))}
        values={values.organizers}
        onSelect={(value) => formik.setFieldValue('organizers', [...values.organizers, value])}
        onDelete={(value) =>
          formik.setFieldValue(
            'organizers',
            values.organizers.filter((el) => el._id !== value._id),
          )
        }
      />

      {!!values.startDate.toString().length && (
        <RecurringModal
          setConfig={(state) => formik.setFieldValue('recurringRules', state)}
          startDate={values.startDate}
          onClose={() => setRecModal((c) => ({ ...c, open: false }))}
          isOpen={recModal.open}
        />
      )}

      <RemoveEventModal
        show={removeEventModal.show}
        onClose={() => setRemoveEventModal(removeEventModalInitState)}
        isEventHaveMembers={isEventHaveMembers}
        recurringRules={values.recurringRules}
        removeEventHandler={removeEventHandler}
        handleChangeCancelEvent={handleChangeCancelEvent}
        cancelType={removeEventModal.cancelType}
        rRulesChanged={rRulesChanged}
        onValueChange={(v) => setRemoveEventModal((c) => ({ ...c, modifyType: v }))}
      />

      <UpdateRRModal
        show={updateRRModal.show}
        onClose={() => setUpdateRRModal((c) => ({ ...c, show: false }))}
        onValueChange={(v) => setUpdateRRModal((c) => ({ ...c, modifyType: v }))}
        rRulesChanged={rRulesChanged}
        submitForm={formik.submitForm}
      />
    </>
  );
};

type TEventRegistration = 'app' | 'event';

export type TVisibilityOption =
  | 'invited_member' //kreise || invitedUser(email) | by Jh version 'invited_members'
  | 'authorized_member' //any app member | by Jh version 'members_and_guests'
  | 'all'; //all | by Jh version 'all'
