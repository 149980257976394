import React from 'react';
import { NavLink } from 'react-router-dom';
import { withConfig } from '../../../config/';
import { useDispatch, useSelector } from 'react-redux';
import { showResponse } from '../../../store/actions/response';
import { closeMenu } from '../../../store/actions/layout';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { WithStyles, withStyles, createStyles } from '@material-ui/styles';
import { DrawerLinkProps } from '../model';
import { ReactComponent as KreiseIcon } from '../assets/kreise.svg';
import { ReactComponent as SucheIcon } from '../assets/suche.svg';
import { ReactComponent as VeranstaltungenIcon } from '../assets/veranstaltungen.svg';
import { ReactComponent as DokumenteIcon } from '../assets/dokumente.svg';
import { ReactComponent as KontakteIcon } from '../assets/kontakte.svg';
import { ReactComponent as KontaktanfragenIcon } from '../assets/kontaktanfragen.svg';
import { ReactComponent as NachrichtenIcon } from '../assets/nachrichten.svg';
import { ReactComponent as ProfilIcon } from '../assets/profil.svg';
import MentoringIcon from '../assets/mentoringIcon';
import MapIcon from '../assets/MapIcon';
import { IonIcon } from 'components/UI/IonIcon';
import { getConfig } from 'config/config';
import { useLanguage } from 'languages/languageContext';
import { useAppSelector } from 'store';

const { theme, config } = getConfig();
const styles = createStyles({
  container: {
    width: '100%',
    position: 'relative',
    boxSizing: 'border-box',
  },
  link: {
    display: 'flex!important',
    alignItems: 'center',
    width: '100%',
    padding: '10px 20px!important',
    fontSize: '14px!important',
    fontFamily: 'Poppins!important',
    textDecoration: 'none!important',
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
  label: {
    marginLeft: '10px!important',
    lineHeight: '14px!important',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  counter: {
    marginLeft: 5,
    fontSize: 14,
    lineHeight: '14px',
    fontFamily: 'Poppins',
  },
  backdrop: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    zIndex: 10,
    cursor: 'pointer',
  },
  notificationWrapper: {
    marginLeft: 40,
    width: 20,
    height: 20,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  notification: {
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any>, DrawerLinkProps {
  badgeCount?: number;
  disabled?: boolean;
}

const DrawerLink: React.FC<Props> = ({
  classes,
  route,
  label,
  iconName,
  location,
  user,
  count,
  badgeCount,
  disabled,
}) => {
  const dispatch = useDispatch();
  const active = location.pathname.split('/')[1] === route.replace('/', '');
  const unreadMessages = useSelector((state: any) => state.chat.unreadMessages);
  const { restrictions: commonRestrictions } = useAppSelector((state) => state.common);
  const {
    drawerScreen,
    privacy: { restrictions: lRestrictions },
  } = useLanguage();

  const restrictions = commonRestrictions.overall ? commonRestrictions : lRestrictions;
  const iconColor = disabled ? theme.ICON_BORDER : 'rgb(112, 126, 135';

  const iconset = {
    neuigkeiten: <IonIcon name="megaphone-outline" color={iconColor} size={16} />,
    kreise: <KreiseIcon color={iconColor} />,
    suche: <SucheIcon color={iconColor} />,
    veranstaltulgen: <VeranstaltungenIcon color={iconColor} />,
    dokumente: <DokumenteIcon color={iconColor} />,
    kontakte: <KontakteIcon color={iconColor} />,
    kontaktanfragen: <KontaktanfragenIcon color={iconColor} />,
    nachrichten: <NachrichtenIcon color={iconColor} />,
    profile: <ProfilIcon color={iconColor} />,
    mentoring: <MentoringIcon fill={iconColor} />,
    map: <MapIcon fill={iconColor} />,
    subscription: <IonIcon name="star-outline" color={iconColor} size={18} />,
  };

  return (
    <div
      className={classes.container}
      onClick={() => {
        if (disabled) {
          // TODO: if add for admin new value for wilkar pls uncommented that
          // let message = config.APP_NAME === 'wilkar' ? restrictions.overallWilkar : restrictions.overall;
          let message = restrictions.overall;
          // if (iconName === 'map') message = 'Die Karte ist nur für Mitglieder einsehbar.';
          dispatch(showResponse({ message }));
          return;
        }
        setTimeout(() => {
          dispatch(closeMenu());
        }, 100);
      }}
      // style={{ pointerEvents: disabled ? 'none' : 'initial' }}
    >
      <NavLink
        onClick={(e) => {
          if (disabled) e.preventDefault();
        }}
        to={route}
        className={classes.link}
        style={{
          background: active ? theme.BACKGROUND_PRIMARY : theme.BACKGROUND,
          color: disabled ? theme.ICON_BORDER : active ? theme.BUTTON_PRIMARY : theme.TEXT_PRIMARY,
        }}
      >
        <div className={classes.iconWrapper} style={{ color: active ? theme.BUTTON_PRIMARY : theme.TEXT_SECONDARY }}>
          {iconset[iconName]}
        </div>
        <span className={classes.label}>{label}</span>
        {count != undefined && <span className={classes.counter}>({count})</span>}
        {!!badgeCount && (
          <div className={classes.notificationWrapper} style={{ background: theme.ERROR_PRIMARY }}>
            <span className={classes.notification} style={{ color: theme.BACKGROUND }}>
              {badgeCount}
            </span>
          </div>
        )}
      </NavLink>
      {user && !user.name && (
        <div
          onClick={() =>
            dispatch(
              showResponse({
                message: drawerScreen.backdrop,
              }),
            )
          }
          className={classes.backdrop}
        ></div>
      )}
    </div>
  );
};

export default withStyles(styles)(withRouter(withConfig(DrawerLink)));
