export const formatString = (stringLength: number, value: string) => {
  let newString = '';

  if (value && value.length > stringLength) {
    const str = value.slice(0, stringLength);
    newString = `${str}...`;
    return newString;
  }

  return value;
};

export const makeDateBold = (str: string) => {
  // Define a regular expression pattern to match the date in the format "dd.mm.yyyy hh:mm"
  const dateRgx = /\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}/g;

  if (typeof str !== 'string' || !str?.match(dateRgx)) return str;

  const formattedString = str?.replace(dateRgx, (match: string) => `<strong>${match}</strong>`);

  return <span dangerouslySetInnerHTML={{ __html: formattedString }} />;
};

// decode html string
export const decodeHTMLEntities = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
};

export const formatNumber = (n: string) => {
  return n.replace(/\D/g, '');
};

export const formatCurrency = (inputValue: string, blur?: boolean) => {
  if (inputValue === '') return '';

  let input_val = inputValue;

  // Check for decimal (in this case, a comma)
  if (input_val.indexOf(',') >= 0) {
    const decimal_pos = input_val.indexOf(',');
    let left_side = input_val.substring(0, decimal_pos);
    let right_side = input_val.substring(decimal_pos + 1);
    // Only keep numbers in the left and right sides
    left_side = formatNumber(left_side);
    right_side = formatNumber(right_side);
    // If blurring, ensure there are two digits after the comma
    if (blur) {
      right_side = right_side.padEnd(2, '0');
    }
    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, 2);
    // Join the number with a comma
    if (!left_side && blur) left_side = '0';
    input_val = left_side + ',' + right_side;
  } else {
    // No comma entered, just format the whole number
    input_val = formatNumber(input_val);
    // Add comma and 00 if blurring
    if (blur) {
      input_val += ',00';
    }
  }

  return input_val;
};
