export const setNestedValue = (obj: any, path: string, value: any): any => {
  const keys = getKeys(path);
  const lastKey = keys.pop(); // Extract the last key
  let result = obj;

  keys.forEach((key) => {
    if (result) result = result[key];
  });

  if (result) result[lastKey] = value; // Set the value at the last key
  return obj;
};

export const getNestedValue = (obj: any, path: string) => {
  const keys = getKeys(path);
  const lastKey = keys.pop(); // Extract the last key
  let result = obj;

  keys.forEach((key) => {
    if (result) result = result[key];
  });

  return result[lastKey];
};

const getKeys = (path: string) => {
  return path.split(/[\.\[\]]+/).filter(Boolean); // Split path into keys and indices
};
