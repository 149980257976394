import { TPrice, TSubscription } from 'scenes/Subscription/Subscription';
import { client } from './client';

const config = (): Promise<{ prices: TPrice[]; publishableKey: string }> => client.get('/subscriptions/config');

const createCustomer = (email: string): Promise<{ customer: { id: string } }> =>
  client.post('/subscriptions/create-customer', { email });

const createSubscription = (priceId: string): Promise<{ subscriptionId: string; clientSecret: string }> =>
  client.post('/subscriptions/create-subscription', { priceId });

const invoicePreview = () => client.get('/subscriptions/invoice-preview');

const cancelSubscription = (subscriptionId: string): Promise<{ subscription: TSubscription }> =>
  client.post('/subscriptions/cancel-subscription', { subscriptionId });

const updateSubscription = (subscriptionId: string, newPriceId: string) =>
  client.post('/subscriptions/update-subscription', { subscriptionId, newPriceId });

const subscriptions = (): Promise<{ subscriptions: { data: TSubscription[] } }> =>
  client.get('/subscriptions/subscriptions');

const customer = (): Promise<{ customer: { id: string } }> => client.get('/subscriptions/customer');

export const subscription = {
  config,
  createCustomer,
  createSubscription,
  invoicePreview,
  cancelSubscription,
  updateSubscription,
  subscriptions,
  customer,
};
