import { translationsType } from 'languages/languageContext';
import moment, { Moment } from 'moment';

type Props = {
  locale: string;
  rules: TRRule;
  startDate?: Moment;
  labels?: translationsType['rrEventLabels'];
  custom?: boolean;
};

export type TRRule = {
  freq: eFrequency;
  interval?: number;
  byweekday?: ByWeekday[];
  bysetpos?: BySetPos | number;
  tzid?: string;
  count?: number;
  until?: string;
  endType?: 'none' | 'count' | 'until' | string;
};

export const Frequency = {
  SINGLE: 8,
  DAILY: 3,
  WEEKLY: 2,
  MONTHLY: 1,
  YEARLY: 0,
  CUSTOM: 9,
};
export const enum eFrequency {
  YEARLY = 0,
  MONTHLY = 1,
  WEEKLY = 2,
  DAILY = 3,
  SINGLE = 8,
  CUSTOM = 9,
}
export declare type ByWeekday = WeekdayStr | number;
export declare type WeekdayStr = 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA' | 'SU';
export const weekdayArray: WeekdayStr[] = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
// Used only for weeks of month, where -1 means on last week of the month and 0 - when unused
export declare type BySetPos = -1 | 1 | 2 | 3 | 4 | 0;

export const configRRules = (props: Props): { recurringConfig: Partial<TRRule>; recurringLabel: string } => {
  const { labels: l, custom } = props;
  const { interval, bysetpos, byweekday, freq, until, count, endType } = props.rules || {};
  const startDate = moment(props.startDate);
  //@ts-ignore
  let recurringLabel = l?.repeatedDefault({ frequency: l?.[getKeyByValue(eFrequency, freq)?.single], freq });
  if (freq == undefined) recurringLabel = l?.notRepeat;

  const modifiedUntil = until ? moment(until).hours(23).minutes(59).seconds(59) : until;
  let endLabel = '';
  let ending = {};

  if (endType === 'count' || (endType === undefined && count)) {
    ending = { count };
    endLabel = ` (${count} ${count > 1 ? l?.times : l?.time}).`.replace('Terminen', 'Termine');
  }
  if (endType === 'until' || (endType === undefined && until)) {
    ending = { until: modifiedUntil };
    endLabel = ` (${l?.until} ${moment(until).format('DD.MM.YYYY')}).`;
  }
  let recurringConfig: Partial<TRRule> = {
    freq,
    interval,
    tzid: Intl.DateTimeFormat().resolvedOptions().timeZone,
    ...ending,
  };

  // All default values (presets)
  if (!custom) {
    if (freq === eFrequency.DAILY) {
      recurringLabel = l?.repeatedDaily({ interval: 1 });
    }

    if (freq === eFrequency.SINGLE) {
      recurringLabel = l?.notRepeat;
      recurringConfig = {};
    }

    if (freq === eFrequency.MONTHLY) {
      recurringLabel = l?.repeatedMonthly({ day: startDate.format('D'), interval: 1 });
    }
    if (freq === eFrequency.YEARLY) {
      recurringLabel = l?.repeatedYearly({ interval: 1 });
    }
  }

  if (freq === eFrequency.WEEKLY) {
    let sortedWeekDays = byweekday?.sort((a: any, b: any) => {
      const order = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
      return order.indexOf(a) - order.indexOf(b);
    });
    const days =
      sortedWeekDays?.length < 3
        ? sortedWeekDays?.map((el) => l?.[el])
        : sortedWeekDays?.map((el) => l?.[el]?.slice(0, 2).toUpperCase());
    recurringLabel = l?.repeatedWeekly({ interval, byweekday: days });
    recurringConfig.byweekday = byweekday.filter((el) => !!el);
  }

  // Customized values
  if (custom === true || (custom === undefined && custom !== false && interval)) {
    // recurringConfig.interval = interval;
    if (freq === eFrequency.DAILY) {
      recurringLabel = l?.repeatedDaily({ interval });
    }
    if (freq === eFrequency.MONTHLY) {
      if (bysetpos === 0 || bysetpos === undefined) {
        recurringLabel = l?.repeatedMonthly({ day: startDate.format('D'), interval });
      }
      if (bysetpos !== 0 && bysetpos !== undefined) {
        recurringLabel = l?.repeatedMonthlyBySetPos({ day: startDate.format('dddd'), interval, bysetpos });
        recurringConfig.bysetpos = bysetpos;
        //@ts-ignore
        recurringConfig.byweekday = [startDate.locale(locale).format('dd').toUpperCase()];
      }
    }
    if (freq === eFrequency.YEARLY) {
      recurringLabel = l?.repeatedYearly({ interval });
    }
  }

  if (endLabel.length && freq !== eFrequency.SINGLE) {
    if (recurringLabel?.slice(-1) === '.') recurringLabel = recurringLabel.slice(0, -1) + endLabel;
    else recurringLabel = recurringLabel + endLabel;
  }

  return { recurringConfig, recurringLabel };
};

function getKeyByValue<T extends number>(enumObj: Record<string, T>, value: T): string | undefined {
  const keys = Object.keys(enumObj);
  for (const key of keys) {
    if (enumObj[key] === value) {
      return key;
    }
  }
  return undefined;
}
