import React from 'react';
import { Config, withConfig } from '../../../config/';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import DrawerLink from './DrawerLink';
import DrawerLogOut from './DrawerLogOut';
import { DrawerNavProps } from '../model';
import { useLanguage } from 'languages/languageContext';
import { getConfig } from 'config/config';
import { useAppSelector } from 'store';
import { SelectMentoringState } from 'store/reducers/mentoring';

const { config } = getConfig();
const styles = createStyles({
  container: {
    width: '100%',
    padding: '20px 0',
  },
});

interface Props extends WithStyles<typeof styles>, DrawerNavProps {
  config: Config;
  handleLogOut?: () => void;
}

const DrawerNav: React.FC<Props> = ({ classes, user, onToggleMenu, kreiseAmount, config, handleLogOut }) => {
  const { isAlive } = useAppSelector((state) => state.server);
  const contactsLength = user && user.name ? user.contact_list.length : '0';
  const { isGuest } = user;
  const unreadMessages = useAppSelector((state) => state.chat.unreadMessages);
  const {
    requests: { incomingRequests, outgoingRequests, mentors },
  } = useAppSelector(SelectMentoringState);
  const uncheckedMentoringRequest = mentors?.filter((el) => !el.isInformed);
  const requestsBadge = incomingRequests?.length || outgoingRequests?.length || uncheckedMentoringRequest.length;
  const { drawer } = useLanguage();
  let navItems = [
    { route: '/posts', label: drawer.news, iconName: 'neuigkeiten' },
    { route: '/kreise', label: drawer.kreise, iconName: 'kreise', count: kreiseAmount, disabled: isGuest },
    { route: '/suche', label: drawer.search, iconName: 'suche', disabled: isGuest },
    { route: '/map', label: drawer.map, iconName: 'map', disabled: isGuest },
    { route: '/calendar', label: drawer.calendar, iconName: 'veranstaltulgen' },
    (config.KAS_MENTORING || config.BVS_MENTORING) && {
      route: '/mentoring',
      label: drawer.mentoring,
      iconName: 'mentoring',
      badgeCount: requestsBadge,
      disabled: isGuest,
    },
    { route: '/documents', label: drawer.documents, iconName: 'dokumente', disabled: isGuest },
    !config.WITHOUT_CONTACTS && {
      route: '/contacts',
      label: drawer.contacts,
      iconName: 'kontakte',
      count: contactsLength,
      disabled: isGuest,
    },
    !config.WITHOUT_CONTACTS && {
      route: '/contacts-requests',
      label: drawer.contacts + '-' + drawer.requests,
      iconName: 'kontakte',
      count: contactsLength,
      disabled: isGuest,
    },
    !config.WITHOUT_CONTACTS && {
      route: '/requests',
      label: drawer.requests,
      iconName: 'kontaktanfragen',
      badgeCount: user?.pending_contact_request?.length,
      disabled: isGuest,
    },
    {
      route: '/dialogs',
      label: drawer.messages,
      iconName: 'nachrichten',
      badgeCount: unreadMessages.length,
    },
    { route: '/profile', label: drawer.profile, iconName: 'profile' },
    { route: '/subscription/prices', label: drawer.subscription, iconName: 'subscription' },
  ];

  return (
    <div className={classes.container}>
      {navItems.map(
        (item: any) =>
          item && (
            <DrawerLink
              onToggleMenu={onToggleMenu}
              route={item?.route}
              label={item?.label}
              iconName={item?.iconName}
              key={item?.label}
              user={item?.user}
              count={item?.count}
              badgeCount={item?.badgeCount}
              disabled={item?.disabled || !isAlive}
            />
          ),
      )}
    </div>
  );
};

export default withStyles(styles)(withConfig(DrawerNav));
