import { authHeader, handleConnectionError, handleResponse } from './core';
import { API } from '../constants/';
import { client } from './client';
import { TEventStatus } from 'scenes/Calendar/CalendarScreen';

// GET ALL EVENTS
export const getEvents = (month: string | number, year: string | number, status: TEventStatus = 'all') =>
  client.get(`/v4/events/${month}/${year}?status=${status}`);

export const getEventsList = ({
  recurring,
  regular,
  status = 'all',
}: {
  recurring: number;
  regular: number;
  status: TEventStatus;
}): Promise<{ Data: any[]; total: number; skip: { recurring: number; regular: number } }> => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${API}/events/list?limit=10&status=${status}&recurring=${recurring}&regular=${regular}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
  // const res = await client.get(`/events/list?limit=${2}&status=${'all'}&recurring=0&regular=0`);
  // console.log('res', res);
};
// GET: http://localhost:6006/api/events/list?status=$EVENT_STATUS&limit=$LIMIT&recurring=0&regular=0

// GET BIRTHDATE EVENTS
export const getBirthdayEvents = async (month: number, year: number): Promise<{ Data: any[] }> => {
  const options: any = {
    method: 'GET',
    headers: await authHeader(),
  };
  // const fixedYear = month === 1 ? year - 1 : year;
  return fetch(`${API}/v4/show-user-birthday/${month}/Alle/${year}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// GET MY EVENTS
export const getMyEvents = (userId: string) => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${API}/events/my-events/${userId}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// GET CORPS EVENTS
export const getCorpsEvents = (userId: string) => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${API}/events/my-corps-events/${userId}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// GET EVENT MEMBERS
export const getEventMembers = (
  eventId: string,
  page: number,
  limit: number,
): Promise<{ data: any[]; total: number; page: number }> => client.get(`/events/${eventId}/members/${page}/${limit}`);

export const getEventWaitList = (
  eventId: string,
  page: number,
  limit: number,
): Promise<{ data: any[]; total: number; page: number }> => client.get(`/events/${eventId}/wait-list/${page}/${limit}`);

// GET EVENT BY ID
export const getEventById = (eventId: string, child?: boolean, startDate?: string, endDate?: string) =>
  client.get(`/events/${eventId}?child=${child}&startDate=${startDate}&endDate=${endDate}`);

// GET EVENT BY ID AND GUEST MAIL
export const getEventByMail = (eventId: string, email: string) => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${API}/events/${eventId}/guest?email=${email}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// ADD EVENT MEMBER
export const addEventMember = (
  id: string,
  me: string,
  recConfig: { startDate: string; recurring: boolean; endDate: string },
) => {
  const options: any = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({ id, me, ...recConfig }),
  };

  return fetch(`${API}/events/${id}/add-member`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// ADD EVENT GUEST MEMBER
export const addEventGuest = (
  eventId: string,
  email: string,
  guest: { guestEmail?: string; guestName: string; inviter?: string },
  parts?: { _id: string }[],
) => {
  const options: any = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({ email, guest, parts }),
  };

  return fetch(`${API}/events/${eventId}/guest/add`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// REMOVE EVENT MEMBER
export const removeEventMember = (id: string, me: string) => {
  const options: any = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({ id, me }),
  };

  return fetch(`${API}/events/${id}/remove-member`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// REMOVE EVENT GUEST MEMBER
export const removeEventGuest = (eventId: string, option: { email: string } | { guestId: string }) => {
  const options: any = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(option),
  };

  return fetch(`${API}/events/${eventId}/guest/remove`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// GET AUTOCOMPLETE
export const getAutocompletes = () => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${API}/events/autocomplete`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// GET CORPS CONTACTS
export const getCorpsContacts = (userId: string, corpsname: string) => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    `${API}/v2/search?index=0&limit=1&me=${userId}&name=corpsname&organizer=true&page=1&searchkey=${corpsname}`,
    options,
  )
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// ADD NEW EVENT
export const addEvent = (event: object) => {
  const options: any = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(event),
  };

  return fetch(`${API}/events`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// UPDATE EVENT
export const updateEvent = (event: object) => {
  const options: any = {
    method: 'PATCH',
    headers: authHeader(),
    body: JSON.stringify(event),
  };

  return fetch(`${API}/events`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// REMOVE EVENT
export const removeEvent = (id: string, notify: string, payload?: { date: string; recurringModifyType: string }) => {
  const options: any = {
    method: 'DELETE',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };

  return fetch(`${API}/events/${id}?notify=${notify}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

export const exportParticipants = (eventId: string) => `${API}/events/${eventId}/export-participants`;

export const joinEventPart = (partId: string, priceId?:string) => client.put(`events/part/${partId}/join`,{priceId});
export const leaveEventPart = (partId: string) => client.put(`events/part/${partId}/leave`);

export const voteEventPart = (eventId: string, optionId: string) =>
  client.put(`events/${eventId}/part/vote/${optionId}`);
export const unvoteEventPart = (eventId: string, optionId: string) =>
  client.delete(`events/${eventId}/part/vote/${optionId}`);
export const voteFreeText = (eventId: string, partId: string, text: string) =>
  client.put(`events/${eventId}/part/${partId}/custom-vote`, { text });
export const unvoteFreeText = (eventId: string, partId: string, optionId: string) =>
  client.delete(`events/${eventId}/part/${partId}/custom-vote/${optionId}`);

export const addPartMember = (partId: string, memberId: string) => client.put(`events/part/${partId}/add/${memberId}`);
export const removePartMember = (partId: string, memberId: string, type?: 'member' | 'guest') =>
  client.delete(`events/part/${partId}/remove/${memberId}?type=${type}`);
