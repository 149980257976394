import { FC, useState } from 'react';
import Dropzone from 'react-dropzone';
import { makeStyles } from '@material-ui/styles';

import imagePlaceholder from '../../assets/imagePlaceholder.png';
import { ProgressLoader } from 'components/UI/ProgressLoader';
import { getConfig } from 'config/config';
import { IonIcon } from 'components/UI/IonIcon';
import { Button } from 'components/shadcn/button';
import { useAppDispatch, useAppSelector } from '../../store';
import { uploadImage, selectMediaState, mediaActions } from '../../store/reducers/mediaSlice';

const { theme } = getConfig();

interface IImageInput {
  addLabel: string;
  editLabel: string;
  editImage?: string;
}

const useStyles = makeStyles({
  container: {
    marginBottom: '15px',
  },
  deleteButton: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    zIndex: 20,
    width: '1.5rem',
    height: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(128, 128, 128, 0.5)',
    borderRadius: '0.375rem',
    cursor: 'pointer',
    backdropFilter: 'blur(4px)',
    transition: 'background-color 0.2s, transform 0.1s',
    '&:hover': {
      backgroundColor: 'rgba(128, 128, 128, 0.7)',
      transform: 'scale(1.05)',
    },
  },
  backdrop: {
    width: '100%',
    height: '10rem',
    borderRadius: 20,
    borderWidth: 1,
    border: 'dashed',
    borderColor: theme.ICON_BORDER,
    backgroundColor: `${theme.ICON_BORDER}30`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'relative',
  },
  dragover: {
    backgroundColor: `${theme.ICON_BORDER}50`,
  },
  image: {
    height: '8rem',
    objectFit: 'cover',
  },
  placeholder: {
    mixBlendMode: 'multiply',
    zIndex: -1,
  },
  buttonLabel: {
    cursor: 'pointer',
  },
  errorText: {
    minHeight: '1rem',
    marginLeft: '0.75rem',
    textAlign: 'left',
    fontSize: '0.75rem',
    color: theme.ERROR_PRIMARY,
  },
});

export const ImageInput: FC<IImageInput> = ({ editImage, addLabel, editLabel }) => {
  const styles = useStyles({});
  const formats = ['image/jpeg', 'image/png'];
  const dispatch = useAppDispatch();
  const { previewImage } = useAppSelector(selectMediaState);

  const [error, setError] = useState<string | null>(null);

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      if (formats.includes(file.type)) {
        deleteAttachment();
        dispatch(uploadImage({ file, previewImage: true }));
        setError(null);
      } else {
        setError('Unsupported file format');
      }
    }
  };

  const deleteAttachment = () => {
    dispatch(mediaActions.deleteAttachment({ type: 'previewImage', id: 0 }));
  };

  const DeleteButton: FC = () => (
    <div
      className={styles.deleteButton}
      onClick={(e) => {
        e.stopPropagation();
        deleteAttachment();
      }}
    >
      <IonIcon name="trash" color={theme.ERROR_PRIMARY} />
    </div>
  );

  const previewImages = previewImage.filter(Boolean);
  const currentImage = previewImages[0]?.renderUrl || previewImages[0]?.url || editImage || imagePlaceholder;

  return (
    <>
      <Dropzone accept={formats} onDrop={onDrop} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <div>
            <div className={styles.container}>
              <input id="file" type="file" {...getInputProps()} />
              <Button>
                <label htmlFor="file" className={styles.buttonLabel}>
                  {previewImages[0] || editImage ? editLabel : addLabel}
                </label>
              </Button>
            </div>
            <div
              {...getRootProps({
                onClick: (e) => {
                  if ((e.target as HTMLElement).closest(`.${styles.deleteButton}`)) {
                    e.preventDefault();
                  }
                },
              })}
              className={`${styles.backdrop} ${error ? styles.dragover : ''}`}
            >
              <div>
                {(previewImages[0] || editImage) && <DeleteButton />}
                <img
                  className={`${styles.image} ${!previewImages[0] && !editImage && styles.placeholder}`}
                  src={currentImage}
                  alt="Selected or placeholder"
                />
                <ProgressLoader loading={!!previewImages[0]?.progress} progress={previewImages[0]?.progress} />
              </div>
            </div>
          </div>
        )}
      </Dropzone>
      <div className={styles.errorText}>{error}</div>
    </>
  );
};
